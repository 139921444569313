import { Logger } from './logger';

export const Constraints = {
  mustEqual: <T = any>(given: T, expected: T): T => {
    if (given !== expected) {
      Logger.warn(`Data does not match expected value. ${given} was given, expected ${expected}`);
    }
    return expected;
  },
  withinRange: (given: number, min: number, max: number): number => {
    // If values are passed in backwards, correct them
    if (min > max) {
      const temp = max;
      max = min;
      min = temp;
    }
    if (given < min || given > max) {
      Logger.warn(
        `Number is not within expected range. ${given} was expected to be within ${min} - ${max}`
      );
    }
    return Math.min(Math.max(given, min), max);
  },
};

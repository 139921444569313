import { NdsAttachmentsComponent } from './attachments/attachments.component';
import { NdsAttributeDoubleComponent } from './attribute-double/attribute-double.component';
import { NdsAttributeQuadComponent } from './attribute-quad/attribute-quad.component';
import { NdsAttributeSingleComponent } from './attribute-single/attribute-single.component';
import { NdsAttributeTripleComponent } from './attribute-triple/attribute-triple.component';
import { NdsModifiersComponent } from './modifiers/modifiers.component';

export const components = [
  NdsAttachmentsComponent,
  NdsAttributeDoubleComponent,
  NdsAttributeQuadComponent,
  NdsAttributeSingleComponent,
  NdsAttributeTripleComponent,
  NdsModifiersComponent,
];

export {
  NdsAttachmentsComponent,
  NdsAttributeDoubleComponent,
  NdsAttributeQuadComponent,
  NdsAttributeSingleComponent,
  NdsAttributeTripleComponent,
  NdsModifiersComponent,
};

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule, BrowserTransferStateModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { CookieService, NgxCoreModule } from '@rpg/ngx/core';
import { NgxAnalyticsModule } from '@rpg/ngx/analytics';
import { NgxWebsocketsModule } from '@rpg/ngx/websockets';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { TranslocoRootModule } from './transloco.module';
import { CoreModule } from './core/core.module';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { icons } from './icon-library';
import { CustomPreloadingStrategy } from './preload-strategy';
import { TranslocoService } from '@ngneat/transloco';
import { PaginatorInternationalizationService } from './paginator-internationalization.service.service';
import { AppRoutingModule } from './app-routing.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '@sessions/shared';

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'rpgsessions' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AppRoutingModule,
    CoreModule,
    SharedModule,
    NgxCoreModule,
    NgxAnalyticsModule,
    NgxWebsocketsModule.forRoot(),
    FontAwesomeModule,
    HammerModule,
    MatButtonModule,
    MatSnackBarModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    TranslocoRootModule,
    HammerModule,
  ],
  providers: [
    CookieService,
    CustomPreloadingStrategy,
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslocoService) => {
        const service = new PaginatorInternationalizationService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslocoService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...(icons as any));
  }
}

export const environment = {
  production: true,
  review: false,
  webserver: 'https://api.rpgsessions.com',
  weburl: 'https://v2.rpgsessions.com',
  promotionalUrl: 'https://rpgsessions.com',
  gameTableApi: 'https://game-table-api.rpgsessions.com',
  enableSentry: true,
  patreonDriveActive: true,
  sentry: {
    version: 'sessions-2.4.28.5',
    environment: 'production',
    dsn: 'https://4fd5e7574b9c44dc8ecc82120c5996c2@o381849.ingest.sentry.io/5210416',
  },
  patreon: {
    baseUrl: 'https://www.patreon.com/oauth2/authorize',
    clientId: '3bbf8kQHphdGrTztM943cxGa5z6FFDpDS7e3mP_TOU2mV8aW6wy4PqJwQHRRgSP1',
    redirectUri: 'https://app.rpgsessions.com/user/patreon-register',
    scopes: 'identity',
  },
  discord: {
    baseUrl: 'https://discord.com/api/oauth2/authorize',
    clientId: '534606682928578572',
    redirectUri: 'https://app.rpgsessions.com/user/discord-register',
    scopes: 'identify guilds',
  },
  supabase: {
    url: 'https://hizqwhqbldtldumwvlxi.supabase.co',
    key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhpenF3aHFibGR0bGR1bXd2bHhpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTExMjMzMzcsImV4cCI6MTk2NjY5OTMzN30.1O2-qA5VfdQmPuZQHNiPs15um0JoWo_KeoqXGb1bw-4',
  },
};

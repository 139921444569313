import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { RxState } from '@rx-angular/state';
import { MatTableDataSource } from '@angular/material/table';
import { isNotNull } from '@rpg/core/base';
import { distinctUntilChanged, merge, pluck, startWith, Subscription } from 'rxjs';

interface ComponentState {
  editMode: boolean;
  columns: string[];
  isSingle: boolean;
  data: FormGroup[];
  consumedHardPoints: number;
  availableHardPoints: number;
}

@Component({
  selector: 'rpg-nds-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  /**
   * We're using standard CD on this component to watch for changes to the
   * passed in form group more easily
   */
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [RxState],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NdsAttachmentsComponent implements OnInit {
  @Input()
  public set attachments(value: FormArray | FormGroup[] | FormGroup) {
    console.warn('attachments updated');
    let data: FormGroup[] = [];
    if (value instanceof FormArray) {
      data = value.controls as FormGroup[];
      this._state.set({
        data,
      });
    } else if (Array.isArray(value)) {
      data = value;
      this._state.set({
        data,
      });
    } else if (value instanceof FormGroup) {
      data = [value];
      this._state.set({
        isSingle: true,
        data: data,
      });
    }
    this._watchHardPoints(data);
  }
  @Input()
  public set editMode(editMode: boolean) {
    this._state.set({
      editMode,
    });
  }
  @Input()
  public set single(isSingle: boolean) {
    this._state.set({
      isSingle,
    });
  }
  @Input()
  public set availableHardPoints(amount: number) {
    this._state.set({
      availableHardPoints: amount,
    });
  }
  @Output()
  public editAttachment = new EventEmitter<FormGroup | undefined>();
  @Output()
  public editModification = new EventEmitter<{ attachmentId: string; form?: FormGroup }>();
  @Output()
  public editQuality = new EventEmitter<{ attachmentId: string; form?: FormGroup }>();

  public state$ = this._state.select();
  public tableDataSource: MatTableDataSource<FormGroup> = new MatTableDataSource<FormGroup>([]);

  private _hpWatchers?: Subscription;

  constructor(private _state: RxState<ComponentState>) {
    this._state.set({
      isSingle: false,
      consumedHardPoints: 0,
      availableHardPoints: 0,
    });
  }

  ngOnInit(): void {
    this._state.hold(
      this._state.select(
        pluck('editMode'),
        distinctUntilChanged(),
        startWith(this._state.get('editMode'))
      ),
      editMode => this._toggleControlsForEditMode(editMode)
    );
  }

  public get consumedHardPoints(): string {
    console.warn('CONSUMED UPDATE');
    const { data } = this._state.get();
    const total = data.reduce(
      (acc, next) => (acc += (next.get('equipped').value ? next.get('hardPoints').value : 0) ?? 0),
      0
    );
    return Math.max(total, 0).toString();
  }

  private _toggleControlsForEditMode(editMode: boolean): void {
    const controls: Array<AbstractControl | null> = [];
    this.tableDataSource.data.forEach(aForm => {
      controls.push(aForm.get('equipped'));
      const modForms = aForm.get('modifications') as FormArray;
      modForms.controls.forEach(mod => {
        controls.push(mod.get('enabled') as FormControl);
      });
    });
    controls.filter(isNotNull).forEach(c => {
      if (editMode) {
        c.enable({
          emitEvent: false,
        });
      } else {
        c.disable({
          emitEvent: false,
        });
      }
    });
  }

  private _watchHardPoints(attachments: FormGroup[]): void {
    this._hpWatchers?.unsubscribe();
    const sub = merge(
      attachments.map(attachment => attachment.getControl('hardPoints').valueChanges)
    ).subscribe(() => {
      console.log('RECALC!');
      const total = attachments.reduce(
        (acc, next) => (acc += next.get('hardPoints').value ?? 0),
        0
      );
      this._state.set({
        consumedHardPoints: total,
      });
    });
    this._hpWatchers = sub;
  }
}

import { DataType, parseEnum } from '@rpg/core/base';

export class CreateContentResponse<Params = any> {
  public type: DataType = DataType.Character;
  public params: Params | null = null;

  constructor(params?: Partial<CreateContentResponse<Params>>) {
    if (!!params) {
      this.type = parseEnum(DataType, params.type, this.type);
      this.params = params.params ?? null;
    }
  }
}

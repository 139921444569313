/* eslint-disable no-magic-numbers */
import { RulesSystem } from '@rpg/core/base';

export enum DiceGroupType {
  Numbered = 1,
  FFGStarWars = 2,
  FFGGenesys = 3,
  NarrativeDiceSystem = 4,
}

export namespace DiceGroupType {
  export function toString(type: DiceGroupType): string {
    switch (type) {
      case DiceGroupType.Numbered:
        return 'Numbered';
      case DiceGroupType.FFGStarWars:
        return 'Star Wars';
      case DiceGroupType.FFGGenesys:
        return 'Genesys';
      case DiceGroupType.NarrativeDiceSystem:
        return 'Narrative Dice';
      default:
        return '';
    }
  }

  export const members: DiceGroupType[] = [
    DiceGroupType.Numbered,
    DiceGroupType.FFGStarWars,
    DiceGroupType.FFGGenesys,
    DiceGroupType.NarrativeDiceSystem,
  ];

  export function toRulesSystem(type: DiceGroupType): RulesSystem | null {
    switch (type) {
      case DiceGroupType.NarrativeDiceSystem:
        return RulesSystem.NarrativeDiceSystem;
      case DiceGroupType.Numbered:
        return RulesSystem.Generic;
      default:
        return null;
    }
  }

  export function fromRulesSystem(type: RulesSystem): DiceGroupType | null {
    switch (type) {
      case RulesSystem.NarrativeDiceSystem:
        return DiceGroupType.NarrativeDiceSystem;
      default:
        return null;
    }
  }

  export function fromShortHand(type: string): DiceGroupType | null {
    switch (`${type}`.toLowerCase().trim()) {
      case 'e':
      case 'g':
        return DiceGroupType.NarrativeDiceSystem;
      // return DiceGroupType.FFGStarWars;
      // return DiceGroupType.FFGGenesys;
      default:
        return null;
    }
  }
}

import { Injectable } from '@angular/core';
import { Logger, Permission } from '@rpg/core/base';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private _permissions: BehaviorSubject<Permission[]> = new BehaviorSubject<Permission[]>([]);

  public permissions$: Observable<Permission[]> = this._permissions.asObservable();
  public setPermissions(permissions: Permission[]): void {
    this._permissions.next(permissions);
  }
  public hasPermission(permission: Permission): boolean {
    Logger.log(
      permission,
      this._permissions.getValue(),
      this._permissions.getValue().includes(permission)
    );
    return this._permissions.getValue().includes(permission);
  }
}

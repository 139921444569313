import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsCharacterWeaponData } from '@rpg/core/character';
import { NDSCharacterModifierFormGroupFactory } from './modifiers';
import { ndsCharacterAttachmentToFormGroup } from './nds-character-attachment-to-form-group';

export function ndsCharacterWeaponToFormGroup(
  weapon: NdsCharacterWeaponData
): FormGroup<NdsCharacterWeaponData> {
  return new FormGroup<NdsCharacterWeaponData & Expandable>({
    expanded: new FormControl((weapon as any)['expanded'] ?? false),
    attachments: new FormArray(weapon.attachments.map(a => ndsCharacterAttachmentToFormGroup(a))),
    baseDamage: new FormControl(weapon.baseDamage),
    carrying: new FormControl(weapon.carrying),
    cost: new FormControl(weapon.cost),
    critRating: new FormControl(weapon.critRating),
    damageAddsBrawn: new FormControl(weapon.damageAddsBrawn),
    encumbrance: new FormControl(weapon.encumbrance),
    equipped: new FormControl(weapon.equipped),
    extraDice: new FormControl(weapon.extraDice),
    hardPoints: new FormControl(weapon.hardPoints),
    id: new FormControl(weapon.id),
    linkedSkillId: new FormControl(weapon.linkedSkillId),
    lookupName: new FormControl(weapon.lookupName),
    modifiers: new FormArray(weapon.modifiers.map(m => NDSCharacterModifierFormGroupFactory(m))),
    name: new FormControl(weapon.name),
    range: new FormControl(weapon.range),
    rarity: new FormControl(weapon.rarity),
    restricted: new FormControl(weapon.restricted),
    description: new FormControl(weapon.description),
  });
}

import { parseBoolean, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterModifierFactory } from '../functions/nds-character-modifier-factory';
import { NdsCharacterModifierValidatorFactory } from '../functions/nds-character-modifier-validator-factory';
import { NdsCharacterModifierData } from './modifiers';

export class NdsCharacterHeroicAbilityLevel {
  public description: string = '';
  public purchased: boolean = false;
  public modifiers: NdsCharacterModifierData[] = [];

  constructor(params?: Partial<NdsCharacterHeroicAbilityLevel>) {
    if (!!params) {
      this.description = params.description ?? this.description;
      this.purchased = parseBoolean(params.purchased, this.purchased);
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(m => NdsCharacterModifierFactory(m)).filter(m => !!m.name)
        : this.modifiers;
    }
  }

  public static validate(
    input: NdsCharacterHeroicAbilityLevel,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = [];
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Level `;
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.purchased, { fieldName: `${name} Purchased Toggle` })
    );
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterModifierValidatorFactory(next, name)],
        []
      )
    );
    return errors;
  }
}

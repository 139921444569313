export enum GameFeature {
  CustomStoryPointStyles = '[game feature] custom story point styles',
}

export namespace GameFeature {
  export const members: GameFeature[] = [GameFeature.CustomStoryPointStyles];

  export function getDescription(feature: GameFeature): string {
    return '';
  }

  export function translationKey(feature: GameFeature): string {
    return '';
  }
}

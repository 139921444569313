import { unreachable } from '@rpg/core/base';
import {
  DiceGroupType,
  DiceInputColorScheme,
  DiceInputNameScheme,
  DiceShortHandStyle,
  DiceType,
} from '../enums';
import { DiceSingle } from '../models/dice-single';

export function numberedDiceToDiceString(dice: DiceSingle[]): string {
  const grouped = dice.reduce<{ [x in DiceType]?: DiceSingle[] }>((acc, next) => {
    if (!next.type) return acc;
    (acc[next.type] = acc[next.type] || []).push(next);
    return acc;
  }, {});
  const numbered = Object.entries(grouped)
    .map(([type, group]) => {
      if (!!type && !!group && group.length > 0) {
        return `${group.length}${type.toString()}`;
      }
      return '';
    })
    .filter(e => e !== '');
  return numbered.join('+');
}

function dieToDieString(dice: DiceSingle, inputStyle: DiceShortHandStyle): string {
  if (!dice.type) return '';
  switch (inputStyle) {
    case DiceShortHandStyle.Color:
      return DiceInputColorScheme.fromDiceType(dice.type) ?? '';
    case DiceShortHandStyle.Name:
      return DiceInputNameScheme.fromDiceType(dice.type) ?? '';
    default:
      unreachable(inputStyle, 'dieToDieString');
  }
}

export function diceToDiceString(
  dice: DiceSingle[],
  diceShorthandStyle: DiceShortHandStyle
): string {
  if (!dice || !dice.length) {
    return '';
  }

  if (dice[0].diceGroup === DiceGroupType.Numbered) {
    return numberedDiceToDiceString(dice);
  }

  const result = dice.map(die => dieToDieString(die, diceShorthandStyle));
  return result.join('');
}

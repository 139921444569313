export enum CharacterSort {
  Name = 'name',
  Newest = 'new',
  Oldest = 'old',
  Modified = 'modified',
  Favorite = 'favorite',
}

export namespace CharacterSort {
  export const members: CharacterSort[] = [
    CharacterSort.Name,
    CharacterSort.Newest,
    CharacterSort.Oldest,
    CharacterSort.Modified,
    CharacterSort.Favorite,
  ];

  export function toString(type: CharacterSort): string {
    switch (type) {
      case CharacterSort.Name:
        return 'Name';
      case CharacterSort.Newest:
        return 'Newest';
      case CharacterSort.Oldest:
        return 'Oldest';
      case CharacterSort.Modified:
        return 'Modified';
      case CharacterSort.Favorite:
        return 'Favorite';
      default:
        return '';
    }
  }

  export function fromString(value: string): CharacterSort | null {
    switch (value) {
      case 'name':
        return CharacterSort.Name;
      case 'new':
        return CharacterSort.Newest;
      case 'old':
        return CharacterSort.Oldest;
      case 'modified':
        return CharacterSort.Modified;
      case 'favorite':
        return CharacterSort.Favorite;
      default:
        return null;
    }
  }
}

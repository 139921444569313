/* eslint-disable @typescript-eslint/ban-types */
import { HttpService } from '../services/http.service';
import { ParamDecoratorType } from '../models/ParamDecoratorType';

/**
 * Attaches the given paramter to the body of the request that will be made
 * when attached to a parameter of a function that is decorated by a request
 * decorator.
 *
 * Using no key for the Body decorator will set the object to the
 * request body root
 *
 * ```Typescript
 * @Post('/some/url')
 * public myPostRequest(
 *      @Body() value: any
 * ): Observable<any> {
 *      return null; // All logic is contained in the @Post decorator
 * }
 *
 * // Results:
 * req.body = value;
 * ```
 *
 * Using a key will set the object to the key in the request body
 *
 * ```Typescript
 * @Post('/some/url'),
 * public myPostRequest(
 *      @Body('key') value: any,
 *      @Body('key2') value2: any
 * ): Observable<any> {
 *      return null;
 * }
 *
 * // Results:
 * req.body = {
 *      key: value,
 *      key2: value2
 * }
 * ```
 *
 * By specifying Body decorators with and without a key, the one without a
 * key will ALWAYS be applied first followed by any other decorators with a key.
 * If multiple decorators without a key are given, only the first one will be
 * applied.
 *
 * ```Typescript
 * @Post('/some/url')
 * public myPostRequest(
 *      @Body('key') value1: any,
 *      @Body() value2: any,
 *      @Body('key2') val3: any
 * ): Obsevable<any> {
 *      return null;
 * }
 *
 * // Results:
 * req.body = {
 *      ...value2,
 *      key: value1,
 *      key2: val3
 * }
 * ```
 */
export const Body = requestParamBuilderOptionalKey(ParamDecoratorType.Body);

/**
 * Replaces a key in the url of the request that will be made when attached to
 * a parameter of a function that is decoratred by a request decorator.
 *
 * ```Typescript
 * @Get('/todo/:id')
 * public getTodoById(
 *      @Path('id') todoId: string
 * ): Observable<any> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 *
 * // Result
 * req.url = `/todo/${todoId}`
 * ```
 */
export const Path = requestParamBuilder(ParamDecoratorType.Path);

/**
 * Adds a url query param to the url of the request that will be made when
 * attached to a paramter of a function that is decorated by a
 * request decorator.
 *
 * ```Typescript
 * @Get('/todo')
 * public getTodoList(
 *      @Query('limit') limit: number
 * ): Observable<any> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 *
 * // Returns:
 * req.url = `/todo?limit=${limit}`
 * ```
 */
export const Query = requestParamBuilder(ParamDecoratorType.Query);

/**
 * Adds a header to a request that will be made when attached to a paramter
 * of a function that is decoratred by a request decorator.
 *
 * ```Typescript
 * @generator('/todo')
 * public getTodoList(
 *      @Header('Authorization') myToken: string
 * ): Observable<any> {
 *      return null; //All logic is contained in the @Get decorator
 * }
 *
 * // Returns:
 * req.headers = {
 *      Authorization: myToken
 * }
 */
export const Header = requestParamBuilder(ParamDecoratorType.Header);

/**
 * @ignore
 *
 * Generates a param decorator with an optional key
 *
 * @param {String} type decorator identifier
 * @returns {Function} decorator
 */
function requestParamBuilderOptionalKey(
  type: string
): (key?: string, defaultValue?: any) => Function {
  return (key: string = '', defaultValue?: any): Function =>
    (target: HttpService, propertyKey: string, parameterIndex: number): void => {
      applyDecorator(type, key, target, propertyKey, parameterIndex, defaultValue);
    };
}

/**
 * @ignore
 *
 * Generates a param decorator with a required key
 *
 * @param {String} type decorator identifier
 * @returns {Function} decorator
 */
function requestParamBuilder(type: string): (key: string, defaultvalue?: any) => Function {
  return (key: string, defaultValue?: any): Function =>
    (target: HttpService, propertyKey: string, parameterIndex: number): void => {
      applyDecorator(type, key, target, propertyKey, parameterIndex, defaultValue);
    };
}

/**
 * @ignore
 *
 * Attaches metadata to a given target about a decorator
 *
 * @param {String} type decorator identifier
 * @param {String} key decorator key
 * @param {HttpService} target target that will receive the metadata
 * @param {String} propertyKey property that holds the decorator
 * @param {Number} parameterIndex index of metadata for a given property
 * @param {any} defaultValue default value to use when no value is given
 * @returns {void} void
 */
function applyDecorator(
  type: string,
  key: string,
  target: HttpService,
  propertyKey: string,
  parameterIndex: number,
  defaultValue?: any
): void {
  const mKey = `${propertyKey}_${type}_parameters`;
  const paramObj: {
    key: string;
    parameterIndex: number;
    defaultValue: any;
  } = {
    key: key,
    parameterIndex: parameterIndex,
    defaultValue: defaultValue,
  };

  if (Array.isArray((target as any)[mKey])) {
    (target as any)[mKey].push(paramObj);
  } else {
    (target as any)[mKey] = [paramObj];
  }
}

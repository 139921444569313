import { Directive, ElementRef, ViewContainerRef, TemplateRef, Input, OnInit } from '@angular/core';
import { FeatureFlag } from '@rpg/core/base';
import { Store } from '@ngxs/store';
import { isFeatureFlagEnabled } from '@sessions/core';

@Directive({
  selector: '[rpgFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  private _el: ElementRef;

  @Input()
  public rpgFeatureFlag!: FeatureFlag;

  constructor(
    el: ElementRef,
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<any>,
    private _store: Store
  ) {
    this._el = el;
  }

  ngOnInit(): void {
    if (!this.rpgFeatureFlag) {
      console.error('rpgFeatureFlag requires a passed flag!');
      return;
    }
    const enabled = isFeatureFlagEnabled(this._store, this.rpgFeatureFlag);
    if (enabled) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }
}

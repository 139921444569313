/* Taken from NGX-CLIPBOARD npm package and adjusted to be compatible with Angular Universal SSR and Angular 5 */

import { Inject, Injectable, Optional, Renderer2, SkipSelf } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  private tempTextArea: any | undefined;
  constructor(@Inject(DOCUMENT) private document: any) {}
  public get isSupported(): boolean {
    return !!this.document.queryCommandSupported && !!this.document.queryCommandSupported('copy');
  }

  public isTargetValid(element: any): boolean {
    if (
      element &&
      element.tagName &&
      (element.tagName.toLowerCase() === 'input' || element.tagName.toLowerCase() === 'textarea')
    ) {
      if (element.hasAttribute('disabled')) {
        // eslint-disable-next-line max-len
        throw new Error(
          'Invalid "target" attribute. Please use "readonly" instead of "disabled" attribute'
        );
      }
      return true;
    }
    throw new Error('Target should be input or textarea');
  }

  public copyFromInputElement(targetElm: any, renderer: Renderer2): boolean {
    try {
      this.selectTarget(targetElm, renderer);
      const re = this.copyText();
      this.clearSelection(targetElm, window);
      return re;
    } catch (error) {
      return false;
    }
  }

  public copyFromContent(content: string, renderer: Renderer2): boolean {
    if (!this.tempTextArea) {
      this.tempTextArea = this.createTempTextArea(this.document, window, renderer);
      this.document.body.appendChild(this.tempTextArea);
    }
    this.tempTextArea.value = content;
    return this.copyFromInputElement(this.tempTextArea, renderer);
  }

  // remove temporary textarea if any
  public destroy(): void {
    if (this.tempTextArea) {
      this.document.body.removeChild(this.tempTextArea);
      this.tempTextArea = undefined;
    }
  }

  // select the target html input element
  private selectTarget(inputElement: any, renderer: Renderer2): number | undefined {
    inputElement.select();
    inputElement.setSelectionRange(0, inputElement.value.length);
    return inputElement.value.length;
  }

  private copyText(): boolean {
    return this.document.execCommand('copy');
  }
  // Removes current selection and focus from `target` element.
  private clearSelection(inputElement: any, window: Window): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    inputElement && inputElement.blur();
    window.getSelection()?.removeAllRanges();
  }

  // create a fake textarea for copy command
  private createTempTextArea(doc: Document, window: Window, renderer: Renderer2): any {
    const isRTL = doc.documentElement.getAttribute('dir') === 'rtl';
    const ta = doc.createElement('textarea');
    // Prevent zooming on iOS
    ta.style.fontSize = '12pt';
    // Reset box model
    ta.style.border = '0';
    ta.style.padding = '0';
    ta.style.margin = '0';
    // Move element out of screen horizontally
    ta.style.position = 'absolute';
    ta.style[isRTL ? 'right' : 'left'] = '-9999px';
    // Move element to the same position vertically
    const yPosition = window.pageYOffset || doc.documentElement.scrollTop;
    ta.style.top = yPosition + 'px';
    ta.setAttribute('readonly', '');
    return ta;
  }
}
// this pattern is mentioned in https://github.com/angular/angular/issues/13854 in #43
export function CLIPBOARD_SERVICE_PROVIDER_FACTORY(
  doc: Document,
  win: Window,
  parentDispatcher: ClipboardService
): ClipboardService {
  return parentDispatcher || new ClipboardService(doc);
}

export const CLIPBOARD_SERVICE_PROVIDER = {
  provide: ClipboardService,
  deps: [DOCUMENT, [new Optional(), new SkipSelf(), ClipboardService]],
  useFactory: CLIPBOARD_SERVICE_PROVIDER_FACTORY,
};

import { ObjectId } from 'bson';
import { parseObjectId } from '@rpg/core/base';
import { GameNPCVisibility } from './game-npc-visibility';

export class GameNPC {
  public characterId!: ObjectId;
  public visibility: GameNPCVisibility = new GameNPCVisibility();
  public clone: boolean = false;

  constructor(params?: Partial<GameNPC>) {
    if (!!params) {
      this.characterId = parseObjectId(params.characterId, this.characterId);
      this.visibility = !!params.visibility
        ? new GameNPCVisibility(params.visibility)
        : this.visibility;
      this.clone = params.clone ?? this.clone;
    }
  }
}

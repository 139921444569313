<rpg-form-field>
  <fa-icon matPrefix *ngIf="prefixIconName" [icon]="prefixIconName"></fa-icon>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    type="text"
    [placeholder]="placeholder"
    matInput
    [formControl]="inputControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.label">
      <markdown [data]="option.label"></markdown>
    </mat-option>
  </mat-autocomplete>
  <mat-error [rpgFormFieldError]="inputControl"></mat-error>
  <fa-icon matSuffix *ngIf="suffixIconName" [icon]="suffixIconName"></fa-icon>
</rpg-form-field>

import {
  Directive,
  forwardRef,
  ElementRef,
  Renderer2,
  HostListener,
  Inject,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { MaskService } from '@rpg/ngx/core';

@Directive({
  selector: '[rpgContentEditableNumber][contentEditable]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContentEditableNumberDirective),
      multi: true,
    },
  ],
})
export class ContentEditableNumberDirective implements ControlValueAccessor, OnDestroy {
  private _control: UntypedFormControl = new UntypedFormControl();
  private _unsub: Subject<void> = new Subject<void>();
  private _previousValue: any;

  @HostListener('keyup', ['$event'])
  public keyup(event: KeyboardEvent): void {
    const target: any = event.target;
    if (isPlatformBrowser(this._platformId)) {
      if (target.innerText === this._previousValue) {
        return;
      }
      const positions = this.getCaretPosition(target);
      // Positions may be undefined
      if (Array.isArray(positions)) {
        const beforeValue = target.innerText;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [cStart, cEnd]: [number, number] = positions;
        const beforeLength = target.innerText.length;
        const masked = this._setDisplayedValue(target.innerText);
        const afterLength = masked.length;
        const didGrow = afterLength > beforeLength;
        const didCharBeforeGetRemoved =
          cEnd > 0 ? beforeValue[cEnd - 1] !== masked[cEnd - 1] : false;
        this._setControlValue(target.innerText);
        if (target.innerText) {
          const range = document.createRange();
          const sel = window.getSelection();

          const newCursorPosition = Math.max(
            Math.min(didGrow ? cEnd + 1 : didCharBeforeGetRemoved ? cEnd - 1 : cEnd, masked.length),
            0
          );

          range.setStart(target.childNodes[0], newCursorPosition);
          range.collapse(true);
          sel?.removeAllRanges();
          sel?.addRange(range);
        }
      }
    }
  }

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _maskService: MaskService
  ) {}

  ngOnDestroy(): void {
    this._unsub.next();
    this._unsub.complete();
  }

  public onTouched: () => void = () => {};

  public writeValue(val: any): void {
    this._setControlValue(val);
    this._setDisplayedValue(val);
  }

  public registerOnChange(fn: any): void {
    this._control.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this._control.disable() : this._control.enable();
  }

  private _setDisplayedValue(val: string): string {
    const newVal = this._maskService.applyNumberMask(`${val}`);
    this._renderer.setProperty(this._elementRef.nativeElement, 'innerText', newVal);
    this._previousValue = newVal;
    return newVal;
  }

  private _setControlValue(val: string | number): void {
    if (typeof val === 'string') {
      val = parseInt(val.replace(/[,.]/g, ''), 10);
    }
    this._control.setValue(val);
  }

  // I don't even know how these work. Just found them online, copied them
  // in and they seem to work great.... soooo cool? ¯\_(ツ)_/¯
  // https://stackoverflow.com/a/53128599

  // node_walk: walk the element tree, stop when func(node) returns false
  private nodeWalk(node: any, func: any): any {
    let result = func(node);
    for (node = node.firstChild; result !== false && node; node = node.nextSibling) {
      result = this.nodeWalk(node, func);
    }
    return result;
  }

  // getCaretPosition: return [start, end] as offsets to elem.textContent that
  //   correspond to the selected portion of text
  //   (if start == end, caret is at given position and no text is selected)
  private getCaretPosition(elem: any): [number, number] | undefined {
    const sel: any = window.getSelection();
    let cLength: [number, number] = [0, 0];

    // eslint-disable-next-line eqeqeq
    if (sel.anchorNode == elem) {
      cLength = [sel.anchorOffset, sel.extentOffset || sel.focusOffset || 0];
    } else {
      const nodesToFind = [sel.anchorNode, sel.extentNode || sel.focusNode];
      if (
        !elem.contains(sel.anchorNode) ||
        !(elem.contains(sel.extentNode) || elem.contains(sel.focusNode))
      ) {
        return undefined;
      } else {
        const found: any = [0, 0];
        let i: number;
        this.nodeWalk(elem, function (node: any): any {
          // eslint-disable-next-line no-magic-numbers
          for (i = 0; i < 2; i++) {
            // eslint-disable-next-line eqeqeq
            if (node == nodesToFind[i]) {
              found[i] = true;
              // eslint-disable-next-line eqeqeq
              if (found[i == 0 ? 1 : 0]) {
                return false; // all done
              }
            }
          }

          if (node.textContent && !node.firstChild) {
            // eslint-disable-next-line no-magic-numbers
            for (i = 0; i < 2; i++) {
              if (!found[i]) {
                cLength[i] += node.textContent.length;
              }
            }
          }
        });
        cLength[0] += sel.anchorOffset;
        cLength[1] += sel.extentOffset || sel.focusOffset || 0;
      }
    }
    if (cLength[0] <= cLength[1]) {
      return cLength;
    }
    return [cLength[1], cLength[0]];
  }
}

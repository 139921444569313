import { parseNumber } from '../functions/utilities';
import { Position2D } from './position-2d';
import {
  EmptyPositionVolatileData,
  Position2DNodeRef
} from './position-2d-node-ref';

// eslint-disable-next-line @typescript-eslint/ban-types
export class Position2DGridManager<NodeRefVolatileData extends EmptyPositionVolatileData = {}> {
  private _nodesByLinkedId: { [key: string]: Position2DNodeRef<NodeRefVolatileData> } = {};
  private _nodeRefsFlattened: Position2DNodeRef<NodeRefVolatileData>[] = [];
  private _nodeRefs: Position2DNodeRef<NodeRefVolatileData>[][] = [];
  private _highestX?: number;
  private _highestY?: number;

  public width: number = 1;
  public height: number = 1;
  public nodes: Position2DNodeRef<NodeRefVolatileData>[] = [];

  constructor(params?: Partial<Position2DGridManager<NodeRefVolatileData>>) {
    if (!!params) {
      this.width = parseNumber(params.width, this.width);
      this.height = parseNumber(params.height, this.height);
      this.nodes = Array.isArray(params.nodes)
        ? params.nodes.map(n => new Position2DNodeRef<NodeRefVolatileData>(n))
        : this.nodes;
    }
    this._rebuildGrid(this.width, this.height, this.nodes);
    this._updateNodeRefs();
  }

  public get asArray(): Position2DNodeRef<NodeRefVolatileData>[] {
    return this._nodeRefsFlattened;
  }

  public get highestNodeXPosition(): number {
    return this._highestX ?? 0;
  }

  public get highestNodeYPosition(): number {
    return this._highestY ?? 0;
  }

  public adjustGridSize({
    width = 0,
    height = 0,
  }: Partial<{ width: number; height: number }>): void {
    if (width === 0 && height === 0) return;
    // Ensure our grid is always 1x1
    this.width = Math.max(1, this.width + width);
    this.height = Math.max(1, this.height + height);
    this._rebuildGrid(this.width, this.height, this.nodes);
    this._updateNodeRefs();
  }

  public getNode(position: Position2D): Position2DNodeRef<NodeRefVolatileData> | null {
    return this._nodeRefs[position.y][position.x] ?? null;
  }

  public clearNode(position: Position2D): void {
    let requiresUpdate = false;
    const node = this.getNode(position);
    if (!node) return;
    const nodeIndex = this.nodes.findIndex(n => n.id === node.id);
    if (nodeIndex > -1) {
      this.nodes = [...this.nodes.slice(0, nodeIndex), ...this.nodes.slice(nodeIndex + 1)];
      requiresUpdate = true;
    }
    const nodeRef = this._nodeRefs[position.y]?.[position.x];
    if (!!nodeRef) {
      const spanToReset = nodeRef.span;
      this._nodeRefs[position.y][position.x] = new Position2DNodeRef({
        ...nodeRef,
        linkedId: '',
        span: 1,
      });
      if (spanToReset > 1) {
        for (let i = 1; i < node.span; i++) {
          if (position.x + i < this.width) {
            this._nodeRefs[position.y][position.x + i] = new Position2DNodeRef({
              ...this._nodeRefs[position.y][position.x + i],
              linkedId: '',
              span: 1,
            });
          }
        }
      }
      requiresUpdate = true;
    }
    if (requiresUpdate) {
      this._updateNodeRefs();
    }
  }

  public getNodeByLinkedId(linkedId: string): Position2DNodeRef<NodeRefVolatileData> | null {
    return this._nodesByLinkedId[linkedId] ?? null;
  }

  public addNode(nodeRef: Position2DNodeRef<NodeRefVolatileData>): void;
  public addNode(position: Position2D, linkedId: string, span?: number): void;
  public addNode(
    positionOrRef: Position2D | Position2DNodeRef<NodeRefVolatileData>,
    linkedId?: string,
    span: number = 1
  ): void {
    const isNodeRef = positionOrRef instanceof Position2DNodeRef;
    if (isNodeRef) {
      const nodeRef = positionOrRef as Position2DNodeRef<NodeRefVolatileData>;
      this._throwIfInvalidPosition(nodeRef.position);
      const { nodeIdx } = this._getNodeAtPosition(nodeRef.position);
      if (nodeIdx > -1) {
        // Replace node instead of add
        this.nodes = [...this.nodes.slice(0, nodeIdx), nodeRef, ...this.nodes.slice(nodeIdx + 1)];
      } else {
        this.nodes.push(nodeRef);
      }
    } else {
      const position = positionOrRef as Position2D;
      this._throwIfInvalidPosition(position);
      const node = new Position2DNodeRef<NodeRefVolatileData>({
        position: position,
        linkedId,
        span,
      });
      const { nodeIdx } = this._getNodeAtPosition(position);
      if (nodeIdx > -1) {
        // Replace node instead of add
        this.nodes = [...this.nodes.slice(0, nodeIdx), node, ...this.nodes.slice(nodeIdx + 1)];
      } else {
        this.nodes.push(node);
      }
    }
    this._updateNodeRefs();
  }

  public expandNode(position: Position2D, amount: number): void {
    // Clamp expand command to at least 1
    amount = Math.max(1, amount);
    const node = this.getNode(position);
    if (!node) throw new Error(`No node found at position ${position.x},${position.y} to expand`);
    // Clamp actual expansion to no more than the max width of the grid
    node.span = Math.min(this.width - position.x, node.span + amount);
    this._updateNodeRefs();
  }

  public contractNode(position: Position2D, amount: number): void {
    throw new Error('Not Implemented');
    // this._updateNodeRefs();
  }

  private _updateNodeRefs(): void {
    this._nodesByLinkedId = {};
    this._highestX = 0;
    this._highestY = 0;
    this.nodes.forEach(node => {
      const position = node.position;
      this._nodeRefs[position.y][position.x] = node;
      if (node.span > 1) {
        for (let i = 1; i < node.span; i++) {
          if (position.x + i < this.width) {
            this._nodeRefs[position.y][position.x + i] = new Position2DNodeRef({
              ...this._nodeRefs[position.y][position.x + i],
              linkedId: node.linkedId,
              span: node.span,
            });
          }
        }
      }
      this._nodesByLinkedId[node.linkedId];
      this._highestX = Math.max(this.highestNodeXPosition, node.position.x);
      this._highestY = Math.max(this.highestNodeYPosition, node.position.y);
    });
    this._nodeRefsFlattened = [];
    this._nodeRefs.forEach(row => {
      let spanThisManyNodes = 0;
      row.forEach(node => {
        if (spanThisManyNodes > 0) {
          spanThisManyNodes--;
        } else {
          this._nodeRefsFlattened.push(node);
          spanThisManyNodes = node.span - 1; // Span is not 0 based
        }
      });
    });
  }

  private _rebuildGrid(
    width: number,
    height: number,
    initialNodes: Position2DNodeRef<NodeRefVolatileData>[]
  ): void {
    const initialNodeRefsByPosition: Position2DNodeRef<NodeRefVolatileData>[][] = [];
    initialNodes.forEach(node => {
      this._throwIfInvalidPosition(node.position);
      if (!initialNodeRefsByPosition[node.position.y]) {
        initialNodeRefsByPosition[node.position.y] = [];
      }
      initialNodeRefsByPosition[node.position.y][node.position.x] = node;
    });
    this._nodeRefs = [];
    for (let row = 0; row < height; row++) {
      this._nodeRefs[row] = [];
      for (let column = 0; column < width; column++) {
        this._nodeRefs[row][column] =
          initialNodeRefsByPosition[row]?.[column] ??
          new Position2DNodeRef<NodeRefVolatileData>({
            position: new Position2D(column, row),
          });
      }
    }
  }

  private _getNodeAtPosition(position: Position2D): {
    nodeIdx: number;
    node: Position2DNodeRef<NodeRefVolatileData> | null;
  } {
    const nodeIdx = this.nodes.findIndex(
      node => node.position.x === position.x && node.position.y === position.y
    );
    if (nodeIdx > -1) return { nodeIdx, node: this.nodes[nodeIdx] };
    return {
      nodeIdx: -1,
      node: null,
    };
  }

  private _throwIfInvalidPosition(position: Position2D): void {
    if (position.x > this.width - 1 || position.y > this.height - 1) {
      throw new Error(
        'Node is out of bounds: ' + [position.x, this.width - 1, position.y, this.height - 1].join(', ')
      );
    }
  }
}

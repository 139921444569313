import { Injectable } from '@angular/core';
import { HttpService, Post, Body, Get, MapValue, Header } from '@rpg/ngx/http';
import { EMPTY, Observable } from 'rxjs';
import { AuthChangeEvent, Session } from '@supabase/supabase-js';
import { User } from '@rpg/core/user';
import { Permission } from '@rpg/core/base';

interface UserWithToken {
  user: User;
  token: string;
  permissions: Permission[];
}

function mapUserToken(val: UserWithToken): UserWithToken {
  return {
    user: new User(val.user),
    token: val.token,
    permissions: Array.isArray(val.permissions) ? val.permissions : [],
  };
}

@Injectable({
  providedIn: 'root',
})
export class Auth2HttpService extends HttpService {
  @Post('/auth2/legacy/link')
  legacyLink(): Observable<boolean> {
    return EMPTY;
  }

  @Post('/auth2/legacy/login')
  legacyLogin(@Body('id') id: string, @Body('key') key: string): Observable<boolean> {
    return EMPTY;
  }

  @Post('/auth2/legacy/register')
  legacyRegister(@Body('email') email: string, @Body('username') username: string) {
    return EMPTY;
  }

  @Post('/auth2/state')
  updateAuthState(
    @Body() data: { event: AuthChangeEvent; session: Session | null }
  ): Observable<any> {
    return EMPTY;
  }

  @Get('/auth2/refresh')
  @MapValue(mapUserToken)
  public refreshToken(@Header('Authorization') token: string): Observable<UserWithToken> {
    return EMPTY;
  }

  @Post('/auth2/fromSupabase')
  @MapValue(mapUserToken)
  fromSupabase(@Body('accessToken') accessToken: string): Observable<UserWithToken> {
    return EMPTY;
  }
}

import {
  Constraints,
  parseBoolean,
  parseEnum,
  parseNumber,
  RpgClassValidators,
} from '@rpg/core/base';
import { NdsCharacterCharacteristic } from '../enums';

export const NDS_CHARACTER_CHARACTERISTIC_DEFAULT_VALUE = 2;
export const NDS_CHARACTER_CHARACTERISTIC_MIN_VALUE = 0;
export const NDS_CHARACTER_CHARACTERISTIC_MAX_VALUE = 9;

export class NdsCharacterCharacteristicData {
  public type!: NdsCharacterCharacteristic;
  public value: number = NDS_CHARACTER_CHARACTERISTIC_DEFAULT_VALUE;
  public superCharacteristic: boolean = false;

  constructor(params?: Partial<NdsCharacterCharacteristicData>) {
    if (!!params) {
      this.type = parseEnum(NdsCharacterCharacteristic, params.type, this.type);
      this.value = parseNumber(params.value, this.value);
      this.superCharacteristic = parseBoolean(params.superCharacteristic, this.superCharacteristic);
    }
    // enforce constraints
    this.value = Constraints.withinRange(
      this.value,
      NDS_CHARACTER_CHARACTERISTIC_MIN_VALUE,
      NDS_CHARACTER_CHARACTERISTIC_MAX_VALUE
    );
  }

  public static validate(input: NdsCharacterCharacteristicData): string[] {
    const errors: string[] = [];
    const name = `Characteristic ${input.type ? `(${input.type}) ` : ''}`;
    errors.push(
      ...RpgClassValidators.enum(input.type, NdsCharacterCharacteristic, {
        enumName: 'Character Characteristic',
        fieldName: `${name} Characteristic`,
      })
    );
    errors.push(...RpgClassValidators.number(input.value, { fieldName: `${name} Value` }));
    errors.push(
      ...RpgClassValidators.boolean(input.superCharacteristic, {
        fieldName: `${name} Super Characteristic`,
      })
    );
    return errors;
  }
}

import { ObjectId } from 'bson';
import { parseObjectId } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';

export class GamePassedDiceRef {
  public characterId!: ObjectId;
  public dice: DiceType[] = [];

  constructor(params?: Partial<GamePassedDiceRef>) {
    if (!!params) {
      this.characterId = parseObjectId(params.characterId, this.characterId);
      this.dice = Array.isArray(params.dice) ? params.dice : this.dice;
    }
  }
}

import produce from 'immer';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CookieKey } from '@rpg/core/base';
import { CookieService } from '@rpg/ngx/core';
import { Auth3Actions } from './auth3.actions';

export interface Auth3StateModel {
  authToken: string;
}

@State<Auth3StateModel>({
  name: 'auth3',
  defaults: {
    authToken: '',
  },
})
@Injectable()
export class Auth3State {
  constructor(private _cookieService: CookieService) {}

  @Selector([Auth3State])
  public static isAuthenticated(auth: Auth3StateModel): boolean {
    return !!auth.authToken;
  }

  @Selector([Auth3State])
  public static authToken(auth: Auth3StateModel): string {
    return auth.authToken;
  }

  @Action(Auth3Actions.SetToken)
  public setToken(
    { setState }: StateContext<Auth3StateModel>,
    { token }: Auth3Actions.SetToken
  ): void {
    this._cookieService.set(CookieKey.AuthToken, token);
    setState(
      produce(draft => {
        draft.authToken = token;
        return draft;
      })
    );
  }

  @Action(Auth3Actions.ClearToken)
  public clearToken({ setState }: StateContext<Auth3StateModel>): void {
    this._cookieService.delete(CookieKey.AuthToken);
    setState(
      produce(draft => {
        draft.authToken = '';
        return draft;
      })
    );
  }
}

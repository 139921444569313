import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { Logger } from '@rpg/core/base';

// Declare window variable
// eslint-disable-next-line
var gapi: any;

@Injectable({
  providedIn: 'root',
})
export class AuthGoogleService {
  private _loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _gapi: any;
  private _gauth: any;

  public loaded$: Observable<boolean> = this._loaded.asObservable();

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}

  public async init(): Promise<boolean> {
    const loaded = this._loaded.value;
    if (!loaded) {
      return (await this._initClient()) && (await this._initAuth2());
    } else {
      return true;
    }
  }

  public login(): Observable<any> {
    return from(this._signin());
  }

  public logout(): Observable<any> {
    return from(this._gauth.signOut());
  }

  public isLoggedIn(): boolean {
    return this._gauth.isSignedIn.get();
  }

  public getUser(): any {
    return this._gauth.currentUser.get();
  }

  private async _initClient(): Promise<boolean> {
    if (!!this._gapi) {
      return true;
    } else if (isPlatformBrowser(this._platformId)) {
      this._gapi = (window as any)['gapi'];
      if (!!this._gapi && this._gapi.load) {
        return new Promise((resolve, reject): void => {
          this._gapi.load('auth2', () => {
            if (!!this._gapi.auth2) {
              resolve(true);
            } else {
              Logger.error('GApi auth2 not loaded');
              reject(false);
            }
          });
        });
      } else {
        Logger.error('GApi not loaded');
        return false;
      }
    } else {
      Logger.error('Not platform browser');
      return false;
    }
  }

  private async _initAuth2(): Promise<boolean> {
    if (!!this._gauth) {
      this._loaded.next(true);
      return true;
    } else {
      try {
        this._gauth = await this._gapi.auth2.init({
          client_id: '96787676147-jnh8ud5r5ckg46dve5f1svttfmql1h96.apps.googleusercontent.com',
        });
        this._loaded.next(true);
        return true;
      } catch (e) {
        Logger.error('Unable to init GAuth:', e);
        return false;
      }
    }
  }

  private async _signin(): Promise<string> {
    const user = await this._gauth.signIn();
    if (!!user) {
      const authRes = user.getAuthResponse();
      if (!!authRes && !!authRes.id_token) {
        return authRes.id_token;
      }
    }
    throw new Error('Unable to authenticate with Google');
  }
}

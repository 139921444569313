import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsCharacterTalentListGroupData } from '@rpg/core/character';

export function ndsCharacterTalentListGroupToFormGroup(
  listGroup: NdsCharacterTalentListGroupData
): FormGroup<NdsCharacterTalentListGroupData> {
  return new FormGroup<NdsCharacterTalentListGroupData & Expandable>({
    expanded: new FormControl((listGroup as any)['expanded'] ?? false),
    id: new FormControl(listGroup.id),
    name: new FormControl(listGroup.name, [Validators.required]),
    talentIds: new FormControl(listGroup.talentIds),
  });
}

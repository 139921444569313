export enum FileType {
  Folder = '[file type] folder',
  Character = '[file type] character',
  Game = '[file type] game',
  Vehicle = '[file type] vehicle',
}

export namespace FileType {
  export const members: FileType[] = [
    FileType.Folder,
    FileType.Character,
    FileType.Game,
    FileType.Vehicle,
  ];
}

import { MongoDoc } from '@rpg/core/base';
import { PatreonReward, PatreonTier } from '@rpg/core/patreon';
import { UserTableTokenRef } from '@rpg/core/table-token';
import { UserLogin } from './user-login';
import { UserSettings } from './user-settings';

export const USERNAME_MIN_LENGTH = 3;

export class User extends MongoDoc {
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public username: string = '';
  public profileImage: string = '';
  public isVerified: boolean = false;
  public settings: UserSettings = new UserSettings();
  public logins: UserLogin[] = [];
  public tableTokens: UserTableTokenRef[] = [];
  public supabaseId?: string;

  constructor(params?: Partial<User>) {
    super(params);
    if (!!params) {
      this.email = params.email || this.email;
      this.firstName = params.firstName || this.firstName;
      this.lastName = params.lastName || this.lastName;
      this.username = params.username || this.username;
      this.profileImage = params.profileImage || this.profileImage;
      this.isVerified = params.isVerified || this.isVerified;
      this.settings = !!params.settings ? new UserSettings(params.settings) : this.settings;
      this.logins = Array.isArray(params.logins)
        ? params.logins.map(l => new UserLogin(l))
        : this.logins;
      this.tableTokens = Array.isArray(params.tableTokens)
        ? params.tableTokens.map(t => new UserTableTokenRef(t))
        : this.tableTokens;
      this.supabaseId = params.supabaseId ?? this.supabaseId;
    }
  }

  public hasReward(reward: PatreonReward): boolean {
    const tier = this.settings?.patreonLink?.currentTier ?? PatreonTier.None;
    return PatreonTier.hasReward(tier, reward);
  }
}

import { normalizeString } from './normalize-string';

const SLUG_MIN_DIGIT = 1000;
const SLUG_MAX_DIGIT = 9999;

export function createSlug(text: string): string {
  const randomId = Math.floor(
    Math.random() * (SLUG_MAX_DIGIT - SLUG_MIN_DIGIT + 1) + SLUG_MIN_DIGIT
  );
  const normalized = normalizeString(text);
  if (normalized.length < 1 || text == null) {
    return randomId.toString();
  }
  return `${normalized
    .replace(/ /g, '-')
    .replace(/[-]+/g, '-')
    .replace(/[^\w- ]+/g, '')}-${randomId}`;
}

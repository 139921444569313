<ng-container *rxLet="state$; let state">
  <img class="attribute-double__image" [src]="state.attrImage" />
  <label class="attribute-double__title">
    {{ state.title }}
  </label>
  <ng-container *ngIf="state.editMode; else displayMode">
    <span
      class="attribute-double__input"
      rpgContentEditableNumber
      contentEditable
      [formControl]="controls[0]"
      (blur)="checkValue(controls[0])"
      >{{ controls[0].value }}</span
    >
    <span
      class="attribute-double__input"
      rpgContentEditableNumber
      contentEditable
      [formControl]="controls[1]"
      (blur)="checkValue(controls[1])"
      >{{ controls[1].value }}</span
    >
  </ng-container>
  <ng-template #displayMode>
    <span class="attribute-double__input">
      {{ state.resolvedValues[0] }} <ng-content select=".slot-1"></ng-content>
    </span>
    <span class="attribute-double__input">
      {{ state.resolvedValues[1] }} <ng-content select=".slot-2"></ng-content>
    </span>
  </ng-template>
  <div class="attribute-double__label-container" [class.use-dark-text]="state.textMode === 'dark'">
    <label class="attribute-double__label">
      {{ state.labels[0] }}
    </label>
    <label class="attribute-double__label">
      {{ state.labels[1] }}
    </label>
  </div>
</ng-container>

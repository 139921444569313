import { Injectable } from '@angular/core';
import { RpgSelectOption } from '@rpg/core/base';
import { ImportSource } from '@rpg/core/imports';
import { Body, Delete, Get, HttpService, Post, Query } from '@rpg/ngx/http';
import { ObjectId } from 'bson';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImportHttpService extends HttpService {
  @Get('/import/limit')
  public getImportLimits(): Observable<{
    limit: number;
    current: number;
    hasCustomData: boolean;
  }> {
    return EMPTY;
  }

  public importEmporiumCharacter(
    importFile: File,
    characterId?: string,
    parentFolderId?: ObjectId
  ): Observable<any> {
    const endpoint = '/import/emporium';
    const formData: FormData = new FormData();
    formData.append('file', importFile, importFile.name);
    if (characterId) {
      formData.append('characterId', characterId);
    }
    if (parentFolderId) {
      formData.append('parentFolderId', parentFolderId.toHexString());
    }

    return this._http.post<string[]>(endpoint, formData);
  }

  public importOggDudeCharacter(
    importFile: File,
    characterId?: string,
    parentFolderId?: ObjectId
  ): Observable<any> {
    const endpoint = '/import/oggdude';
    const formData: FormData = new FormData();
    formData.append('file', importFile, importFile.name);
    if (characterId) {
      formData.append('characterId', characterId);
    }
    if (parentFolderId) {
      formData.append('parentFolderId', parentFolderId.toHexString());
    }

    return this._http.post<string[]>(endpoint, formData);
  }

  public importOggDudeCustomData(importFile: File): Observable<any> {
    const endpoint = '/import/oggdude/custom';
    const formData: FormData = new FormData();
    formData.append('file', importFile, importFile.name);
    return this._http.post<string[]>(endpoint, formData);
  }

  @Delete('/import/oggdude/custom')
  public deleteCustomOggDudeData(): Observable<boolean> {
    return EMPTY;
  }

  @Get('/import/npc/list')
  public loadNpcData(
    @Query('importType') importType: ImportSource
  ): Observable<RpgSelectOption<string>[]> {
    return EMPTY;
  }

  @Post('/import/npc')
  public importNpcData(
    @Body('importType') importType: ImportSource,
    @Body('name') name: string,
    @Body('parentFolderId') parentFolderId?: ObjectId
  ): Observable<any> {
    return EMPTY;
  }
}

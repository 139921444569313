import { createId, parseEnum, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterModifierType } from '../../enums';

export class NdsCharacterModifierData {
  public id: string = '';
  public type: NdsCharacterModifierType = NdsCharacterModifierType.Narrative;
  public name: string = '';
  public description: string = '';

  constructor(params?: Partial<NdsCharacterModifierData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.type = parseEnum(NdsCharacterModifierType, params.type, this.type);
      this.name = params.name ?? this.name;
      this.description = params.description ?? this.description;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsCharacterModifierData, fieldNamePrefix: string = ''): string[] {
    const errors: string[] = [];
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Modifier ${
      input.name ? `(${input.name})` : ''
    }`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(
      ...RpgClassValidators.enum(input.type, NdsCharacterModifierType, {
        enumName: 'Modifier Type',
        fieldName: `${name} type`,
      })
    );
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    return errors;
  }
}

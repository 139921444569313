import { MongoDoc, parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { MessageActionRef } from './message-action-ref';
import formatDistance from 'date-fns/formatDistance';
import { enUS, es, fr } from 'date-fns/locale';

const locales = {
  fr,
  en: enUS,
  es,
};

export class GameTableMessage extends MongoDoc {
  public userId: ObjectId | null = null;
  public gameId: ObjectId = new ObjectId();
  public message: string = '';
  public isGM: boolean = false;
  public ephemeralSessionID: string = '';
  public messageActionRef: MessageActionRef = new MessageActionRef();

  constructor(params?: Partial<GameTableMessage>) {
    super(params);
    if (!!params) {
      this.userId = parseObjectId(params.userId);
      this.gameId = parseObjectId(params.gameId, this.gameId);
      this.message = params.message ?? this.message;
      this.isGM = params.isGM ?? this.isGM;
      this.ephemeralSessionID = params.ephemeralSessionID ?? this.ephemeralSessionID;
      if (!!params.messageActionRef) {
        this.messageActionRef = new MessageActionRef(params.messageActionRef);
      }
    }
  }

  public createdDateFormatted(locale: string = 'en'): string {
    if (!!this.createdDate) {
      return formatDistance(this.createdDate, new Date(), {
        addSuffix: true,
        locale: (locales as any)[locale] || enUS,
      });
    }

    return '';
  }
}

import { DataType, RulesSystem } from '@rpg/core/base';
import { CritTable, CritTableRow } from '@rpg/core/crit';

// eslint:disable
export const GENCharacterCritTable: CritTable = new CritTable({
  dataType: DataType.Character,
  description: 'FFG Genesys Character Crit Table',
  rulesSystem: RulesSystem.NarrativeDiceSystem,
  name: 'FFG Genesys Character Crit Table',
  status: 1,
  userId: null,
  rows: [
    new CritTableRow({
      id: 1,
      name: 'Minor Nick',
      description: 'The target suffers 1 strain',
      difficulty: 1,
      min: 0,
      max: 5,
    }),
    new CritTableRow({
      id: 2,
      name: 'Slowed Down',
      description:
        'The target can only act during the last allied initiative slot on their next turn',
      difficulty: 1,
      min: 6,
      max: 10,
    }),
    new CritTableRow({
      id: 3,
      name: 'Sudden Jolt',
      description: 'The target drops whatever is in hand',
      difficulty: 1,
      min: 11,
      max: 15,
    }),
    new CritTableRow({
      id: 4,
      name: 'Distracted',
      description: 'The target cannot perform a free maneuver during their next turn',
      difficulty: 1,
      min: 16,
      max: 20,
    }),
    new CritTableRow({
      id: 5,
      name: 'Off-Balance',
      description: "Add :eK: to the target's next skill check",
      difficulty: 1,
      min: 21,
      max: 25,
    }),
    new CritTableRow({
      id: 6,
      name: 'Discouraging Wound',
      description: 'Move one player pool Story Point to the Game Master pool (reverse if NPC)',
      difficulty: 1,
      min: 26,
      max: 30,
    }),
    new CritTableRow({
      id: 7,
      name: 'Stunned',
      description: 'The target is staggered until the end of their next turn',
      difficulty: 1,
      min: 31,
      max: 35,
    }),
    new CritTableRow({
      id: 8,
      name: 'Stinger',
      description: "Increase the difficulty of the target's next check by one",
      difficulty: 1,
      min: 36,
      max: 40,
    }),
    new CritTableRow({
      id: 9,
      name: 'Bowled Over',
      description: 'The target is knocked prone and suffers 1 strain',
      difficulty: 2,
      min: 41,
      max: 45,
    }),
    new CritTableRow({
      id: 10,
      name: 'Head Ringer',
      description:
        'The target increases the difficulty of all Intellect and Cunning checks by one until this Critical Injury is healed',
      difficulty: 2,
      min: 46,
      max: 50,
    }),
    new CritTableRow({
      id: 11,
      name: 'Fearsome Wound',
      description:
        'The target increases the difficulty of all Presence and Willpower checks by one until this Critical Injury is healed',
      difficulty: 2,
      min: 51,
      max: 55,
    }),
    new CritTableRow({
      id: 12,
      name: 'Agonizing Wound',
      description:
        'The target increases the difficulty of all Brawn and Agility checks by one until this Critical Injury is healed',
      difficulty: 2,
      min: 56,
      max: 60,
    }),
    new CritTableRow({
      id: 13,
      name: 'Slightly Dazed',
      description: 'The target is disoriented until this Critical Injury is healed',
      difficulty: 2,
      min: 61,
      max: 65,
    }),
    new CritTableRow({
      id: 14,
      name: 'Scattered Senses',
      description:
        'The target removes all :eB: from skill checks until this Critical Injury is healed',
      difficulty: 2,
      min: 66,
      max: 70,
    }),
    new CritTableRow({
      id: 15,
      name: 'Hamstrung',
      description: 'The target loses their free maneuver until this Critical Injury is healed',
      difficulty: 2,
      min: 71,
      max: 75,
    }),
    new CritTableRow({
      id: 16,
      name: 'Overpowered',
      description:
        'The target leaves themselves open, and the attacker may immediately attempt another free attack against them, using the exact same pool as the original attack',
      difficulty: 2,
      min: 76,
      max: 80,
    }),
    new CritTableRow({
      id: 17,
      name: 'Winded',
      description:
        'the target cannot voluntarily suffer strain to activate any abilities or gain additional maneuvers until this Critical Injury is healed',
      difficulty: 2,
      min: 81,
      max: 85,
    }),
    new CritTableRow({
      id: 18,
      name: 'Compromised',
      description:
        'Increase the difficulty of all skill checks by one until this Critical Injury is healed',
      difficulty: 2,
      min: 86,
      max: 90,
    }),
    new CritTableRow({
      id: 19,
      name: 'At the Brink',
      description:
        'The target suffers 2 strain each time they perform an action until this Critical Injury is healed',
      difficulty: 3,
      min: 91,
      max: 95,
    }),
    new CritTableRow({
      id: 20,
      name: 'Crippled',
      description:
        "One of the target's limbs (selected by the GM) is impaired until this Critical Injury is healed. Increase difficulty of all checks that require use of that limb by one",
      difficulty: 3,
      min: 96,
      max: 100,
    }),
    new CritTableRow({
      id: 21,
      name: 'Maimed',
      description:
        "One of the target's limbs (selected by the GM) is permanently lost. Unless the target has a cybernetic or prosthetic replacement, the target cannot perform actions that would require the use of that limb. All other actions gain :eK: until this Critical Injury is healed",
      difficulty: 3,
      min: 101,
      max: 105,
    }),
    new CritTableRow({
      id: 22,
      name: 'Horrific Injury',
      description:
        "Roll 1d10 to determine which of the target's characteristics is affected: 1-3 for Brawn, 4-6 for Agility, 7 for Intellect, 8 for Cunning, 9 for Presence, 10 for Willpower. Until this Critical Injury is healed, treat that characteristic as 1 point lower",
      difficulty: 3,
      min: 106,
      max: 110,
    }),
    new CritTableRow({
      id: 23,
      name: 'Temporarily Disabled',
      description: 'The target is immobilized until this Critical Injury is healed',
      difficulty: 3,
      min: 111,
      max: 115,
    }),
    new CritTableRow({
      id: 24,
      name: 'Blinded',
      description:
        'The target can no longer see. Upgrade the difficulty of checks twice. Upgrade the difficulty of Perception and Vigilance checks three times until this Critical Injury is healed',
      difficulty: 3,
      min: 116,
      max: 120,
    }),
    new CritTableRow({
      id: 25,
      name: 'Knocked Senseless',
      description: 'The target is staggered until this Critical Injury is healed',
      difficulty: 3,
      min: 121,
      max: 125,
    }),
    new CritTableRow({
      id: 26,
      name: 'Gruesome Injury',
      description:
        "Roll 1d10 to determine which of the target's characteristics is affected: 1-3 for Brawn, 4-6 for Agility, 7 for Intellect, 8 for Cunning, 9 for Presence, 10 for Willpower. That characteristic is permanently reduced by 1, to a minimum of 1",
      difficulty: 4,
      min: 126,
      max: 130,
    }),
    new CritTableRow({
      id: 27,
      name: 'Bleeding Out',
      description:
        'Every round, the target suffers 1 wound and 1 strain at the beginning of their turn. For every 5 wounds they suffer beyond their wound threshold, they suffer 1 additional Critical Injury. Roll on the chart, suffering the injury (if they would suffer this result a second time due to this, roll again)',
      difficulty: 4,
      min: 131,
      max: 140,
    }),
    new CritTableRow({
      id: 28,
      name: 'The end is Nigh',
      description:
        'The target dies after the last initiative slot during the next round, unless this Critical Injury is healed',
      difficulty: 4,
      min: 141,
      max: 150,
    }),
    new CritTableRow({
      id: 29,
      name: 'Dead',
      description: 'Complete, obliterated death',
      difficulty: 0,
      min: 151,
      max: null,
    }),
  ],
});

export const GENVehicleCritTable: CritTable = new CritTable({
  dataType: DataType.Vehicle,
  description: 'FFG Genesys Vehicle Crit Table',
  rulesSystem: RulesSystem.NarrativeDiceSystem,
  name: 'FFG Genesys Vehicle Crit Table',
  status: 1,
  userId: null,
  rows: [
    new CritTableRow({
      id: 1,
      name: 'Rattled',
      description:
        'The vehicle suffers 3 system strain, and its pilot and each occupant suffers 3 strain',
      difficulty: 1,
      min: 0,
      max: 18,
    }),
    new CritTableRow({
      id: 2,
      name: 'Shrapnel Spray',
      description:
        'Chunks of metal or wood are hurled at the occupants at deadly velocity. The pilot and occupants must each make a Hard ( :gD: :gD: :gD: ) Resilience or Vigilance check or suffer 1 wound, plus 1 additional wound per failure on the check; You may spend 3 threat ( :g%: :g%: :g%: ) or 1 despair ( :g$: ) to inflict a Critical Injury on the character',
      difficulty: 1,
      min: 19,
      max: 36,
    }),
    new CritTableRow({
      id: 3,
      name: 'Hull Damaged',
      description: "The vehicle's hull is compromised (See CRB-221)",
      difficulty: 1,
      min: 37,
      max: 54,
    }),
    new CritTableRow({
      id: 4,
      name: 'Navigation Damaged',
      description: "The vehicle's navigation is compromised (See CRB-221)",
      difficulty: 2,
      min: 55,
      max: 63,
    }),
    new CritTableRow({
      id: 5,
      name: 'Propulsion Damaged',
      description: "The vehicle's propulsion is compromised (See CRB-221)",
      difficulty: 2,
      min: 64,
      max: 72,
    }),
    new CritTableRow({
      id: 6,
      name: 'Defenses Damaged',
      description: "The vehicle's defenses are compromised (See CRB-221)",
      difficulty: 2,
      min: 73,
      max: 81,
    }),
    new CritTableRow({
      id: 7,
      name: 'Weapons Damaged',
      description:
        "One of the vehicle's weapons of the attacker's choice is compromised (See CRB-221)",
      difficulty: 3,
      min: 82,
      max: 108,
    }),
    new CritTableRow({
      id: 8,
      name: 'Brakes Damaged',
      description: "The vehicle's brakes are compromised (See CRB-221)",
      difficulty: 3,
      min: 109,
      max: 126,
    }),
    new CritTableRow({
      id: 9,
      name: 'All Systems Down',
      description: "All of the vehicle's components are compromised (See CRB-221)",
      difficulty: 4,
      min: 127,
      max: 138,
    }),
    new CritTableRow({
      id: 10,
      name: 'Fire!',
      description:
        'The vehicle catches on fire. While the vehicle is on fire, each occupant suffers damage as discussed on CRB-111. A fire can be put out with a Hard ( :gD: :gD: :gD: ) Cool or Athletics check (or multiple checks for big vehicles)',
      difficulty: 4,
      min: 139,
      max: 144,
    }),
    new CritTableRow({
      id: 11,
      name: 'Breaking Up',
      description:
        'The vehicle beings to come apart at its seams, disintegrating around the occupants. At the end of the following round, it is completely destroyed, and the surrounding environment is littered with debris. Anyone aboard the vehicle has one round to dive for the nearest door before they are lost.',
      difficulty: 4,
      min: 144,
      max: 153,
    }),
    new CritTableRow({
      id: 12,
      name: 'Vaporized',
      description:
        'The vehicle is completely destroyed, consumed in a large and dramatic fireball. Nothing survives',
      difficulty: 0,
      min: 154,
      max: null,
    }),
  ],
});

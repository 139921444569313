import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterHeroicAbilityLevel } from '@rpg/core/character';
import { NDSCharacterModifierFormGroupFactory } from './modifiers';

export function ndsCharacterHeroicAbilityLevelToFormGroup(
  level: NdsCharacterHeroicAbilityLevel
): FormGroup<NdsCharacterHeroicAbilityLevel> {
  return new FormGroup<NdsCharacterHeroicAbilityLevel>({
    description: new FormControl(level.description),
    modifiers: new FormArray(level.modifiers.map(m => NDSCharacterModifierFormGroupFactory(m))),
    purchased: new FormControl(level.purchased),
  });
}

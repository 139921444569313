import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';

const ROOT_KEY = 'material.paginator';

export class PaginatorInternationalizationService extends MatPaginatorIntl {
  public itemsPerPageLabel: string = 'Items per page';
  public nextPageLabel: string = 'Next page';
  public lastPageLabel: string = 'Last page';
  public firstPageLabel: string = 'First page';
  public previousPageLabel: string = 'Previous page';
  private _translocoService!: TranslocoService;

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return this._translocoService.translate(
        `${ROOT_KEY}.items-left-display-when-no-other-pages`,
        {
          length,
        }
      );
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this._translocoService.translate(`${ROOT_KEY}.items-left-when-other-pages-exist`, {
      startIndex: startIndex + 1,
      endIndex,
      length,
    });
    // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
  };

  public injectTranslateService(translocoService: TranslocoService): void {
    this._translocoService = translocoService;

    this._translocoService.langChanges$.subscribe(x => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  public async translateLabels(): Promise<void> {
    // By using selectTranslate, we're waiting for the translation
    // file to be loaded first.
    this.itemsPerPageLabel = await firstValueFrom(
      this._translocoService.selectTranslate(`${ROOT_KEY}.items-per-page`)
    );
    this.nextPageLabel = await firstValueFrom(
      this._translocoService.selectTranslate(`${ROOT_KEY}.next-page`)
    );
    this.previousPageLabel = await firstValueFrom(
      this._translocoService.selectTranslate(`${ROOT_KEY}.previous-page`)
    );
    this.lastPageLabel = await firstValueFrom(
      this._translocoService.selectTranslate(`${ROOT_KEY}.last-page`)
    );
    this.firstPageLabel = await firstValueFrom(
      this._translocoService.selectTranslate(`${ROOT_KEY}.first-page`)
    );
  }
}

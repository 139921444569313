import { DiceType } from './dice-type';

export enum DiceInputColorScheme {
  Proficiency = 'y',
  Ability = 'g',
  Boost = 'b',
  Challenge = 'r',
  Difficulty = 'p',
  Setback = 'k',
  RemoveSetback = 'x',
  Force = 'w',
  Triumph = '!',
  Success = '*',
  Advantage = '^',
  Despair = '$',
  Failure = '-',
  Threat = '%',
  PositiveUpgrade = 'u',
  PositiveIncrease = 'i',
  NegativeUpgrade = 'v',
  NegativeIncrease = 'j',
  LightSide = 'l',
  DarkSide = 'n',
}

export namespace DiceInputColorScheme {
  export const members: DiceInputColorScheme[] = [
    DiceInputColorScheme.Proficiency,
    DiceInputColorScheme.Ability,
    DiceInputColorScheme.Boost,
    DiceInputColorScheme.Challenge,
    DiceInputColorScheme.Difficulty,
    DiceInputColorScheme.Setback,
    DiceInputColorScheme.RemoveSetback,
    DiceInputColorScheme.Force,
    DiceInputColorScheme.Triumph,
    DiceInputColorScheme.Success,
    DiceInputColorScheme.Advantage,
    DiceInputColorScheme.Despair,
    DiceInputColorScheme.Failure,
    DiceInputColorScheme.Threat,
    DiceInputColorScheme.PositiveUpgrade,
    DiceInputColorScheme.PositiveIncrease,
    DiceInputColorScheme.NegativeUpgrade,
    DiceInputColorScheme.NegativeIncrease,
    DiceInputColorScheme.LightSide,
    DiceInputColorScheme.DarkSide,
  ];

  export function toParseOptions(): { [key in DiceInputColorScheme]: [DiceInputColorScheme] } {
    // Screw Intellisense
    const parseable: any = {};
    DiceInputColorScheme.members.forEach(m => {
      parseable[m] = [m];
    });
    return parseable;
  }

  export function toDiceType(type: DiceInputColorScheme): DiceType | null {
    switch (type) {
      case DiceInputColorScheme.Proficiency:
        return DiceType.NdsProficiency;
      case DiceInputColorScheme.Ability:
        return DiceType.NdsAbility;
      case DiceInputColorScheme.Boost:
        return DiceType.NdsBoost;
      case DiceInputColorScheme.Challenge:
        return DiceType.NdsChallenge;
      case DiceInputColorScheme.Difficulty:
        return DiceType.NdsDifficulty;
      case DiceInputColorScheme.Setback:
        return DiceType.NdsSetback;
      case DiceInputColorScheme.RemoveSetback:
        return DiceType.NdsRemoveSetback;
      case DiceInputColorScheme.Force:
        return DiceType.NdsForce;
      case DiceInputColorScheme.Triumph:
        return DiceType.NdsTriumph;
      case DiceInputColorScheme.Success:
        return DiceType.NdsSuccess;
      case DiceInputColorScheme.Advantage:
        return DiceType.NdsAdvantage;
      case DiceInputColorScheme.Despair:
        return DiceType.NdsDespair;
      case DiceInputColorScheme.Failure:
        return DiceType.NdsFailure;
      case DiceInputColorScheme.Threat:
        return DiceType.NdsThreat;
      case DiceInputColorScheme.PositiveUpgrade:
        return DiceType.NdsPositiveUpgrade;
      case DiceInputColorScheme.PositiveIncrease:
        return DiceType.NdsPositiveIncrease;
      case DiceInputColorScheme.NegativeUpgrade:
        return DiceType.NdsNegativeUpgrade;
      case DiceInputColorScheme.NegativeIncrease:
        return DiceType.NdsNegativeIncrease;
      case DiceInputColorScheme.LightSide:
        return DiceType.NdsLightSide;
      case DiceInputColorScheme.DarkSide:
        return DiceType.NdsDarkSide;
      default:
        return null;
    }
  }

  export function fromDiceType(type: DiceType): DiceInputColorScheme | null {
    switch (type) {
      case DiceType.NdsProficiency:
        return DiceInputColorScheme.Proficiency;
      case DiceType.NdsAbility:
        return DiceInputColorScheme.Ability;
      case DiceType.NdsBoost:
        return DiceInputColorScheme.Boost;
      case DiceType.NdsChallenge:
        return DiceInputColorScheme.Challenge;
      case DiceType.NdsDifficulty:
        return DiceInputColorScheme.Difficulty;
      case DiceType.NdsSetback:
        return DiceInputColorScheme.Setback;
      case DiceType.NdsRemoveSetback:
        return DiceInputColorScheme.RemoveSetback;
      case DiceType.NdsForce:
        return DiceInputColorScheme.Force;
      case DiceType.NdsTriumph:
        return DiceInputColorScheme.Triumph;
      case DiceType.NdsSuccess:
        return DiceInputColorScheme.Success;
      case DiceType.NdsAdvantage:
        return DiceInputColorScheme.Advantage;
      case DiceType.NdsDespair:
        return DiceInputColorScheme.Despair;
      case DiceType.NdsFailure:
        return DiceInputColorScheme.Failure;
      case DiceType.NdsThreat:
        return DiceInputColorScheme.Threat;
      case DiceType.NdsPositiveUpgrade:
        return DiceInputColorScheme.PositiveUpgrade;
      case DiceType.NdsPositiveIncrease:
        return DiceInputColorScheme.PositiveIncrease;
      case DiceType.NdsNegativeUpgrade:
        return DiceInputColorScheme.NegativeUpgrade;
      case DiceType.NdsNegativeIncrease:
        return DiceInputColorScheme.NegativeIncrease;
      case DiceType.NdsLightSide:
        return DiceInputColorScheme.LightSide;
      case DiceType.NdsDarkSide:
        return DiceInputColorScheme.DarkSide;
      default:
        return null;
    }
  }
}

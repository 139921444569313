import { FormGroup, AbstractControl, FormArray, FormControl } from '@ngneat/reactive-forms';
import { replaceBetween } from '@rpg/core/base';

export function getLookupKey(key: string, defaultLookupKey: string = 'id'): [string, string] {
  let lookupKey = defaultLookupKey;
  const startIndex = key.indexOf('[');
  const endIndex = key.indexOf(']', startIndex);
  if (startIndex !== 0 || endIndex === -1) {
    return [lookupKey, key];
  }
  if (startIndex > -1 && endIndex > -1) {
    lookupKey = key
      .substr(startIndex, endIndex - startIndex + 1)
      .replace('[', '')
      .replace(']', '');
    key = replaceBetween(key, startIndex, endIndex, '');
  }
  return [lookupKey, key];
}

/**
 * formTraversal
 *
 * Used to easily traverse through a Reactive Form. Will navigate through
 * FormGroup and FormArray elements. To select an element in a FormArray, you
 * must provide the key on which it will attempt to match the value provided.
 *
 * ex. path: talents.[id]12345
 * Will get the talents control on the root form element, then on the resulting
 * element, if it is a FormArray, will look for a FormGroup with a key of 'id'
 * that matches the value provided (12345).
 *
 * form.get('talents').controls.find(c => c.get('id').value === '12345')
 *
 * If no key is provided when encountering a FormArray, the method will default
 * to looking for a control with the key of 'id'.
 *
 * NOTE: You can only match on string values
 *
 * @param {AbstractControl} form Root form element that will be traversed
 * @param {string} path Path to traverse through the form
 * @param {string} defaultArrayLookupKey The default key used when performing array lookups
 *
 * @returns {{previousControl: AbstractControl, targetControl: AbstractControl}}
 * Resulting Abstract Control at the end of the path
 */
export function formTraversal<
  T extends AbstractControl = AbstractControl,
  K extends AbstractControl = AbstractControl
>(
  form: AbstractControl,
  path?: string,
  defaultArrayLookupKey: string = 'id'
): { previousControl: K | null; targetControl: T } {
  if (form instanceof FormControl) {
    throw new Error('Cannot traverse a Form Control element!');
  }
  if (!path) {
    return {
      previousControl: null,
      targetControl: form as T,
    };
  }
  const keys = path.split('.');
  let currentControl: AbstractControl | undefined = form;
  let previousControl: AbstractControl = form;
  let usedKeys = '';
  do {
    previousControl = currentControl;
    let nextKey = keys.shift();
    if (!nextKey) {
      break;
    }
    if (currentControl instanceof FormGroup) {
      currentControl = currentControl.get(nextKey);
    } else if (currentControl instanceof FormArray) {
      const [lookupKey, parsedKey] = getLookupKey(nextKey, defaultArrayLookupKey);
      currentControl = currentControl.controls.find(
        control => control.get(lookupKey)?.value === parsedKey
      );
      // Recreate full lookup key for the usedKey output
      nextKey = `[${lookupKey}]${parsedKey}`;
    }
    usedKeys = `${usedKeys.length > 0 ? usedKeys + '.' : ''}${nextKey}`;
    if (!currentControl || currentControl instanceof FormControl) {
      break;
    }
  } while (keys.length > 0);

  if (!currentControl) {
    throw new Error(`No control found at key: ${usedKeys}`);
  }
  return {
    previousControl: previousControl as K,
    targetControl: currentControl as T,
  };
}

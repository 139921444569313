import { Constraints } from '@rpg/core/base';
import { NdsCharacterModifierType } from '../../enums';
import { NdsCharacterModifierData } from './nds-character-modifier-data';

export class NdsCharacterModifierNarrativeData extends NdsCharacterModifierData {
  public type: NdsCharacterModifierType = NdsCharacterModifierType.Narrative;

  constructor(params?: Partial<NdsCharacterModifierNarrativeData>) {
    super(params);
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsCharacterModifierType.Narrative);
  }

  public static validate(
    input: NdsCharacterModifierNarrativeData,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = NdsCharacterModifierData.validate(input, fieldNamePrefix);
    return errors;
  }
}

export enum DiceShortHandStyle {
  Name = '[DiceShortHandStyle] standard',
  Color = '[DiceShortHandStyle] color based',
}

export namespace DiceShortHandStyle {
  export function translationKey(type: DiceShortHandStyle): string {
    return `enums.dice.dice-short-hand-style.${type}`;
  }

  export function toString(type: DiceShortHandStyle): string {
    switch (type) {
      case DiceShortHandStyle.Name:
        return 'Name';
      case DiceShortHandStyle.Color:
        return 'Color';
      default:
        return '';
    }
  }

  export const defaultValue: DiceShortHandStyle = DiceShortHandStyle.Color;

  export const members: DiceShortHandStyle[] = [DiceShortHandStyle.Color, DiceShortHandStyle.Name];
}

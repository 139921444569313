import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RedirectService } from '@rpg/ngx/core';
import { PermissionService } from '../services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _redirectService: RedirectService,
    private _permissionService: PermissionService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permission = route.data['rpgPermission'] ?? null;
    if (!!permission && this._permissionService.hasPermission(permission)) {
      return true;
    }
    this._router.navigateByUrl(this._redirectService.homePage);
    return false;
  }
}

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { Edge, Position2DNodeRef } from '@rpg/core/base';
import {
  NdsCharacterForceTreeData,
  NdsCharacterTalentData,
  NdsCharacterTalentTreeData,
  NDS_CHARACTER_SIGNATURE_TALENT_TREE_HEIGHT,
  NDS_CHARACTER_TALENT_TREE_HEIGHT,
  NDS_CHARACTER_TALENT_TREE_WIDTH,
} from '@rpg/core/character';
import { TalentTreeLayoutState } from './talent-tree-layout.state';

interface SlotData {
  id: string;
  talentData: NdsCharacterTalentData;
  isSignatureTree: boolean;
}

@Component({
  selector: 'rpg-nds-character-talent-tree-layout',
  templateUrl: './talent-tree-layout.component.html',
  styleUrls: ['./talent-tree-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TalentTreeLayoutState],
})
export class NdsCharacterTalentTreeLayoutComponent {
  @HostBinding('class.force-mobile')
  public _forceMobile: boolean = false;

  @Input()
  public set treeData(treeData: NdsCharacterTalentTreeData | NdsCharacterForceTreeData) {
    this.vm.treeData = treeData;
  }
  @Input()
  public set editMode(editMode: boolean) {
    this.vm.editMode = editMode;
  }
  @Input()
  public set signatureTalentEnabled(enabled: boolean) {
    this.vm.signatureTalentsEnabled = enabled;
  }
  @Input()
  public set expansionEnabled(enabled: boolean) {
    this.vm.expansionEnabled = enabled;
  }
  @Input()
  public set talents(talents: NdsCharacterTalentData[]) {
    this.vm.talents = talents;
  }
  @Input()
  public set forceMobile(forceMobile: boolean) {
    this.vm.forceMobile = forceMobile;
    this._forceMobile = forceMobile;
  }

  @Output()
  public talentContentClick = new EventEmitter<{
    isSignature: boolean;
    nodeRef: Position2DNodeRef;
  }>();
  @Output()
  public talentPurchasedToggle = new EventEmitter<{ nodeRef: Position2DNodeRef; state: boolean }>();
  @Output()
  public edgeMatrixUpdated = this.vm.edgeMatrixUpdated$;
  @Output()
  public addSignatureTalent = new EventEmitter<void>();
  @Output()
  public expandNode = new EventEmitter<{ nodeRef: Position2DNodeRef; isSignature: boolean }>();

  public talentSlotRef: { [key: string]: SlotData } = {};
  public NDS_CHARACTER_TALENT_TREE_WIDTH = NDS_CHARACTER_TALENT_TREE_WIDTH - 1;
  public NDS_CHARACTER_TALENT_TREE_HEIGHT = NDS_CHARACTER_TALENT_TREE_HEIGHT - 1;
  public NDS_CHARACTER_SIGNATURE_TALENT_TREE_HEIGHT =
    NDS_CHARACTER_SIGNATURE_TALENT_TREE_HEIGHT - 1;
  public TREE_BOUNDARIES: { [key in Edge]: number } = {
    [Edge.Up]: 0,
    [Edge.Right]: this.NDS_CHARACTER_TALENT_TREE_WIDTH,
    [Edge.Down]: this.NDS_CHARACTER_TALENT_TREE_HEIGHT,
    [Edge.Left]: 0,
  };
  public SIGNATURE_TREE_BOUNDARIES: { [key in Edge]: number } = {
    [Edge.Up]: 0,
    [Edge.Right]: this.NDS_CHARACTER_TALENT_TREE_WIDTH,
    [Edge.Down]: this.NDS_CHARACTER_SIGNATURE_TALENT_TREE_HEIGHT,
    [Edge.Left]: 0,
  };

  constructor(public vm: TalentTreeLayoutState) {}

  public trackBySlotId(index: number, ref: Position2DNodeRef): string {
    return ref.id;
  }
}

import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Theme } from '@rpg/core/user';
import { ThemeService } from '@rpg/ngx/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[rpgTheme]',
})
export class ThemeDirective implements OnInit, OnDestroy {
  private _sub: Subscription | undefined;

  constructor(
    private _el: ElementRef,
    private _themeService: ThemeService,
    private _renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this._sub = this._themeService.currentTheme$.subscribe(theme => {
      Theme.themeClasses.forEach(cl => {
        this._renderer.removeClass(this._el.nativeElement, cl);
      });
      this._renderer.addClass(this._el.nativeElement, Theme.getBodyClass(theme));
    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }
}

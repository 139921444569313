import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterHeroicAbilityUpgradeData } from '@rpg/core/character';

export function ndsCharacterHeroicAbilityUpgradeToFormGroup(
  upgrade: NdsCharacterHeroicAbilityUpgradeData
): FormGroup<NdsCharacterHeroicAbilityUpgradeData> {
  return new FormGroup<NdsCharacterHeroicAbilityUpgradeData>({
    cost: new FormControl(upgrade.cost),
    description: new FormControl(upgrade.description),
    id: new FormControl(upgrade.id),
    name: new FormControl(upgrade.name),
    ranks: new FormControl(upgrade.ranks),
  });
}

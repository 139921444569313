export namespace Auth3Actions {
  export class SetToken {
    static readonly type = '[AuthState] Set Token';
    constructor(public token: string) {}
  }

  export class ClearToken {
    static readonly type = '[AuthState] Clear Token';
  }
}

import { Store } from '@ngxs/store';
import { FeatureFlag } from '@rpg/core/base';
import { FlagsState } from '@sessions/state';
import { map } from 'rxjs';

export function isFeatureFlagEnabled(store: Store, flag: FeatureFlag): boolean {
  let enabled = false;
  store
    .selectOnce(FlagsState.checkFlag)
    .pipe(map(flagFn => flagFn(flag)))
    .subscribe(flagEnabled => {
      enabled = flagEnabled;
    });
  return enabled;
}

import { parseEnum, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterAttribute } from '../enums';

export class NdsCharacterAttributeData {
  public type!: NdsCharacterAttribute;
  public value: number = 0;

  constructor(params?: Partial<NdsCharacterAttributeData>) {
    if (!!params) {
      this.type = parseEnum(NdsCharacterAttribute, params.type, this.type);
      this.value = parseNumber(params.value, this.value);
    }
  }

  public static validate(input: NdsCharacterAttributeData): string[] {
    const errors: string[] = [];
    const name = `Attribute ${input.type ? `(${input.type}) ` : ''}`;
    errors.push(
      ...RpgClassValidators.enum(input.type, NdsCharacterAttribute, {
        enumName: 'Character Attribute',
        fieldName: `${name} Attribute`,
      })
    );
    errors.push(...RpgClassValidators.number(input.value, { fieldName: `${name} Value` }));
    return errors;
  }
}

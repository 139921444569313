import { Injectable } from '@angular/core';
import { MarkdownService, MarkedRenderer } from 'ngx-markdown';
import { DiceInputTextScheme } from '@rpg/core/dice';
import { MarkedJSHelpers } from '../functions';
import { DiceTheme } from '@rpg/core/base';
import { Lexer } from 'marked';
import { ThemeService } from './theme.service';
import { ThemeOption } from '@rpg/core/user';

const imageSizeLink =
  // eslint-disable-next-line
  /^!?\[((?:\[[^\[\]]*\]|\\[\[\]]?|`[^`]*`|[^\[\]\\])*?)\]\(\s*(<(?:\\[<>]?|[^\s<>\\])*>|(?:\\[()]?|\([^\s\x00-\x1f()\\]*\)|[^\s\x00-\x1f()\\])*?(?:\s+=(?:[\w%]+)?x(?:[\w%]+)?)?)(?:\s+("(?:\\"?|[^"\\])*"|'(?:\\'?|[^'\\])*'|\((?:\\\)?|[^)\\])*\)))?\s*\)/;
(Lexer as any).rules.inline.normal.link = imageSizeLink;
(Lexer as any).rules.inline.gfm.link = imageSizeLink;
(Lexer as any).rules.inline.breaks.link = imageSizeLink;

@Injectable({
  providedIn: 'root',
})
export class MarkdownConfigService {
  constructor(private _markdown: MarkdownService, private _themeService: ThemeService) {}

  public reset(): void {
    this._markdown.renderer = new MarkedRenderer();
  }

  public addGlobalDiceSupport(diceTheme: DiceTheme = DiceTheme.Generic): void {
    this._markdown.renderer = this.buildRendererWithDiceSupport(diceTheme);
  }

  public buildRendererWithDiceSupport(diceTheme: DiceTheme = DiceTheme.Generic): MarkedRenderer {
    const renderer = new MarkedRenderer();
    renderer.em = (text: string) => {
      // This is a special case where dice icon text will trigger the
      // EM renderer before the paragraph rendering, we need to catch
      // this case and ignore it so the paragraph will pick it up correctly
      // :e*: some text :e*: will trigger it, so we match the last colon of
      // the first symbol and the first colon of the last symbol. If these positions
      // match, 99% chance says these are dice icons and not actually em ids
      // eslint-disable-next-line no-magic-numbers
      if (text[0] === ':' && text[text.length - 2] === ':') {
        return `*${text}*`;
      }
      return `<em>${text}</em>`;
    };
    renderer.paragraph = (text: string) => `<p>${this._diceReplacement(text, diceTheme)}</p>`;
    renderer.listitem = (text: string) => `<li>${this._diceReplacement(text, diceTheme)}</li>\n`;
    renderer.link = (href: string, title: string, text: string) => {
      href = MarkedJSHelpers.cleanUrl(renderer.options.sanitize, renderer.options.baseUrl, href);
      if (href === null) {
        return text;
      }
      let out =
        '<a target="_blank" rel="noopener noreferrer" href="' + MarkedJSHelpers.escape(href) + '"';
      if (title) {
        out += ' title="' + title + '"';
      }
      out += '>' + text + '</a>';
      return out;
    };
    renderer.code = (code: string, language: string | undefined, escaped: boolean) => {
      const lang = (language || '').match(/\S*/)?.[0] ?? '';
      if (!lang) {
        return (
          '<pre><code>' + (escaped ? code : MarkedJSHelpers.escape(code, true)) + '</code></pre>\n'
        );
      }
      const className = lang.toLowerCase().trim() === 'code' ? 'language-code' : '';
      return (
        '<pre class="' +
        className +
        '"><code class="' +
        renderer.options.langPrefix +
        MarkedJSHelpers.escape(lang, true) +
        '">' +
        (escaped ? code : MarkedJSHelpers.escape(code, true)) +
        '</code></pre>\n'
      );
    };
    renderer.tablecell = (text: string, flags: any) => {
      const type = flags.header ? 'th' : 'td';
      const tag = flags.align ? `<${type} align="${flags.align}">` : `<${type}>`;
      return `${tag}${this._diceReplacement(text, diceTheme)}</${type}>`;
    };
    renderer.image = (href: string | null, title: string | null, text: string) => {
      if (href?.includes(' =')) {
        const parts = href.split(' =');
        const url = parts[0];
        const [width, height] = parts[1].split('x');
        const parsedWidth = parseInt(width, 10);
        const parsedHeight = parseInt(height, 10);
        if (
          !!url &&
          !isNaN(parsedWidth) &&
          parsedWidth > 0 &&
          !isNaN(parsedHeight) &&
          parsedHeight > 0
        ) {
          const cleanUrl = MarkedJSHelpers.cleanUrl(
            renderer.options.sanitize,
            renderer.options.baseUrl,
            url
          );
          if (cleanUrl === null) return text;
          return `<img src="${cleanUrl}" alt="${text}" width="${parsedWidth}px" height="${parsedHeight}px"${
            title ? ` title="${title}"` : ''
          }${renderer.options.xhtml ? ' />' : '>'}`;
        }
      }
      return MarkedRenderer.prototype.image.apply(renderer, [href, title, text]);
    };
    return renderer;
  }

  public _diceReplacement(text: string, diceTheme: DiceTheme): string {
    const diceKey = ':';
    const diceEndKey = ':';
    let keyIndex = -1;
    let endIndex = -1;
    let minSearchIndex = 0;

    const replaceString = (t: string): string => {
      keyIndex = t.indexOf(diceKey, minSearchIndex);
      if (keyIndex < 0) {
        return t; // No more items to process, break
      }
      endIndex = t.indexOf(diceEndKey, keyIndex + 1);
      if (endIndex <= keyIndex) {
        return t; // no matching end key found, break
      }
      const diceString = t.substring(keyIndex, endIndex + 1);
      const type = DiceInputTextScheme.toDiceType(diceString as any);
      const isDarkMode = this._themeService.currentTheme === ThemeOption.Dark;
      if (!!type) {
        const replacedImages = [
          `<span class="markdown-rendered-dice inline-dice__container">`,
          `<span class="inline-dice__image dice-image ${
            isDarkMode ? 'rpg-theme-currently-dark' : ''
          } ${type} ${DiceTheme.toClass(diceTheme)}">${diceString}</span>`,
          `</span>`,
        ].join('');
        t = [t.substring(0, keyIndex), replacedImages, t.substring(endIndex + 1)].join('');
        minSearchIndex = keyIndex + replacedImages.length;
      } else {
        // Make sure we don't pick up the same key again if we were not able to find a match
        minSearchIndex = keyIndex + 1;
      }

      return replaceString(t);
    };
    return replaceString(text);
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import {
  AdjacentTracker,
  Edge,
  EdgeMatrix,
  isDefined,
  Position2D,
  Position2DGridManager,
  Position2DNodeRef,
} from '@rpg/core/base';
import { NdsCharacterTalentData, NdsCharacterTalentTreeData } from '@rpg/core/character';
import { RxState } from '@rx-angular/state';

interface VD {
  adjacencyTrackers: AdjacentTracker[];
  expandable: boolean;
}

interface TreeState {
  talents: Position2DNodeRef<VD>[];
  signatureTalents: Position2DNodeRef<VD>[];
  editMode: boolean;
  talentSlotRef: { [key: string]: NdsCharacterTalentData };
  hasSignatureTalent: boolean;
  signatureTalentsEnabled: boolean;
  expansionEnabled: boolean;
  selectedTalentData: Position2DNodeRef<VD> | undefined;
  selectedTalentIsSignatureTree: boolean;
  forceMobile: boolean;
}

type SomePartialProps<T, K extends keyof T> = Omit<T, K> & Partial<T>;

type TreeData = SomePartialProps<
  NdsCharacterTalentTreeData,
  'signatureTalentEdgeMatrix' | 'signatureTalentId' | 'signatureTalentPositionManager'
>;

const MAX_MOBILE_WIDTH = 767;

@Injectable()
export class TalentTreeLayoutState extends RxState<TreeState> {
  // We only want to check once for the mobile scaling, this is not responsive to changes
  private _mobileScalingChecked: boolean = false;
  private _inMobile: boolean = false;

  private _treeData!: TreeData;
  private _talentData: NdsCharacterTalentData[] = [];

  public state$ = this.select();
  public edgeMatrixUpdated$ = new EventEmitter<{ isSignature: boolean; matrix: EdgeMatrix }>();

  constructor() {
    super();
    this.set({
      selectedTalentData: undefined,
      selectedTalentIsSignatureTree: false,
      forceMobile: false,
    });
  }

  private get _positionManager(): Position2DGridManager<VD> {
    return this._treeData.positionManager as Position2DGridManager<VD>;
  }

  private get _sigPositionManager(): Position2DGridManager<VD> | undefined {
    return this._treeData.signatureTalentPositionManager as Position2DGridManager<VD>;
  }

  public set forceMobile(forceMobile: boolean) {
    this.set({ forceMobile });
  }

  public set editMode(editMode: boolean) {
    this.set({ editMode });
  }

  public set signatureTalentsEnabled(enabled: boolean) {
    this.set({
      signatureTalentsEnabled: enabled,
    });
  }

  public set expansionEnabled(enabled: boolean) {
    this.set({
      expansionEnabled: enabled,
    });
  }

  public set talents(talents: NdsCharacterTalentData[]) {
    this._talentData = talents;
    this._rebuildSlotRefs();
  }

  public set treeData(treeData: TreeData) {
    this._treeData = treeData;
    this.set({ hasSignatureTalent: !!treeData.signatureTalentId ?? false });
    this._recalculateTalents();
  }

  public isMobile(): boolean {
    if (this._mobileScalingChecked) return this._inMobile;

    this._mobileScalingChecked = true;
    const { forceMobile } = this.get();
    this._inMobile = forceMobile;
    const currentWidth = window?.innerWidth ?? -1;

    // No window size available, SSR maybe? default to desktop unless otherwise asked
    if (currentWidth === -1) return this._inMobile;
    if (currentWidth <= MAX_MOBILE_WIDTH) {
      // If we have a width, and it's the mobile width of smaller, use mobile
      this._inMobile = true;
      return this._inMobile;
    }
    // InMobile defaults to false (aka, Desktop), but it's also been adjusted in the case
    // of mobile styling being forced, so we should rely on that logic here.
    return this._inMobile;
  }

  public mobileSelect(nodeRef: Position2DNodeRef<VD>, isSignature: boolean): void {
    this.set({
      selectedTalentData: nodeRef,
      selectedTalentIsSignatureTree: isSignature,
    });
  }

  public clearMobileSelection(): void {
    this.set({
      selectedTalentData: undefined,
    });
  }

  public updateAdjacency(
    { isSignature, nodeRef }: { isSignature: boolean; nodeRef: Position2DNodeRef },
    { edge, node, offset }: { edge: Edge; node: AdjacentTracker; offset: number }
  ): void {
    const actualPosition = new Position2D(nodeRef.position);
    actualPosition.x += offset;
    const matrix = isSignature
      ? this._treeData.signatureTalentEdgeMatrix
      : this._treeData.edgeMatrix;
    if (!matrix) return;
    matrix.setAdjacency(actualPosition, edge, !Edge.hasEdge(edge, node.value));
    this.edgeMatrixUpdated$.emit({ isSignature, matrix: matrix });
  }

  public updateSignatureConnector(idx: number, node: AdjacentTracker): void {
    if (!this._treeData.signatureTalentEdgeMatrix) return;
    this._treeData.signatureTalentEdgeMatrix.setEdge(
      idx,
      0,
      Edge.Up,
      !Edge.hasEdge(Edge.Up, node.value)
    );
    this.edgeMatrixUpdated$.emit({
      isSignature: true,
      matrix: this._treeData.signatureTalentEdgeMatrix,
    });
  }

  private _rebuildSlotRefs(): void {
    // eslint-disable-next-line prefer-const
    let { talentSlotRef, selectedTalentData } = this.get();
    if (!talentSlotRef) {
      talentSlotRef = {};
    }
    let selectedTalentExists = false;
    this._talentData.forEach(t => {
      const tId = t.id;
      talentSlotRef[tId] = t;
      if (!!selectedTalentData && selectedTalentData.linkedId === tId) {
        selectedTalentExists = true;
      }
    });
    this.set({
      talentSlotRef,
      selectedTalentData: selectedTalentExists ? selectedTalentData : undefined,
    });
  }

  private _recalculateTalents(): void {
    if (!this._treeData) return;
    const edgeMatrix = this._treeData.edgeMatrix;
    const nodes = this._positionManager.asArray;
    const sigMatrix = this._treeData.signatureTalentEdgeMatrix;
    const sigNodes = this._sigPositionManager?.asArray ?? [];
    const { expansionEnabled } = this.get();
    this.set({
      talents: nodes.map(node => {
        node.volatileData.adjacencyTrackers = this._getAdjacenciesForNode(node, edgeMatrix);
        node.volatileData.expandable =
          expansionEnabled && this._isPositionExpandable(node, this._positionManager);
        return node;
      }),
      signatureTalents: sigNodes.map(node => {
        node.volatileData.adjacencyTrackers = !!sigMatrix
          ? this._getAdjacenciesForNode(node, sigMatrix)
          : [];
        node.volatileData.expandable =
          expansionEnabled &&
          !!this._sigPositionManager &&
          this._isPositionExpandable(node, this._sigPositionManager);
        return node;
      }),
    });
  }

  private _getAdjacenciesForNode(
    node: Position2DNodeRef<VD>,
    matrix: EdgeMatrix
  ): AdjacentTracker[] {
    const adjacencies = [matrix.getNode(node.position)];
    if (node.span > 1) {
      let nextPosition = node.position;
      for (let i = 1; i < node.span; i++) {
        nextPosition = new Position2D(nextPosition);
        nextPosition.x += 1;
        adjacencies.push(matrix.getNode(nextPosition));
      }
    }
    return adjacencies.filter(isDefined);
  }

  private _isPositionExpandable(
    nodeRef: Position2DNodeRef,
    pm: Position2DGridManager<VD>
  ): boolean {
    // Last item in row cannot be expanded
    if (nodeRef.position.x + nodeRef.span >= pm.width) return false;
    // Adjacent position must be empty
    const adjacentNodePosition = new Position2D(nodeRef.position);
    adjacentNodePosition.x += nodeRef.span - 1; // Span is not 0 based
    adjacentNodePosition.traverseEdge(Edge.Right);
    const node = pm.getNode(adjacentNodePosition);
    if (!node) return false; // No node to consume
    if (!!node.linkedId) return false; // Adjacent node has content
    return true;
  }
}

import { Constraints, parseEnum, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsVehicleAttribute, NdsVehicleModifierType } from '../../enums';
import { NdsVehicleModifierData } from './nds-vehicle-modifier-data';

export class NdsVehicleModifierAttributeData extends NdsVehicleModifierData {
  public type: NdsVehicleModifierType = NdsVehicleModifierType.Attribute;
  public attribute!: NdsVehicleAttribute;
  public modifierAmount: number = 0;

  constructor(params?: Partial<NdsVehicleModifierAttributeData>) {
    super(params);
    if (!!params) {
      this.attribute = parseEnum(NdsVehicleAttribute, params.attribute, this.attribute);
      this.modifierAmount = parseNumber(params.modifierAmount, this.modifierAmount);
    }
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsVehicleModifierType.Attribute);
  }

  public static validate(input: NdsVehicleModifierAttributeData): string[] {
    const errors: string[] = NdsVehicleModifierData.validate(input);
    errors.push(
      ...RpgClassValidators.enum(input.attribute, NdsVehicleAttribute, {
        enumName: 'Vehicle Attribute',
      })
    );
    errors.push(...RpgClassValidators.number(input.modifierAmount));
    return errors;
  }
}

import {
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  ComponentFactoryResolver,
  Injector,
  ViewContainerRef,
  Host,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { TranslocoService } from '@ngneat/transloco';
import { RxState } from '@rx-angular/state';
import { Subscription, timer } from 'rxjs';
import { SpinnerComponent } from '../components/spinner/spinner.component';

interface ComponentState {
  pendingConfirm: boolean;
}

const DEFAULT_TIMEOUT = 3000;

@Directive({
  selector: 'button[mat-raised-button][rpgConfirmAction],button[mat-button][rpgConfirmAction]',
  providers: [RxState],
})
export class RpgConfirmActionDirective implements OnDestroy {
  @Input()
  public confirmTimeoutDelay: number = DEFAULT_TIMEOUT;
  @Input()
  public spinnerOnConfirm: boolean = false;

  @Output()
  public startConfirm = new EventEmitter<MouseEvent>();
  @Output()
  public confirm = new EventEmitter<MouseEvent>();
  @Output()
  public timedOut = new EventEmitter<void>();

  public state$ = this._state.select();

  private _timer?: Subscription;
  private _ogText?: any;

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent) {
    this.clicked(event);
  }

  constructor(
    private _state: RxState<ComponentState>,
    private _elementRef: ElementRef<HTMLButtonElement>,
    private _transloco: TranslocoService,
    private _renderer: Renderer2,
    private _resolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _viewRef: ViewContainerRef,
    @Host() private _host: MatButton
  ) {
    this._state.set({
      pendingConfirm: false,
    });
  }

  ngOnDestroy(): void {
    this._timer?.unsubscribe();
  }

  public clicked(event: MouseEvent): void {
    const { pendingConfirm } = this._state.get();
    if (pendingConfirm) {
      return this.confirmDelete(event);
    }
    return this.beginConfirm(event);
  }

  private beginConfirm(event: MouseEvent): void {
    this._state.set({
      pendingConfirm: true,
    });
    this._setTimer();
    this.startConfirm.emit(event);
    this._rewriteTextForConfirm();
  }

  private confirmDelete(event: MouseEvent): void {
    this._state.set({
      pendingConfirm: false,
    });
    this.confirm.emit(event);
    if (this.spinnerOnConfirm) {
      this._rewriteTextForSpinner();
    } else {
      this._restoreOGText();
    }
  }

  private _rewriteTextForConfirm(): void {
    this._ogText = this._elementRef.nativeElement.innerHTML;
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'width',
      `${this._elementRef.nativeElement.getBoundingClientRect().width}px`
    );
    this._renderer.setStyle(this._elementRef.nativeElement, 'display', `inline-flex`);
    this._renderer.setStyle(this._elementRef.nativeElement, 'justify-content', `center`);
    this._renderer.setStyle(this._elementRef.nativeElement, 'align-items', `center`);
    this._elementRef.nativeElement.innerHTML = this._transloco.translate('words.Confirm');
  }

  private _restoreOGText(): void {
    this._elementRef.nativeElement.innerHTML = this._ogText;
  }

  private _rewriteTextForSpinner(): void {
    this._setTimer();
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'height',
      `${this._elementRef.nativeElement.getBoundingClientRect().height}px`
    );
    const factory = this._resolver.resolveComponentFactory(SpinnerComponent);
    const ref = this._viewRef.createComponent(factory);
    ref.instance.diameter = 18;
    ref.instance.color = 'text';
    this._elementRef.nativeElement.innerHTML = '';
    this._elementRef.nativeElement.appendChild(ref.location.nativeElement);
  }

  private _setTimer(): void {
    this._timer?.unsubscribe();
    this._timer = timer(this.confirmTimeoutDelay).subscribe(() => {
      this._state.set({
        pendingConfirm: false,
      });
      this.timedOut.emit();
      this._restoreOGText();
    });
  }
}

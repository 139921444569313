export enum ImportSource {
  OggDude = '[import source] oggdude',
  OggDudeCustomData = '[import source] oggdude custom data',
  GenesysEmporium = '[import source] genesys emporium',
  StooGoffNpc = '[import source] stoogoff npc',
  DylanPetrumsaNpc = '[import source] dylan petrumsa npc',
}

export namespace ImportSource {
  export const members: ImportSource[] = [
    ImportSource.OggDude,
    ImportSource.OggDudeCustomData,
    ImportSource.GenesysEmporium,
    ImportSource.StooGoffNpc,
    ImportSource.DylanPetrumsaNpc,
  ];

  export function translationKey(type: ImportSource): string {
    return `enums.imports.import-source.${type}`;
  }
}

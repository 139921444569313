import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsVehicleActionData } from '@rpg/core/vehicle';

export function ndsVehicleActionToFormGroup(
  action: NdsVehicleActionData
): FormGroup<NdsVehicleActionData> {
  return new FormGroup<NdsVehicleActionData & Expandable>({
    expanded: new FormControl((action as any)['expanded'] ?? false),
    id: new FormControl(action.id),
    name: new FormControl(action.name, [Validators.required]),
    lookupName: new FormControl(action.lookupName),
    description: new FormControl(action.description),
    dice: new FormControl(action.dice),
  });
}

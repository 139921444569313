export enum NdsCharacterRangeBand {
  Engaged = '[nds character range band] engaged',
  Short = '[nds character range band] short',
  Medium = '[nds character range band] medium',
  Long = '[nds character range band] long',
  Extreme = '[nds character range band] extreme',
}

export namespace NdsCharacterRangeBand {
  export function translationKey(type: NdsCharacterRangeBand): string {
    return `enums.character.nds.range-band.${type}`;
  }

  export function toString(type: NdsCharacterRangeBand): string {
    switch (type) {
      case NdsCharacterRangeBand.Engaged:
        return 'Engaged';
      case NdsCharacterRangeBand.Short:
        return 'Short';
      case NdsCharacterRangeBand.Medium:
        return 'Medium';
      case NdsCharacterRangeBand.Long:
        return 'Long';
      case NdsCharacterRangeBand.Extreme:
        return 'Extreme';
      default:
        return '';
    }
  }

  export const members: NdsCharacterRangeBand[] = [
    NdsCharacterRangeBand.Engaged,
    NdsCharacterRangeBand.Short,
    NdsCharacterRangeBand.Medium,
    NdsCharacterRangeBand.Long,
    NdsCharacterRangeBand.Extreme,
  ];
}

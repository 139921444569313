import { State, Selector, StateContext, Action } from '@ngxs/store';
import { LoginType } from '@rpg/core/auth';
import { DiscordLink } from '@rpg/core/discord';
import { PatreonTier } from '@rpg/core/patreon';
import { User } from '@rpg/core/user';
import {
  Auth2HttpService,
  ImmutableContext,
  ImmutableSelector,
  RedirectService,
  ThemeService,
  UserHttpService,
} from '@rpg/ngx/core';
import { environment } from '@sessions/env';
import * as Sentry from '@sentry/browser';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ObjectId } from 'bson';
import { UserActions } from './user.actions';
import { GameTokenType, UserTableTokenRef } from '@rpg/core/table-token';
import { firstValueFrom } from 'rxjs';
import produce from 'immer';
import { Auth2Actions } from './auth2/auth2.actions';
import { PermissionService } from '@rpg/ngx/permissions';
import { RouterActions } from './router/router.actions';
import { UserSB } from '@sessions/supabase';
import { UserSchema } from '@rpg/core/schema';

export interface UserStateModel {
  currentUser: User;
  supabaseUser: UserSchema | null;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    currentUser: new User(),
    supabaseUser: null,
  },
})
@Injectable()
export class UserState {
  constructor(
    private themeService: ThemeService,
    private translocoService: TranslocoService,
    private userHttp: UserHttpService,
    private permissionService: PermissionService,
    private redirectService: RedirectService,
    private user: UserSB,
    private auth2Http: Auth2HttpService
  ) {}

  @Selector([UserState])
  @ImmutableSelector()
  public static userId(user: UserStateModel): ObjectId {
    return user.currentUser._id;
  }

  @Selector([UserState])
  @ImmutableSelector()
  public static currentUser(user: UserStateModel): User {
    return user.currentUser;
  }

  @Selector([UserState])
  @ImmutableSelector()
  public static patreonTier(user: UserStateModel): PatreonTier {
    return user.currentUser.settings.patreonLink.currentTier;
  }

  @Selector([UserState])
  @ImmutableSelector()
  public static isPatron(user: UserStateModel): boolean {
    return !!(
      user.currentUser.settings.patreonLink.currentTier &&
      user.currentUser.settings.patreonLink.currentTier !== PatreonTier.None
    );
  }

  @Selector([UserState])
  @ImmutableSelector()
  public static discordLink(user: UserStateModel): DiscordLink {
    return user.currentUser.settings.discordLink;
  }

  @Selector([UserState])
  @ImmutableSelector()
  public static userTableTokens(user: UserStateModel): UserTableTokenRef[] {
    return user.currentUser.tableTokens.sort(GameTokenType.tokenSort);
  }

  @Selector([UserState])
  @ImmutableSelector()
  public static enabledLogins(user: UserStateModel): { [key in LoginType]: boolean } {
    return {
      [LoginType.Website]: true, // always enabled with supabase
      [LoginType.Facebook]: false, // always disabled with supabase, even if used
      [LoginType.Google]: false, // always disabled with supabase, even if used
      [LoginType.Discord]: false, // Not supported for login
      [LoginType.Patreon]: false, // Not supported for login
    };
  }

  @Action(UserActions.Set)
  @ImmutableContext()
  public setUser(
    { setState, getState }: StateContext<UserStateModel>,
    { user }: UserActions.Set
  ): void {
    const ogUser = getState();
    if (ogUser.currentUser.settings.siteTheme !== user.settings.siteTheme) {
      this.themeService.changeTheme(user.settings.siteTheme);
    }
    if (ogUser.currentUser.settings.language !== user.settings.language) {
      this.translocoService.setActiveLang(user.settings.language);
    }
    this.translocoService.getTranslation(user.settings.language);
    if (environment.enableSentry) {
      Sentry.configureScope(scope => {
        scope.setUser({
          email: user.email,
          id: user._id.toHexString(),
          username: user.username,
        });
      });
    }
    setState;
    setState((state: UserStateModel) => {
      state.currentUser = new User(user);
      return state;
    });
  }

  @Action(UserActions.Update)
  @ImmutableContext()
  public updateUser(
    { setState, getState }: StateContext<UserStateModel>,
    { user }: UserActions.Update
  ): void {
    const { currentUser } = getState();
    // Change Site Theme if different
    if (!!user?.settings?.siteTheme && currentUser.settings.siteTheme !== user.settings.siteTheme) {
      this.themeService.changeTheme(user.settings.siteTheme);
    }

    // Change Site Language if different
    if (!!user?.settings?.language && currentUser.settings.language !== user.settings.language) {
      this.translocoService.setActiveLang(user.settings.language);
    }

    // Update Sentry Scope if different
    if (!!user?.email || !!user?.username) {
      Sentry.configureScope(scope => {
        scope.setUser({
          email: user.email ?? currentUser.email,
          id: currentUser._id.toHexString(),
          username: user.username ?? currentUser.username,
        });
      });
    }

    setState((state: UserStateModel) => {
      state.currentUser = new User({
        ...currentUser,
        ...user,
      });
      return state;
    });
  }

  @Action(UserActions.Clear)
  @ImmutableContext()
  public clearUser({ setState }: StateContext<UserStateModel>): void {
    if (environment.enableSentry) {
      Sentry.configureScope(scope => {
        scope.clear();
      });
    }
    setState((state: UserStateModel) => {
      state.currentUser = new User();
      state.supabaseUser = null;
      return state;
    });
  }

  @Action(UserActions.Restore)
  async restoreUser({ setState, dispatch }: StateContext<UserStateModel>) {
    try {
      const restored = await firstValueFrom(this.userHttp.restoreUser());
      this.permissionService.setPermissions(restored.permissions);
      setState(
        produce(draft => {
          draft.currentUser = restored.user;
          return draft;
        })
      );
      await firstValueFrom(this.translocoService.load(restored.user.settings.language));
      this.translocoService.setActiveLang(restored.user.settings.language);
      return dispatch(new RouterActions.InitialNavigation());
    } catch (e) {
      console.error('RESTORE USER ERROR', e);
      return dispatch(new Auth2Actions.RestoreFailed());
    }
  }

  @Action(UserActions.NewLogin)
  async newLogin({ setState, dispatch }: StateContext<UserStateModel>) {
    try {
      const user = await this.user.getUser();
      if (!user) throw new Error('No user found'); // Sanity check, shouldn't happen
      if (!user.legacy_mongo_id) {
        await firstValueFrom(this.auth2Http.legacyLink());
      }
      const restored = await firstValueFrom(this.userHttp.restoreUser());
      if (!user.legacy_mongo_id) {
        user.legacy_mongo_id = restored.user._id.toHexString();
      }
      this.permissionService.setPermissions(restored.permissions);
      setState(
        produce(draft => {
          draft.currentUser = restored.user;
          draft.supabaseUser = user as UserSchema;
          return draft;
        })
      );
      return dispatch(new RouterActions.Navigate(this.redirectService.useLoginRedirect()));
    } catch (e) {
      console.error('NEW LOGIN RESTORE USER ERROR', e);
      return dispatch(new Auth2Actions.SignOut());
    }
  }
}

export enum NdsCharacterType {
  Player = '[nds character type] player',
  Nemesis = '[nds character type] nemesis',
  Rival = '[nds character type] rival',
  Minion = '[nds character type] minion',
}

export namespace NdsCharacterType {
  export function translationKey(type: NdsCharacterType): string {
    return `enums.character.nds.character-type.${type}`;
  }

  export function toClass(type: NdsCharacterType): string {
    switch (type) {
      case NdsCharacterType.Player:
        return 'player';
      case NdsCharacterType.Nemesis:
        return 'nemesis';
      case NdsCharacterType.Rival:
        return 'rival';
      case NdsCharacterType.Minion:
        return 'minion';
      default:
        return '';
    }
  }

  export function toString(type: NdsCharacterType): string {
    switch (type) {
      case NdsCharacterType.Player:
        return 'Player';
      case NdsCharacterType.Nemesis:
        return 'Nemesis';
      case NdsCharacterType.Rival:
        return 'Rival';
      case NdsCharacterType.Minion:
        return 'Minion';
      default:
        return '';
    }
  }

  export function toUrlParam(type: NdsCharacterType): string {
    switch (type) {
      case NdsCharacterType.Player:
        return 'player';
      case NdsCharacterType.Nemesis:
        return 'nemesis';
      case NdsCharacterType.Rival:
        return 'rival';
      case NdsCharacterType.Minion:
        return 'minion';
      default:
        return '';
    }
  }

  export const members: NdsCharacterType[] = [
    NdsCharacterType.Player,
    NdsCharacterType.Nemesis,
    NdsCharacterType.Rival,
    NdsCharacterType.Minion,
  ];
}

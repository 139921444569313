import { Constraints, parseEnum, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterAttribute, NdsCharacterModifierType } from '../../enums';
import { NdsCharacterModifierData } from './nds-character-modifier-data';

export class NdsCharacterModifierAttributeData extends NdsCharacterModifierData {
  public type: NdsCharacterModifierType = NdsCharacterModifierType.Attribute;
  public attribute!: NdsCharacterAttribute;
  public modifierAmount: number = 0;

  constructor(params?: Partial<NdsCharacterModifierAttributeData>) {
    super(params);
    if (!!params) {
      this.attribute = parseEnum(NdsCharacterAttribute, params.attribute, this.attribute);
      this.modifierAmount = parseNumber(params.modifierAmount, this.modifierAmount);
    }
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsCharacterModifierType.Attribute);
  }

  public static validate(
    input: NdsCharacterModifierAttributeData,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = NdsCharacterModifierData.validate(input, fieldNamePrefix);
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Modifier ${
      input.name ? `(${input.name})` : ''
    }`;
    errors.push(
      ...RpgClassValidators.enum(input.attribute, NdsCharacterAttribute, {
        enumName: 'Character Attribute',
        fieldName: `${name} Attribute`,
      })
    );
    errors.push(
      ...RpgClassValidators.number(input.modifierAmount, { fieldName: `${name} Amount` })
    );
    return errors;
  }
}

import { Constraints, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterModifierType } from '../../enums';
import { NdsCharacterModifierData } from './nds-character-modifier-data';

export class NdsCharacterModifierArmorData extends NdsCharacterModifierData {
  public type: NdsCharacterModifierType = NdsCharacterModifierType.Armor;
  public linkedArmorId: string = '';
  public soakModifier: number = 0;
  public defenseModifier: number = 0;
  public encumbranceModifier: number = 0;

  constructor(params?: Partial<NdsCharacterModifierArmorData>) {
    super(params);
    if (!!params) {
      this.linkedArmorId = params.linkedArmorId ?? this.linkedArmorId;
      this.soakModifier = parseNumber(params.soakModifier, this.soakModifier);
      this.defenseModifier = parseNumber(params.defenseModifier, this.defenseModifier);
      this.encumbranceModifier = parseNumber(params.encumbranceModifier, this.encumbranceModifier);
    }
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsCharacterModifierType.Armor);
  }

  public static validate(
    input: NdsCharacterModifierArmorData,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = NdsCharacterModifierData.validate(input, fieldNamePrefix);
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Modifier ${
      input.name ? `(${input.name})` : ''
    }`;
    errors.push(
      ...RpgClassValidators.string(input.linkedArmorId, { fieldName: `${name} Linked Armor` })
    );
    errors.push(...RpgClassValidators.number(input.soakModifier, { fieldName: `${name} Soak` }));
    errors.push(
      ...RpgClassValidators.number(input.defenseModifier, { fieldName: `${name} Defense` })
    );
    errors.push(
      ...RpgClassValidators.number(input.encumbranceModifier, { fieldName: `${name} Encumbrance` })
    );
    return errors;
  }
}

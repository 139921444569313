export enum FileSort {
  Name = 'name',
  Newest = 'new',
  Oldest = 'old',
  Modified = 'modified',
  Favorite = 'favorite',
}

export namespace FileSort {
  export const members: FileSort[] = [
    FileSort.Name,
    FileSort.Newest,
    FileSort.Oldest,
    FileSort.Modified,
    FileSort.Favorite,
  ];

  export function fromString(value: string): FileSort | null {
    switch (value) {
      case 'name':
        return FileSort.Name;
      case 'new':
        return FileSort.Newest;
      case 'old':
        return FileSort.Oldest;
      case 'modified':
        return FileSort.Modified;
      case 'favorite':
        return FileSort.Favorite;
      default:
        return null;
    }
  }
}

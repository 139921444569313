import { GameTheme, RulesSystem } from '@rpg/core/base';
import { NdsCharacterAttribute, NdsCharacterCharacteristic, NdsCharacterDetail } from '../enums';
import {
  NdsCharacter,
  NdsCharacterAttributeData,
  NdsCharacterCharacteristicData,
  NdsCharacterConfigurationData,
  NdsCharacterDetailData,
} from '../models';
import { getDefaultNdsCharacterSkills } from './get-default-nds-character-skills';

export function getDefaultNdsCharacterData(
  theme: GameTheme = GameTheme.Genesys_Core
): NdsCharacter {
  return new NdsCharacter({
    image: GameTheme.getDefaultCharacterImage(theme),
    rulesSystem: RulesSystem.NarrativeDiceSystem,
    configuration: NdsCharacterConfigurationData.presets[theme],
    attributes: NdsCharacterAttribute.members.map(attribute => {
      const attr = new NdsCharacterAttributeData({ type: attribute });
      if (attribute === NdsCharacterAttribute.CharacterCount) {
        attr.value = 1;
      }
      return attr;
    }),
    characteristics: NdsCharacterCharacteristic.defaultValues.map(
      type => new NdsCharacterCharacteristicData({ type })
    ),
    details: NdsCharacterDetail.members.map(
      type =>
        new NdsCharacterDetailData({
          type,
        })
    ),
    skills: getDefaultNdsCharacterSkills(theme),
  });
}

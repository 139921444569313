export enum NdsCharacterDetail {
  Archetype = '[nds character detail] archetype',
  Species = '[nds character detail] species',
  Career = '[nds character detail] career',
  Specialization = '[nds character detail] specialization',
  Age = '[nds character detail] age',
  Weight = '[nds character detail] weight',
  Height = '[nds character detail] height',
  NotableFeatures = '[nds character detail] notable features',
  Background = '[nds character detail] background',
  Motivation = '[nds character detail] motivation',
  Desire = '[nds character detail] desire',
  Fear = '[nds character detail] fear',
  Strength = '[nds character detail] strength',
  Flaw = '[nds character detail] flaw',
}

export namespace NdsCharacterDetail {
  export function translationKey(type: NdsCharacterDetail): string {
    return `enums.character.nds.detail.${type}`;
  }

  export const members: NdsCharacterDetail[] = [
    NdsCharacterDetail.Archetype,
    NdsCharacterDetail.Species,
    NdsCharacterDetail.Career,
    NdsCharacterDetail.Specialization,
    NdsCharacterDetail.Age,
    NdsCharacterDetail.Weight,
    NdsCharacterDetail.Height,
    NdsCharacterDetail.NotableFeatures,
    NdsCharacterDetail.Background,
    NdsCharacterDetail.Motivation,
    NdsCharacterDetail.Desire,
    NdsCharacterDetail.Fear,
    NdsCharacterDetail.Strength,
    NdsCharacterDetail.Flaw,
  ];
}

/* eslint-disable @typescript-eslint/ban-types */
import { GatewayOptions, defaultGatewayOptions, WebsocketEmittable } from '../models';
import { WebsocketService } from '../services/websocket.service';
import { Observable, Subject } from 'rxjs';
import { WebsocketInjectorAccessor } from '../services/websocket-injector-accessor.service';

export function WebsocketGateway(options?: Partial<GatewayOptions>): ClassDecorator {
  const resolvedOptions = {
    ...defaultGatewayOptions,
    ...options,
  };
  return (constructor: Function): void => {
    constructor.prototype._gatewayOptions = resolvedOptions;
  };
}

export function WebsocketReceiver(eventName: string): any {
  return (target: any, propertyKey: string): void => {
    if (!target['_eventsToRegister']) {
      target['_eventsToRegister'] = [];
    }
    const newValue = function (...args: any[]): Observable<any> {
      const subj = new Subject<any>();
      target['_eventsToRegister'].push({
        eventName,
        cb: (eventData: any): void => {
          subj.next(eventData);
        },
      });
      return subj.asObservable();
    };
    (target as any)[propertyKey] = newValue();
  };
}

export function WebsocketEmitter(eventName: string): any {
  return (target: WebsocketService, propertyKey: string): void => {
    const newValue: WebsocketEmittable<any> = {
      emit: (data): void => {
        const item = WebsocketInjectorAccessor.getSocketService(target.constructor);
        item['_sendNewEvent'](eventName, data);
      },
    };
    // target[propertyKey] = newValue;
    Object.defineProperty(target, propertyKey, {
      get: (): any => newValue,
    });
  };
}

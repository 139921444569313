<ng-container *rxLet="state$; let state">
  <div class="attachments-table">
    <h3 class="attachments-table__header">
      {{ (state.isSingle ? 'words.Attachment' : 'words.Attachments') | transloco }}
    </h3>
    <h3 class="attachments-table__header attachments-table__header--smaller hide-on-mobile">
      {{ 'words.Cost' | transloco }}
    </h3>
    <h3 class="attachments-table__header attachments-table__header--smaller hide-on-mobile">
      {{ 'words.Rarity' | transloco }}
    </h3>
    <h3 class="attachments-table__header attachments-table__header--smaller hide-on-mobile">
      {{ 'phrases.Hard Points' | transloco }}:
      <span *ngIf="!state.isSingle"
        >{{ state.consumedHardPoints | zeroDash }}/{{ state.availableHardPoints | zeroDash }}</span
      >
    </h3>
    <ng-container *ngFor="let attachmentForm of state.data">
      <div class="attachments-table__item-header attachments-table__item-header--left">
        <button
          *ngIf="state.editMode && !state.isSingle"
          mat-mini-fab
          class="attachments-table__edit-button"
          color="accent"
          type="button"
          (click)="editAttachment.emit(attachmentForm)"
        >
          <fa-icon icon="pencil-alt"></fa-icon>
        </button>
        <mat-checkbox
          class="name-checkbox"
          [formControl]="$any(attachmentForm.get('equipped'))"
          [rpgDisableControl]="!state.editMode"
        >
          {{ attachmentForm.get('name').value }}
        </mat-checkbox>
      </div>
      <div
        class="
          attachments-table__item-header attachments-table__item-header--center
          center
          hide-on-mobile
        "
      >
        {{ attachmentForm.get('cost').value | zeroDash }}
      </div>
      <div
        class="
          attachments-table__item-header attachments-table__item-header--center
          center
          hide-on-mobile
        "
      >
        {{ attachmentForm.get('rarity').value | zeroDash
        }}{{
          attachmentForm.get('restricted').value === true
            ? ' (' + ('character.phrases.restricted-designation' | transloco) + ')'
            : ''
        }}
      </div>
      <div
        class="
          attachments-table__item-header attachments-table__item-header--right
          center
          hide-on-mobile
        "
      >
        {{ attachmentForm.get('hardPoints').value | zeroDash }}
      </div>
      <div class="attachments-table__item-details">
        <div class="attachments-table__item-mobile-header">
          <h3 class="attachments-table__mobile-header">
            {{ 'words.Cost' | transloco }}:
            <span>{{ attachmentForm.get('cost').value | zeroDash }}</span>
          </h3>
          <h3 class="attachments-table__mobile-header">
            {{ 'words.Rarity' | transloco }}:
            <span>{{ attachmentForm.get('cost').value | zeroDash }}</span>
          </h3>
          <h3 class="attachments-table__mobile-header">
            {{ 'phrases.Hard Points' | transloco }}:
            <span *ngIf="!state.isSingle"
              >{{ state.consumedHardPoints | zeroDash }}/{{
                state.availableHardPoints | zeroDash
              }}</span
            ><span *ngIf="state.isSingle">{{
              attachmentForm.get('hardPoints').value | zeroDash
            }}</span>
          </h3>
        </div>
        <rpg-list-group
          [label]="'Base Modifier'"
          [value]="attachmentForm.get('description').value"
          [displayMarkdown]="true"
          [inlineMarkdown]="true"
        ></rpg-list-group>
        <div class="attachments-table__item-modifications">
          <h4>{{ 'words.Modifications' | transloco }}</h4>
          <div
            class="attachments-table__modification"
            [class.attachments-table__modification--no-edit]="!state.editMode"
            *ngFor="let modForm of $any(attachmentForm.get('modifications')).controls"
            [formGroup]="modForm"
          >
            <button
              *ngIf="state.editMode"
              mat-mini-fab
              class="attachments-table__edit-button"
              color="accent"
              type="button"
              (click)="
                editModification.emit({
                  attachmentId: attachmentForm.get('id').value,
                  form: modForm
                })
              "
            >
              <fa-icon icon="pencil-alt"></fa-icon>
            </button>
            <mat-checkbox
              [formControl]="modForm.get('enabled')"
              [rpgDisableControl]="!state.editMode"
            >
              <markdown [data]="modForm.get('name').value"></markdown>
            </mat-checkbox>
            <ng-container *ngIf="modForm.get('description').value">
              <rpg-list-group
                class="attachments-table__mod-description"
                [label]="'words.Description' | transloco"
                [value]="modForm.get('description').value"
                [displayMarkdown]="true"
              ></rpg-list-group>
            </ng-container>
          </div>
          <button
            *ngIf="state.editMode"
            class="attachments-table__add-modifier"
            type="button"
            (click)="
              editModification.emit({
                attachmentId: attachmentForm.get('id').value
              })
            "
          >
            <button mat-mini-fab color="primary" type="button">
              <fa-icon icon="plus"></fa-icon>
            </button>
            {{ 'phrases.add-button-text' | transloco: { type: 'words.Modification' | transloco } }}
          </button>
        </div>
      </div>
      <div class="attachments-table__qualities">
        <h4 class="qualities-header">{{ 'words.Qualities' | transloco }}</h4>
        <rpg-nds-modifiers
          [modifiers]="attachmentForm.get('modifiers')"
          [editMode]="state.editMode"
          (editModifier)="
            editQuality.emit({ attachmentId: attachmentForm.get('id').value, form: $event })
          "
        ></rpg-nds-modifiers>
        <button
          *ngIf="state.editMode"
          mat-raised-button
          color="primary"
          type="button"
          class="add-qualities-button"
          (click)="editQuality.emit({ attachmentId: attachmentForm.get('id').value })"
        >
          {{ 'phrases.add-button-text' | transloco: { type: 'words.Quality' | transloco } }}
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>

import { Auth2Actions } from './auth2/auth2.actions';
import { Auth2State, Auth2StateModel } from './auth2/auth2.state';
import { Auth3Actions } from './auth3/auth3.actions';
import { Auth3State, Auth3StateModel } from './auth3/auth3.state';
import { FlagsActions } from './flags.actions';
import { FlagsState, FlagsStateModel } from './flags.state';
import { RouterActions } from './router/router.actions';
import { RouterState, RouterStateModel } from './router/router.state';
import { UserActions } from './user.actions';
import { UserState, UserStateModel } from './user.state';

export const states = [UserState, FlagsState, Auth2State, RouterState, Auth3State];

export {
  Auth2State,
  Auth2StateModel,
  Auth2Actions,
  Auth3State,
  Auth3StateModel,
  Auth3Actions,
  FlagsActions,
  FlagsState,
  FlagsStateModel,
  RouterState,
  RouterStateModel,
  RouterActions,
  UserState,
  UserStateModel,
  UserActions,
};

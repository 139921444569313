export enum CloneType {
  Character,
  Vehicle,
}

export namespace CloneType {
  export function getAvailableMessages(type: CloneType): string[] {
    switch (type) {
      case CloneType.Character:
        return [
          'Isolating DNA Fragments',
          'Killing Rogue Clone',
          'Growing New Host',
          'Performing Genetic Modifications',
          'Implanting Obedience Chip',
          'Replicating Genetic Code',
          'Teaching Hostile Ideology',
          'Implanting Disobedience Chip',
          'Removing Self-Expression',
          'Inhibiting Abnormalities',
          'Splicing Added Mutations',
          'Initiating Memory Wipe',
          'Kaminoan Funds Transfered',
          'Imprinting Barcode',
          'Monitoring Behavior Complex',
          'Testing Organic Makeup',
          'Emptying Chamber Pot',
          'Granting Immortality',
          'Teaching Dirty Words',
        ];
      case CloneType.Vehicle:
        return [
          'Manufacturing Motor',
          'Copying Schematics',
          'Stealing Power Supply',
          'Rigging Up Blasters',
          'Performing Overpriced Oil Change',
          'Changing Wipers',
          'Installing Headlights',
          'Preparing Warp Drive',
          'Scanning Fuel Capacity',
          'Initiating Rivet Adhesion',
          'Removing Spare Tire',
          'Reduce Hover Intake',
          'Decreasing Horsepower',
          'Installing Anti-Gravity',
          'Optimizing Matter Compressor',
          'Warping Warp Drive',
          'Applying Chrome Overlay',
          'Connecting Turbo Discharge',
          'Hanging Fuzzy Dice',
          'Repairing Faulty Flange',
          'Shining Tires',
          `Disinfecting Pilot's Seat`,
          'Training Gerbils',
          'Improving Water Resistance',
          'Testing Fire Resistance',
          'Adding Skeuomorphic Design',
          'Passing Seatbelt Inspection',
          'Painting Racing Stripes',
          'Installing Mr Fusion',
        ];
      default:
        return [];
    }
  }
}

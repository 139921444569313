import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleModifierCharacteristicData } from '@rpg/core/vehicle';
import { ndsVehicleModifierBaseToFormGroup } from './nds-vehicle-modifier-base-to-form-group';

export function ndsVehicleModifierCharacteristicToFormGroup(
  mod: NdsVehicleModifierCharacteristicData
): FormGroup<NdsVehicleModifierCharacteristicData> {
  return new FormGroup<NdsVehicleModifierCharacteristicData>({
    ...ndsVehicleModifierBaseToFormGroup(mod).controls,
    characteristic: new FormControl(mod.characteristic, [Validators.required]),
    modifierAmount: new FormControl(mod.modifierAmount),
  });
}

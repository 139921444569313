import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterModifierSkillData } from '@rpg/core/character';
import { ndsCharacterModifierBaseToFormGroup } from './nds-character-modifier-base-to-form-group';

export function ndsCharacterModifierSkillToFormGroup(
  mod: NdsCharacterModifierSkillData
): FormGroup<NdsCharacterModifierSkillData> {
  return new FormGroup<NdsCharacterModifierSkillData>({
    ...ndsCharacterModifierBaseToFormGroup(mod).controls,
    careerModifier: new FormControl(mod.careerModifier),
    extraDice: new FormControl(mod.extraDice),
    includeUncommittedForceDice: new FormControl(mod.includeUncommittedForceDice),
    linkedSkillId: new FormControl(mod.linkedSkillId),
    rankModifier: new FormControl(mod.rankModifier),
  });
}

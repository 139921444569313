import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterSkillData } from '@rpg/core/character';

export function ndsCharacterSkillToFormGroup(
  skill: NdsCharacterSkillData
): FormGroup<NdsCharacterSkillData> {
  return new FormGroup<NdsCharacterSkillData>({
    extraDice: new FormControl(skill.extraDice),
    id: new FormControl(skill.id),
    lookupName: new FormControl(skill.lookupName),
    isCareer: new FormControl(skill.isCareer),
    linkedCharacteristic: new FormControl(skill.linkedCharacteristic, [Validators.required]),
    name: new FormControl(skill.name, [Validators.required]),
    ranks: new FormControl(skill.ranks),
    skillType: new FormControl(skill.skillType, [Validators.required]),
  });
}

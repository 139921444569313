import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@ngneat/reactive-forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rpg-nds-modifiers',
  templateUrl: './modifiers.component.html',
  styleUrls: ['./modifiers.component.scss'],
  /**
   * We're using standard CD on this component to watch for changes to the
   * passed in form group more easily
   */
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NdsModifiersComponent implements OnDestroy {
  private _lastModifiersLength: number = 0;
  private _modifiersArray: FormGroup[] = [];

  @Input()
  public set modifiers(value: FormArray | AbstractControl) {
    if (value instanceof FormArray) {
      this._modifiersArray = value.controls as FormGroup[];
      this._lastModifiersLength = this._modifiersArray.length;
      this._initWatcher(value);
    }
  }
  @Input()
  public editMode: boolean = false;

  @Output()
  public editModifier: EventEmitter<FormGroup> = new EventEmitter();

  private _watcher?: Subscription;

  constructor(private _cd: ChangeDetectorRef, private _zone: NgZone) {}

  ngOnDestroy(): void {
    this._removeWatcher();
  }

  public get modifiersArray(): FormGroup[] {
    return this._modifiersArray;
  }

  private _initWatcher(form: FormArray): void {
    this._removeWatcher();
    this._watcher = form.valueChanges.subscribe(newValue => {
      if (newValue.length !== this._lastModifiersLength) {
        this._zone.run(() => {
          this._lastModifiersLength = newValue.length;
          this._cd.detectChanges();
        });
      }
    });
  }

  private _removeWatcher(): void {
    this._watcher?.unsubscribe();
  }
}

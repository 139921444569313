import { Injectable } from '@angular/core';
import { GameTableToken, GameTokenType } from '@rpg/core/table-token';
import { Body, Delete, HttpService, MapClass, Path, Post, Put } from '@rpg/ngx/http';
import { ObjectId } from 'bson';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GameTokenHttpService extends HttpService {
  @Put('/game-token/:id/user')
  public assignTokenToUser(
    @Path('id') tokenId: ObjectId,
    @Body('userId') userId: ObjectId
  ): Observable<boolean> {
    return EMPTY;
  }

  @Delete('/game-token/:id/user')
  public removeTokenFromUser(@Path('id') tokenId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Put('/game-token/:id/game')
  public assignTokenToGame(
    @Path('id') tokenId: ObjectId,
    @Body('gameId') gameId: ObjectId
  ): Observable<boolean> {
    return EMPTY;
  }

  @Delete('/game-token/:id/game')
  public removeTokenFromGame(@Path('id') tokenId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Put('/game-token/:id/cooldown')
  public resetTokenCooldown(@Path('id') tokenId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Delete('/game-token/:id')
  public deleteToken(@Path('id') tokenId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Post('/game-token')
  @MapClass(GameTableToken)
  public createToken(
    @Body()
    params: {
      type: GameTokenType;
      assignedUserId?: ObjectId;
      assignedGameId?: ObjectId;
    }
  ): Observable<GameTableToken> {
    return EMPTY;
  }
}

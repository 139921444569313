import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError, map, filter, catchError } from 'rxjs';
import { HttpAdapter } from '../models/http.adapter';
import { Adapter } from '../models/Adapter';
import { AdapterType } from '../models/AdapterType';

/**
 * {@link HttpService}
 *
 * Provides underlying functionality to support the additional decorators.
 * Any service that will make http requests using the decorators, must extend
 * this service to have the correct functionality available.
 *
 * @example
 * export class MyService extends HttpService {}
 */
@Injectable()
export class HttpService {
  /**
   * HttpService constructor
   *
   * @param {HttpClient} _http Injects the HttpClient
   * @returns {void} void
   */
  constructor(protected _http: HttpClient) {}

  /**
   * @ignore
   *
   * Applies the attached adapters to a given http request
   *
   * @param {Observable<HttpEvent<any>>} res The HttpEvent Response
   * @param {Adapter[]} adapters An array of attached adapters to be run
   * @param {Function} errorFn Function to run if Http request throws an error
   * @returns {Observable<any>} returns the HttpEvent Response
   */
  protected responseInterceptor(
    res: Observable<HttpEvent<any>>,
    adapters: Adapter[],
    errorFn: ((error: any) => Observable<any>) | null
  ): Observable<any> {
    return res.pipe(
      filter((obs: any) => obs instanceof HttpResponse),
      map((obs: HttpResponse<any>) => {
        if (Array.isArray(adapters)) {
          // Reversing the order of adapters will read them from top down
          // as the developer would write them instead of bottom up as decorators
          // are interpreted by typescript
          adapters.reverse().forEach(a => {
            if (a.type === AdapterType.MapValue) {
              obs = HttpAdapter.baseMapValue(obs, a.value);
            } else if (a.type === AdapterType.MapClass) {
              obs = HttpAdapter.baseMapClass(obs, a.value as any);
            } else if (a.type === AdapterType.MapPaginatedList) {
              obs = HttpAdapter.baseMapPaginatedList(obs, a.value as any);
            }
          });
        }
        return obs;
      }),
      map((obs: HttpResponse<any>) => (!!obs && typeof obs.body !== 'undefined' ? obs.body : obs)),
      catchError(error => {
        if (!!errorFn && errorFn != null) {
          return errorFn.call(undefined, error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}

import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterAttachmentModificationData } from '@rpg/core/character';
import { NDSCharacterModifierFormGroupFactory } from './modifiers';

export function ndsCharacterAttachmentModificationToFormGroup(
  mod: NdsCharacterAttachmentModificationData
): FormGroup<NdsCharacterAttachmentModificationData> {
  return new FormGroup<NdsCharacterAttachmentModificationData>({
    description: new FormControl(mod.description),
    enabled: new FormControl(mod.enabled),
    id: new FormControl(mod.id),
    modifiers: new FormArray(mod.modifiers.map(m => NDSCharacterModifierFormGroupFactory(m))),
    name: new FormControl(mod.name),
  });
}

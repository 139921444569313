import { parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { GameTableNoteActionRefType } from '../enums';

export class GameTableNoteActionRef {
  public type: GameTableNoteActionRefType = GameTableNoteActionRefType.Comment;
  public docId?: ObjectId;
  public docData: {
    characterId?: ObjectId;
    characterName?: string;
    characterImage?: string;
    [key: string]: any;
  } = {};

  constructor(params?: Partial<GameTableNoteActionRef>) {
    if (!!params) {
      this.type = params.type ?? this.type;
      this.docId = parseObjectId(params.docId) ?? this.docId;
      this.docData = params.docData ?? this.docData;
      if (!!this.docData.characterId) {
        this.docData.characterId =
          parseObjectId(this.docData.characterId) ?? this.docData.characterId;
      }
    }
  }
}

import { Directive, Input, ViewContainerRef, TemplateRef, OnDestroy } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { Permission } from '@rpg/core/base';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[rpgPermission]',
})
export class PermissionDirective implements OnDestroy {
  private _permissionSub: Subscription;

  private _permission: Permission | undefined;
  @Input('rpgPermission')
  public set requiredPermission(permission: Permission | undefined) {
    this._permission = permission;
    this._updateView();
  }

  constructor(
    private _permissionService: PermissionService,
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<any>
  ) {
    this._permissionSub = this._permissionService.permissions$.subscribe(perms => {
      this._updateView();
    });
  }

  ngOnDestroy(): void {
    if (!!this._permissionSub) {
      this._permissionSub.unsubscribe();
    }
  }

  private _updateView(): void {
    this._viewContainer.clear();
    if (!this._permission || this._permissionService.hasPermission(this._permission)) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
  }
}

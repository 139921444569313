import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { DiceTheme } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';
import { DiceWheelRow } from '@rpg/ngx/core';

@Component({
  selector: 'rpg-dice-wheel',
  templateUrl: './dice-wheel.component.html',
  styleUrls: ['./dice-wheel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiceWheelComponent {
  private _diceRows: DiceWheelRow[] = [];

  @Input()
  public set diceRows(types: DiceWheelRow[]) {
    this._diceRows = types;
    // eslint-disable-next-line no-magic-numbers
    if (this._diceRows.length < 8) {
      this._useInnerCircle = false;
    } else {
      this._useInnerCircle = true;
    }
  }
  public get diceRows(): DiceWheelRow[] {
    return this._diceRows;
  }

  @Input()
  // eslint-disable-next-line no-magic-numbers
  public circleRadius: number = 60;

  @Input()
  // eslint-disable-next-line no-magic-numbers
  public initialDegreeOffset: number = 90;

  @Input()
  // eslint-disable-next-line no-magic-numbers
  public degreesToSpan: number = 180;

  @Input()
  public responsiveToSiteTheme: boolean = true;

  @Output()
  public dice: EventEmitter<DiceType> = new EventEmitter<DiceType>();

  @Output()
  public didOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public didClose: EventEmitter<void> = new EventEmitter<void>();

  public isOpen: boolean = false;
  public DiceTheme: typeof DiceTheme = DiceTheme;
  // eslint-disable-next-line no-magic-numbers
  private _radiansConverter: number = Math.PI / 180;
  private _useInnerCircle: boolean = false;

  constructor(private _cd: ChangeDetectorRef) {}

  public close(): void {
    this.isOpen = false;
    this.didClose.emit();
    this._cd.detectChanges();
  }

  public toggle(): void {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  public open(): void {
    this.isOpen = true;
    this.didOpen.emit();
    this._cd.detectChanges();
  }

  public useInnerCircle(shouldUse: boolean): void {
    this._useInnerCircle = shouldUse;
    this._cd.detectChanges();
  }

  public getStyle(
    isOpen: boolean,
    { radius, types }: DiceWheelRow,
    rowIndex: number,
    buttonIndex: number
  ): any {
    if (!isOpen || types.length < 1 || !types[buttonIndex]) {
      return { transform: 'scale(0)' };
    } else {
      // eslint-disable-next-line no-magic-numbers
      const isEvenRow = rowIndex % 2 === 0;
      // Use half circle, offset by 15 degrees for odd rows
      // eslint-disable-next-line no-magic-numbers
      const oddOffset = 5;
      // eslint-disable-next-line no-magic-numbers
      const circumfrenceToCover = isEvenRow ? 180 : 180 - oddOffset * 2;
      const degreeOffset = this._getDegreeOffset(types.length, buttonIndex, circumfrenceToCover);
      // Add 90 degrees to push values to the left half of the circle
      // eslint-disable-next-line no-magic-numbers
      const topDegrees = 270;
      const degreesAdjusted = topDegrees - (isEvenRow ? 0 : oddOffset) - degreeOffset;
      // const degreesCorrected = degrees + this.initialDegreeOffset;

      return {
        transform: `scale(1) translateX(${this._getXPosition(
          radius,
          degreesAdjusted
        )}px) translateY(${this._getYPosition(radius, degreesAdjusted)}px)`,
      };
    }
  }

  private _getXPosition(radius: number, degrees: number): number {
    const maxDegrees = 360;
    const radians = (degrees % maxDegrees) * this._radiansConverter;
    return radius * Math.cos(radians);
  }

  private _getYPosition(radius: number, degrees: number): number {
    const maxDegrees = 360;
    const radians = (degrees % maxDegrees) * this._radiansConverter;
    return radius * Math.sin(radians);
  }

  /* eslint-disable no-magic-numbers */
  private _getDegreeOffset(itemCount: number, index: number, circumference: number): number {
    // All values are precalculated when only 3 items or less
    let options: number[] = [];
    if (itemCount <= 3) {
      switch (itemCount) {
        case 1:
          options = [90];
          break;
        case 2:
          options = [60, 120];
          break;
        case 3:
          options = [45, 90, 135];
          break;
        default:
          options = [];
      }
    } else {
      const interval = circumference / (itemCount - 1);
      options = new Array(itemCount).fill(null).map((val, idx) => interval * idx);
    }
    return options[index];
    // if (itemCount <= 3) {
    //     let radiusOptions = [];
    //     switch (itemCount) {
    //         case 1:
    //             radiusOptions = [];
    //             break;
    //         case 2:
    //             radiusOptions = [
    //                 circumference / 3,
    //                 (circumference / 3) * 2
    //             ];
    //             break;
    //         case 3:
    //             radiusOptions = [45, 90, 135];
    //             break;
    //         default:
    //             radiusOptions = [];
    //     }
    //     return radiusOptions[index];
    // } else {
    // eslint-disable-next-line , no-magic-numbers
    //     const outerItems = allDice.filter((_, idx) => idx % 2 === 0);
    // eslint-disable-next-line , no-magic-numbers
    //     const innerItems = allDice.filter((_, idx) => idx % 2 === 1);
    //     // Get the counts for the inner and outer rings. Outer ring
    //     // will always have more items than the inner
    //     /** Replacing this to handle null placeholders
    //     const [outerItemCount, innerItemCount]: [number, number] =
    // eslint-disable-next-line , no-magic-numbers
    //         itemCount % 2 === 0
    // eslint-disable-next-line , no-magic-numbers
    //               [itemCount / 2 + 1, itemCount / 2 - 1]
    // eslint-disable-next-line no-magic-numbers
    //               [(itemCount + 1) / 2, (itemCount - 1) / 2];
    //     */
    //     const outerItemCount = outerItems.filter(i => !!i).length;
    //     const innerItemCount = innerItems.filter(i => !!i).length;
    //     console.log(outerItemCount, innerItemCount);
    //     if (this._useInnerCircle) {
    //         // Odd items need to be offset from the even ones
    // eslint-disable-next-line , no-magic-numbers
    //         if (index % 2 === 1) {
    //             // Odd Item, inner circle
    //             // We want the base value to be offset halfway between the
    //             // outer item distances, so start at the angle halway between
    //             const outerItemDegreeDistance =
    //                 this.degreesToSpan / (outerItemCount - 1);
    //             const insideInitialDegreeOffset =
    // eslint-disable-next-line , no-magic-numbers
    //                 outerItemDegreeDistance / 2;
    //             // The total radius is offset on both ends by half the degree
    //             // distance of the outer ring. This reduces our inner degree
    //             // radius we are working with by 2 * the offset value
    //             const innerDegreesToSpan =
    // eslint-disable-next-line , no-magic-numbers
    //                 this.degreesToSpan - insideInitialDegreeOffset * 2;
    //             return (
    //                 insideInitialDegreeOffset +
    //                 (innerDegreesToSpan / (innerItemCount - 1)) *
    //                     ((index -
    //                         (1 +
    //                             (outerItemCount - innerItemCount - 1) *
    // eslint-disable-next-line , no-magic-numbers
    //                                 2)) /
    // eslint-disable-next-line , no-magic-numbers
    //                         2)
    //             );
    //         } else {
    //             // Even Item, outer circle
    //             return (
    //                 (this.degreesToSpan / (outerItemCount - 1)) *
    // eslint-disable-next-line no-magic-numbers
    //                 (index / 2)
    //             );
    //         }
    //     } else {
    //         return (this.degreesToSpan / (itemCount - 1)) * index;
    //     }
    // }
  }
}

import { isPlatformBrowser } from '@angular/common';
import { Component, ChangeDetectionStrategy, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Images, Permission, SocialLinks } from '@rpg/core/base';
import { PatreonTier } from '@rpg/core/patreon';
import { User } from '@rpg/core/user';
import { RedirectService } from '@rpg/ngx/core';
import { PermissionService } from '@rpg/ngx/permissions';
import { RxState } from '@rx-angular/state';
import { environment } from '@sessions/env';
import { Auth3Actions, Auth3State, UserActions, UserState } from '@sessions/state';
import { filter } from 'rxjs';

interface ComponentState {
  user: User | null;
  isAuthenticated: boolean;
  menuItems: MenuItem[];
  patreonDriveActive: boolean;
  headerSize: 'small' | 'large';
  footerVisible: boolean;
}

interface MenuItem {
  label: string;
  routerLink?: string;
  handler?: () => void;
  permission?: Permission;
}

@Component({
  selector: 'rpg-secured-container',
  templateUrl: './secured-container.component.html',
  styleUrls: ['./secured-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class SecuredContainerComponent implements OnInit {
  public Images: typeof Images = Images;
  public SocialLinks: typeof SocialLinks = SocialLinks;

  public state$ = this._state.select();
  public homePage: string = this._redirectService.homePage;
  public year: number = new Date().getFullYear();
  public version: string = environment.sentry.version;

  constructor(
    private _state: RxState<ComponentState>,
    private _store: Store,
    private _redirectService: RedirectService,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _router: Router,
    private _permissionService: PermissionService
  ) {
    this._state.set({
      menuItems: [],
      patreonDriveActive: true,
      headerSize: 'large',
      footerVisible: true,
    });
    this._state.connect('isAuthenticated', this._store.select(Auth3State.isAuthenticated));
    this._state.connect('user', this._store.select(UserState.currentUser));
    // if (environment.review) {
    //   this._lockRoutes();
    // }
  }

  ngOnInit(): void {
    const { user } = this._state.get();
    this._state.set({
      menuItems: this.buildMenuItems(user),
      patreonDriveActive: user?.settings.patreonLink.currentTier === PatreonTier.None ?? true,
    });
  }

  public get redirectParams(): { redirect: string } {
    if (isPlatformBrowser(this._platformId)) {
      return {
        redirect: window.location.pathname ?? '',
      };
    } else {
      return {
        redirect: '',
      };
    }
  }

  public buildMenuItems(user: User | null): MenuItem[] {
    const menuItems: MenuItem[] = [
      {
        label: 'words.Admin',
        routerLink: '/admin',
        permission: Permission.SuperAdmin,
      },
    ];
    if (!this._isUnpriveledgedUser(user)) {
      menuItems.push(
        {
          label: 'words.Library',
          routerLink: '/library/explorer',
        },
        {
          label: 'words.Dice',
          routerLink: '/dice',
        },
        {
          label: 'words.Games',
          routerLink: '/game',
        }
      );
    }
    menuItems.push(
      {
        label: 'words.Profile',
        routerLink: '/user',
      },
      {
        label: 'words.Logout',
        handler: (): void => {
          // this._store.dispatch(new Auth2Actions.SignOut());
          this._store.dispatch(new Auth3Actions.ClearToken());
          this._store.dispatch(new UserActions.Clear());
          this._router.navigateByUrl('/auth/login');
        },
      }
    );

    return menuItems;
  }

  private _lockRoutes(): void {
    this._state.hold(
      this._router.events.pipe(filter(event => event instanceof NavigationEnd)),
      navEvent => {
        const event = navEvent as NavigationEnd;
        const { user } = this._state.get();
        if (this._isUnpriveledgedUser(user)) {
          if (!event.url.includes('/user/profile') && !event.url.includes('/auth')) {
            this._router.navigateByUrl('/user/profile');
          }
        } else {
          console.log('ROUTES UNLOCKED');
        }
      }
    );
  }

  /**
   *
   * An unpriveledged user is anyone who is either not an Admin (us) or someone with Champion
   * tier access, which gives them the ability to look around the review site. This only applies to review.
   * In production and in dev mode, everyone is "priveledged"
   */
  private _isUnpriveledgedUser(user: User | null): boolean {
    if (environment.review) {
      return (
        !user ||
        (user.settings.patreonLink.currentTier !== PatreonTier.Champion &&
          !this._permissionService.hasPermission(Permission.SuperAdmin))
      );
    }
    return false;
  }
}

export enum MessageActionRefType {
  General = '[MessageActionRefType] General',
  CharacterUpdate = '[MessageActionRefType] Character Update',
  CharacterAttributeUpdate = '[MessageActionRefType] Character Attribute Update',
  VehicleAttributeUpdate = '[MessageActionRefType] Vehicle Attribute Update',
  VehicleCharacteristicUpdate = '[MessageActionRefType] Vehicle Characteristic Update',
  NewDiceRoll = '[MessageActionRefType] New Dice Roll',
  StoryPointsSet = '[MessageActionRefType] Story Points Set',
  StoryPointsAdd = '[MessageActionRefType] Story Points Add',
  StoryPointsRemove = '[MessageActionRefType] Story Points Remove',
  StoryPointsClear = '[MessageActionRefType] Story Points Clear',
  StoryPointsUse = '[MessageActionRefType] Story Points Use',
  InitiativeClear = '[MessageActionRefType] Initiative Clear',
  InitiativeNext = '[MessageActionRefType] Initiative Next',
  InitiativePrevious = '[MessageActionRefType] Initiative Previous',
  InitaitiveAdd = '[MessageActionRefType] Initiative Add',
  InitiativeReset = '[MessageActionRefType] Initiative Reset',
  InitiativeRemove = '[MessageActionRefType] Initiative Remove',
  InitiativeSet = '[MessageActionRefType] Initiative Set',
  InitiativeNew = '[MessageActionRefType] Initiative New',
  Crit = '[MessageActionRefType] Crit',
}

export namespace MessageActionRefType {
  export const members: MessageActionRefType[] = [
    MessageActionRefType.General,
    MessageActionRefType.CharacterUpdate,
    MessageActionRefType.CharacterAttributeUpdate,
    MessageActionRefType.VehicleAttributeUpdate,
    MessageActionRefType.VehicleCharacteristicUpdate,
    MessageActionRefType.NewDiceRoll,
    MessageActionRefType.StoryPointsAdd,
    MessageActionRefType.StoryPointsClear,
    MessageActionRefType.StoryPointsRemove,
    MessageActionRefType.StoryPointsSet,
    MessageActionRefType.StoryPointsUse,
    MessageActionRefType.InitiativeClear,
    MessageActionRefType.InitiativeNext,
    MessageActionRefType.InitiativePrevious,
    MessageActionRefType.InitaitiveAdd,
    MessageActionRefType.InitiativeReset,
    MessageActionRefType.InitiativeRemove,
    MessageActionRefType.InitiativeSet,
    MessageActionRefType.InitiativeNew,
    MessageActionRefType.Crit,
  ];

  export const storyPointsTypes: MessageActionRefType[] = [
    MessageActionRefType.StoryPointsAdd,
    MessageActionRefType.StoryPointsClear,
    MessageActionRefType.StoryPointsRemove,
    MessageActionRefType.StoryPointsSet,
    MessageActionRefType.StoryPointsUse,
  ];

  export const initiativeTypes: MessageActionRefType[] = [
    MessageActionRefType.InitiativeClear,
    MessageActionRefType.InitiativeNext,
    MessageActionRefType.InitiativePrevious,
    MessageActionRefType.InitaitiveAdd,
    MessageActionRefType.InitiativeReset,
    MessageActionRefType.InitiativeRemove,
    MessageActionRefType.InitiativeSet,
    MessageActionRefType.InitiativeNew,
  ];

  export function allowsGrouping(type: MessageActionRefType): boolean {
    switch (type) {
      case MessageActionRefType.StoryPointsSet:
      case MessageActionRefType.StoryPointsClear:
      case MessageActionRefType.InitiativeClear:
      case MessageActionRefType.InitiativeNew:
      case MessageActionRefType.InitiativeReset:
      case MessageActionRefType.InitiativeSet:
      case MessageActionRefType.Crit:
        return false;
      default:
        return true;
    }
  }
}

import { Constraints, parseEnumArray, RpgClassValidators } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';
import { NdsVehicleModifierType } from '../../enums';
import { NdsVehicleModifierData } from './nds-vehicle-modifier-data';

export class NdsVehicleModifierActionData extends NdsVehicleModifierData {
  public type: NdsVehicleModifierType = NdsVehicleModifierType.Action;
  public linkedActionId: string = '';
  public extraDice: DiceType[] = [];

  constructor(params?: Partial<NdsVehicleModifierActionData>) {
    super(params);
    if (!!params) {
      this.linkedActionId = params.linkedActionId ?? this.linkedActionId;
      this.extraDice = parseEnumArray(DiceType, params.extraDice);
    }

    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsVehicleModifierType.Action);
  }

  public static validate(input: NdsVehicleModifierActionData): string[] {
    const errors: string[] = NdsVehicleModifierData.validate(input);
    errors.push(...RpgClassValidators.string(input.linkedActionId));
    errors.push(...RpgClassValidators.enum(input.extraDice, DiceType, { isArray: true }));
    return errors;
  }
}

export enum NdsDiceImage {
  Success = 'S',
  Advantage = 'A',
  Triumph = 'R',
  Failure = 'F',
  Threat = 'T',
  Despair = 'D',
  Light = 'L',
  Dark = 'N',
  Blank = 'B',
}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageConfig } from '../models';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly _localStorageConfigKey: string = 'rpgsessions';

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}

  public getLocalStorageData(): LocalStorageConfig {
    if (!isPlatformBrowser(this._platformId) || !window?.localStorage?.getItem) {
      // If the browser doesn't support local storage, give up
      return new LocalStorageConfig();
    }

    const configJson = localStorage?.getItem(this._localStorageConfigKey);
    try {
      const config = configJson ? JSON.parse(configJson) : {};
      return new LocalStorageConfig(config);
    } catch {
      // The JSON parse may have failed, possibly something got corrupted.
      // Doesn't matter, this data is volatile.
      return new LocalStorageConfig();
    }
  }

  public updateLocalStorage(newData: Partial<LocalStorageConfig>): void {
    if (!isPlatformBrowser(this._platformId) || !window?.localStorage?.setItem) {
      // If the browser doesn't support local storage, give up
      return;
    }

    const config = { ...this.getLocalStorageData(), ...newData };
    localStorage.setItem(this._localStorageConfigKey, JSON.stringify(config));
  }
}

export enum NdsCharacterCharacteristic {
  None = '[nds character characteristic] none',
  Brawn = '[nds character characteristic] brawn',
  Agility = '[nds character characteristic] agility',
  Intellect = '[nds character characteristic] intellect',
  Cunning = '[nds character characteristic] cunning',
  Willpower = '[nds character characteristic] willpower',
  Presence = '[nds character characteristic] presence',
}

export namespace NdsCharacterCharacteristic {
  export function translationKey(type: NdsCharacterCharacteristic): string {
    return `enums.character.nds.characteristic.${type}`;
  }

  export function translationKeyAbbr(type: NdsCharacterCharacteristic): string {
    return `enums.character.nds.characteristic-abreviated.${type}`;
  }

  export const defaultValues: NdsCharacterCharacteristic[] = [
    NdsCharacterCharacteristic.Brawn,
    NdsCharacterCharacteristic.Agility,
    NdsCharacterCharacteristic.Intellect,
    NdsCharacterCharacteristic.Cunning,
    NdsCharacterCharacteristic.Willpower,
    NdsCharacterCharacteristic.Presence,
  ];

  export const members: NdsCharacterCharacteristic[] = [
    ...NdsCharacterCharacteristic.defaultValues,
    NdsCharacterCharacteristic.None, // Intentionally Last
  ];

  export const order: { [key in NdsCharacterCharacteristic]: number } = {
    [NdsCharacterCharacteristic.None]: 0,
    [NdsCharacterCharacteristic.Brawn]: 1,
    [NdsCharacterCharacteristic.Agility]: 2,
    [NdsCharacterCharacteristic.Intellect]: 3,
    [NdsCharacterCharacteristic.Cunning]: 4,
    [NdsCharacterCharacteristic.Willpower]: 5,
    [NdsCharacterCharacteristic.Presence]: 6,
  };

  export function fromAbbr(type: string): NdsCharacterCharacteristic | null {
    type = type.toUpperCase();
    switch (type) {
      case '-':
        return NdsCharacterCharacteristic.None;
      case 'BR':
        return NdsCharacterCharacteristic.Brawn;
      case 'AG':
        return NdsCharacterCharacteristic.Agility;
      case 'INT':
        return NdsCharacterCharacteristic.Intellect;
      case 'CUN':
        return NdsCharacterCharacteristic.Cunning;
      case 'WILL':
      case 'WIL':
        return NdsCharacterCharacteristic.Willpower;
      case 'PR':
        return NdsCharacterCharacteristic.Presence;
      default:
        return null;
    }
  }

  export function toImportStringRef(type: NdsCharacterCharacteristic): string {
    switch (type) {
      case NdsCharacterCharacteristic.Brawn:
        return 'Brawn';
      case NdsCharacterCharacteristic.Agility:
        return 'Agility';
      case NdsCharacterCharacteristic.Intellect:
        return 'Intellect';
      case NdsCharacterCharacteristic.Cunning:
        return 'Cunning';
      case NdsCharacterCharacteristic.Willpower:
        return 'Willpower';
      case NdsCharacterCharacteristic.Presence:
        return 'Presence';
      default:
        return '';
    }
  }
}

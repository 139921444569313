import { parseBoolean, parseNumber, parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { DiceMeta } from './dice-meta';
import { NdsDiceStats } from './nds-dice-stats';

export class DiceDetails {
  public description: string = '';
  public resultText: string = '';
  public userId: ObjectId | null = null;
  public gameId: ObjectId | null = null;
  public supabaseGameId: string | null = '';
  public superCharacteristicAvailable: boolean = false;
  public superCharacteristicTriggered: number = 0;
  public unmatchedFortune: boolean = false;
  public ndsStats: NdsDiceStats | null = null;
  public meta: DiceMeta[] = [];

  constructor(params?: Partial<DiceDetails>) {
    if (!!params) {
      this.description = params.description ?? this.description;
      this.resultText = params.resultText ?? this.resultText;
      this.userId = parseObjectId(params.userId);
      this.gameId = parseObjectId(params.gameId);
      this.supabaseGameId = params.supabaseGameId ?? this.supabaseGameId;
      this.superCharacteristicAvailable =
        params.superCharacteristicAvailable ?? this.superCharacteristicAvailable;
      this.superCharacteristicTriggered = parseNumber(
        params.superCharacteristicTriggered,
        this.superCharacteristicTriggered
      );
      this.unmatchedFortune = parseBoolean(params.unmatchedFortune, this.unmatchedFortune);
      this.ndsStats = !!params.ndsStats ? new NdsDiceStats(params.ndsStats) : this.ndsStats;
      this.meta = Array.isArray(params.meta) ? params.meta.map(m => new DiceMeta(m)) : this.meta;
    }
  }
}

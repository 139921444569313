import { Edge } from '../enums';
import { parseNumber } from '../functions/utilities';

export class Position2D {
  public x: number = 0;
  public y: number = 0;

  constructor(params?: Partial<Position2D>);
  constructor(x: number, y: number);
  constructor(paramsOrX?: Partial<Position2D> | number, y?: number) {
    if (typeof paramsOrX !== 'undefined') {
      if (typeof paramsOrX === 'number') {
        this.x = parseNumber(paramsOrX, this.x);
        this.y = parseNumber(y, this.y);
      } else {
        this.x = parseNumber(paramsOrX.x, this.x);
        this.y = parseNumber(paramsOrX.y, this.y);
      }
    }
  }

  public traverseEdge(edge: Edge): void {
    switch (edge) {
      case Edge.Up:
        this.y -= 1;
        break;
      case Edge.Down:
        this.y += 1;
        break;
      case Edge.Left:
        this.x -= 1;
        break;
      case Edge.Right:
        this.x += 1;
        break;
      default:
        throw new Error(`Invalid edge given: ${edge}`);
    }
  }

  public static validate(input: Position2D): string[] {
    const errors: string[] = [];
    // errors.push(...RpgClassValidators.number(input.x));
    // errors.push(...RpgClassValidators.number(input.y));
    return errors;
  }
}

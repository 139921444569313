import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from './cookie.service';
import { CookieKey, Logger } from '@rpg/core/base';
import { Subscription, filter } from 'rxjs';

const MAX_TRACE_SIZE = 5;

type Trace = { url: string; queryParams: Record<string, string> };

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private _homePage: string = '';
  private _loginRedirectUrl: string = '';
  private _tracer: Subscription | undefined;
  private _urlTrace: Trace[] = [];

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _cookieService: CookieService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this._loginRedirectUrl = this._cookieService.get(CookieKey.Redirect) || '';
  }

  public enableRouteTracing(): void {
    this._tracer = this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this._urlTrace.unshift({
          url: this._router
            .parseUrl((event as NavigationEnd).urlAfterRedirects)
            .root.children.primary.segments.reduce((acc, next) => `${acc}/${next.path}`, ''),
          queryParams: this._activatedRoute.snapshot.queryParams,
        });

        if (this._urlTrace.length > MAX_TRACE_SIZE) {
          this._urlTrace = this._urlTrace.slice(0, MAX_TRACE_SIZE);
        }
      });
  }

  public disableRouteTracing(): void {
    this._tracer?.unsubscribe();
  }

  public canGoBack(): boolean {
    return this._urlTrace.length > 1;
  }

  public get backUrl(): string {
    return this._urlTrace[1]?.url ?? this._homePage;
  }

  public get backQueryParams(): Record<string, string> {
    return this._urlTrace[1]?.queryParams ?? {};
  }

  public getBackUrlWithFallback(fallback?: string): string {
    return this._urlTrace[1]?.url ?? fallback ?? this._homePage;
  }

  public consumeBackUrl(): Trace {
    this._urlTrace.shift(); // Shifts off current url
    return this._urlTrace[0] ?? { url: this.homePage, queryParams: {} };
  }

  public setLoginRedirect(url: string): void {
    this._loginRedirectUrl = url;
    this._cookieService.set(CookieKey.Redirect, url);
  }

  public hasLoginRedirect(): boolean {
    return this._loginRedirectUrl !== '';
  }

  public useLoginRedirect(): string {
    let url: string = this._loginRedirectUrl;
    Logger.log('saved redirect', url);
    if (url === '') {
      url = this.homePage;
    }
    Logger.log('using redirect', url);
    this._loginRedirectUrl = '';
    if (isPlatformBrowser(this._platformId)) {
      this._cookieService.set(CookieKey.Redirect, '');
    }
    return url;
  }

  public get twitterPage(): string {
    return 'https://twitter.com/rpgsessions';
  }

  public get instagramPage(): string {
    return 'https://www.instagram.com/rpgsessions';
  }

  public get discordPage(): string {
    return 'https://discord.gg/ZVK8u5R';
  }

  public get patreonPage(): string {
    return 'https://www.patreon.com/rpgsessions';
  }

  public get patreonSubscribe(): string {
    return 'https://www.patreon.com/bePatron?u=16389612';
  }

  public get discordBotInvite(): string {
    return 'https://discord.com/api/oauth2/authorize?client_id=534606682928578572&permissions=265216&scope=bot';
  }

  public set homePage(value: string) {
    this._homePage = value;
  }
  public get homePage(): string {
    return this._homePage;
  }
}

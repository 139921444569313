import { Component, ChangeDetectionStrategy, Inject, NgZone } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@ngneat/reactive-forms';
import { OverlayResponse, OverlayResponseType } from '@rpg/ngx/core';

export interface SingleInputOverlayOptions {
  initialValue: string;
  allowEmpty: boolean;
  placeholder: string;
  submitLabel: string;
  cancelLabel: string;
  type: string;
}

const inputOptionsDefaults: SingleInputOverlayOptions = {
  initialValue: '',
  allowEmpty: false,
  placeholder: 'Value',
  submitLabel: 'Save',
  cancelLabel: 'Cancel',
  type: 'text',
};

@Component({
  selector: 'rpg-single-input',
  templateUrl: './single-input.component.html',
  styleUrls: ['./single-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleInputOverlayComponent {
  public group = new UntypedFormGroup({});
  public inputControl = new FormControl<string>('');

  constructor(
    public dialogRef: MatDialogRef<SingleInputOverlayComponent, OverlayResponse<string>>,
    @Inject(MAT_DIALOG_DATA) public options: SingleInputOverlayOptions,
    private _zone: NgZone
  ) {
    this.options = { ...inputOptionsDefaults, ...this.options };
    this.inputControl.setValue(this.options.initialValue);
    if (!this.options.allowEmpty) {
      this.inputControl.setValidators(Validators.required);
      this.inputControl.updateValueAndValidity();
    }
  }

  public cancel(): void {
    // We may be outside the zone here, so tell Angular!
    this._zone.run(() => {
      this.dialogRef.close(new OverlayResponse(OverlayResponseType.Cancel));
    });
  }

  public submit({ value, valid }: FormControl<string>): void {
    if (!valid) {
      return;
    }
    // We may be outside the zone here, so tell Angular!
    this._zone.run(() => {
      this.dialogRef.close(new OverlayResponse(OverlayResponseType.Confirm, value));
    });
  }

  public clear(): void {
    this.inputControl.setValue('');
  }
}

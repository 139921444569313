<ng-container *rxLet="state$; let state">
  <img class="characteristic-single__image" [src]="state.attrImage" />
  <ng-container *ngIf="state.editMode; else displayMode">
    <span
      [class.higherOffset]="higherOffset"
      class="characteristic-single__input"
      rpgContentEditableNumber
      contentEditable
      [formControl]="control"
      (blur)="checkValue()"
      >{{ control.value }}</span
    >
  </ng-container>
  <ng-template #displayMode>
    <span class="characteristic-single__input" [class.higherOffset]="higherOffset">
      {{ state.resolvedValue }} <ng-content></ng-content>
    </span>
  </ng-template>
  <label class="characteristic-single__label">
    {{ state.label }}
  </label>
  <div class="characteristic-single__super-toggle-container" *ngIf="superCharacteristicsEnabled">
    <span
      class="characteristic-single__super-toggle"
      [class.toggle]="state.editMode"
      [class.selected]="superCharacteristicControl.value"
      (click)="state.editMode ? toggleSuperCharacteristic() : null"
    >
      {{ 'words.Super' | transloco }}
    </span>
  </div>
</ng-container>

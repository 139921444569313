import { Validators } from '@angular/forms';
import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleAttachmentModificationData } from '@rpg/core/vehicle';
import { NDSVehicleModifierFormGroupFactory } from './modifiers';

export function ndsVehicleAttachmentModificationToFormGroup(
  mod: NdsVehicleAttachmentModificationData
): FormGroup<NdsVehicleAttachmentModificationData> {
  return new FormGroup<NdsVehicleAttachmentModificationData>({
    id: new FormControl(mod.id),
    name: new FormControl(mod.name, [Validators.required]),
    description: new FormControl(mod.description),
    enabled: new FormControl(mod.enabled),
    modifiers: new FormArray(mod.modifiers.map(m => NDSVehicleModifierFormGroupFactory(m))),
  });
}

import { DiceType } from '../enums';
import { DiceSingle } from '../models';

export function sortDice(dice: DiceSingle[]): DiceSingle[] {
  const order = DiceType.diceOrder;
  let diceA, diceB;
  return dice.sort((a, b) => {
    diceA = order.find(d => d.type === a.type);
    diceB = order.find(d => d.type === b.type);
    if (!diceA || !diceB) {
      return 0;
    } else if (diceA.order > diceB.order) {
      return 1;
    } else if (diceB.order > diceA.order) {
      return -1;
    } else {
      return 0;
    }
  });
}

export function sortDiceTypes(types: DiceType[]): DiceType[] {
  const order = DiceType.diceOrder;
  let typeA, typeB;
  return types.sort((a, b) => {
    typeA = order.find(d => d.type === a);
    typeB = order.find(d => d.type === b);
    if (!typeA || !typeB) {
      return 0;
    } else if (typeA.order > typeB.order) {
      return 1;
    } else if (typeB.order > typeA.order) {
      return -1;
    } else {
      return 0;
    }
  });
}

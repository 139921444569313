import produce from 'immer';
import { EMPTY, firstValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateOperator } from '@ngxs/store';
import { CookieKey, Logger } from '@rpg/core/base';
import { Auth2HttpService, CookieService } from '@rpg/ngx/core';
import { AuthSB, UserSB } from '@sessions/supabase';
import { Session } from '@supabase/supabase-js';

import { RouterActions } from '../router/router.actions';
import { UserActions } from '../user.actions';
import { Auth2Actions } from './auth2.actions';

// let lastNotifiedToken = '';
let authListenerConnected = false;

export interface Auth2StateModel {
  accessToken: string | undefined;
  error: string | undefined;
  loading: boolean;
  session: Session | null;
}

@State<Auth2StateModel>({
  name: 'auth2',
  defaults: {
    accessToken: undefined,
    error: undefined,
    loading: false,
    session: null,
  },
})
@Injectable()
export class Auth2State {
  constructor(
    private auth: AuthSB,
    private auth2Http: Auth2HttpService,
    private user: UserSB,
    private cookies: CookieService
  ) {}

  /**
   * Returns true if the user is authenticated, else false
   */
  @Selector([Auth2State])
  static authenticated(auth: Auth2StateModel) {
    return !!auth.accessToken;
  }

  /**
   * Returns the access token currently available in the state
   */
  @Selector([Auth2State])
  static accessToken(auth: Auth2StateModel) {
    return auth.accessToken ?? '';
  }

  /**
   * Returns the error message currently for the state, else undefined
   */
  @Selector([Auth2State])
  static error(auth: Auth2StateModel) {
    return auth.error;
  }

  /**
   * Returns true if any auth UI should show as busy, else false
   */
  @Selector([Auth2State])
  static loading(auth: Auth2StateModel) {
    return auth.loading;
  }

  @Action(Auth2Actions.AuthStateChange)
  async authStateChange(
    { dispatch, setState, getState }: StateContext<Auth2StateModel>,
    { event, session }: Auth2Actions.AuthStateChange
  ) {
    Logger.log('AUTH STATE CHANGED!', event, session);
    // if (event === 'SIGNED_IN' && !session) {
    //   // weird case, ignore this:
    //   return EMPTY;
    // }

    // const { accessToken } = getState();
    // const alreadySignedIn = !!accessToken;

    // setState(
    //   produce(draft => {
    //     draft.session = session;
    //     draft.accessToken = session?.access_token;
    //     return draft;
    //   })
    // );

    // switch (event) {
    //   case 'SIGNED_IN':
    //     if (!!session?.access_token && lastNotifiedToken !== session?.access_token) {
    //       lastNotifiedToken = session.access_token;
    //       this.auth2Http.updateAuthState({ event, session }).subscribe();
    //       this.cookies.set(CookieKey.SupabaseAuthToken, session?.refresh_token ?? '');
    //     }
    //     if (!alreadySignedIn) {
    //       return dispatch(new Auth2Actions.SignInComplete());
    //     }
    //     return EMPTY;
    //   case 'SIGNED_OUT':
    //     this.auth2Http.updateAuthState({ event, session }).subscribe();
    //     lastNotifiedToken = '';
    //     this.cookies.delete(CookieKey.SupabaseAuthToken);
    //     return dispatch(new Auth2Actions.SignOutComplete());
    //   default:
    //     return EMPTY;
    // }
  }

  /**
   * Restore Actions - These handle specifically checking the user auth on page load,
   * loading the session into memory and then kicking off anything else that needs to occur
   * before starting the application
   */
  @Action(Auth2Actions.Restore)
  async restore({ setState, dispatch }: StateContext<Auth2StateModel>) {
    const { data } = await this.auth.session;
    console.log('auth session restore', data);
    if (!authListenerConnected) {
      authListenerConnected = true;
      this.auth.onStateChange((event, session) =>
        dispatch(new Auth2Actions.AuthStateChange(event, session))
      );
    }
    if (!!data.session) {
      setState(
        produce(draft => {
          draft.session = data.session;
          draft.accessToken = data.session.access_token;
          return draft;
        })
      );
      return dispatch(new UserActions.Restore());
    } else {
      const refreshToken = this.cookies.get(CookieKey.SupabaseAuthToken);
      if (!!refreshToken) {
        const { error, data: session } = await this.auth.signInWithRefreshToken(refreshToken);
        if (!error && !!session.session) {
          setState(
            produce(draft => {
              draft.session = session.session;
              draft.accessToken = session.session?.access_token;
              return draft;
            })
          );
          return dispatch(new UserActions.Restore());
        }
      }
    }
    return dispatch(new Auth2Actions.RestoreFailed());
  }

  /**
   * Should be called when the inital application restoration fails for any way,
   * typically this will be because the session is expired. It should handle
   * any cleanup and then kicking off the application
   */
  @Action(Auth2Actions.RestoreFailed)
  async restoreFailed({ setState, getState, dispatch }: StateContext<Auth2StateModel>) {
    const { session } = getState();
    if (!!session) {
      await this.auth.signOut();
    }
    setState(
      produce(draft => {
        draft.accessToken = undefined;
        draft.error = undefined;
        draft.loading = false;
        return draft;
      })
    );
    return dispatch(new RouterActions.InitialNavigation());
  }

  /**
   * Called in any case where we are dealing with a callback auth flow, most likely
   * this will come from the registration flow or the magic links, where we get this callback after
   * the user clicks the link in the confirmation email
   */
  @Action(Auth2Actions.CallbackLoginComplete)
  async callbackLoginComplete({ setState, dispatch }: StateContext<Auth2StateModel>) {
    const { data } = await this.auth.session;
    const session = data.session;
    if (!session) return dispatch(new Auth2Actions.Error('Unable to complete login'));
    if (!!session.user) {
      await firstValueFrom(this.auth2Http.legacyLink());
    }
    return dispatch(new Auth2Actions.SignInComplete());
  }

  /**
   * Specifically handle the flow where the user wants to sign in with their email.
   * This also handles the "magic link" flow start, if only an email is provided
   */
  @Action(Auth2Actions.SignInWithEmail)
  async signInWithEmail(
    { setState, dispatch }: StateContext<Auth2StateModel>,
    { email, password }: Auth2Actions.SignInWithEmail
  ) {
    setState(startOperation());
    let error;
    if (!password) {
      const { error: linkError } = await this.auth.sendMagicLink(email);
      error = linkError;
    } else {
      const { error: emailError } = await this.auth.signInWithEmail(email, password);
      error = emailError;
    }
    /**
     * The following code enables fallback to our legacy auth system that will automatically
     * migrate users to supabase. We should remove this code in the future when we're ready
     * to ditch our legacy stuff
     */
    if (!!error && !!password) {
      try {
        await firstValueFrom(this.auth2Http.legacyLogin(email, password ?? ''));
        // If the check was successfull, the password should have been migrated by the server
        const finalAttempt = await this.auth.signInWithEmail(email, password);
        if (!!finalAttempt.error) {
          // If an error was returned, the server was not able to update the password, but that
          // should have resulted in the authHTTP throwing. So this is just a sanity thing.
          return dispatch(new Auth2Actions.Error(`${error.name}: ${error.message}`));
        }
        // Supabase is signed in correctly at this point, so let's go ahead and mark the signin complete
        return dispatch(new Auth2Actions.SignInComplete());
      } catch (e) {
        // The authHTTP failed so username/pass is actually wrong, just return the OG error
        return dispatch(new Auth2Actions.Error(`${error.name}: ${error.message}`));
      }
    }
    /**
     * This will eventually be what we want, after we remove the legacy code above
     */
    // if (!!error) return dispatch(new Auth2Actions.Error(`${error.status}: ${error.message}`));
    return EMPTY;
  }

  /**
   * Specifically handle the flow where the user wants to sign in with their social
   * auth provider.
   */
  @Action(Auth2Actions.SignInWithProvider)
  async signInWithProvider(
    { setState, dispatch }: StateContext<Auth2StateModel>,
    { provider }: Auth2Actions.SignInWithProvider
  ) {
    setState(startOperation());
    const { error } = await this.auth.signInWithProvider(provider);
    if (!!error) return dispatch(new Auth2Actions.Error(`${error.name}: ${error.message}`));
    // We can't actually do anything here, because the social logins redirect away from the page
    return EMPTY;
  }

  /**
   * Regardless of how the sign in flow was kicked off, it should eventually end
   * here, which establishes the authentication state before triggering a request
   * to populate the user data
   */
  @Action(Auth2Actions.SignInComplete)
  async signInComplete({ setState, dispatch }: StateContext<Auth2StateModel>) {
    const { data } = await this.auth.session;
    const session = data.session;
    // This should never happen, but it's a great sanity check
    if (!session) return dispatch(new Auth2Actions.Error(`No Session found`));
    setState(
      produce(draft => {
        draft.session = session;
        draft.accessToken = session.access_token;
        draft.loading = false;
        draft.error = undefined;
        return draft;
      })
    );

    return dispatch(new UserActions.NewLogin());
  }

  /**
   * Specifically handle the sign up flow when using a username and password
   */
  @Action(Auth2Actions.SignUpWithEmail)
  async signUpWithEmail(
    { setState, dispatch }: StateContext<Auth2StateModel>,
    { email, password, username }: Auth2Actions.SignUpWithEmail
  ) {
    setState(startOperation());
    const uniqueEmail = await this.user.isEmailUnique(email);
    if (!uniqueEmail) return dispatch(new Auth2Actions.Error('Email is already registered'));
    const uniqueUsername = await this.user.isUsernameUnique(username);
    if (!uniqueUsername) return dispatch(new Auth2Actions.Error('Username is already registered'));
    if (!password) return dispatch(new Auth2Actions.Error('Password is requried!'));

    const { error } = await this.auth.signUpWithEmail(email, username, password);
    if (!!error) return dispatch(new Auth2Actions.Error(`${error.name}: ${error.message}`));
    try {
      await firstValueFrom(this.auth2Http.legacyRegister(email, username));
    } catch (e) {
      return dispatch(new Auth2Actions.Error(`Legacy: Unable to complete registration`));
    }
    return dispatch(new Auth2Actions.SignUpComplete());
  }

  /**
   * Regardless of how the sign up flow was kicked off, it should result with this
   * method being called, which will notify the user that they need to confirm
   * their email
   * TODO: Auth2 - This may not be needed for social providers? Need to check
   */
  @Action(Auth2Actions.SignUpComplete)
  async signUpComplete({ dispatch }: StateContext<Auth2StateModel>) {
    return dispatch(new RouterActions.Navigate(['/', 'auth2', 'pending']));
  }

  /**
   * This should be called to start the sign out flow for the user
   */
  @Action(Auth2Actions.SignOut)
  async signOut({ setState, dispatch }: StateContext<Auth2StateModel>) {
    setState(startOperation());
    const { error } = await this.auth.signOut();
    if (!!error) {
      return dispatch(new Auth2Actions.Error(`${error.name}: ${error.message}`));
    }
    return EMPTY;
  }

  /**
   * This is called when the signout flow is completed and should clear the session
   */
  @Action(Auth2Actions.SignOutComplete)
  async signOutComplete({ setState, dispatch }: StateContext<Auth2StateModel>) {
    setState(
      produce(draft => {
        draft.accessToken = undefined;
        draft.session = null;
        draft.error = undefined;
        draft.loading = false;
        return draft;
      })
    );
    return dispatch(new RouterActions.Navigate(['/', 'auth2']));
  }

  /**
   * Sends a magic link that will auto-login a user
   */
  @Action(Auth2Actions.SendMagicLink)
  async sendMagicLink(
    { setState, dispatch }: StateContext<Auth2StateModel>,
    { email }: Auth2Actions.SendMagicLink
  ) {
    setState(startOperation());
    const { error } = await this.auth.sendMagicLink(email);
    if (!!error) return dispatch(new Auth2Actions.Error(`${error.name}: ${error.message}`));
    return dispatch(new Auth2Actions.ResetState());
  }

  /**
   * This should be called when the user submits their email to reset their password
   */
  @Action(Auth2Actions.SendEmailReset)
  async sendEmailreset(
    { setState, dispatch }: StateContext<Auth2StateModel>,
    { email }: Auth2Actions.SendEmailReset
  ) {
    setState(startOperation());
    const { error } = await this.auth.sendEmailReset(email);
    if (!!error) return dispatch(new Auth2Actions.Error(`${error.name}: ${error.message}`));
    return dispatch(new Auth2Actions.ResetState());
  }

  /**
   * This is called when the reset email flow has completed
   */
  @Action(Auth2Actions.ResetPassword)
  async resetPassword(
    { setState, dispatch }: StateContext<Auth2StateModel>,
    { newPassword }: Auth2Actions.ResetPassword
  ) {
    setState(startOperation());
    const { error } = await this.auth.resetPassword(newPassword);
    if (!!error) return dispatch(new Auth2Actions.Error(`${error.name}: ${error.message}`));
    return dispatch(new Auth2Actions.CallbackLoginComplete());
  }

  /**
   * This should be called to reset the state for any of the auth pages, this typically
   * means that it is called in the constructor for the pages to ensure the state is ready
   */
  @Action(Auth2Actions.ResetState)
  async resetState(
    { setState }: StateContext<Auth2StateModel>,
    { newState }: Auth2Actions.ResetState
  ) {
    setState(
      produce(draft => {
        draft.error = newState?.error;
        draft.loading = newState?.loading ?? false;
        return draft;
      })
    );
  }

  /**
   * Called to populate an error for any of the views
   */
  @Action(Auth2Actions.Error)
  async error({ setState }: StateContext<Auth2StateModel>, { message }: Auth2Actions.Error) {
    setState(
      produce(draft => {
        draft.error = message;
        draft.loading = false;
        return draft;
      })
    );
  }
}

function startOperation(): StateOperator<Auth2StateModel> {
  return produce(draft => {
    draft.loading = true;
    draft.error = undefined;
    return draft;
  });
}

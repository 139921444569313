/* eslint-disable @typescript-eslint/ban-types */
import { HttpResponse } from '@angular/common/http';
import { PaginatedList, Newable } from '@rpg/core/base';

/**
 * @ignore
 *
 * Provides the HttpAdapter methods used by the [HttpService]{@link HttpService}
 */
export class HttpAdapter {
  // eslint-disable-next-line no-magic-numbers
  private static _successCodeMin: number = 200;
  // eslint-disable-next-line no-magic-numbers
  private static _successCodeMax: number = 299;
  // eslint-disable-next-line no-magic-numbers
  private static _invalidCode: number = 400;

  /**
   * Applies an adapter function to transform the response body
   *
   * @param {HttpResponse<any>} res Http Response object
   * @param {Function} adapterFn Function to transform the response body
   * @returns {HttpResponse<any>} Http Response object
   */
  public static baseMapValue(res: HttpResponse<any>, adapterFn?: Function): HttpResponse<any> {
    if (!res) {
      return res;
    } else if (
      HttpAdapter._successCodeMin <= res.status &&
      res.status <= HttpAdapter._successCodeMax
    ) {
      try {
        return res.clone({
          body: adapterFn ? adapterFn.call(this, res.body) : res.body,
        });
      } catch (e) {
        return res.clone({
          status: HttpAdapter._invalidCode,
          statusText: `@rpg/app-http Adapter failed with error: ${JSON.stringify(e)}`,
        });
      }
    } else {
      return res;
    }
  }

  /**
   * Passes the response body into the constructor of a given class
   *
   * @param {HttpResponse<any>} res Http Response object
   * @param {Newable<T>} classToMap A class with a constructor
   * @returns {HttpResponse<T | T[]>} Http Response object
   */
  public static baseMapClass<T>(
    res: HttpResponse<any>,
    classToMap: Newable<T>
  ): HttpResponse<T | T[]> {
    if (!classToMap) {
      return res;
    } else if (!res) {
      return res;
    } else if (
      HttpAdapter._successCodeMin <= res.status &&
      res.status <= HttpAdapter._successCodeMax
    ) {
      try {
        if (Array.isArray(res.body)) {
          let mapped: Array<T> = [];
          let validData = true;
          for (const m of res.body) {
            if (!!m && typeof m === 'object' && !Array.isArray(m)) {
              mapped = [...mapped, new classToMap(m)];
            } else {
              validData = false;
              break;
            }
          }
          if (!validData) {
            throw new Error('Cannot map non-object in array response type to class');
          }
          return res.clone({ body: mapped });
        } else if (!!res.body && typeof res.body === 'object') {
          return res.clone({ body: new classToMap(res.body) });
        } else {
          throw new Error('Cannot map non-object response type to class');
        }
      } catch (e) {
        return res.clone({
          status: HttpAdapter._invalidCode,
          statusText: `@rpg/app-http Adapter failed to map ${JSON.stringify(
            res.body
          )} to ${JSON.stringify(classToMap)} with error: ${JSON.stringify(e)}`,
        });
      }
    } else {
      return res;
    }
  }

  /**
   * Applies an adapter function to transform the response body
   *
   * @param {HttpResponse<any>} res Http Response object
   * @param {Newable<T>} classToMap A class with a constructor
   * @returns {HttpResponse<any>} Http Response object
   */
  public static baseMapPaginatedList<T>(
    res: HttpResponse<any>,
    classToMap: Newable<T>
  ): HttpResponse<PaginatedList<T>> {
    if (!res) {
      return res;
    } else if (
      HttpAdapter._successCodeMin <= res.status &&
      res.status <= HttpAdapter._successCodeMax
    ) {
      try {
        const docs = Array.isArray(res?.body?.docs)
          ? res.body.docs.map((d: T) => new classToMap(d))
          : [];
        const count = res?.body?.totalDocs ?? 0;
        const mapped = new PaginatedList<T>(Array.isArray(docs) ? docs : [docs], count);

        return res.clone({
          body: mapped,
        });
      } catch (e) {
        return res.clone({
          status: HttpAdapter._invalidCode,
          statusText: `@rpg/app-http Adapter failed with error: ${JSON.stringify(e)}`,
        });
      }
    } else {
      return res;
    }
  }
}

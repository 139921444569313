import { AuthChangeEvent, Provider, Session } from '@supabase/supabase-js';

export namespace Auth2Actions {
  export class Restore {
    static readonly type = '[Auth2] Restore';
  }

  export class RestoreFailed {
    static readonly type = '[Auth2] Restore Failed';
  }

  export class CallbackLoginComplete {
    static readonly type = '[Auth2] Callback Login Complete';
  }

  export class SignUpWithEmail {
    static readonly type = '[Auth2] Sign Up With Email';
    constructor(public email: string, public username: string, public password?: string) {}
  }

  export class SignInWithEmail {
    static readonly type = '[Auth2] Sign In With Email';
    constructor(public email: string, public password?: string) {}
  }

  export class SignInWithProvider {
    static readonly type = '[Auth2] Sign In With Provider';
    constructor(public provider: Provider) {}
  }

  export class SignOut {
    static readonly type = '[Auth2] Sign Out';
  }

  export class ResetState {
    static readonly type = '[Auth2] Reset State';
    constructor(public newState?: { loading?: boolean; error?: string }) {}
  }

  export class Error {
    static readonly type = '[Auth2] Error';
    constructor(public message: string) {}
  }

  export class SignUpComplete {
    static readonly type = '[Auth2] Sign Up Complete';
  }

  export class SignInComplete {
    static readonly type = '[Auth2] Sign In Complete';
  }

  export class SignOutComplete {
    static readonly type = '[Auth2] Sign Out Complete';
  }

  export class SendMagicLink {
    static readonly type = '[Auth2] Send Magic Link';
    constructor(public email: string) {}
  }

  export class SendEmailReset {
    static readonly type = '[Auth2] Send Email Reset';
    constructor(public email: string) {}
  }

  export class ResetPassword {
    static readonly type = '[Auth2] Reset Password';
    constructor(public newPassword: string) {}
  }

  export class AuthStateChange {
    static readonly type = '[Auth2] Auth State Change';
    constructor(public event: AuthChangeEvent, public session: Session | null) {}
  }
}

export enum CookieKey {
  AuthToken = '[cookie key] auth token',
  SiteTheme = '[cookie key] site theme',
  Redirect = '[cookie key] redirect',
  TranslationStatus = '[cookie key] translation status',
  SupabaseAccessToken = '[cookie key] supabase access token',
  SupabaseRefreshToken = '[cookie key] supabase refresh token',
  SupabaseAuthToken = '[cookie key] supabase auth token',
}

export namespace CookieKey {
  export function getNameFromKey(key: CookieKey | string): string {
    switch (key) {
      case CookieKey.AuthToken:
        return 'rpgTok';
      case CookieKey.SiteTheme:
        return 'rpgTheme';
      case CookieKey.Redirect:
        return 'rpgRedirect';
      case CookieKey.TranslationStatus:
        return 'rpgTranslationStatus';
      case CookieKey.SupabaseAccessToken:
        return 'sb-access-token';
      case CookieKey.SupabaseRefreshToken:
        return 'sb-refresh-token';
      case CookieKey.SupabaseAuthToken:
        return 'sb-auth-token';
      default:
        return key;
    }
  }
}

import { Injectable } from '@angular/core';
import {
  HttpService,
  Get,
  Put,
  Post,
  Path,
  Body,
  Query,
  MapClass,
  ResponseType,
  MapValue,
} from '@rpg/ngx/http';
import { EMPTY, Observable } from 'rxjs';
import { Permission } from '@rpg/core/base';
import { CheckUniqueType } from '@rpg/core/base';
import { User, UserProfileUpdate, UserSettings, UserPasswordUpdate } from '@rpg/core/user';
import { LoginType } from '@rpg/core/auth';
import { ObjectId } from 'bson';

function mapRestoreUser(val: { user: User; permissions: Permission[] }) {
  return {
    user: new User(val.user),
    permissions: Array.isArray(val.permissions) ? val.permissions : [],
  };
}

@Injectable({
  providedIn: 'root',
})
export class UserHttpService extends HttpService {
  @Get('/user')
  @MapClass(User)
  public getCurrentUser(): Observable<User> {
    return EMPTY;
  }

  @Get('/user/restore')
  @MapValue(mapRestoreUser)
  public restoreUser(): Observable<{ user: User; permissions: Permission[] }> {
    return EMPTY;
  }

  @Get('/user/:id')
  @MapClass(User)
  public getUserById(@Path('id') userId: ObjectId): Observable<User> {
    return EMPTY;
  }

  @Put('/user/:id')
  public updateUserById(
    @Path('id') userId: ObjectId,
    @Body() updates: Partial<UserProfileUpdate>
  ): Observable<boolean> {
    return EMPTY;
  }

  @Put('/user/:id/settings')
  public updateUserSettingsById(
    @Path('id') userId: ObjectId,
    @Body() updates: Partial<UserSettings>
  ): Observable<boolean> {
    return EMPTY;
  }

  @Put('/user/:id/patreon')
  public updateUserPatreonFeaturesById(
    @Path('id') userId: ObjectId,
    @Body() updates: Partial<UserSettings>
  ): Observable<boolean> {
    return EMPTY;
  }

  @Put('/user/:id/password')
  public updateUserPasswordById(
    @Path('id') userId: ObjectId,
    @Body() update: UserPasswordUpdate
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/user/:id/password')
  public enableUserPassword(
    @Path('id') userId: ObjectId,
    @Body() newPassword: { newPassword: string }
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/user/:id/linkDiscord')
  public linkUserDiscordById(
    @Path('id') userId: ObjectId,
    @Body('discordId') discordId: string,
    @Body('guildId') guildId: string,
    @Body('name') name: string
  ): Observable<boolean> {
    return EMPTY;
  }

  @Get('/user/check')
  public checkUnique(
    @Query('type') type: CheckUniqueType,
    @Query('value') value: string
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/user/:id/unlinkDiscord')
  public unlinkDiscord(@Path('id') userId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Get('/user/socialLinkTransactionId', {
    responseType: ResponseType.Text,
  })
  public getSocialLinkTransactionId(@Query('loginType') loginType: LoginType): Observable<string> {
    return EMPTY;
  }

  @Post('/user/linkUserOAuth')
  public linkUserSocialOAuth(
    @Body('loginType') loginType: LoginType,
    @Body('code') code: string,
    @Body('state') state: string
  ): Observable<User> {
    return EMPTY;
  }

  @Post('/user/unlinkUserOAuth')
  public unlinkUserSocialOAuth(@Body('loginType') loginType: LoginType): Observable<User> {
    return EMPTY;
  }
}

import { parseEnum, parseNumber } from '../../base';
import { Dice } from '@rpg/core/dice';
import { InitiativeType } from '../enums';

export class InitiativeOrder {
  public type!: InitiativeType;
  public roll: Dice | null = null;
  public selectedSlot: number = 1;

  constructor(params?: Partial<InitiativeOrder>) {
    if (!!params) {
      this.type = parseEnum(InitiativeType, params.type, this.type);
      this.roll = !!params.roll ? new Dice(params.roll) : this.roll;
      this.selectedSlot = parseNumber(params.selectedSlot, this.selectedSlot);
    }
  }
}

import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { CookieKey, FeatureFlag } from '@rpg/core/base';
import { LanguageOption, ThemeOption } from '@rpg/core/user';
import { AnalyticsService } from '@rpg/ngx/analytics';
import {
  Auth2HttpService,
  CookieService,
  MarkdownConfigService,
  RedirectService,
  SeoService,
  ServiceWorkerService,
  ThemeService,
} from '@rpg/ngx/core';
import { PermissionService } from '@rpg/ngx/permissions';
import { environment } from '@sessions/env';
import { Auth3Actions, FlagsActions, UserActions } from '@sessions/state';
import { AuthSB } from '@sessions/supabase';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'rpg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isProduction: boolean = environment.production;

  constructor(
    private router: Router,
    private store: Store,
    private sw: ServiceWorkerService,
    private analyticsService: AnalyticsService,
    private cookieService: CookieService,
    private markdownConfig: MarkdownConfigService,
    private permissionService: PermissionService,
    private redirectService: RedirectService,
    private seoService: SeoService,
    private themeService: ThemeService,
    private tranlocoService: TranslocoService,
    private auth: AuthSB,
    private authHttp: Auth2HttpService,
    @Inject(PLATFORM_ID)
    private platformId: Object
  ) {
    this.seoService.configureSeo();
    this.markdownConfig.addGlobalDiceSupport();
    this.themeService.restoreThemeIfExists();
    if (isPlatformBrowser(this.platformId)) {
      this.analyticsService.init('EWDUHUWM', {
        url: 'https://paradise-two.unboundlegends.com/script.js',
      });
      this.analyticsService.enableRouteTracking();
    }
    this.redirectService.enableRouteTracing();
    this.redirectService.homePage = '/dice';
    this._init();
  }

  public get _icon(): IconProp {
    return this.themeService.currentTheme === ThemeOption.Dark ? 'sun' : 'moon';
  }

  public get _color(): string {
    return this.themeService.currentTheme === ThemeOption.Dark ? 'primary' : 'accent';
  }

  public toggleTheme(): void {
    this.themeService.changeTheme(
      this.themeService.currentTheme === ThemeOption.Dark ? ThemeOption.Light : ThemeOption.Dark
    );
  }

  public toggleTranslations(): void {
    const translationStatusOn = this.cookieService.get(CookieKey.TranslationStatus) ?? 'off';
    const nextStatus = translationStatusOn === 'on' ? 'off' : 'on';
    this.cookieService.set(CookieKey.TranslationStatus, nextStatus);
    const translation = this.tranlocoService.getTranslation('en-fallback');
    this.tranlocoService.setTranslation(translation, 'en-fallback');
  }

  // private async _initAuth2() {
  //   this.setGlobalFlags();
  //   this.setTestingFlags();
  //   this.sw.checkForUpdates();
  //   if (isPlatformBrowser(this.platformId)) {
  //     // Trying as a short term fix for OAuth
  //     setTimeout(() => {
  //       this.store.dispatch(new Auth2Actions.Restore());
  //       // eslint-disable-next-line no-magic-numbers
  //     }, 200);
  //   } else {
  //     this.router.initialNavigation();
  //   }
  // }

  private async _init(): Promise<void> {
    this.setGlobalFlags();
    this.setTestingFlags();
    this.sw.initialCheck();
    const token = this.cookieService.get(CookieKey.AuthToken);
    console.log('getting auth token', token);
    let languageToLoad: LanguageOption = LanguageOption.English;
    if (!!token && token !== 'undefined' && token !== 'null') {
      try {
        const userWithToken = await firstValueFrom(this.authHttp.refreshToken(`Bearer ${token}`));
        if (!!userWithToken) {
          // Login
          this.store.dispatch(new Auth3Actions.SetToken(userWithToken.token));
          this.store.dispatch(new UserActions.Set(userWithToken.user));
          this.permissionService.setPermissions(userWithToken.permissions);
          languageToLoad = userWithToken.user.settings.language ?? LanguageOption.English;
        } else {
          this.cookieService.delete(CookieKey.AuthToken);
        }
      } catch (e) {
        this.cookieService.delete(CookieKey.AuthToken);
      }
    } else if (token === 'undefined' || token === 'null') {
      // Clear any possible bad data
      this.cookieService.delete(CookieKey.AuthToken);
    }
    // Preload selected language files
    await firstValueFrom(this.tranlocoService.load(languageToLoad));
    this.router.initialNavigation();
  }

  private setGlobalFlags(): void {
    this.store.dispatch(new FlagsActions.SetFlag(FeatureFlag.GenesysTwilightImperium, true));
  }

  private setTestingFlags(): void {
    if (!environment.production) {
      // Turn on any flags here that are only for review
    }
  }
}

export enum CheckUniqueType {
  Email = 'email',
  Username = 'username',
}

export namespace CheckUniqueType {
  export function toString(type: CheckUniqueType): string {
    switch (type) {
      case CheckUniqueType.Email:
        return 'Email';
      case CheckUniqueType.Username:
        return 'Username';
      default:
        return '';
    }
  }

  export const members: CheckUniqueType[] = [CheckUniqueType.Email, CheckUniqueType.Username];
}

import { DiceType } from './dice-type';

export enum DiceInputNameScheme {
  Proficiency = 'p',
  Ability = 'a',
  Boost = 'b',
  Challenge = 'c',
  Difficulty = 'd',
  Setback = 's',
  RemoveSetback = 'x',
  Force = 'f',
  Triumph = '!',
  Success = '*',
  Advantage = '^',
  Despair = '$',
  Failure = '-',
  Threat = '%',
  PositiveUpgrade = 'u',
  PositiveIncrease = 'i',
  NegativeUpgrade = 'v',
  NegativeIncrease = 'j',
  LightSide = 'l',
  DarkSide = 'n',
}

export namespace DiceInputNameScheme {
  export const members: DiceInputNameScheme[] = [
    DiceInputNameScheme.Proficiency,
    DiceInputNameScheme.Ability,
    DiceInputNameScheme.Boost,
    DiceInputNameScheme.Challenge,
    DiceInputNameScheme.Difficulty,
    DiceInputNameScheme.Setback,
    DiceInputNameScheme.RemoveSetback,
    DiceInputNameScheme.Force,
    DiceInputNameScheme.Triumph,
    DiceInputNameScheme.Success,
    DiceInputNameScheme.Advantage,
    DiceInputNameScheme.Despair,
    DiceInputNameScheme.Failure,
    DiceInputNameScheme.Threat,
    DiceInputNameScheme.PositiveUpgrade,
    DiceInputNameScheme.PositiveIncrease,
    DiceInputNameScheme.NegativeUpgrade,
    DiceInputNameScheme.NegativeIncrease,
    DiceInputNameScheme.LightSide,
    DiceInputNameScheme.DarkSide,
  ];

  export function toParseOptions(): { [key in DiceInputNameScheme]: [DiceInputNameScheme] } {
    // Screw Intellisense
    const parseable: any = {};
    DiceInputNameScheme.members.forEach(m => {
      parseable[m] = [m];
    });
    return parseable;
  }

  export function toDiceType(type: DiceInputNameScheme): DiceType | null {
    switch (type) {
      case DiceInputNameScheme.Proficiency:
        return DiceType.NdsProficiency;
      case DiceInputNameScheme.Ability:
        return DiceType.NdsAbility;
      case DiceInputNameScheme.Boost:
        return DiceType.NdsBoost;
      case DiceInputNameScheme.Challenge:
        return DiceType.NdsChallenge;
      case DiceInputNameScheme.Difficulty:
        return DiceType.NdsDifficulty;
      case DiceInputNameScheme.Setback:
        return DiceType.NdsSetback;
      case DiceInputNameScheme.RemoveSetback:
        return DiceType.NdsRemoveSetback;
      case DiceInputNameScheme.Force:
        return DiceType.NdsForce;
      case DiceInputNameScheme.Triumph:
        return DiceType.NdsTriumph;
      case DiceInputNameScheme.Success:
        return DiceType.NdsSuccess;
      case DiceInputNameScheme.Advantage:
        return DiceType.NdsAdvantage;
      case DiceInputNameScheme.Despair:
        return DiceType.NdsDespair;
      case DiceInputNameScheme.Failure:
        return DiceType.NdsFailure;
      case DiceInputNameScheme.Threat:
        return DiceType.NdsThreat;
      case DiceInputNameScheme.PositiveUpgrade:
        return DiceType.NdsPositiveUpgrade;
      case DiceInputNameScheme.PositiveIncrease:
        return DiceType.NdsPositiveIncrease;
      case DiceInputNameScheme.NegativeUpgrade:
        return DiceType.NdsNegativeUpgrade;
      case DiceInputNameScheme.NegativeIncrease:
        return DiceType.NdsNegativeIncrease;
      case DiceInputNameScheme.LightSide:
        return DiceType.NdsLightSide;
      case DiceInputNameScheme.DarkSide:
        return DiceType.NdsDarkSide;
      default:
        return null;
    }
  }

  export function fromDiceType(type: DiceType): DiceInputNameScheme | null {
    switch (type) {
      case DiceType.NdsProficiency:
        return DiceInputNameScheme.Proficiency;
      case DiceType.NdsAbility:
        return DiceInputNameScheme.Ability;
      case DiceType.NdsBoost:
        return DiceInputNameScheme.Boost;
      case DiceType.NdsChallenge:
        return DiceInputNameScheme.Challenge;
      case DiceType.NdsDifficulty:
        return DiceInputNameScheme.Difficulty;
      case DiceType.NdsSetback:
        return DiceInputNameScheme.Setback;
      case DiceType.NdsRemoveSetback:
        return DiceInputNameScheme.RemoveSetback;
      case DiceType.NdsForce:
        return DiceInputNameScheme.Force;
      case DiceType.NdsTriumph:
        return DiceInputNameScheme.Triumph;
      case DiceType.NdsSuccess:
        return DiceInputNameScheme.Success;
      case DiceType.NdsAdvantage:
        return DiceInputNameScheme.Advantage;
      case DiceType.NdsDespair:
        return DiceInputNameScheme.Despair;
      case DiceType.NdsFailure:
        return DiceInputNameScheme.Failure;
      case DiceType.NdsThreat:
        return DiceInputNameScheme.Threat;
      case DiceType.NdsPositiveUpgrade:
        return DiceInputNameScheme.PositiveUpgrade;
      case DiceType.NdsPositiveIncrease:
        return DiceInputNameScheme.PositiveIncrease;
      case DiceType.NdsNegativeUpgrade:
        return DiceInputNameScheme.NegativeUpgrade;
      case DiceType.NdsNegativeIncrease:
        return DiceInputNameScheme.NegativeIncrease;
      case DiceType.NdsLightSide:
        return DiceInputNameScheme.LightSide;
      case DiceType.NdsDarkSide:
        return DiceInputNameScheme.DarkSide;
      default:
        return null;
    }
  }
}

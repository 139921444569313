import { RulesSystem } from './rules-system';

export enum GameTheme {
  Generic = '[game theme] generic',
  StarWars_EotE = '[game theme] star wars eote',
  StarWars_AoR = '[game theme] star wars aor',
  StarWars_FaD = '[game theme] star wars fad',
  Genesys_Core = '[game theme] genesys core',
  Genesys_Terrinoth = '[game theme] genesys terrinoth',
  Genesys_Android = '[game theme] genesys android',
  Genesys_Keyforge = '[game theme] genesys keyforge',
  Genesys_TwilightImperium = '[game theme] genesys twilight imperium',
  Community_AwakenedAge = '[game theme] community awakened age',
  Community_Inquisition = '[game theme] community inquisition',
  Community_SomethingStrange = '[game theme] community something strange',
  Community_Tron = '[game theme] community tron',
}

export namespace GameTheme {
  export function toString(type: GameTheme): string {
    switch (type) {
      case GameTheme.Generic:
        return 'Generic';
      case GameTheme.StarWars_EotE:
        return 'Edge of the Empire';
      case GameTheme.StarWars_AoR:
        return 'Age of Rebellion';
      case GameTheme.StarWars_FaD:
        return 'Force and Destiny';
      case GameTheme.Genesys_Core:
        return 'Genesys Core';
      case GameTheme.Genesys_Terrinoth:
        return 'Genesys Terrinoth';
      case GameTheme.Genesys_Android:
        return 'Genesys Android';
      case GameTheme.Genesys_Keyforge:
        return 'Genesys Keyforge';
      case GameTheme.Genesys_TwilightImperium:
        return 'Genesys Twilight Imperium';
      case GameTheme.Community_AwakenedAge:
        return 'Community - Awakened Age';
      case GameTheme.Community_Inquisition:
        return 'Community - Inquisition';
      case GameTheme.Community_SomethingStrange:
        return 'Community - Something Strange';
      case GameTheme.Community_Tron:
        return 'Community - Tron';
      default:
        return '';
    }
  }

  export function translationKey(theme: GameTheme): string {
    return `enums.core.game-theme.${theme}`;
  }

  export const members: GameTheme[] = [
    GameTheme.Generic,
    GameTheme.StarWars_EotE,
    GameTheme.StarWars_AoR,
    GameTheme.StarWars_FaD,
    GameTheme.Genesys_Core,
    GameTheme.Genesys_Terrinoth,
    GameTheme.Genesys_Android,
    GameTheme.Genesys_Keyforge,
    GameTheme.Genesys_TwilightImperium,
    GameTheme.Community_AwakenedAge,
    GameTheme.Community_Inquisition,
    GameTheme.Community_SomethingStrange,
    GameTheme.Community_Tron,
  ];

  export const starWars: GameTheme[] = [
    GameTheme.StarWars_EotE,
    GameTheme.StarWars_AoR,
    GameTheme.StarWars_FaD,
  ];

  export const genesys: GameTheme[] = [
    GameTheme.Genesys_Core,
    GameTheme.Genesys_Terrinoth,
    GameTheme.Genesys_Android,
    GameTheme.Genesys_Keyforge,
    GameTheme.Genesys_TwilightImperium,
  ];

  export function membersByRulesSystem(type: RulesSystem): GameTheme[] {
    switch (type) {
      case RulesSystem.NarrativeDiceSystem:
        return [
          GameTheme.StarWars_EotE,
          GameTheme.StarWars_AoR,
          GameTheme.StarWars_FaD,
          GameTheme.Genesys_Core,
          GameTheme.Genesys_Terrinoth,
          GameTheme.Genesys_Android,
          GameTheme.Genesys_Keyforge,
          GameTheme.Genesys_TwilightImperium,
          GameTheme.Community_AwakenedAge,
          GameTheme.Community_Inquisition,
          GameTheme.Community_SomethingStrange,
          GameTheme.Community_Tron,
        ];
      default:
        return [];
    }
  }

  export function getLogoUrl(type: GameTheme): string {
    switch (type) {
      case GameTheme.StarWars_EotE:
        return 'https://unbound-legends.imgix.net/game/sw-eote-logo.jpg?auto=format';
      case GameTheme.StarWars_AoR:
        return 'https://unbound-legends.imgix.net/game/sw-aor-logo.jpg?auto=format';
      case GameTheme.StarWars_FaD:
        return 'https://unbound-legends.imgix.net/game/sw-fad-logo.jpg?auto=format';
      case GameTheme.Genesys_Android:
        return 'https://unbound-legends.imgix.net/character2/logo-android.png?auto=format';
      case GameTheme.Genesys_Core:
        return 'https://unbound-legends.imgix.net/character2/logo-genesys.png?auto=format';
      case GameTheme.Genesys_Terrinoth:
        return 'https://unbound-legends.imgix.net/character2/logo-terrinoth2.png?auto=format';
      case GameTheme.Genesys_Keyforge:
        return 'https://unbound-legends.imgix.net/character2/keyforge-logo.png?auto=format';
      case GameTheme.Genesys_TwilightImperium:
        return 'https://unbound-legends.imgix.net/character2/twilight-imperium-logo.png?auto=format';
      case GameTheme.Community_AwakenedAge:
        return 'https://unbound-legends.imgix.net/game/AwakenedAgeLogo2.png?auto=format';
      case GameTheme.Community_Inquisition:
        return 'https://unbound-legends.imgix.net/game/Inquisition_Logo-small@2x.png?auto=format';
      case GameTheme.Community_SomethingStrange:
        return 'https://unbound-legends.imgix.net/game/ss-Logo-small@2x.png?auto=format';
      case GameTheme.Community_Tron:
        return 'https://unbound-legends.imgix.net/game/200px-TRON@2x.png?auto=format';
      default:
        return '';
    }
  }

  export function getDefaultCharacterImage(type: GameTheme): string {
    switch (type) {
      case GameTheme.StarWars_AoR:
        return 'https://unbound-legends.imgix.net/character2/star-AoR-jjr.jpg?auto=format';
      case GameTheme.StarWars_FaD:
        return 'https://unbound-legends.imgix.net/character2/star-FaD-jjr.jpg?auto=format';
      case GameTheme.StarWars_EotE:
        return 'https://unbound-legends.imgix.net/character2/star-EoE-jjr.jpg?auto=format';
      case GameTheme.Genesys_Android:
        return 'https://unbound-legends.imgix.net/character2/genesys-A-jjr.jpg?auto=format';
      case GameTheme.Genesys_Keyforge:
        return 'https://unbound-legends.imgix.net/character2/genesys-K-jjr.jpg?auto=format';
      case GameTheme.Genesys_Terrinoth:
        return 'https://unbound-legends.imgix.net/character2/genesys-T-jjr.jpg?auto=format';
      case GameTheme.Community_AwakenedAge:
      case GameTheme.Community_Inquisition:
      case GameTheme.Community_SomethingStrange:
      case GameTheme.Community_Tron:
      case GameTheme.Genesys_TwilightImperium:
      case GameTheme.Genesys_Core:
      default:
        return 'https://unbound-legends.imgix.net/character2/genesys-base-jjr.jpg?auto=format';
    }
  }
}

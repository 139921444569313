import { Edge } from '../enums';

export class AdjacentTracker {
  constructor(public value: number = 0) {}

  public get Up(): boolean {
    return Edge.hasEdge(Edge.Up, this.value);
  }

  public set Up(shouldEnable: boolean) {
    this._toggle(shouldEnable, Edge.Up);
  }

  public get Right(): boolean {
    return Edge.hasEdge(Edge.Right, this.value);
  }

  public set Right(shouldEnable: boolean) {
    this._toggle(shouldEnable, Edge.Right);
  }

  public get Down(): boolean {
    return Edge.hasEdge(Edge.Down, this.value);
  }

  public set Down(shouldEnable: boolean) {
    this._toggle(shouldEnable, Edge.Down);
  }

  public get Left(): boolean {
    return Edge.hasEdge(Edge.Left, this.value);
  }

  public set Left(shouldEnable: boolean) {
    this._toggle(shouldEnable, Edge.Left);
  }

  public _toggle(shouldEnable: boolean, edge: Edge): void {
    if (shouldEnable) {
      this.value = Edge.addEdge(edge, this.value);
    } else {
      this.value = Edge.removeEdge(edge, this.value);
    }
  }
}

import { Component, ChangeDetectionStrategy, ViewChild, Inject, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { faRocket, faUser, faFolder } from '@fortawesome/pro-solid-svg-icons';
import { FormControl } from '@ngneat/reactive-forms';
import { DataType } from '@rpg/core/base';
import { CreateContentResponse } from '@rpg/core/content-library';
import { focusWhenRendered, OverlayResponse, OverlayResponseType } from '@rpg/ngx/core';
import { RxState } from '@rx-angular/state';
import {
  NdsCharacterConfigurationComponent,
  NdsVehicleConfigurationComponent,
} from '../../components';

interface ComponentState {
  step: number;
  type: DataType;
  disabledReason: Partial<Record<DataType, string>>;
}

export interface CreateContentOptions {
  preselectType?: DataType;
  disabledDataTypeReason: Partial<Record<DataType, string>>;
}

const PAGE_BY_TYPE: Record<DataType, number> = {
  [DataType.Character]: 1,
  [DataType.Folder]: 2,
  [DataType.Game]: 3,
  [DataType.Vehicle]: 4,
};

@Component({
  selector: 'rpg-create-content',
  templateUrl: './create-content.component.html',
  styleUrls: ['./create-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class CreateContentComponent {
  @ViewChild(MatStepper, { read: MatStepper, static: false })
  public stepper!: MatStepper;
  @ViewChild('stepper', { read: ElementRef, static: false })
  public stepperElementRef!: ElementRef;
  @ViewChild(NdsCharacterConfigurationComponent, {
    read: NdsCharacterConfigurationComponent,
    static: false,
  })
  public characterConfig!: NdsCharacterConfigurationComponent;
  @ViewChild(NdsVehicleConfigurationComponent, {
    read: NdsVehicleConfigurationComponent,
    static: false,
  })
  public vehicleConfig!: NdsVehicleConfigurationComponent;

  public DataType: typeof DataType = DataType;

  public dataTypes: { type: DataType; icon: any }[] = [
    { type: DataType.Character, icon: faUser },
    { type: DataType.Vehicle, icon: faRocket },
    // { type: DataType.Game, icon: faBookReader },
    { type: DataType.Folder, icon: faFolder },
  ];
  public state$ = this._state.select();
  public folderNameControl = new FormControl<string>('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<CreateContentComponent, OverlayResponse<CreateContentResponse>>,
    private _state: RxState<ComponentState>,
    @Inject(MAT_DIALOG_DATA)
    private _data?: Partial<CreateContentOptions>
  ) {
    this._state.set({
      step: 1,
      disabledReason: this._data?.disabledDataTypeReason ?? {},
    });
    if (this._data?.preselectType) {
      this.selectType(this._data.preselectType);
    }
  }

  public selectType(type: DataType): void {
    this._state.set({
      step: PAGE_BY_TYPE[type],
      type,
    });
    this.stepper.selectedIndex = PAGE_BY_TYPE[type];
    if (type === DataType.Folder) {
      focusWhenRendered(this.stepperElementRef.nativeElement, '#folderNameInput');
    }
  }

  public backStep(): void {
    this._state.set({
      step: 0,
    });
    this.stepper.selectedIndex = 0;
  }

  public createContent(): void {
    const { type } = this._state.get();
    const value = this._getValue(type);
    if (!value) return;
    this.dialogRef.close(
      new OverlayResponse(
        OverlayResponseType.Confirm,
        new CreateContentResponse({ type, params: value })
      )
    );
  }

  private _getValue(type: DataType): any {
    switch (type) {
      case DataType.Character:
        return this.characterConfig.configurationForm.valid
          ? this.characterConfig.getValue()
          : undefined;
      case DataType.Folder:
        return this.folderNameControl.valid ? this.folderNameControl.value : undefined;
      case DataType.Vehicle:
        return this.vehicleConfig.configurationForm.valid
          ? this.vehicleConfig.getValue()
          : undefined;
      default:
        return undefined;
    }
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { GameTheme } from '@rpg/core/base';
import {
  NDS_VEHICLE_CHARACTERISTIC_DEFAULT_VALUE,
  NDS_VEHICLE_CHARACTERISTIC_MAX_VALUE,
  NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE,
} from '@rpg/core/vehicle';
import { RxState } from '@rx-angular/state';

interface ComponentState {
  attrImage: string;
  label: string;
  editMode: boolean;
  resolvedValue: number;
}

@Component({
  selector: 'rpg-nds-vehicle-characteristic-single',
  templateUrl: './characteristic-single.component.html',
  styleUrls: ['./characteristic-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class NdsVehicleCharacteristicSingleComponent {
  @Input()
  public set gameTheme(theme: GameTheme) {
    this._updateAttrImage(theme);
  }

  @Input()
  public set label(label: string) {
    this._state.set({
      label,
    });
  }

  @Input()
  public set editMode(editMode: boolean) {
    this._state.set({
      editMode,
    });
  }

  @Input()
  public set resolvedValue(resolvedValue: number) {
    this._state.set({
      resolvedValue,
    });
  }

  @Input()
  public control!: FormControl<number>;

  public state$ = this._state.select();

  constructor(private _state: RxState<ComponentState>) {}

  public checkValue(): void {
    const val = +this.control.value;
    if (!val) {
      this.control.setValue(NDS_VEHICLE_CHARACTERISTIC_DEFAULT_VALUE);
    } else if (isNaN(val)) {
      this.control.setValue(NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE);
    } else if (val < NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE) {
      this.control.setValue(NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE);
    } else if (val > NDS_VEHICLE_CHARACTERISTIC_MAX_VALUE) {
      this.control.setValue(NDS_VEHICLE_CHARACTERISTIC_MAX_VALUE);
    }
  }

  private _updateAttrImage(theme: GameTheme): void {
    let imgSrc: string = '';
    switch (theme) {
      case GameTheme.StarWars_AoR:
      case GameTheme.StarWars_EotE:
      case GameTheme.StarWars_FaD:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/starwars/vehstat01-sw.svg?auto=format';
        break;
      case GameTheme.Genesys_Android:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-android/vehstat01-and.svg?auto=format';
        break;
      case GameTheme.Genesys_TwilightImperium:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-imperium/vehstat01-ti.svg?auto=format';
        break;
      case GameTheme.Genesys_Core:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-core/vehstat01-gen.svg?auto=format';
        break;
      case GameTheme.Genesys_Keyforge:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-keyforge/vehstat01-kf.svg?auto=format';
        break;
      case GameTheme.Genesys_Terrinoth:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-terrinoth/vehstat01-ter.svg?auto=format';
        break;
      case GameTheme.Community_AwakenedAge:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-awakenedage/vehstat01-aa.svg?auto=format';
        break;
      case GameTheme.Community_Inquisition:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-inquisition/vehstat01-in.svg?auto=format';
        break;
      case GameTheme.Community_SomethingStrange:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-somethingstrange/vehstat01-ss.svg?auto=format';
        break;
      case GameTheme.Community_Tron:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-tron/vehstat01-tron.svg?auto=format';
        break;
      default:
        throw new Error('Invalid Game Theme supplied to NdsVehicleAttributeSingle');
    }
    this._state.set({
      attrImage: imgSrc,
    });
  }
}

import { unreachable } from '../functions/utilities';
import { RulesSystem } from './rules-system';

export enum DiceTheme {
  Generic = '[dice theme] generic',
  StarWars = '[dice theme] star wars',
  Genesys = '[dice theme] genesys',
}

export namespace DiceTheme {
  export function translationKey(type: DiceTheme): string {
    return `enums.dice.dice-theme.${type}`;
  }

  export function toString(type: DiceTheme): string {
    switch (type) {
      case DiceTheme.Generic:
        return 'Generic';
      case DiceTheme.StarWars:
        return 'Star Wars';
      case DiceTheme.Genesys:
        return 'Genesys';
      default:
        return '';
    }
  }

  export function toClass(type: DiceTheme): string {
    switch (type) {
      case DiceTheme.Generic:
        return 'dice-theme-generic';
      case DiceTheme.StarWars:
        return 'dice-theme-star-wars';
      case DiceTheme.Genesys:
        return 'dice-theme-genesys';
      default:
        return '';
    }
  }

  export function fromClass(type: string): DiceTheme {
    switch (type) {
      case 'dice-theme-star-wars':
        return DiceTheme.StarWars;
      case 'dice-theme-genesys':
        return DiceTheme.Genesys;
      case 'dice-theme-generic':
      default:
        return DiceTheme.Generic;
    }
  }

  export function toRulesSystem(theme: DiceTheme): RulesSystem {
    switch (theme) {
      case DiceTheme.Generic:
        return RulesSystem.Generic;
      case DiceTheme.Genesys:
      case DiceTheme.StarWars:
        return RulesSystem.NarrativeDiceSystem;
      default:
        unreachable(theme, 'DiceTheme.toRulesSystem');
    }
  }

  export function membersByRulesSystem(system: RulesSystem): DiceTheme[] {
    switch (system) {
      case RulesSystem.NarrativeDiceSystem:
        return [DiceTheme.Genesys, DiceTheme.StarWars];
      case RulesSystem.AGE:
      case RulesSystem.Generic:
        return [DiceTheme.Generic];
      default:
        unreachable(system, 'DiceTheme.membersByRulesSystem');
    }
  }

  export const members: DiceTheme[] = [DiceTheme.Generic, DiceTheme.StarWars, DiceTheme.Genesys];
  export const staticDiceRollerOptions: DiceTheme[] = [
    DiceTheme.Generic,
    DiceTheme.StarWars,
    DiceTheme.Genesys,
  ];
}

export enum DiscordChannelType {
  GuildText,
  DM,
  GuildVoice,
  GroupDM,
  GuildCategory,
  GuildNews,
  GuildStore,
}

export namespace DiscordChannelType {
  export const members: DiscordChannelType[] = [
    DiscordChannelType.GuildText,
    DiscordChannelType.DM,
    DiscordChannelType.GuildVoice,
    DiscordChannelType.GroupDM,
    DiscordChannelType.GuildCategory,
    DiscordChannelType.GuildNews,
    DiscordChannelType.GuildStore,
  ];
}

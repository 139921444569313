import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsVehicleCritData } from '@rpg/core/vehicle';

export function ndsVehicleCritToFormGroup(crit: NdsVehicleCritData): FormGroup<NdsVehicleCritData> {
  return new FormGroup<NdsVehicleCritData & Expandable>({
    expanded: new FormControl((crit as any)['expanded'] ?? false),
    id: new FormControl(crit.id),
    name: new FormControl(crit.name, [Validators.required]),
    description: new FormControl(crit.description),
    severity: new FormControl(crit.severity),
    difficulty: new FormControl(crit.difficulty),
    healed: new FormControl(crit.healed),
  });
}

export enum NdsVehicleRangeBand {
  Close = '[nds vehicle range band] close',
  Short = '[nds vehicle range band] short',
  Medium = '[nds vehicle range band] medium',
  Long = '[nds vehicle range band] long',
  Extreme = '[nds vehicle range band] extreme',
  Strategic = '[nds vehicle range band] strategic',
}

export namespace NdsVehicleRangeBand {
  export function translationKey(type: NdsVehicleRangeBand): string {
    return `enums.vehicle.nds.range-band.${type}`;
  }

  export const members: NdsVehicleRangeBand[] = [
    NdsVehicleRangeBand.Close,
    NdsVehicleRangeBand.Short,
    NdsVehicleRangeBand.Medium,
    NdsVehicleRangeBand.Long,
    NdsVehicleRangeBand.Extreme,
    NdsVehicleRangeBand.Strategic,
  ];
}

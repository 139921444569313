import { parseEnum, DiceTheme } from '@rpg/core/base';
import { DiceShortHandStyle } from '@rpg/core/dice';
import { DiscordLink } from '@rpg/core/discord';
import { PatreonLink } from '@rpg/core/patreon';
import { LanguageOption, ThemeOption } from '../enums';

export class UserSettings {
  public diceTheme: DiceTheme = DiceTheme.Genesys;
  public discordLink: DiscordLink = new DiscordLink();
  public patreonLink: PatreonLink = new PatreonLink();
  public diceShortHandStyle: DiceShortHandStyle = DiceShortHandStyle.defaultValue;
  public siteTheme: ThemeOption = ThemeOption.defaultValue;
  public language: LanguageOption = LanguageOption.English;
  public honoraryTitles: boolean = true;

  constructor(params?: Partial<UserSettings>) {
    if (!!params) {
      this.diceTheme = parseEnum(DiceTheme, params.diceTheme, this.diceTheme);
      this.diceShortHandStyle = parseEnum(
        DiceShortHandStyle,
        params.diceShortHandStyle,
        this.diceShortHandStyle
      );
      this.siteTheme = parseEnum(ThemeOption, params.siteTheme, this.siteTheme);
      this.language = parseEnum(LanguageOption, params.language, this.language);
      this.honoraryTitles = params.honoraryTitles ?? this.honoraryTitles;

      if (!!params.discordLink) {
        this.discordLink = new DiscordLink(params.discordLink);
      }
      if (!!params.patreonLink) {
        this.patreonLink = new PatreonLink(params.patreonLink);
      }
    }
  }
}

<ng-container *rxLet="state$; let state">
  <img class="characteristic-double__image" [src]="state.attrImage" />
  <ng-container *ngIf="state.editMode; else displayMode">
    <span
      class="characteristic-double__input"
      rpgContentEditableNumber
      contentEditable
      [formControl]="controls[0]"
      (blur)="checkValue(controls[0])"
      >{{ controls[0].value }}</span
    >
    <span
      class="characteristic-double__input"
      rpgContentEditableNumber
      contentEditable
      [formControl]="controls[1]"
      (blur)="checkValue(controls[1])"
      >{{ controls[1].value }}</span
    >
  </ng-container>
  <ng-template #displayMode>
    <div class="characteristic-double__input-container">
      <span class="characteristic-double__input">
        {{ state.resolvedValues[0] }} <ng-content select=".slot-1"></ng-content>
      </span>
      <span class="characteristic-double__input">
        {{ state.resolvedValues[1] }} <ng-content select=".slot-2"></ng-content>
      </span>
    </div>
  </ng-template>
  <div class="characteristic-double__label-container">
    <label class="characteristic-double__label">
      {{ state.labels[0] }}
    </label>
    <label class="characteristic-double__label">
      {{ state.labels[1] }}
    </label>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomPreloadingStrategy } from './preload-strategy';
import { SecuredContainerComponent } from './shared/containers';

const routes: Routes = [
  {
    path: 'auth',
    redirectTo: '/auth2',
  },
  {
    path: 'auth2',
    loadChildren: () => import('./auth2/auth2.module').then(am => am.Auth2Module),
  },
  {
    path: 'table',
    loadChildren: () => import('./game-table/game-table.module').then(gtm => gtm.GameTableModule),
  },
  {
    path: '',
    component: SecuredContainerComponent,
    children: [
      {
        path: 'admin',
        loadChildren: (): any => import('./admin/admin.module').then(am => am.AdminModule),
      },
      {
        path: 'char',
        loadChildren: (): any =>
          import('./character/character.module').then(cm => cm.CharacterModule),
      },
      {
        path: 'game',
        loadChildren: (): any => import('./game/game.module').then(gm => gm.GameModule),
      },
      {
        path: 'library',
        loadChildren: (): any =>
          import('./content-library/content-library.module').then(cm => cm.ContentLibraryModule),
        data: {
          preload: true,
        },
      },
      {
        path: 'dice',
        loadChildren: (): any => import('./dice/dice.module').then(dm => dm.DiceModule),
        data: {
          preload: true,
        },
      },
      // {
      //   path: 'help',
      //   loadChildren: (): any => import('./help/help.module').then(hm => hm.HelpModule),
      // },
      {
        path: 'user',
        loadChildren: (): any => import('./user/user.module').then(um => um.UserModule),
      },
      {
        path: 'vehicle',
        loadChildren: (): any => import('./vehicle/vehicle.module').then(vm => vm.VehicleModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dice',
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dice',
  },
  {
    path: '**',
    redirectTo: '/dice',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: CustomPreloadingStrategy,
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MarkdownModule } from 'ngx-markdown';
import { CloneOverlayComponent } from './clone-overlay/clone-overlay.component';
import { ConfirmationOverlayComponent } from './confirmation-overlay/confirmation-overlay.component';
import { FormattingHelpComponent } from './formatting-help/formatting-help.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerOverlayComponent } from './spinner/spinner.component';
import { BaseOverlayService } from './base-overlay.service';
import { SingleInputOverlayComponent } from './single-input/single-input.component';
import { FormsComponentsModule } from '@rpg/ngx/components/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RpgConfirmActionDialogDirective } from './confirm-action-dialog.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedComponentsModule } from '../shared';
import { LetModule } from '@rx-angular/template';

@NgModule({
  declarations: [
    RpgConfirmActionDialogDirective,
    CloneOverlayComponent,
    ConfirmationOverlayComponent,
    FormattingHelpComponent,
    SpinnerOverlayComponent,
    SingleInputOverlayComponent,
  ],
  exports: [
    RpgConfirmActionDialogDirective,
    CloneOverlayComponent,
    ConfirmationOverlayComponent,
    FormattingHelpComponent,
    SpinnerOverlayComponent,
    SingleInputOverlayComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FormsComponentsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslocoModule,
    LetModule,
    SharedComponentsModule,
    MarkdownModule.forChild(),
  ],
  providers: [BaseOverlayService],
})
export class OverlaysComponentsModule {}

import { createId, createSlug, parseEnumArray, RpgClassValidators } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';

export class NdsVehicleActionData {
  public id: string = '';
  public name: string = '';
  public lookupName: string = '';
  public description: string = '';
  public dice: DiceType[] = [];

  constructor(params?: Partial<NdsVehicleActionData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.lookupName = params.lookupName ?? this.lookupName;
      this.description = params.description ?? this.description;
      this.dice = parseEnumArray(DiceType, params.dice);
    }

    if (!this.id) {
      this.id = createId();
    }
    if (!this.lookupName) {
      this.lookupName = createSlug(this.name ?? '');
    }
  }

  public static validate(input: NdsVehicleActionData): string[] {
    const errors = [];
    errors.push(...RpgClassValidators.string(input.id));
    errors.push(...RpgClassValidators.string(input.name));
    errors.push(...RpgClassValidators.string(input.lookupName));
    errors.push(...RpgClassValidators.string(input.description, { allowEmpty: true }));
    errors.push(
      ...RpgClassValidators.enum(input.dice, DiceType, {
        enumName: 'Dice',
        isArray: true,
      })
    );
    return errors;
  }
}

import { NdsMatchedCharacterModifier } from './nds-matched-character-modifier';

export class NdsCalculatedCharacterModifier<Type = any> {
  public base!: Type;
  public result!: Type;
  public appliedModifiers: NdsMatchedCharacterModifier[] = [];
  public ignoredModifiers: NdsMatchedCharacterModifier[] = [];

  constructor(params: NdsCalculatedCharacterModifier<Type>) {
    this.base = params.base;
    this.result = params.result;
    this.appliedModifiers = params.appliedModifiers.map(m => new NdsMatchedCharacterModifier(m));
    this.ignoredModifiers = params.ignoredModifiers.map(m => new NdsMatchedCharacterModifier(m));
  }
}

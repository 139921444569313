import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { CharacterOptions } from '@rpg/core/character';

export function characterOptionsToFormGroup(
  options: CharacterOptions
): FormGroup<CharacterOptions> {
  return new FormGroup<CharacterOptions>({
    allowGMControl: new FormControl(options.allowGMControl),
  });
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DiceType } from '@rpg/core/dice';
import { forEach, map } from 'lodash';
import { NdsCharacterAttribute, NdsCharacterModifierType, NdsCharacterType } from '../enums';
import {
  NdsCalculatedCharacterModifier,
  NdsCharacter,
  NdsCharacterArmorData,
  NdsCharacterAttributeData,
  NdsCharacterCharacteristicData,
  NdsCharacterDefense,
  NdsCharacterEncumbrance,
  NdsCharacterModifierArmorData,
  NdsCharacterModifierAttributeData,
  NdsCharacterModifierCharacteristicData,
  NdsCharacterModifierData,
  NdsCharacterModifierSkillData,
  NdsCharacterModifierWeaponData,
  NdsCharacterSkillData,
  NdsCharacterSoak,
  NdsCharacterWeaponData,
  NdsMatchedCharacterModifier,
  NDS_CHARACTER_SKILL_MAX_RANKS,
} from '../models';

export interface NdsCalculatedCharacter {
  resultCharacter: NdsCharacter;
  modifiers: Map<string, NdsCalculatedCharacterModifier>;
}

function ndsGatherModifiers(char: NdsCharacter): NdsMatchedCharacterModifier[] {
  const modifiers: NdsMatchedCharacterModifier[] = [];

  modifiers.push(
    ...map(
      char.modifiers ?? [],
      mod =>
        new NdsMatchedCharacterModifier({
          enabled: true,
          fromId: '',
          fromName: char['name'],
          parentIds: [],
          pathRefs: [],
          modifier: mod,
        })
    )
  );

  forEach(char.armor ?? [], armor => {
    const isBaseArmorEnabled = (mod: NdsCharacterModifierData) =>
      (mod.type === NdsCharacterModifierType.Armor &&
        armor.id === (mod as NdsCharacterModifierArmorData).linkedArmorId) ||
      armor.equipped;
    modifiers.push(
      ...map(
        armor.modifiers ?? [],
        mod =>
          new NdsMatchedCharacterModifier({
            enabled: isBaseArmorEnabled(mod),
            fromId: armor.id,
            fromName: armor.name,
            parentIds: [armor.id],
            pathRefs: [
              {
                id: armor.id,
                path: 'armor',
                name: armor.name,
              },
            ],
            modifier: mod,
          })
      )
    );
    forEach(armor.attachments ?? [], attachment => {
      modifiers.push(
        ...map(
          attachment.modifiers ?? [],
          mod =>
            new NdsMatchedCharacterModifier({
              enabled: isBaseArmorEnabled(mod) && attachment.equipped,
              fromId: attachment.id,
              fromName: attachment.name,
              parentIds: [armor.id, attachment.id],
              pathRefs: [
                {
                  id: armor.id,
                  path: 'armor',
                  name: armor.name,
                },
                {
                  id: attachment.id,
                  path: 'attachments',
                  name: attachment.name,
                },
              ],
              modifier: mod,
            })
        )
      );
      forEach(attachment.modifications, mod => {
        modifiers.push(
          ...map(
            mod.modifiers ?? [],
            m =>
              new NdsMatchedCharacterModifier({
                enabled: isBaseArmorEnabled(m) && attachment.equipped && mod.enabled,
                fromId: mod.id,
                fromName: mod.name,
                parentIds: [armor.id, attachment.id, mod.id],
                pathRefs: [
                  {
                    id: armor.id,
                    path: 'armor',
                    name: armor.name,
                  },
                  {
                    id: attachment.id,

                    path: 'attachments',
                    name: attachment.name,
                  },
                  {
                    id: mod.id,
                    path: 'modifications',
                    name: mod.name,
                  },
                ],
                modifier: m,
              })
          )
        );
      });
    });
  });

  forEach(char.equipment ?? [], equipment => {
    modifiers.push(
      ...map(
        equipment.modifiers ?? [],
        mod =>
          new NdsMatchedCharacterModifier({
            enabled: equipment.carrying,
            fromId: equipment.id,
            fromName: equipment.name,
            parentIds: [equipment.id],
            pathRefs: [
              {
                id: equipment.id,
                path: 'equipment',
                name: equipment.name,
              },
            ],
            modifier: mod,
          })
      )
    );
  });

  forEach(char.forcePowers.talents ?? [], forcePower => {
    modifiers.push(
      ...map(
        forcePower.modifiers ?? [],
        mod =>
          new NdsMatchedCharacterModifier({
            enabled: forcePower.purchased,
            fromId: forcePower.id,
            fromName: forcePower.name,
            parentIds: [forcePower.id],
            pathRefs: [
              {
                id: forcePower.id,
                path: 'forcePowers.talents',
                name: forcePower.name,
              },
            ],
            modifier: mod,
          })
      )
    );
  });

  forEach(char.talents.talents ?? [], talent => {
    modifiers.push(
      ...map(
        talent.modifiers ?? [],
        mod =>
          new NdsMatchedCharacterModifier({
            enabled: talent.purchased,
            fromId: talent.id,
            fromName: talent.name,
            parentIds: [talent.id],
            pathRefs: [
              {
                id: talent.id,
                path: 'talents.talents',
                name: talent.name,
              },
            ],
            modifier: mod,
          })
      )
    );
  });

  forEach(char.weapons ?? [], weapon => {
    const isBaseWeaponEnabled = (mod: NdsCharacterModifierData) =>
      (mod.type === NdsCharacterModifierType.Weapon &&
        weapon.id === (mod as NdsCharacterModifierWeaponData).linkedWeaponId) ||
      weapon.equipped;
    modifiers.push(
      ...map(
        weapon.modifiers ?? [],
        mod =>
          new NdsMatchedCharacterModifier({
            enabled: isBaseWeaponEnabled(mod),
            fromId: weapon.id,
            fromName: weapon.name,
            parentIds: [weapon.id],
            pathRefs: [
              {
                id: weapon.id,
                path: 'weapons',
                name: weapon.name,
              },
            ],
            modifier: mod,
          })
      )
    );
    forEach(weapon.attachments ?? [], attachment => {
      modifiers.push(
        ...map(
          attachment.modifiers ?? [],
          mod =>
            new NdsMatchedCharacterModifier({
              enabled: isBaseWeaponEnabled(mod) && attachment.equipped,
              fromId: attachment.id,
              fromName: attachment.name,
              parentIds: [weapon.id, attachment.id],
              pathRefs: [
                {
                  id: weapon.id,
                  path: 'weapons',
                  name: weapon.name,
                },
                {
                  id: attachment.id,
                  path: 'attachments',
                  name: attachment.name,
                },
              ],
              modifier: mod,
            })
        )
      );
      forEach(attachment.modifications ?? [], mod => {
        modifiers.push(
          ...map(
            mod.modifiers ?? [],
            m =>
              new NdsMatchedCharacterModifier({
                enabled: isBaseWeaponEnabled(m) && attachment.equipped && mod.enabled,
                fromId: mod.id,
                fromName: mod.name,
                parentIds: [weapon.id, attachment.id, mod.id],
                pathRefs: [
                  {
                    id: weapon.id,
                    path: 'weapons',
                    name: weapon.name,
                  },
                  {
                    id: attachment.id,
                    path: 'attachments',
                    name: attachment.name,
                  },
                  {
                    id: mod.id,
                    path: 'modifications',
                    name: mod.name,
                  },
                ],
                modifier: m,
              })
          )
        );
      });
    });
  });

  return modifiers;
}

function reduceArmorModifiers(
  modifiers: NdsMatchedCharacterModifier[],
  baseValue: NdsCharacterArmorData
): NdsCalculatedCharacterModifier<NdsCharacterArmorData> {
  const res = new NdsCalculatedCharacterModifier({
    base: new NdsCharacterArmorData(baseValue),
    result: new NdsCharacterArmorData(baseValue),
    appliedModifiers: [],
    ignoredModifiers: [],
  });

  forEach(modifiers, mod => {
    if (!!baseValue && mod.modifier.type === NdsCharacterModifierType.Armor) {
      const modRef = mod.modifier as NdsCharacterModifierArmorData;
      if (modRef.linkedArmorId === baseValue.id) {
        if (mod.enabled) {
          res.result.defense += modRef.defenseModifier;
          res.result.soak += modRef.soakModifier;
          res.result.encumbrance += modRef.encumbranceModifier;
          res.appliedModifiers.push(mod);
        } else {
          res.ignoredModifiers.push(mod);
        }
      }
    }
  });

  return res;
}

function reduceAttributeModifiers(
  modifiers: NdsMatchedCharacterModifier[],
  baseValue: NdsCharacterAttributeData
): NdsCalculatedCharacterModifier<NdsCharacterAttributeData> {
  const res = new NdsCalculatedCharacterModifier({
    base: new NdsCharacterAttributeData(baseValue),
    result: new NdsCharacterAttributeData(baseValue),
    appliedModifiers: [],
    ignoredModifiers: [],
  });

  forEach(modifiers, mod => {
    if (mod.modifier.type === NdsCharacterModifierType.Attribute) {
      if ((mod.modifier as NdsCharacterModifierAttributeData).attribute === baseValue.type) {
        if (!mod.enabled) {
          res.ignoredModifiers.push(mod);
        } else {
          res.result.value += (mod.modifier as NdsCharacterModifierAttributeData).modifierAmount;
          res.appliedModifiers.push(mod);
        }
      }
    }
  });

  return res;
}

function reduceCharacteristicModifiers(
  modifiers: NdsMatchedCharacterModifier[],
  baseValue: NdsCharacterCharacteristicData
): NdsCalculatedCharacterModifier<NdsCharacterCharacteristicData> {
  const res = new NdsCalculatedCharacterModifier({
    base: new NdsCharacterCharacteristicData(baseValue),
    result: new NdsCharacterCharacteristicData(baseValue),
    appliedModifiers: [],
    ignoredModifiers: [],
  });

  forEach(modifiers, mod => {
    if (!!baseValue && mod.modifier.type === NdsCharacterModifierType.Characteristic) {
      if (
        (mod.modifier as NdsCharacterModifierCharacteristicData).characteristic === baseValue.type
      ) {
        if (!mod.enabled) {
          res.ignoredModifiers.push(mod);
        } else {
          res.result.value += (
            mod.modifier as NdsCharacterModifierCharacteristicData
          ).modifierAmount;
          res.appliedModifiers.push(mod);
        }
      }
    }
  });

  return res;
}

function reduceSkillModifiers(
  modifiers: NdsMatchedCharacterModifier[],
  baseValue: NdsCharacterSkillData,
  {
    characterCount,
    characterType,
    uncommittedForceDiceCount,
  }: {
    characterCount: number;
    characterType: NdsCharacterType;
    uncommittedForceDiceCount: number;
  }
): NdsCalculatedCharacterModifier<NdsCharacterSkillData> {
  const res = new NdsCalculatedCharacterModifier({
    base: new NdsCharacterSkillData(baseValue),
    result: new NdsCharacterSkillData(baseValue),
    appliedModifiers: [],
    ignoredModifiers: [],
  });

  let includeUncommittedForceDice = false;

  forEach(modifiers, mod => {
    if (!!baseValue && mod.modifier.type === NdsCharacterModifierType.Skill) {
      const modRef = mod.modifier as NdsCharacterModifierSkillData;
      if (modRef.linkedSkillId === baseValue.id) {
        if (!mod.enabled) {
          res.ignoredModifiers.push(mod);
        } else {
          includeUncommittedForceDice =
            includeUncommittedForceDice || modRef.includeUncommittedForceDice;
          res.result.isCareer = modRef.careerModifier || res.result.isCareer;
          res.result.extraDice = [...res.result.extraDice, ...modRef.extraDice];
          res.result.ranks += modRef.rankModifier;
          res.appliedModifiers.push(mod);
        }
      }
    }
  });

  if (includeUncommittedForceDice && uncommittedForceDiceCount > 0) {
    const forceDice = new Array(uncommittedForceDiceCount).fill(DiceType.NdsForce);
    res.result.extraDice = [...res.result.extraDice, ...forceDice];
  }

  if (characterType === NdsCharacterType.Minion) {
    if (res.result.isCareer) {
      const MAX_MINION_RANKS = NDS_CHARACTER_SKILL_MAX_RANKS;
      res.result.ranks = Math.min(Math.max(characterCount - 1, 0), MAX_MINION_RANKS);
    } else {
      res.result.ranks = 0;
    }
  }

  return res;
}

function reduceWeaponModifiers(
  modifiers: NdsMatchedCharacterModifier[],
  baseValue: NdsCharacterWeaponData,
  {
    uncommittedForceDiceCount,
  }: {
    uncommittedForceDiceCount: number;
  }
): NdsCalculatedCharacterModifier<NdsCharacterWeaponData> {
  const res = new NdsCalculatedCharacterModifier({
    base: new NdsCharacterWeaponData(baseValue),
    result: new NdsCharacterWeaponData(baseValue),
    appliedModifiers: [],
    ignoredModifiers: [],
  });

  let includeUncommittedForceDice = false;

  forEach(modifiers, mod => {
    if (!!baseValue && mod.modifier.type === NdsCharacterModifierType.Weapon) {
      const modRef = mod.modifier as NdsCharacterModifierWeaponData;
      if (modRef.linkedWeaponId === baseValue.id) {
        if (mod.enabled) {
          includeUncommittedForceDice =
            includeUncommittedForceDice || modRef.includeUncommittedForceDice;
          res.result.baseDamage += modRef.damageModifier;
          if (modRef.critModifier !== 0) {
            // Cannot reduce crit rating below 1 via modifiers
            res.result.critRating += Math.max(modRef.critModifier, res.result.critRating * -1 + 1);
          }
          if (modRef.encumbranceModifier !== 0) {
            // Cannot reduce encumbrance below 0 via modifiers
            res.result.encumbrance = Math.max(
              0,
              res.result.encumbrance + modRef.encumbranceModifier
            );
          }
          res.result.extraDice = [...res.result.extraDice, ...modRef.extraDice];
          res.appliedModifiers.push(mod);
        } else {
          res.ignoredModifiers.push(mod);
        }
      }
    }
  });

  if (includeUncommittedForceDice && uncommittedForceDiceCount > 0) {
    const forceDice = new Array(uncommittedForceDiceCount).fill(DiceType.NdsForce);
    res.result.extraDice = [...res.result.extraDice, ...forceDice];
  }

  return res;
}

export function ndsCalculatedCharacter(character: NdsCharacter): NdsCalculatedCharacter {
  const modifiers = ndsGatherModifiers(character);
  const resultCharacter = new NdsCharacter(character);
  const sortedModifiers = new Map<string, NdsCalculatedCharacterModifier>();

  // Resolve Armor
  resultCharacter.armor = map(resultCharacter.armor, armor => {
    const calculatedModifier = reduceArmorModifiers(modifiers, armor);
    sortedModifiers.set(armor.id, calculatedModifier);
    return calculatedModifier.result;
  });

  // Resolve Attributes
  resultCharacter.attributes = map(resultCharacter.attributes, attr => {
    const calculatedModifier = reduceAttributeModifiers(modifiers, attr);
    sortedModifiers.set(attr.type, calculatedModifier);
    return calculatedModifier.result;
  });

  // Now that attributes are resolved, we need to step in
  // and manually resolve the minion count as this depends
  // on various resolved attribute values. This only occurs
  // if the ndsCharacterType === Minion!
  let adjustedCharacterCount = 1; // Default to 1
  if (character.configuration.ndsCharacterType === NdsCharacterType.Minion) {
    const characterCount = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.CharacterCount
    );
    const currentMinionCount = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.CurrentMinionCount
    );
    const currentWounds = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.WoundsCurrent
    );
    const woundsThreshold = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.WoundsThreshold
    );
    if (!!characterCount && currentWounds && woundsThreshold && currentMinionCount) {
      adjustedCharacterCount = characterCount.value;
      currentMinionCount.value = characterCount.value;
      const wt = woundsThreshold.value;
      let cw = currentWounds.value;
      while (cw > wt && adjustedCharacterCount > 0) {
        adjustedCharacterCount = Math.max(adjustedCharacterCount - 1, 0);
        cw = cw - wt;
      }
      woundsThreshold.value = woundsThreshold.value * characterCount.value;
      // Only create the modifier if the value is different
      if (adjustedCharacterCount !== characterCount.value) {
        currentMinionCount.value = adjustedCharacterCount;
        // We use the non-null assertion safely here since we have already proven the attr exists
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const modifier: NdsCalculatedCharacterModifier<NdsCharacterAttributeData> =
          sortedModifiers.get(currentMinionCount.type)!;
        modifier.appliedModifiers.push(
          new NdsMatchedCharacterModifier({
            enabled: true,
            fromId: '',
            fromName: 'Wounds Adjustment',
            parentIds: [],
            pathRefs: [],
            modifier: new NdsCharacterModifierAttributeData({
              attribute: currentMinionCount.type,
              name: 'Wounds Adjustment',
              modifierAmount:
                (characterCount.value - adjustedCharacterCount) * woundsThreshold.value,
            }),
          })
        );
        modifier.result.value = adjustedCharacterCount;
      }
    }
  }

  // We should also get the available force dice if enabled for use in later items
  let uncommittedForceDice = 0;
  if (!!character.configuration.forceEnabled) {
    const forceCurrent = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.ForceRatingCurrent
    );
    const forceCommitted = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.ForceRatingCommitted
    );
    if (!!forceCurrent && forceCommitted) {
      uncommittedForceDice = Math.max(0, forceCurrent.value - forceCommitted.value);
    }
  }

  // Resolve Characteristics
  resultCharacter.characteristics = map(resultCharacter.characteristics, char => {
    const calculatedModifier = reduceCharacteristicModifiers(modifiers, char);
    sortedModifiers.set(char.type, calculatedModifier);
    return calculatedModifier.result;
  });

  // Resolve Skills
  resultCharacter.skills = map(resultCharacter.skills, skill => {
    const calculatedModifier = reduceSkillModifiers(modifiers, skill, {
      characterCount: adjustedCharacterCount,
      characterType: resultCharacter.configuration.ndsCharacterType,
      uncommittedForceDiceCount: uncommittedForceDice,
    });
    sortedModifiers.set(skill.id, calculatedModifier);
    return calculatedModifier.result;
  });

  // Resolve Weapons
  resultCharacter.weapons = map(resultCharacter.weapons, weapon => {
    const calculatedModifier = reduceWeaponModifiers(modifiers, weapon, {
      uncommittedForceDiceCount: uncommittedForceDice,
    });
    sortedModifiers.set(weapon.id, calculatedModifier);
    return calculatedModifier.result;
  });

  // Handle Automations conditionally
  if (resultCharacter.configuration.automations) {
    const soakItems: NdsCharacterSoak[] = [];
    const defenseItems: NdsCharacterDefense[] = [];
    const encumbranceItems: NdsCharacterEncumbrance[] = [];

    // Loop items that can fit into the required categories
    forEach(resultCharacter.armor, armor => {
      soakItems.push(armor);
      defenseItems.push(armor);
      encumbranceItems.push({ ...armor, isArmor: true });
    });
    forEach(resultCharacter.equipment, equipment => {
      encumbranceItems.push(equipment);
    });
    forEach(resultCharacter.weapons, weapon => {
      encumbranceItems.push(weapon);
    });
    // Soak
    const soakAttr = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.Soak
    );
    if (!!soakAttr) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const soakMod: NdsCalculatedCharacterModifier<NdsCharacterAttributeData> =
        sortedModifiers.get(NdsCharacterAttribute.Soak)!;
      forEach(soakItems, item => {
        if (item.equipped && item.soak !== 0) {
          soakAttr.value += item.soak;
          soakMod.appliedModifiers.push(
            new NdsMatchedCharacterModifier({
              enabled: true,
              fromId: item.id,
              fromName: item.name,
              parentIds: [item.id],
              pathRefs: [],
              modifier: new NdsCharacterModifierAttributeData({
                attribute: NdsCharacterAttribute.Soak,
                name: 'Automations',
                modifierAmount: item.soak,
              }),
            })
          );
        }
      });
      soakMod.result.value = soakAttr.value;
    }

    // Defense
    const rDefenseAttr = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.DefenseRanged
    );
    const mDefenseAttr = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.DefenseMelee
    );
    if (!!rDefenseAttr && !!mDefenseAttr) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const rDefenseMod: NdsCalculatedCharacterModifier<NdsCharacterAttributeData> =
        sortedModifiers.get(NdsCharacterAttribute.DefenseRanged)!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const mDefenseMod: NdsCalculatedCharacterModifier<NdsCharacterAttributeData> =
        sortedModifiers.get(NdsCharacterAttribute.DefenseMelee)!;

      forEach(defenseItems, item => {
        if (item.equipped && item.defense !== 0) {
          rDefenseAttr.value += item.defense;
          mDefenseAttr.value += item.defense;
          rDefenseMod.appliedModifiers.push(
            new NdsMatchedCharacterModifier({
              enabled: true,
              fromId: item.id,
              fromName: item.name,
              parentIds: [item.id],
              pathRefs: [],
              modifier: new NdsCharacterModifierAttributeData({
                attribute: NdsCharacterAttribute.DefenseRanged,
                name: 'Automations',
                modifierAmount: item.defense,
              }),
            })
          );
          mDefenseMod.appliedModifiers.push(
            new NdsMatchedCharacterModifier({
              enabled: true,
              fromId: item.id,
              fromName: item.name,
              parentIds: [item.id],
              pathRefs: [],
              modifier: new NdsCharacterModifierAttributeData({
                attribute: NdsCharacterAttribute.DefenseMelee,
                name: 'Automations',
                modifierAmount: item.defense,
              }),
            })
          );
        }
      });
      rDefenseMod.result.value = rDefenseAttr.value;
      mDefenseMod.result.value = mDefenseAttr.value;
    }

    // Encumbrance
    const encumAttr = resultCharacter.attributes.find(
      attr => attr.type === NdsCharacterAttribute.EncumbranceCurrent
    );
    if (!!encumAttr) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const encumMod: NdsCalculatedCharacterModifier<NdsCharacterAttributeData> =
        sortedModifiers.get(NdsCharacterAttribute.EncumbranceCurrent)!;
      // Worn armor reduces it's encumbrance rating by 3, to a minimum of 0. Genesys CRB:92
      const WORN_ARMOR_MODIFIER = 3;
      forEach(encumbranceItems, item => {
        if (item.isArmor && item.equipped && item.encumbrance > 0) {
          item.encumbrance = Math.max(0, item.encumbrance - WORN_ARMOR_MODIFIER);
        }
        const finalModifiedValue = Math.floor(item.encumbrance * (item.quantity ?? 1));
        if (item.carrying && finalModifiedValue !== 0) {
          encumAttr.value += finalModifiedValue;
          encumMod.appliedModifiers.push(
            new NdsMatchedCharacterModifier({
              enabled: true,
              fromId: item.id,
              fromName: item.name,
              parentIds: [item.id],
              pathRefs: [],
              modifier: new NdsCharacterModifierAttributeData({
                attribute: NdsCharacterAttribute.EncumbranceCurrent,
                name: 'Automations',
                modifierAmount: finalModifiedValue,
              }),
            })
          );
        }
      });
      encumMod.result.value = encumAttr.value;
    }
  }

  return {
    resultCharacter,
    modifiers: sortedModifiers,
  };
}

import { createId, createSlug, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsVehicleModifierFactory } from '../functions/nds-vehicle-modifier-factory';
import { NdsVehicleModifierValidatorFactory } from '../functions/nds-vehicle-modifier-validator-factory';
import { NdsVehicleModifierData } from './modifiers';
import { NdsVehicleAttachmentModificationData } from './nds-vehicle-attachment-modification-data';

export class NdsVehicleAttachmentData {
  public id: string = '';
  public name: string = '';
  public lookupName: string = '';
  public hardPoints: number = 0;
  public description: string = '';
  public cost: string = '';
  public rarity: number = 0;
  public restricted: boolean = false;
  public equipped: boolean = true;
  public modifications: NdsVehicleAttachmentModificationData[] = [];
  public modifiers: NdsVehicleModifierData[] = [];

  constructor(params?: Partial<NdsVehicleAttachmentData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.lookupName = params.lookupName ?? this.lookupName;
      this.hardPoints = parseNumber(params.hardPoints, this.hardPoints);
      this.description = params.description ?? this.description;
      this.cost = params.cost ?? this.cost;
      this.rarity = parseNumber(params.rarity, this.rarity);
      this.restricted = params.restricted ?? this.restricted;
      this.equipped = params.equipped ?? this.equipped;
      this.modifications = Array.isArray(params.modifications)
        ? params.modifications.map(m => new NdsVehicleAttachmentModificationData(m))
        : this.modifications;
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(m => NdsVehicleModifierFactory(m))
        : this.modifiers;
    }

    if (!this.id) {
      this.id = createId();
    }
    if (!this.lookupName) {
      this.lookupName = createSlug(this.name);
    }
  }

  public static validate(input: NdsVehicleAttachmentData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id));
    errors.push(...RpgClassValidators.string(input.name));
    errors.push(...RpgClassValidators.number(input.hardPoints, { min: 0 }));
    errors.push(...RpgClassValidators.number(input.rarity, { min: 0 }));
    errors.push(...RpgClassValidators.boolean(input.restricted));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
      })
    );
    errors.push(...RpgClassValidators.string(input.cost, { allowEmpty: true }));
    errors.push(...RpgClassValidators.boolean(input.equipped));
    errors.push(
      ...input.modifications.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleAttachmentModificationData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleModifierValidatorFactory(next)],
        []
      )
    );
    return errors;
  }
}

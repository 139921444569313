<ng-container *rxLet="state$; let state">
  <img class="attribute-single__image" [src]="state.attrImage" />
  <label class="attribute-single__title">
    {{ state.title }}
  </label>
  <ng-container *ngIf="state.editMode; else displayMode">
    <span
      class="attribute-single__input"
      [class.attribute-single__input--smaller]="state.isSmaller"
      rpgContentEditableNumber
      contentEditable
      [formControl]="control"
      (blur)="checkValue()"
      >{{ control.value }}</span
    >
  </ng-container>
  <ng-template #displayMode>
    <span class="attribute-single__input">
      {{ state.resolvedValue }} <ng-content></ng-content>
    </span>
  </ng-template>
  <label class="attribute-single__label">
    {{ state.label }}
  </label>
</ng-container>

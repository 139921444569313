import { FormGroup, FormControl } from '@ngneat/reactive-forms';
import { MongoDoc } from '@rpg/core/base';

export function mongoDocToFormGroup(doc: MongoDoc): FormGroup<MongoDoc> {
  return new FormGroup<MongoDoc>({
    _id: new FormControl(doc._id),
    createdDate: new FormControl(doc.createdDate),
    modifiedDate: new FormControl(doc.modifiedDate),
    status: new FormControl(doc.status),
  });
}

export enum Permission {
  Basic = '[perm] basic',
  SuperAdmin = '[perm] super admin',
}

export namespace Permission {
  export function toString(type: Permission): string {
    switch (type) {
      case Permission.Basic:
        return 'Basic';
      case Permission.SuperAdmin:
        return 'Super Admin';
      default:
        return '';
    }
  }

  export const members: Permission[] = [Permission.Basic, Permission.SuperAdmin];
}

import { Constraints, parseEnum, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterCharacteristic, NdsCharacterModifierType } from '../../enums';
import { NdsCharacterModifierData } from './nds-character-modifier-data';

export class NdsCharacterModifierCharacteristicData extends NdsCharacterModifierData {
  public type: NdsCharacterModifierType = NdsCharacterModifierType.Characteristic;
  public characteristic!: NdsCharacterCharacteristic;
  public modifierAmount: number = 0;

  constructor(params?: Partial<NdsCharacterModifierCharacteristicData>) {
    super(params);
    if (!!params) {
      this.characteristic = parseEnum(
        NdsCharacterCharacteristic,
        params.characteristic,
        this.characteristic
      );
      this.modifierAmount = parseNumber(params.modifierAmount, this.modifierAmount);
    }
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsCharacterModifierType.Characteristic);
  }

  public static validate(
    input: NdsCharacterModifierCharacteristicData,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = NdsCharacterModifierData.validate(input, fieldNamePrefix);
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Modifier ${
      input.name ? `(${input.name})` : ''
    }`;
    errors.push(
      ...RpgClassValidators.enum(input.characteristic, NdsCharacterCharacteristic, {
        enumName: 'Character Characteristic',
        fieldName: `${name} Characteristic`,
      })
    );
    errors.push(
      ...RpgClassValidators.number(input.modifierAmount, { fieldName: `${name} Amount` })
    );
    return errors;
  }
}

import {
  Component,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, timer, delay, take, tap } from 'rxjs';

export interface SavingIndicatorStatus {
  status?: boolean;
  message: string;
}

const DEFAULT_SAVING_INDICATOR_TIMEOUT = 3000;

@Component({
  selector: 'rpg-saving-indicator',
  templateUrl: './saving-indicator.component.html',
  styleUrls: ['./saving-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavingIndicatorComponent implements OnDestroy {
  @Input()
  public isSaving: boolean = false;
  @Input()
  public saveIndicatorTimeout: number = DEFAULT_SAVING_INDICATOR_TIMEOUT;
  private _saveStatus: SavingIndicatorStatus | undefined;
  @Input()
  public set saveStatus(saveStatus: SavingIndicatorStatus) {
    this._saveStatus = saveStatus;
    if (!!saveStatus) {
      this._saveStatus.message = !!saveStatus.message
        ? this._translocoService.translate(saveStatus.message)
        : saveStatus.message;
    }
    this.fade = false;
    this.clearTimer();
    if (saveStatus?.status === true) {
      this.createTimer();
    }
  }
  public fade: boolean = false;
  public get status(): boolean | undefined {
    return this._saveStatus?.status ?? undefined;
  }
  public get message(): string {
    return this._saveStatus?.message ?? this.defaultMessage;
  }
  public get defaultMessage(): string {
    if (!!this._saveStatus) {
      return this._saveStatus.status
        ? this.getDefaultTranslation('words.Saved')
        : this.getDefaultTranslation('words.Error');
    }
    return '';
  }

  private _timer: Subscription | undefined;
  private _translatedSaved: string = '';
  private _translatedError: string = '';

  constructor(private _cd: ChangeDetectorRef, private _translocoService: TranslocoService) {}

  ngOnDestroy() {
    this.clearTimer();
  }

  public getDefaultTranslation(key: string): string {
    switch (key) {
      case 'words.Saved':
        if (!this._translatedSaved) {
          this._translatedSaved = this._translocoService.translate(key);
        }
        return this._translatedSaved;
      case 'words.Error':
        if (!this._translatedError) {
          this._translatedError = this._translocoService.translate(key);
        }
        return this._translatedError;
      default:
        return '';
    }
  }

  public clearTimer(): void {
    this._timer?.unsubscribe();
  }

  public clearIndicator(): void {
    this.fade = true;
    this._cd.detectChanges();
  }

  public resetSaveStatus(): void {
    this._saveStatus = undefined;
    this.fade = false;
    this._cd.detectChanges();
  }

  public createTimer(): void {
    const oneSecond = 1000;
    this._timer = timer(this.saveIndicatorTimeout)
      .pipe(
        take(1),
        tap(() => this.clearIndicator()),
        delay(oneSecond),
        tap(() => this.resetSaveStatus())
      )
      .subscribe();
  }
}

import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterDetailData } from '@rpg/core/character';

export function ndsCharacterDetailToFormGroup(
  detail: NdsCharacterDetailData
): FormGroup<NdsCharacterDetailData> {
  return new FormGroup<NdsCharacterDetailData>({
    type: new FormControl(detail.type, [Validators.required]),
    value: new FormControl(detail.value),
  });
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NdsCalculatedVehicleModifier } from '@rpg/core/vehicle';

@Component({
  selector: 'rpg-nds-vehicle-modified-value',
  templateUrl: './modified-value.component.html',
  styleUrls: ['./modified-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NdsVehicleModifiedValueComponent {
  public _modifiers: NdsCalculatedVehicleModifier | undefined;
  @Input()
  public set modifiers(value: NdsCalculatedVehicleModifier | undefined) {
    this._modifiers = value;
    this._tooltipContent =
      value?.appliedModifiers.reduce<string>(
        (acc, next) => `${acc.length > 0 ? `${acc}\n` : ''}${next.fromName}: ${next.modifier.name}`,
        ''
      ) ?? '';
  }

  public _tooltipContent: string = 'This is modified!';

  constructor() {}
}

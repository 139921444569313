export namespace StooGoffNpc {
  export interface Npc {
    name: string;
    type: 'Nemesis' | 'Minion' | 'Rival' | string;
    characteristics: Characteristics;
    derived: Derived;
    skills: string[] | { [key: string]: number };
    talents?: Array<TalentClass | string>;
    abilities?: Array<AbilityClass | string>;
    gear?: string;
    tags: string[];
    weapons?: Array<WeaponClass | string>;
    notes?: string;
    source?: SourceClass | string;
  }

  export interface AbilityClass {
    name: string;
    description: string;
    character?: string;
    id?: string;
  }

  export interface Characteristics {
    Brawn: number;
    Agility: number;
    Intellect: number;
    Cunning: number;
    Willpower: number;
    Presence: number;
    Stealth?: number;
    [key: string]: number | undefined;
  }

  export interface Derived {
    soak: number;
    wounds: number;
    strain?: number;
    defence?: number[];
  }

  export interface SourceClass {
    owner: Owner;
    url?: string;
  }

  export enum Owner {
    ChrisHunt = 'Chris Hunt',
    Kualan = 'Kualan',
  }

  export interface TalentClass {
    name: string;
    description: string;
  }

  export interface WeaponClass {
    name: string;
    skill: Skill;
    damage?: number | null | string;
    critical?: number | null | string;
    range: Range;
    qualities?: string[];
    notes?: string;
    'plus-damage'?: number;
    id?: string;
  }

  export enum Range {
    Close = 'Close',
    Engaged = 'Engaged',
    Engages = 'Engages',
    Extreme = 'Extreme',
    Long = 'Long',
    Medium = 'Medium',
    Short = 'Short',
  }

  export enum Skill {
    Bralw = 'Bralw',
    Brawl = 'Brawl',
    Discipline = 'Discipline',
    Gunnery = 'Gunnery',
    Lightsaber = 'Lightsaber',
    LightsaberAgility = 'Lightsaber (Agility)',
    LightsaberCunning = 'Lightsaber (Cunning)',
    LightsaberIntellect = 'Lightsaber (Intellect)',
    LightsaberPresence = 'Lightsaber (Presence)',
    LightsaberWillpower = 'Lightsaber (Willpower)',
    Medicine = 'Medicine',
    Melee = 'Melee',
    RangedHeavy = 'Ranged: Heavy',
    RangedLight = 'Ranged: Light',
  }
}

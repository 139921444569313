import { NdsCharacterObligationEntryData } from './nds-character-obligation-entry-data';

export class NdsCharacterObligationData {
  public obligations: NdsCharacterObligationEntryData[] = [];

  constructor(params?: Partial<NdsCharacterObligationData>) {
    if (!!params) {
      this.obligations = Array.isArray(params.obligations)
        ? params.obligations.map(o => new NdsCharacterObligationEntryData(o))
        : this.obligations;
    }
  }

  public static validate(input: NdsCharacterObligationData): string[] {
    const errors: string[] = [];
    errors.push(
      ...input.obligations.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterObligationEntryData.validate(next)],
        []
      )
    );
    return errors;
  }
}

import { Injectable } from '@angular/core';

interface MaskConfig {
  decimalMarker: string;
  thousandSeparator: string;
}

const defaultMaskConfig: MaskConfig = {
  decimalMarker: '.',
  thousandSeparator: ',',
};

@Injectable({
  providedIn: 'root',
})
export class MaskService {
  public applyNumberMask(inputValue: string, config?: Partial<MaskConfig>): string {
    const mappedConfig: MaskConfig = {
      ...defaultMaskConfig,
      ...config,
    };
    if (
      inputValue.match('[wа-яА-Я]') ||
      inputValue.match('[ЁёА-я]') ||
      inputValue.match('[a-z]|[A-Z]') ||
      inputValue.match(/[-@#!$%\\^&*()_£¬'+|~=`{}[\]:";<>.?/]/) ||
      inputValue.match('[^A-Za-z0-9,]')
    ) {
      inputValue = this._stripToDecimal(inputValue);
    }

    inputValue =
      inputValue.length > 1 && inputValue[0] === '0' && inputValue[1] !== mappedConfig.decimalMarker
        ? inputValue.slice(1, inputValue.length)
        : inputValue;

    const thousandSeperatorCharEscaped: string = this._charToRegExpExpression(
      mappedConfig.thousandSeparator
    );
    const decimalMarkerEscaped: string = this._charToRegExpExpression(mappedConfig.decimalMarker);
    const invalidChars: string = '@#!$%^&*()_+|~=`{}\\[\\]:\\s,";<>?\\/'
      .replace(thousandSeperatorCharEscaped, '')
      .replace(decimalMarkerEscaped, '');

    const invalidCharRegexp: RegExp = new RegExp('[' + invalidChars + ']');

    if (inputValue.match(invalidCharRegexp)) {
      inputValue = inputValue.substring(0, inputValue.length - 1);
    }

    const precision: number = 0;
    inputValue = this._checkInputPrecision(inputValue, precision, mappedConfig.decimalMarker);
    const strForSep: string = inputValue.replace(new RegExp(thousandSeperatorCharEscaped, 'g'), '');
    return this._formatWithSeparators(
      strForSep,
      mappedConfig.thousandSeparator,
      mappedConfig.decimalMarker,
      precision
    );
  }

  private _formatWithSeparators = (
    str: string,
    thousandSeparatorChar: string,
    decimalChar: string,
    precision: number
  ): string => {
    const x: string[] = str.split(decimalChar);
    const decimals: string = x.length > 1 ? `${decimalChar}${x[1]}` : '';
    let res: string = x[0];
    const separatorLimit: string = '';
    if (separatorLimit && +separatorLimit) {
      res = res.slice(0, separatorLimit.length);
    }
    const rgx: RegExp = /(\d+)(\d{3})/;
    while (rgx.test(res)) {
      res = res.replace(rgx, '$1' + thousandSeparatorChar + '$2');
    }
    if (precision === undefined) {
      return res + decimals;
    } else if (precision === 0) {
      return res;
    }
    return res + decimals.substr(0, precision + 1);
    // eslint-disable-next-line @typescript-eslint/semi,@typescript-eslint/member-delimiter-style
  };

  private _checkInputPrecision = (
    inputValue: string,
    precision: number,
    decimalMarker: string
  ): string => {
    if (precision < Infinity) {
      const precisionRegEx: RegExp = new RegExp(
        this._charToRegExpExpression(decimalMarker) + `\\d{${precision}}.*$`
      );

      const precisionMatch: RegExpMatchArray | null = inputValue.match(precisionRegEx);
      if (precisionMatch && precisionMatch[0].length - 1 > precision) {
        inputValue = inputValue.substring(0, inputValue.length - 1);
      } else if (precision === 0 && inputValue.endsWith(decimalMarker)) {
        inputValue = inputValue.substring(0, inputValue.length - 1);
      }
    }
    return inputValue;
    // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
  };

  private _stripToDecimal(str: string): string {
    return str
      .split('')
      .filter(
        (i: string, index: number) =>
          i.match('\\d') || i === '.' || i === ',' || (i === '-' && index === 0)
      )
      .join('');
  }

  private _charToRegExpExpression(char: string): string {
    const charsToEscape: string = '[\\^$.|?*+()';
    return char === ' ' ? '\\s' : charsToEscape.indexOf(char) >= 0 ? '\\' + char : char;
  }
}

import { faStar as faStarRegular } from '@fortawesome/pro-regular-svg-icons';
import {
  faEnvelope,
  faLock,
  faEye,
  faEyeSlash,
  faUser,
  faPlusCircle,
  faPencilAlt,
  faMinus,
  faMinusCircle,
  faBars,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faCheck,
  faLink,
  faQuestionCircle,
  faStar as faStarSolid,
  faSun,
  faMoon,
  faPlus,
  faEdit,
  faSave,
  faCopy,
  faReply,
  faDice,
  faCog,
  faList,
  faExchangeAlt,
  faDownload,
  faPrint,
  faTrash,
  faImage,
  faExclamationTriangle,
  faInfoCircle,
  faListOl,
  faRocket,
  faUsers,
  faHistory,
  faCommentAlt,
  faTh,
  faStickyNote,
  faRaygun,
  faUserCircle,
  faEllipsisH,
  faEllipsisV,
  faArrowLeft,
  faArrowRight,
  faCrosshairs,
  faSignOutAlt,
  faSyncAlt,
  faHouseUser,
  faArrowToBottom,
  faFolder,
  faFolderPlus,
  faCaretRight,
  faUnlink,
} from '@fortawesome/pro-solid-svg-icons';
import { faDiceD20, faDiceD12 } from '@fortawesome/pro-light-svg-icons';
import {
  faTwitterSquare,
  faInstagram,
  faDiscord,
  faPatreon,
  faJediOrder,
} from '@fortawesome/free-brands-svg-icons';

export const icons = [
  faEnvelope,
  faLock,
  faEye,
  faEyeSlash,
  faUser,
  faPlusCircle,
  faPencilAlt,
  faMinus,
  faMinusCircle,
  faBars,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faCheck,
  faLink,
  faQuestionCircle,
  faTwitterSquare,
  faInstagram,
  faDiscord,
  faPatreon,
  faStarSolid,
  faStarRegular,
  faSun,
  faMoon,
  faPlus,
  faEdit,
  faSave,
  faCopy,
  faReply,
  faDice,
  faCog,
  faList,
  faExchangeAlt,
  faDownload,
  faPrint,
  faTrash,
  faImage,
  faExclamationTriangle,
  faInfoCircle,
  faListOl,
  faRocket,
  faUsers,
  faHistory,
  faCommentAlt,
  faTh,
  faStickyNote,
  faRaygun,
  faDiceD20,
  faDiceD12,
  faUserCircle,
  faJediOrder,
  faEllipsisH,
  faEllipsisV,
  faArrowLeft,
  faArrowRight,
  faCrosshairs,
  faSignOutAlt,
  faSyncAlt,
  faHouseUser,
  faArrowToBottom,
  faFolder,
  faFolderPlus,
  faCaretRight,
  faUnlink,
];

import { FormGroup } from '@ngneat/reactive-forms';
import { unreachable } from '@rpg/core/base';
import {
  NdsCharacterModifierArmorData,
  NdsCharacterModifierAttributeData,
  NdsCharacterModifierCharacteristicData,
  NdsCharacterModifierData,
  NdsCharacterModifierNarrativeData,
  NdsCharacterModifierSkillData,
  NdsCharacterModifierType,
  NdsCharacterModifierWeaponData,
} from '@rpg/core/character';
import { ndsCharacterModifierArmorToFormGroup } from './nds-character-modifier-armor-to-form-group';
import { ndsCharacterModifierAttributeToFormGroup } from './nds-character-modifier-attribute-to-form-group';
import { ndsCharacterModifierCharacteristicToFormGroup } from './nds-character-modifier-characteristic-to-form-group';
import { ndsCharacterModifierNarrativeToFormGroup } from './nds-character-modifier-narrative-to-form-group';
import { ndsCharacterModifierSkillToFormGroup } from './nds-character-modifier-skill-to-form-group';
import { ndsCharacterModifierWeaponToFormGroup } from './nds-character-modifier-weapon-to-form-group';

export function NDSCharacterModifierFormGroupFactory(
  modifier: NdsCharacterModifierData
): FormGroup<NdsCharacterModifierData> {
  switch (modifier.type) {
    case NdsCharacterModifierType.Armor:
      return ndsCharacterModifierArmorToFormGroup(modifier as NdsCharacterModifierArmorData);
    case NdsCharacterModifierType.Attribute:
      return ndsCharacterModifierAttributeToFormGroup(
        modifier as NdsCharacterModifierAttributeData
      );
    case NdsCharacterModifierType.Characteristic:
      return ndsCharacterModifierCharacteristicToFormGroup(
        modifier as NdsCharacterModifierCharacteristicData
      );
    case NdsCharacterModifierType.Narrative:
      return ndsCharacterModifierNarrativeToFormGroup(
        modifier as NdsCharacterModifierNarrativeData
      );
    case NdsCharacterModifierType.Skill:
      return ndsCharacterModifierSkillToFormGroup(modifier as NdsCharacterModifierSkillData);
    case NdsCharacterModifierType.Weapon:
      return ndsCharacterModifierWeaponToFormGroup(modifier as NdsCharacterModifierWeaponData);
    default:
      unreachable(modifier.type, 'NDSVehicleModifierFormGroupFactory');
  }
}

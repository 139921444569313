/**
 *
 * @param containerEl Element to search for element to-be-rendered
 * @param selector Selector to search for inside containerEl
 * @param retryAttempt The current retry attempt {default: 0}
 * @param options Options to configure the retry and dispatch behavior
 * @returns
 */
export function focusWhenRendered(
  containerEl: HTMLElement,
  selector: string,
  retryAttempt: number = 0,
  options: {
    MAX_RETRIES: number;
    RETRY_TIMEOUT: number;
  } = {
    MAX_RETRIES: 20,
    RETRY_TIMEOUT: 50,
  }
): void {
  const matchedEl = containerEl.querySelector(selector);
  if (!matchedEl) {
    if (retryAttempt >= options.MAX_RETRIES) {
      // BAIL
      return;
    }
    setTimeout(
      () => focusWhenRendered(containerEl, selector, retryAttempt + 1, options),
      options.RETRY_TIMEOUT
    );
    return; // Do not continue
  }
  // Element exists, focus on it
  if (matchedEl.tagName.startsWith('RPG-')) {
    matchedEl.dispatchEvent(new Event('rpgFocus'));
    return;
  }
  if ((matchedEl as any).focus) {
    (matchedEl as any).focus();
  } else {
    console.warn(`${selector} is not focusable`);
  }
}

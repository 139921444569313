import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { GameTheme, parseNumber } from '@rpg/core/base';
import {
  NDS_CHARACTER_CHARACTERISTIC_DEFAULT_VALUE,
  NDS_CHARACTER_CHARACTERISTIC_MAX_VALUE,
  NDS_CHARACTER_CHARACTERISTIC_MIN_VALUE,
} from '@rpg/core/character';
import { RxState } from '@rx-angular/state';

interface ComponentState {
  attrImage: string;
  label: string;
  editMode: boolean;
  resolvedValue: number;
}

@Component({
  selector: 'rpg-nds-character-characteristic-single',
  templateUrl: './characteristic-single.component.html',
  styleUrls: ['./characteristic-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class NdsCharacterCharacteristicSingleComponent {
  @Input()
  public set gameTheme(theme: GameTheme) {
    this._updateAttrImage(theme);
    // This theme just needs a different offset due to the design
    this.higherOffset = theme === GameTheme.Community_Inquisition;
  }

  @Input()
  public set label(label: string) {
    this._state.set({
      label,
    });
  }

  @Input()
  public set editMode(editMode: boolean) {
    this._state.set({
      editMode,
    });
  }

  @Input()
  public set resolvedValue(resolvedValue: number) {
    this._state.set({
      resolvedValue,
    });
  }

  @Input()
  public superCharacteristicsEnabled: boolean = false;

  @Input()
  public superCharacteristicControl!: FormControl<boolean>;

  @Input()
  public control!: FormControl<number>;

  public state$ = this._state.select();
  public higherOffset: boolean = false;

  constructor(private _state: RxState<ComponentState>) {
    this._state.set({
      editMode: false,
      label: '',
      resolvedValue: undefined,
    });
  }

  public checkValue(): void {
    const val = parseNumber(this.control.value);
    if (val === null) {
      this.control.setValue(NDS_CHARACTER_CHARACTERISTIC_DEFAULT_VALUE);
    } else if (isNaN(val)) {
      this.control.setValue(NDS_CHARACTER_CHARACTERISTIC_MIN_VALUE);
    } else if (val < NDS_CHARACTER_CHARACTERISTIC_MIN_VALUE) {
      this.control.setValue(NDS_CHARACTER_CHARACTERISTIC_MIN_VALUE);
    } else if (val > NDS_CHARACTER_CHARACTERISTIC_MAX_VALUE) {
      this.control.setValue(NDS_CHARACTER_CHARACTERISTIC_MAX_VALUE);
    }
  }

  public toggleSuperCharacteristic(): void {
    this.superCharacteristicControl.setValue(!this.superCharacteristicControl.value);
  }

  private _updateAttrImage(theme: GameTheme): void {
    let imgSrc: string = '';
    switch (theme) {
      case GameTheme.StarWars_AoR:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/starwars/charstat-swaor.svg?auto=format';
        break;
      case GameTheme.StarWars_EotE:
        imgSrc = 'https://unbound-legends.imgix.net/rules-nds/starwars/charstat-sw.svg?auto=format';
        break;
      case GameTheme.StarWars_FaD:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/starwars/charstat-swfad.svg?auto=format';
        break;
      case GameTheme.Genesys_Android:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-android/charstat-and.svg?auto=format';
        break;
      case GameTheme.Genesys_TwilightImperium:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-imperium/charstat-ti.svg?auto=format';
        break;
      case GameTheme.Genesys_Core:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-core/charstat-gen.svg?auto=format';
        break;
      case GameTheme.Genesys_Keyforge:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-keyforge/charstat-kf.svg?auto=format';
        break;
      case GameTheme.Genesys_Terrinoth:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-terrinoth/charstat-ter.svg?auto=format';
        break;
      case GameTheme.Community_AwakenedAge:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-awakenedage/charstat-aa.svg?auto=format';
        break;
      case GameTheme.Community_Inquisition:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-inquisition/charstat-in.svg?auto=format';
        break;
      case GameTheme.Community_SomethingStrange:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-somethingstrange/charstat-ss.svg?auto=format';
        break;
      case GameTheme.Community_Tron:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-tron/charstat-tron.svg?auto=format';
        break;
      default:
        throw new Error('Invalid Game Theme supplied to NdsVehicleAttributeSingle');
    }
    this._state.set({
      attrImage: imgSrc,
    });
  }
}

import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterDutyEntryData } from '@rpg/core/character';

export function ndsCharacterDutyEntryToFormGroup(
  entry: NdsCharacterDutyEntryData
): FormGroup<NdsCharacterDutyEntryData> {
  return new FormGroup<NdsCharacterDutyEntryData>({
    amount: new FormControl(entry.amount),
    description: new FormControl(entry.description),
    id: new FormControl(entry.id),
    name: new FormControl(entry.name),
    triggered: new FormControl(entry.triggered),
  });
}

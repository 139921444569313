export enum NdsVehicleFireArc {
  Forward = '[nds fire arc] forward',
  Aft = '[nds fire arc] aft',
  Port = '[nds fire arc] port',
  Starboard = '[nds fire arc] starboard',
  Dorsal = '[nds fire arc] dorsal',
  Ventral = '[nds fire arc] ventral',
  All = '[nds fire arc] all',
}

export namespace NdsVehicleFireArc {
  export function translationKey(type: NdsVehicleFireArc): string {
    return `enums.vehicle.nds.fire-arc.${type}`;
  }

  export const members: NdsVehicleFireArc[] = [
    NdsVehicleFireArc.Forward,
    NdsVehicleFireArc.Aft,
    NdsVehicleFireArc.Port,
    NdsVehicleFireArc.Starboard,
    NdsVehicleFireArc.Dorsal,
    NdsVehicleFireArc.Ventral,
    NdsVehicleFireArc.All,
  ];
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { SessionService } from '../services';
import { Observable } from 'rxjs';

@Injectable()
export class SessionIdInterceptor implements HttpInterceptor {
  constructor(private _session: SessionService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newOpts = { headers: req.headers };
    newOpts.headers = req.headers.set('rpg-session-id', this._session.sessionId);
    const updatedReq = req.clone(newOpts);
    return next.handle(updatedReq);
  }
}

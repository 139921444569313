<ng-container *rxLet="state$; let state">
  <div mat-dialog-header>
    <h4 [innerHTML]="'shared.import-overlay.' + state.title | transloco | markdown"></h4>
    <h5 *ngIf="state.limits">
      {{ 'shared.import-overlay.monthly-imports-label' | transloco }}
      {{ state.limits.current + (state.limits.limit === -1 ? '' : ' / ' + state.limits.limit) }}
    </h5>
  </div>
  <div mat-dialog-content *ngIf="state.loading || !state.limits" class="spinner">
    <mat-spinner></mat-spinner>
  </div>
  <div mat-dialog-content *ngIf="state.limits && !state.loading">
    <div
      class="form-group"
      *ngIf="state.limits.limit === -1 || state.limits.current < state.limits.limit"
    >
      <div *ngIf="state.error" class="error">
        {{ state.error }}
      </div>
      <rpg-select-typeahead
        [options]="state.items"
        placeholder="Start typing the name of an NPC"
        [minCharacters]="3"
        [resultLimit]="20"
        [inputControl]="inputName"
      ></rpg-select-typeahead>
    </div>
    <div
      class="patron-error"
      *ngIf="state.limits.limit !== -1 && state.limits.current >= state.limits.limit"
    >
      <p>{{ 'shared.import-overlay.import-limit-greeting' | transloco }}</p>
      <p>{{ 'shared.import-overlay.import-limit-description' | transloco }}</p>
      <p>
        <span [innerHTML]="'shared.import-overlay.import-limit-patreon-tier-error' | transloco">
        </span>
      </p>
    </div>
  </div>
  <div mat-dialog-actions class="actions" *ngIf="!state.loading">
    <div class="button-container">
      <button class="cancel" mat-raised-button color="accent" type="button" (click)="cancel()">
        {{ 'words.Cancel' | transloco }}
      </button>
      <button
        class="save"
        *ngIf="
          state.limits && (state.limits.limit === -1 || state.limits.current < state.limits.limit)
        "
        mat-raised-button
        color="primary"
        (click)="import()"
        [disabled]="(submitEnabled$ | async) === (false || null || undefined)"
      >
        {{ 'words.Import' | transloco }}
      </button>
    </div>
  </div>
</ng-container>

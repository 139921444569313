import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroDash',
})
export class RpgZeroDashPipe implements PipeTransform {
  private _cases: any[] = [0, '0', ''];

  public transform(value: string | number | undefined): string | number {
    if (!value || typeof value === 'undefined' || this._cases.includes(value)) {
      return '-';
    }
    return value;
  }
}

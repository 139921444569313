import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

// Boilerplate for applying mixins to MatInput.
/** @docs-private */
class MatInputBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    /** @docs-private */
    public ngControl: NgControl
  ) {}
}
export const _MatInputMixinBase: typeof MatInputBase = mixinErrorState(MatInputBase as any);

@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    'span[contenteditable]:not([rpgContentEditableNumber]), div[contenteditable]:not([rpgContentEditableNumber])',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ContentEditableFormDirective,
    },
  ],
})
export class ContentEditableFormDirective
  extends _MatInputMixinBase
  implements ControlValueAccessor, MatFormFieldControl<string>, DoCheck
{
  /**
   * Implemented as part of MatFormFieldControl.
   * See https://material.angular.io/guide/creating-a-custom-form-field-control
   */
  public static nextId: number = 0;

  @Input()
  public get value(): string {
    return this.elementRef.nativeElement[this.propValueAccessor];
  }
  public set value(value: string) {
    if (value !== this.value) {
      this.elementRef.nativeElement[this.propValueAccessor] = value;
      this.stateChanges.next();
    }
  }

  public readonly stateChanges: Subject<void> = new Subject<void>();

  @HostBinding()
  public id: string = `mat-input-${ContentEditableFormDirective.nextId++}`;

  @Input()
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string = '';

  public focused: boolean = false;

  @Input() public contentEmpty: Array<string> = ['<br>', '<div><br></div>'];
  public get empty(): boolean {
    return !this.value || this.contentEmpty.includes(this.value);
  }

  public get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  public get required(): boolean {
    return this._required;
  }
  public set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required: boolean = false;

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  private _disabled: boolean = false;

  @HostBinding('attr.aria-invalid') public errorState: boolean = false;
  @Input() public errorStateMatcher!: ErrorStateMatcher;

  @Input() public propValueAccessor: string = 'innerHTML';

  public controlType: string = 'mat-input';

  @HostBinding('attr.aria-describedby') public describedBy: string = '';

  // Part of ControlValueAccessor
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  private removeDisabledState: () => void = () => {};

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    // Setting the value accessor directly (instead of using
    // the providers) to avoid running into a circular import.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      (this as any).updateErrorState?.();
    }
  }

  @HostListener('input')
  public callOnChange(): void {
    if (typeof this.onChange === 'function') {
      this.onChange(this.elementRef.nativeElement[this.propValueAccessor]);
    }
  }

  @HostListener('focus')
  public callOnFocused(): void {
    if (this.focused !== true) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  @HostListener('blur')
  public callOnTouched(): void {
    if (typeof this.onTouched === 'function') {
      this.onTouched();
    }
    if (this.focused !== false) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(): void {
    this.elementRef.nativeElement.focus();
  }

  public writeValue(value: any): void {
    const normalizedValue = value == null ? '' : value;
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      this.propValueAccessor,
      normalizedValue
    );
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
      this.removeDisabledState = this.renderer.listen(
        this.elementRef.nativeElement,
        'keydown',
        this.listenerDisabledState
      );
    } else {
      if (this.removeDisabledState) {
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
        this.removeDisabledState();
      }
    }
  }

  private listenerDisabledState(e: KeyboardEvent): void {
    e.preventDefault();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetModule } from '@rx-angular/template';

import { components } from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '../../shared';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MarkdownModule } from 'ngx-markdown';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    LetModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    FontAwesomeModule,
    MatButtonModule,
    MarkdownModule,
    MatTableModule,
    TranslocoModule,
    MatCheckboxModule,
  ],
})
export class NdsSharedComponentsModule {}

<div class="flex-row flex-h-center">
  <div class="theme">
    <rpg-well>
      <rpg-nds-vehicle-characteristic-single
        [gameTheme]="configurationForm.get('gameTheme').value"
      ></rpg-nds-vehicle-characteristic-single>
      <rpg-nds-attribute-double
        [gameTheme]="configurationForm.get('gameTheme').value"
      ></rpg-nds-attribute-double>
    </rpg-well>
    <rpg-select
      [placeholder]="'words.Theme' | transloco"
      [inputControl]="configurationForm.get('gameTheme')"
      [options]="gameThemeOptions"
    ></rpg-select>
  </div>
  <div class="type"></div>
</div>
<div class="divider" (click)="advancedOptionsVisible = !advancedOptionsVisible">
  <fa-icon icon="caret-right" [class.rotated]="advancedOptionsVisible"></fa-icon>
  <span class="label">{{ 'phrases.Advanced Options' | transloco }}</span>
  <span class="line"></span>
</div>
<div
  class="advanced"
  [formGroup]="configurationForm"
  [@detailExpand]="advancedOptionsVisible ? 'expanded' : 'collapsed'"
>
  <div class="configs">
    <div class="group">
      <h3>{{ 'words.Dice' | transloco }}</h3>
      <rpg-well>
        <mat-radio-group formControlName="diceTheme">
          <mat-radio-button [value]="DiceTheme.Genesys">
            {{ 'dice.phrases.Genesys Dice' | transloco }}
          </mat-radio-button>
          <mat-checkbox formControlName="forceDiceEnabled" class="inset">
            {{ 'dice.phrases.Force Dice' | transloco }}
          </mat-checkbox>
          <mat-radio-button [value]="DiceTheme.StarWars">
            {{ 'dice.phrases.Star Wars Dice' | transloco }}
          </mat-radio-button>
        </mat-radio-group>
      </rpg-well>
    </div>
    <div class="group">
      <h3>{{ 'vehicle.phrases.Defense Zones' | transloco }}</h3>
      <rpg-well class="zone-grid">
        <ng-container *ngFor="let slot of defenseZoneGrid">
          <span *ngIf="!slot" class="spacer"></span>
          <mat-checkbox
            *ngIf="!!slot"
            [value]="slot"
            (change)="updateDefenseZone($event)"
            [checked]="configurationForm.get('defenseZones').value.includes(slot)"
          >
            {{ NdsVehicleDefenseZone.translationKey(slot) | transloco }}
          </mat-checkbox>
        </ng-container>
      </rpg-well>
      <mat-error
        class="zone-error"
        [rpgFormFieldError]="configurationForm.get('defenseZones')"
      ></mat-error>
    </div>
  </div>
</div>

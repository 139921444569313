import { DataType, RulesSystem } from '@rpg/core/base';
import { CritTable, CritTableRow } from '@rpg/core/crit';

// eslint:disable
export const SWCharacterCritTable: CritTable = new CritTable({
  dataType: DataType.Character,
  description: 'FFG SW Character Crit Table',
  rulesSystem: RulesSystem.NarrativeDiceSystem,
  name: 'FFG SW Character Crit Table',
  status: 1,
  userId: null,
  rows: [
    new CritTableRow({
      id: 1,
      name: 'Minor Nick',
      description: 'The target suffers 1 strain',
      difficulty: 1,
      min: 0,
      max: 5,
    }),
    new CritTableRow({
      id: 2,
      name: 'Slowed Down',
      description:
        'The target can only act during the last allied initiative slot on their next turn',
      difficulty: 1,
      min: 6,
      max: 10,
    }),
    new CritTableRow({
      id: 3,
      name: 'Sudden Jolt',
      description: 'The target drops whatever is in hand',
      difficulty: 1,
      min: 11,
      max: 15,
    }),
    new CritTableRow({
      id: 4,
      name: 'Distracted',
      description: 'The target cannot perform a free maneuver during their next turn',
      difficulty: 1,
      min: 16,
      max: 20,
    }),
    new CritTableRow({
      id: 5,
      name: 'Off-Balance',
      description: "Add :eK: to the target's next skill check",
      difficulty: 1,
      min: 21,
      max: 25,
    }),
    new CritTableRow({
      id: 6,
      name: 'Discouraging Wound',
      description:
        'Flip one light side Destiny Point to a dark side Destiny Point (reverse if NPC)',
      difficulty: 1,
      min: 26,
      max: 30,
    }),
    new CritTableRow({
      id: 7,
      name: 'Stunned',
      description: 'The target is staggered until the end of their next turn',
      difficulty: 1,
      min: 31,
      max: 35,
    }),
    new CritTableRow({
      id: 8,
      name: 'Stinger',
      description: 'Increase the difficulty of the next check by one',
      difficulty: 1,
      min: 36,
      max: 40,
    }),
    new CritTableRow({
      id: 9,
      name: 'Bowled Over',
      description: 'The target is knocked prone and suffers 1 strain',
      difficulty: 2,
      min: 41,
      max: 45,
    }),
    new CritTableRow({
      id: 10,
      name: 'Head Ringer',
      description:
        'The target increases the difficulty of all Intellect and Cunning checks by one until the end of the encounter',
      difficulty: 2,
      min: 46,
      max: 50,
    }),
    new CritTableRow({
      id: 11,
      name: 'Fearsome Wound',
      description:
        'The target increases the difficulty of all Presence and Willpower checks by one until the end of the encounter',
      difficulty: 2,
      min: 51,
      max: 55,
    }),
    new CritTableRow({
      id: 12,
      name: 'Agonizing Wound',
      description:
        'The target increases the difficulty of all Brawn and Agility checks by one until the end of the encounter',
      difficulty: 2,
      min: 56,
      max: 60,
    }),
    new CritTableRow({
      id: 13,
      name: 'Slightly Dazed',
      description: 'The target is disoriented until the end of the encounter',
      difficulty: 2,
      min: 61,
      max: 65,
    }),
    new CritTableRow({
      id: 14,
      name: 'Scattered Senses',
      description: 'The target removes all :eB: from skill checks until the end of the encounter',
      difficulty: 2,
      min: 66,
      max: 70,
    }),
    new CritTableRow({
      id: 15,
      name: 'Hamstrung',
      description: 'The target loses their free maneuver until the end of the encounter',
      difficulty: 2,
      min: 71,
      max: 75,
    }),
    new CritTableRow({
      id: 16,
      name: 'Overpowered',
      description:
        'The target leaves themselves open, and the attacker may immediately attempt another free attack against them, using the exact same pool as the original attack',
      difficulty: 2,
      min: 76,
      max: 80,
    }),
    new CritTableRow({
      id: 17,
      name: 'Winded',
      description:
        'Until the end of the encounter, the target cannot voluntarily suffer strain to activate any abilities or gain additional maneuvers',
      difficulty: 2,
      min: 81,
      max: 85,
    }),
    new CritTableRow({
      id: 18,
      name: 'Compromised',
      description:
        'Increase the difficulty of all skill checks by one until the end of the encounter',
      difficulty: 2,
      min: 86,
      max: 90,
    }),
    new CritTableRow({
      id: 19,
      name: 'At the Brink',
      description: 'The target suffers 1 strain each time they perform an action',
      difficulty: 3,
      min: 91,
      max: 95,
    }),
    new CritTableRow({
      id: 20,
      name: 'Crippled',
      description:
        "One of the target's limbs (selected by the GM) is impaired until healed or replaced. Increase difficulty of all checks that require use of that limb by one",
      difficulty: 3,
      min: 96,
      max: 100,
    }),
    new CritTableRow({
      id: 21,
      name: 'Maimed',
      description:
        "One of the target's limbs (selected by the GM) is permanently lost. Unless the target has a cybernetic replacement, the target cannot perform actions that would require the use of that limb. All other actions gain :eK:",
      difficulty: 3,
      min: 101,
      max: 105,
    }),
    new CritTableRow({
      id: 22,
      name: 'Horrific Injury',
      description:
        "Roll 1d10 to determine which of the target's characteristics is affected: 1-3 for Brawn, 4-6 for Agility, 7 for Intellect, 8 for Cunning, 9 for Presence, 10 for Willpower. Until this Critical Injury is healed, treat that characteristic as 1 point lower",
      difficulty: 3,
      min: 106,
      max: 110,
    }),
    new CritTableRow({
      id: 23,
      name: 'Temporarily Lame',
      description:
        'Until this Critical Injury is healed, the target cannot perform more than one manuever during their turn',
      difficulty: 3,
      min: 111,
      max: 115,
    }),
    new CritTableRow({
      id: 24,
      name: 'Blinded',
      description:
        'The target can no longer see. Upgrade the difficulty of checks twice. Upgrade the difficulty of Perception and Vigilance checks three times',
      difficulty: 3,
      min: 116,
      max: 120,
    }),
    new CritTableRow({
      id: 25,
      name: 'Knocked Senseless',
      description: 'The target is staggered for the remainder of the encounter',
      difficulty: 3,
      min: 121,
      max: 125,
    }),
    new CritTableRow({
      id: 26,
      name: 'Gruesome Injury',
      description:
        "Roll 1d10 to determine which of the target's characteristics is affected: 1-3 for Brawn, 4-6 for Agility, 7 for Intellect, 8 for Cunning, 9 for Presence, 10 for Willpower. That characteristic is permanently reduced by 1, to a minimum of 1",
      difficulty: 4,
      min: 126,
      max: 130,
    }),
    new CritTableRow({
      id: 27,
      name: 'Bleeding Out',
      description:
        'Every round, the target suffers 1 wound and 1 strain at the beginning of their turn. For every 5 wounds they suffer beyond their wound threshold, they suffer 1 additional Critical Injury. Roll on the chart, suffering the injury (if they would suffer this result a second time due to this, roll again)',
      difficulty: 4,
      min: 131,
      max: 140,
    }),
    new CritTableRow({
      id: 28,
      name: 'The end is Nigh',
      description: 'The target will die after the last initiative slot during the next round',
      difficulty: 4,
      min: 141,
      max: 150,
    }),
    new CritTableRow({
      id: 29,
      name: 'Dead',
      description: 'Complete, obliterated death',
      difficulty: 0,
      min: 151,
      max: null,
    }),
  ],
});

export const SWVehicleCritTable: CritTable = new CritTable({
  dataType: DataType.Vehicle,
  description: 'FFG SW Vehicle Crit Table',
  rulesSystem: RulesSystem.NarrativeDiceSystem,
  name: 'FFG SW Vehicle Crit Table',
  status: 1,
  userId: null,
  rows: [
    new CritTableRow({
      id: 1,
      name: 'Mechanical Stress',
      description: 'The ship or vehicle suffers 1 point of system strain',
      difficulty: 1,
      min: 0,
      max: 9,
    }),
    new CritTableRow({
      id: 2,
      name: 'Jostled',
      description:
        'A small explosion or impact rocks the vehicle. All crew members suffer 1 strain and are disoriented for one round',
      difficulty: 1,
      min: 10,
      max: 18,
    }),
    new CritTableRow({
      id: 3,
      name: 'Losing Power to Shields',
      description:
        'Decrease defense in affecteddefense zone by 1 until this Critical Hit is repaired. If the ship or vehicle has no defense, suffer 1 point of system strain',
      difficulty: 1,
      min: 19,
      max: 27,
    }),
    new CritTableRow({
      id: 4,
      name: 'Knocked Off Course',
      description:
        'A particularly strong blast or impact sends the ship or vehicle careening off in a new direction. On their next turn, the pilot cannot execute any maneuvers and must make a Piloting check to regain control. The difficulty of this check depends on their current speed',
      difficulty: 1,
      min: 28,
      max: 36,
    }),
    new CritTableRow({
      id: 5,
      name: 'Tailspin',
      description:
        "All firing from the ship or vehicle suffers :eK: :eK: until the end of the pilot's next turn. All crew members are immobilized until the end of the pilot's next turn",
      difficulty: 1,
      min: 37,
      max: 45,
    }),
    new CritTableRow({
      id: 6,
      name: 'Component Hit',
      description:
        'One component of the attackers choice is knocked offline and is rendered inoperable until the end of the following round',
      difficulty: 1,
      min: 46,
      max: 54,
    }),
    new CritTableRow({
      id: 7,
      name: 'Shields Failing',
      description:
        'Reduce defense in all defense zones by 1 point until this Critical Hit is repaired. If the ship or vehicle has no defense, suffer 2 points of system strain',
      difficulty: 2,
      min: 55,
      max: 63,
    }),
    new CritTableRow({
      id: 8,
      name: 'Navicomputer Failure',
      description:
        "The navicomputer (or in the case of a ship without a navicomputer, its R2 unit) fails, and the ship cannot make the jump to hyperspace until this Critical Hit is repaired. If the ship or vehicle is without a hyperdrive, the vehicle or ship's navigation systems fail, leaving it flying or driving blind, unable to tell where it is or where it's going",
      difficulty: 2,
      min: 64,
      max: 72,
    }),
    new CritTableRow({
      id: 9,
      name: 'Power Fluctuations',
      description:
        'The shipr or vehicle is beset by random power surges and outages. The pilot cannot voluntarily inflict system strain on the ship (to gain an extra maneuver, for example) until this Critical Hit is repaired',
      difficulty: 2,
      min: 73,
      max: 81,
    }),
    new CritTableRow({
      id: 10,
      name: 'Shields Down',
      description:
        'Decrease defense in the affected defense zone to 0, and decrease the defense in all other defense zones by 1 until this Critial Hit is repaired. While the defense of the affected defense zone cannot be restored until the Critial Hit is repaired, defense from other zones can be assigned to protect that defnse zone as usual. If the ship or vehicle has no defense, suffer 4 points of system strain',
      difficulty: 3,
      min: 82,
      max: 90,
    }),
    new CritTableRow({
      id: 11,
      name: 'Engine Damaged',
      description:
        "The ship or vehicle's maximum speed is reduced by 1 point, to a minimum of 1, until this Critical Hit is repaired",
      difficulty: 3,
      min: 91,
      max: 99,
    }),
    new CritTableRow({
      id: 12,
      name: 'Shield Overload',
      description:
        "The ship's shields completely fail. Decrease the defense of all defense zones to 0. This Critial Hit cannot be repaired until the end of the encounter, and the ship suffers 2 points of system strain. If the ship or vehicle has no defense, reduce armor by 1 until this Critial Hit is repaired",
      difficulty: 3,
      min: 100,
      max: 108,
    }),
    new CritTableRow({
      id: 13,
      name: 'Engines Down',
      description:
        "The ship or vehicle's maximum speed is reduced to 0 until this Critial Hit is repaired, although it continues on its present course thanks to momentum. In addition, the ship cannot execute any manuevers until this Critial Hit is repaired",
      difficulty: 3,
      min: 109,
      max: 117,
    }),
    new CritTableRow({
      id: 14,
      name: 'Major System Failure',
      description:
        "One component of the attacker's choice is heavily damaged and inoperable until this Critical Hit is repaired",
      difficulty: 3,
      min: 118,
      max: 126,
    }),
    new CritTableRow({
      id: 15,
      name: 'Major Hull Breach',
      description:
        "A huge, gaping tear is torn in the ship's hull, and the ship depressurizes. For ships and vehicles of silhouette 4 and smaller, the entire ship depressurizes in a number of rounds equal to the ship's silhouette. Ships and vehicles of silhouette 5 and larger tend to be highly compartmentalized and have many safeguards against depressurization. These ships don't complete depressurize, but parts do (the specifics regarding which parts depressurize is up to the GM; however, each section of the ship or vehicle that does lose air does so in a number of rounds equal to the vehicle's silhouette). Vehicles and ships in atmosphere suffer the Destabilzed Critical Hit instead (Reduce hull trauma and system strain thresholds to half until repaired)",
      difficulty: 4,
      min: 127,
      max: 133,
    }),
    new CritTableRow({
      id: 16,
      name: 'Destabilized',
      description:
        "The ship or vehicle's structural integrity is seriously damaged. Reduce the ship or vehicle's hull trauma threshold and system strain threshold to half their original values until repaired",
      difficulty: 4,
      min: 134,
      max: 138,
    }),
    new CritTableRow({
      id: 17,
      name: 'Fire!',
      description:
        "Fire rages through the ship. The ship or vehicle immediately takes 2 points of system strain and anyone caught in the fire takes damage as discussed by CRB-220. A fire can be put out with some quick thinking and appropriate skill or Vigilance and/or Cool checks at the GMs discretion. Once going, a fire takes one round per 2 of the ship's silhouette points to put out",
      difficulty: 4,
      min: 139,
      max: 144,
    }),
    new CritTableRow({
      id: 18,
      name: 'Breaking Up',
      description:
        'The vehicle or ship has suffered so much damage that it begins to come apart at its seams, breaking up and disintegrating around the crew. At the end of the following round, the ship is completely destroyed and the surrounding environment is littered with debris. Anyone aboard the ship or vehicle has one round to get to an escape pod, bail out, or dive for the nearest hatch before they are lost',
      difficulty: 4,
      min: 145,
      max: 153,
    }),
    new CritTableRow({
      id: 19,
      name: 'Vaporized',
      description:
        'The ship or vehicle is completely destoryed, consumed in a particularly large and dramatic fireball. Nothing survives',
      difficulty: 0,
      min: 154,
      max: null,
    }),
  ],
});

import { Injectable } from '@angular/core';
import { CreateContentResponse } from '@rpg/core/content-library';
import { ImportSource } from '@rpg/core/imports';
import { BaseOverlayService } from '@rpg/ngx/components/overlays';
import { OverlayResponse } from '@rpg/ngx/core';
import { ObjectId } from 'bson';
import { firstValueFrom } from 'rxjs';
import { CharacterImportComponent } from './character-import/character-import.component';
import {
  CreateContentComponent,
  CreateContentOptions,
} from './create-content/create-content.component';
import { NpcImportComponent } from './npc-import/npc-import.component';

interface ImportOptions {
  type: ImportSource;
  parentFolderId?: ObjectId;
}

@Injectable()
export class SharedOverlayService extends BaseOverlayService {
  public createContent(options?: Partial<CreateContentOptions>) {
    const ref = this._dialog.open<
      CreateContentComponent,
      Partial<CreateContentOptions>,
      OverlayResponse<CreateContentResponse>
    >(CreateContentComponent, { data: options, panelClass: 'create-content-overlay-panel' });
    return firstValueFrom(ref.afterClosed());
  }

  public characterImport(options: ImportOptions) {
    const ref = this._dialog.open<
      CharacterImportComponent,
      ImportOptions,
      OverlayResponse<boolean>
    >(CharacterImportComponent, { data: options });
    return firstValueFrom(ref.afterClosed());
  }

  public npcImport(options: ImportOptions) {
    const ref = this._dialog.open<NpcImportComponent, ImportOptions, OverlayResponse<boolean>>(
      NpcImportComponent,
      { data: options }
    );
    return firstValueFrom(ref.afterClosed());
  }
}

export class LocalStorageConfig {
  public vehicleSort: string = '';
  public characterSort: string = '';
  public gameSort: string = '';

  constructor(params?: Partial<LocalStorageConfig>) {
    if (!!params) {
      this.vehicleSort = params.vehicleSort ?? this.vehicleSort;
      this.characterSort = params.characterSort ?? this.characterSort;
      this.gameSort = params.gameSort ?? this.gameSort;
    }
  }
}

import { Injectable, ApplicationRef, Inject, PLATFORM_ID } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, concat, first } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { ToastService } from './toast.service';
import { Logger } from '@rpg/core/base';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerService {
  // private _reloadOnUpdateAvailable: boolean = false;

  constructor(
    private _appRef: ApplicationRef,
    private _updates: SwUpdate,
    @Inject(PLATFORM_ID) private _platformId: any,
    private _toastService: ToastService
  ) {
    if (isPlatformBrowser(this._platformId) && this._updates.isEnabled) {
      const appIsStable$ = this._appRef.isStable.pipe(first(isStable => isStable === true));
      // eslint-disable-next-line no-magic-numbers
      const everyFiveMinutes$ = interval(5 * 60 * 1000);
      concat(appIsStable$, everyFiveMinutes$).subscribe(() => this._updates.checkForUpdate());

      this._updates.available.subscribe(event => {
        Logger.log('--------UPDATE AVAILBLE--------');
        Logger.log(event);
        Logger.log('--------UPDATE AVAILBLE--------');
        this._toastService.updateNotification();
      });

      this._updates.activated.subscribe(event => {
        Logger.log('--------UPDATE APPLIED--------');
        Logger.log(event);
        Logger.log('--------UPDATE APPLIED--------');
      });
    }
    Logger.log('UPDATE STATUS', this._updates.isEnabled);
  }

  public async checkForUpdates(): Promise<void> {
    if (isPlatformBrowser(this._platformId) && this._updates.isEnabled) {
      await this._updates.checkForUpdate();
      Logger.log('CHECK FOR UPDATE COMPLETED 2');
      return;
    }
  }

  public async applyUpdate(): Promise<void> {
    if (isPlatformBrowser(this._platformId) && this._updates.isEnabled) {
      await this._updates.activateUpdate();
    }
  }

  public async initialCheck(): Promise<void> {
    if (isPlatformBrowser(this._platformId) && this._updates.isEnabled) {
      // this._reloadOnUpdateAvailable = true;
      await this._updates.checkForUpdate();
      // this._reloadOnUpdateAvailable = false;
    }
  }
}

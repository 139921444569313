export enum OggDudeCustomImportType {
  AllData = '[oggdude custom import type] all data',
  Armor = '[oggdude custom import type] armor',
  Attitudes = '[oggdude custom import type] attitudes',
  Careers = '[oggdude custom import type] careers',
  Classes = '[oggdude custom import type] classes',
  Duty = '[oggdude custom import type] duty',
  ForceAbilities = '[oggdude custom import type] force abilities',
  ForcePowers = '[oggdude custom import type] force powers',
  Gear = '[oggdude custom import type] gear',
  Hooks = '[oggdude custom import type] hooks',
  ItemAttachments = '[oggdude custom import type] item attachments',
  Moralities = '[oggdude custom import type] moralities',
  Motivations = '[oggdude custom import type] motivations',
  Obligations = '[oggdude custom import type] obligations',
  SignatureAbilities = '[oggdude custom import type] signature abilities',
  SignatureAbilityNodes = '[oggdude custom import type] signature ability nodes',
  Skills = '[oggdude custom import type] skills',
  Specializations = '[oggdude custom import type] specializations',
  Species = '[oggdude custom import type] species',
  SpecificMotivations = '[oggdude custom import type] specific motivations',
  Talents = '[oggdude custom import type] talents',
  Weapons = '[oggdude custom import type] weapons',
}

export namespace OggDudeCustomImportType {
  export const members: OggDudeCustomImportType[] = [
    OggDudeCustomImportType.AllData,
    OggDudeCustomImportType.Armor,
    OggDudeCustomImportType.Attitudes,
    OggDudeCustomImportType.Careers,
    OggDudeCustomImportType.Classes,
    OggDudeCustomImportType.Duty,
    OggDudeCustomImportType.ForceAbilities,
    OggDudeCustomImportType.ForcePowers,
    OggDudeCustomImportType.Gear,
    OggDudeCustomImportType.Hooks,
    OggDudeCustomImportType.ItemAttachments,
    OggDudeCustomImportType.Moralities,
    OggDudeCustomImportType.Motivations,
    OggDudeCustomImportType.Obligations,
    OggDudeCustomImportType.SignatureAbilities,
    OggDudeCustomImportType.SignatureAbilityNodes,
    OggDudeCustomImportType.Skills,
    OggDudeCustomImportType.Specializations,
    OggDudeCustomImportType.Species,
    OggDudeCustomImportType.SpecificMotivations,
    OggDudeCustomImportType.Talents,
    OggDudeCustomImportType.Weapons,
  ];
}

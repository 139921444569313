import { HttpErrorResponse } from '@angular/common/http';
// import { Logger } from '@rpg/core/base';

// interface NestErrorMessage {
//   children: NestErrorMessage[];
//   constraints: { [key: string]: string };
//   property: string;
//   target: any;
//   value: any;
// }

// function decodeNestErrorMessage(error: NestErrorMessage): string {
//   Logger.error(error);
//   let message = '';
//   if (Array.isArray(error.children) && error.children.length > 0) {
//     message = error.children
//       // eslint-disable-next-line @typescript-eslint/no-unused-vars
//       .map(child => decodeNestErrorMessage(child))
//       .join(', ');
//   } else {
//     message = Object.values(error.constraints).join(', ');
//   }
//   return message;
// }

export function getErrorMessage(error: HttpErrorResponse | Error | string | any): string {
  if (typeof error === 'string') {
    return error;
  } else if (error instanceof HttpErrorResponse && !!error.error) {
    return getErrorMessage(error.error);
  } else if (!!error && typeof error.message === 'string') {
    return error.message;
  } else if (!!error && Array.isArray(error.message)) {
    return error.message.join(', ');
    // return error.message
    //     .map((message: NestErrorMessage) => decodeNestErrorMessage(message))
    //     .join(', ');
  } else {
    return 'Error';
  }
}

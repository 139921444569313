import { unreachable } from '@rpg/core/base';
import { FileType } from '../enums';
import {
  CharacterFileReference,
  FileReference,
  FolderFileReference,
  GameFileReference,
  VehicleFileReference,
} from '../models';

export function mapFileReference<T extends FileReference = FileReference>(ref: FileReference): T;
export function mapFileReference<T extends FileReference = FileReference>(
  ref: FileReference[]
): T[];
export function mapFileReference<T extends FileReference = FileReference>(
  ref: FileReference | FileReference[]
): T | T[] {
  if (Array.isArray(ref)) {
    return ref.map(r => mapFileReference(r));
  }
  let reference: FileReference;
  switch (ref.type) {
    case FileType.Character:
      reference = new CharacterFileReference(ref);
      break;
    case FileType.Folder:
      reference = new FolderFileReference(ref);
      break;
    case FileType.Game:
      reference = new GameFileReference(ref);
      break;
    case FileType.Vehicle:
      reference = new VehicleFileReference(ref);
      break;
    default:
      unreachable(ref.type, 'mapFileReference');
  }
  return reference as T;
}

import { Directive, Inject, Input, Optional } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgControl } from '@angular/forms';
import { AbstractControl } from '@ngneat/reactive-forms';

interface RpgInputRef {
  inputControl: AbstractControl;
}

@Directive({
  selector: '[rpgDisableControl]',
})
export class DisableControlDirective {
  @Input()
  set rpgDisableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl?.control?.[action]({ emitEvent: false });
    if (!this.ngControl && this.dirtyFCRef.length) {
      this.dirtyFCRef[0].inputControl?.[action]({ emitEvent: false });
    }
  }

  constructor(
    @Optional() private ngControl: NgControl,
    // This is a dirty way to access the custom RPGInput/Select/Textarea elements. They
    // all provide themselves to the DI under this value accessor, so this directive, if placed
    // on one of these elements will be able to access the componentRef. We know because we built
    // them that they all reference the form control we need at the `inputControl` key, so we assume
    // if we pick something up here and ngControl is not found (non-standard input element) that we
    // are likely using one of the custom RPG elements.
    @Inject(NG_VALUE_ACCESSOR) private dirtyFCRef: RpgInputRef[]
  ) {}
}

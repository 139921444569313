export enum LanguageOption {
  // Detect = 'detect',
  English = 'en',
  Spanish = 'es',
  French = 'fr',
  German = 'de',
  Polish = 'pl',
  PigLatin = 'pigLatin',
}

export namespace LanguageOption {
  export function translationKey(type: LanguageOption): string {
    return `enums.user.language-option.${type}`;
  }

  export function toString(type: LanguageOption): string {
    switch (type) {
      // case LanguageOption.Detect:
      //     return 'Auto-Detect';
      case LanguageOption.English:
        return 'English';
      case LanguageOption.Spanish:
        return 'Spanish';
      case LanguageOption.French:
        return 'French';
      case LanguageOption.German:
        return 'German';
      case LanguageOption.Polish:
        return 'Polish';
      default:
        return '';
    }
  }

  export const members: LanguageOption[] = [
    // LanguageOption.Detect,
    LanguageOption.English,
    LanguageOption.Spanish,
    LanguageOption.French,
    LanguageOption.German,
    LanguageOption.Polish,
  ];
}

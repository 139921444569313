import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  Renderer2,
  Inject,
  PLATFORM_ID,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[rpgClickOutside]',
})
export class RpgClickOutsideDirective implements OnInit, OnDestroy {
  private _enabled: boolean = true;
  private _listenerRemover: () => void = () => {};

  @Input('rpgClickOutsideEnabled')
  public set enabled(enabled: boolean) {
    this._enabled = coerceBooleanProperty(enabled);
    if (this._enabled) {
      this._attachListener();
    } else {
      this._removeListener();
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output('rpgClickOutside')
  public clickOutside: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}

  ngOnInit(): void {
    if (this._enabled) {
      this._attachListener();
    }
  }

  ngOnDestroy(): void {
    this._removeListener();
  }

  private _attachListener(): void {
    if (isPlatformBrowser(this._platformId)) {
      this._removeListener(); // Remove a listener if it exists
      this._listenerRemover = this._renderer.listen(document, 'click', (event: MouseEvent) => {
        if (this._didClickOutside(event.target)) {
          this.clickOutside.emit();
        }
      });
    }
  }

  private _removeListener(): void {
    if (!!this._listenerRemover) {
      this._listenerRemover();
    }
  }

  private _didClickOutside(targetElement: any): boolean {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    return !clickedInside;
  }
}

<div
  class="talent-slot__container"
  [class.talent-slot__container--mobile-disabled]="!mobileEnabled"
>
  <div class="talent-slot__center">
    <div
      class="talent-slot__talent"
      [class.talent-slot__talent--purchased]="talentData?.purchased"
      [class.talent-slot__talent--mobile-disabled]="!mobileEnabled"
      [class.talent-slot__talent--force-mobile]="forceMobile"
    >
      <div
        class="talent__header"
        [class.talent__header--mobile-disabled]="!mobileEnabled"
        [class.talent__header--force-mobile]="forceMobile"
      >
        <ng-container *ngIf="talentData">
          <mat-checkbox
            class="talent__purchased"
            [class.talent__purchased--mobile-disabled]="!mobileEnabled"
            [class.talent__purchased--force-mobile]="forceMobile"
            [checked]="talentData.purchased"
            [disabled]="!editMode"
            (change)="talentPurchasedToggle.emit($event.checked)"
          ></mat-checkbox>
          <span
            class="talent__activation"
            [class.talent__activation--mobile-disabled]="!mobileEnabled"
            [class.talent__activation--force-mobile]="forceMobile"
          >
            {{ NdsCharacterActivationType.translationKey(talentData.activationType) | transloco }}
          </span>
        </ng-container>
        <ng-container *ngIf="!talentData">
          <mat-checkbox
            class="talent__purchased"
            [class.talent__purchased--mobile-disabled]="!mobileEnabled"
            [class.talent__purchased--force-mobile]="forceMobile"
            [disabled]="true"
          ></mat-checkbox>
        </ng-container>
      </div>
      <div
        class="talent__content"
        [class.talent__content--display-mode]="!editMode"
        [class.talent__content--selected]="mobileSelected"
        [class.talent__content--mobile-disabled]="!mobileEnabled"
        [class.talent__content--force-mobile]="forceMobile"
        (click)="talentContentClick.emit()"
      >
        <div class="talent__content-edit-indicator" *ngIf="editMode">
          <span class="indicator-text" [class.indicator-text--has-text]="!!talentData">
            {{ 'phrases.Click To Edit' | transloco }}
          </span>
        </div>
        <div
          *ngIf="talentData"
          class="talent__content-text"
          [class.talent__content-text--blur-on-hover]="editMode"
        >
          <label
            class="talent__name"
            [class.talent__name--mobile-disabled]="!mobileEnabled"
            [class.talent__name--force-mobile]="forceMobile"
            >{{ talentData.name }}</label
          >
          <markdown
            class="talent__description"
            [class.talent__description--mobile-disabled]="!mobileEnabled"
            [class.talent__description--force-mobile]="forceMobile"
            [data]="talentData?.description"
          ></markdown>
        </div>
      </div>
      <div
        class="talent__footer"
        [class.talent__footer--mobile-disabled]="!mobileEnabled"
        [class.talent__footer--force-mobile]="forceMobile"
      >
        <ng-container *ngIf="talentData">
          <span
            class="talent__ranks"
            [class.talent__ranks--mobile-disabled]="!mobileEnabled"
            [class.talent__ranks--force-mobile]="forceMobile"
            *ngIf="talentData?.ranked === true"
          >
            {{ 'words.Ranked' | transloco }}
          </span>
          <span
            class="talent__cost"
            [class.talent__cost--mobile-disabled]="!mobileEnabled"
            [class.talent__cost--force-mobile]="forceMobile"
          >
            {{ talentData?.xpCost }}
            {{ 'words.XP' | transloco }}
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  class="talent-slot__container"
  [class.talent-slot__container--mobile-disabled]="!mobileEnabled"
>
  <div class="talent-slot__upper" *ngIf="canUp">
    <div
      class="talent-slot__adjacency-wrapper"
      *ngFor="let node of adjacencyTrackers; let idx = index"
    >
      <span class="talent-slot__spacer"></span>
      <span
        class="talent-slot__connector talent-slot__connector--vertical"
        [class.talent-slot__connector--force-mobile]="forceMobile"
        [class.talent-slot__connector--connected]="node?.Up"
        [class.talent-slot__connector--display-mode]="!editMode"
        [class.talent-slot__connector--mobile-disabled]="!mobileEnabled"
        (click)="toggleEdge(Edge.Up, node, idx)"
      ></span>
      <span class="talent-slot__spacer"></span>
    </div>
  </div>
  <div class="talent-slot__center">
    <span
      class="talent-slot__connector talent-slot__connector--horizontal"
      [class.talent-slot__connector--force-mobile]="forceMobile"
      [class.talent-slot__connector--hidden]="!canLeft"
      [class.talent-slot__connector--connected]="firstAdjacency?.Left"
      [class.talent-slot__connector--display-mode]="!editMode"
      [class.talent-slot__connector--mobile-disabled]="!mobileEnabled"
      (click)="canLeft ? toggleEdge(Edge.Left, firstAdjacency, 0) : null"
    ></span>
    <rpg-nds-character-talent-slot
      [talentData]="talentData"
      [mobileEnabled]="mobileEnabled"
      [mobileSelected]="mobileSelected"
      [forceMobile]="forceMobile"
      [editMode]="editMode"
      (talentContentClick)="talentContentClick.emit($event)"
      (talentPurchasedToggle)="talentPurchasedToggle.emit($event)"
    ></rpg-nds-character-talent-slot>
    <span
      class="talent-slot__connector talent-slot__connector--horizontal"
      [class.talent-slot__connector--force-mobile]="forceMobile"
      [class.talent-slot__connector--hidden]="!canRight"
      [class.talent-slot__connector--connected]="lastAdjacency?.Right"
      [class.talent-slot__connector--display-mode]="!editMode"
      [class.talent-slot__connector--mobile-disabled]="!mobileEnabled"
      (click)="
        canRight ? toggleEdge(Edge.Right, lastAdjacency, adjacencyTrackers.length - 1) : null
      "
    ></span>
    <button
      *ngIf="editMode && expandable"
      class="talent-slot__merge talent-slot__merge--right"
      [class.talent-slot__merge--force-mobile]="forceMobile"
      [class.talent-slot__merge--mobile-disabled]="!mobileEnabled"
      type="button"
      (click)="expandNode.emit()"
    >
      {{ 'words.Combine' | transloco }}
    </button>
  </div>
  <div class="talent-slot__lower" *ngIf="canDown">
    <div
      class="talent-slot__adjacency-wrapper"
      *ngFor="let node of adjacencyTrackers; let idx = index"
    >
      <span class="talent-slot__spacer"></span>
      <span
        class="talent-slot__connector talent-slot__connector--vertical"
        [class.talent-slot__connector--force-mobile]="forceMobile"
        [class.talent-slot__connector--connected]="node?.Down"
        [class.talent-slot__connector--display-mode]="!editMode"
        [class.talent-slot__connector--mobile-disabled]="!mobileEnabled"
        (click)="toggleEdge(Edge.Down, node, idx)"
      ></span>
      <span class="talent-slot__spacer"></span>
    </div>
  </div>
</div>

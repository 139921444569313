/* eslint-disable no-bitwise */
export enum Edge {
  Up = 0,
  Right,
  Down,
  Left,
}

export namespace Edge {
  export function addEdge(edge: Edge, previousValue: number): number {
    return (1 << edge) | previousValue;
  }

  export function removeEdge(edge: Edge, previousValue: number): number {
    return ~(1 << edge) & previousValue;
  }

  export function toggleEdge(edge: Edge, previousValue: number): number {
    return (1 << edge) ^ previousValue;
  }

  export function hasEdge(edge: Edge, value: number): boolean {
    return (value & (1 << edge)) > 0;
  }

  export function getValue(edges: Edge[]): number {
    let value = 0;
    edges.forEach(e => (value = Edge.addEdge(e, value)));
    return value;
  }

  export function getOpposingEdge(edge: Edge): Edge {
    switch (edge) {
      case Edge.Up:
        return Edge.Down;
      case Edge.Down:
        return Edge.Up;
      case Edge.Left:
        return Edge.Right;
      case Edge.Right:
        return Edge.Left;
      default:
        throw new Error(`Invalid Edge given: ${edge}`);
    }
  }

  export function toString(edge: Edge): string {
    switch (edge) {
      case Edge.Up:
        return 'Up';
      case Edge.Down:
        return 'Down';
      case Edge.Left:
        return 'Left';
      case Edge.Right:
        return 'Right';
      default:
        return '';
    }
  }
}

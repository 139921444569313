import { Injectable } from '@angular/core';
import { RollParams } from '@rpg/core/roll';

import { SupabaseService, toSupabaseErrorType } from './supabase.service';

@Injectable({
  providedIn: 'root',
})
export class RollSB {
  constructor(private sb: SupabaseService) {}

  __client() {
    return this.sb.client;
  }

  async getRollSeed(game_id?: string) {
    const funcRes = await this.__client()
      .rpc('roll_seed_generate', { game_id: game_id ?? null })
      .maybeSingle();
    return toSupabaseErrorType(funcRes.error, funcRes.data);
  }

  async saveRoll(roll: RollParams) {
    const funcRes = await this.__client().rpc('roll_save_new', { roll_data: roll }).maybeSingle();
    // const funcRes = await this.__client().rpc('test_data_flow', { param_data: roll });
    return toSupabaseErrorType(funcRes.error, funcRes.data);
  }

  async rollHistory(page: number = 1, limit: number = 5) {
    const userId = (await this.sb.auth.getUser())?.data.user?.id;
    if (!userId) return { error: new Error('Missing user auth'), data: null };
    const { error, data } = await this.__client()
      .from('roll')
      .select('id, description, result_text, verified, dice (*)')
      .eq('user_id', userId);
    return toSupabaseErrorType(error, data);
  }
}

import { ObjectId } from 'bson';
import { isNotNull } from './is-not-null';

type OptionalObjectId = string | ObjectId | null | undefined;

export function parseObjectId(val: OptionalObjectId): ObjectId | null;
export function parseObjectId(val: OptionalObjectId, defaultValue: ObjectId): ObjectId;
export function parseObjectId(val: OptionalObjectId, defaultValue?: ObjectId): ObjectId | null {
  try {
    if (!!val) {
      if (val instanceof ObjectId) {
        return val;
      }
      if (ObjectId.isValid(val)) {
        return new ObjectId(val);
      }
    }
    if (!!defaultValue) {
      return defaultValue;
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function parseObjectIdArray(val: Array<OptionalObjectId> | undefined): Array<ObjectId>;
export function parseObjectIdArray(
  val: Array<OptionalObjectId> | undefined,
  filterNullValues: false
): Array<ObjectId | null>;
export function parseObjectIdArray(
  val: Array<OptionalObjectId> | undefined,
  filterNullValues: boolean = true
): Array<ObjectId | null> {
  if (!val) {
    return [];
  }
  const mappedValues = val.map(id => parseObjectId(id));
  if (filterNullValues) {
    return mappedValues.filter(isNotNull);
  }
  return mappedValues;
}

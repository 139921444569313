import { createId, RpgClassValidators } from '@rpg/core/base';
import { NdsVehicleModifierFactory } from '../functions/nds-vehicle-modifier-factory';
import { NdsVehicleModifierValidatorFactory } from '../functions/nds-vehicle-modifier-validator-factory';
import { NdsVehicleModifierData } from './modifiers';

export class NdsVehicleAttachmentModificationData {
  public id: string = '';
  public name: string = '';
  public description: string = '';
  public enabled: boolean = false;
  public modifiers: NdsVehicleModifierData[] = [];

  constructor(params?: Partial<NdsVehicleAttachmentModificationData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.description = params.description ?? this.description;
      this.enabled = params.enabled ?? this.enabled;
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(m => NdsVehicleModifierFactory(m))
        : this.modifiers;
    }

    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsVehicleAttachmentModificationData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id));
    errors.push(...RpgClassValidators.string(input.name));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
      })
    );
    errors.push(...RpgClassValidators.boolean(input.enabled));
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleModifierValidatorFactory(next)],
        []
      )
    );
    return errors;
  }
}

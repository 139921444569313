import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_INTERCEPTOR,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoInterceptor,
  TranslocoLoader,
  TranslocoModule,
} from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { CookieService } from '@rpg/ngx/core';
import { CookieKey } from '@rpg/core/base';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<Translation> {
    if (lang.indexOf('-fallback') === -1) {
      console.warn('Fetching language', lang);
      if (!environment.production) return of({});
      return this.http.get<Translation>(
        `https://resources.unboundlegends.com/internationalization/app/${lang}.json`
      );
    } else {
      console.warn('Getting default language', lang);
      return this.http.get<Translation>(
        `${window?.location?.origin ?? environment.weburl}/assets/i18n/default.json`
      );
    }
  }
}

@Injectable({ providedIn: 'root' })
export class SeeUnTranslatedItemsInterceptor implements TranslocoInterceptor {
  constructor(private _cookie: CookieService) {}

  preSaveTranslationKey(key: string, value: string, lang: string): string {
    return value;
  }
  preSaveTranslation(translation: Translation, lang: string): Translation {
    if (environment.production) return translation;
    const translationStatus = (this._cookie.get(CookieKey.TranslationStatus) ?? 'off') === 'on';
    console.warn('preSaveTranslation', lang, translationStatus);
    Object.keys(translation).forEach(key => {
      if (typeof translation[key] === 'string') {
        if (translationStatus) {
          translation[key] = `✅ ${translation[key]}`;
        } else {
          translation[key] = translation[key].replace(/✅ /g, '');
        }
      }
    });
    console.log(translation);
    return translation;
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en-fallback', 'en', 'es', 'fr', 'de', 'pl'],
        fallbackLang: 'en-fallback',
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          // will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_INTERCEPTOR, useClass: SeeUnTranslatedItemsInterceptor },
  ],
})
export class TranslocoRootModule {}

import { parseDate, parseEnum, parseNumber } from '@rpg/core/base';
import { PatreonTier } from '../enums';

export class PatreonLink {
  public id: string = '';
  public transactionId: string = '';
  public tier: PatreonTier | null = null;
  public adminOverrideTier: PatreonTier | null = null;
  public pledgeAmountCents: number | null = null;
  public pledgeStartDate: Date | null = null;
  public expirationDate: Date | null = null;

  constructor(params?: Partial<PatreonLink>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.transactionId = params.transactionId ?? this.transactionId;
      this.tier = parseEnum(PatreonTier, params.tier);
      this.adminOverrideTier = parseEnum(PatreonTier, params.adminOverrideTier);
      this.pledgeAmountCents = parseNumber(params.pledgeAmountCents);
      this.pledgeStartDate = parseDate(params.pledgeStartDate);
      this.expirationDate = parseDate(params.expirationDate);
    }
  }

  public get currentTier(): PatreonTier {
    return this.adminOverrideTier ?? this.tier ?? PatreonTier.None;
  }
}

import { GameTheme, parseEnum, parseObjectId, RulesSystem } from '@rpg/core/base';
import { BaseCharacter, CharacterType } from '@rpg/core/character';
import { ObjectId } from 'bson';
import { FileType } from '../enums';
import { FileReference } from './file-reference';

export class CharacterFileReference extends FileReference {
  public type: FileType = FileType.Character;
  public rulesSystem: RulesSystem = RulesSystem.Generic;
  public gameTheme: GameTheme = GameTheme.Generic;
  public characterType: CharacterType = CharacterType.Player;
  public referenceDocId!: ObjectId;

  constructor(params?: Partial<CharacterFileReference>) {
    super(params);
    if (!!params) {
      this.type = FileType.Character;
      this.rulesSystem = parseEnum(RulesSystem, params.rulesSystem, this.rulesSystem);
      this.gameTheme = parseEnum(GameTheme, params.gameTheme, this.gameTheme);
      this.characterType = parseEnum(CharacterType, params.characterType, this.characterType);
      this.referenceDocId = parseObjectId(params.referenceDocId, this.referenceDocId);
    }
  }

  static fromCharacter(
    character: BaseCharacter,
    existingFileRef?: Partial<CharacterFileReference>
  ): CharacterFileReference {
    return new CharacterFileReference({
      ...(existingFileRef ?? {}),
      userId: character.userId,
      name: character.name,
      imageUrl: character.image,
      rulesSystem: character.rulesSystem,
      gameTheme: character.configuration.gameTheme,
      characterType: character.characterType,
      referenceDocId: character._id,
    });
  }
}

import { createId, parseBoolean, parseEnum, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterActivationType } from '../enums';
import { NdsCharacterModifierFactory } from '../functions/nds-character-modifier-factory';
import { NdsCharacterModifierValidatorFactory } from '../functions/nds-character-modifier-validator-factory';
import { NdsCharacterModifierData } from './modifiers';

export class NdsCharacterTalentData {
  public id: string = '';
  public name: string = '';
  public purchased: boolean = false;
  public activationType: NdsCharacterActivationType = NdsCharacterActivationType.Passive;
  public description: string = '';
  public modifiers: NdsCharacterModifierData[] = [];
  public ranked: boolean = false;
  public ranks: number = 0;
  public isForceTalent: boolean = false;
  public isConflictTalent: boolean = false;
  public xpCost: number = 0;

  constructor(params?: Partial<NdsCharacterTalentData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.purchased = parseBoolean(params.purchased, this.purchased);
      this.activationType = parseEnum(
        NdsCharacterActivationType,
        params.activationType,
        this.activationType
      );
      this.description = params.description ?? this.description;
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(m => NdsCharacterModifierFactory(m)).filter(m => !!m.name)
        : this.modifiers;
      this.ranked = parseBoolean(params.ranked, this.ranked);
      this.ranks = parseNumber(params.ranks, this.ranks);
      this.isForceTalent = parseBoolean(params.isForceTalent, this.isForceTalent);
      this.isConflictTalent = parseBoolean(params.isConflictTalent, this.isConflictTalent);
      this.xpCost = parseNumber(params.xpCost, this.xpCost);
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsCharacterTalentData): string[] {
    const errors: string[] = [];
    const name = `Talent ${input.name ? `(${input.name}) ` : ''}`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(...RpgClassValidators.boolean(input.purchased, { fieldName: `${name} Purchased` }));
    errors.push(
      ...RpgClassValidators.enum(input.activationType, NdsCharacterActivationType, {
        enumName: 'Talent Activation Type',
        fieldName: `${name} Talent Activation Type`,
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterModifierValidatorFactory(next, name)],
        []
      )
    );
    errors.push(
      ...RpgClassValidators.boolean(input.ranked, { fieldName: `${name} Ranked Toggle` })
    );
    errors.push(...RpgClassValidators.number(input.ranks, { fieldName: `${name} Ranks` }));
    errors.push(
      ...RpgClassValidators.boolean(input.isForceTalent, { fieldName: `${name} Force Toggle` })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.isConflictTalent, {
        fieldName: `${name} Conflict Toggle`,
      })
    );
    errors.push(...RpgClassValidators.number(input.xpCost, { fieldName: `${name} XP Cost` }));
    return errors;
  }
}

import { parseDateArray, parseEnum, parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { addMilliseconds, differenceInMilliseconds, formatDistance } from 'date-fns';
import { GameTokenSource, GameTokenType } from '../enums';
import { GAME_TABLE_TOKEN_COOL_DOWN_DURATION, GAME_TABLE_TOKEN_MAX_MOVEMENTS } from './table-token';

export class GameTableTokenRef {
  public _id: ObjectId = new ObjectId();
  public userId: ObjectId | null = null;
  public type: GameTokenType = GameTokenType.Champion;
  public source: GameTokenSource = GameTokenSource.Admin;
  public movedTimestamps: Date[] = [];

  constructor(params?: Partial<GameTableTokenRef>) {
    if (!!params) {
      this._id = parseObjectId(params._id, this._id);
      this.userId = parseObjectId(params.userId);
      this.type = parseEnum(GameTokenType, params.type, this.type);
      this.source = parseEnum(GameTokenSource, params.source, this.source);
      this.movedTimestamps = Array.isArray(params.movedTimestamps)
        ? parseDateArray(params.movedTimestamps, true)
        : this.movedTimestamps;
    }
  }

  public get canMove(): boolean {
    if (this.movedTimestamps.length < GAME_TABLE_TOKEN_MAX_MOVEMENTS) {
      return true;
    }
    if (
      differenceInMilliseconds(new Date(), this.movedTimestamps[0]) >
      GAME_TABLE_TOKEN_COOL_DOWN_DURATION
    ) {
      return true;
    }
    return false;
  }

  public get timeUntilMoveAvailable(): string {
    const time =
      GAME_TABLE_TOKEN_COOL_DOWN_DURATION -
      differenceInMilliseconds(new Date(), this.movedTimestamps[0]);
    const dateAvailable = addMilliseconds(new Date(), time);
    return formatDistance(new Date(), dateAvailable);
  }
}

import { createId, parseEnum, RpgClassValidators } from '@rpg/core/base';
import { NdsVehicleModifierType } from '../../enums';

export class NdsVehicleModifierData {
  public id: string = '';
  public type: NdsVehicleModifierType = NdsVehicleModifierType.Narrative;
  public name: string = '';
  public description: string = '';

  constructor(params?: Partial<NdsVehicleModifierData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.type = parseEnum(NdsVehicleModifierType, params.type, this.type);
      this.name = params.name ?? this.name;
      this.description = params.description ?? this.description;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsVehicleModifierData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id));
    errors.push(
      ...RpgClassValidators.enum(input.type, NdsVehicleModifierType, {
        enumName: 'Modifier Type',
      })
    );
    errors.push(...RpgClassValidators.string(input.name));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
      })
    );
    return errors;
  }
}

import { MongoDoc, parseEnum, parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { OggDudeCustomImportType } from '../enums';

export class OggDudeCustomData extends MongoDoc {
  public userId!: ObjectId;
  public type: OggDudeCustomImportType = OggDudeCustomImportType.AllData;
  public fileName: string = '';
  public importedData: Record<string, any> = {};

  constructor(params: Partial<OggDudeCustomData>) {
    super(params);
    if (!!params) {
      this.userId = parseObjectId(params.userId, this.userId);
      this.type = parseEnum(OggDudeCustomImportType, params.type, this.type);
      this.fileName = params.fileName ?? this.fileName;
      this.importedData = params.importedData ?? this.importedData;
    }
  }
}

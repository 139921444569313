import { parseEnum, parseNumber, parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { MessageActionRefType } from '../enums';

export class MessageActionRef {
  public type: MessageActionRefType = MessageActionRefType.General;
  public docId: ObjectId = new ObjectId();
  public docData: {
    characterId: ObjectId | null;
    characterName: string;
    characterImage: string;
    [key: string]: any;
  } = {
    characterId: null,
    characterName: '',
    characterImage: '',
  };
  public count: number = 0;
  public amount: number = 0;
  public targetType: any;

  constructor(params?: Partial<MessageActionRef>) {
    if (!!params) {
      this.type = parseEnum(MessageActionRefType, params.type, this.type);
      this.docId = parseObjectId(params.docId, this.docId);
      if (!!params.docData) {
        this.docData = {
          ...params.docData,
          characterId: parseObjectId(params.docData.characterId),
          characterName: params.docData.characterName ?? '',
          characterImage: params.docData.characterImage ?? '',
        };
      }
      this.count = parseNumber(params.count, this.count);
      this.amount = parseNumber(params.amount, this.amount);
      this.targetType = params.targetType ?? this.targetType;
    }
  }
}

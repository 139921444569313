import { PatreonReward } from './patreon-reward';

export enum PatreonTier {
  None = '[patreon tier] none',
  TheFan = '[patreon tier] the fan',
  TheFanId = '3708704',
  TheDeuce = '[patreon tier] the deuce',
  TheDeuceId = '3780124',
  HighFiver = '[patreon tier] high fiver',
  HighFiverId = '3708712',
  Scout = '[patreon tier] scout',
  ScoutId = '6578155',
  Explorer = '[patreon tier] explorer',
  ExplorerId = '6579211',
  Adventurer = '[patreon tier] adventurer',
  AdventurerId = '6672294',
  Champion = '[patreon tier] champion',
  ChampionId = '6672357',
}

export namespace PatreonTier {
  export function fromId(id: string): PatreonTier | null {
    switch (id) {
      // IDs pulled from Patreon
      case PatreonTier.TheFanId:
        return PatreonTier.TheFan;
      case PatreonTier.TheDeuceId:
        return PatreonTier.TheDeuce;
      case PatreonTier.HighFiverId:
        return PatreonTier.HighFiver;
      case PatreonTier.ScoutId:
        return PatreonTier.Scout;
      case PatreonTier.ExplorerId:
        return PatreonTier.Explorer;
      case PatreonTier.AdventurerId:
        return PatreonTier.Adventurer;
      case PatreonTier.ChampionId:
        return PatreonTier.Champion;
      default:
        return null;
    }
  }

  export function toString(type: PatreonTier): string {
    switch (type) {
      case PatreonTier.None:
        return 'None';
      case PatreonTier.TheFan:
        return 'The Fan';
      case PatreonTier.TheDeuce:
        return 'The Deuce';
      case PatreonTier.HighFiver:
        return 'High Fiver';
      case PatreonTier.Scout:
        return 'Scout';
      case PatreonTier.Explorer:
        return 'Explorer';
      case PatreonTier.Adventurer:
        return 'Adventurer';
      case PatreonTier.Champion:
        return 'Champion';
      default:
        return '';
    }
  }

  export function translationKey(type: PatreonTier): string {
    return `enums.patreon.patreon-tier.${type}`;
  }

  export function getAvailableTiers(tier: PatreonTier): PatreonTier[] {
    const availableTiers: PatreonTier[] = [];
    switch (tier) {
      case PatreonTier.HighFiver:
        return [PatreonTier.HighFiver, PatreonTier.TheDeuce, PatreonTier.TheFan];
      case PatreonTier.TheDeuce:
        return [PatreonTier.TheDeuce, PatreonTier.TheFan];
      case PatreonTier.TheFan:
        return [PatreonTier.TheFan];
      default:
    }
    return availableTiers;
  }

  export function isAtLeast(minimumTier: PatreonTier, tierToCheck: PatreonTier): boolean {
    const availableTiers = PatreonTier.getAvailableTiers(minimumTier);
    return availableTiers.includes(tierToCheck);
  }

  export function hasReward(tier: PatreonTier, reward: PatreonReward): boolean {
    switch (tier) {
      case PatreonTier.Scout:
        return PatreonTier.scoutRewards.includes(reward);
      case PatreonTier.Explorer:
        return PatreonTier.explorerRewards.includes(reward);
      case PatreonTier.Adventurer:
        return PatreonTier.adventurerRewards.includes(reward);
      case PatreonTier.Champion:
        return PatreonTier.championRewards.includes(reward);
      // Old Tier support
      case PatreonTier.TheFan:
        return PatreonTier.theFanRewards.includes(reward);
      case PatreonTier.TheDeuce:
        return PatreonTier.theDeuceRewards.includes(reward);
      case PatreonTier.HighFiver:
        return PatreonTier.highFiverRewards.includes(reward);
      default:
        return false;
    }
  }

  export const theFanRewards: PatreonReward[] = [
    PatreonReward.ImportLimitLevel1,
    PatreonReward.NPCImports,
    PatreonReward.OggdudeCustomData,
    PatreonReward.HonoraryTitle,
    PatreonReward.SneakPeekAccess,
    PatreonReward.FeatureImprovementVotingRights,
  ];

  export const theDeuceRewards: PatreonReward[] = [
    PatreonReward.ImportLimitLevel2,
    PatreonReward.NPCImports,
    PatreonReward.OggdudeCustomData,
    PatreonReward.HonoraryTitle,
    PatreonReward.SneakPeekAccess,
    PatreonReward.FeatureImprovementVotingRights,
    PatreonReward.GTMessagesImageUpload,
  ];

  export const highFiverRewards: PatreonReward[] = [
    PatreonReward.UnlimitedImports,
    PatreonReward.NPCImports,
    PatreonReward.OggdudeCustomData,
    PatreonReward.HonoraryTitle,
    PatreonReward.SneakPeekAccess,
    PatreonReward.FeatureImprovementVotingRights,
    PatreonReward.GTMessagesImageUpload,
  ];

  export const scoutRewards: PatreonReward[] = [
    PatreonReward.ImportLimitLevel1,
    PatreonReward.NPCImports,
    PatreonReward.OggdudeCustomData,
    PatreonReward.HonoraryTitle,
    PatreonReward.PrintableCharacterSheets,
    PatreonReward.GTMessagesImageUpload,
    PatreonReward.ImageUpload,
  ];

  export const explorerRewards: PatreonReward[] = [
    PatreonReward.ImportLimitLevel2,
    PatreonReward.NPCImports,
    PatreonReward.OggdudeCustomData,
    PatreonReward.HonoraryTitle,
    PatreonReward.PrintableCharacterSheets,
    PatreonReward.SneakPeekAccess,
    PatreonReward.SneakPeekCommunityAccess,
    PatreonReward.FeatureImprovementVotingRights,
    PatreonReward.GTMessagesImageUpload,
    PatreonReward.ExplorerGameTableToken,
    PatreonReward.ImageUpload,
    PatreonReward.AIRollSuggestions,
  ];

  export const adventurerRewards: PatreonReward[] = [
    PatreonReward.ImportLimitLevel3,
    PatreonReward.NPCImports,
    PatreonReward.OggdudeCustomData,
    PatreonReward.HonoraryTitle,
    PatreonReward.PrintableCharacterSheets,
    PatreonReward.SneakPeekAccess,
    PatreonReward.SneakPeekCommunityAccess,
    PatreonReward.FeatureImprovementVotingRights,
    PatreonReward.GTMessagesImageUpload,
    PatreonReward.AdventurerGameTableToken,
    PatreonReward.AdventurerSupporterList,
    PatreonReward.Folders,
    PatreonReward.ImageUpload,
    PatreonReward.AIRollSuggestions,
    PatreonReward.AIRulesLawyer
  ];

  export const championRewards: PatreonReward[] = [
    PatreonReward.UnlimitedImports,
    PatreonReward.NPCImports,
    PatreonReward.OggdudeCustomData,
    PatreonReward.HonoraryTitle,
    PatreonReward.PrintableCharacterSheets,
    PatreonReward.SneakPeekAccess,
    PatreonReward.SneakPeekCommunityAccess,
    PatreonReward.FeatureImprovementVotingRights,
    PatreonReward.GTMessagesImageUpload,
    PatreonReward.ChampionGameTableToken,
    PatreonReward.ChampionSupporterList,
    PatreonReward.FirstLookAccess,
    PatreonReward.FirstLookCommunityAccess,
    PatreonReward.Folders,
    PatreonReward.ImageUpload,
    PatreonReward.AIRollSuggestions,
    PatreonReward.AIRulesLawyer
  ];

  export const members: PatreonTier[] = [
    PatreonTier.TheFan,
    PatreonTier.TheDeuce,
    PatreonTier.HighFiver,
    PatreonTier.Scout,
    PatreonTier.Explorer,
    PatreonTier.Adventurer,
    PatreonTier.Champion,
  ];

  export const memberIds: PatreonTier[] = [
    PatreonTier.TheFanId,
    PatreonTier.TheDeuceId,
    PatreonTier.HighFiverId,
    PatreonTier.ScoutId,
    PatreonTier.ExplorerId,
    PatreonTier.AdventurerId,
    PatreonTier.ChampionId,
  ];
}

import { Directive, AfterViewChecked, ElementRef, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[rpgFormFieldError]',
})
export class FormFieldErrorDirective implements AfterViewChecked, OnInit {
  @Input('rpgFormFieldError') public control!: AbstractControl;
  @Input('rpgFormFieldErrorMessages')
  public messages: { [key: string]: string } = {};

  constructor(private _el: ElementRef) {}

  ngOnInit(): void {
    if (!this.control) {
      throw new Error('Control must be defined for the form field error component');
    }
  }

  ngAfterViewChecked(): void {
    const elem: HTMLElement = this._el.nativeElement;
    let content: string = '';
    if (
      !!this.control &&
      typeof this.control.errors !== 'undefined' &&
      this.control.errors !== null
    ) {
      for (const errorKey in this.control.errors) {
        if (!!this.messages[errorKey]) {
          content = this.messages[errorKey];
        } else {
          switch (errorKey) {
            case 'required':
              content = 'Required';
              break;
            case 'email':
              content = 'Not a valid email';
              break;
            case 'equalTo':
              content = `Fields don't match`;
              break;
            case 'minlength':
              content = 'Length is too short';
              break;
            case 'maxlength':
              content = 'Length is too long';
              break;
            case 'fieldUnique':
              content = 'Field value is not unique';
              break;
            case 'notSaved':
              content = 'Save Failed';
              break;
            case 'arrayWithValue':
              content = 'At least 1 value is required';
              break;
            default:
              content = 'Invalid';
          }
        }
      }
    }
    elem.textContent = content;
  }
}

import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterObligationEntryData } from '@rpg/core/character';

export function ndsCharacterObligationEntryToFormGroup(
  entry: NdsCharacterObligationEntryData
): FormGroup<NdsCharacterObligationEntryData> {
  return new FormGroup<NdsCharacterObligationEntryData>({
    amount: new FormControl(entry.amount),
    description: new FormControl(entry.description),
    id: new FormControl(entry.id),
    name: new FormControl(entry.name),
    triggered: new FormControl(entry.triggered),
  });
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timer, forkJoin, Observable, tap, take } from 'rxjs';
import { HttpService } from '@rpg/ngx/http';
import { CloneType } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { getErrorMessage, OverlayResponse, OverlayResponseType } from '@rpg/ngx/core';

interface HttpReq {
  context: HttpService;
  request: (id: ObjectId) => Observable<ObjectId>;
}

export interface CloneOverlayComponentData {
  completeWord?: string;
  itemId: ObjectId;
  itemName: string;
  itemType: CloneType;
  httpRequest: HttpReq;
}

@Component({
  selector: 'rpg-clone-overlay',
  templateUrl: './clone-overlay.component.html',
  styleUrls: ['./clone-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloneOverlayComponent implements OnInit {
  private _newCharId!: ObjectId;

  public cloneInProgress: boolean = false;
  public cloneComplete: boolean = false;
  public showCompleteMessage: boolean = false;
  public name!: string;
  public completedSlot: number = 0;
  public messages: [string, string, string] = ['', '', ''];
  public errorMessage: string = '';
  public completeWord: string = 'View';

  constructor(
    private _dialog: MatDialogRef<CloneOverlayComponent, OverlayResponse<ObjectId>>,
    @Inject(MAT_DIALOG_DATA)
    private _data: CloneOverlayComponentData,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.name = this._data.itemName;
    this.completeWord = this._data.completeWord ?? this.completeWord;
    const options = [...CloneType.getAvailableMessages(this._data.itemType)];
    this.messages[0] = options.splice(Math.floor(Math.random() * options.length), 1)[0];
    this.messages[1] = options.splice(Math.floor(Math.random() * options.length), 1)[0];
    // eslint-disable-next-line no-magic-numbers
    this.messages[2] = options.splice(Math.floor(Math.random() * options.length), 1)[0];
  }

  public cancel(): void {
    this._dialog.close(new OverlayResponse(OverlayResponseType.Cancel));
  }

  public startClone(): void {
    this.cloneInProgress = true;
    const animationTimer = 600;
    const slots = 8;
    forkJoin([
      timer(animationTimer, animationTimer).pipe(
        tap(slot => {
          this.completedSlot = slot + 1;
          this._cd.detectChanges();
        }),
        take(slots)
      ),
      this._data.httpRequest.request.call(this._data.httpRequest.context, this._data.itemId),
    ]).subscribe(
      ([t, newCharId]: [number, ObjectId]) => {
        this._newCharId = newCharId;
        this.cloneInProgress = false;
        this.cloneComplete = true;
        this._cd.detectChanges();
        const messageDelay = 100;
        setTimeout(() => {
          this.showCompleteMessage = true;
          this._cd.detectChanges();
        }, messageDelay);
      },
      error => {
        console.error(error);
        this.errorMessage = getErrorMessage(error);
        this.cloneInProgress = false;
        this._cd.detectChanges();
      }
    );
  }

  public complete(): void {
    this._dialog.close(new OverlayResponse(OverlayResponseType.Confirm, this._newCharId));
  }
}

import { RulesSystem } from '@rpg/core/base';
import { BaseCharacter } from '../models';
import { NdsCharacter } from '../rules-nds';

export function mapCharacterToClass<T extends BaseCharacter>(
  data: BaseCharacter,
  stripPrivateData: boolean = false
): T {
  let char: BaseCharacter;
  switch (data.rulesSystem) {
    case RulesSystem.NarrativeDiceSystem:
      char = new NdsCharacter(data as NdsCharacter);
      break;
    default:
      char = data;
      break;
  }
  if (stripPrivateData) {
    char.privateNotes = '';
  }
  return char as T;
}

import {
  NdsCharacterAttribute,
  NdsCharacterCharacteristic,
  NdsCharacterDetail,
  NdsCharacterType,
} from '../enums';

import { BaseCharacter } from '../../models';
import { CharacterType } from '../../enums';
import { NdsCharacterArmorData } from './nds-character-armor-data';
import { NdsCharacterAttributeData } from './nds-character-attribute-data';
import { NdsCharacterCharacteristicData } from './nds-character-characteristic-data';
import { NdsCharacterConfigurationData } from './nds-character-configuration-data';
import { NdsCharacterCritData } from './nds-character-crit-data';
import { NdsCharacterDetailData } from './nds-character-detail-data';
import { NdsCharacterDutyData } from './nds-character-duty-data';
import { NdsCharacterEquipmentData } from './nds-character-equipment-data';
import { NdsCharacterFavorData } from './nds-character-favor-data';
import { NdsCharacterForceConfigData } from './nds-character-force-config-data';
import { NdsCharacterHeroicAbilityData } from './nds-character-heroic-ability-data';
import { NdsCharacterModifierData } from './modifiers';
import { NdsCharacterModifierFactory } from '../functions/nds-character-modifier-factory';
import { NdsCharacterModifierValidatorFactory } from '../functions/nds-character-modifier-validator-factory';
import { NdsCharacterMoralityData } from './nds-character-morality-data';
import { NdsCharacterObligationData } from './nds-character-obligation-data';
import { NdsCharacterSkillData } from './nds-character-skill-data';
import { NdsCharacterTalentConfigData } from './nds-character-talent-config-data';
import { NdsCharacterWeaponData } from './nds-character-weapon-data';

export class NdsCharacter extends BaseCharacter {
  public armor: NdsCharacterArmorData[] = [];
  public attributes: NdsCharacterAttributeData[] = [];
  public characteristics: NdsCharacterCharacteristicData[] = [];
  public configuration: NdsCharacterConfigurationData = new NdsCharacterConfigurationData();
  public crits: NdsCharacterCritData[] = [];
  public details: NdsCharacterDetailData[] = [];
  public duty: NdsCharacterDutyData = new NdsCharacterDutyData();
  public equipment: NdsCharacterEquipmentData[] = [];
  public favors: NdsCharacterFavorData[] = [];
  public forcePowers: NdsCharacterForceConfigData = new NdsCharacterForceConfigData();
  public heroicAbilities: NdsCharacterHeroicAbilityData[] = [];
  public modifiers: NdsCharacterModifierData[] = [];
  public morality: NdsCharacterMoralityData = new NdsCharacterMoralityData();
  public obligation: NdsCharacterObligationData = new NdsCharacterObligationData();
  public skills: NdsCharacterSkillData[] = [];
  public talents: NdsCharacterTalentConfigData = new NdsCharacterTalentConfigData();
  public weapons: NdsCharacterWeaponData[] = [];

  constructor(params?: Partial<NdsCharacter>) {
    super(params);
    if (!!params) {
      this.armor = Array.isArray(params.armor)
        ? params.armor.map(a => new NdsCharacterArmorData(a))
        : this.armor;
      this.attributes = Array.isArray(params.attributes)
        ? params.attributes.map(a => new NdsCharacterAttributeData(a))
        : this.attributes;
      this.characteristics = Array.isArray(params.characteristics)
        ? params.characteristics
            .map(a => new NdsCharacterCharacteristicData(a))
            .sort((c1, c2) => {
              const order1 = NdsCharacterCharacteristic.order[c1.type];
              const order2 = NdsCharacterCharacteristic.order[c2.type];
              if (order1 > order2) {
                return 1;
              } else if (order1 < order2) {
                return -1;
              }
              return 0;
            })
        : this.characteristics;
      this.configuration = !!params.configuration
        ? new NdsCharacterConfigurationData(params.configuration)
        : this.configuration;
      this.crits = Array.isArray(params.crits)
        ? params.crits.map(a => new NdsCharacterCritData(a))
        : this.crits;
      this.details = Array.isArray(params.details)
        ? params.details.map(a => new NdsCharacterDetailData(a))
        : this.details;
      this.duty = !!params.duty ? new NdsCharacterDutyData(params.duty) : this.duty;
      this.equipment = Array.isArray(params.equipment)
        ? params.equipment.map(a => new NdsCharacterEquipmentData(a))
        : this.equipment;
      this.favors = Array.isArray(params.favors)
        ? params.favors.map(a => new NdsCharacterFavorData(a))
        : this.favors;
      this.forcePowers = !!params.forcePowers
        ? new NdsCharacterForceConfigData(params.forcePowers)
        : this.forcePowers;
      this.heroicAbilities = Array.isArray(params.heroicAbilities)
        ? params.heroicAbilities.map(a => new NdsCharacterHeroicAbilityData(a))
        : this.heroicAbilities;
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(a => NdsCharacterModifierFactory(a))
        : this.modifiers;
      this.morality = !!params.morality
        ? new NdsCharacterMoralityData(params.morality)
        : this.morality;
      this.obligation = !!params.obligation
        ? new NdsCharacterObligationData(params.obligation)
        : this.obligation;
      this.skills = Array.isArray(params.skills)
        ? params.skills.map(a => new NdsCharacterSkillData(a))
        : this.skills;
      this.talents = !!params.talents
        ? new NdsCharacterTalentConfigData(params.talents)
        : this.talents;
      this.weapons = Array.isArray(params.weapons)
        ? params.weapons.map(a => new NdsCharacterWeaponData(a))
        : this.weapons;
    }
    // Make any adjustments to character data if needed
    // We should force character type based on the ndsCharacterType
    if (this.configuration.ndsCharacterType === NdsCharacterType.Player) {
      this.characterType = CharacterType.Player;
    } else {
      this.characterType = CharacterType.NPC;
    }

    /** If we are not a minion, CharacterCount must = 1 */
    if (this.configuration.ndsCharacterType !== NdsCharacterType.Minion) {
      const countAttr = this.attributes.find(
        attr => attr.type === NdsCharacterAttribute.CharacterCount
      );
      if (countAttr) {
        countAttr.value = 1;
      }
    }
  }

  public getArmor(id: string): NdsCharacterArmorData | null {
    return this.armor.find(a => a.id === id) ?? null;
  }

  public getAttribute(type: NdsCharacterAttribute): NdsCharacterAttributeData | null {
    return this.attributes.find(a => a.type === type) ?? null;
  }

  public getCharacteristic(
    type: NdsCharacterCharacteristic
  ): NdsCharacterCharacteristicData | null {
    return this.characteristics.find(c => c.type === type) ?? null;
  }

  public getCrit(id: string): NdsCharacterCritData | null;
  public getCrit(severity: number): NdsCharacterCritData | null;
  public getCrit(idOrSeverity: string | number): NdsCharacterCritData | null {
    if (typeof idOrSeverity === 'string') {
      return this.crits.find(c => c.id === idOrSeverity) ?? null;
    } else if (typeof idOrSeverity === 'number') {
      return this.crits.find(c => c.severity === idOrSeverity) ?? null;
    }
    return null;
  }

  public getDetail(type: NdsCharacterDetail): NdsCharacterDetailData | null {
    return this.details.find(d => d.type === type) ?? null;
  }

  public getEquipment(id: string): NdsCharacterEquipmentData | null {
    return this.equipment.find(e => e.id === id) ?? null;
  }

  public getFavor(id: string): NdsCharacterFavorData | null {
    return this.favors.find(f => f.id === id) ?? null;
  }

  public getHeroicAbility(id: string): NdsCharacterHeroicAbilityData | null {
    return this.heroicAbilities.find(h => h.id === id) ?? null;
  }

  public getModifier(id: string): NdsCharacterModifierData | null {
    return this.modifiers.find(m => m.id === id) ?? null;
  }

  public getSkill(id: string): NdsCharacterSkillData | null {
    return this.skills.find(s => s.id === id) ?? null;
  }

  public getWeapon(id: string): NdsCharacterWeaponData | null {
    return this.weapons.find(w => w.id === id) ?? null;
  }

  public static validate(input: NdsCharacter): string[] {
    const errors: string[] = BaseCharacter.validate(input);
    errors.push(
      ...input.armor.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterArmorData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.attributes.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterAttributeData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.characteristics.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterCharacteristicData.validate(next)],
        []
      )
    );
    errors.push(...NdsCharacterConfigurationData.validate(input.configuration));
    errors.push(
      ...input.crits.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterCritData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.details.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterDetailData.validate(next)],
        []
      )
    );
    errors.push(...NdsCharacterDutyData.validate(input.duty));
    errors.push(
      ...input.equipment.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterEquipmentData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.favors.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterFavorData.validate(next)],
        []
      )
    );
    errors.push(...NdsCharacterForceConfigData.validate(input.forcePowers));
    errors.push(
      ...input.heroicAbilities.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterHeroicAbilityData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterModifierValidatorFactory(next)],
        []
      )
    );
    errors.push(...NdsCharacterMoralityData.validate(input.morality));
    errors.push(...NdsCharacterObligationData.validate(input.obligation));
    errors.push(
      ...input.skills.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterSkillData.validate(next)],
        []
      )
    );
    errors.push(...NdsCharacterTalentConfigData.validate(input.talents));
    errors.push(
      ...input.weapons.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterWeaponData.validate(next)],
        []
      )
    );
    return errors.filter(e => typeof e === 'string');
  }
}

import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { BaseCharacter } from '@rpg/core/character';
import { mongoDocToFormGroup } from '../mongo-doc-to-form-group';
import { baseContentConfigurationToFormGroup } from '../base';
import { characterOptionsToFormGroup } from './character-options-to-form-group';

export function baseCharacterToFormGroup(char: BaseCharacter): FormGroup<BaseCharacter> {
  return new FormGroup<BaseCharacter>({
    ...mongoDocToFormGroup(char).controls,
    name: new FormControl(char.name),
    lookupName: new FormControl(char.lookupName),
    image: new FormControl(char.image),
    notes: new FormControl(char.notes),
    privateNotes: new FormControl(char.privateNotes),
    rulesSystem: new FormControl(char.rulesSystem),
    characterType: new FormControl(char.characterType),
    configuration: baseContentConfigurationToFormGroup(char.configuration),
    userId: new FormControl(char.userId),
    userFavorite: new FormControl(char.userFavorite),
    clonedCount: new FormControl(char.clonedCount),
    clonedFromCharacterId: new FormControl(char.clonedFromCharacterId),
    importData: new FormControl(char.importData),
    options: characterOptionsToFormGroup(char.options),
    tempClone: new FormControl(char.tempClone),
  });
}

export enum NdsVehicleModifierType {
  Action = '[nds vehicle modifier type] action',
  Attribute = '[nds vehicle modifier type] attribute',
  Characteristic = '[nds vehicle modifier type] characteristic',
  Narrative = '[nds vehicle modifier type] narrative',
  Weapon = '[nds vehicle modifier type] weapon',
}

export namespace NdsVehicleModifierType {
  export function translationKey(type: NdsVehicleModifierType): string {
    return `enums.vehicle.nds.modifier-type.${type}`;
  }

  export const members: NdsVehicleModifierType[] = [
    NdsVehicleModifierType.Action,
    NdsVehicleModifierType.Attribute,
    NdsVehicleModifierType.Characteristic,
    NdsVehicleModifierType.Narrative,
    NdsVehicleModifierType.Weapon,
  ];
}

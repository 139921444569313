import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterMoralityData } from '@rpg/core/character';
import { ndsCharacterMoralityEntryToFormGroup } from './nds-character-morality-entry-to-form-group';

export function ndsCharacterMoralityToFormGroup(
  morality: NdsCharacterMoralityData
): FormGroup<NdsCharacterMoralityData> {
  return new FormGroup<NdsCharacterMoralityData>({
    moralities: new FormArray(
      morality.moralities.map(m => ndsCharacterMoralityEntryToFormGroup(m))
    ),
    score: new FormControl(morality.score),
    sessionScore: new FormControl(morality.sessionScore),
  });
}

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { NdsCharacterActivationType, NdsCharacterTalentData } from '@rpg/core/character';

@Component({
  selector: 'rpg-nds-character-talent-slot',
  templateUrl: './talent-slot.component.html',
  styleUrls: ['./talent-slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NdsCharacterTalentSlotComponent {
  @Input()
  public talentData?: NdsCharacterTalentData;
  @Input()
  public mobileEnabled: boolean = true;
  @Input()
  public mobileSelected: boolean = false;
  @Input()
  public editMode: boolean = false;
  @Input()
  @HostBinding('class.force-mobile')
  public forceMobile: boolean = false;

  @Output()
  public talentContentClick = new EventEmitter<void>();
  @Output()
  public talentPurchasedToggle = new EventEmitter<boolean>();

  public NdsCharacterActivationType: typeof NdsCharacterActivationType = NdsCharacterActivationType;
}

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ChangeDetectionStrategy, OnDestroy, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Store } from '@ngxs/store';
import { DiceTheme, FeatureFlag, GameTheme, RpgSelectOption, RulesSystem } from '@rpg/core/base';
import {
  NdsCharacterConfigurationData,
  NdsCharacterTalentDisplayType,
  NdsCharacterType,
} from '@rpg/core/character';
import { ndsCharacterConfigurationToFormGroup } from '@rpg/ngx/core';
import { debounceTime, startWith, Subscription } from 'rxjs';

@Component({
  selector: 'rpg-nds-character-configuration',
  templateUrl: './nds-character-configuration.component.html',
  styleUrls: ['./nds-character-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NdsCharacterConfigurationComponent implements OnInit, OnDestroy {
  @Input()
  public configurationForm = ndsCharacterConfigurationToFormGroup(
    new NdsCharacterConfigurationData(GameTheme.Genesys_Core)
  );
  public characterTypeControl = this.configurationForm.get('ndsCharacterType') as FormControl;
  public advancedOptionsVisible = false;

  public gameThemeOptions: RpgSelectOption<GameTheme>[] = GameTheme.membersByRulesSystem(
    RulesSystem.NarrativeDiceSystem
  ).map(theme => ({
    label: GameTheme.translationKey(theme),
    value: theme,
  }));

  public NdsCharacterType: typeof NdsCharacterType = NdsCharacterType;
  public DiceTheme: typeof DiceTheme = DiceTheme;
  public NdsCharacterTalentDisplayType: typeof NdsCharacterTalentDisplayType =
    NdsCharacterTalentDisplayType;
  public FeatureFlag: typeof FeatureFlag = FeatureFlag;

  private _diceThemeWatcher: Subscription | undefined;
  private _forceEnabled: Subscription | undefined;
  private _themeWatcher: Subscription | undefined;
  private _themeWatcherWatcher: Subscription | undefined;

  constructor(private _store: Store) {}

  ngOnInit() {
    this._watchDiceTheme(this.configurationForm);
    this._watchForceEnabled(this.configurationForm);
    this._watchThemeValue(this.configurationForm);
    this._watchThemeWatcher(this.configurationForm);
  }

  ngOnDestroy(): void {
    this._diceThemeWatcher?.unsubscribe();
    this._forceEnabled?.unsubscribe();
    this._themeWatcher?.unsubscribe();
    this._themeWatcherWatcher?.unsubscribe();
  }

  public updateFormGroup(form: FormGroup<NdsCharacterConfigurationData>): void {
    this.configurationForm = form;
    this.characterTypeControl = form.get('ndsCharacterType') as FormControl;
    this._watchDiceTheme(form);
    this._watchForceEnabled(form);
    this.configurationForm.updateValueAndValidity();
  }

  public getValue(): NdsCharacterConfigurationData {
    return new NdsCharacterConfigurationData(this.configurationForm.value);
  }

  public isDiceTheme(theme: DiceTheme) {
    return this.configurationForm.get('diceTheme').value === theme;
  }

  private _watchThemeWatcher(form: FormGroup<NdsCharacterConfigurationData>) {
    this._themeWatcherWatcher?.unsubscribe();

    const UPDATE_DELAY = 500;
    this._themeWatcherWatcher = form.valueChanges
      .pipe(debounceTime(UPDATE_DELAY))
      .subscribe(configValue => {
        if (
          !NdsCharacterConfigurationData.isEqual(
            form.getRawValue(), // Some fields may be disabled
            NdsCharacterConfigurationData.presets[configValue.gameTheme]
          )
        ) {
          // Clear watchers
          this._themeWatcher?.unsubscribe();
          this._themeWatcherWatcher?.unsubscribe();
          this._themeWatcher = undefined;
          this._themeWatcherWatcher = undefined;
        }
      });
  }

  private _watchDiceTheme(form: FormGroup): void {
    this._diceThemeWatcher?.unsubscribe();
    const diceThemeControl = form.get('diceTheme');
    const forceDiceControl = form.get('forceDiceEnabled');
    this._diceThemeWatcher = diceThemeControl.valueChanges
      .pipe(startWith(diceThemeControl.value))
      .subscribe(nextValue => {
        if (nextValue === DiceTheme.Genesys && forceDiceControl.disabled) {
          forceDiceControl.enable();
        } else if (nextValue !== DiceTheme.Genesys && forceDiceControl.enabled) {
          forceDiceControl.disable();
          forceDiceControl.setValue(false);
        }
      });
  }

  private _watchForceEnabled(form: FormGroup): void {
    this._forceEnabled?.unsubscribe();
    const forceEnabledControl = form.get('forceEnabled');
    const forceTreeControl = form.get('forcePowersDisplayType');
    this._forceEnabled = forceEnabledControl.valueChanges
      .pipe(startWith(forceEnabledControl.value))
      .subscribe(nextValue => {
        if (nextValue === true && forceTreeControl.disabled) {
          forceTreeControl.enable();
        } else if (nextValue === false && forceTreeControl.enabled) {
          forceTreeControl.disable();
        }
      });
  }

  private _watchThemeValue(form: FormGroup<NdsCharacterConfigurationData>) {
    this._themeWatcher?.unsubscribe();
    const themeControl: FormControl<GameTheme> = form.get('gameTheme') as FormControl;

    // Only start the watcher if the current value is equal to a preset, otherwise,
    // we most likely have a custom config already and don't want to screw with that
    if (
      !NdsCharacterConfigurationData.isEqual(
        form.getRawValue(), // Some fields may be disabled
        NdsCharacterConfigurationData.presets[themeControl.value]
      )
    ) {
      return;
    }
    this._themeWatcher = themeControl.valueChanges
      .pipe(startWith(themeControl.value))
      .subscribe(nextValue => {
        const nextValues = { ...NdsCharacterConfigurationData.presets[nextValue] };
        // Changing the theme shouldn't change the character type you've chosen
        delete (nextValues as any).ndsCharacterType;
        if (!!nextValues.gameTheme) {
          // We need to appease TS here, if we don't delete this, we get an infinite loop
          delete (nextValues as any).gameTheme;
        }
        form.patchValue(nextValues);
      });
  }
}

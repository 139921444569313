import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ObserversModule } from '@angular/cdk/observers';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormFieldComponent } from './form-field/form-field.component';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { SelectTypeaheadComponent } from './select-typeahead/select-typeahead.component';
import { TextareaComponent } from './textarea/textarea.component';
import { SharedComponentsModule } from '../shared';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [
    FormFieldComponent,
    TextareaComponent,
    InputComponent,
    SelectComponent,
    SelectTypeaheadComponent,
  ],
  exports: [
    FormFieldComponent,
    TextareaComponent,
    InputComponent,
    SelectComponent,
    SelectTypeaheadComponent,
  ],
  imports: [
    CommonModule,
    ObserversModule,
    SharedComponentsModule,
    FontAwesomeModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MarkdownModule.forChild(),
  ],
})
export class FormsComponentsModule {}

export { InputComponent as RpgInputComponent, TextareaComponent as RpgTextareaComponent };

import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OverlayButton } from '../models';

export interface ConfirmationOverlayOptions {
  title: string;
  messages: string[];
  buttons: OverlayButton[];
}

const confirmationOptionsDefaults: ConfirmationOverlayOptions = {
  title: 'Confirm',
  messages: ['Confirm your action'],
  buttons: [
    { label: 'Cancel', value: false, color: 'accent' },
    { label: 'Confirm', value: true, color: 'primary' },
  ],
};

@Component({
  selector: 'rpg-confirmation-overlay',
  templateUrl: './confirmation-overlay.component.html',
  styleUrls: ['./confirmation-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationOverlayComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public options: ConfirmationOverlayOptions
  ) {
    this.options = { ...confirmationOptionsDefaults, ...this.options };
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RedirectService } from '@rpg/ngx/core';
import { Store } from '@ngxs/store';
import { Auth3State } from '@sessions/state';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private redirectService: RedirectService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authenticated = this.store.selectSnapshot(Auth3State.isAuthenticated);
    if (!authenticated) {
      this.redirectService.setLoginRedirect(state.url);
      return this.router.parseUrl('/auth2');
    }
    return true;
  }
}

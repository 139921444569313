import { unreachable } from '@rpg/core/base';
import { NdsVehicleModifierType } from '../enums';
import {
  NdsVehicleModifierActionData,
  NdsVehicleModifierAttributeData,
  NdsVehicleModifierCharacteristicData,
  NdsVehicleModifierData,
  NdsVehicleModifierNarrativeData,
  NdsVehicleModifierWeaponData,
} from '../models/modifiers';

export function NdsVehicleModifierFactory(
  modifier: NdsVehicleModifierData
): NdsVehicleModifierData {
  switch (modifier?.type) {
    case NdsVehicleModifierType.Action:
      return new NdsVehicleModifierActionData(modifier as NdsVehicleModifierActionData);
    case NdsVehicleModifierType.Attribute:
      return new NdsVehicleModifierAttributeData(modifier as NdsVehicleModifierAttributeData);
    case NdsVehicleModifierType.Characteristic:
      return new NdsVehicleModifierCharacteristicData(
        modifier as NdsVehicleModifierCharacteristicData
      );
    case NdsVehicleModifierType.Narrative:
      return new NdsVehicleModifierNarrativeData(modifier as NdsVehicleModifierNarrativeData);
    case NdsVehicleModifierType.Weapon:
      return new NdsVehicleModifierWeaponData(modifier as NdsVehicleModifierWeaponData);
    default:
      unreachable(modifier.type, 'NdsVehicleModifierFactory');
  }
}

import normalize from 'validator/lib/normalizeEmail';

export function normalizeEmail(email: string) {
  const res = normalize(email, {
    gmail_remove_subaddress: false,
    outlookdotcom_remove_subaddress: false,
    yahoo_remove_subaddress: false,
    icloud_remove_subaddress: false,
  });
  if (typeof res === 'string') {
    return res;
  } else {
    return email;
  }
}

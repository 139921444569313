import { ObjectId } from 'bson';
import { parseObjectId } from '../../base';

export class PartyVehicle {
  public _id: ObjectId | null = null;

  constructor(params?: Partial<PartyVehicle>) {
    if (!!params) {
      this._id = parseObjectId(params._id);
    }
  }
}

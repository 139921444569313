import { Newable } from '../types';

export class PaginatedList<T> {
  public docs: Array<T> = [];
  public totalDocs: number = 0;

  constructor(docs: Array<T>, totalDocs: number);
  constructor(data: PaginatedList<any>, type?: Newable<T>);
  constructor(data: Array<T> | PaginatedList<any>, typeOrTotalDocs?: number | Newable<T>) {
    if (Array.isArray(data)) {
      this.docs = data;
      this.totalDocs = (typeOrTotalDocs as number) || this.totalDocs;
    } else {
      const type: Newable<T> = typeOrTotalDocs as Newable<T>;
      this.totalDocs = data.totalDocs || this.totalDocs;
      if (typeof type !== 'undefined') {
        this.docs = Array.isArray(data.docs) ? data.docs.map(d => new type(d)) : [];
      } else {
        this.docs = Array.isArray(data.docs) ? data.docs : [];
      }
    }
  }
}

<ng-container *rxLet="state$; let state">
  <mat-drawer-container class="container" fullscreen>
    <mat-drawer #drawer mode="over" position="end" class="drawer">
      <h3 class="drawer__username" *ngIf="state.user">
        {{ state.user.username }}
      </h3>
      <ul class="drawer__items" *ngIf="state.isAuthenticated">
        <ng-container *ngFor="let item of state.menuItems">
          <li *rpgPermission="item.permission" class="drawer__item drawer-item">
            <a
              mat-button
              [routerLink]="item.routerLink"
              class="drawer-item__link"
              routerLinkActive="drawer-item__link--active"
              (click)="drawer.close(); item.handler ? item.handler() : null"
            >
              {{ item.label | transloco }}
            </a>
          </li>
        </ng-container>
      </ul>
      <ul class="drawer__items" *ngIf="!state.isAuthenticated">
        <li class="drawer__item drawer-item">
          <a
            mat-button
            routerLink="/auth2"
            [queryParams]="redirectParams"
            class="drawer-item__link"
            (click)="drawer.close()"
          >
            {{ 'words.Login' | transloco }}
          </a>
        </li>
      </ul>
    </mat-drawer>
    <div class="container__content">
      <!-- <rpg-patreon-drive-banner [patreonLink]="SocialLinks.Discord"></rpg-patreon-drive-banner> -->
      <nav class="nav" [class.nav--small]="state.headerSize === 'small'">
        <ng-container>
          <a class="nav__link" [routerLink]="homePage">
            <img class="nav__logo" [src]="Images.LogoWhite" alt="RPG Sessions Logo" />
          </a>
          <ul class="nav__items" *ngIf="state.isAuthenticated">
            <ng-container *ngFor="let item of state.menuItems">
              <li *rpgPermission="item.permission" class="nav__item nav-item">
                <a
                  *ngIf="item.routerLink; else buttonRef"
                  mat-button
                  [routerLink]="item.routerLink"
                  class="nav-item__link"
                  routerLinkActive="nav-item__link--active"
                  (click)="item.handler ? item.handler() : null"
                >
                  {{ item.label | transloco }}
                </a>
                <ng-template #buttonRef>
                  <button
                    mat-button
                    class="nav-item__link"
                    (click)="item.handler ? item.handler() : null"
                  >
                    {{ item.label | transloco }}
                  </button>
                </ng-template>
              </li>
            </ng-container>
          </ul>
          <ul class="nav__items" *ngIf="!state.isAuthenticated">
            <li class="nav__item nav-item">
              <a
                mat-button
                class="nav-item__link"
                routerLink="/auth2"
                [queryParams]="redirectParams"
              >
                {{ 'words.Login' | transloco }}
              </a>
            </li>
          </ul>
          <button mat-button class="nav__drawer-toggle" (click)="drawer.toggle()">
            <fa-icon icon="bars"></fa-icon>
          </button>
        </ng-container>
      </nav>
      <main class="container__main">
        <router-outlet></router-outlet>
      </main>
      <ng-container *ngIf="state.footerVisible">
        <footer class="container__footer footer">
          &#169; RPG Sessions {{ year }} |
          <a class="footer__link" href="https://www.rpgsessions.com/help-doc.html" target="_blank">
            {{ 'shared.footer.bot-help' | transloco }}
          </a>
          |
          <a class="footer__link" [href]="SocialLinks.Twitter">
            <fa-icon class="action-icon twitter" [icon]="['fab', 'twitter-square']"></fa-icon>
          </a>
          <a class="footer__link" [href]="SocialLinks.Instagram">
            <fa-icon class="action-icon instagram" [icon]="['fab', 'instagram']"></fa-icon>
          </a>
          <a class="footer__link" [href]="SocialLinks.Discord">
            <fa-icon class="action-icon discord" [icon]="['fab', 'discord']"></fa-icon>
          </a>
          | {{ 'shared.footer.build-version' | transloco: { version: version } }}
        </footer>
      </ng-container>
    </div>
  </mat-drawer-container>
</ng-container>

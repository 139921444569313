<div class="flex-row gap-32 flex-h-center basics" [formGroup]="configurationForm">
  <div class="theme">
    <rpg-well>
      <rpg-nds-character-characteristic-single
        [gameTheme]="configurationForm.get('gameTheme').value"
      ></rpg-nds-character-characteristic-single>
      <rpg-nds-attribute-double
        [gameTheme]="configurationForm.get('gameTheme').value"
      ></rpg-nds-attribute-double>
    </rpg-well>
    <rpg-select
      [placeholder]="'words.Theme' | transloco"
      [inputControl]="configurationForm.get('gameTheme')"
      [options]="gameThemeOptions"
    ></rpg-select>
  </div>
  <div class="type">
    <h3>{{ 'phrases.Character Type' | transloco }}</h3>
    <mat-radio-group formControlName="ndsCharacterType" color="accent">
      <mat-radio-button *ngFor="let type of NdsCharacterType.members" [value]="type">
        {{ type !== NdsCharacterType.Player ? ('words.Adversary' | transloco) + ': ' : ''
        }}{{ NdsCharacterType.translationKey(type) | transloco }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
<div class="divider" (click)="advancedOptionsVisible = !advancedOptionsVisible">
  <fa-icon icon="caret-right" [class.rotated]="advancedOptionsVisible"></fa-icon>
  <span class="label">{{ 'phrases.Advanced Options' | transloco }}</span>
  <span class="line"></span>
</div>
<div
  class="advanced"
  [formGroup]="configurationForm"
  [@detailExpand]="advancedOptionsVisible ? 'expanded' : 'collapsed'"
>
  <div class="configs">
    <div class="group">
      <div class="header">
        <h3>{{ 'words.Dice' | transloco }}</h3>
      </div>
      <div class="content">
        <mat-radio-group formControlName="diceTheme">
          <mat-radio-button [value]="DiceTheme.Genesys">
            {{ 'dice.phrases.Genesys Dice' | transloco }}
          </mat-radio-button>
          <mat-checkbox formControlName="forceDiceEnabled" class="inset">
            {{ 'dice.phrases.Force Dice' | transloco }}
          </mat-checkbox>
          <hr />
          <mat-radio-button [value]="DiceTheme.StarWars">
            {{ 'dice.phrases.Star Wars Dice' | transloco }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="group">
      <div class="header">
        <h3>{{ 'phrases.Talent Trees' | transloco }}</h3>
      </div>
      <div class="content">
        <mat-radio-group class="taller" formControlName="talentsDisplayType">
          <ng-container *ngFor="let type of NdsCharacterTalentDisplayType.members">
            <mat-radio-button [value]="type">
              {{ NdsCharacterTalentDisplayType.translationKey(type) | transloco }}
            </mat-radio-button>
            <mat-checkbox
              *ngIf="type === NdsCharacterTalentDisplayType.Tree"
              formControlName="talentsSignatureAbilitiesEnabled"
              class="inset"
              [rpgDisableControl]="
                configurationForm.get('talentsDisplayType').value !==
                NdsCharacterTalentDisplayType.Tree
              "
            >
              {{ 'character.phrases.signature-talent-shortened' | transloco }}
            </mat-checkbox>
          </ng-container>
        </mat-radio-group>
      </div>
    </div>
    <div class="group">
      <div class="header">
        <h3>{{ 'phrases.Force Options' | transloco }}</h3>
      </div>
      <div class="content">
        <mat-radio-group formControlName="forcePowersDisplayType">
          <mat-checkbox formControlName="forceEnabled">
            {{ 'phrases.Force Rating' | transloco }}
          </mat-checkbox>
          <mat-radio-button class="inset" [value]="NdsCharacterTalentDisplayType.Tree">
            {{
              NdsCharacterTalentDisplayType.translationKey(NdsCharacterTalentDisplayType.Tree)
                | transloco
            }}
          </mat-radio-button>
          <mat-radio-button class="inset" [value]="NdsCharacterTalentDisplayType.ListGroup">
            {{
              NdsCharacterTalentDisplayType.translationKey(NdsCharacterTalentDisplayType.ListGroup)
                | transloco
            }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="mechanics">
    <div class="group">
      <div class="header">
        <h3>{{ 'phrases.Extra Mechanics' | transloco }}</h3>
      </div>
      <div class="content flex-wrap">
        <mat-checkbox formControlName="obligationEnabled">
          {{ 'words.Obligation' | transloco }}
        </mat-checkbox>
        <mat-checkbox formControlName="moralityEnabled">
          {{ 'words.Morality' | transloco }}
        </mat-checkbox>
        <mat-checkbox formControlName="aemberEnabled">
          {{ 'words.Aember' | transloco }}
        </mat-checkbox>
        <mat-checkbox
          formControlName="agendasEnabled"
          *rpgFeatureFlag="FeatureFlag.GenesysTwilightImperium"
        >
          {{ 'words.Agendas' | transloco }}
        </mat-checkbox>
        <mat-checkbox formControlName="dutyEnabled">
          {{ 'words.Duty' | transloco }}
        </mat-checkbox>
        <mat-checkbox formControlName="favorsEnabled">
          {{ 'words.Favors' | transloco }}
        </mat-checkbox>
        <mat-checkbox formControlName="heroicAbilitiesEnabled">
          {{ 'phrases.Heroic Abilities' | transloco }}
        </mat-checkbox>
        <mat-checkbox formControlName="superCharacteristicsEnabled" class="wide">
          {{ 'character.nds.configuration.Super Characteristics' | transloco }}
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="m-b-12">
    <mat-checkbox formControlName="automations">
      {{ 'character.nds.configuration.Automations' | transloco }}
    </mat-checkbox>
  </div>
</div>

<ng-container *rxLet="info$; let game; rxSuspense: suspense">
  <a
    *ngIf="!disabled; else notLink"
    href="/game/table/{{ game._id.toHexString() }}"
    class="name-link"
  >
    {{ game.name }}
  </a>
  <ng-template #notLink>
    <span class="name-link">
      {{ game.name }}
    </span>
  </ng-template>
</ng-container>
<ng-template #suspense>
  <mat-spinner [diameter]="19"></mat-spinner>
</ng-template>

import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterCharacteristicData } from '@rpg/core/character';

export function ndsCharacterCharacteristicToFormGroup(
  char: NdsCharacterCharacteristicData
): FormGroup<NdsCharacterCharacteristicData> {
  return new FormGroup<NdsCharacterCharacteristicData>({
    type: new FormControl(char.type, [Validators.required]),
    value: new FormControl(char.value),
    superCharacteristic: new FormControl(char.superCharacteristic),
  });
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { GameHttpService } from '@rpg/ngx/core';
import { Observable, of, map } from 'rxjs';
import { ObjectId } from 'bson';

@Component({
  selector: 'rpg-game-name-link',
  templateUrl: './game-name-link.component.html',
  styleUrls: ['./game-name-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameNameLinkComponent implements OnInit {
  @Input()
  public game: { _id: ObjectId; name: string } | undefined;
  @Input()
  public gameId: ObjectId | undefined;
  @Input()
  public disabled: boolean = false;
  @Output()
  public nameClick: EventEmitter<ObjectId> = new EventEmitter();

  public info$!: Observable<{ _id: ObjectId; name: string }>;

  constructor(private _gameHttp: GameHttpService) {}

  ngOnInit(): void {
    if (!!this.game) {
      this.info$ = of({
        _id: this.game._id,
        name: this.game.name,
      });
    } else if (!!this.gameId) {
      this.info$ = this._gameHttp.getGameById(this.gameId).pipe(
        map(game => ({
          _id: game._id,
          name: game.name,
        }))
      );
    } else {
      throw new Error('Game Name Link component requires either game data or a gameId');
    }
  }
}

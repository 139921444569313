import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LetModule } from '@rx-angular/template';
import { TranslocoModule } from '@ngneat/transloco';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from '@rpg/ngx/permissions';
import { SharedComponentsModule } from '@rpg/ngx/components/shared';

import { components } from './components';
import { directives } from './directives';
import { overlays, SharedOverlayService } from './overlays';
import { pipes } from './pipes';
import { containers } from './containers';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsComponentsModule } from '@rpg/ngx/components/forms';
import { NdsSharedComponentsModule } from '@rpg/ngx/components/nds/shared';
import { NdsCharacterComponentsModule } from '@rpg/ngx/components/nds/character';
import { NdsVehicleComponentsModule } from '@rpg/ngx/components/nds/vehicle';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [...components, ...containers, ...directives, ...overlays, ...pipes],
  exports: [...components, ...containers, ...directives, ...pipes],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatDialogModule,
    FontAwesomeModule,
    LetModule,
    TranslocoModule,
    NgxPermissionsModule,
    SharedComponentsModule,
    MatStepperModule,
    FormsComponentsModule,
    NdsSharedComponentsModule,
    NdsCharacterComponentsModule,
    NdsVehicleComponentsModule,
    MatRadioModule,
    MatCheckboxModule,
    MarkdownModule,
  ],
  providers: [SharedOverlayService],
})
export class SharedModule {}

export * from './components';
export * from './directives';
export * from './overlays';

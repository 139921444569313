import { GameTheme, parseObjectId, RulesSystem } from '@rpg/core/base';
import { BaseVehicle } from '@rpg/core/vehicle';
import { ObjectId } from 'bson';
import { FileType } from '../enums';
import { FileReference } from './file-reference';

export class VehicleFileReference extends FileReference {
  public type: FileType = FileType.Vehicle;
  public rulesSystem: RulesSystem = RulesSystem.Generic;
  public gameTheme: GameTheme = GameTheme.Generic;
  public referenceDocId!: ObjectId;

  constructor(params?: Partial<VehicleFileReference>) {
    super(params);
    if (!!params) {
      this.type = FileType.Vehicle;
      this.referenceDocId = parseObjectId(params.referenceDocId, this.referenceDocId);
    }
  }

  static fromVehicle(
    vehicle: BaseVehicle,
    existingFileRef?: Partial<VehicleFileReference>
  ): VehicleFileReference {
    return new VehicleFileReference({
      ...(existingFileRef ?? {}),
      userId: vehicle.userId,
      name: vehicle.name,
      imageUrl: vehicle.image,
      rulesSystem: vehicle.rulesSystem,
      gameTheme: vehicle.configuration.gameTheme,
      referenceDocId: vehicle._id,
    });
  }
}

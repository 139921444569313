import { NdsCharacterModifierType } from '../enums';
import {
  NdsCharacterModifierArmorData,
  NdsCharacterModifierAttributeData,
  NdsCharacterModifierCharacteristicData,
  NdsCharacterModifierData,
  NdsCharacterModifierNarrativeData,
  NdsCharacterModifierSkillData,
  NdsCharacterModifierWeaponData,
} from '../models/modifiers';

export function NdsCharacterModifierValidatorFactory(
  modifier: NdsCharacterModifierData,
  fieldNamePrefix: string = ''
): string[] {
  switch (modifier?.type) {
    case NdsCharacterModifierType.Armor:
      return NdsCharacterModifierArmorData.validate(
        modifier as NdsCharacterModifierArmorData,
        fieldNamePrefix
      );
    case NdsCharacterModifierType.Attribute:
      return NdsCharacterModifierAttributeData.validate(
        modifier as NdsCharacterModifierAttributeData,
        fieldNamePrefix
      );
    case NdsCharacterModifierType.Characteristic:
      return NdsCharacterModifierCharacteristicData.validate(
        modifier as NdsCharacterModifierCharacteristicData,
        fieldNamePrefix
      );
    case NdsCharacterModifierType.Skill:
      return NdsCharacterModifierSkillData.validate(
        modifier as NdsCharacterModifierSkillData,
        fieldNamePrefix
      );
    case NdsCharacterModifierType.Weapon:
      return NdsCharacterModifierWeaponData.validate(
        modifier as NdsCharacterModifierWeaponData,
        fieldNamePrefix
      );
    case NdsCharacterModifierType.Narrative:
      return NdsCharacterModifierNarrativeData.validate(
        modifier as NdsCharacterModifierNarrativeData,
        fieldNamePrefix
      );
    default:
      return [`${modifier.type} is not a valid modifier type`];
  }
}

import { NdsVehicleModifierFactory } from '../functions/nds-vehicle-modifier-factory';
import { NdsVehicleModifierData } from './modifiers';

export class NdsMatchedVehicleModifier {
  public enabled: boolean = false;
  public fromId: string = '';
  public fromName: string = '';
  public modifier!: NdsVehicleModifierData;

  constructor(params: NdsMatchedVehicleModifier) {
    this.enabled = params.enabled ?? this.enabled;
    this.fromId = params.fromId ?? this.fromId;
    this.fromName = params.fromName ?? this.fromName;
    this.modifier = !!params.modifier ? NdsVehicleModifierFactory(params.modifier) : this.modifier;
  }
}

import { createId, parseBoolean, parseNumber, RpgClassValidators } from '@rpg/core/base';

export class NdsCharacterObligationEntryData {
  public id: string = '';
  public name: string = '';
  public amount: number = 0;
  public description: string = '';
  public triggered: boolean = false;

  constructor(params?: Partial<NdsCharacterObligationEntryData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.amount = parseNumber(params.amount, this.amount);
      this.description = params.description ?? this.description;
      this.triggered = parseBoolean(params.triggered, this.triggered);
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsCharacterObligationEntryData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id, { fieldName: 'Obligation ID' }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: 'Obligation Name' }));
    errors.push(...RpgClassValidators.number(input.amount, { fieldName: 'Obligation Amount' }));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: 'Obligation Description',
      })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.triggered, { fieldName: 'Obligation Triggered Toggle' })
    );
    return errors;
  }
}

import {
  Directive,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
} from '@angular/core';

@Directive({
  selector: 'textarea[rpgSelectionStart]',
})
export class RpgSelectionStartDirective {
  @Output()
  public rpgSelectionStart: EventEmitter<number> = new EventEmitter<number>();

  @HostListener('keyup')
  @HostListener('focus')
  @HostListener('blur')
  public emitSelectionPosition(): void {
    this.rpgSelectionStart.emit(this._elementRef.nativeElement.selectionStart);
  }

  constructor(
    private _elementRef: ElementRef<HTMLTextAreaElement>,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}
}

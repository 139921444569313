export enum PatreonReward {
  ImportLimitLevel1 = '[patreon reward] import limit level 1',
  ImportLimitLevel2 = '[patreon reward] import limit level 2',
  ImportLimitLevel3 = '[patreon reward] import limit level 3',
  UnlimitedImports = '[patreon reward] unlimited imports',
  NPCImports = '[patreon reward] npc imports',
  OggdudeCustomData = '[patreon reward] oggdude custom data',
  HonoraryTitle = '[patreon reward] honorary title',
  PrintableCharacterSheets = '[patreon reward] printable character sheets',
  SneakPeekAccess = '[patreon reward] sneak peek access',
  SneakPeekCommunityAccess = '[patreon reward] sneak peek community access',
  FirstLookAccess = '[patreon reward] first look access',
  FirstLookCommunityAccess = '[patreon reward] first look community access',
  FeatureImprovementVotingRights = '[patreon reward] feature improvement voting rights',
  ExplorerGameTableToken = '[patreon reward] explorer game table token',
  AdventurerGameTableToken = '[patreon reward] adventurer game table token',
  ChampionGameTableToken = '[patreon reward] champion game table token',
  AdventurerSupporterList = '[patreon reward] adventurer supporter list',
  ChampionSupporterList = '[patreon reward] champion supporter list',
  Folders = '[patreon reward] folders',
  ImageUpload = '[patreon reward] image upload',
  GTMessagesImageUpload = '[patreon reward] GT messages images upload',
  AIRollSuggestions = '[patreon reward] AI roll suggestions',
  AIRulesLawyer = '[patreon reward] AI rules lawyer',
}

export namespace PatreonReward {
  export const members: PatreonReward[] = [
    PatreonReward.ImportLimitLevel1,
    PatreonReward.ImportLimitLevel2,
    PatreonReward.ImportLimitLevel3,
    PatreonReward.UnlimitedImports,
    PatreonReward.NPCImports,
    PatreonReward.OggdudeCustomData,
    PatreonReward.HonoraryTitle,
    PatreonReward.PrintableCharacterSheets,
    PatreonReward.SneakPeekAccess,
    PatreonReward.SneakPeekCommunityAccess,
    PatreonReward.FirstLookAccess,
    PatreonReward.FirstLookCommunityAccess,
    PatreonReward.FeatureImprovementVotingRights,
    PatreonReward.ExplorerGameTableToken,
    PatreonReward.AdventurerGameTableToken,
    PatreonReward.ChampionGameTableToken,
    PatreonReward.AdventurerSupporterList,
    PatreonReward.ChampionSupporterList,
    PatreonReward.Folders,
    PatreonReward.ImageUpload,
    PatreonReward.GTMessagesImageUpload,
    PatreonReward.AIRollSuggestions,
    PatreonReward.AIRulesLawyer
  ];

  export function getDescription(reward: PatreonReward): string {
    return '';
  }

  export function translationKey(reward: PatreonReward): string {
    return '';
  }
}

import { Injectable } from '@angular/core';
import { normalizeEmail, normalizeUsername } from '@rpg/core/base';
import { environment } from '@sessions/env';
import { AuthChangeEvent, Provider, Session } from '@supabase/supabase-js';

import { SupabaseService } from './supabase.service';

@Injectable({
  providedIn: 'root',
})
export class AuthSB {
  constructor(private sb: SupabaseService) {}

  get client() {
    return this.sb;
  }

  get session() {
    return this.sb.auth.getSession();
  }

  get user() {
    return this.sb.auth.getUser();
  }

  onStateChange(fn: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.sb.auth.onAuthStateChange(fn);
  }

  signUpWithEmail(email: string, username: string, password: string) {
    return this.sb.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: `${window?.location?.origin ?? environment.weburl}/auth2/confirmed`,
        data: {
          username,
          username_normalized: normalizeUsername(username),
          email_normalized: normalizeEmail(email),
        },
      },
    });
  }

  signInWithRefreshToken(refreshToken: string) {
    return this.sb.auth.setSession({ refresh_token: refreshToken, access_token: '' });
  }

  signInWithEmail(email: string, password: string) {
    return this.sb.auth.signInWithPassword({ email, password });
  }

  signInWithProvider(provider: Provider) {
    return this.sb.auth.signInWithOAuth({
      provider,
      options: { redirectTo: `${window?.location?.origin ?? environment.weburl}/auth2/confirmed` },
    });
  }

  sendMagicLink(email: string) {
    return this.sb.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: `${window?.location?.origin ?? environment.weburl}/auth2/confirmed`,
      },
    });
  }

  sendEmailReset(email: string) {
    return this.sb.auth.resetPasswordForEmail(email, {
      redirectTo: `${window?.location?.origin ?? environment.weburl}/auth2/reset`,
    });
  }

  async resetPassword(newPassword: string) {
    return this.sb.auth.updateUser({ password: newPassword });
  }

  async updateEmail(newEmail: string) {
    return this.sb.auth.updateUser({ email: newEmail });
  }

  async updatePassword(password: string) {
    return this.sb.auth.updateUser({ password });
  }

  signOut() {
    return this.sb.auth.signOut();
  }
}

import { Edge } from '../enums';
import { createId, Logger } from '../functions/utilities';
import { AdjacentTracker } from './adjacent-tracker';
import { Position2D } from './position-2d';

export class EdgeMatrix {
  public id: string = '';
  public points: { [key: number]: { [key: number]: AdjacentTracker | undefined } } = {};

  constructor(params?: Partial<EdgeMatrix>);
  constructor(width: number, height: number);
  constructor(params?: Partial<EdgeMatrix> | number, height?: number) {
    if (typeof params === 'number') {
      this.newGraph(params, height as number);
      this.id = createId();
    } else if (!!params && !!params.points) {
      for (const x of Object.keys(params.points)) {
        for (const y of Object.keys(params.points[+x])) {
          this.addNode(+x, +y, params.points[+x][+y]?.value);
        }
      }
      this.id = params.id ?? createId();
    }
  }

  public newGraph(width: number, height: number): void {
    this.points = {};
    for (let x = 0; x < width; x++) {
      for (let y = 0; y < height; y++) {
        if (!this.points[x]) {
          this.points[x] = {};
        }
        this.points[x][y] = new AdjacentTracker();
      }
    }
  }

  public getNode(x: number, y: number): AdjacentTracker | undefined;
  public getNode(position2d: Position2D): AdjacentTracker | undefined;
  public getNode(xOrPosition: Position2D | number, y?: number): AdjacentTracker | undefined {
    if (typeof xOrPosition === 'number') {
      return this.points[xOrPosition]?.[y as number] ?? undefined;
    } else {
      return this.points[xOrPosition.x]?.[xOrPosition.y] ?? undefined;
    }
  }

  public getAdjacentNode(x: number, y: number, direction: Edge): AdjacentTracker | undefined;
  public getAdjacentNode(position: Position2D, direction: Edge): AdjacentTracker | undefined;
  public getAdjacentNode(
    xOrPosition: Position2D | number,
    yOrDirection: Edge | number,
    direction?: Edge
  ): AdjacentTracker | undefined {
    let x: number, y: number;
    if (typeof xOrPosition === 'number') {
      x = xOrPosition;
      y = yOrDirection;
    } else {
      x = xOrPosition.x;
      y = xOrPosition.y;
      direction = yOrDirection;
    }
    switch (direction) {
      case Edge.Up:
        y -= 1;
        break;
      case Edge.Down:
        y += 1;
        break;
      case Edge.Left:
        x -= 1;
        break;
      case Edge.Right:
        x += 1;
        break;
      default:
        throw new Error(`Invalid direction given: ${direction}`);
    }
    return this.getNode(x, y);
  }

  public addNode(x: number, y: number, initialValue?: number): AdjacentTracker;
  public addNode(position: Position2D, initialValue?: number): AdjacentTracker;
  public addNode(
    xOrPosition: Position2D | number,
    yOrInitialValue: number,
    initialValue?: number
  ): AdjacentTracker {
    let x: number, y: number;
    if (typeof xOrPosition === 'number') {
      x = xOrPosition;
      y = yOrInitialValue;
      initialValue = initialValue ?? 0;
    } else {
      x = xOrPosition.x;
      y = xOrPosition.y;
      initialValue = yOrInitialValue ?? 0;
    }
    if (!this.points[x]) {
      this.points[x] = [];
    }
    if (typeof this.points[x][y] === 'undefined') {
      const newAdjacent = new AdjacentTracker(initialValue);
      this.points[x][y] = newAdjacent;
      return newAdjacent;
    } else {
      throw new Error(`Node already exists at point ${x},${y}`);
    }
  }

  public removeNode(x: number, y: number): void;
  public removeNode(position: Position2D): void;
  public removeNode(xOrPosition: Position2D | number, y?: number): void {
    let x: number;
    if (typeof xOrPosition === 'number') {
      x = xOrPosition;
      y = y as number;
    } else {
      x = xOrPosition.x;
      y = xOrPosition.y;
    }
    if (!this.points[x]) {
      return;
    }
    if (!this.points[x][y]) {
      return;
    }
    this.points[x][y] = undefined;
  }

  public setEdge(x: number, y: number, direction: Edge, isAdjacent: boolean): void;
  public setEdge(position: Position2D, direction: Edge, isAdjacent: boolean): void;
  public setEdge(
    xOrPosition: Position2D | number,
    yOrDirection: Edge | number,
    directionOrIsAdjacent: boolean | Edge,
    isAdjacent?: boolean
  ): void {
    let x: number, y: number, direction: Edge;
    if (typeof xOrPosition === 'number') {
      x = xOrPosition;
      y = yOrDirection;
      direction = directionOrIsAdjacent as Edge;
      isAdjacent = isAdjacent as boolean;
    } else {
      x = xOrPosition.x;
      y = xOrPosition.y;
      direction = yOrDirection;
      isAdjacent = directionOrIsAdjacent as boolean;
    }
    const primaryNode = this.getNode(x, y);
    if (!primaryNode) {
      throw new Error(`No primary node found at ${x}, ${y}`);
    }
    primaryNode._toggle(isAdjacent, direction);
  }

  public setAdjacency(x: number, y: number, direction: Edge, isAdjacent: boolean): void;
  public setAdjacency(position: Position2D, direction: Edge, isAdjacent: boolean): void;
  public setAdjacency(
    xOrPosition: Position2D | number,
    yOrDirection: Edge | number,
    directionOrIsAdjacent: boolean | Edge,
    isAdjacent?: boolean
  ): void {
    let x: number, y: number, direction: Edge;
    if (typeof xOrPosition === 'number') {
      x = xOrPosition;
      y = yOrDirection;
      direction = directionOrIsAdjacent as Edge;
      isAdjacent = isAdjacent as boolean;
    } else {
      x = xOrPosition.x;
      y = xOrPosition.y;
      direction = yOrDirection;
      isAdjacent = directionOrIsAdjacent as boolean;
    }
    const primaryNode = this.getNode(x, y);
    if (!primaryNode) {
      throw new Error(`No primary node found at ${x}, ${y}`);
    }
    const adjacentNode = this.getAdjacentNode(x, y, direction);
    if (!adjacentNode) {
      Logger.warn(
        `No adjacent node found to ${x}, ${y} on edge ${Edge.toString(direction) ?? direction}`
      );
    }
    primaryNode._toggle(isAdjacent, direction);
    adjacentNode?._toggle(isAdjacent, Edge.getOpposingEdge(direction));
  }
}

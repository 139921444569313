import { ObjectId } from 'bson';
import { parseObjectId, parseObjectIdArray } from '@rpg/core/base';

export class GameMember {
  public userId!: ObjectId;
  public characterId: ObjectId | null = null;
  public characters: ObjectId[] = [];
  public vehicleId: ObjectId | null = null;

  constructor(params?: Partial<GameMember>) {
    if (!!params) {
      this.userId = parseObjectId(params.userId, new ObjectId());
      this.characterId = parseObjectId(params.characterId);
      this.characters = parseObjectIdArray(params.characters);
      this.vehicleId = parseObjectId(params.vehicleId);
    }
  }
}

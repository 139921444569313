import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterModifierArmorData } from '@rpg/core/character';
import { ndsCharacterModifierBaseToFormGroup } from './nds-character-modifier-base-to-form-group';

export function ndsCharacterModifierArmorToFormGroup(
  mod: NdsCharacterModifierArmorData
): FormGroup<NdsCharacterModifierArmorData> {
  return new FormGroup<NdsCharacterModifierArmorData>({
    ...ndsCharacterModifierBaseToFormGroup(mod).controls,
    defenseModifier: new FormControl(mod.defenseModifier),
    encumbranceModifier: new FormControl(mod.encumbranceModifier),
    linkedArmorId: new FormControl(mod.linkedArmorId),
    soakModifier: new FormControl(mod.soakModifier),
  });
}

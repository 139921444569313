import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterDutyData } from '@rpg/core/character';
import { ndsCharacterDutyEntryToFormGroup } from './nds-character-duty-entry-to-form-group';

export function ndsCharacterDutyToFormGroup(
  duty: NdsCharacterDutyData
): FormGroup<NdsCharacterDutyData> {
  return new FormGroup<NdsCharacterDutyData>({
    contributionRanks: new FormControl(duty.contributionRanks),
    duties: new FormArray(duty.duties.map(d => ndsCharacterDutyEntryToFormGroup(d))),
  });
}

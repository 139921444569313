import { MongoDoc, parseEnum, parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { FileType } from '../enums';

export class FileReference extends MongoDoc {
  public userId!: ObjectId;
  public parentFileRefId: ObjectId | null = null;
  public name: string = '';
  public imageUrl: string = '';
  public color: string = '';
  public type: FileType = FileType.Folder;
  public userFavorite: boolean = false;

  constructor(params?: Partial<FileReference>) {
    super(params);
    if (!!params) {
      this.userId = parseObjectId(params.userId, this.userId);
      this.parentFileRefId = parseObjectId(params.parentFileRefId);
      this.name = params.name ?? this.name;
      this.imageUrl = params.imageUrl ?? this.imageUrl;
      this.color = params.color ?? this.color;
      this.type = parseEnum(FileType, params.type, this.type);
      this.userFavorite = params.userFavorite ?? this.userFavorite;
    }
  }
}

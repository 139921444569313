import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleCharacteristicData } from '@rpg/core/vehicle';

export function ndsVehicleCharacteristicToFormGroup(
  char: NdsVehicleCharacteristicData
): FormGroup<NdsVehicleCharacteristicData> {
  return new FormGroup<NdsVehicleCharacteristicData>({
    type: new FormControl(char.type, [Validators.required]),
    value: new FormControl(char.value),
  });
}

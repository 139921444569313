import {
  NdsCalculatedVehicleModifier,
  NdsMatchedVehicleModifier,
  NdsVehicle,
  NdsVehicleActionData,
  NdsVehicleAttributeData,
  NdsVehicleCharacteristicData,
  NdsVehicleModifierActionData,
  NdsVehicleModifierAttributeData,
  NdsVehicleModifierCharacteristicData,
  NdsVehicleModifierData,
  NdsVehicleModifierWeaponData,
  NdsVehicleWeaponData,
} from '../models';
import { forEach, map } from 'lodash';
import { NdsVehicleModifierType } from '../enums';
import { sortDiceTypes } from '@rpg/core/dice';

export interface NdsCalculatedVehicle {
  resultVehicle: NdsVehicle;
  modifiers: Map<string, NdsCalculatedVehicleModifier>;
}

function gatherModifiers(vehicle: NdsVehicle): NdsMatchedVehicleModifier[] {
  const modifiers: NdsMatchedVehicleModifier[] = [];

  modifiers.push(
    ...map(
      vehicle.modifiers ?? [],
      mod =>
        new NdsMatchedVehicleModifier({
          enabled: true,
          fromId: '',
          fromName: vehicle.name,
          modifier: mod,
        })
    )
  );

  forEach(vehicle.attachments ?? [], attachment => {
    modifiers.push(
      ...map(
        attachment.modifiers ?? [],
        mod =>
          new NdsMatchedVehicleModifier({
            enabled: attachment.equipped,
            fromId: attachment.id,
            fromName: attachment.name,
            modifier: mod,
          })
      )
    );
    forEach(attachment.modifications, mod => {
      modifiers.push(
        ...map(
          mod.modifiers ?? [],
          m =>
            new NdsMatchedVehicleModifier({
              enabled: attachment.equipped && mod.enabled,
              fromId: mod.id,
              fromName: mod.name,
              modifier: m,
            })
        )
      );
    });
  });

  forEach(vehicle.weapons ?? [], weapon => {
    const isBaseWeaponEnabled = (mod: NdsVehicleModifierData) =>
      (mod.type === NdsVehicleModifierType.Weapon &&
        weapon.id === (mod as NdsVehicleModifierWeaponData).linkedWeaponId) ||
      weapon.equipped;
    modifiers.push(
      ...map(
        weapon.modifiers ?? [],
        mod =>
          new NdsMatchedVehicleModifier({
            enabled: isBaseWeaponEnabled(mod),
            fromId: weapon.id,
            fromName: weapon.name,
            modifier: mod,
          })
      )
    );
  });

  return modifiers;
}

function reduceActionModifiers(
  modifiers: NdsMatchedVehicleModifier[],
  baseValue: NdsVehicleActionData
): NdsCalculatedVehicleModifier<NdsVehicleActionData> {
  const res = new NdsCalculatedVehicleModifier({
    base: new NdsVehicleActionData(baseValue),
    result: new NdsVehicleActionData(baseValue),
    appliedModifiers: [],
    ignoredModifiers: [],
  });

  forEach(modifiers, mod => {
    if (!!baseValue && mod.modifier.type === NdsVehicleModifierType.Action) {
      const modRef = mod.modifier as NdsVehicleModifierActionData;
      if (modRef.linkedActionId === baseValue.id) {
        if (mod.enabled) {
          res.result.dice = [...res.result.dice, ...modRef.extraDice];
          res.appliedModifiers.push(mod);
        } else {
          res.ignoredModifiers.push(mod);
        }
      }
    }
  });
  res.result.dice = sortDiceTypes(res.result.dice);

  return res;
}

function reduceAttributeModifiers(
  modifiers: NdsMatchedVehicleModifier[],
  baseValue: NdsVehicleAttributeData
): NdsCalculatedVehicleModifier<NdsVehicleAttributeData> {
  const res = new NdsCalculatedVehicleModifier({
    base: new NdsVehicleAttributeData(baseValue),
    result: new NdsVehicleAttributeData(baseValue),
    appliedModifiers: [],
    ignoredModifiers: [],
  });

  forEach(modifiers, mod => {
    if (mod.modifier.type === NdsVehicleModifierType.Attribute) {
      if ((mod.modifier as NdsVehicleModifierAttributeData).attribute === baseValue.type) {
        if (!mod.enabled) {
          res.ignoredModifiers.push(mod);
        } else {
          res.result.value += (mod.modifier as NdsVehicleModifierAttributeData).modifierAmount;
          res.appliedModifiers.push(mod);
        }
      }
    }
  });

  return res;
}

function reduceCharacteristicModifiers(
  modifiers: NdsMatchedVehicleModifier[],
  baseValue: NdsVehicleCharacteristicData
): NdsCalculatedVehicleModifier<NdsVehicleCharacteristicData> {
  const res = new NdsCalculatedVehicleModifier({
    base: new NdsVehicleCharacteristicData(baseValue),
    result: new NdsVehicleCharacteristicData(baseValue),
    appliedModifiers: [],
    ignoredModifiers: [],
  });

  forEach(modifiers, mod => {
    if (!!baseValue && mod.modifier.type === NdsVehicleModifierType.Characteristic) {
      if (
        (mod.modifier as NdsVehicleModifierCharacteristicData).characteristic === baseValue.type
      ) {
        if (!mod.enabled) {
          res.ignoredModifiers.push(mod);
        } else {
          res.result.value += (mod.modifier as NdsVehicleModifierCharacteristicData).modifierAmount;
          res.appliedModifiers.push(mod);
        }
      }
    }
  });

  return res;
}

function reduceWeaponModifiers(
  modifiers: NdsMatchedVehicleModifier[],
  baseValue: NdsVehicleWeaponData
): NdsCalculatedVehicleModifier<NdsVehicleWeaponData> {
  const res = new NdsCalculatedVehicleModifier({
    base: new NdsVehicleWeaponData(baseValue),
    result: new NdsVehicleWeaponData(baseValue),
    appliedModifiers: [],
    ignoredModifiers: [],
  });

  forEach(modifiers, mod => {
    if (!!baseValue && mod.modifier.type === NdsVehicleModifierType.Weapon) {
      const modRef = mod.modifier as NdsVehicleModifierWeaponData;
      if (modRef.linkedWeaponId === baseValue.id) {
        if (mod.enabled) {
          res.result.damage += modRef.damageModifier;
          res.result.critRating += modRef.critModifier;
          res.result.extraDice = [...res.result.extraDice, ...modRef.extraDice];
          res.appliedModifiers.push(mod);
        } else {
          res.ignoredModifiers.push(mod);
        }
      }
    }
  });
  res.result.extraDice = sortDiceTypes(res.result.extraDice);

  return res;
}

export function ndsCalculatedVehicle(vehicle: NdsVehicle): NdsCalculatedVehicle {
  const modifiers = gatherModifiers(vehicle);
  const resultVehicle = new NdsVehicle(vehicle);
  const sortedModifiers = new Map<string, NdsCalculatedVehicleModifier>();

  // Resolve Actions
  resultVehicle.actions = map(resultVehicle.actions, action => {
    const calculatedModifier = reduceActionModifiers(modifiers, action);
    sortedModifiers.set(action.id, calculatedModifier);
    return calculatedModifier.result;
  });

  // Resolve Attributes
  resultVehicle.attributes = map(resultVehicle.attributes, attr => {
    const calculatedModifier = reduceAttributeModifiers(modifiers, attr);
    sortedModifiers.set(attr.type, calculatedModifier);
    return calculatedModifier.result;
  });

  // Resolve Characteristics
  resultVehicle.characteristics = map(resultVehicle.characteristics, char => {
    const calculatedModifier = reduceCharacteristicModifiers(modifiers, char);
    sortedModifiers.set(char.type, calculatedModifier);
    return calculatedModifier.result;
  });

  // Resolve Weapons
  resultVehicle.weapons = map(resultVehicle.weapons, weapon => {
    const calculatedModifier = reduceWeaponModifiers(modifiers, weapon);
    sortedModifiers.set(weapon.id, calculatedModifier);
    return calculatedModifier.result;
  });

  return {
    resultVehicle,
    modifiers: sortedModifiers,
  };
}

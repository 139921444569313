import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  // eslint-disable-next-line no-magic-numbers
  private _debounceTimeout: number = 400;
  // eslint-disable-next-line no-magic-numbers
  private _minCharacters: number = 3;

  public emailUnique = (fn: (email: string) => Promise<boolean>) => {
    let emailTimeout: any;
    let previousResponse: any;
    let previousValue: any;
    const stream = new Subject<any>();
    const validator = (control: AbstractControl): Promise<{ [key: string]: boolean } | null> => {
      if (emailTimeout) {
        clearTimeout(emailTimeout);
      }
      return new Promise((resolve, reject): void => {
        emailTimeout = setTimeout(() => {
          if (control.value === previousValue && typeof previousValue !== 'undefined') {
            if (previousResponse) {
              stream.next(null);
              resolve(null);
            } else {
              stream.next({ fieldUnique: true });
              resolve({
                fieldUnique: true,
              });
            }
          } else {
            fn(control.value).then(isUnique => {
              previousResponse = isUnique;
              previousValue = control.value;
              if (isUnique) {
                stream.next(null);
                resolve(null);
              } else {
                stream.next({ fieldUnique: true });
                resolve({ fieldUnique: true });
              }
            });
          }
        }, this._debounceTimeout);
      });
      // eslint-disable-next-line @typescript-eslint/semi,@typescript-eslint/member-delimiter-style
    };
    return {
      validator,
      stream$: stream.asObservable(),
    };
  };

  public usernameUnique = (fn: (username: string) => Promise<boolean>) => {
    let usernameTimeout: any;
    let previousResponse: any;
    let previousValue: any;
    const stream = new Subject<any>();
    const validator = (control: AbstractControl): Promise<{ [key: string]: boolean } | null> => {
      if (usernameTimeout) {
        clearTimeout(usernameTimeout);
      }
      const value: string = control.value;
      return new Promise((resolve, reject): void => {
        if (value.trim() === '' || value.trim().length < this._minCharacters) {
          stream.next({ minLength: true });
          resolve({ minlength: true });
          return;
        }
        usernameTimeout = setTimeout(() => {
          if (control.value === previousValue && typeof previousValue !== 'undefined') {
            if (previousResponse) {
              stream.next(null);
              resolve(null);
            } else {
              stream.next({ fieldUnique: true });
              resolve({
                fieldUnique: true,
              });
            }
          } else {
            fn(control.value).then(isUnique => {
              previousResponse = isUnique;
              previousValue = control.value;
              if (isUnique) {
                stream.next(null);
                resolve(null);
              } else {
                stream.next({ fieldUnique: true });
                resolve({ fieldUnique: true });
              }
            });
          }
        }, this._debounceTimeout);
      });
      // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    };
    return {
      validator,
      stream$: stream.asObservable(),
    };
  };
}

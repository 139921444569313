import {
  Component,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  ViewChild,
  Optional,
  AfterContentInit,
  OnDestroy,
  ElementRef,
  HostListener,
  HostBinding,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormControl,
  FormGroupDirective,
  AbstractControl,
} from '@angular/forms';
import { FormFieldComponent } from '../form-field/form-field.component';
import { Subject, takeUntil } from 'rxjs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'rpg-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements AfterContentInit, OnDestroy, ControlValueAccessor {
  @Input()
  public placeholder: string = '';
  @Input()
  public type: string = 'text';
  @Input()
  public inputControl: AbstractControl = new UntypedFormControl('');
  @Input()
  public min: number | undefined;
  @Input()
  public step: number | undefined;
  @Input()
  public prefixIconName: IconProp | null = null;
  @Input()
  public suffixIconName: IconProp | null = null;
  @HostBinding('class.small')
  @Input()
  public small: boolean = false;
  @Output()
  public suffixClick: EventEmitter<void> = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  public blur: EventEmitter<FocusEvent> = new EventEmitter();
  @Output()
  public enterPressed: EventEmitter<void> = new EventEmitter();

  @ViewChild(FormFieldComponent)
  public formField!: FormFieldComponent;
  @ViewChild(MatInput)
  public matInput!: MatInput;

  private _unsub: Subject<void> = new Subject<void>();

  @HostListener('rpgFocus')
  public onFocus(): void {
    this.focus();
  }

  constructor(@Optional() public fg: FormGroupDirective, private _el: ElementRef<HTMLElement>) {}

  ngAfterContentInit(): void {
    if (!!this.fg) {
      this.fg.ngSubmit.pipe(takeUntil(this._unsub)).subscribe(event => {
        this.formField._checkForChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this._unsub.next();
    this._unsub.complete();
  }

  public get control(): UntypedFormControl {
    return this.inputControl as UntypedFormControl;
  }

  public focus(): void {
    this.matInput.focus();
  }

  public onBlur(event: any): void {
    this.blur.emit(event);
    if (this.type === 'number') {
      // overwrites the value to handle casting to number
      // blame Angular: https://github.com/angular/angular/issues/13243
      this.writeValue(event.target.value);
    }
  }

  public onTouched: () => void = () => {};

  public writeValue(val: any): void {
    this.inputControl.setValue(this.type === 'number' ? parseFloat(val) : val);
  }

  public registerOnChange(fn: any): void {
    this.inputControl.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }
}

<ng-container *rxLet="state$; let state">
  <mat-stepper #stepper>
    <!-- ORDER MATTERS!!!!! -->
    <mat-step>
      <h2 class="create-content__header">
        {{ 'content-library.create-content-overlay.create-content-prompt' | transloco }}
      </h2>
      <div class="create-content__types">
        <button
          *ngFor="let set of dataTypes"
          [disabled]="!!state.disabledReason[set.type]"
          class="create-content__button"
          (click)="selectType(set.type)"
          type="button"
        >
          <fa-icon class="create-content__button-icon" [icon]="set.icon"></fa-icon>
          {{
            !!state.disabledReason[set.type] ? '' : (DataType.translationKey(set.type) | transloco)
          }}
          <p *ngIf="!!state.disabledReason[set.type]" class="create-content__disabled-reason">
            {{ state.disabledReason[set.type] ?? '' | transloco }}
          </p>
        </button>
      </div>
      <div class="create-content__actions">
        <button mat-raised-button color="accent" type="button" mat-dialog-close>
          {{ 'words.Cancel' | transloco }}
        </button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepContent>
        <h2 class="create-content__header">
          {{ 'character.nds.configuration.Character Sheet Customization Options' | transloco }}
        </h2>
        <div class="create-content__types">
          <rpg-nds-character-configuration #characterConfig></rpg-nds-character-configuration>
        </div>
        <div class="create-content__actions">
          <button mat-raised-button color="accent" type="button" (click)="backStep()">
            {{ 'words.Back' | transloco }}
          </button>
          <button
            class="confirm"
            mat-raised-button
            color="primary"
            type="button"
            (click)="createContent()"
          >
            {{ 'words.Create' | transloco }}
          </button>
        </div>
      </ng-template>
    </mat-step>
    <mat-step>
      <ng-template matStepContent>
        <h2 class="create-content__header">
          {{ 'content-library.create-content-overlay.enter-a-folder-name' | transloco }}
        </h2>
        <div class="create-content__types">
          <rpg-input
            id="folderNameInput"
            [placeholder]="'phrases.Folder Name' | transloco"
            [inputControl]="folderNameControl"
            (enterPressed)="createContent()"
          ></rpg-input>
        </div>
        <div class="create-content__actions">
          <button mat-raised-button color="accent" type="button" (click)="backStep()">
            {{ 'words.Back' | transloco }}
          </button>
          <button
            class="confirm"
            mat-raised-button
            color="primary"
            type="button"
            (click)="createContent()"
          >
            {{ 'words.Create' | transloco }}
          </button>
        </div>
      </ng-template>
    </mat-step>
    <mat-step>
      <ng-template matStepContent>
        <p>GAME CONFIG</p>
      </ng-template>
    </mat-step>
    <mat-step>
      <ng-template matStepContent>
        <h2 class="create-content__header">
          {{ 'vehicle.nds.configuration.Vehicle Sheet Customization Options' | transloco }}
        </h2>
        <div class="create-content__types">
          <rpg-nds-vehicle-configuration #vehicleConfig></rpg-nds-vehicle-configuration>
        </div>
        <div class="create-content__actions">
          <button mat-raised-button color="accent" type="button" (click)="backStep()">
            {{ 'words.Back' | transloco }}
          </button>
          <button
            class="confirm"
            mat-raised-button
            color="primary"
            type="button"
            (click)="createContent()"
          >
            {{ 'words.Create' | transloco }}
          </button>
        </div>
      </ng-template>
    </mat-step>
  </mat-stepper>
</ng-container>

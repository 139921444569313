import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DiceGroupType } from '@rpg/core/dice';
import {
  AnalyticsCharacterItemType,
  AnalyticsModifierAction,
  AnalyticsGameTableAdversaryAction,
} from './enums';
import { Subscription, filter } from 'rxjs';
import {
  trackPageview,
  LoadOptions as FathomLoadOptions,
  trackGoal,
  enableTrackingForMe,
  blockTrackingForMe,
  setSite,
} from 'fathom-client';
import { DOCUMENT } from '@angular/common';

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars
declare const gtag: Function;

@Injectable()
export class AnalyticsService {
  private fathomLoaded: boolean = false;
  private _routeTracker: Subscription | undefined;

  constructor(private _router: Router, @Inject(DOCUMENT) private document: Document) {}

  init(id: string, options: FathomLoadOptions) {
    this.createFathomScriptNode(id, options);
  }

  public enableRouteTracking(): void {
    this._routeTracker = this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.fathomLoaded && trackPageview();
        gtag('event', 'page_view', {
          page_path: (event as NavigationEnd).urlAfterRedirects,
        });
      });
  }

  public disableRouteTracking(): void {
    this._routeTracker?.unsubscribe();
  }

  public diceRollEvent(isLive: boolean): void {
    this.newEvent(`dice-roll-${isLive ? 'live' : 'test'}`, 'dice');
  }

  public diceRollTypeEvent(diceGroupType: DiceGroupType): void {
    this.newEvent(`dice-roll-group-${DiceGroupType.toString(diceGroupType)}`, 'dice');
  }

  public characterSkillToDiceRoller(diceGroupType: DiceGroupType): void {
    this.newEvent(`character-skill-to-dice-${DiceGroupType.toString(diceGroupType)}`, 'character');
  }

  public characterModifierAction(action: AnalyticsModifierAction): void {
    this.newEvent(`character-modifier-action-${action}`, 'character-edit');
  }

  public characterItemDeleted(itemType: AnalyticsCharacterItemType): void {
    this.newEvent(`character-item-deleted-${itemType}`, 'character-edit');
  }

  public characterItemAdded(itemType: AnalyticsCharacterItemType): void {
    this.newEvent(`character-item-added-${itemType}`, 'character-edit');
  }

  public characterItemUpdated(itemType: AnalyticsCharacterItemType): void {
    this.newEvent(`character-item-updated-${itemType}`, 'character-edit');
  }

  public gameTablePanelClick(panel: string): void {
    this.newEvent(`game-table-panel-view-${panel}`, 'gameTable');
  }

  public gameTableAdversaryAction(action: AnalyticsGameTableAdversaryAction): void {
    this.newEvent(`game-table-adversary-action-${action}`, 'gameTable');
  }

  public newEvent(action: string, category: string, label: string = ''): void {
    gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  }

  public pageViewEvent(url: string): void {
    gtag('event', 'page_view', {
      page_path: url,
    });
  }

  private createFathomScriptNode(siteId: string, opts: FathomLoadOptions) {
    const tracker = this.document.createElement('script');
    const firstScript =
      this.document.getElementsByTagName('script')[0] || this.document.querySelector('body');

    tracker.id = 'fathom-script';
    tracker.defer = true;
    tracker.setAttribute('data-site', siteId);
    tracker.src = opts && opts.url ? opts.url : 'https://cdn.usefathom.com/script.js';
    if (opts) {
      if (opts.auto !== undefined) tracker.setAttribute('data-auto', `${opts.auto}`);
      if (opts.honorDNT !== undefined) tracker.setAttribute('data-honor-dnt', `${opts.honorDNT}`);
      if (opts.canonical !== undefined) tracker.setAttribute('data-canonical', `${opts.canonical}`);
      if (opts.includedDomains) {
        this.checkDomainsAndWarn(opts.includedDomains);
        tracker.setAttribute('data-included-domains', opts.includedDomains.join(','));
      }
      if (opts.excludedDomains) {
        this.checkDomainsAndWarn(opts.excludedDomains);
        tracker.setAttribute('data-excluded-domains', opts.excludedDomains.join(','));
      }
      if (opts.spa) tracker.setAttribute('data-spa', opts.spa);
    }
    tracker.onload = this.flushQueue;
    if (!firstScript.parentNode) {
      console.error('Unable to create Fathom script node');
      return false;
    }
    firstScript.parentNode?.insertBefore(tracker, firstScript);
    return true;
  }

  /**
   * Loops through list of domains and warns if they start with
   * http, https, http://, etc... as this does not work with the
   * Fathom script.
   *
   * @param domains - List of domains to check
   */
  private checkDomainsAndWarn(domains: string[]) {
    const regex = /(https?)(?=:|\/|$)/; // matches http or https followed by
    // either a : or /
    domains.forEach(domain => {
      if (regex.exec(domain) !== null)
        console.warn(
          `The include domain ${domain} might fail to work as intended as it begins with a transfer protocol (http://, https://). Consider removing the protocol portion of the string.`
        );
    });
  }

  /**
   * Flushes the command queue.
   */
  private flushQueue() {
    this.fathomLoaded = true;
    window.__fathomClientQueue = window.__fathomClientQueue || [];
    window.__fathomClientQueue.forEach(command => {
      switch (command.type) {
        case 'trackPageview':
          trackPageview(command.opts);
          return;

        case 'trackGoal':
          trackGoal(command.code, command.cents);
          return;

        case 'enableTrackingForMe':
          enableTrackingForMe();
          return;

        case 'blockTrackingForMe':
          blockTrackingForMe();
          return;

        case 'setSite':
          setSite(command.id);
          return;

        default:
          return;
      }
    });
    window.__fathomClientQueue = [];
  }
}

import { FormArray, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterObligationData } from '@rpg/core/character';
import { ndsCharacterObligationEntryToFormGroup } from './nds-character-obligation-entry-to-form-group';

export function ndsCharacterObligationToFormGroup(
  obligation: NdsCharacterObligationData
): FormGroup<NdsCharacterObligationData> {
  return new FormGroup<NdsCharacterObligationData>({
    obligations: new FormArray(
      obligation.obligations.map(o => ndsCharacterObligationEntryToFormGroup(o))
    ),
  });
}

import {
  createId,
  createSlug,
  parseEnum,
  parseEnumArray,
  parseNumber,
  RpgClassValidators,
} from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';
import { NdsVehicleFireArc, NdsVehicleRangeBand } from '../enums';
import { NdsVehicleModifierFactory } from '../functions/nds-vehicle-modifier-factory';
import { NdsVehicleModifierValidatorFactory } from '../functions/nds-vehicle-modifier-validator-factory';
import { NdsVehicleModifierData } from './modifiers/nds-vehicle-modifier-data';

export class NdsVehicleWeaponData {
  public id: string = '';
  public name: string = '';
  public lookupName: string = '';
  public description: string = '';
  public skillName: string = '';
  public fireArc: NdsVehicleFireArc[] = [];
  public damage: number = 0;
  public critRating: number = 0;
  public range: NdsVehicleRangeBand = NdsVehicleRangeBand.Medium;
  public equipped: boolean = true;
  public extraDice: DiceType[] = [];
  public modifiers: NdsVehicleModifierData[] = [];

  constructor(params?: Partial<NdsVehicleWeaponData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.description = params.description ?? this.description;
      this.skillName = params.skillName ?? this.skillName;
      this.fireArc = parseEnumArray(NdsVehicleFireArc, params.fireArc);
      this.damage = parseNumber(params.damage, this.damage);
      this.critRating = parseNumber(params.critRating, this.critRating);
      this.range = parseEnum(NdsVehicleRangeBand, params.range, this.range);
      this.equipped = params.equipped ?? this.equipped;
      this.extraDice = parseEnumArray(DiceType, params.extraDice);
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(m => NdsVehicleModifierFactory(m))
        : this.modifiers;
    }

    // Enforce Constraints
    if (!this.id) {
      this.id = createId();
    }
    if (!this.lookupName) {
      this.lookupName = createSlug(this.name ?? '');
    }
  }

  public static validate(input: NdsVehicleWeaponData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id));
    errors.push(...RpgClassValidators.string(input.name));
    errors.push(...RpgClassValidators.string(input.description, { allowEmpty: true }));
    errors.push(...RpgClassValidators.string(input.skillName, { allowEmpty: true }));
    errors.push(...RpgClassValidators.number(input.damage));
    errors.push(...RpgClassValidators.string(input.lookupName));
    errors.push(...RpgClassValidators.number(input.critRating));
    errors.push(...RpgClassValidators.boolean(input.equipped));
    errors.push(
      ...RpgClassValidators.enum(input.fireArc, NdsVehicleFireArc, {
        enumName: 'Fire Arc',
        isArray: true,
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.range, NdsVehicleRangeBand, {
        enumName: 'Weapon Range',
      })
    );
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleModifierValidatorFactory(next)],
        []
      )
    );
    errors.push(
      ...RpgClassValidators.enum(input.extraDice, DiceType, {
        enumName: 'Extra Dice',
        isArray: true,
      })
    );
    return errors;
  }
}

export enum ThemeOption {
  Light = '[theme option] light',
  Dark = '[theme option] dark',
}

export namespace ThemeOption {
  export const defaultValue: ThemeOption = ThemeOption.Dark;

  export const members: ThemeOption[] = [ThemeOption.Light, ThemeOption.Dark];

  export function translationKey(type: ThemeOption): string {
    return `enums.user.theme-option.${type}`;
  }

  export function toString(option: ThemeOption): string {
    switch (option) {
      case ThemeOption.Dark:
        return 'Dark Theme';
      case ThemeOption.Light:
        return 'Light Theme';
      default:
        return '';
    }
  }
}

import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { BaseContentConfiguration } from '@rpg/core/base';

export function baseContentConfigurationToFormGroup(
  config: BaseContentConfiguration
): FormGroup<BaseContentConfiguration> {
  return new FormGroup<BaseContentConfiguration>({
    gameTheme: new FormControl(config.gameTheme, [Validators.required]),
    diceTheme: new FormControl(config.diceTheme, [Validators.required]),
  });
}

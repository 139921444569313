import { State, Selector, StateContext, Action } from '@ngxs/store';
import { CookieService, ImmutableSelector, ImmutableContext } from '@rpg/ngx/core';
import { Injectable } from '@angular/core';
import { FeatureFlag } from '@rpg/core/base';
import { FlagsActions } from './flags.actions';

export type FlagsStateModel = Record<FeatureFlag, boolean>;

@State<FlagsStateModel>({
  name: 'flags',
  defaults: FeatureFlag.getDefaults(),
})
@Injectable()
export class FlagsState {
  constructor(private _cookieService: CookieService) {}

  @Selector([FlagsState])
  @ImmutableSelector()
  public static flags(flags: FlagsStateModel): Record<FeatureFlag, boolean> {
    return flags;
  }

  @Selector([FlagsState])
  @ImmutableSelector()
  public static checkFlag(flags: FlagsStateModel) {
    return (flag: FeatureFlag) => {
      return flags[flag];
    };
  }

  @Action(FlagsActions.SetFlag)
  @ImmutableContext()
  public setToken(
    { setState }: StateContext<FlagsStateModel>,
    { flag, status }: FlagsActions.SetFlag
  ): void {
    setState((state: FlagsStateModel) => {
      state[flag] = status;
      return state;
    });
  }
}

import { Injectable, ApplicationRef } from '@angular/core';
import {
  HttpInterceptor,
  HttpParams,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { firstValueFrom, Observable, of, tap, filter } from 'rxjs';
import { Logger } from '@rpg/core/base';

export interface TransferHttpResponse {
  body?: any | null;
  headers?: { [k: string]: string[] };
  status?: number;
  statusText?: string;
  url?: string;
}

function getHeadersMap(headers: HttpHeaders): { [name: string]: string[] } {
  const headersMap: { [name: string]: string[] } = {};
  for (const key of headers.keys()) {
    headersMap[key] = headers.getAll(key) ?? [];
  }
  return headersMap;
}

@Injectable()
export class TransferCacheInterceptor implements HttpInterceptor {
  private isCacheActive: boolean = true;

  constructor(private _appRef: ApplicationRef, private _transferState: TransferState) {
    firstValueFrom(this._appRef.isStable.pipe(filter((isStable: boolean) => isStable))).then(() => {
      this.isCacheActive = false;
    });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    Logger.log('request to intercept', req.method, req);
    // Stop using the cache if there is a mutating call.
    if (req.method !== 'GET' && req.method !== 'HEAD') {
      Logger.log('disabling cache');
      this.isCacheActive = false;
      this.invalidateCacheEntry(req.url);
    }
    Logger.log('is cache active', this.isCacheActive);

    if (!this.isCacheActive) {
      // Cache is no longer active. Pass the request through.
      return next.handle(req);
    }

    const storeKey = this.makeCacheKey(req.method, req.url, req.params);
    Logger.log('hasKey', storeKey, this._transferState.hasKey(storeKey));

    if (this._transferState.hasKey(storeKey)) {
      // Request found in cache. Respond using it.
      const response = this._transferState.get<TransferHttpResponse>(
        storeKey,
        {} as TransferHttpResponse
      );
      Logger.log('using cached data', response);
      return of(
        new HttpResponse<any>({
          body: response.body,
          headers: new HttpHeaders(response.headers),
          status: response.status,
          statusText: response.statusText,
          url: response.url,
        })
      );
    } else {
      // Request not found in cache. Make the request and cache it.
      const httpEvent = next.handle(req);
      return httpEvent.pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            Logger.log('caching data', storeKey, event.body);
            this._transferState.set<TransferHttpResponse>(storeKey, {
              body: event.body,
              headers: getHeadersMap(event.headers),
              status: event.status,
              statusText: event.statusText,
              url: event.url ?? undefined,
            });
          }
        })
      );
    }
  }

  private invalidateCacheEntry(url: string): void {
    Object.keys(this._transferState['store']).forEach(key =>
      key.includes(url) ? this._transferState.remove(makeStateKey(key)) : null
    );
  }

  private makeCacheKey(method: string, url: string, params: HttpParams) {
    // make the params encoded same as a url so it's easy to identify
    const encodedParams = params
      .keys()
      .sort()
      .map(k => `${k}=${params.get(k)}`)
      .join('&');
    const key = (method === 'GET' ? 'G.' : 'H.') + url + '?' + encodedParams;
    return makeStateKey<TransferHttpResponse>(key);
  }
}

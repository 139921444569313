import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SpinnerOverlayOptions {
  message: string;
}

@Component({
  selector: 'rpg-spinner-overlay',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerOverlayComponent {
  constructor(
    public dialogRef: MatDialogRef<SpinnerOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public options: SpinnerOverlayOptions
  ) {}
}

import { ObjectId } from 'bson';
import {
  RulesSystem,
  MongoDoc,
  normalizeString,
  parseEnum,
  parseObjectId,
  RpgClassValidators,
  BaseContentConfiguration,
  parseNumber,
  parseBoolean,
} from '@rpg/core/base';
import { ImportData } from '@rpg/core/imports';
import { CharacterOptions } from './character-options';
import { CharacterType } from '../enums';

export const CHARACTER_MAX_IMAGE_LENGTH = 2000;
export const UNKNOWN_CHARACTER_NAME = '?????';

export class BaseCharacter extends MongoDoc {
  public name: string = '';
  public lookupName: string = '';
  public image: string = '';
  public notes: string = '';
  public privateNotes: string = '';
  public rulesSystem: RulesSystem = RulesSystem.Generic;
  public characterType: CharacterType = CharacterType.Player;
  public configuration: BaseContentConfiguration = new BaseContentConfiguration();
  public userId!: ObjectId;
  public userFavorite: boolean = false;
  public clonedCount: number = 0;
  public clonedFromCharacterId: ObjectId | null = null;
  public importData: ImportData | null = null;
  public options: CharacterOptions = new CharacterOptions();
  public tempClone: boolean = false;

  constructor(params?: Partial<BaseCharacter>) {
    super(params);
    if (!!params) {
      this.name = params.name ?? this.name;
      this.lookupName = params.lookupName ?? this.lookupName;
      this.image = params.image ?? this.image;
      this.notes = params.notes ?? this.notes;
      this.privateNotes = params.privateNotes ?? this.privateNotes;
      this.rulesSystem = parseEnum(RulesSystem, params.rulesSystem, this.rulesSystem);
      this.characterType = parseEnum(CharacterType, params.characterType, this.characterType);
      this.configuration = !!params.configuration
        ? new BaseContentConfiguration(params.configuration)
        : this.configuration;
      this.userId = parseObjectId(params.userId, this.userId);
      this.userFavorite = parseBoolean(params.userFavorite, this.userFavorite);
      this.clonedCount = parseNumber(params.clonedCount, this.clonedCount);
      this.clonedFromCharacterId = parseObjectId(params.clonedFromCharacterId);
      this.importData = !!params.importData ? new ImportData(params.importData) : this.importData;
      this.options = !!params.options ? new CharacterOptions(params.options) : this.options;
      this.tempClone = parseBoolean(params.tempClone, this.tempClone);
    }
    // Enforce Constraints
    if (this.image.length > CHARACTER_MAX_IMAGE_LENGTH) {
      this.image = '';
    }
    if (!this.lookupName) {
      this.lookupName = normalizeString(this.name);
    }
  }

  public static validate(input: BaseCharacter): string[] {
    const errors: string[] = [];
    errors.push(
      ...RpgClassValidators.string(input.name, {
        fieldName: 'Character Name',
      })
    );
    errors.push(...RpgClassValidators.string(input.lookupName, { fieldName: 'Lookup Name' }));
    errors.push(
      ...RpgClassValidators.string(input.image, {
        allowEmpty: true,
        maxLength: CHARACTER_MAX_IMAGE_LENGTH,
        fieldName: 'Character Image',
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.notes, { allowEmpty: true, fieldName: 'Notes' })
    );
    errors.push(
      ...RpgClassValidators.string(input.privateNotes, {
        allowEmpty: true,
        fieldName: 'Private Notes',
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.rulesSystem, RulesSystem, {
        enumName: 'Rules System',
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.characterType, CharacterType, {
        enumName: 'Character Type',
      })
    );
    errors.push(...BaseContentConfiguration.validate(input.configuration));
    errors.push(...RpgClassValidators.boolean(input.userFavorite, { fieldName: 'User Favorite' }));
    errors.push(
      ...RpgClassValidators.number(input.clonedCount, { min: 0, fieldName: 'Cloned Count' })
    );
    return errors;
  }
}

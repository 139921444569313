export enum NdsVehicleDetail {
  Background = '[nds vehicle detail] background',
  CargoHold = '[nds vehicle detail] cargo hold',
  Model = '[nds vehicle detail] model',
  Manufacturer = '[nds vehicle detail] manufacturer',
  Crew = '[nds vehicle detail] crew',
  Passengers = '[nds vehicle detail] passengers',
  Consumables = '[nds vehicle detail] consumables',
  Engine = '[nds vehicle detail] engine',
  Sensors = '[nds vehicle detail] sensors',
  NotableFeatures = '[nds vehicle detail] notable features',
}

export namespace NdsVehicleDetail {
  export function translationKey(type: NdsVehicleDetail): string {
    return `NdsVehicleDetail Key`;
  }

  export const members: NdsVehicleDetail[] = [
    NdsVehicleDetail.Background,
    NdsVehicleDetail.CargoHold,
    NdsVehicleDetail.Model,
    NdsVehicleDetail.Manufacturer,
    NdsVehicleDetail.Crew,
    NdsVehicleDetail.Passengers,
    NdsVehicleDetail.Consumables,
    NdsVehicleDetail.Engine,
    NdsVehicleDetail.Sensors,
    NdsVehicleDetail.NotableFeatures,
  ];
}

import { StoryPointsType } from '../enums';

export function sortStoryPoints(points: StoryPointsType[]): StoryPointsType[] {
  return [...points].sort((a, b) => {
    if (a === b) {
      return 0;
    } else if (a === StoryPointsType.Player) {
      return -1;
    } else if (b === StoryPointsType.Player) {
      return 1;
    } else {
      return 0;
    }
  });
}

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription, Observable, Subject } from 'rxjs';

interface RegisteredPaginator {
  id: number;
  paginator: MatPaginator;
  watchers: Subscription;
}

export interface PaginatorEvent {
  event: PageEvent;
  ref: MatPaginator | null;
}

export class PaginatorManager {
  private _pageSize: number = 0;
  private _pageIndex: number = 0;
  private _length: number = 0;
  private _lastId: number = 0;
  private _paginators: RegisteredPaginator[] = [];
  private _page: Subject<PaginatorEvent> = new Subject<PaginatorEvent>();

  public page: Observable<PaginatorEvent> = this._page.asObservable();

  constructor(pageSize: number, pageIndex: number, length: number = 0) {
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.length = length;
  }

  /**
   * Get and Set the current pageSize for the paginators
   *
   * @type {number}
   */
  public get pageSize(): number {
    return this._pageSize;
  }
  public set pageSize(l: number) {
    this._pageSize = l;
    this._paginators.forEach(rp => (rp.paginator.pageSize = l));
  }

  /**
   * Get and Set the current pageIndex for the paginators
   * This number is 0-based to match the MatPaginator specs
   *
   * @type {number}
   */
  public get pageIndex(): number {
    return this._pageIndex;
  }
  public set pageIndex(p: number) {
    this._pageIndex = p;
    this._paginators.forEach(rp => (rp.paginator.pageIndex = p));
  }

  /**
   * Get and Set the current length of documents for the paginators
   *
   * @type {number}
   */
  public get length(): number {
    return this._length;
  }
  public set length(l: number) {
    this._length = l;
    this._paginators.forEach(rp => (rp.paginator.length = l));
  }

  public register(paginator: MatPaginator): void {
    const pIndex = this._paginators.findIndex(rp => rp.paginator === paginator);
    if (pIndex === -1) {
      paginator.pageSize = this.pageSize;
      paginator.pageIndex = this.pageIndex;
      paginator.length = this.length;
      const registered: RegisteredPaginator = {
        id: this._lastId++,
        paginator: paginator,
        watchers: new Subscription(),
      };
      registered.watchers.add(
        paginator.page.subscribe(pageEvent => this._onPage(pageEvent, registered.id))
      );
      this._paginators.push(registered);
    }
  }

  public unregister(paginator: MatPaginator): void {
    const pIndex = this._paginators.findIndex(rp => rp.paginator === paginator);
    if (pIndex !== -1) {
      this._paginators[pIndex].watchers.unsubscribe();
      this._paginators = [
        ...this._paginators.slice(0, pIndex),
        ...this._paginators.slice(pIndex + 1),
      ];
    }
  }

  public clear(): void {
    this._paginators.forEach(p => p.watchers.unsubscribe());
    this._paginators = [];
  }

  public firstPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = 0;
    this._emitPageEvent(previousPageIndex);
  }

  public getNumberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }
    return Math.ceil(this.length / this.pageSize);
  }

  public hasNextPage(): boolean {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }

  public hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  public lastPage(): void {
    if (!this.hasNextPage()) {
      return;
    }
    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.getNumberOfPages() - 1;
    this._emitPageEvent(previousPageIndex);
  }

  public nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }
    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex + 1;
    this._emitPageEvent(previousPageIndex);
  }

  public previousPage(): void {
    if (!this.hasPreviousPage()) {
      const previousPageIndex = this.pageIndex;
      this.pageIndex = this.pageIndex - 1;
      this._emitPageEvent(previousPageIndex);
    }
  }

  private _onPage(event: PageEvent, id: number): void {
    const previousPageIndex = this.pageIndex;
    this._length = event.length;
    this._pageIndex = event.pageIndex;
    this._pageSize = event.pageSize;
    this._paginators.forEach(rp => {
      if (rp.id !== id) {
        rp.paginator.length = this.length;
        rp.paginator.pageSize = this.pageSize;
        rp.paginator.pageIndex = this.pageIndex;
      }
    });
    this._emitPageEvent(previousPageIndex, this._paginators.find(rp => rp.id === id)?.paginator);
  }

  private _emitPageEvent(previousPageIndex: number, ref?: MatPaginator): void {
    this._page.next({
      event: {
        length: this.length,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        previousPageIndex,
      },
      ref: !!ref ? ref : null,
    });
  }
}

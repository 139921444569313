import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import interact from 'interactjs';

@Directive({
  selector: '[rpgDraggable]',
})
export class DraggableDirective implements OnInit, AfterViewInit {
  @Input()
  dragData: any;

  @Input()
  dragOptions: any;

  @Output()
  draggableClick = new EventEmitter();

  private currentlyDragged = false;

  constructor(private element: ElementRef<HTMLElement>) {}

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    if (!this.currentlyDragged) {
      this.draggableClick.emit();
    }
  }

  ngOnInit(): void {
    const options = !!this.dragOptions ? { ...this.dragOptions } : {};
    interact(this.element.nativeElement)
      .draggable(options)
      .on('dragmove', event => {
        const target = event.target;
        const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
        const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

        target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);

        target.classList.add('getting-dragged');
        this.currentlyDragged = true;
        (window as any).dragData = this.dragData;
      })
      .on('dragend', event => {
        event.target.style.transform = 'none';
        event.target.removeAttribute('data-x');
        event.target.removeAttribute('data-y');
        event.target.classList.remove('getting-dragged');
        setTimeout(() => {
          (window as any).dragData = null;
          this.currentlyDragged = false;
        });
      });
  }

  ngAfterViewInit(): void {
    this.element.nativeElement.style['touchAction'] = 'none';
  }
}

import { Constraints, createId, parseNumber, RpgClassValidators } from '@rpg/core/base';

export const NDS_VEHICLE_CRIT_MIN_DIFFICULTY = 0;
export const NDS_VEHICLE_CRIT_MAX_DIFFICULTY = 5;
export const NDS_VEHICLE_CRIT_MIN_SEVERITY = 0;
export const NDS_VEHICLE_CRIT_MAX_SEVERITY = 999;

export class NdsVehicleCritData {
  public id: string = '';
  public name: string = '';
  public severity: number = 0;
  public description: string = '';
  public difficulty: number = 0;
  public healed: boolean = false;

  constructor(params?: Partial<NdsVehicleCritData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.severity = parseNumber(params.severity, this.severity);
      this.description = params.description || this.description;
      this.difficulty = parseNumber(params.difficulty, this.difficulty);
      this.healed = params.healed || this.healed;
    }
    // Enforce Constraints
    if (!this.id) {
      this.id = createId();
    }
    this.difficulty = Constraints.withinRange(
      this.difficulty,
      NDS_VEHICLE_CRIT_MIN_DIFFICULTY,
      NDS_VEHICLE_CRIT_MAX_DIFFICULTY
    );
    this.severity = Constraints.withinRange(
      this.severity,
      NDS_VEHICLE_CRIT_MIN_SEVERITY,
      NDS_VEHICLE_CRIT_MAX_SEVERITY
    );
  }

  public static validate(input: NdsVehicleCritData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
      })
    );
    errors.push(
      ...RpgClassValidators.number(input.difficulty, {
        min: NDS_VEHICLE_CRIT_MIN_DIFFICULTY,
        max: NDS_VEHICLE_CRIT_MAX_DIFFICULTY,
      })
    );
    errors.push(...RpgClassValidators.string(input.name));
    errors.push(
      ...RpgClassValidators.number(input.severity, {
        min: NDS_VEHICLE_CRIT_MIN_SEVERITY,
        max: NDS_VEHICLE_CRIT_MAX_SEVERITY,
      })
    );
    return errors;
  }
}

import { StoryPointsType } from './story-points-type';

export enum StoryPointsStyle {
  // Player Styles
  Player_Eote = '[StoryPointsStyle] player Eote',
  Player_Genesys = '[StoryPointsStyle] player Genesys',
  Player_RPGSessions = '[StoryPointsStyle] player RPG Sessions',
  Player_Styled1 = '[StoryPointsStyle] player Styled 1',
  Player_Styled2 = '[StoryPointsStyle] player Styled 2',
  Player_Styled3 = '[StoryPointsStyle] player Styled 3',

  // GM Styles
  GM_Eote = '[StoryPointsStyle] gm Eote',
  GM_Genesys = '[StoryPointsStyle] gm Genesys',
  GM_RPGSessions = '[StoryPointsStyle] gm RPG Sessions',
  GM_Styled1 = '[StoryPointsStyle] gm Styled 1',
  GM_Styled2 = '[StoryPointsStyle] gm Styled 2',
  GM_Styled3 = '[StoryPointsStyle] gm Styled 3',
}

export namespace StoryPointsStyle {
  export function byStoryPointsType(type: StoryPointsType): StoryPointsStyle[] {
    switch (type) {
      case StoryPointsType.GM:
        return StoryPointsStyle.gmStyles;
      case StoryPointsType.Player:
        return StoryPointsStyle.playerStyles;
      default:
        return [];
    }
  }

  export function defaults(): Record<StoryPointsType, StoryPointsStyle> {
    return {
      [StoryPointsType.Player]: StoryPointsStyle.defaultStyle(StoryPointsType.Player),
      [StoryPointsType.GM]: StoryPointsStyle.defaultStyle(StoryPointsType.GM),
    };
  }

  export function defaultStyle(type: StoryPointsType): StoryPointsStyle {
    switch (type) {
      case StoryPointsType.GM:
        return StoryPointsStyle.GM_RPGSessions;
      case StoryPointsType.Player:
        return StoryPointsStyle.Player_RPGSessions;
      default:
        return StoryPointsStyle.GM_RPGSessions;
    }
  }

  export function toDiscordName(type: StoryPointsStyle): string {
    const className = StoryPointsStyle.toClass(type);
    return className.replace(/-/g, '');
  }

  export function toClass(type: StoryPointsStyle): string {
    switch (type) {
      case StoryPointsStyle.Player_Eote:
        return 'story-points__token--player-eote';
      case StoryPointsStyle.Player_Genesys:
        return 'story-points__token--player-genesys';
      case StoryPointsStyle.Player_RPGSessions:
        return 'story-points__token--player-rpg';
      case StoryPointsStyle.Player_Styled1:
        return 'story-points__token--player-styled1';
      case StoryPointsStyle.Player_Styled2:
        return 'story-points__token--player-styled2';
      case StoryPointsStyle.Player_Styled3:
        return 'story-points__token--player-styled3';
      case StoryPointsStyle.GM_Eote:
        return 'story-points__token--gm-eote';
      case StoryPointsStyle.GM_Genesys:
        return 'story-points__token--gm-genesys';
      case StoryPointsStyle.GM_RPGSessions:
        return 'story-points__token--gm-rpg';
      case StoryPointsStyle.GM_Styled1:
        return 'story-points__token--gm-styled1';
      case StoryPointsStyle.GM_Styled2:
        return 'story-points__token--gm-styled2';
      case StoryPointsStyle.GM_Styled3:
        return 'story-points__token--gm-styled3';
      default:
        return '';
    }
  }

  export const playerStyles: StoryPointsStyle[] = [
    StoryPointsStyle.Player_RPGSessions,
    StoryPointsStyle.Player_Eote,
    StoryPointsStyle.Player_Genesys,
    StoryPointsStyle.Player_Styled1,
    StoryPointsStyle.Player_Styled2,
    StoryPointsStyle.Player_Styled3,
  ];

  export const gmStyles: StoryPointsStyle[] = [
    StoryPointsStyle.GM_RPGSessions,
    StoryPointsStyle.GM_Eote,
    StoryPointsStyle.GM_Genesys,
    StoryPointsStyle.GM_Styled1,
    StoryPointsStyle.GM_Styled2,
    StoryPointsStyle.GM_Styled3,
  ];

  export const freeStyles: StoryPointsStyle[] = [
    StoryPointsStyle.Player_RPGSessions,
    StoryPointsStyle.Player_Eote,
    StoryPointsStyle.Player_Genesys,
    StoryPointsStyle.GM_RPGSessions,
    StoryPointsStyle.GM_Eote,
    StoryPointsStyle.GM_Genesys,
  ];

  export const members: StoryPointsStyle[] = [
    ...StoryPointsStyle.playerStyles,
    ...StoryPointsStyle.gmStyles,
  ];
}

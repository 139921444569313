export enum GameTableNoteActionRefType {
  ImageUpload = '[NoteActionRefType] Image Upload',
  Comment = '[NoteActionRefType] Comment',
}

export namespace GameTableNoteActionRefType {
  export const members: GameTableNoteActionRefType[] = [
    GameTableNoteActionRefType.ImageUpload,
    GameTableNoteActionRefType.Comment,
  ];
}

import { Subscription, BehaviorSubject, scan, debounceTime, filter } from 'rxjs';

export function debounceAndTrack<T = any>(next: (value: T[]) => any, time: number = 400) {
  let sub: Subscription;
  let obs: BehaviorSubject<T[]>;
  function getCleanSubject() {
    sub?.unsubscribe();
    obs = new BehaviorSubject<T[]>([]);
    sub = obs
      .pipe(
        scan((acc, next) => [...acc, ...next]),
        debounceTime(time),
        filter(val => val.length > 0)
      )
      .subscribe(val => {
        next(val);
        getCleanSubject();
      });
    return [];
  }
  getCleanSubject();

  return {
    next: (value: T | T[]) => {
      obs?.next(Array.isArray(value) ? value : [value]);
    },
    complete: () => sub?.unsubscribe(),
  };
}

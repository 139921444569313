<ng-container *rxLet="state$; let state">
  <img class="attribute-triple__image" [src]="state.attrImage" />
  <label class="attribute-triple__title">
    {{ state.title }}
  </label>
  <ng-container *ngIf="state.editMode; else displayMode">
    <span
      class="attribute-triple__input left"
      rpgContentEditableNumber
      contentEditable
      [formControl]="controls[0]"
      (blur)="checkValue(controls[0])"
      >{{ controls[0].value }}</span
    >
    <span
      class="attribute-triple__input center"
      rpgContentEditableNumber
      contentEditable
      [formControl]="controls[1]"
      (blur)="checkValue(controls[1])"
      >{{ controls[1].value }}</span
    >
    <span
      class="attribute-triple__input right"
      rpgContentEditableNumber
      contentEditable
      [formControl]="controls[2]"
      (blur)="checkValue(controls[2])"
      >{{ controls[2].value }}</span
    >
  </ng-container>
  <ng-template #displayMode>
    <span class="attribute-triple__input left">
      {{ state.resolvedValues[0] | zeroDash }} <ng-content select=".slot-1"></ng-content>
    </span>
    <span class="attribute-triple__input center">
      {{ state.resolvedValues[1] | zeroDash }} <ng-content select=".slot-2"></ng-content>
    </span>
    <span class="attribute-triple__input right">
      {{ state.resolvedValues[2] | zeroDash }} <ng-content select=".slot-3"></ng-content>
    </span>
  </ng-template>
  <div class="attribute-triple__label-container">
    <label class="attribute-triple__label">
      <ng-container *ngFor="let label of state.labels; let i = index">
        {{ i !== 0 ? '/ ' : '' }}{{ label }}
      </ng-container>
    </label>
  </div>
</ng-container>

import { RulesSystem, DiceTheme } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';

export interface DiceWheelRow {
  radius: number;
  types: DiceType[];
  theme: DiceTheme;
}

export interface NdsOptions {
  includeForceDice: boolean;
}

export type WheelOptions = NdsOptions;

export function getDiceWheelConfig(rulesSystem: RulesSystem, diceTheme: DiceTheme): DiceWheelRow[];
export function getDiceWheelConfig(
  rulesSystem: RulesSystem.NarrativeDiceSystem,
  diceTheme: DiceTheme,
  options?: NdsOptions
): DiceWheelRow[];
export function getDiceWheelConfig(
  rulesSystem: RulesSystem,
  diceTheme: DiceTheme,
  options?: WheelOptions
): DiceWheelRow[] {
  switch (rulesSystem) {
    case RulesSystem.NarrativeDiceSystem:
      return options?.includeForceDice ? ndsWithForce(diceTheme) : ndsWithoutForce(diceTheme);
    default:
      return [];
  }
}

function ndsWithForce(theme: DiceTheme): DiceWheelRow[] {
  return [
    {
      radius: 66,
      types: [
        DiceType.NdsProficiency,
        DiceType.NdsAbility,
        DiceType.NdsBoost,
        DiceType.NdsChallenge,
        DiceType.NdsDifficulty,
        DiceType.NdsSetback,
        DiceType.NdsRemoveSetback,
        DiceType.NdsForce,
        DiceType.NdsPositiveUpgrade,
      ],
      theme,
    },
    {
      radius: 94,
      types: [
        DiceType.NdsTriumph,
        DiceType.NdsSuccess,
        DiceType.NdsAdvantage,
        DiceType.NdsDespair,
        DiceType.NdsFailure,
        DiceType.NdsThreat,
        DiceType.NdsLightSide,
        DiceType.NdsDarkSide,
        DiceType.NdsLightDarkSplit,
        DiceType.NdsNegativeUpgrade,
      ],
      theme,
    },
  ];
}

function ndsWithoutForce(theme: DiceTheme): DiceWheelRow[] {
  return [
    {
      radius: 60,
      types: [
        DiceType.NdsProficiency,
        DiceType.NdsAbility,
        DiceType.NdsBoost,
        DiceType.NdsChallenge,
        DiceType.NdsDifficulty,
        DiceType.NdsSetback,
        DiceType.NdsRemoveSetback,
      ],
      theme,
    },
    {
      radius: 88,
      types: [
        DiceType.NdsTriumph,
        DiceType.NdsSuccess,
        DiceType.NdsAdvantage,
        DiceType.NdsDespair,
        DiceType.NdsFailure,
        DiceType.NdsThreat,
        DiceType.NdsPositiveUpgrade,
        DiceType.NdsNegativeUpgrade,
      ],
      theme,
    },
  ];
}

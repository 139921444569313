export enum NdsVehicleAttribute {
  Defense = '[nds vehicle attribute] defense',
  DefenseFore = '[nds vehicle attribute] defense fore',
  DefensePort = '[nds vehicle attribute] defense port',
  DefenseStarboard = '[nds vehicle attribute] defense starboard',
  DefenseAft = '[nds vehicle attribute] defense aft',
  Armor = '[nds vehicle attribute] armor',
  HullTrauma = '[nds vehicle attribute] hull trauma',
  HullTraumaCurrent = '[nds vehicle attribute] hull trauma current',
  HullTraumaThreshold = '[nds vehicle attribute] hull trauma threshold',
  SystemStrain = '[nds vehicle attribute] system strain',
  SystemStrainCurrent = '[nds vehicle attribute] system strain current',
  SystemStrainThreshold = '[nds vehicle attribute] system strain threshold',
  HardPoints = '[nds vehicle attribute] hard points',
  HardPointsCapacity = '[nds vehicle attribute] hard points capacity',
  HardPointsUsed = '[nds vehicle attribute] hard points used',
  Encumbrance = '[nds vehicle attribute] encumbrance',
  Price = '[nds vehicle attribute] price',
  Rarity = '[nds vehicle attribute] rarity',
}

export namespace NdsVehicleAttribute {
  export function translationKey(type: NdsVehicleAttribute): string {
    return `enums.vehicle.nds.attribute.${type}`;
  }

  export const members: NdsVehicleAttribute[] = [
    NdsVehicleAttribute.Defense,
    NdsVehicleAttribute.DefenseFore,
    NdsVehicleAttribute.DefensePort,
    NdsVehicleAttribute.DefenseStarboard,
    NdsVehicleAttribute.DefenseAft,
    NdsVehicleAttribute.Armor,
    NdsVehicleAttribute.HullTrauma,
    NdsVehicleAttribute.HullTraumaCurrent,
    NdsVehicleAttribute.HullTraumaThreshold,
    NdsVehicleAttribute.SystemStrain,
    NdsVehicleAttribute.SystemStrainCurrent,
    NdsVehicleAttribute.SystemStrainThreshold,
    NdsVehicleAttribute.HardPoints,
    NdsVehicleAttribute.HardPointsCapacity,
    NdsVehicleAttribute.HardPointsUsed,
    NdsVehicleAttribute.Encumbrance,
    NdsVehicleAttribute.Price,
    NdsVehicleAttribute.Rarity,
  ];
}

import { parseDate, parseEnum } from '@rpg/core/base';
import { ImportSource } from '../enums';

export class ImportData {
  public imported: boolean = false;
  public importDate: Date | null = null;
  public importSource: ImportSource | null = null;

  constructor(params?: Partial<ImportData>) {
    if (!!params) {
      this.imported = params.imported ?? this.imported;
      this.importDate = parseDate(params.importDate);
      this.importSource = parseEnum(ImportSource, params.importSource);
    }
  }
}

<rpg-form-field>
  <fa-icon matPrefix *ngIf="prefixIconName" [icon]="prefixIconName"></fa-icon>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    [min]="min"
    [step]="step"
    [placeholder]="placeholder"
    [type]="type"
    [formControl]="control"
    (blur)="onBlur($event)"
    (keyup.enter)="enterPressed.emit()"
  />
  <mat-error [rpgFormFieldError]="inputControl"></mat-error>
  <fa-icon
    matSuffix
    *ngIf="suffixIconName"
    (click)="suffixClick.emit()"
    [icon]="suffixIconName"
  ></fa-icon>
</rpg-form-field>

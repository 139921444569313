import { State, StateContext, Action } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { RouterActions } from './router.actions';

export interface RouterStateModel {}

@State<RouterStateModel>({
  name: 'router',
  defaults: {},
})
@Injectable()
export class RouterState {
  constructor(private router: Router, private zone: NgZone) {}

  @Action(RouterActions.Navigate)
  navigate(_: StateContext<RouterStateModel>, { route }: RouterActions.Navigate) {
    // Actions are executed outside of the zone by default, navigation needs to be in zone however
    this.zone.run(() => {
      if (typeof route === 'string') {
        this.router.navigateByUrl(route);
      } else if (Array.isArray(route)) {
        this.router.navigate(route);
      } else {
        console.error('INVALID RouterActions.Navigate() param', route);
      }
    });
  }

  @Action(RouterActions.InitialNavigation)
  initialNavigation() {
    this.zone.run(() => {
      this.router.initialNavigation();
    });
  }
}

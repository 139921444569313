import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, Input } from '@angular/core';
import { unreachable } from '@rpg/core/base';
import { ThemeOption } from '@rpg/core/user';
import { ThemeService } from '@rpg/ngx/core';
import { Subscription } from 'rxjs';

export const LIGHT_THEME_CLASS = 'rpg-theme-currently-light';
export const DARK_THEME_CLASS = 'rpg-theme-currently-dark';

const ALL_OPTIONS = [LIGHT_THEME_CLASS, DARK_THEME_CLASS];

@Directive({
  selector: '[rpgThemeHelper]',
})
export class RpgThemeHelperDirective implements OnInit, OnDestroy {
  private _enabled: boolean = true;
  @Input()
  set rpgThemeHelper(condition: boolean | string) {
    // Ignore non-boolean conditions (allows the default to be true if no value is provided)
    this._enabled = typeof condition === 'boolean' ? condition : true;
    if (!!this._sub && !this._enabled) {
      this._sub.unsubscribe();
      this._clear();
    } else if (!this._sub && !!this._enabled) {
      this.ngOnInit();
    }
  }

  private _sub: Subscription | undefined;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2,
    private _themeService: ThemeService
  ) {}

  ngOnInit(): void {
    if (this._enabled) {
      this._sub = this._themeService.currentTheme$.subscribe(theme => {
        this._clear();
        switch (theme) {
          case ThemeOption.Light:
            this._add(LIGHT_THEME_CLASS);
            break;
          case ThemeOption.Dark:
            this._add(DARK_THEME_CLASS);
            break;
          default:
            unreachable(theme, 'RPG Theme Helper Subscription');
        }
      });
    }
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }

  private _add(className: string): void {
    this._renderer.addClass(this._elementRef.nativeElement, className);
  }

  private _remove(className: string): void {
    this._renderer.removeClass(this._elementRef.nativeElement, className);
  }

  private _clear(): void {
    ALL_OPTIONS.forEach(option => this._remove(option));
  }
}

export enum LegacyGameType {
  NarrativeDiceSystem = '[gameType] nds',
  FFGStarWars = '[gameType] ffg star wars',
  FFGGenesys = '[gameType] ffg genesys',
  AGE = '[gameType] age',
  Generic = '[gameType] generic',
}

export namespace LegacyGameType {
  export function toString(type: LegacyGameType): string {
    switch (type) {
      case LegacyGameType.NarrativeDiceSystem:
        return 'Narrative Dice System';
      case LegacyGameType.FFGStarWars:
        return 'FFG Star Wars';
      case LegacyGameType.FFGGenesys:
        return 'FFG Genesys';
      case LegacyGameType.AGE:
        return 'Adventure Game Engine';
      case LegacyGameType.Generic:
        return 'Generic';
      default:
        return '';
    }
  }

  export function translationKey(type: LegacyGameType): string {
    return `generic.enums.game-type.${LegacyGameType.toString(type)}`;
  }

  export const availableGameOptions: LegacyGameType[] = [
    LegacyGameType.FFGStarWars,
    LegacyGameType.FFGGenesys,
  ];

  export const members: LegacyGameType[] = [
    LegacyGameType.Generic,
    LegacyGameType.FFGGenesys,
    LegacyGameType.FFGStarWars,
    LegacyGameType.AGE,
  ];

  export function toUrlParam(type: LegacyGameType): string | null {
    switch (type) {
      case LegacyGameType.Generic:
        return 'generic';
      case LegacyGameType.FFGStarWars:
        return 'ffg-sw';
      case LegacyGameType.FFGGenesys:
        return 'ffg-gen';
      case LegacyGameType.AGE:
        return 'gr-age';
      default:
        return null;
    }
  }

  export function fromUrlParam(param: string): LegacyGameType | null {
    const sanitized: string = `${param}`.trim().toLowerCase();
    switch (sanitized) {
      case 'generic':
        return LegacyGameType.Generic;
      case 'ffg-sw':
        return LegacyGameType.FFGStarWars;
      case 'ffg-gen':
        return LegacyGameType.FFGGenesys;
      case 'gr-age':
        return LegacyGameType.AGE;
      default:
        return null;
    }
  }
}

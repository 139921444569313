import { Constraints, parseBoolean, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';
import { NdsCharacterModifierType } from '../../enums';
import { NdsCharacterModifierData } from './nds-character-modifier-data';

export class NdsCharacterModifierSkillData extends NdsCharacterModifierData {
  public type: NdsCharacterModifierType = NdsCharacterModifierType.Skill;
  public linkedSkillId: string = '';
  public careerModifier: boolean = false;
  public includeUncommittedForceDice: boolean = false;
  public rankModifier: number = 0;
  public extraDice: DiceType[] = [];

  constructor(params?: Partial<NdsCharacterModifierSkillData>) {
    super(params);
    if (!!params) {
      this.linkedSkillId = params.linkedSkillId ?? this.linkedSkillId;
      this.careerModifier = parseBoolean(params.careerModifier, this.careerModifier);
      this.includeUncommittedForceDice = parseBoolean(
        params.includeUncommittedForceDice,
        this.includeUncommittedForceDice
      );
      this.rankModifier = parseNumber(params.rankModifier, this.rankModifier);
      this.extraDice = Array.isArray(params.extraDice) ? params.extraDice : this.extraDice;
    }
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsCharacterModifierType.Skill);
  }

  public static validate(
    input: NdsCharacterModifierSkillData,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = NdsCharacterModifierData.validate(input, fieldNamePrefix);
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Modifier ${
      input.name ? `(${input.name})` : ''
    }`;
    errors.push(
      ...RpgClassValidators.string(input.linkedSkillId, { fieldName: `${name} Linked Skill` })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.careerModifier, { fieldName: `${name} Career` })
    );
    errors.push(...RpgClassValidators.number(input.rankModifier, { fieldName: `${name} Ranks` }));
    errors.push(
      ...RpgClassValidators.boolean(input.includeUncommittedForceDice, {
        fieldName: `${name} Uncommitted Force Dice`,
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.extraDice, DiceType, {
        enumName: 'Dice Type',
        isArray: true,
      })
    );
    return errors;
  }
}

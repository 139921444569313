import Fuse from 'fuse.js';

const defaultSearchOptions: FuzzySearchOptions = {
  shouldSort: true,
  threshold: 0.25,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: [],
};

export interface FuzzySearchOptions {
  shouldSort: boolean;
  threshold: number;
  location: number;
  distance: number;
  maxPatternLength: number;
  minMatchCharLength: number;
  keys: string[];
}

export function fuzzySearchResult<T>(
  listToSearch: T[],
  searchString: string,
  options?: Partial<FuzzySearchOptions>
): T | null {
  const matched = fuzzySearch(listToSearch, searchString, options);
  if (matched.length > 0) {
    return matched[0].item;
  }
  return null;
}

export function fuzzySearch<T>(
  listToSearch: T[],
  searchString: string,
  options?: Partial<FuzzySearchOptions>,
  includeScore?: false
): { item: T; refIndex: number }[];
export function fuzzySearch<T>(
  listToSearch: T[],
  searchString: string,
  options?: Partial<FuzzySearchOptions>,
  includeScore?: true
): { item: T; score: number; refIndex: number }[];
export function fuzzySearch<T>(
  listToSearch: T[],
  searchString: string,
  options?: Partial<FuzzySearchOptions>,
  includeScore: boolean = false
): { item: T; refIndex: number; score?: number }[] {
  const searchTable = new Fuse(listToSearch, {
    ...defaultSearchOptions,
    ...options,
    includeScore: includeScore,
  });
  return searchTable.search(searchString) as any;
}

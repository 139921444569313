export enum NdsCharacterSkillType {
  General = '[nds character skill type] general',
  Magic = '[nds character skill type] magic',
  Combat = '[nds character skill type] combat',
  Social = '[nds character skill type] social',
  Knowledge = '[nds character skill type] knowledge',
  Other = '[nds character skill type] other',
}

export namespace NdsCharacterSkillType {
  export function translationKey(type: NdsCharacterSkillType): string {
    return `enums.character.nds.skill-type.${type}`;
  }

  export const members: NdsCharacterSkillType[] = [
    NdsCharacterSkillType.General,
    NdsCharacterSkillType.Magic,
    NdsCharacterSkillType.Combat,
    NdsCharacterSkillType.Social,
    NdsCharacterSkillType.Knowledge,
    NdsCharacterSkillType.Other,
  ];
}

import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterAttachmentData } from '@rpg/core/character';
import { NDSCharacterModifierFormGroupFactory } from './modifiers';
import { ndsCharacterAttachmentModificationToFormGroup } from './nds-character-attachment-modification-to-form-group';

export function ndsCharacterAttachmentToFormGroup(
  attach: NdsCharacterAttachmentData
): FormGroup<NdsCharacterAttachmentData> {
  return new FormGroup<NdsCharacterAttachmentData>({
    cost: new FormControl(attach.cost),
    description: new FormControl(attach.description),
    equipped: new FormControl(attach.equipped),
    hardPoints: new FormControl(attach.hardPoints),
    id: new FormControl(attach.id),
    modifications: new FormArray(
      attach.modifications.map(m => ndsCharacterAttachmentModificationToFormGroup(m))
    ),
    modifiers: new FormArray(attach.modifiers.map(m => NDSCharacterModifierFormGroupFactory(m))),
    name: new FormControl(attach.name),
    rarity: new FormControl(attach.rarity),
    restricted: new FormControl(attach.restricted),
  });
}

import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsCharacterForceTreeData } from '@rpg/core/character';

export function ndsCharacterForcePowerTreeToFormGroup(
  tree: NdsCharacterForceTreeData
): FormGroup<NdsCharacterForceTreeData> {
  return new FormGroup<NdsCharacterForceTreeData & Expandable>({
    expanded: new FormControl((tree as any)['expanded'] ?? false),
    id: new FormControl(tree.id),
    name: new FormControl(tree.name),
    edgeMatrix: new FormControl(tree.edgeMatrix),
    positionManager: new FormControl(tree.positionManager),
    basePowerId: new FormControl(tree.basePowerId),
  });
}

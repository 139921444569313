import { OverlayResponseType } from '../enums';

export class OverlayResponse<T> {
  public get cancel(): boolean {
    return this.responseType === OverlayResponseType.Cancel;
  }
  public get confirm(): boolean {
    return this.responseType === OverlayResponseType.Confirm;
  }
  public get delete(): boolean {
    return this.responseType === OverlayResponseType.Delete;
  }

  constructor(public responseType: OverlayResponseType, public data?: T) {}

  public static Cancel<T>(data?: T): OverlayResponse<T> {
    return new OverlayResponse(OverlayResponseType.Cancel, data);
  }

  public static Delete<T>(data?: T): OverlayResponse<T> {
    return new OverlayResponse(OverlayResponseType.Delete, data);
  }

  public static Confirm<T>(data?: T): OverlayResponse<T> {
    return new OverlayResponse(OverlayResponseType.Confirm, data);
  }
}

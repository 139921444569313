import { Constraints, createId, createSlug, RpgClassValidators } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';
import { NdsCharacterCharacteristic, NdsCharacterSkillType } from '../enums';

export const NDS_CHARACTER_SKILL_MIN_RANKS = 0;
export const NDS_CHARACTER_SKILL_MAX_RANKS = 6;

export class NdsCharacterSkillData {
  public id: string = '';
  public name: string = '';
  public lookupName: string = '';
  public isCareer: boolean = false;
  public ranks: number = 0;
  public linkedCharacteristic!: NdsCharacterCharacteristic;
  public skillType: NdsCharacterSkillType = NdsCharacterSkillType.General;
  public extraDice: DiceType[] = [];

  constructor(params?: Partial<NdsCharacterSkillData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.lookupName = params.lookupName ?? this.lookupName;
      this.name = params.name ?? this.name;
      this.isCareer = params.isCareer ?? this.isCareer;
      this.ranks = params.ranks ?? this.ranks;
      this.linkedCharacteristic = params.linkedCharacteristic ?? this.linkedCharacteristic;
      this.skillType = params.skillType ?? this.skillType;
      this.extraDice = Array.isArray(params.extraDice) ? params.extraDice : this.extraDice;
    }
    // Enforce Constraints
    if (!this.id) {
      this.id = createId();
    }
    if (!this.lookupName) {
      this.lookupName = createSlug(this.name ?? '');
    }
    this.ranks = Constraints.withinRange(
      this.ranks,
      NDS_CHARACTER_SKILL_MIN_RANKS,
      NDS_CHARACTER_SKILL_MAX_RANKS
    );
  }

  public static validate(input: NdsCharacterSkillData): string[] {
    const errors: string[] = [];
    const name = `Skill ${input.name ? `(${input.name}) ` : ''}`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(
      ...RpgClassValidators.string(input.lookupName, { fieldName: `${name} Lookup Name` })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.isCareer, { fieldName: `${name} Career Toggle` })
    );
    errors.push(
      ...RpgClassValidators.number(input.ranks, {
        min: NDS_CHARACTER_SKILL_MIN_RANKS,
        max: NDS_CHARACTER_SKILL_MAX_RANKS,
        fieldName: `${name} Ranks`,
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.linkedCharacteristic, NdsCharacterCharacteristic, {
        enumName: 'Linked Characteristic',
        fieldName: `${name} Characteristic`,
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.skillType, NdsCharacterSkillType, {
        enumName: 'Skill Type',
        fieldName: `${name} Skill Type`,
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.extraDice, DiceType, {
        enumName: 'Dice Type',
        isArray: true,
        fieldName: `${name} Extra Dice`,
      })
    );
    return errors;
  }
}

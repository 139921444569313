<ng-container *rxLet="vm.state$; let state">
  <ng-container *ngIf="!!state.selectedTalentData">
    <rpg-nds-character-talent-slot-connectable
      class="mobile-displayed-talent"
      [talentData]="state.talentSlotRef[state.selectedTalentData.linkedId] ?? undefined"
      [mobileEnabled]="false"
      [mobileSelected]="false"
      [editMode]="state.editMode"
      [position]="state.selectedTalentData.position"
      [expandable]="state.editMode && state.selectedTalentData.volatileData.expandable"
      [columnSpan]="state.selectedTalentData.span"
      [edgeBoundaries]="TREE_BOUNDARIES"
      [adjacencyTrackers]="state.selectedTalentData.volatileData.adjacencyTrackers"
      (talentContentClick)="
        talentContentClick.emit({
          isSignature: state.selectedTalentIsSignatureTree,
          nodeRef: state.selectedTalentData
        })
      "
      (talentPurchasedToggle)="
        talentPurchasedToggle.emit({ nodeRef: state.selectedTalentData, state: $event })
      "
      (updateAdjacency)="
        vm.updateAdjacency(
          { isSignature: state.selectedTalentIsSignatureTree, nodeRef: state.selectedTalentData },
          $event
        )
      "
      (expandNode)="
        expandNode.emit({
          isSignature: state.selectedTalentIsSignatureTree,
          nodeRef: state.selectedTalentData
        })
      "
    ></rpg-nds-character-talent-slot-connectable>
  </ng-container>
  <!-- PRIMARY GRID -->
  <rpg-nds-character-talent-slot-connectable
    *ngFor="let nodeRef of state.talents; let i = index; trackBy: trackBySlotId"
    [talentData]="state.talentSlotRef[nodeRef.linkedId] ?? undefined"
    [mobileEnabled]="true"
    [mobileSelected]="state.selectedTalentData?.id === nodeRef.id"
    [forceMobile]="state.forceMobile"
    [editMode]="state.editMode"
    [position]="nodeRef.position"
    [expandable]="state.editMode && nodeRef.volatileData.expandable"
    [columnSpan]="nodeRef.span"
    [edgeBoundaries]="TREE_BOUNDARIES"
    [adjacencyTrackers]="nodeRef.volatileData.adjacencyTrackers"
    (talentContentClick)="
      vm.isMobile()
        ? vm.mobileSelect(nodeRef, false)
        : talentContentClick.emit({ isSignature: false, nodeRef: this.nodeRef })
    "
    (talentPurchasedToggle)="talentPurchasedToggle.emit({ nodeRef: nodeRef, state: $event })"
    (updateAdjacency)="vm.updateAdjacency({ isSignature: false, nodeRef: this.nodeRef }, $event)"
    (expandNode)="expandNode.emit({ isSignature: false, nodeRef: this.nodeRef })"
  ></rpg-nds-character-talent-slot-connectable>

  <ng-template #addSignatureTalentButton>
    <div
      class="add-signature-talent-button"
      *ngIf="state.signatureTalentsEnabled && state.editMode"
    >
      <button mat-raised-button color="primary" type="button" (click)="addSignatureTalent.emit()">
        {{
          'phrases.add-button-text'
            | transloco
              : {
                  type: ('phrases.Signature Talent' | transloco)
                }
        }}
      </button>
    </div>
  </ng-template>

  <ng-container
    *ngIf="state.signatureTalentsEnabled && state.hasSignatureTalent; else addSignatureTalentButton"
  >
    <ng-container
      *ngFor="let node of state.signatureTalents[0].volatileData.adjacencyTrackers; let idx = index"
    >
      <rpg-nds-character-talent-signature-connector
        *ngIf="state.editMode || node.Up; else hiddenConnector"
        [connected]="node.Up"
        (toggle)="vm.updateSignatureConnector(idx, node)"
      ></rpg-nds-character-talent-signature-connector>
    </ng-container>
    <ng-template #hiddenConnector>
      <span></span>
    </ng-template>
    <rpg-nds-character-talent-slot-connectable
      *ngFor="let nodeRef of state.signatureTalents; let i = index; trackBy: trackBySlotId"
      [talentData]="state.talentSlotRef[nodeRef.linkedId] ?? undefined"
      [mobileEnabled]="true"
      [mobileSelected]="state.selectedTalentData?.id === nodeRef.id"
      [forceMobile]="state.forceMobile"
      [editMode]="state.editMode"
      [position]="nodeRef.position"
      [expandable]="state.editMode && nodeRef.volatileData.expandable"
      [columnSpan]="nodeRef.span"
      [edgeBoundaries]="SIGNATURE_TREE_BOUNDARIES"
      [adjacencyTrackers]="nodeRef.volatileData.adjacencyTrackers"
      (talentContentClick)="
        vm.isMobile()
          ? vm.mobileSelect(nodeRef, true)
          : talentContentClick.emit({ isSignature: true, nodeRef: this.nodeRef })
      "
      (talentPurchasedToggle)="talentPurchasedToggle.emit({ nodeRef: nodeRef, state: $event })"
      (updateAdjacency)="vm.updateAdjacency({ isSignature: true, nodeRef: this.nodeRef }, $event)"
      (expandNode)="expandNode.emit({ isSignature: true, nodeRef: this.nodeRef })"
    ></rpg-nds-character-talent-slot-connectable>
  </ng-container>
</ng-container>

<ng-container *rxLet="state$; let state">
  <ng-container *transloco="let t; read: 'shared.import-overlay'">
    <div mat-dialog-header>
      <h4>
        {{ t(title) }}
      </h4>
      <h5 *ngIf="state.limits && !customDataImport">
        {{ t('monthly-imports-label') }}
        {{ state.limits.current + (state.limits.limit === -1 ? '' : ' / ' + state.limits.limit) }}
      </h5>
    </div>
    <div mat-dialog-content *ngIf="state.loading || !state.limits" class="spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div mat-dialog-content *ngIf="state.limits && state.loading === false">
      <div
        class="form-group"
        *ngIf="
          ((state.limits.limit === -1 || state.limits.current < state.limits.limit) &&
            !customDataImport) ||
          (customDataImport && user.hasReward(PatreonReward.OggdudeCustomData))
        "
      >
        <div *ngIf="state.error" class="error">
          {{ state.error }}
        </div>
        <label
          for="file"
          class="file-upload-label"
          rpgFileDrop
          (fileDropped)="handleFileInput({ files: $event })"
        >
          <span class="label" [ngClass]="{ 'file-selected': !!fileToUpload }">
            {{ !fileToUpload ? t('drop-file-prompt', { fileType: fileType }) : fileToUpload.name }}
          </span>
          <span class="icon">
            <fa-icon [icon]="['fas', fileToUpload && state.error === '' ? 'user' : 'download']">
            </fa-icon>
          </span>
        </label>
        <div>
          <input type="file" id="file" class="ng-hide" (change)="handleFileInput($event.target)" />
        </div>
        <div>
          <h5 *ngIf="customDataImport && user.hasReward(PatreonReward.OggdudeCustomData)">
            {{ t('create-import-file-instructions') }}
          </h5>
        </div>
        <div *ngIf="isCharacterImport">
          <rpg-select
            [placeholder]="t('overwrite-character')"
            [inputControl]="importForm.get('characterId')"
            [options]="characterOptions"
          ></rpg-select>
        </div>
        <div *ngIf="isOggDude" class="warning">
          <span *ngIf="!user.hasReward(PatreonReward.OggdudeCustomData)">
            {{ t('non-patron-custom-data-warning') }}
          </span>
          <span *ngIf="user.hasReward(PatreonReward.OggdudeCustomData)">
            <ng-container *ngIf="!state.limits.hasCustomData">
              {{ t('custom-oggdude-data-not-imported') }}
            </ng-container>
            <ng-container *ngIf="state.limits.hasCustomData">
              <ng-container *ngIf="!state.deleteCustomDataMode">
                <span [innerHTML]="t('custom-oggdude-data-already-imported')"> </span>
                <fa-icon [icon]="['fas', 'trash']" (click)="toggleDeleteCustomDataMode()"></fa-icon>
              </ng-container>
              <ng-container *ngIf="state.deleteCustomDataMode">
                <span>
                  {{ t('custom-oggdude-data-confirm-delete') }}
                </span>
                <div class="button-container delete-confirmation-container">
                  <button
                    class="cancel"
                    mat-raised-button
                    color="accent"
                    type="button"
                    (click)="toggleDeleteCustomDataMode()"
                  >
                    {{ 'words.Cancel' | transloco }}
                  </button>
                  <button
                    class="save"
                    mat-raised-button
                    color="primary"
                    (click)="deleteCustomOggDudeData()"
                  >
                    {{ 'words.Confirm' | transloco }}
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </span>
        </div>
        <div *ngIf="importForm?.get('characterId')?.value" class="overwrite-info">
          {{ t('overwrite-ignored-label') }}
          <ul>
            <li *ngFor="let item of t('oggdude-import-wont-overwrite')">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div
        class="patron-error"
        *ngIf="
          state.limits.limit !== -1 &&
          state.limits.current >= state.limits.limit &&
          !customDataImport
        "
      >
        <p>{{ t('import-limit-greeting') }}</p>
        <p>{{ t('import-limit-description') }}</p>
        <p>
          <span
            *ngIf="user.hasReward(PatreonReward.OggdudeCustomData)"
            [innerHTML]="t('import-limit-patreon-tier-error')"
          >
          </span>
          <span
            *ngIf="!user.hasReward(PatreonReward.OggdudeCustomData)"
            [innerHTML]="t('import-limit-no-patreon-error')"
          >
          </span>
        </p>
        <p
          *ngIf="!user.hasReward(PatreonReward.OggdudeCustomData)"
          [innerHTML]="t('import-limit-linking-instructions')"
        ></p>
      </div>
      <div
        class="patron-error"
        *ngIf="customDataImport && !user.hasReward(PatreonReward.OggdudeCustomData)"
      >
        <p>Greetings traveler!</p>
        <p [innerHTML]="t('patreon-only-error-warning')"></p>
        <p [innerHTML]="t('patreon-only-error-call-to-action')"></p>
      </div>
    </div>
    <div mat-dialog-actions class="actions" *ngIf="state.loading === false">
      <div class="button-container">
        <button class="cancel" mat-raised-button color="accent" type="button" (click)="cancel()">
          {{ 'words.Cancel' | transloco }}
        </button>
        <button
          class="save"
          *ngIf="
            (state.limits &&
              (state.limits.limit === -1 || state.limits.current < state.limits.limit) &&
              !customDataImport) ||
            (customDataImport && user.hasReward(PatreonReward.OggdudeCustomData))
          "
          mat-raised-button
          color="primary"
          [disabled]="state.error || !fileToUpload"
          (click)="import()"
        >
          {{ 'words.Import' | transloco }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

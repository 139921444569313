import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { states } from './state';
import { Observable } from 'rxjs';
import { environment } from '@sessions/env';
import {
  RPG_HTTP_AUTH_TOKEN_STREAM,
  UrlInterceptor,
  AuthInterceptor,
  RPG_HTTP_HOST_URL,
  RPG_HTTP_CONNECTION_OPTIONS,
} from '@rpg/ngx/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SessionIdInterceptor, TransferCacheInterceptor } from './interceptors';
import { SentryErrorHandler } from './error';

export function getTokenStream(store: Store): Observable<string> {
  return store.select(state => state.auth3.authToken);
}

export * from './error';
export * from './functions';
export * from './guards';
export * from './interceptors';
export * from './services';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot(states, {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: environment.production,
        injectContainerState: false,
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferCacheInterceptor,
      multi: true,
    },
    {
      provide: RPG_HTTP_HOST_URL,
      useValue: environment.webserver,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
    {
      provide: RPG_HTTP_AUTH_TOKEN_STREAM,
      useFactory: getTokenStream,
      deps: [Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionIdInterceptor,
      multi: true,
    },
    {
      provide: RPG_HTTP_CONNECTION_OPTIONS,
      useValue: {
        heartbeatUrl: `${environment.webserver}/heartbeat/alive`,
      },
    },
    {
      provide: ErrorHandler,
      useClass: environment.enableSentry ? SentryErrorHandler : ErrorHandler,
    },
  ],
})
export class CoreModule {}

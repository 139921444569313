import { Constraints, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';
import { NdsVehicleModifierType } from '../../enums';
import { NdsVehicleModifierData } from './nds-vehicle-modifier-data';

export class NdsVehicleModifierWeaponData extends NdsVehicleModifierData {
  public type: NdsVehicleModifierType = NdsVehicleModifierType.Weapon;
  public linkedWeaponId: string = '';
  public extraDice: DiceType[] = [];
  public damageModifier: number = 0;
  public critModifier: number = 0;

  constructor(params?: Partial<NdsVehicleModifierWeaponData>) {
    super(params);
    if (!!params) {
      this.linkedWeaponId = params.linkedWeaponId ?? this.linkedWeaponId;
      this.extraDice = Array.isArray(params.extraDice) ? params.extraDice : this.extraDice;
      this.damageModifier = parseNumber(params.damageModifier, this.damageModifier);
      this.critModifier = parseNumber(params.critModifier, this.critModifier);
    }
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsVehicleModifierType.Weapon);
  }

  public static validate(input: NdsVehicleModifierWeaponData): string[] {
    const errors: string[] = NdsVehicleModifierData.validate(input);
    errors.push(...RpgClassValidators.string(input.linkedWeaponId));
    errors.push(
      ...RpgClassValidators.enum(input.extraDice, DiceType, {
        enumName: 'Dice Type',
        isArray: true,
      })
    );
    errors.push(...RpgClassValidators.number(input.damageModifier));
    errors.push(...RpgClassValidators.number(input.critModifier));
    return errors;
  }
}

import { FileType } from '../enums';
import { FileReference } from './file-reference';

export class FolderFileReference extends FileReference {
  public type: FileType = FileType.Folder;

  constructor(params?: Partial<FolderFileReference>) {
    super(params);
    if (!!params) {
      this.type = FileType.Folder;
    }
  }
}

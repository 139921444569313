import { Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Images } from '@rpg/core/base';

interface SeoConfiguration {
  image: string;
  title: string;
  description: string;
}

const defaultSeo: SeoConfiguration = {
  image: Images.LogoBlue,
  title: 'RPG Sessions - Online RPG Tools',
  // eslint-disable-next-line
  description: `RPG Sessions offers free online character sheets for Star Wars and Genesys role-playing, as well as integrated dice roller, Discord Bot, and virtual game table features.`,
};

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private _title: Title, private _meta: Meta, private _transloco: TranslocoService) {}

  public configureSeo(configuration?: Partial<SeoConfiguration>): string[] {
    const config: SeoConfiguration = {
      ...defaultSeo,
      ...configuration,
    };
    this.setTitle(config.title);
    const tags: MetaDefinition[] = [
      {
        name: 'og:title',
        property: 'og:title',
        content: `${config.title}${
          config.title.indexOf('RPG Sessions') < 0 ? ' | RPG Sessions' : ''
        }`,
      },
      {
        name: 'description',
        property: 'description',
        content: config.description,
      },
      {
        name: 'og:description',
        property: 'og:description',
        content: config.description,
      },
      {
        name: 'image',
        property: 'image',
        content: config.image,
      },
      {
        name: 'og:image',
        property: 'og:image',
        content: config.image,
      },
    ];
    const tagsToRemove = tags.reduce<string[]>((acc, next) => [...acc, `name='${next.name}'`], []);
    this.removeTags(...tagsToRemove);
    this.addTags(...tags);
    return tagsToRemove;
  }

  public setTitle(title: string, alreadyTranslated?: boolean): void {
    if (
      alreadyTranslated !== true &&
      !title.includes(' ') &&
      title.includes('.') &&
      title[title.length - 1] !== '.'
    ) {
      // This should be translated since it follows some format of 'string.string'
      this._transloco.selectTranslate(title).subscribe(t => this.setTitle(t, true));
      return;
    }
    if (title.indexOf('| RPG Sessions') < 0) {
      title += ' | RPG Sessions';
    }
    this._title.setTitle(title);
  }

  public addTags(...tags: MetaDefinition[]): void {
    this._meta.addTags(tags);
  }

  public removeTags(...tagSelectors: string[]): void {
    for (const s of tagSelectors) {
      if (this._meta.getTag(s)) {
        this._meta.removeTag(s);
      }
    }
  }
}

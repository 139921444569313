import { Validators } from '@angular/forms';
import { FormControl, FormGroup, FormArray } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsVehicleWeaponData } from '@rpg/core/vehicle';
import { NDSVehicleModifierFormGroupFactory } from './modifiers';

export function ndsVehicleWeaponToFormGroup(
  weapon: NdsVehicleWeaponData
): FormGroup<NdsVehicleWeaponData> {
  return new FormGroup<NdsVehicleWeaponData & Expandable>({
    expanded: new FormControl((weapon as any)['expanded'] ?? false),
    id: new FormControl(weapon.id),
    name: new FormControl(weapon.name, [Validators.required]),
    lookupName: new FormControl(weapon.lookupName),
    description: new FormControl(weapon.description),
    skillName: new FormControl(weapon.skillName),
    fireArc: new FormControl(weapon.fireArc),
    damage: new FormControl(weapon.damage),
    critRating: new FormControl(weapon.critRating),
    range: new FormControl(weapon.range),
    equipped: new FormControl(weapon.equipped),
    extraDice: new FormControl(weapon.extraDice),
    modifiers: new FormArray(weapon.modifiers.map(m => NDSVehicleModifierFormGroupFactory(m))),
  });
}

import { NgModule, Self, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebsocketInjectorAccessor } from './services/websocket-injector-accessor.service';

@NgModule({
  imports: [CommonModule],
})
export class NgxWebsocketsModule {
  constructor(@Self() private _socketInjectorAccessor: WebsocketInjectorAccessor) {}

  public static forRoot(): ModuleWithProviders<NgxWebsocketsModule> {
    return {
      ngModule: NgxWebsocketsModule,
      providers: [WebsocketInjectorAccessor],
    };
  }
}

export { WebsocketService } from './services/websocket.service';
export * from './decorators/socket.decorators';
export * from './models';

<div mat-dialog-title>
  <h2>{{ options.title }}</h2>
</div>
<mat-dialog-content>
  <p *ngFor="let m of options.messages">{{ m }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    *ngFor="let b of options.buttons"
    mat-raised-button
    [color]="b.color"
    (click)="dialogRef.close(b.value)"
  >
    {{ b.label }}
  </button>
</mat-dialog-actions>

export enum CharacterType {
  Player = '[character type] player',
  NPC = '[character type] npc',
}

export namespace CharacterType {
  export function translationKey(type: CharacterType): string {
    return `enums.character.character-type.${type}`;
  }

  export function toString(type: CharacterType): string {
    switch (type) {
      case CharacterType.Player:
        return 'Player';
      case CharacterType.NPC:
        return 'NPC';
      default:
        return '';
    }
  }

  export const members: CharacterType[] = [CharacterType.Player, CharacterType.NPC];
}

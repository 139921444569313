/* eslint-disable brace-style */
import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  AfterContentInit,
  AfterContentChecked,
  AfterViewInit,
  OnDestroy,
  ContentChild,
  ContentChildren,
  QueryList,
  ElementRef,
  ChangeDetectorRef,
  NgZone,
  ViewChild,
  Inject,
  Optional,
} from '@angular/core';
import {
  matFormFieldAnimations,
  MatFormFieldControl,
  MatLabel,
  MatError,
  MatPrefix,
  MatSuffix,
  MatFormFieldAppearance,
  getMatFormFieldMissingControlError,
  FloatLabelType,
} from '@angular/material/form-field';
import { mixinColor, CanColor } from '@angular/material/core';
import { Subject, merge, fromEvent, startWith, takeUntil, take } from 'rxjs';
import { NgControl, NgForm } from '@angular/forms';
import { Direction } from '@angular/cdk/bidi';
import { Platform } from '@angular/cdk/platform';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';

let nextUniqueId = 0;
const floatingLabelScale = 0.75;
const outlineGapPadding = 5;

class MatFormFieldBase {
  constructor(public _elementRef: ElementRef) {}
}

const _MatFormFieldMixinBase: typeof MatFormFieldBase = mixinColor(MatFormFieldBase, 'primary');

// prettier-ignore
@Component({
  selector: 'rpg-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  animations: [matFormFieldAnimations.transitionMessages],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'mat-form-field rpg-form-field',
    '[class.mat-form-field-appearance-outline]': 'true',
    '[class.mat-form-field-invalid]': '_control.errorState',
    '[class.mat-form-field-can-float]': '_canLabelFloat',
    '[class.mat-form-field-should-float]': '_shouldLabelFloat()',
    '[class.mat-form-field-has-label]': '_hasFloatingLabel()',
    '[class.mat-form-field-hide-placeholder]': '_hideControlPlaceholder()',
    '[class.mat-form-field-disabled]': '_control.disabled',
    '[class.mat-form-field-autofilled]': '_control.autofilled',
    '[class.mat-focused]': '_control.focused',
    '[class.mat-accent]': 'color == "accent"',
    '[class.mat-warn]': 'color == "warn"',
    '[class.ng-untouched]': '_shouldForward("untouched")',
    '[class.ng-touched]': '_shouldForward("touched")',
    '[class.ng-pristine]': '_shouldForward("pristine")',
    '[class.ng-dirty]': '_shouldForward("dirty")',
    '[class.ng-valid]': '_shouldForward("valid")',
    '[class.ng-invalid]': '_shouldForward("invalid")',
    '[class.ng-pending]': '_shouldForward("pending")',
    '[class._mat-animation-noopable]': '!_animationsEnabled',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent
  extends _MatFormFieldMixinBase
  implements AfterContentInit, AfterContentChecked, AfterViewInit, OnDestroy, CanColor
{
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public static ngAcceptInputType_hideRequiredMarker: boolean | string;

  public includedForStylesOnly: boolean = true;

  public color: any;
  public defaultColor: any;

  /**
   * Whether the outline gap needs to be calculated
   * immediately on the next change detection run.
   */
  private _outlineGapCalculationNeededImmediately: boolean = false;

  /** Whether the outline gap needs to be calculated next time the zone has stabilized. */
  private _outlineGapCalculationNeededOnStable: boolean = true;

  /** State of the mat-hint and mat-error animations. */
  public _subscriptAnimationState: string = '';

  private _destroyed: Subject<void> = new Subject<void>();

  /** The form-field appearance style. */
  private _appearance: MatFormFieldAppearance = 'outline';
  public get appearance(): MatFormFieldAppearance {
    return this._appearance;
  }

  private _hideRequiredMarker: boolean = true;
  public get hideRequiredMarker(): boolean {
    return this._hideRequiredMarker;
  }

  /** Whether the floating label should always float or not. */
  public get _shouldAlwaysFloat(): boolean {
    return this.floatLabel === 'always' && !this._showAlwaysAnimate;
  }

  /** Whether the label can float or not. */
  public get _canLabelFloat(): boolean {
    return this.floatLabel !== 'never';
  }

  private _floatLabel: FloatLabelType = 'auto';
  public get floatLabel(): FloatLabelType {
    return this._floatLabel;
  }
  public set floatLabel(val: FloatLabelType) {
    this._floatLabel = val;
  }

  /** Whether the Angular animations are enabled. */
  public _animationsEnabled: boolean;

  /** Override for the logic that disables the label animation in certain cases. */
  private _showAlwaysAnimate: boolean = false;

  /* Holds the previous direction emitted by directionality service change emitter.
     This is used in updateOutlineGap() method to update the width and position of the gap in the
     outline. Only relevant for the outline appearance. The direction is getting updated in the
     UI after directionality service change emission. So the outlines gaps are getting
     updated in updateOutlineGap() method before connectionContainer child direction change
     in UI. We may get wrong calculations. So we are storing the previous direction to get the
     correct outline calculations*/
  private _previousDirection: Direction = 'ltr';

  @ViewChild('connectionContainer', { static: true })
  public _connectionContainerRef!: ElementRef;
  @ViewChild('subscriptWrapper', { static: true })
  public _subscriptWrapper!: ElementRef;
  @ViewChild('inputContainer')
  public _inputContainerRef!: ElementRef;
  @ViewChild('label')
  private _label!: ElementRef<HTMLElement>;

  @ContentChild(MatFormFieldControl)
  public _control!: MatFormFieldControl<any>;

  @ContentChild(MatLabel)
  public _labelChild!: MatLabel;

  @ContentChildren(MatError, { descendants: true })
  public _errorChildren!: QueryList<MatError>;
  @ContentChildren(MatError, { read: ElementRef, descendants: true })
  public _errorChildrenEl!: QueryList<ElementRef<MatError>>;
  @ContentChildren(MatPrefix, { descendants: true })
  public _prefixChildren!: QueryList<MatPrefix>;
  @ContentChildren(MatSuffix, { descendants: true })
  public _suffixChildren!: QueryList<MatSuffix>;

  // Unique id for the hint label.
  public _hintLabelId: string = `mat-hint-${nextUniqueId++}`;

  // Unique id for the internal form field label.
  public _labelId: string = `mat-form-field-label-${nextUniqueId++}`;

  constructor(
    public _elementRef: ElementRef,
    private _cd: ChangeDetectorRef,
    private _platform: Platform,
    private _ngZone: NgZone,
    @Optional() private _parentForm: NgForm,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) _animationMode: string
  ) {
    super(_elementRef);

    this._animationsEnabled = _animationMode !== 'NoopAnimations';
    this.includedForStylesOnly = false;
  }

  ngAfterContentInit(): void {
    this._validateControlChild();

    const control = this._control;

    if (control.controlType) {
      this._elementRef.nativeElement.classList.add(`mat-form-field-type-${control.controlType}`);
    }

    control.stateChanges.pipe(startWith(null), takeUntil(this._destroyed)).subscribe(() => {
      this._syncDescribedByIds();
      this._cd.markForCheck();
      setTimeout(() => {
        // Defer to next tick to let the error el render
        this.updateOutlineGap();
      });
    });

    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {
        this._cd.markForCheck();
      });
    }

    this._ngZone.runOutsideAngular(() => {
      this._ngZone.onStable
        .asObservable()
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => {
          if (this._outlineGapCalculationNeededOnStable) {
            this.updateOutlineGap();
          }
        });
    });

    // Run change detection and update the outline if the suffix or prefix changes.
    merge(this._prefixChildren.changes, this._suffixChildren.changes).subscribe(() => {
      this._outlineGapCalculationNeededOnStable = true;
      this._cd.markForCheck();
    });

    // Update the aria-described by when the number of errors changes.
    this._errorChildren.changes.pipe(startWith(null)).subscribe(() => {
      this._syncDescribedByIds();
      this._cd.markForCheck();
    });
  }

  ngAfterContentChecked(): void {
    this._validateControlChild();
    if (this._outlineGapCalculationNeededImmediately) {
      this.updateOutlineGap();
    }
  }

  ngAfterViewInit(): void {
    this._subscriptAnimationState = 'enter';
    this._cd.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public _checkForChanges(): void {
    this._cd.markForCheck();
  }

  // eslint-disable-next-line
  /** Determines whether a class from the NgControl should be forwarded to the host element. */
  public _shouldForward(prop: keyof NgControl): boolean {
    const ngControl = this._control ? this._control.ngControl : null;
    return ngControl && (ngControl as any)[prop];
  }

  public _hasPlaceholder(): boolean {
    return !!(this._control && this._control.placeholder);
  }

  public _hasLabel(): boolean {
    return !!this._labelChild;
  }

  public _hasFloatingLabel(): boolean {
    // In the legacy appearance the placeholder is promoted to a label if no label is given.
    return this._hasLabel() || (this.appearance === 'legacy' && this._hasPlaceholder());
  }

  public _shouldLabelFloat(): boolean {
    return this._canLabelFloat && (this._control.shouldLabelFloat || this._shouldAlwaysFloat);
  }

  public _hideControlPlaceholder(): boolean {
    // In the legacy appearance the placeholder is promoted to a label if no label is given.
    return (
      (this.appearance === 'legacy' && !this._hasLabel()) ||
      (this._hasLabel() && !this._shouldLabelFloat())
    );
  }

  // eslint-disable-next-line
  /** Determines whether to display hints or errors. */
  public _getDisplayedMessages(): 'error' | 'hint' {
    return this._errorChildren && this._errorChildren.length > 0 && this._control.errorState
      ? 'error'
      : 'hint';
  }

  // eslint-disable-next-line
  /** Animates the placeholder up and locks it in position. */
  public _animateAndLockLabel(): void {
    // If animations are disabled, we shouldn't go in here,
    // because the `transitionend` will never fire.
    if (this._animationsEnabled) {
      this._showAlwaysAnimate = true;

      fromEvent(this._label.nativeElement, 'transitionend')
        .pipe(take(1))
        .subscribe(() => {
          this._showAlwaysAnimate = false;
        });
    }

    this.floatLabel = 'always';
    this._cd.markForCheck();
  }

  // eslint-disable-next-line
  /** Does any extra processing that is required when handling the hints. */
  private _processHints(): void {
    this._syncDescribedByIds();
  }

  // eslint-disable-next-line
  /**
   * Sets the list of element IDs that describe the child control. This allows the control to update
   * its `aria-describedby` attribute accordingly.
   */
  private _syncDescribedByIds(): void {
    if (this._control) {
      let ids: string[] = [];

      if (this._errorChildren) {
        ids = this._errorChildren.map(error => error.id);
      }

      this._control.setDescribedByIds(ids);
    }
  }

  // eslint-disable-next-line
  /** Throws an error if the form field's control is missing. */
  protected _validateControlChild(): void {
    if (!this._control) {
      throw getMatFormFieldMissingControlError();
    }
  }

  // eslint-disable-next-line
  /**
   * Updates the width and position of the gap in the outline. Only relevant for the outline
   * appearance.
   */
  public updateOutlineGap(): void {
    const labelEl = this._label ? this._label.nativeElement : null;

    if (
      this.appearance !== 'outline' ||
      !labelEl ||
      !labelEl.children.length ||
      !labelEl.textContent?.trim()
    ) {
      return;
    }

    if (!this._platform.isBrowser) {
      // getBoundingClientRect isn't available on the server.
      return;
    }
    // If the element is not present in the DOM, the outline gap will need to be calculated
    // the next time it is checked and in the DOM.
    if (!this._isAttachedToDOM()) {
      this._outlineGapCalculationNeededImmediately = true;
      return;
    }

    let startWidth = 0;
    let gapWidth = 0;
    let bottomGapWidth = 0;
    let prefixWidth = 0;

    const container = this._connectionContainerRef.nativeElement;
    const startEls = container.querySelectorAll('.mat-form-field-outline-start');
    const gapEls = container.querySelectorAll('.mat-form-field-outline-gap-top');
    const bottomGapEls = container.querySelectorAll('.mat-form-field-outline-gap-bottom');
    const prefix = container.querySelectorAll('.mat-form-field-prefix');
    // const suffix = container.querySelectorAll('.mat-form-field-suffix');

    if (this._label && this._label.nativeElement.children.length) {
      const containerRect = container.getBoundingClientRect();

      // If the container's width and height are zero, it means that the element is
      // invisible and we can't calculate the outline gap. Mark the element as needing
      // to be checked the next time the zone stabilizes. We can't do this immediately
      // on the next change detection, because even if the element becomes visible,
      // the `ClientRect` won't be reclaculated immediately. We reset the
      // `_outlineGapCalculationNeededImmediately` flag some we don't run the checks twice.
      if (containerRect.width === 0 && containerRect.height === 0) {
        this._outlineGapCalculationNeededOnStable = true;
        this._outlineGapCalculationNeededImmediately = false;
        return;
      }

      const containerStart = this._getStartEnd(containerRect);
      const labelStart = this._getStartEnd(labelEl.children[0].getBoundingClientRect());
      let labelWidth = 0;

      // Not supported on iOS 10
      if (!!labelEl) {
        for (const child of Array.from(labelEl.children)) {
          labelWidth += (child as any)['offsetWidth'] ?? 0;
        }
      }
      startWidth = labelStart - containerStart - outlineGapPadding;
      gapWidth =
        labelWidth > 0
          ? // eslint-disable-next-line no-magic-numbers
            labelWidth * floatingLabelScale + outlineGapPadding * 2
          : 0;
    }

    if (this._errorChildren.length === 1) {
      const containerRect = container.getBoundingClientRect();

      // If the container's width and height are zero, it means that the element is
      // invisible and we can't calculate the outline gap. Mark the element as needing
      // to be checked the next time the zone stabilizes. We can't do this immediately
      // on the next change detection, because even if the element becomes visible,
      // the `ClientRect` won't be reclaculated immediately. We reset the
      // `_outlineGapCalculationNeededImmediately` flag some we don't run the checks twice.
      if (containerRect.width === 0 && containerRect.height === 0) {
        this._outlineGapCalculationNeededOnStable = true;
        this._outlineGapCalculationNeededImmediately = false;
        return;
      }

      // const containerStart = this._getStartEnd(containerRect);
      const errorEl = this._errorChildrenEl.first.nativeElement as HTMLElement;
      // const errorStart = this._getStartEnd(
      //     errorEl.getBoundingClientRect()
      // );

      const labelWidth = errorEl.offsetWidth;

      for (let i = 0; i < prefix.length; i++) {
        prefixWidth += prefix.item(i).offsetWidth;
      }

      bottomGapWidth =
        labelWidth > 0
          ? // eslint-disable-next-line no-magic-numbers
            labelWidth + outlineGapPadding * 2
          : 0;
    } else if (this._errorChildren.length > 1) {
      throw new Error('Only a single `mat-error` element is supported!');
    }

    for (let i = 0; i < startEls.length; i++) {
      startEls.item(i).style.width = `${startWidth}px`;
    }
    for (let i = 0; i < gapEls.length; i++) {
      gapEls.item(i).style.width = `${gapWidth}px`;
    }
    for (let i = 0; i < bottomGapEls.length; i++) {
      bottomGapEls.item(i).style.width = `${bottomGapWidth}px`;
    }
    this._subscriptWrapper.nativeElement.style.paddingLeft = `calc(1em + ${prefixWidth}px)`;

    this._outlineGapCalculationNeededOnStable = this._outlineGapCalculationNeededImmediately =
      false;
  }

  // eslint-disable-next-line
  /** Gets the start end of the rect considering the current directionality. */
  private _getStartEnd(rect: ClientRect): number {
    return this._previousDirection === 'rtl' ? rect.right : rect.left;
  }

  // eslint-disable-next-line
  /** Checks whether the form field is attached to the DOM. */
  private _isAttachedToDOM(): boolean {
    const element: HTMLElement = this._elementRef.nativeElement;

    if (element.getRootNode) {
      const rootNode = element.getRootNode();
      // If the element is inside the DOM the root node will be either the document
      // or the closest shadow root, otherwise it'll be the element itself.
      return rootNode && rootNode !== element;
    }

    // Otherwise fall back to checking if it's in the document. This doesn't account for
    // shadow DOM, however browser that support shadow DOM should support `getRootNode` as well.
    return document.documentElement.contains(element);
  }
}

import { Injectable, Injector } from '@angular/core';
import { WebsocketService } from './websocket.service';

@Injectable()
export class WebsocketInjectorAccessor {
  private static _injector: Injector;

  constructor(_injector: Injector) {
    WebsocketInjectorAccessor._injector = _injector;
  }

  public static getSocketService(serviceRef: any): never | WebsocketService {
    if (!this._injector) {
      throw new Error('ngx-websocket module is not imported!');
    }
    return this._injector.get<WebsocketService>(serviceRef);
  }
}

import { parseEnum, parseNumber, DiceTheme } from '@rpg/core/base';
import { DiceGroupType, DiceType } from '../enums';
import { diceTypeToDiceGroupType } from '../functions';

export class DiceSingle {
  public diceTheme: DiceTheme = DiceTheme.Generic;
  public diceGroup!: DiceGroupType;
  public type: DiceType | null = null;
  public result: number | null = null;
  public image: string = '';
  public poly: number | null = null;
  public rollOrder: number = 0;

  constructor(params?: Partial<DiceSingle>) {
    if (!!params) {
      this.diceTheme = parseEnum(DiceTheme, params.diceTheme, this.diceTheme);
      this.diceGroup = parseEnum(DiceGroupType, params.diceGroup, this.diceGroup);
      this.type = parseEnum(DiceType, params.type);
      this.result = parseNumber(params.result);
      this.image = params.image || this.image;
      this.poly = parseNumber(params.poly);
      this.rollOrder = parseNumber(params.rollOrder, this.rollOrder);
    }
    if (!this.diceGroup && !!this.type) {
      this.diceGroup = diceTypeToDiceGroupType(this.type);
    }
    if (!this.diceGroup) {
      this.diceGroup = DiceGroupType.Numbered;
    }
  }
}

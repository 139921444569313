import { Injectable } from '@angular/core';
import { PaginatedList, RulesSystem, DiceTheme } from '@rpg/core/base';
import { Dice } from '@rpg/core/dice';
import { User } from '@rpg/core/user';
import {
  HttpService,
  Get,
  Post,
  Put,
  Query,
  MapPaginatedList,
  MapClass,
  Body,
  Path,
  MapValue,
} from '@rpg/ngx/http';
import { ObjectId } from 'bson';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DiceHttpService extends HttpService {
  @Get('/dice')
  @MapPaginatedList(Dice)
  public getDiceList(
    @Query('page') page: number,
    @Query('limit') limit: number,
    @Query('userId') userId?: ObjectId | null
  ): Observable<PaginatedList<Dice>> {
    return EMPTY;
  }

  @Post('/dice')
  @MapClass(Dice)
  public rollDice(
    @Body()
    params: {
      diceString: string;
      rulesSystem: RulesSystem;
      diceTheme: DiceTheme;
      description?: string;
      save?: boolean;
      createChain?: boolean;
    }
  ): Observable<Dice> {
    return EMPTY;
  }

  @Get('/dice/:id')
  @MapClass(Dice)
  public getDiceById(@Path('id') diceId: string): Observable<Dice> {
    return EMPTY;
  }

  @Get('/dice/:id/withUser')
  @MapValue((res: any) => ({ dice: new Dice(res.dice), user: new User(res.user) }))
  public getDiceByIdWithUser(@Path('id') diceId: string): Observable<{ dice: Dice; user: User }> {
    return EMPTY;
  }

  @Put('/dice/:id')
  public updateDiceById(
    @Path('id') diceId: string,
    @Body()
    params: {
      diceString: string;
      rulesSystem: RulesSystem;
      diceTheme: DiceTheme;
      description: string;
      roll: boolean;
    }
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/dice/fromLocal')
  saveSupabaseRoll(@Body('id') rollId: string): Observable<ObjectId> {
    return EMPTY;
  }
}

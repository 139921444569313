import { Injectable } from '@angular/core';
import { Body, Delete, Get, HttpService, MapClass, MapValue, Path, Post, Put } from '@rpg/ngx/http';
import {
  FileCrumb,
  FileReference,
  FolderFileReference,
  mapFileReference,
  ROOT_FOLDER_KEY,
} from '@rpg/core/content-library';
import { EMPTY, Observable } from 'rxjs';
import { ObjectId } from 'bson';

@Injectable({
  providedIn: 'root',
})
export class ContentLibraryHttpService extends HttpService {
  @Get('/content-library/root')
  @MapValue(mapFileReference)
  public getRootFolder(): Observable<FolderFileReference> {
    return EMPTY;
  }

  @Get('/content-library/root/contents')
  @MapValue(mapFileReference)
  public getRootFolderContents(): Observable<FileReference[]> {
    return EMPTY;
  }

  @Get('/content-library/:id')
  @MapValue(mapFileReference)
  public getFileRefById<Type extends FileReference = FileReference>(
    @Path('id') fileRefId: ObjectId
  ): Observable<Type> {
    return EMPTY;
  }

  @Put('/content-library/:id')
  @MapValue(mapFileReference)
  public updateFileRefById<Type extends FileReference = FileReference>(
    @Path('id') fileRefId: ObjectId,
    @Body('name') fileName: string,
    @Body('color') color: string
  ): Observable<Type> {
    return EMPTY;
  }

  @Delete('/content-library/:id')
  public deleteFileRefById(@Path('id') fileRefId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Get('/content-library/:id/contents')
  @MapValue(mapFileReference)
  public getFolderContents(@Path('id') folderId: ObjectId): Observable<FileReference[]> {
    return EMPTY;
  }

  @Get('/content-library/:id/breadcrumbs')
  @MapClass(FileCrumb)
  public getFileRefBreadcrumb(@Path('id') refId: ObjectId): Observable<FileCrumb[]> {
    return EMPTY;
  }

  @Post('/content-library/:id/folder')
  @MapValue(mapFileReference)
  public createFolder(
    @Path('id') parentFolderId: ObjectId | typeof ROOT_FOLDER_KEY,
    @Body('name') name: string
  ): Observable<FolderFileReference> {
    return EMPTY;
  }

  @Put('/content-library/:id/move')
  public moveFile(
    @Path('id') fileId: ObjectId,
    @Body('parentFolderId') parentFolderId: ObjectId
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/content-library/:id/clone')
  public cloneFile(@Path('id') fileId: ObjectId): Observable<ObjectId> {
    return EMPTY;
  }
}

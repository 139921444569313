import { GameTheme, RulesSystem, DiceTheme } from '@rpg/core/base';
import {
  NdsVehicleAttribute,
  NdsVehicleCharacteristic,
  NdsVehicleDefenseZone,
  NdsVehicleDetail,
} from '../enums';
import { NdsVehicle } from './nds-vehicle';
import { NdsVehicleAttributeData } from './nds-vehicle-attribute-data';
import { NdsVehicleCharacteristicData } from './nds-vehicle-characteristic-data';
import { NdsVehicleConfigurationData } from './nds-vehicle-configuration-data';
import { NdsVehicleDetailData } from './nds-vehicle-detail-data';

export const ndsVehicleDefaultData: Partial<NdsVehicle> = {
  attributes: NdsVehicleAttribute.members.map(
    attr =>
      new NdsVehicleAttributeData({
        type: attr,
      })
  ),
  characteristics: NdsVehicleCharacteristic.members.map(
    char =>
      new NdsVehicleCharacteristicData({
        type: char,
      })
  ),
  configuration: new NdsVehicleConfigurationData({
    diceTheme: DiceTheme.Genesys,
    gameTheme: GameTheme.Genesys_Core,
    defenseZones: [NdsVehicleDefenseZone.Fore],
  }),
  image: 'https://images.hdqwalls.com/wallpapers/cyberpunk-2077-game-4k-car-n2.jpg',
  details: NdsVehicleDetail.members.map(
    d =>
      new NdsVehicleDetailData({
        type: d,
      })
  ),
  rulesSystem: RulesSystem.NarrativeDiceSystem,
};

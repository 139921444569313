import { MongoDoc, parseDate } from '@rpg/core/base';
import { ObjectId } from 'bson';

export class GameTableNoteBucket extends MongoDoc {
  public bucketNumber: number = 1;
  public noteIds: ObjectId[] = [];
  public gameId: ObjectId = new ObjectId();
  public startDate: Date = new Date();
  public endDate: Date = new Date();

  constructor(params?: Partial<GameTableNoteBucket>) {
    super(params);
    if (!!params) {
      this._id = params._id ?? this._id;
      this.bucketNumber = params.bucketNumber ?? this.bucketNumber;
      this.noteIds = Array.isArray(params.noteIds) ? params.noteIds : this.noteIds;
      this.gameId = params.gameId ?? this.gameId;
      this.startDate = parseDate(params.startDate, this.startDate);
      this.endDate = parseDate(params.endDate, this.endDate);
    }
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'rpg-list-group',
  templateUrl: './list-group.component.html',
  styleUrls: ['./list-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListGroupComponent {
  @Input()
  public label: string = '';
  private _value: string | number = '';
  @Input()
  public set value(v: string | number) {
    this._value = v;
    this.valueAsString = typeof v === 'string' ? v : v.toString();
  }
  public get value(): string | number {
    return this._value;
  }
  @Input()
  public displayMarkdown: boolean = false;
  @Input()
  public inlineMarkdown: boolean = false;

  public valueAsString: string = '';

  constructor() {}
}

import { Constraints, parseEnum, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsVehicleCharacteristic, NdsVehicleModifierType } from '../../enums';
import { NdsVehicleModifierData } from './nds-vehicle-modifier-data';

export class NdsVehicleModifierCharacteristicData extends NdsVehicleModifierData {
  public type: NdsVehicleModifierType = NdsVehicleModifierType.Characteristic;
  public characteristic!: NdsVehicleCharacteristic;
  public modifierAmount: number = 0;

  constructor(params?: Partial<NdsVehicleModifierCharacteristicData>) {
    super(params);
    if (!!params) {
      this.characteristic = parseEnum(
        NdsVehicleCharacteristic,
        params.characteristic,
        this.characteristic
      );
      this.modifierAmount = parseNumber(params.modifierAmount, this.modifierAmount);
    }
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsVehicleModifierType.Characteristic);
  }

  public static validate(input: NdsVehicleModifierCharacteristicData): string[] {
    const errors: string[] = NdsVehicleModifierData.validate(input);
    errors.push(
      ...RpgClassValidators.enum(input.characteristic, NdsVehicleCharacteristic, {
        enumName: 'Vehicle Characteristic',
      })
    );
    errors.push(...RpgClassValidators.number(input.modifierAmount));
    return errors;
  }
}

import { GameTheme, parseNumber, RulesSystem, DiceTheme, parseEnum } from '@rpg/core/base';
import { DiscordDiceSize } from '@rpg/core/discord';
import { StoryPointsStyle, StoryPointsType } from '../enums';

export class GameSettings {
  public rulesSystem: RulesSystem = RulesSystem.NarrativeDiceSystem;
  public gameTheme: GameTheme = GameTheme.Genesys_Core;
  public diceTheme: DiceTheme = DiceTheme.Genesys;
  public forceDiceEnabled: boolean = false;
  public overrideCharacterDiceTheme: boolean = false;
  public diceSize: DiscordDiceSize = DiscordDiceSize.Large;
  public storyPointStyles: {
    [key in StoryPointsType]?: StoryPointsStyle | null;
  } = {};
  public allowNewPlayers: boolean = true;
  public rngSeed: number = new Date().getTime();

  constructor(params?: Partial<GameSettings>) {
    if (!!params) {
      this.rulesSystem = parseEnum(RulesSystem, params.rulesSystem, this.rulesSystem);
      this.gameTheme = parseEnum(GameTheme, params.gameTheme, this.gameTheme);
      this.diceTheme = parseEnum(DiceTheme, params.diceTheme, this.diceTheme);
      this.forceDiceEnabled = params.forceDiceEnabled ?? this.forceDiceEnabled;
      this.overrideCharacterDiceTheme =
        params.overrideCharacterDiceTheme ?? this.overrideCharacterDiceTheme;
      this.diceSize = parseEnum(DiscordDiceSize.members, params.diceSize, this.diceSize);
      if (!!params.storyPointStyles) {
        Object.entries(params.storyPointStyles).forEach(
          ([key, value]: [string, StoryPointsStyle | undefined | null]) => {
            const type = parseEnum<StoryPointsType>(
              StoryPointsType.members,
              key as StoryPointsType
            );
            const style = parseEnum(StoryPointsStyle, value);
            if (!!type && !!style) {
              this.storyPointStyles[type] = style;
            }
          }
        );
      }
      this.allowNewPlayers = params.allowNewPlayers ?? this.allowNewPlayers;
      this.rngSeed = parseNumber(params.rngSeed, this.rngSeed);
    }

    // Force dice is always enabled for SW theme
    if (this.diceTheme === DiceTheme.StarWars) {
      this.forceDiceEnabled = true;
    }

    // Game Theme is defunct, derive it from the dice theme
    this.gameTheme =
      this.diceTheme === DiceTheme.StarWars ? GameTheme.StarWars_EotE : GameTheme.Genesys_Core;
  }
}

import { MongoDoc } from '@rpg/core/base';
import { DiceDetails } from './dice-details';
import { DiceSingle } from './dice-single';

export class Dice extends MongoDoc {
  public dice: DiceSingle[] = [];
  public details: DiceDetails = new DiceDetails();
  public supabaseId?: string;
  public verified?: boolean;

  constructor(params?: Partial<Dice>) {
    super(params);
    if (!!params) {
      if (Array.isArray(params.dice)) {
        this.dice = params.dice.map(d => new DiceSingle(d));
      }
      if (!!params.details) {
        this.details = new DiceDetails(params.details);
      }
      this.supabaseId = params.supabaseId ?? this.supabaseId;
      this.verified = params.verified ?? this.verified;
    }
  }

  get isVerified(): boolean {
    return typeof this.verified === 'boolean';
  }

  get verifiedStatus(): boolean {
    if (this.dice.length === 0) return true; // Nothing to verify
    if (!this.supabaseId) {
      /**
       * Only client rolls (stored in supabase) require validation, rolls made
       * server-side (no supabaseId) are guarenteed to be authentic
       */
      return true;
    }
    if (typeof this.verified === 'boolean') {
      /**
       * The validation has been done on this dice object already. It could
       * have been done locally, or by the server, but it has been verified
       */
      return this.verified;
    }
    return false;
  }
}

import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { Store } from '@ngxs/store';
import { RpgSelectOption } from '@rpg/core/base';
import { CharacterSort } from '@rpg/core/character';
import { ImportSource } from '@rpg/core/imports';
import { PatreonReward } from '@rpg/core/patreon';
import { User } from '@rpg/core/user';
import {
  CharacterHttpService,
  getErrorMessage,
  ImportHttpService,
  OverlayResponse,
  ToastService,
} from '@rpg/ngx/core';
import { RxState } from '@rx-angular/state';
import { UserState } from '@sessions/state';
import { ObjectId } from 'bson';
import { EMPTY, Observable } from 'rxjs';

interface ImportLimits {
  limit: number;
  current: number;
  hasCustomData: boolean;
}

interface ComponentState {
  loading: boolean;
  error: string;
  limits: ImportLimits | null;
  deleteCustomDataMode: boolean;
  characterOptions: RpgSelectOption<string>[];
}

@Component({
  selector: 'rpg-character-import',
  templateUrl: './character-import.component.html',
  styleUrls: ['./character-import.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class CharacterImportComponent implements OnInit {
  public fileToUpload: File | null = null;
  public fileType: string = 'JSON';
  public title: string = '';
  public characterOptions: { label: string; value: string }[] = [];
  public importForm: FormGroup;
  public isOggDude: boolean = false;
  public customDataImport: boolean = false;
  public isCharacterImport: boolean = false;
  public PatreonReward: typeof PatreonReward = PatreonReward;
  public user: User;
  public state$ = this._state.select();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: { type: ImportSource; parentFolderId?: ObjectId },
    private _dialog: MatDialogRef<CharacterImportComponent, OverlayResponse<void>>,
    private _importHttp: ImportHttpService,
    private _characterHttp: CharacterHttpService,
    private _fb: FormBuilder,
    private _store: Store,
    private _toast: ToastService,
    private _state: RxState<ComponentState>
  ) {
    this._state.set({
      loading: false,
      error: '',
      limits: null,
      deleteCustomDataMode: false,
      characterOptions: [],
    });
    this.user = this._store.selectSnapshot(UserState.currentUser);
    switch (_data.type) {
      case ImportSource.GenesysEmporium:
        this.fileType = 'JSON';
        this.title = 'title-emporium';
        this.isCharacterImport = true;
        break;
      case ImportSource.OggDudeCustomData:
        this.fileType = 'ZIP';
        this.title = 'title-oggdude-custom';
        this.customDataImport = true;
        this.isCharacterImport = false;
        break;
      case ImportSource.OggDude:
        this.fileType = 'XML';
        this.title = 'title-oggdude';
        this.isCharacterImport = true;
        this.isOggDude = true;
        break;
      default:
        this.fileType = 'JSON';
        break;
    }

    this.importForm = this._fb.group({
      characterId: ['', []],
    });
  }

  public async ngOnInit(): Promise<void> {
    this._characterHttp.getCharacterList(CharacterSort.Name).subscribe({
      next: list =>
        this._state.set({
          characterOptions: [
            { value: '', label: 'None' },
            ...list.map(c => ({ value: c._id.toHexString(), label: c.name })),
          ],
        }),
    });

    this._importHttp.getImportLimits().subscribe({
      next: limits => this._state.set({ limits }),
      error: e => this._state.set({ error: getErrorMessage(e) }),
    });
  }

  public cancel(): void {
    this._dialog.close(OverlayResponse.Cancel());
  }

  public handleFileInput(target: any): void {
    const fileCap = 100000000;
    this.fileToUpload = target?.files?.item(0);
    if (!this.fileToUpload) return;
    if (this.fileToUpload.size > fileCap) {
      this._state.set({ error: 'Import file must be under 100 MB' });
      return;
    }

    if (
      this.fileToUpload.type !== 'application/json' &&
      this._data.type === ImportSource.GenesysEmporium
    ) {
      this._state.set({ error: 'Import file must be in a JSON format' });
      return;
    }

    if (this.fileToUpload.type !== 'text/xml' && this._data.type === ImportSource.OggDude) {
      this._state.set({ error: 'Import file must be in an XML format' });
      return;
    }

    if (
      this.fileToUpload.type.indexOf('zip') === -1 &&
      this._data.type === ImportSource.OggDudeCustomData
    ) {
      this._state.set({ error: 'Import file must be in a ZIP format' });
      return;
    }

    this._state.set({ error: '' });
  }

  public async import(): Promise<void> {
    const { error } = this._state.get();
    if (error || !this.fileToUpload) {
      this._state.set({ loading: false });
      return;
    }

    this._state.set({ loading: true });
    let req: Observable<any>;

    if (this._data.type === ImportSource.GenesysEmporium) {
      req = this._importHttp.importEmporiumCharacter(
        this.fileToUpload,
        this.importForm.get('characterId').value,
        this._data.parentFolderId
      );
    } else if (this._data.type === ImportSource.OggDude) {
      req = this._importHttp.importOggDudeCharacter(
        this.fileToUpload,
        this.importForm.get('characterId').value,
        this._data.parentFolderId
      );
    } else if (this._data.type === ImportSource.OggDudeCustomData) {
      req = this._importHttp.importOggDudeCustomData(this.fileToUpload);
    } else {
      req = EMPTY;
    }

    req.subscribe({
      next: () => this._dialog.close(OverlayResponse.Confirm()),
      error: e => {
        this._toast.error(e);
        this._state.set({
          loading: false,
          error: getErrorMessage(e) ?? 'An unknown error occured.',
        });
      },
    });
  }

  public toggleDeleteCustomDataMode(): void {
    const { deleteCustomDataMode } = this._state.get();
    this._state.set({ deleteCustomDataMode: !deleteCustomDataMode });
  }

  public async deleteCustomOggDudeData(): Promise<void> {
    this._importHttp.deleteCustomOggDudeData().subscribe({
      next: () => {
        const { limits } = this._state.get();
        if (!!limits) {
          this._state.set({
            deleteCustomDataMode: false,
            limits: {
              ...limits,
              hasCustomData: false,
            },
          });
        }
      },
      error: e => this._state.set({ error: getErrorMessage(e) }),
    });
  }
}

<span class="sig-talent-connector__spacer"></span>
<span
  class="sig-talent-connector__arrow-container"
  [class.sig-talent-connector--connected]="connected"
  (click)="toggle.emit()"
>
  <span class="sig-talent-connector__arrow-edge-left"></span>
  <span class="sig-talent-connector__arrow-edge-right"></span>
</span>
<span class="sig-talent-connector__spacer"></span>

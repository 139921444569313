import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
  HostBinding,
} from '@angular/core';
import { AdjacentTracker, Edge, Position2D } from '@rpg/core/base';
import { NdsCharacterTalentData } from '@rpg/core/character';

const DEFAULT_BOUNDARIES: { [key in Edge]: number } = {
  [Edge.Up]: 0,
  [Edge.Right]: 0,
  [Edge.Down]: 0,
  [Edge.Left]: 0,
};

@Component({
  selector: 'rpg-nds-character-talent-slot-connectable',
  templateUrl: './talent-slot-connectable.component.html',
  styleUrls: ['./talent-slot-connectable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NdsCharacterTalentSlotConnectableComponent implements OnInit {
  @Input()
  public talentData?: NdsCharacterTalentData;
  @Input()
  public mobileEnabled: boolean = true;
  @Input()
  public mobileSelected: boolean = false;
  @Input()
  public forceMobile: boolean = false;
  @Input()
  public editMode: boolean = false;
  @Input()
  public position!: Position2D;
  @Input()
  public expandable: boolean = false;

  private _columnSpan: number = 1;
  @Input()
  public set columnSpan(span: number) {
    this._columnSpan = span;
    this._setHostSpan(span);
  }
  public get columnSpan(): number {
    return this._columnSpan;
  }

  private _boundaries: { [key in Edge]: number } = DEFAULT_BOUNDARIES;
  @Input()
  public set edgeBoundaries(boundaries: { [key in Edge]: number }) {
    this._boundaries = {
      ...DEFAULT_BOUNDARIES,
      ...boundaries,
    };
  }

  private _trackers: AdjacentTracker[] = [];
  @Input()
  public set adjacencyTrackers(trackers: AdjacentTracker[]) {
    this._trackers = trackers;
  }
  public get adjacencyTrackers(): AdjacentTracker[] {
    return this._trackers;
  }

  @Output()
  public talentContentClick = new EventEmitter<void>();
  @Output()
  public talentPurchasedToggle = new EventEmitter<boolean>();
  @Output()
  public updateAdjacency = new EventEmitter<{
    edge: Edge;
    node: AdjacentTracker;
    offset: number;
  }>();
  @Output()
  public expandNode = new EventEmitter<void>();

  @HostBinding('class') public hostClass: { [key: string]: boolean } = {};

  public Edge: typeof Edge = Edge;

  constructor() {}

  ngOnInit(): void {
    if (!this.position) {
      throw new Error('Position is required to init a Connectable Talent Slot!');
    }
  }

  public get canUp(): boolean {
    return this.position.y > this._boundaries[Edge.Up];
  }

  public get canRight(): boolean {
    return this.position.x + (this.columnSpan - 1) < this._boundaries[Edge.Right];
  }

  public get canDown(): boolean {
    return this.position.y < this._boundaries[Edge.Down];
  }

  public get canLeft(): boolean {
    return this.position.x > this._boundaries[Edge.Left];
  }

  public get firstAdjacency(): AdjacentTracker {
    return this.adjacencyTrackers[0];
  }

  public get lastAdjacency(): AdjacentTracker {
    return this.adjacencyTrackers[this.adjacencyTrackers.length - 1];
  }

  public toggleEdge(edge: Edge, node: AdjacentTracker, offset: number): void {
    if (this.editMode) {
      this.updateAdjacency.emit({ edge, node, offset });
    }
  }

  private _setHostSpan(span: number): void {
    let foundExistingKey: boolean = false;
    Object.keys(this.hostClass).forEach(key => {
      if (key.startsWith('span')) {
        if (key === `span-${span}`) {
          this.hostClass[key] = true;
          foundExistingKey = true;
        } else {
          this.hostClass[key] = false;
        }
      }
    });
    if (!foundExistingKey) {
      this.hostClass[`span-${span}`] = true;
    }
    this.hostClass = { ...this.hostClass };
  }
}

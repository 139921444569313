import { Injectable } from '@angular/core';

// Redirect data is only stored here for a max of 3 seconds
const TTL = 3000;

@Injectable({
  providedIn: 'root',
})
export class RedirectDataService<T = any> {
  private _expirationTime = 0;
  private _heldData: T | null = null;

  public set data(data: T | null) {
    this._heldData = data;
    this._expirationTime = Date.now() + TTL;
  }

  public get data(): T | null {
    const currentTime = Date.now();
    if (this._expirationTime > currentTime && !!this._heldData) {
      return this._heldData;
    }
    this._heldData = null;
    return null;
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  ViewChild,
  Optional,
  HostBinding,
  ElementRef,
  Output,
  EventEmitter,
  AfterContentInit,
  OnDestroy,
  ContentChild,
  HostListener,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormControl,
  FormGroupDirective,
  AbstractControl,
} from '@angular/forms';
import { FormFieldComponent } from '../form-field/form-field.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { DiceType, DiceInputTextScheme } from '@rpg/core/dice';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MatDialog } from '@angular/material/dialog';
import { FormattingHelpComponent } from '../../overlays/formatting-help/formatting-help.component';
import { Logger } from '@rpg/core/base';
import { DiceWheelComponent } from '../../shared';
import { MatInput } from '@angular/material/input';

// prettier-ignore
@Component({
  selector: 'rpg-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent
  implements OnInit, AfterContentInit, OnDestroy, ControlValueAccessor
// eslint-disable-next-line brace-style
{
  @Input()
  public placeholder: string = '';
  @Input()
  // eslint-disable-next-line no-magic-numbers
  public rows: number = 3;
  @Input()
  public inputControl!: AbstractControl;
  @Input()
  public value: string = '';
  private _disabled: boolean = false;
  @Input()
  public set disabled(val: boolean) {
    this._disabled = val;
    if (!!this.inputControl) {
      if (val) {
        this.inputControl.disable();
      } else {
        this.inputControl.enable();
      }
    }
  }
  @Input()
  @HostBinding('class.has-formatting')
  public formatting: boolean = false;
  @Input()
  public prefixIconName: IconProp | null = null;
  @Input()
  public suffixIconName: IconProp | null = null;
  @Output()
  public prefixClick: EventEmitter<void> = new EventEmitter();
  @Output()
  public suffixClick: EventEmitter<void> = new EventEmitter();
  @Output()
  public clickOutside: EventEmitter<void> = new EventEmitter();

  @ViewChild(FormFieldComponent)
  public formField!: FormFieldComponent;
  @ViewChild('textInput', { read: ElementRef })
  public textarea!: ElementRef<HTMLTextAreaElement>;
  @ViewChild(MatInput)
  public matInput!: MatInput;
  private _wheel: DiceWheelComponent | null = null;
  @ContentChild(DiceWheelComponent, { read: DiceWheelComponent, static: false })
  public set wheelComponent(comp: DiceWheelComponent) {
    this._wheel = comp ?? null;
    this._configureWheelEvents(comp);
  }
  @HostBinding('class.has-focus')
  public hasFocus: boolean = false;
  public _lastCursorPosition: number | undefined = undefined;

  private _unsub: Subject<void> = new Subject<void>();
  private _wheelSubscriptions: Subscription[] = [];

  @HostListener('rpgFocus')
  public onFocus(): void {
    this.focus();
  }

  constructor(@Optional() public fg: FormGroupDirective, private _dialog: MatDialog) {}

  ngOnInit(): void {
    if (!this.inputControl) {
      this.inputControl = new UntypedFormControl(typeof this.value === 'string' ? this.value : '');
      if (this._disabled) {
        this.inputControl.disable();
      }
    }
  }

  ngAfterContentInit(): void {
    if (!!this.fg) {
      this.fg.ngSubmit.pipe(takeUntil(this._unsub)).subscribe(event => {
        this.formField._checkForChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this._unsub.next();
    this._unsub.complete();
  }

  public get control(): UntypedFormControl {
    return this.inputControl as UntypedFormControl;
  }

  public focus(): void {
    this.matInput.focus();
  }

  public onTouched: () => void = () => {};

  public writeValue(val: any): void {
    this.inputControl.setValue(val);
  }

  public registerOnChange(fn: any): void {
    this.inputControl.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  public showFormattingHelp(): void {
    this._dialog.open(FormattingHelpComponent);
  }

  public setFocus(isFocused: boolean): void {
    this.hasFocus = isFocused;
  }

  public onClickOutside(): void {
    this.hasFocus = false;
    this.clickOutside.emit();
  }

  public addDice(type: DiceType): void {
    Logger.log(`addDice @ ${this._lastCursorPosition}`);
    let diceText = DiceInputTextScheme.fromDiceType(type) as string;
    const val: string = this.inputControl.value;
    if (this._lastCursorPosition !== undefined) {
      const beforeText = val.slice(0, this._lastCursorPosition);
      const afterText = val.slice(this._lastCursorPosition);
      if (beforeText.length > 0 && beforeText[beforeText.length - 1] !== ' ') {
        diceText = ' ' + diceText;
      }
      if (afterText.length === 0 || afterText[0] !== ' ') {
        diceText = diceText + ' ';
      }
      this.inputControl.setValue([beforeText, diceText, afterText].join(''));
    } else {
      if (val.length > 0 && val[val.length - 1] !== ' ') {
        diceText = ' ' + diceText;
      }
      diceText = diceText + ' ';
      this.inputControl.setValue(val + diceText);
    }
    this._lastCursorPosition = (this._lastCursorPosition ?? 0) + diceText.length;
    this.resetFocus(this._lastCursorPosition);
  }

  public resetFocus(position: number): void {
    if (this.hasFocus) {
      this.textarea.nativeElement.focus({
        preventScroll: true,
      });
      this.textarea.nativeElement.setSelectionRange(position, position);
    }
  }

  private _configureWheelEvents(wheel: DiceWheelComponent | null): void {
    if (!wheel) {
      this._wheelSubscriptions.forEach(x => x.unsubscribe());
      return;
    }
    this._wheelSubscriptions.push(
      wheel.didClose.subscribe(() => this.resetFocus(this._lastCursorPosition ?? 0)),
      wheel.didOpen.subscribe(() => this.resetFocus(this._lastCursorPosition ?? 0)),
      wheel.dice.subscribe(type => this.addDice(type))
    );
  }
}

import { parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { FileType } from '../enums';
import { FileReference } from './file-reference';

export class GameFileReference extends FileReference {
  public type: FileType = FileType.Game;
  public referenceDocId!: ObjectId;

  constructor(params?: Partial<GameFileReference>) {
    super(params);
    if (!!params) {
      this.type = FileType.Game;
      this.referenceDocId = parseObjectId(params.referenceDocId, this.referenceDocId);
    }
  }
}

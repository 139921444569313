<div
  class="message-container"
  [class.message-container--success]="!isSaving && status === true"
  [class.message-container--error]="!isSaving && status === false"
  [class.message-container--saving]="isSaving"
  [class.message-container--fade]="fade"
>
  <fa-icon *ngIf="isSaving" class="in-progress" icon="sync-alt" [spin]="true"></fa-icon>
  <fa-icon *ngIf="!isSaving && status === true" icon="check"></fa-icon>
  <fa-icon *ngIf="!isSaving && status === false" icon="exclamation-triangle"></fa-icon>
  <span class="message-container__message" *ngIf="!isSaving && !!message">
    {{ message }}
  </span>
</div>

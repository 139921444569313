import { Constraints, parseBoolean, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';
import { NdsCharacterModifierType } from '../../enums';
import { NdsCharacterModifierData } from './nds-character-modifier-data';

export class NdsCharacterModifierWeaponData extends NdsCharacterModifierData {
  public type: NdsCharacterModifierType = NdsCharacterModifierType.Weapon;
  public linkedWeaponId: string = '';
  public extraDice: DiceType[] = [];
  public includeUncommittedForceDice: boolean = false;
  public damageModifier: number = 0;
  public critModifier: number = 0;
  public encumbranceModifier: number = 0;

  constructor(params?: Partial<NdsCharacterModifierWeaponData>) {
    super(params);
    if (!!params) {
      this.linkedWeaponId = params.linkedWeaponId ?? this.linkedWeaponId;
      this.extraDice = Array.isArray(params.extraDice) ? params.extraDice : this.extraDice;
      this.includeUncommittedForceDice = parseBoolean(
        params.includeUncommittedForceDice,
        this.includeUncommittedForceDice
      );
      this.damageModifier = parseNumber(params.damageModifier, this.damageModifier);
      this.critModifier = parseNumber(params.critModifier, this.critModifier);
      this.encumbranceModifier = parseNumber(params.encumbranceModifier, this.encumbranceModifier);
    }
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsCharacterModifierType.Weapon);
  }

  public static validate(
    input: NdsCharacterModifierWeaponData,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = NdsCharacterModifierData.validate(input, fieldNamePrefix);
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Modifier ${
      input.name ? `(${input.name})` : ''
    }`;
    errors.push(
      ...RpgClassValidators.string(input.linkedWeaponId, { fieldName: `${name} Linked Weapon` })
    );
    errors.push(
      ...RpgClassValidators.enum(input.extraDice, DiceType, {
        enumName: 'Dice Type',
        isArray: true,
        fieldName: `${name} Extra Dice`,
      })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.includeUncommittedForceDice, {
        fieldName: `${name} Uncommitted Force Dice`,
      })
    );
    errors.push(
      ...RpgClassValidators.number(input.damageModifier, { fieldName: `${name} Damage` })
    );
    errors.push(
      ...RpgClassValidators.number(input.encumbranceModifier, { fieldName: `${name} Encumbrance` })
    );
    errors.push(...RpgClassValidators.number(input.critModifier, { fieldName: `${name} Crit` }));
    return errors;
  }
}

import { DiceType } from './dice-type';

export enum DiceInputTextScheme {
  EoteProficiency = ':eP:',
  EoteAbility = ':eA:',
  EoteBoost = ':eB:',
  EoteChallenge = ':eC:',
  EoteDifficulty = ':eD:',
  EoteSetback = ':eK:',
  EoteRemoveSetback = ':eX:',
  EoteForce = ':eF:',
  EoteTriumph = ':e!:',
  EoteSuccess = ':e*:',
  EoteAdvantage = ':e^:',
  EoteDespair = ':e$:',
  EoteFailure = ':e-:',
  EoteThreat = ':e%:',
  EoteLightSide = ':eL:',
  EoteDarkSide = ':eN:',
  EoteLightDarkSplit = ':eLN:',
  EotePositiveUpgrade = ':eU:',
  EoteNegativeUpgrade = ':eV:',

  GenesysProficiency = ':gP:',
  GenesysAbility = ':gA:',
  GenesysBoost = ':gB:',
  GenesysChallenge = ':gC:',
  GenesysDifficulty = ':gD:',
  GenesysSetback = ':gK:',
  GenesysRemoveSetback = ':gX:',
  GenesysTriumph = ':g!:',
  GenesysSuccess = ':g*:',
  GenesysAdvantage = ':g^:',
  GenesysDespair = ':g$:',
  GenesysFailure = ':g-:',
  GenesysThreat = ':g%:',
  GenesysPositiveUpgrade = ':gU:',
  GenesysNegativeUpgrade = ':gV:',

  NdsProficiency = ':nP:',
  NdsAbility = ':nA:',
  NdsBoost = ':nB:',
  NdsChallenge = ':nC:',
  NdsDifficulty = ':nD:',
  NdsSetback = ':nK:',
  NdsRemoveSetback = ':nX:',
  NdsForce = ':nF:',
  NdsTriumph = ':n!:',
  NdsSuccess = ':n*:',
  NdsAdvantage = ':n^:',
  NdsDespair = ':n$:',
  NdsFailure = ':n-:',
  NdsThreat = ':n%:',
  NdsLightSide = ':nL:',
  NdsDarkSide = ':nN:',
  NdsLightDarkSplit = ':nLN:',
  NdsPositiveUpgrade = ':nU:',
  NdsNegativeUpgrade = ':nV:',
}

export namespace DiceInputTextScheme {
  export const members: DiceInputTextScheme[] = [
    DiceInputTextScheme.EoteProficiency,
    DiceInputTextScheme.EoteAbility,
    DiceInputTextScheme.EoteBoost,
    DiceInputTextScheme.EoteChallenge,
    DiceInputTextScheme.EoteDifficulty,
    DiceInputTextScheme.EoteSetback,
    DiceInputTextScheme.EoteRemoveSetback,
    DiceInputTextScheme.EoteForce,
    DiceInputTextScheme.EoteTriumph,
    DiceInputTextScheme.EoteSuccess,
    DiceInputTextScheme.EoteAdvantage,
    DiceInputTextScheme.EoteDespair,
    DiceInputTextScheme.EoteFailure,
    DiceInputTextScheme.EoteThreat,
    DiceInputTextScheme.EoteLightSide,
    DiceInputTextScheme.EoteDarkSide,
    DiceInputTextScheme.EoteLightDarkSplit,
    DiceInputTextScheme.EotePositiveUpgrade,
    DiceInputTextScheme.EoteNegativeUpgrade,
    DiceInputTextScheme.GenesysProficiency,
    DiceInputTextScheme.GenesysAbility,
    DiceInputTextScheme.GenesysBoost,
    DiceInputTextScheme.GenesysChallenge,
    DiceInputTextScheme.GenesysDifficulty,
    DiceInputTextScheme.GenesysSetback,
    DiceInputTextScheme.GenesysRemoveSetback,
    DiceInputTextScheme.GenesysTriumph,
    DiceInputTextScheme.GenesysSuccess,
    DiceInputTextScheme.GenesysAdvantage,
    DiceInputTextScheme.GenesysDespair,
    DiceInputTextScheme.GenesysFailure,
    DiceInputTextScheme.GenesysThreat,
    DiceInputTextScheme.GenesysPositiveUpgrade,
    DiceInputTextScheme.GenesysNegativeUpgrade,
    DiceInputTextScheme.NdsProficiency,
    DiceInputTextScheme.NdsAbility,
    DiceInputTextScheme.NdsBoost,
    DiceInputTextScheme.NdsChallenge,
    DiceInputTextScheme.NdsDifficulty,
    DiceInputTextScheme.NdsSetback,
    DiceInputTextScheme.NdsRemoveSetback,
    DiceInputTextScheme.NdsForce,
    DiceInputTextScheme.NdsTriumph,
    DiceInputTextScheme.NdsSuccess,
    DiceInputTextScheme.NdsAdvantage,
    DiceInputTextScheme.NdsDespair,
    DiceInputTextScheme.NdsFailure,
    DiceInputTextScheme.NdsThreat,
    DiceInputTextScheme.NdsLightSide,
    DiceInputTextScheme.NdsDarkSide,
    DiceInputTextScheme.NdsLightDarkSplit,
    DiceInputTextScheme.NdsPositiveUpgrade,
    DiceInputTextScheme.NdsNegativeUpgrade,
  ];

  export function toParseOptions(): { [key in DiceInputTextScheme]: [DiceInputTextScheme] } {
    // Screw Intellisense
    const parseable: any = {};
    DiceInputTextScheme.members.forEach(m => {
      parseable[m] = [m];
    });
    return parseable;
  }

  export function toDiceType(type: DiceInputTextScheme): DiceType | null {
    switch (type) {
      case DiceInputTextScheme.EoteProficiency:
      case DiceInputTextScheme.GenesysProficiency:
      case DiceInputTextScheme.NdsProficiency:
        return DiceType.NdsProficiency;
      case DiceInputTextScheme.EoteAbility:
      case DiceInputTextScheme.GenesysAbility:
      case DiceInputTextScheme.NdsAbility:
        return DiceType.NdsAbility;
      case DiceInputTextScheme.EoteBoost:
      case DiceInputTextScheme.GenesysBoost:
      case DiceInputTextScheme.NdsBoost:
        return DiceType.NdsBoost;
      case DiceInputTextScheme.EoteChallenge:
      case DiceInputTextScheme.GenesysChallenge:
      case DiceInputTextScheme.NdsChallenge:
        return DiceType.NdsChallenge;
      case DiceInputTextScheme.EoteDifficulty:
      case DiceInputTextScheme.GenesysDifficulty:
      case DiceInputTextScheme.NdsDifficulty:
        return DiceType.NdsDifficulty;
      case DiceInputTextScheme.EoteSetback:
      case DiceInputTextScheme.GenesysSetback:
      case DiceInputTextScheme.NdsSetback:
        return DiceType.NdsSetback;
      case DiceInputTextScheme.EoteRemoveSetback:
      case DiceInputTextScheme.GenesysRemoveSetback:
      case DiceInputTextScheme.NdsRemoveSetback:
        return DiceType.NdsRemoveSetback;
      case DiceInputTextScheme.EoteForce:
      case DiceInputTextScheme.NdsForce:
        return DiceType.NdsForce;
      case DiceInputTextScheme.EoteTriumph:
      case DiceInputTextScheme.GenesysTriumph:
      case DiceInputTextScheme.NdsTriumph:
        return DiceType.NdsTriumph;
      case DiceInputTextScheme.EoteSuccess:
      case DiceInputTextScheme.GenesysSuccess:
      case DiceInputTextScheme.NdsSuccess:
        return DiceType.NdsSuccess;
      case DiceInputTextScheme.EoteAdvantage:
      case DiceInputTextScheme.GenesysAdvantage:
      case DiceInputTextScheme.NdsAdvantage:
        return DiceType.NdsAdvantage;
      case DiceInputTextScheme.EoteDespair:
      case DiceInputTextScheme.GenesysDespair:
      case DiceInputTextScheme.NdsDespair:
        return DiceType.NdsDespair;
      case DiceInputTextScheme.EoteFailure:
      case DiceInputTextScheme.GenesysFailure:
      case DiceInputTextScheme.NdsFailure:
        return DiceType.NdsFailure;
      case DiceInputTextScheme.EoteThreat:
      case DiceInputTextScheme.GenesysThreat:
      case DiceInputTextScheme.NdsThreat:
        return DiceType.NdsThreat;
      case DiceInputTextScheme.EoteLightSide:
      case DiceInputTextScheme.NdsLightSide:
        return DiceType.NdsLightSide;
      case DiceInputTextScheme.EoteDarkSide:
      case DiceInputTextScheme.NdsDarkSide:
        return DiceType.NdsDarkSide;
      case DiceInputTextScheme.EoteLightDarkSplit:
      case DiceInputTextScheme.NdsLightDarkSplit:
        return DiceType.NdsLightDarkSplit;
      case DiceInputTextScheme.EotePositiveUpgrade:
      case DiceInputTextScheme.GenesysPositiveUpgrade:
      case DiceInputTextScheme.NdsPositiveUpgrade:
        return DiceType.NdsPositiveUpgrade;
      case DiceInputTextScheme.EoteNegativeUpgrade:
      case DiceInputTextScheme.GenesysNegativeUpgrade:
      case DiceInputTextScheme.NdsNegativeUpgrade:
        return DiceType.NdsNegativeUpgrade;
      default:
        return null;
    }
  }

  export function fromDiceType(type: DiceType): DiceInputTextScheme | null {
    switch (type) {
      case DiceType.EoteProficiency:
      case DiceType.GenesysProficiency:
      case DiceType.NdsProficiency:
        return DiceInputTextScheme.NdsProficiency;
      case DiceType.EoteAbility:
      case DiceType.GenesysAbility:
      case DiceType.NdsAbility:
        return DiceInputTextScheme.NdsAbility;
      case DiceType.EoteBoost:
      case DiceType.GenesysBoost:
      case DiceType.NdsBoost:
        return DiceInputTextScheme.NdsBoost;
      case DiceType.EoteChallenge:
      case DiceType.GenesysChallenge:
      case DiceType.NdsChallenge:
        return DiceInputTextScheme.NdsChallenge;
      case DiceType.EoteDifficulty:
      case DiceType.GenesysDifficulty:
      case DiceType.NdsDifficulty:
        return DiceInputTextScheme.NdsDifficulty;
      case DiceType.EoteSetback:
      case DiceType.GenesysSetback:
      case DiceType.NdsSetback:
        return DiceInputTextScheme.NdsSetback;
      case DiceType.EoteRemoveSetback:
      case DiceType.GenesysRemoveSetback:
      case DiceType.NdsRemoveSetback:
        return DiceInputTextScheme.NdsRemoveSetback;
      case DiceType.EoteForce:
      case DiceType.NdsForce:
        return DiceInputTextScheme.NdsForce;
      case DiceType.EoteTriumph:
      case DiceType.GenesysTriumph:
      case DiceType.NdsTriumph:
        return DiceInputTextScheme.NdsTriumph;
      case DiceType.EoteSuccess:
      case DiceType.GenesysSuccess:
      case DiceType.NdsSuccess:
        return DiceInputTextScheme.NdsSuccess;
      case DiceType.EoteAdvantage:
      case DiceType.GenesysAdvantage:
      case DiceType.NdsAdvantage:
        return DiceInputTextScheme.NdsAdvantage;
      case DiceType.EoteDespair:
      case DiceType.GenesysDespair:
      case DiceType.NdsDespair:
        return DiceInputTextScheme.NdsDespair;
      case DiceType.EoteFailure:
      case DiceType.GenesysFailure:
      case DiceType.NdsFailure:
        return DiceInputTextScheme.NdsFailure;
      case DiceType.EoteThreat:
      case DiceType.GenesysThreat:
      case DiceType.NdsThreat:
        return DiceInputTextScheme.NdsThreat;
      case DiceType.EoteLightSide:
      case DiceType.NdsLightSide:
        return DiceInputTextScheme.NdsLightSide;
      case DiceType.EoteDarkSide:
      case DiceType.NdsDarkSide:
        return DiceInputTextScheme.NdsDarkSide;
      case DiceType.EoteLightDarkSplit:
      case DiceType.NdsLightDarkSplit:
        return DiceInputTextScheme.NdsLightDarkSplit;
      case DiceType.EotePositiveUpgrade:
      case DiceType.GenesysPositiveUpgrade:
      case DiceType.NdsPositiveUpgrade:
        return DiceInputTextScheme.NdsPositiveUpgrade;
      case DiceType.EoteNegativeUpgrade:
      case DiceType.GenesysNegativeUpgrade:
      case DiceType.NdsNegativeUpgrade:
        return DiceInputTextScheme.NdsNegativeUpgrade;
      default:
        return null;
    }
  }

  export function getBlankFaceKey(type: DiceInputTextScheme): string {
    switch (type) {
      case DiceInputTextScheme.EoteTriumph:
      case DiceInputTextScheme.GenesysTriumph:
      case DiceInputTextScheme.NdsTriumph:
        return 'r';
      case DiceInputTextScheme.EoteSuccess:
      case DiceInputTextScheme.GenesysSuccess:
      case DiceInputTextScheme.NdsSuccess:
        return 's';
      case DiceInputTextScheme.EoteAdvantage:
      case DiceInputTextScheme.GenesysAdvantage:
      case DiceInputTextScheme.NdsAdvantage:
        return 'a';
      case DiceInputTextScheme.EoteDespair:
      case DiceInputTextScheme.GenesysDespair:
      case DiceInputTextScheme.NdsDespair:
        return 'd';
      case DiceInputTextScheme.EoteFailure:
      case DiceInputTextScheme.GenesysFailure:
      case DiceInputTextScheme.NdsFailure:
        return 'f';
      case DiceInputTextScheme.EoteThreat:
      case DiceInputTextScheme.GenesysThreat:
      case DiceInputTextScheme.NdsThreat:
        return 't';
      default:
        return 'b';
    }
  }
}

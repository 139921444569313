import { createId, parseNumber, RpgClassValidators } from '@rpg/core/base';

export class NdsCharacterHeroicAbilityUpgradeData {
  public id: string = '';
  public name: string = '';
  public cost: number = 0;
  public ranks: number = 0;
  public description: string = '';

  constructor(params?: Partial<NdsCharacterHeroicAbilityUpgradeData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.cost = parseNumber(params.cost, this.cost);
      this.ranks = parseNumber(params.ranks, this.ranks);
      this.description = params.description ?? this.description;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(
    input: NdsCharacterHeroicAbilityUpgradeData,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = [];
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Upgrade ${
      input.name ? `(${input.name})` : ''
    }`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(...RpgClassValidators.number(input.cost, { fieldName: `${name} Cost` }));
    errors.push(...RpgClassValidators.number(input.ranks, { fieldName: `${name} Ranks` }));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    return errors;
  }
}

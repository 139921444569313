import { parseISO } from 'date-fns';
import { isNotNull } from './is-not-null';

type RequiredDate = string | number | Date;
type OptionalDate = RequiredDate | null | undefined;
const INVALID_DATE = 'Invalid Date';

export function parseDate(toParse: OptionalDate): Date | null;
export function parseDate(toParse: OptionalDate, defaultValue: RequiredDate): Date;
export function parseDate(toParse: OptionalDate, defaultValue?: RequiredDate): Date | null {
  if (toParse instanceof Date && toParse.toString() !== INVALID_DATE) {
    return new Date(Date.parse(toParse.toISOString()));
  } else if (typeof toParse === 'string') {
    const parsed = parseISO(toParse);
    if (parsed.toString() !== INVALID_DATE) {
      return parsed;
    }
  } else if (typeof toParse === 'number' && !isNaN(toParse)) {
    return new Date(toParse);
  }
  if (defaultValue instanceof Date) {
    return new Date(Date.parse(defaultValue.toISOString()));
  } else if (typeof defaultValue === 'string') {
    return parseISO(defaultValue);
  } else if (typeof defaultValue === 'number') {
    return new Date(defaultValue);
  } else {
    return null;
  }
}

export function parseDateArray(val: Array<OptionalDate>): Array<Date | null>;
export function parseDateArray(val: Array<OptionalDate>, filterNullValues: true): Array<Date>;
export function parseDateArray(
  val: Array<OptionalDate>,
  filterNullValues: boolean = false
): Array<Date | null> {
  const mappedValues = val.map(id => parseDate(id));
  if (filterNullValues) {
    return mappedValues.filter(isNotNull);
  }
  return mappedValues;
}

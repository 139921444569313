import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterTalentConfigData } from '@rpg/core/character';
import { ndsCharacterTalentListGroupToFormGroup } from './nds-character-talent-list-group-to-form-group';
import { ndsCharacterTalentToFormGroup } from './nds-character-talent-to-form-group';
import { ndsCharacterTalentTreeToFormGroup } from './nds-character-talent-tree-to-form-group';

export function ndsCharacterTalentConfigToFormGroup(
  config: NdsCharacterTalentConfigData
): FormGroup<NdsCharacterTalentConfigData> {
  return new FormGroup<NdsCharacterTalentConfigData>({
    talents: new FormArray(config.talents.map(t => ndsCharacterTalentToFormGroup(t))),
    trees: new FormArray(config.trees.map(t => ndsCharacterTalentTreeToFormGroup(t))),
    listGroups: new FormArray(
      config.listGroups.map(t => ndsCharacterTalentListGroupToFormGroup(t))
    ),
    pyramidPositionManager: new FormControl(config.pyramidPositionManager),
  });
}

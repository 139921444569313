export namespace RouterActions {
  export class Navigate {
    static readonly type = '[Router] Navigate';
    constructor(public route: string | string[]) {}
  }

  export class InitialNavigation {
    static readonly type = '[Router] Initial Navigation';
  }
}

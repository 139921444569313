import { parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterMoralityEntryData } from './nds-character-morality-entry-data';

export class NdsCharacterMoralityData {
  public score: number = 0;
  public sessionScore: number = 0;
  public moralities: NdsCharacterMoralityEntryData[] = [];

  constructor(params?: Partial<NdsCharacterMoralityData>) {
    if (!!params) {
      this.score = parseNumber(params.score, this.score);
      this.sessionScore = parseNumber(params.sessionScore, this.sessionScore);
      this.moralities = Array.isArray(params.moralities)
        ? params.moralities.map(m => new NdsCharacterMoralityEntryData(m))
        : this.moralities;
    }
  }

  public static validate(input: NdsCharacterMoralityData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.number(input.score, { fieldName: `Morality Score` }));
    errors.push(
      ...RpgClassValidators.number(input.sessionScore, { fieldName: `Morality Session Score` })
    );
    errors.push(
      ...input.moralities.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterMoralityEntryData.validate(next)],
        []
      )
    );
    return errors;
  }
}

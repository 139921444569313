import { parseEnum } from '../../base';
import { DiscordChannelType } from '../enums';

export class DiscordChannel {
  public id: string = '';
  public name: string = '';
  public type: DiscordChannelType = DiscordChannelType.GuildText;

  constructor(params?: Partial<DiscordChannel>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.type = parseEnum(DiscordChannelType, params.type, this.type);
    }
  }
}

<div class="rpg-list-group" [class.rpg-list-group--column]="displayMarkdown && !inlineMarkdown">
  <label class="rpg-list-group__label">{{ label }}:</label>
  <span *ngIf="!displayMarkdown" class="rpg-list-group__value">
    {{ value }} <ng-content></ng-content>
  </span>
  <markdown
    *ngIf="displayMarkdown"
    class="rpg-list-group__markdown"
    [data]="valueAsString"
  ></markdown>
</div>

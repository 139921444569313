import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsCharacterCritData } from '@rpg/core/character';

export function ndsCharacterCritToFormGroup(
  crit: NdsCharacterCritData
): FormGroup<NdsCharacterCritData> {
  return new FormGroup<NdsCharacterCritData & Expandable>({
    expanded: new FormControl((crit as any)['expanded'] ?? false),
    id: new FormControl(crit.id),
    name: new FormControl(crit.name, [Validators.required]),
    description: new FormControl(crit.description),
    severity: new FormControl(crit.severity),
    difficulty: new FormControl(crit.difficulty),
  });
}

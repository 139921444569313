import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsCharacterFavorData } from '@rpg/core/character';

export function ndsCharacterFavorToFormGroup(
  favor: NdsCharacterFavorData
): FormGroup<NdsCharacterFavorData> {
  return new FormGroup<NdsCharacterFavorData & Expandable>({
    expanded: new FormControl((favor as any)['expanded'] ?? false),
    description: new FormControl(favor.description),
    faction: new FormControl(favor.faction),
    favorSize: new FormControl(favor.favorSize),
    id: new FormControl(favor.id),
    resolved: new FormControl(favor.resolved),
  });
}

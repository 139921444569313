import { RulesSystem, unreachable } from '@rpg/core/base';
import { BaseVehicle } from '../models';
import { NdsVehicle } from '../rules-nds';

export function validateBaseVehicle(data: BaseVehicle): string[] {
  let errors: string[] = [];
  switch (data.rulesSystem) {
    case RulesSystem.NarrativeDiceSystem:
      errors = NdsVehicle.validate(data as any);
      break;
    case RulesSystem.AGE:
    case RulesSystem.Generic:
      break;
    default:
      unreachable(data.rulesSystem, 'validateBaseVehicle');
  }
  return errors;
}

import { ObjectId } from 'bson';
import { MongoDoc, GameTheme, RulesSystem, parseObjectIdArray } from '@rpg/core/base';
import { CritTable } from '@rpg/core/crit';
import { DiscordGameLink } from '@rpg/core/discord';
import { PatreonReward } from '@rpg/core/patreon';
import { GameFeature, GameTableTokenRef, GameTokenType } from '@rpg/core/table-token';
import { User } from '@rpg/core/user';
import { GameLinkType, StoryPointsType } from '../enums';
import { GameMember } from './game-member';
import { GameNPC } from './game-npc';
import { GamePassedDiceRef } from './game-passed-dice-ref';
import { PartyVehicle } from './party-vehicle';
import { GameSettings } from './game-settings';
import { GameFlags } from './game-flags';
import { SWCharacterCritTable, SWVehicleCritTable } from './ffg-sw-crit-tables';
import { GENCharacterCritTable, GENVehicleCritTable } from './ffg-gen-crit-tables';
import { Initiative } from './initiative';

export class Game extends MongoDoc {
  public name: string = '';
  public image: string = '';
  public linkType: GameLinkType = GameLinkType.RPGSessions;
  public discordLink: DiscordGameLink = new DiscordGameLink();
  public ownerId: ObjectId | null = null;
  public gameMasters: ObjectId[] = [];
  public members: GameMember[] = [];
  public npcs: GameNPC[] = [];
  public passedDice: GamePassedDiceRef[] = [];
  public partyVehicle: PartyVehicle | null = null;
  public settings: GameSettings = new GameSettings();
  public initiative: Initiative = new Initiative();
  public storyPoints: StoryPointsType[] = [];
  public critTable!: CritTable;
  public vehicleCritTable!: CritTable;
  public flags: GameFlags = new GameFlags();
  public tableTokens: GameTableTokenRef[] = [];
  public bannedUserIds: ObjectId[] = [];
  public supabaseId?: string;

  constructor(params?: Partial<Game>) {
    super(params);
    if (!!params) {
      this.name = params.name || this.name;
      this.linkType = params.linkType ?? this.linkType;
      this.discordLink = !!params.discordLink
        ? new DiscordGameLink(params.discordLink)
        : this.discordLink;
      this.ownerId = params.ownerId || this.ownerId;
      this.gameMasters = Array.isArray(params.gameMasters)
        ? parseObjectIdArray(params.gameMasters)
        : this.gameMasters;
      this.members = Array.isArray(params.members)
        ? params.members.map(m => new GameMember(m))
        : this.members;
      this.npcs = Array.isArray(params.npcs) ? params.npcs.map(n => new GameNPC(n)) : this.npcs;
      this.passedDice = Array.isArray(params.passedDice)
        ? params.passedDice.map(p => new GamePassedDiceRef(p))
        : this.passedDice;
      this.partyVehicle = !!params.partyVehicle
        ? new PartyVehicle(params.partyVehicle)
        : this.partyVehicle;
      this.settings = !!params.settings ? new GameSettings(params.settings) : this.settings;
      this.initiative = !!params.initiative ? new Initiative(params.initiative) : this.initiative;
      this.storyPoints = Array.isArray(params.storyPoints)
        ? [...params.storyPoints]
        : this.storyPoints;
      this.critTable = !!params.critTable ? new CritTable(params.critTable) : this.critTable;
      this.vehicleCritTable = !!params.vehicleCritTable
        ? new CritTable(params.vehicleCritTable)
        : this.vehicleCritTable;
      this.flags = !!params.flags ? new GameFlags(params.flags) : this.flags;
      this.tableTokens = Array.isArray(params.tableTokens)
        ? params.tableTokens.map(t => new GameTableTokenRef(t))
        : this.tableTokens;
      this.bannedUserIds = !!params.bannedUserIds
        ? parseObjectIdArray(params.bannedUserIds)
        : this.bannedUserIds;
      this.supabaseId = params.supabaseId;
    }
    // Apply Corrected Tables
    if (GameTheme.starWars.includes(this.settings.gameTheme)) {
      this.critTable = SWCharacterCritTable;
      this.vehicleCritTable = SWVehicleCritTable;
    } else if (GameTheme.genesys.includes(this.settings.gameTheme)) {
      this.critTable = GENCharacterCritTable;
      this.vehicleCritTable = GENVehicleCritTable;
    }

    /**
     * TODO: This is only temp for development, this will be a scripted migration later
     */
    if (!!(params as any)?.['gameType']) {
      const gt = (params as any)['gameType'];
      this.settings.rulesSystem =
        gt === '[gameType] ffg star wars' || gt === '[gameType] ffg genesys'
          ? RulesSystem.NarrativeDiceSystem
          : this.settings.rulesSystem;
      this.settings.gameTheme =
        gt === '[gameType] ffg star wars' ? GameTheme.StarWars_EotE : GameTheme.Genesys_Core;
    }
  }

  public getMember(user: User): GameMember | null;
  public getMember(userId?: ObjectId | string | null): GameMember | null;
  public getMember(userOrId?: User | ObjectId | string | null): GameMember | null {
    if (!userOrId) return null;
    if (this.members.length === 0) return null;
    const userId: ObjectId | string = userOrId instanceof User ? userOrId._id : userOrId;
    return this.members.find(x => x.userId.equals(userId)) ?? null;
  }

  public isMember(user: User): boolean;
  public isMember(userId?: ObjectId | string | null): boolean;
  public isMember(userOrId?: User | ObjectId | string | null): boolean {
    if (!userOrId) return false;
    if (this.members.length === 0) return false;
    const userId: ObjectId | string = userOrId instanceof User ? userOrId._id : userOrId;
    return (this.members?.filter(x => x.userId.equals(userId)).length ?? -1) > 0;
  }

  public isGM(user: User): boolean;
  public isGM(userId?: ObjectId | string | null): boolean;
  public isGM(userOrId?: User | ObjectId | string | null): boolean {
    if (!userOrId) return false;
    if (this.gameMasters.length === 0) return false;
    const userId: ObjectId | string = userOrId instanceof User ? userOrId._id : userOrId;
    return (this.gameMasters?.filter(x => x.equals(userId)).length ?? -1) > 0;
  }

  public isUserBanned(user: User): boolean;
  public isUserBanned(userId?: ObjectId | string | null): boolean;
  public isUserBanned(userOrId?: User | ObjectId | string | null): boolean {
    if (!userOrId) return false;
    if (this.bannedUserIds.length === 0) return false;
    const userId: ObjectId | string = userOrId instanceof User ? userOrId._id : userOrId;
    return (this.bannedUserIds?.filter(x => x.equals(userId)).length ?? -1) > 0;
  }

  /**
   * Check if this game has any game feature or patreon reward currently enabled.
   * Pass a user class object to also check if the user has the patreon reward
   */
  public featureEnabled(feature: GameFeature | PatreonReward, user?: User): boolean {
    if (PatreonReward.members.includes(feature as PatreonReward)) {
      return this.isPatreonRewardEnabled(feature as PatreonReward, user);
    } else if (GameFeature.members.includes(feature as GameFeature)) {
      return this.isGameFeatureEnabled(feature as GameFeature);
    }
    return false;
  }

  /**
   * Checks if any of the table tokens have the reward in question. If not,
   * then we fallback to checking the user if one was provided
   */
  private isPatreonRewardEnabled(reward: PatreonReward, user?: User): boolean {
    let isEnabled: boolean = false;
    this.tableTokens.forEach(token => {
      isEnabled = isEnabled || GameTokenType.rewards(token.type).includes(reward);
    });
    if (!isEnabled && !!user) {
      isEnabled = user.hasReward(reward);
    }
    return isEnabled;
  }

  /**
   * Checks if any of the table tokens have the feature in question
   */
  private isGameFeatureEnabled(feature: GameFeature): boolean {
    let isEnabled: boolean = false;
    this.tableTokens.forEach(token => {
      isEnabled = isEnabled || GameTokenType.features(token.type).includes(feature);
    });
    return isEnabled;
  }
}

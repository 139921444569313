import {
  createId,
  EdgeMatrix,
  Position2D,
  Position2DGridManager,
  Position2DNodeRef,
  RpgClassValidators,
} from '@rpg/core/base';
import { NDS_CHARACTER_TREE_HEIGHT, NDS_CHARACTER_TREE_WIDTH } from './tree-size-constants';

export const NDS_CHARACTER_FORCE_TREE_WIDTH = NDS_CHARACTER_TREE_WIDTH;
export const NDS_CHARACTER_FORCE_TREE_HEIGHT = NDS_CHARACTER_TREE_HEIGHT;

export class NdsCharacterForceTreeData {
  public id: string = '';
  public name: string = '';
  public edgeMatrix: EdgeMatrix = new EdgeMatrix(
    NDS_CHARACTER_FORCE_TREE_WIDTH,
    NDS_CHARACTER_FORCE_TREE_HEIGHT
  );
  public positionManager: Position2DGridManager = new Position2DGridManager({
    width: NDS_CHARACTER_FORCE_TREE_WIDTH,
    height: NDS_CHARACTER_FORCE_TREE_HEIGHT,
  });
  public basePowerId: string = '';

  constructor(params?: Partial<NdsCharacterForceTreeData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.edgeMatrix = !!params.edgeMatrix ? new EdgeMatrix(params.edgeMatrix) : this.edgeMatrix;
      this.positionManager = !!params.positionManager
        ? new Position2DGridManager(params.positionManager)
        : this.positionManager;
      this.basePowerId = params.basePowerId ?? this.basePowerId;
    }
    if (!this.id) {
      this.id = createId();
    }
    if (this.positionManager.nodes.length === 0) {
      // If tree is Empty, we should add a blank node with a span of 4,
      // since force trees always start with a single, tree-spanning talent
      this.positionManager.addNode(
        new Position2DNodeRef({
          position: new Position2D(0, 0),
          span: NDS_CHARACTER_FORCE_TREE_WIDTH,
        })
      );
    }
  }

  public static validate(input: NdsCharacterForceTreeData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id, { fieldName: 'Force Tree ID' }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: 'Force Tree' }));
    return errors;
  }
}

<router-outlet></router-outlet>
<button
  class="translation"
  *ngIf="!isProduction"
  mat-fab
  color="primary"
  (click)="toggleTranslations()"
>
  ✅
</button>
<button *ngIf="!isProduction" mat-fab [color]="_color" (click)="toggleTheme()">
  <fa-icon [icon]="_icon"></fa-icon>
</button>

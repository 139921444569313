Array; /* Taken from NGX-CLIPBOARD npm package and adjusted to be compatible with Angular Universal SSR and Angular 5 */

import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { ClipboardService } from '@rpg/ngx/core';

@Directive({
  selector: '[rpgClipboard]',
})
export class ClipboardDirective implements OnDestroy {
  @Input('rpgClipboard') public targetElm!: ElementRef | any;
  @Input() public rpgClipboardContent!: string;
  @Output() public rpgClipboardOnSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() public rpgClipboardOnError: EventEmitter<any> = new EventEmitter<any>();

  constructor(private clipboardSrv: ClipboardService, private renderer: Renderer2) {}

  public ngOnDestroy(): void {
    if (!!this.clipboardSrv) {
      this.clipboardSrv.destroy();
    }
  }

  @HostListener('click', ['$event.target'])
  public onClick(): void {
    if (!this.clipboardSrv.isSupported) {
      this.handleResult(false, undefined);
    } else if (this.targetElm && this.clipboardSrv.isTargetValid(this.targetElm)) {
      this.handleResult(
        this.clipboardSrv.copyFromInputElement(this.targetElm, this.renderer),
        this.targetElm.nativeElement.value
      );
    } else if (this.rpgClipboardContent) {
      this.handleResult(
        this.clipboardSrv.copyFromContent(this.rpgClipboardContent, this.renderer),
        this.rpgClipboardContent
      );
    }
  }

  /**
   * Fires an event based on the copy operation result.
   * @param { boolean } succeeded was the copy successful
   * @param { string | undefined } copiedContent what was copied
   * @returns { void }
   */
  private handleResult(succeeded: boolean, copiedContent: string | undefined): void {
    if (succeeded) {
      this.rpgClipboardOnSuccess.emit({ isSuccess: true, content: copiedContent });
    } else {
      this.rpgClipboardOnError.emit({ isSuccess: false });
    }
  }
}

import { MongoDoc, parseDateArray, parseEnum, parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { addMilliseconds, differenceInMilliseconds, formatDistance } from 'date-fns';
import { GameTokenSource, GameTokenType } from '../enums';

export const GAME_TABLE_TOKEN_MAX_MOVEMENTS = 2;
// eslint-disable-next-line no-magic-numbers
export const GAME_TABLE_TOKEN_COOL_DOWN_DURATION = 1000 * 60 * 60 * 24 * 7; // 7 Days

export class GameTableToken extends MongoDoc {
  public userId: ObjectId | null = null;
  public gameId: ObjectId | null = null;
  public type: GameTokenType = GameTokenType.Champion;
  public source: GameTokenSource = GameTokenSource.Admin;
  public createdByUserId: ObjectId | null = null;
  public movedTimestamps: Date[] = [];

  constructor(params?: Partial<GameTableToken>) {
    super(params);
    if (!!params) {
      this.userId = parseObjectId(params.userId);
      this.gameId = parseObjectId(params.gameId);
      this.type = parseEnum(GameTokenType, params.type, this.type);
      this.source = parseEnum(GameTokenSource, params.source, this.source);
      this.createdByUserId = parseObjectId(params.createdByUserId);
      this.movedTimestamps = Array.isArray(params.movedTimestamps)
        ? parseDateArray(params.movedTimestamps, true)
        : this.movedTimestamps;
    }
  }
  public get canMove(): boolean {
    if (this.movedTimestamps.length < GAME_TABLE_TOKEN_MAX_MOVEMENTS) {
      return true;
    }
    if (
      differenceInMilliseconds(new Date(), this.movedTimestamps[0]) >
      GAME_TABLE_TOKEN_COOL_DOWN_DURATION
    ) {
      return true;
    }
    return false;
  }

  public get timeUntilMoveAvailable(): string {
    const time =
      GAME_TABLE_TOKEN_COOL_DOWN_DURATION -
      differenceInMilliseconds(new Date(), this.movedTimestamps[0]);
    const dateAvailable = addMilliseconds(new Date(), time);
    return formatDistance(new Date(), dateAvailable);
  }
}

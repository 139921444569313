import { unreachable } from '@rpg/core/base';
import { NdsVehicleModifierType } from '../enums';
import {
  NdsVehicleModifierActionData,
  NdsVehicleModifierAttributeData,
  NdsVehicleModifierCharacteristicData,
  NdsVehicleModifierData,
  NdsVehicleModifierNarrativeData,
  NdsVehicleModifierWeaponData,
} from '../models/modifiers';

export function NdsVehicleModifierValidatorFactory(modifier: NdsVehicleModifierData): string[] {
  switch (modifier?.type) {
    case NdsVehicleModifierType.Action:
      return NdsVehicleModifierActionData.validate(modifier as NdsVehicleModifierActionData);
    case NdsVehicleModifierType.Attribute:
      return NdsVehicleModifierAttributeData.validate(modifier as NdsVehicleModifierAttributeData);
    case NdsVehicleModifierType.Characteristic:
      return NdsVehicleModifierCharacteristicData.validate(
        modifier as NdsVehicleModifierCharacteristicData
      );
    case NdsVehicleModifierType.Narrative:
      return NdsVehicleModifierNarrativeData.validate(modifier as NdsVehicleModifierNarrativeData);
    case NdsVehicleModifierType.Weapon:
      return NdsVehicleModifierWeaponData.validate(modifier as NdsVehicleModifierWeaponData);
    default:
      try {
        unreachable(modifier.type, 'NdsVehicleModifierValidatorFactory');
      } catch (e) {
        return [`${modifier.type} is not a valid modifier type`];
      }
  }
}

import { parseEnum } from '@rpg/core/base';
import { DiscordDiceSize, DiscordServerStructure } from '../enums';
import { DiscordChannel } from './discord-channel';
import { DiscordLink } from './discord-link';

export class DiscordGameLink extends DiscordLink {
  public diceChannel: DiscordChannel | null = null;
  public initiativeChannel: DiscordChannel | null = null;
  public destinyChannel: DiscordChannel | null = null;
  public serverStructure: DiscordServerStructure = DiscordServerStructure.BaseGame;
  public includedChannels: DiscordChannel[] = [];
  public shouldMirrorMembers: boolean = true;
  public webhookID: string = '';
  public webhookToken: string = '';
  public diceSize: DiscordDiceSize = DiscordDiceSize.Large;

  constructor(params?: Partial<DiscordGameLink>) {
    super(params);
    if (!!params) {
      if (!!params.diceChannel) {
        this.diceChannel = new DiscordChannel(params.diceChannel);
      }
      if (!!params.initiativeChannel) {
        this.initiativeChannel = new DiscordChannel(params.initiativeChannel);
      }
      if (!!params.destinyChannel) {
        this.destinyChannel = new DiscordChannel(params.destinyChannel);
      }
      this.serverStructure = parseEnum(
        DiscordServerStructure,
        params.serverStructure,
        this.serverStructure
      );
      this.includedChannels = Array.isArray(params.includedChannels)
        ? params.includedChannels.map(i => new DiscordChannel(i))
        : this.includedChannels;
      this.shouldMirrorMembers = params.shouldMirrorMembers ?? this.shouldMirrorMembers;
      this.webhookID = params.webhookID ?? this.webhookID;
      this.webhookToken = params.webhookToken ?? this.webhookToken;
      this.diceSize = parseEnum(DiscordDiceSize, params.diceSize, this.diceSize);
    }
  }
}

import { FormArray, FormGroup } from '@ngneat/reactive-forms';
import { NonFunctionProperties } from '@rpg/core/base';
import { NdsCharacter } from '@rpg/core/character';
import { baseCharacterToFormGroup } from '../../character';
import {
  ndsCharacterArmorToFormGroup,
  ndsCharacterAttributeToFormGroup,
  ndsCharacterCharacteristicToFormGroup,
  ndsCharacterConfigurationToFormGroup,
  ndsCharacterCritToFormGroup,
  ndsCharacterDetailToFormGroup,
  ndsCharacterDutyToFormGroup,
  ndsCharacterEquipmentToFormGroup,
  ndsCharacterFavorToFormGroup,
  ndsCharacterForcePowerConfigToFormGroup,
  ndsCharacterHeroicAbilityToFormGroup,
  NDSCharacterModifierFormGroupFactory,
  ndsCharacterMoralityToFormGroup,
  ndsCharacterObligationToFormGroup,
  ndsCharacterSkillToFormGroup,
  ndsCharacterTalentConfigToFormGroup,
  ndsCharacterWeaponToFormGroup,
} from './form-converters';

export function ndsCharacterToFormGroup(
  char: NdsCharacter
): FormGroup<NonFunctionProperties<NdsCharacter>> {
  return new FormGroup<NonFunctionProperties<NdsCharacter>>({
    ...baseCharacterToFormGroup(char).controls,
    armor: new FormArray(char.armor.map(a => ndsCharacterArmorToFormGroup(a))),
    attributes: new FormArray(char.attributes.map(a => ndsCharacterAttributeToFormGroup(a))),
    characteristics: new FormArray(
      char.characteristics.map(c => ndsCharacterCharacteristicToFormGroup(c))
    ),
    configuration: ndsCharacterConfigurationToFormGroup(char.configuration),
    crits: new FormArray(char.crits.map(c => ndsCharacterCritToFormGroup(c))),
    details: new FormArray(char.details.map(d => ndsCharacterDetailToFormGroup(d))),
    duty: ndsCharacterDutyToFormGroup(char.duty),
    equipment: new FormArray(char.equipment.map(e => ndsCharacterEquipmentToFormGroup(e))),
    favors: new FormArray(char.favors.map(f => ndsCharacterFavorToFormGroup(f))),
    forcePowers: ndsCharacterForcePowerConfigToFormGroup(char.forcePowers),
    heroicAbilities: new FormArray(
      char.heroicAbilities.map(h => ndsCharacterHeroicAbilityToFormGroup(h))
    ),
    modifiers: new FormArray(char.modifiers.map(m => NDSCharacterModifierFormGroupFactory(m))),
    morality: ndsCharacterMoralityToFormGroup(char.morality),
    obligation: ndsCharacterObligationToFormGroup(char.obligation),
    skills: new FormArray(char.skills.map(s => ndsCharacterSkillToFormGroup(s))),
    talents: ndsCharacterTalentConfigToFormGroup(char.talents),
    weapons: new FormArray(char.weapons.map(w => ndsCharacterWeaponToFormGroup(w))),
  });
}

<rpg-form-field>
  <fa-icon matPrefix *ngIf="prefixIconName" [icon]="prefixIconName"></fa-icon>
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    [formControl]="control"
    (selectionChange)="change.emit($event)"
    [panelClass]="panelClass"
    [multiple]="multiple"
  >
    <mat-select-trigger *ngIf="markdownLabel" class="markdown-label">
      <markdown [data]="getTranslatedInput(getLabel(control.value))"></markdown>
    </mat-select-trigger>
    <mat-option *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled">
      <markdown [data]="getTranslatedInput(option.label)"></markdown>
    </mat-option>
  </mat-select>
  <mat-error [rpgFormFieldError]="control"></mat-error>
  <fa-icon matSuffix *ngIf="suffixIconName" [icon]="suffixIconName"></fa-icon>
</rpg-form-field>

import { createId, parseBoolean, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterModifierFactory } from '../functions/nds-character-modifier-factory';
import { NdsCharacterModifierValidatorFactory } from '../functions/nds-character-modifier-validator-factory';
import { NdsCharacterModifierData } from './modifiers';
import { NdsCharacterAttachmentModificationData } from './nds-character-attachment-modification-data';

export class NdsCharacterAttachmentData {
  public id: string = '';
  public name: string = '';
  public description: string = '';
  public equipped: boolean = false;
  public cost: string = '';
  public rarity: number = 0;
  public restricted: boolean = false;
  public hardPoints: number = 0;
  public modifiers: NdsCharacterModifierData[] = [];
  public modifications: NdsCharacterAttachmentModificationData[] = [];

  constructor(params?: Partial<NdsCharacterAttachmentData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.description = params.description ?? this.description;
      this.equipped = parseBoolean(params.equipped, this.equipped);
      this.cost = params.cost ?? this.cost;
      this.rarity = parseNumber(params.rarity, this.rarity);
      this.restricted = parseBoolean(params.restricted, this.restricted);
      this.hardPoints = parseNumber(params.hardPoints, this.hardPoints);
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(m => NdsCharacterModifierFactory(m)).filter(m => !!m.name)
        : this.modifiers;
      this.modifications = Array.isArray(params.modifications)
        ? params.modifications.map(m => new NdsCharacterAttachmentModificationData(m))
        : this.modifications;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(
    input: NdsCharacterAttachmentData,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = [];
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Attachment ${
      input.name ? `(${input.name})` : ''
    }`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    errors.push(...RpgClassValidators.boolean(input.equipped, { fieldName: `${name} Equipped` }));
    errors.push(
      ...RpgClassValidators.string(input.cost, { allowEmpty: true, fieldName: `${name} Cost` })
    );
    errors.push(...RpgClassValidators.number(input.rarity, { fieldName: `${name} Rarity` }));
    errors.push(
      ...RpgClassValidators.boolean(input.restricted, { fieldName: `${name} Restricted` })
    );
    errors.push(
      ...RpgClassValidators.number(input.hardPoints, { fieldName: `${name} Hard Points` })
    );
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterModifierValidatorFactory(next, fieldNamePrefix)],
        []
      )
    );
    errors.push(
      ...input.modifications.reduce<string[]>(
        (acc, next) => [
          ...acc,
          ...NdsCharacterAttachmentModificationData.validate(next, fieldNamePrefix),
        ],
        []
      )
    );
    return errors;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showPlusMinus',
})
export class RpgShowPlusMinusPipe implements PipeTransform {
  public transform(value: string | number): string | number {
    let parsed: number = 0;
    if (typeof value === 'number') {
      parsed = value;
    } else {
      parsed = parseInt(value, 10);
      if (!isNaN(parsed)) {
        return value;
      }
    }
    if (parsed >= 0) {
      return `+${value}`;
    } else {
      return `${value}`;
    }
  }
}

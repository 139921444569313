import { GameTheme } from '@rpg/core/base';

export enum StoryPointsType {
  Player = '[StoryPointsType] player',
  GM = '[StoryPointsType] gm',
}

export namespace StoryPointsType {
  export const members: StoryPointsType[] = [StoryPointsType.Player, StoryPointsType.GM];

  export function toString(type?: StoryPointsType | null, theme?: GameTheme): string {
    if (!!theme && GameTheme.starWars.includes(theme)) {
      return StoryPointsType.toStarWarsString(type);
    }
    return StoryPointsType.toGenesysString(type);
  }

  export function toGenesysString(type?: StoryPointsType | null): string {
    switch (type) {
      case StoryPointsType.Player:
        return 'Player';
      case StoryPointsType.GM:
        return 'GM';
      default:
        return 'Story Points';
    }
  }

  export function toStarWarsString(type?: StoryPointsType | null): string {
    switch (type) {
      case StoryPointsType.Player:
        return 'Light Side';
      case StoryPointsType.GM:
        return 'Dark Side';
      default:
        return 'Destiny Points';
    }
  }

  export function toClass(type: StoryPointsType): string {
    switch (type) {
      case StoryPointsType.Player:
        return 'Player';
      case StoryPointsType.GM:
        return 'GM';
      default:
        return '';
    }
  }

  export function toEnum(type: string): StoryPointsType | null {
    switch (`${type}`.toLowerCase().trim()) {
      case 'p':
      case 'pc':
      case 'player':
      case 'light':
      case 'lightside':
      case 'l':
        return StoryPointsType.Player;
      case 'g':
      case 'gm':
      case 'dark':
      case 'darkside':
      case 'd':
        return StoryPointsType.GM;
      default:
        return null;
    }
  }
}

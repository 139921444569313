import {
  Injectable,
  Inject,
  PLATFORM_ID,
  InjectionToken,
  Optional,
  ApplicationRef,
} from '@angular/core';
import {
  ReplaySubject,
  Observable,
  timer,
  share,
  switchMap,
  retryWhen,
  delay,
  tap,
  distinctUntilChanged,
  startWith,
  take,
  first,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

interface ConnectionState {
  isConnected: boolean;
}

export interface ConnectionOptions {
  heartbeatUrl: string;
}

export const RPG_HTTP_CONNECTION_OPTIONS = new InjectionToken<string>(
  'rpg.http.connection.options'
);

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private _status: ReplaySubject<ConnectionState> = new ReplaySubject();
  private _defaultOptions: ConnectionOptions = {
    heartbeatUrl: 'http://localhost:5131/heartbeat/alive',
  };

  public status: Observable<ConnectionState> = this._status.asObservable().pipe(
    distinctUntilChanged(),
    share(),
    startWith({
      isConnected: isPlatformBrowser(this._platformId) ? window.navigator.onLine : true,
    })
  );

  constructor(
    private _http: HttpClient,
    private _appRef: ApplicationRef,
    @Inject(PLATFORM_ID) private _platformId: any,
    @Inject(RPG_HTTP_CONNECTION_OPTIONS)
    @Optional()
    public options: ConnectionOptions
  ) {
    this.options = {
      ...this._defaultOptions,
      ...this.options,
    };
    if (isPlatformBrowser(this._platformId)) {
      this._determineInitialState();
    }
  }

  public get isOnline(): boolean {
    let isOnline = false;
    this.status.pipe(take(1)).subscribe(o => (isOnline = o.isConnected));
    return isOnline;
  }

  private _determineInitialState(): void {
    const heartbeatTimer = 30000;
    const retryDelay = 5000;
    this._appRef.isStable
      .pipe(
        first(isStable => isStable === true),
        switchMap(() => timer(0, heartbeatTimer)),
        switchMap(() => this._http.get(this.options.heartbeatUrl)),
        retryWhen(errors =>
          errors.pipe(
            tap(() => {
              this._status.next({
                isConnected: false,
              });
            }),
            delay(retryDelay)
          )
        )
      )
      .subscribe(() => {
        this._status.next({
          isConnected: true,
        });
      });
  }
}

import { ObjectId } from 'bson';
import {
  RulesSystem,
  MongoDoc,
  normalizeString,
  parseEnum,
  parseObjectId,
  RpgClassValidators,
  BaseContentConfiguration,
} from '@rpg/core/base';
import { ImportData } from '@rpg/core/imports';
import { VehicleOptions } from './vehicle-options';

export const VEHICLE_MAX_IMAGE_LENGTH = 2000;

export class BaseVehicle extends MongoDoc {
  public name: string = '';
  public lookupName: string = '';
  public image: string = '';
  public notes: string = '';
  public privateNotes: string = '';
  public rulesSystem: RulesSystem = RulesSystem.Generic;
  public configuration: BaseContentConfiguration = new BaseContentConfiguration();
  public userId!: ObjectId;
  public userFavorite: boolean = false;
  public clonedCount: number = 0;
  public clonedFromVehicleId: ObjectId | null = null;
  public importData: ImportData | null = null;
  public options: VehicleOptions = new VehicleOptions();
  public tempClone: boolean = false;

  constructor(params?: Partial<BaseVehicle>) {
    super(params);
    if (!!params) {
      this.name = params.name ?? this.name;
      this.lookupName = params.lookupName ?? this.lookupName;
      this.image = params.image ?? this.image;
      this.notes = params.notes ?? this.notes;
      this.privateNotes = params.privateNotes ?? this.privateNotes;
      this.rulesSystem = parseEnum(RulesSystem, params.rulesSystem, this.rulesSystem);
      this.configuration = !!params.configuration
        ? new BaseContentConfiguration(params.configuration)
        : this.configuration;
      this.userId = parseObjectId(params.userId, this.userId);
      this.userFavorite = params.userFavorite ?? this.userFavorite;
      this.clonedCount = params.clonedCount ?? this.clonedCount;
      this.clonedFromVehicleId = parseObjectId(params.clonedFromVehicleId);
      this.importData = !!params.importData ? new ImportData(params.importData) : this.importData;
      this.options = !!params.options ? new VehicleOptions(params.options) : this.options;
      this.tempClone = params.tempClone ?? this.tempClone;
    }
    // Enforce Constraints
    if (this.image.length > VEHICLE_MAX_IMAGE_LENGTH) {
      this.image = '';
    }
    if (!this.lookupName) {
      this.lookupName = normalizeString(this.name);
    }
  }

  public static validate(input: BaseVehicle): string[] {
    const errors: string[] = [];
    errors.push(
      ...RpgClassValidators.string(input.name, {
        fieldName: 'Vehicle Name',
      })
    );
    errors.push(...RpgClassValidators.string(input.lookupName, { fieldName: 'Lookup Name' }));
    errors.push(
      ...RpgClassValidators.string(input.image, {
        allowEmpty: true,
        maxLength: VEHICLE_MAX_IMAGE_LENGTH,
        fieldName: 'Vehicle Image',
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.notes, { allowEmpty: true, fieldName: 'Notes' })
    );
    errors.push(
      ...RpgClassValidators.string(input.privateNotes, {
        allowEmpty: true,
        fieldName: 'Private Notes',
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.rulesSystem, RulesSystem, {
        enumName: 'Rules System',
      })
    );
    errors.push(...BaseContentConfiguration.validate(input.configuration));
    errors.push(...RpgClassValidators.boolean(input.userFavorite));
    errors.push(...RpgClassValidators.number(input.clonedCount, { min: 0 }));
    return errors;
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'rpg-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input()
  // eslint-disable-next-line no-magic-numbers
  public diameter: number = 100;

  @Input()
  public color: 'primary' | 'accent' | 'warn' | 'text' = 'primary';

  constructor() {}
}

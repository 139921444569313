<!--
I disabled the menu's backdrop and made my own because the mat menu's doesn't respond
to context menu clicks. This still isn't ideal as it doesn't break out of the
parent container and actually cover the entire page, which is probably a little
problematic, but don't really want to put in the effort to solve right now.

It's worth noting that the mat menu's backdrop will break the context and cover
the entire page, but since we're using it as a context menu, it's a bit unexpected
that you can't right click from one item onto another item.
 -->
<div
  class="context-menu__backdrop"
  *ngIf="menuOpen"
  (click)="closeMenu()"
  (contextmenu)="closeMenu($event)"
></div>
<div
  class="context-menu"
  [matMenuTriggerFor]="contextMenu"
  [style.top.px]="menuPos?.y"
  [style.left.px]="menuPos?.x"
></div>
<mat-menu #contextMenu="matMenu" [hasBackdrop]="false">
  <ng-content></ng-content>
</mat-menu>

import { RulesSystem } from '@rpg/core/base';
import { BaseVehicle } from '../../models';
import { NdsVehicleModifierFactory } from '../functions/nds-vehicle-modifier-factory';
import { NdsVehicleModifierValidatorFactory } from '../functions/nds-vehicle-modifier-validator-factory';
import { NdsVehicleModifierData } from './modifiers';
import { NdsVehicleActionData } from './nds-vehicle-action-data';
import { NdsVehicleAttachmentData } from './nds-vehicle-attachment-data';
import { NdsVehicleAttributeData } from './nds-vehicle-attribute-data';
import { NdsVehicleCharacteristicData } from './nds-vehicle-characteristic-data';
import { NdsVehicleConfigurationData } from './nds-vehicle-configuration-data';
import { NdsVehicleCritData } from './nds-vehicle-crit-data';
import { NdsVehicleDetailData } from './nds-vehicle-detail-data';
import { NdsVehicleWeaponData } from './nds-vehicle-weapon-data';

export class NdsVehicle extends BaseVehicle {
  public actions: NdsVehicleActionData[] = [];
  public attachments: NdsVehicleAttachmentData[] = [];
  public attributes: NdsVehicleAttributeData[] = [];
  public characteristics: NdsVehicleCharacteristicData[] = [];
  public configuration: NdsVehicleConfigurationData = new NdsVehicleConfigurationData();
  public crits: NdsVehicleCritData[] = [];
  public details: NdsVehicleDetailData[] = [];
  public modifiers: NdsVehicleModifierData[] = [];
  public weapons: NdsVehicleWeaponData[] = [];

  constructor(params?: Partial<NdsVehicle>) {
    super(params);
    if (!!params) {
      this.actions = Array.isArray(params.actions)
        ? params.actions.map(a => new NdsVehicleActionData(a))
        : this.actions;
      this.attachments = Array.isArray(params.attachments)
        ? params.attachments.map(a => new NdsVehicleAttachmentData(a))
        : this.attachments;
      this.attributes = Array.isArray(params.attributes)
        ? params.attributes.map(a => new NdsVehicleAttributeData(a))
        : this.attributes;
      this.characteristics = Array.isArray(params.characteristics)
        ? params.characteristics.map(a => new NdsVehicleCharacteristicData(a))
        : this.characteristics;
      this.configuration = !!params.configuration
        ? new NdsVehicleConfigurationData(params.configuration)
        : this.configuration;
      this.crits = Array.isArray(params.crits)
        ? params.crits.map(a => new NdsVehicleCritData(a))
        : this.crits;
      this.details = Array.isArray(params.details)
        ? params.details.map(a => new NdsVehicleDetailData(a))
        : this.details;
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(a => NdsVehicleModifierFactory(a))
        : this.modifiers;
      this.weapons = Array.isArray(params.weapons)
        ? params.weapons.map(a => new NdsVehicleWeaponData(a))
        : this.weapons;
    }
    this.rulesSystem = params?.rulesSystem ?? RulesSystem.NarrativeDiceSystem;
  }

  public static validate(input: NdsVehicle): string[] {
    const errors: string[] = BaseVehicle.validate(input);
    errors.push(...NdsVehicleConfigurationData.validate(input.configuration));
    errors.push(
      ...input.actions.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleActionData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.attachments.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleAttachmentData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.attributes.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleAttributeData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.characteristics.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleCharacteristicData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.crits.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleCritData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.details.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleDetailData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleModifierValidatorFactory(next)],
        []
      )
    );
    errors.push(
      ...input.weapons.reduce<string[]>(
        (acc, next) => [...acc, ...NdsVehicleWeaponData.validate(next)],
        []
      )
    );
    return errors.filter(e => typeof e === 'string');
  }
}

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rpg-nds-character-talent-signature-connector',
  templateUrl: './talent-signature-connector.component.html',
  styleUrls: ['./talent-signature-connector.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NdsCharacterTalentSignatureConnectorComponent {
  @Input()
  public connected: boolean = false;

  @Output()
  public toggle: EventEmitter<void> = new EventEmitter();

  constructor() {}
}

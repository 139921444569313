export enum FeatureFlag {
  GenesysTwilightImperium = '[FeatureFlag] genesys twilight imperium',
}

export namespace FeatureFlag {
  export const members: FeatureFlag[] = [FeatureFlag.GenesysTwilightImperium];

  export function getDefaults(): Record<FeatureFlag, boolean> {
    const defaults: { [key in FeatureFlag]?: boolean } = {};
    FeatureFlag.members.forEach(flag => (defaults[flag] = false));
    return defaults as Record<FeatureFlag, boolean>;
  }
}

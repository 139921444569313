import { createId, parseBoolean, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterModifierFactory } from '../functions/nds-character-modifier-factory';
import { NdsCharacterModifierValidatorFactory } from '../functions/nds-character-modifier-validator-factory';
import { NdsCharacterModifierData } from './modifiers';

export class NdsCharacterAttachmentModificationData {
  public id: string = '';
  public name: string = '';
  public description: string = '';
  public enabled: boolean = false;
  public modifiers: NdsCharacterModifierData[] = [];

  constructor(params?: Partial<NdsCharacterAttachmentModificationData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.description = params.description ?? this.description;
      this.enabled = parseBoolean(params.enabled, this.enabled);
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(m => NdsCharacterModifierFactory(m)).filter(m => !!m.name)
        : this.modifiers;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(
    input: NdsCharacterAttachmentModificationData,
    fieldNamePrefix: string = ''
  ): string[] {
    const errors: string[] = [];
    const name = `${fieldNamePrefix ? fieldNamePrefix + ' ' : ''}Attachment Mod ${
      input.name ? `(${input.name})` : ''
    }`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    errors.push(...RpgClassValidators.boolean(input.enabled, { fieldName: `${name} Enabled` }));
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterModifierValidatorFactory(next, fieldNamePrefix)],
        []
      )
    );
    return errors;
  }
}

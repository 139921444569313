import { GameTheme, parseEnum, RpgClassValidators, DiceTheme } from '@rpg/core/base';

export class BaseContentConfiguration {
  public diceTheme: DiceTheme = DiceTheme.Generic;
  public gameTheme: GameTheme = GameTheme.Generic;

  constructor(params?: Partial<BaseContentConfiguration>) {
    if (!!params) {
      this.gameTheme = parseEnum(GameTheme, params.gameTheme, this.gameTheme);
      this.diceTheme = parseEnum(DiceTheme, params.diceTheme, this.diceTheme);
    }
  }

  public static validate(input: BaseContentConfiguration): string[] {
    const errors: string[] = [];
    errors.push(
      ...RpgClassValidators.enum(input.diceTheme, DiceTheme, {
        enumName: 'Dice Theme',
        fieldName: 'Dice Theme',
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.gameTheme, GameTheme, {
        enumName: 'Game Theme',
        fieldName: 'Game Theme',
      })
    );
    return errors;
  }
}

import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleDetailData } from '@rpg/core/vehicle';

export function ndsVehicleDetailToFormGroup(
  detail: NdsVehicleDetailData
): FormGroup<NdsVehicleDetailData> {
  return new FormGroup<NdsVehicleDetailData>({
    type: new FormControl(detail.type, [Validators.required]),
    value: new FormControl(detail.value),
  });
}

import { parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterDutyEntryData } from './nds-character-duty-entry-data';

export class NdsCharacterDutyData {
  public contributionRanks: number = 0;
  public duties: NdsCharacterDutyEntryData[] = [];

  constructor(params?: Partial<NdsCharacterDutyData>) {
    if (!!params) {
      this.contributionRanks = parseNumber(params.contributionRanks, this.contributionRanks);
      this.duties = Array.isArray(params.duties)
        ? params.duties.map(d => new NdsCharacterDutyEntryData(d))
        : this.duties;
    }
  }

  public static validate(input: NdsCharacterDutyData): string[] {
    const errors: string[] = [];
    errors.push(
      ...RpgClassValidators.number(input.contributionRanks, {
        fieldName: 'Duty Contribution Ranks',
      })
    );
    errors.push(
      ...input.duties.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterDutyEntryData.validate(next)],
        []
      )
    );
    return errors;
  }
}

import { ObjectId } from 'bson';
import {
  MongoDoc,
  GameTheme,
  DataType,
  parseEnum,
  parseObjectId,
  RulesSystem,
} from '@rpg/core/base';
import { CritTableRow } from './crit-table-row';

export class CritTable extends MongoDoc {
  public name: string = '';
  public description: string = '';
  public gameTheme: GameTheme = GameTheme.Generic;
  public rulesSystem: RulesSystem = RulesSystem.Generic;
  public dataType: DataType = DataType.Character;
  public userId: ObjectId | null = null;
  public rows: CritTableRow[] = [];

  constructor(params?: Partial<CritTable>) {
    super(params);
    if (!!params) {
      this.name = params.name ?? this.name;
      this.description = params.description ?? this.description;
      this.gameTheme = parseEnum(GameTheme, params.gameTheme, this.gameTheme);
      this.rulesSystem = parseEnum(RulesSystem, params.rulesSystem, this.rulesSystem);
      this.dataType = parseEnum(DataType, params.dataType, this.dataType);
      this.userId = parseObjectId(params.userId);
      this.rows = Array.isArray(params.rows)
        ? params.rows.map(r => new CritTableRow(r))
        : this.rows;
    }
  }
}

import { FormGroup } from '@ngneat/reactive-forms';
import { unreachable } from '@rpg/core/base';
import {
  NdsVehicleModifierActionData,
  NdsVehicleModifierAttributeData,
  NdsVehicleModifierCharacteristicData,
  NdsVehicleModifierData,
  NdsVehicleModifierNarrativeData,
  NdsVehicleModifierType,
  NdsVehicleModifierWeaponData,
} from '@rpg/core/vehicle';
import { ndsVehicleModifierActionToFormGroup } from './nds-vehicle-modifier-action-to-form-group';
import { ndsVehicleModifierAttributeToFormGroup } from './nds-vehicle-modifier-attribute-to-form-group';
import { ndsVehicleModifierCharacteristicToFormGroup } from './nds-vehicle-modifier-characteristic-to-form-group';
import { ndsVehicleModifierNarrativeToFormGroup } from './nds-vehicle-modifier-narrative-to-form-group';
import { ndsVehicleModifierWeaponToFormGroup } from './nds-vehicle-modifier-weapon-to-form-group';

export function NDSVehicleModifierFormGroupFactory(
  modifier: NdsVehicleModifierData
): FormGroup<NdsVehicleModifierData> {
  switch (modifier.type) {
    case NdsVehicleModifierType.Action:
      return ndsVehicleModifierActionToFormGroup(modifier as NdsVehicleModifierActionData);
    case NdsVehicleModifierType.Attribute:
      return ndsVehicleModifierAttributeToFormGroup(modifier as NdsVehicleModifierAttributeData);
    case NdsVehicleModifierType.Characteristic:
      return ndsVehicleModifierCharacteristicToFormGroup(
        modifier as NdsVehicleModifierCharacteristicData
      );
    case NdsVehicleModifierType.Narrative:
      return ndsVehicleModifierNarrativeToFormGroup(modifier as NdsVehicleModifierNarrativeData);
    case NdsVehicleModifierType.Weapon:
      return ndsVehicleModifierWeaponToFormGroup(modifier as NdsVehicleModifierWeaponData);
    default:
      unreachable(modifier.type, 'NDSVehicleModifierFormGroupFactory');
  }
}

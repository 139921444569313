import { Component, ChangeDetectionStrategy, ViewChild, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { first, Subscription } from 'rxjs';

@Component({
  selector: 'rpg-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent implements OnDestroy {
  @ViewChild(MatMenuTrigger, { static: false })
  public contextMenuTrigger!: MatMenuTrigger;
  public menuPos: { x: number; y: number } | null = null;
  public menuOpen: boolean = false;

  private _subscriptions: Subscription[] = [];

  public ngOnDestroy(): void {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  public openContextMenu($event: MouseEvent): void {
    $event.preventDefault();

    // Move menu and show it
    this.contextMenuTrigger.closeMenu();

    this.menuPos = {
      x: $event.clientX,
      y: $event.clientY,
    };
    this.contextMenuTrigger.openMenu();
    this.menuOpen = true;
    const sub = this.contextMenuTrigger.menu?.close.pipe(first()).subscribe(x => {
      this.menuOpen = false;
    });
    if (!!sub) {
      this._subscriptions.push(sub);
    }
  }

  public closeMenu($event: MouseEvent | null = null): void {
    $event?.preventDefault();
    this.contextMenuTrigger.closeMenu();
  }
}

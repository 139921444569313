import { LoginType } from '@rpg/core/auth';
import { parseEnum } from '@rpg/core/base';

export class UserLogin {
  public type: LoginType | null = null;

  constructor(params?: Partial<UserLogin>) {
    if (!!params) {
      this.type = parseEnum(LoginType, params.type);
    }
  }
}

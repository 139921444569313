<div class="dice-fab-container">
  <button mat-mini-fab type="button" color="primary" class="dice-fab" (click)="toggle()">
    <fa-icon icon="times" class="dice-fab-icon" [class.dice-fab-icon--shown]="isOpen"></fa-icon>
    <fa-icon icon="dice" class="dice-fab-icon" [class.dice-fab-icon--shown]="!isOpen"></fa-icon>
  </button>
  <div class="wheel-container">
    <ng-container *ngFor="let row of diceRows; let j = index">
      <button
        mat-mini-fab
        type="button"
        [tabIndex]="isOpen ? 0 : -1"
        *ngFor="let type of row.types; let i = index"
        class="dice-button dice-button-{{ i }} inline-dice__container"
        [class.dice-button--open]="isOpen"
        [ngStyle]="getStyle(isOpen, row, j, i)"
        (click)="dice.emit(type)"
      >
        <span
          [rpgThemeHelper]="responsiveToSiteTheme"
          class="dice-image inline-dice__image {{ type }} {{ DiceTheme.toClass(row.theme) }}"
        ></span>
      </button>
    </ng-container>
  </div>
</div>

import { Validators } from '@angular/forms';
import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsVehicleAttachmentData } from '@rpg/core/vehicle';
import { NDSVehicleModifierFormGroupFactory } from './modifiers';
import { ndsVehicleAttachmentModificationToFormGroup } from './nds-vehicle-attachment-modification-to-form-group';

export function ndsVehicleAttachmentToFormGroup(
  attachment: NdsVehicleAttachmentData
): FormGroup<NdsVehicleAttachmentData> {
  return new FormGroup<NdsVehicleAttachmentData & Expandable>({
    expanded: new FormControl(false),
    id: new FormControl(attachment.id),
    name: new FormControl(attachment.name, [Validators.required]),
    lookupName: new FormControl(attachment.lookupName),
    hardPoints: new FormControl(attachment.hardPoints),
    description: new FormControl(attachment.description),
    cost: new FormControl(attachment.cost),
    rarity: new FormControl(attachment.rarity),
    restricted: new FormControl(attachment.restricted),
    equipped: new FormControl(attachment.equipped),
    modifications: new FormArray(
      attachment.modifications.map(m => ndsVehicleAttachmentModificationToFormGroup(m))
    ),
    modifiers: new FormArray(attachment.modifiers.map(m => NDSVehicleModifierFormGroupFactory(m))),
  });
}

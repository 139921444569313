import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RedirectService } from '@rpg/ngx/core';
import { Store } from '@ngxs/store';
import { Auth3State } from '@sessions/state';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private redirectService: RedirectService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authenticated = this.store.selectSnapshot(Auth3State.isAuthenticated);
    console.log('authenticated', authenticated);
    if (authenticated) return this.router.parseUrl(this.redirectService.homePage);
    return true;
  }
}

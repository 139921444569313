import { parseNumber } from '@rpg/core/base';
import { InitiativeOrder } from './initiative-order';

export class Initiative {
  public round: number = 1;
  public slot: number = 1;
  public order: InitiativeOrder[] = [];
  public rollingEnabled: boolean = false;

  constructor(params?: Partial<Initiative>) {
    if (!!params) {
      this.round = parseNumber(params.round, this.round);
      this.slot = parseNumber(params.slot, this.slot);
      this.order = Array.isArray(params.order)
        ? params.order.map(o => new InitiativeOrder(o))
        : this.order;
      this.rollingEnabled = params.rollingEnabled ?? this.rollingEnabled;
    }
  }
}

import { parseEnum, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsVehicleAttribute } from '../enums';

export class NdsVehicleAttributeData {
  public type!: NdsVehicleAttribute;
  public value: number = 0;

  constructor(params?: Partial<NdsVehicleAttributeData>) {
    if (!!params) {
      this.type = parseEnum(NdsVehicleAttribute, params.type, this.type);
      this.value = parseNumber(params.value, this.value);
    }
  }

  public static validate(input: NdsVehicleAttributeData): string[] {
    const errors: string[] = [];
    errors.push(
      ...RpgClassValidators.enum(input.type, NdsVehicleAttribute, {
        enumName: 'Vehicle Attribute',
      })
    );
    errors.push(...RpgClassValidators.number(input.value));
    return errors;
  }
}

export enum AdapterType {
  MapValue = '[Adapter Type] map value',
  MapClass = '[Adapter Type] map class',
  MapPaginatedList = '[Adapter Type] map paginated list',
}

export namespace AdapterType {
  export const members: AdapterType[] = [
    AdapterType.MapValue,
    AdapterType.MapClass,
    AdapterType.MapPaginatedList,
  ];
}

import { NdsCharacterModifierFactory } from '../functions';
import { NdsCharacterModifierData } from './modifiers';

class Ref {
  public id: string = '';
  public path: string = '';
  public name: string = '';

  constructor(params?: Partial<Ref>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.path = params.path ?? this.path;
      this.name = params.name ?? this.name;
    }
  }
}
export class NdsMatchedCharacterModifier {
  public enabled: boolean = false;
  public fromId: string = '';
  public fromName: string = '';
  public parentIds: string[] = [];
  public pathRefs: Ref[] = [];
  public modifier!: NdsCharacterModifierData;

  constructor(params: NdsMatchedCharacterModifier) {
    this.enabled = params.enabled ?? this.enabled;
    this.fromId = params.fromId ?? this.fromId;
    this.fromName = params.fromName ?? this.fromName;
    this.parentIds = Array.isArray(params.parentIds) ? params.parentIds : this.parentIds;
    this.pathRefs = Array.isArray(params.pathRefs)
      ? params.pathRefs.map(ref => new Ref(ref))
      : this.pathRefs;
    this.modifier = !!params.modifier
      ? NdsCharacterModifierFactory(params.modifier)
      : this.modifier;
  }
}

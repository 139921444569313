export enum ImportStatsType {
  Month = '[import stats type] month',
  Year = '[import stats type] year',
  AllTime = '[import stats type] all time',
}

export namespace ImportStatsType {
  export const members: ImportStatsType[] = [
    ImportStatsType.Month,
    ImportStatsType.Year,
    ImportStatsType.AllTime,
  ];
}

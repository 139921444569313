/* eslint-disable @typescript-eslint/ban-types */
import { HttpService } from '../services/http.service';
import { AdapterType } from '../models/AdapterType';
import { Adapter } from '../models/Adapter';
import { Observable } from 'rxjs';
import { Newable } from '@rpg/core/base';

/**
 * @ignore
 *
 * Registers an adapter into an array on the decorator descriptor that will
 * allow it to be accessed later on the decorator target
 *
 * @param {Adapter} adapter An Adapter to be registered
 * @param {any} descriptor The storage mechanism for a decorator
 * @returns {void} void
 */
function registerAdapter(adapter: Adapter, descriptor: any): void {
  if (AdapterType.members.indexOf(adapter.type) > -1) {
    if (Array.isArray(descriptor.adapters)) {
      descriptor.adapters = [...descriptor.adapters, adapter];
    } else {
      descriptor.adapters = [adapter];
    }
  }
}

/**
 * Registers a function to be applied to the response body of a request.
 * Can be combined with the [MapClass]{@link MapClass} decorator.
 *
 * ```Typescript
 * @Map((body) => {
 *      if (body.status === 'complete') {
 *          body.date = body.updatedDate;
 *      } else {
 *          body.date = body.createdDate;
 *      }
 *      return body;
 * })
 * @Get('/todos/1')
 * public getTodo(): Observable<any> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 * ```
 *
 * @param {Function} adapterFn Function to apply as a map
 * @returns {Function} decorator
 */
export function MapValue(adapterFn: Function): Function {
  return (target: HttpService, propertyKey: string, descriptor: any): any =>
    registerAdapter(
      {
        type: AdapterType.MapValue,
        value: adapterFn,
      },
      descriptor
    );
}

/**
 * {@link MapClass}
 *
 * Reigsters a class to be applied to the response body of a request. Handles
 * both cases where the response is a single object or an array of objects.
 * Can be combined with the [MapValue]{@link MapValue} decorator.
 *
 * ```Typescript
 * export class Todo {
 *      constructor(params) {}
 * }
 *
 * @MapClass(Todo)
 * @Get('/todos/1')
 * public getTodo(): Observable<Todo> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 *
 *
 * @MapClass(Todo)
 * @Get('/todo')
 * public getTodoList(): Observable<Todo[]> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 * ```
 *
 * @param {Newable<T>} constructorFn A class to map the body response to
 * @returns {Function} decorator
 */
export function MapClass<T>(constructorFn: Newable<T>): Function {
  return (target: HttpService, propertyKey: string, descriptor: any): any =>
    registerAdapter(
      {
        type: AdapterType.MapClass,
        value: constructorFn,
      },
      descriptor
    );
}

/**
 * {@link MapPaginatedList}
 *
 * Reigsters a class to be applied to the response body of a request. Handles
 * both cases where the response is a single object or an array of objects.
 * Can be combined with the [MapValue]{@link MapValue} decorator.
 *
 * ```Typescript
 * export class Todo {
 *      constructor(params) {}
 * }
 *
 * @MapPaginatedList(Todo)
 * @Get('/todos/1')
 * public getTodo(): Observable<PaginatedList<Todo>> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 * ```
 *
 * @param {Newable<T>} constructorFn A class to map the body response to
 * @returns {Function} decorator
 */
export function MapPaginatedList<T>(constructorFn: Newable<T>): Function {
  return (target: HttpService, propertyKey: string, descriptor: any): any =>
    registerAdapter(
      {
        type: AdapterType.MapPaginatedList,
        value: constructorFn,
      },
      descriptor
    );
}

/**
 * Attach error handling functionality to an HTTP Request
 *
 * ```Typescript
 * @ErrorHandler((err: any) => of('New Value'))
 * @Get('/todo')
 * public getTodoList(): Observable<Todo[]> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 * ```
 *
 * @param {Function} handlerFn Function to run on Error
 * @returns {Function} decorator
 */
export function ErrorHandler(handlerFn: (error: any) => Observable<any>): Function {
  return (target: HttpService, propertyKey: string, descriptor: any): any => {
    descriptor.errorHandler = handlerFn || null;
    return descriptor;
  };
}

/**
 * @ignore
 *
 * Used for internal testing purposes. Creates an invalid adapter
 *
 * @returns {Function} decorator
 */
export function InvalidMap(): Function {
  return (target: HttpService, propertyKey: string, descriptor: any): any =>
    registerAdapter(
      {
        // use as any here to allow the data to be invalid for testing
        type: null as any,
        value: null as any,
      },
      descriptor
    );
}

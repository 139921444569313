import { RulesSystem, unreachable } from '@rpg/core/base';
import { BaseVehicle } from '../models';
import { NdsVehicle } from '../rules-nds';

export function mapVehicleToClass<T extends BaseVehicle>(
  data: BaseVehicle,
  stripPrivateData: boolean = false
): T {
  let char: BaseVehicle;
  switch (data.rulesSystem) {
    case RulesSystem.NarrativeDiceSystem:
      char = new NdsVehicle(data as any);
      break;
    case RulesSystem.AGE:
    case RulesSystem.Generic:
      char = data;
      break;
    default:
      unreachable(data.rulesSystem, 'mapVehicleToClass');
  }
  if (stripPrivateData) {
    char.privateNotes = '';
  }
  return char as T;
}

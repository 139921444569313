import { DiceGroupType, DiceType } from '../enums';

export function diceTypeToDiceGroupType(type: DiceType): DiceGroupType {
  if ([...DiceType.numberedDice, DiceType.Modifier, DiceType.Polyhedral].includes(type)) {
    return DiceGroupType.Numbered;
  } else if (DiceType.ndsDice.includes(type)) {
    return DiceGroupType.NarrativeDiceSystem;
  } else if (DiceType.eoteDice.includes(type)) {
    return DiceGroupType.FFGStarWars;
  } else if (DiceType.genesysDice.includes(type)) {
    return DiceGroupType.FFGGenesys;
  } else {
    return DiceGroupType.Numbered;
  }
}

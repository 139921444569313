<div mat-dialog-title *ngIf="!cloneComplete">
  <h2>
    {{ cloneInProgress ? 'Cloning' : 'Clone' }}
    {{ name }}
    {{ errorMessage ? ' Failed' : '' }}
  </h2>
</div>
<mat-dialog-content>
  <ng-container *ngIf="!cloneInProgress && !cloneComplete && !errorMessage">
    <p>Are you sure you want to clone {{ name }}?</p>
  </ng-container>
  <ng-container *ngIf="!cloneInProgress && !cloneComplete && errorMessage">
    <p class="error">{{ errorMessage }}</p>
  </ng-container>
  <ng-container *ngIf="cloneInProgress && !cloneComplete">
    <span class="slot" [class.slot--full]="completedSlot >= 1">
      <span class="fill"></span>
      <span class="message" [class.message--visible]="completedSlot >= 0">{{ messages[0] }}</span>
    </span>
    <span class="slot" [class.slot--full]="completedSlot >= 3">
      <span class="fill"></span>
      <span class="message" [class.message--visible]="completedSlot >= 2">{{ messages[1] }}</span>
    </span>
    <span class="slot" [class.slot--full]="completedSlot >= 5">
      <span class="fill"></span>
      <span class="message" [class.message--visible]="completedSlot >= 4">{{ messages[2] }}</span>
    </span>
  </ng-container>
  <ng-container *ngIf="!cloneInProgress && cloneComplete">
    <div class="checkmark-box">
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
      <p class="success" [class.success--visible]="showCompleteMessage">
        {{ name }} cloning complete!
      </p>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions
  class="actions actions--visible"
  *ngIf="!cloneInProgress && !cloneComplete && !errorMessage"
>
  <button mat-raised-button color="accent" (click)="cancel()" class="cancel">Cancel</button>
  <button mat-raised-button color="primary" (click)="startClone()" class="save">Clone</button>
  <ng-container> </ng-container>
</mat-dialog-actions>
<mat-dialog-actions
  class="actions actions--visible"
  *ngIf="!cloneInProgress && !cloneComplete && errorMessage"
>
  <button mat-raised-button color="accent" (click)="cancel()" class="cancel">Close</button>
  <ng-container> </ng-container>
</mat-dialog-actions>
<mat-dialog-actions
  class="actions"
  *ngIf="!cloneInProgress && cloneComplete"
  [class.actions--visible]="showCompleteMessage"
>
  <button mat-raised-button color="accent" (click)="cancel()" class="cancel">Close</button>
  <button mat-raised-button color="primary" (click)="complete()" class="save">
    {{ completeWord }}
  </button>
</mat-dialog-actions>

import { Constraints } from '@rpg/core/base';
import { NdsVehicleModifierType } from '../../enums';
import { NdsVehicleModifierData } from './nds-vehicle-modifier-data';

export class NdsVehicleModifierNarrativeData extends NdsVehicleModifierData {
  public type: NdsVehicleModifierType = NdsVehicleModifierType.Narrative;

  constructor(params?: Partial<NdsVehicleModifierNarrativeData>) {
    super(params);
    // Enforce Constraints
    this.type = Constraints.mustEqual(this.type, NdsVehicleModifierType.Narrative);
  }

  public static validate(input: NdsVehicleModifierNarrativeData): string[] {
    const errors: string[] = NdsVehicleModifierData.validate(input);
    return errors;
  }
}

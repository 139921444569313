import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OverlayResponse } from '@rpg/ngx/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  CloneOverlayComponent,
  CloneOverlayComponentData,
} from './clone-overlay/clone-overlay.component';
import {
  ConfirmationOverlayComponent,
  ConfirmationOverlayOptions,
} from './confirmation-overlay/confirmation-overlay.component';
import { FormattingHelpComponent } from './formatting-help/formatting-help.component';
import { SpinnerOverlayComponent, SpinnerOverlayOptions } from './spinner/spinner.component';
import {
  SingleInputOverlayComponent,
  SingleInputOverlayOptions,
} from './single-input/single-input.component';
import { ObjectId } from 'bson';

@Injectable()
export class BaseOverlayService {
  constructor(protected _dialog: MatDialog, protected _transloco: TranslocoService) {}

  public cloneOverlay(
    options: CloneOverlayComponentData
  ): MatDialogRef<CloneOverlayComponent, OverlayResponse<ObjectId>> {
    return this._dialog.open<
      CloneOverlayComponent,
      CloneOverlayComponentData,
      OverlayResponse<ObjectId>
    >(CloneOverlayComponent, {
      data: options,
    });
  }

  public confirmationOverlay<ReturnType = any>(
    options: Partial<ConfirmationOverlayOptions>
  ): MatDialogRef<ConfirmationOverlayComponent, ReturnType> {
    return this._dialog.open(ConfirmationOverlayComponent, {
      data: {
        ...options,
        title: options.title ? this._transloco.translate(options.title) : '',
        messages: (options.messages ?? []).map(m => this._transloco.translate(m)),
        buttons: (options.buttons ?? []).map(b => {
          b.label = this._transloco.translate(b.label);
          return b;
        }),
      },
      panelClass: 'rpg-dialog-limit-width-500',
    });
  }

  public singleInputOverlay(
    options: Partial<SingleInputOverlayOptions>
  ): MatDialogRef<SingleInputOverlayComponent, OverlayResponse<string>> {
    return this._dialog.open<
      SingleInputOverlayComponent,
      Partial<SingleInputOverlayOptions>,
      OverlayResponse<string>
    >(SingleInputOverlayComponent, {
      data: {
        ...options,
        placeholder: options.placeholder ? this._transloco.translate(options.placeholder) : '',
        cancelLabel: options.cancelLabel ? this._transloco.translate(options.cancelLabel) : '',
        submitLabel: options.submitLabel ? this._transloco.translate(options.submitLabel) : '',
      },
    });
  }

  public formattingHelpOverlay(): MatDialogRef<FormattingHelpComponent> {
    return this._dialog.open(FormattingHelpComponent);
  }

  public spinnerOverlay(
    options: Partial<SpinnerOverlayOptions>
  ): MatDialogRef<SpinnerOverlayComponent> {
    return this._dialog.open(SpinnerOverlayComponent, {
      data: options,
      disableClose: true,
    });
  }
}

import { MongoDoc, parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { GameTableNoteActionRef } from './note-action-ref';
import formatDistance from 'date-fns/formatDistance';
import { enUS, es, fr } from 'date-fns/locale';

const locales = {
  fr,
  en: enUS,
  es,
};

export class GameTableNote extends MongoDoc {
  public userId: ObjectId = new ObjectId();
  public gameId: ObjectId = new ObjectId();
  public imageUrl: string = '';
  public message: string = '';
  public isGM: boolean = false;
  public noteActionRef: GameTableNoteActionRef = new GameTableNoteActionRef();

  constructor(params?: Partial<GameTableNote>) {
    super(params);
    if (!!params) {
      this.userId = parseObjectId(params.userId, this.userId);
      this.gameId = parseObjectId(params.gameId, this.gameId);
      this.imageUrl = params.imageUrl ?? this.imageUrl;
      this.message = params.message ?? this.message;
      this.noteActionRef = !!params.noteActionRef
        ? new GameTableNoteActionRef(params.noteActionRef)
        : this.noteActionRef;
      this.isGM = params.isGM != null ? params.isGM : this.isGM;
    }
  }

  public createdDateFormatted(locale: string = 'en'): string {
    if (!!this.createdDate) {
      return formatDistance(this.createdDate, new Date(), {
        addSuffix: true,
        locale: (locales as any)[locale] || enUS,
      });
    }

    return '';
  }
}

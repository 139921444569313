import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterAttributeData } from '@rpg/core/character';

export function ndsCharacterAttributeToFormGroup(
  attr: NdsCharacterAttributeData
): FormGroup<NdsCharacterAttributeData> {
  return new FormGroup<NdsCharacterAttributeData>({
    type: new FormControl(attr.type, [Validators.required]),
    value: new FormControl(attr.value),
  });
}

import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleModifierData } from '@rpg/core/vehicle';

export function ndsVehicleModifierBaseToFormGroup(
  mod: NdsVehicleModifierData
): FormGroup<NdsVehicleModifierData> {
  return new FormGroup<NdsVehicleModifierData>({
    id: new FormControl(mod.id),
    type: new FormControl(mod.type, [Validators.required]),
    name: new FormControl(mod.name, [Validators.required]),
    description: new FormControl(mod.description),
  });
}

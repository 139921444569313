import { isNotNull } from './is-not-null';

type Required<T> = T;
type Optional<T> = T | undefined | null;

export function parseEnum<EnumType>(
  enumReference: { members: Array<EnumType> },
  toParse: Optional<EnumType>
): EnumType | null;
export function parseEnum<EnumType>(
  members: Array<EnumType>,
  toParse: Optional<EnumType>
): EnumType | null;
export function parseEnum<EnumType>(
  enumReference: { members: Array<EnumType> },
  toParse: Optional<EnumType>,
  defaultValue: Required<EnumType>
): EnumType;
export function parseEnum<EnumType>(
  members: Array<EnumType>,
  toParse: Optional<EnumType>,
  defaultValue: Required<EnumType>
): EnumType;
export function parseEnum<EnumType>(
  membersOrRef: Array<EnumType> | { members: Array<EnumType> },
  toParse: Optional<EnumType>,
  defaultValue?: Required<EnumType>
): EnumType | null {
  let members: Array<EnumType>;
  if (!Array.isArray(membersOrRef)) {
    members = membersOrRef.members;
  } else {
    members = membersOrRef;
  }
  if (typeof toParse !== 'undefined' && toParse !== null && members.includes(toParse)) {
    return toParse;
  }
  if (typeof defaultValue !== 'undefined' && defaultValue !== null) {
    return defaultValue;
  }
  return null;
}

export function parseEnumArray<EnumType>(
  enumReference: { members: Array<EnumType> },
  toParse: Optional<Array<EnumType>>
): Array<EnumType>;
export function parseEnumArray<EnumType>(
  members: Array<EnumType>,
  toParse: Optional<Array<EnumType>>
): Array<EnumType>;
export function parseEnumArray<EnumType>(
  enumReference: { members: Array<EnumType> },
  toParse: Optional<Array<EnumType>>,
  filterNullValues: false
): Array<EnumType | null>;
export function parseEnumArray<EnumType>(
  members: Array<EnumType>,
  toParse: Optional<Array<EnumType>>,
  filterNullValues: false
): Array<EnumType | null>;
export function parseEnumArray<EnumType>(
  membersOrRef: Array<EnumType> | { members: Array<EnumType> },
  toParse: Optional<Array<EnumType>>,
  filterNullValues: boolean = true
): Array<EnumType | null> {
  if (!toParse || !Array.isArray(toParse)) {
    return [];
  }
  // TS complains because this technically doesn't match any of the overloads
  // but TS is stupid since it matches the base implementation definition.
  // Screw TS
  let mappedValues: Array<EnumType | null> = [];
  if (Array.isArray(membersOrRef)) {
    mappedValues = toParse.map(p => parseEnum(membersOrRef, p));
  } else {
    mappedValues = toParse.map(p => parseEnum(membersOrRef, p));
  }
  if (filterNullValues) {
    return mappedValues.filter(isNotNull);
  }
  return mappedValues;
}

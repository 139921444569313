import { ThemeOptions } from '@mui/material/styles';

export const THEME_LIGHT_PRIMARY = '#4064af';
export const THEME_LIGHT_ACCENT = '#c8cbce';
export const THEME_LIGHT_WARN = '#dc2d32';
export const THEME_LIGHT_TEXT = '#323b43';
export const THEME_LIGHT_PAGE_BACKGROUND = '#f9f9f9';
export const THEME_LIGHT_HEADER = '#b8bcc0';
export const THEME_LIGHT_SECTION_HEADER = '#f9f9f9';
export const THEME_LIGHT_SECTION_BACKGROUND = '#d8dadd';
export const THEME_LIGHT_SECTION_BACKGROUND_LIGHT = '#9ea3a9';
export const THEME_LIGHT_SECTION_BACKGROUND_DARK = '#e8eaeb';
export const THEME_LIGHT_LINES = '#f9f9f9';
export const THEME_LIGHT_LINES_TRANSPARENT = '#f9f9f94d';
export const THEME_LIGHT_INPUT_OUTLINE = '#0000003b';
export const THEME_LIGHT_INPUT_OUTLINE_FOCUSED = '#4064af';
export const THEME_LIGHT_INPUT_OUTLINE_HOVER = '#000000ff';

export const THEME_DARK_PRIMARY = '#4164af';
export const THEME_DARK_ACCENT = '#4b525a';
export const THEME_DARK_WARN = '#f27168';
export const THEME_DARK_TEXT = '#d8dadd';
export const THEME_DARK_PAGE_BACKGROUND = '#121921';
export const THEME_DARK_HEADER = '#4b525a';
export const THEME_DARK_SECTION_HEADER = '#121921';
export const THEME_DARK_SECTION_BACKGROUND = '#343b43';
export const THEME_DARK_SECTION_BACKGROUND_LIGHT = '#545b63';
export const THEME_DARK_SECTION_BACKGROUND_DARK = '#262d35';
export const THEME_DARK_LINES = '#121921';
export const THEME_DARK_LINES_TRANSPARENT = '#1219214d';
export const THEME_DARK_INPUT_OUTLINE = '#ffffff3b';
export const THEME_DARK_INPUT_OUTLINE_FOCUSED = '#4164af';
export const THEME_DARK_INPUT_OUTLINE_HOVER = '#ffffffff';

export enum RpgColor {
  PRIMARY = 'var(--theme-primary)',
  ACCENT = 'var(--theme-accent)',
  WARN = 'var(--theme-warn)',
  TEXT = 'var(--theme-text)',
  PAGE_BACKGROUND = 'var(--theme-page-background)',
  HEADER = 'var(--theme-header)',
  SECTION_HEADER = 'var(--theme-section-header)',
  SECTION_BACKGROUND = 'var(--theme-section-background)',
  SECTION_BACKGROUND_LIGHT = 'var(--theme-section-background-light)',
  SECTION_BACKGROUND_DARK = 'var(--theme-section-background-dark)',
  LINES = 'var(--theme-lines)',
  LINES_TRANSPARENT = 'var(--theme-lines-transparent)',
  INPUT_OUTLINE = 'var(--theme-input-outline)',
  INPUT_OUTLINE_FOCUSED = 'var(--theme-input-outline-focused)',
  INPUT_OUTLINE_HOVER = 'var(--theme-input-outline-hover)',
}

export namespace RpgColor {
  export const members: RpgColor[] = [
    RpgColor.PRIMARY,
    RpgColor.ACCENT,
    RpgColor.WARN,
    RpgColor.TEXT,
    RpgColor.PAGE_BACKGROUND,
    RpgColor.HEADER,
    RpgColor.SECTION_HEADER,
    RpgColor.SECTION_BACKGROUND,
    RpgColor.SECTION_BACKGROUND_LIGHT,
    RpgColor.SECTION_BACKGROUND_DARK,
    RpgColor.LINES,
    RpgColor.LINES_TRANSPARENT,
    RpgColor.INPUT_OUTLINE,
    RpgColor.INPUT_OUTLINE_FOCUSED,
    RpgColor.INPUT_OUTLINE_HOVER,
  ];

  export function getColorMap(theme: RpgColorTheme | 'light' | 'dark'): Record<RpgColor, string> {
    if (theme === RpgColorTheme.LIGHT) return RpgColor.lightMap;
    return RpgColor.darkMap;
  }

  export function nameOnly(color: RpgColor): string {
    // eslint-disable-next-line no-magic-numbers
    return color.slice(4, color.length - 1);
  }

  export const lightMap: Record<RpgColor, string> = {
    [RpgColor.PRIMARY]: THEME_LIGHT_PRIMARY,
    [RpgColor.ACCENT]: THEME_LIGHT_ACCENT,
    [RpgColor.WARN]: THEME_LIGHT_WARN,
    [RpgColor.TEXT]: THEME_LIGHT_TEXT,
    [RpgColor.PAGE_BACKGROUND]: THEME_LIGHT_PAGE_BACKGROUND,
    [RpgColor.HEADER]: THEME_LIGHT_HEADER,
    [RpgColor.SECTION_HEADER]: THEME_LIGHT_SECTION_HEADER,
    [RpgColor.SECTION_BACKGROUND]: THEME_LIGHT_SECTION_BACKGROUND,
    [RpgColor.SECTION_BACKGROUND_LIGHT]: THEME_LIGHT_SECTION_BACKGROUND_LIGHT,
    [RpgColor.SECTION_BACKGROUND_DARK]: THEME_LIGHT_SECTION_BACKGROUND_DARK,
    [RpgColor.LINES]: THEME_LIGHT_LINES,
    [RpgColor.LINES_TRANSPARENT]: THEME_LIGHT_LINES_TRANSPARENT,
    [RpgColor.INPUT_OUTLINE]: THEME_LIGHT_INPUT_OUTLINE,
    [RpgColor.INPUT_OUTLINE_FOCUSED]: THEME_LIGHT_INPUT_OUTLINE_FOCUSED,
    [RpgColor.INPUT_OUTLINE_HOVER]: THEME_LIGHT_INPUT_OUTLINE_HOVER,
  };

  export const darkMap: Record<RpgColor, string> = {
    [RpgColor.PRIMARY]: THEME_DARK_PRIMARY,
    [RpgColor.ACCENT]: THEME_DARK_ACCENT,
    [RpgColor.WARN]: THEME_DARK_WARN,
    [RpgColor.TEXT]: THEME_DARK_TEXT,
    [RpgColor.PAGE_BACKGROUND]: THEME_DARK_PAGE_BACKGROUND,
    [RpgColor.HEADER]: THEME_DARK_HEADER,
    [RpgColor.SECTION_HEADER]: THEME_DARK_SECTION_HEADER,
    [RpgColor.SECTION_BACKGROUND]: THEME_DARK_SECTION_BACKGROUND,
    [RpgColor.SECTION_BACKGROUND_LIGHT]: THEME_DARK_SECTION_BACKGROUND_LIGHT,
    [RpgColor.SECTION_BACKGROUND_DARK]: THEME_DARK_SECTION_BACKGROUND_DARK,
    [RpgColor.LINES]: THEME_DARK_LINES,
    [RpgColor.LINES_TRANSPARENT]: THEME_DARK_LINES_TRANSPARENT,
    [RpgColor.INPUT_OUTLINE]: THEME_DARK_INPUT_OUTLINE,
    [RpgColor.INPUT_OUTLINE_FOCUSED]: THEME_DARK_INPUT_OUTLINE_FOCUSED,
    [RpgColor.INPUT_OUTLINE_HOVER]: THEME_DARK_INPUT_OUTLINE_HOVER,
  };
}

export enum RpgColorTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export namespace RpgColorTheme {
  export const members: RpgColorTheme[] = [RpgColorTheme.LIGHT, RpgColorTheme.DARK];

  const baseThemeOptions: ThemeOptions = {
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: RpgColor.SECTION_BACKGROUND,
            backgroundImage: 'none',
          },
        },
      },
    },
  };

  export const LIGHT_THEME: ThemeOptions = {
    ...baseThemeOptions,
    palette: {
      mode: RpgColorTheme.LIGHT,
      background: {
        default: THEME_LIGHT_PAGE_BACKGROUND,
      },
      primary: {
        main: THEME_LIGHT_PRIMARY,
      },
      secondary: {
        main: THEME_LIGHT_ACCENT,
      },
      error: {
        main: THEME_LIGHT_WARN,
      },
    },
  };

  export const DARK_THEME: ThemeOptions = {
    ...baseThemeOptions,
    palette: {
      mode: RpgColorTheme.DARK,
      background: {
        default: THEME_DARK_PAGE_BACKGROUND,
      },
      primary: {
        main: THEME_DARK_PRIMARY,
      },
      secondary: {
        main: THEME_DARK_ACCENT,
      },
      error: {
        main: THEME_DARK_WARN,
      },
    },
  };

  export function getThemeOptions(theme: RpgColorTheme): ThemeOptions {
    if (theme === RpgColorTheme.LIGHT) return RpgColorTheme.LIGHT_THEME;
    return RpgColorTheme.DARK_THEME;
  }

  export function translationKey(theme: RpgColorTheme): string {
    return `enums.core.color-theme.${theme}`;
  }
}

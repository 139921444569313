import { parseObjectId } from '@rpg/core/base';
import { ObjectId } from 'bson';
import { FileType } from '../enums';

export class FileCrumb {
  public _id!: ObjectId;
  public parentFileRefId: ObjectId | null = null;
  public name: string = '';
  public type: FileType = FileType.Folder;

  constructor(params?: Partial<FileCrumb>) {
    if (!!params) {
      this._id = parseObjectId(params._id, this._id);
      this.parentFileRefId = parseObjectId(params.parentFileRefId);
      this.name = params.name ?? this.name;
    }
  }
}

export enum DocStatus {
  Inactive = 0,
  Active = 1,
}

export namespace DocStatus {
  export function toString(type: DocStatus): string {
    switch (type) {
      case DocStatus.Inactive:
        return 'InActive';
      case DocStatus.Active:
        return 'Active';
      default:
        return '';
    }
  }

  export const members: DocStatus[] = [DocStatus.Inactive, DocStatus.Active];
}

<ng-container *rxLet="vm.state$; let state">
  <ng-container *ngIf="!!state.selectedTalentData">
    <rpg-nds-character-talent-slot
      class="mobile-displayed-talent"
      [talentData]="state.talentSlotRef[state.selectedTalentData.linkedId] ?? undefined"
      [mobileEnabled]="false"
      [mobileSelected]="false"
      [editMode]="state.editMode"
      (talentContentClick)="
        talentContentClick.emit({
          nodeRef: state.selectedTalentData
        })
      "
      (talentPurchasedToggle)="
        talentPurchasedToggle.emit({ nodeRef: state.selectedTalentData, state: $event })
      "
    ></rpg-nds-character-talent-slot>
  </ng-container>
  <ng-container *ngFor="let nodeRef of state.talents; let i = index; trackBy: trackBySlotId">
    <rpg-nds-character-talent-slot
      *ngIf="
        !nodeRef.hidden &&
          (state.editMode
            ? nodeRef.position.y <= state.maxRowIndexToRender
            : nodeRef.position.y < state.maxRowIndexToRender);
        else hidden
      "
      [talentData]="state.talentSlotRef[nodeRef.linkedId] ?? undefined"
      [mobileEnabled]="true"
      [mobileSelected]="state.selectedTalentData?.id === nodeRef.id"
      [forceMobile]="state.forceMobile"
      [editMode]="state.editMode"
      (talentContentClick)="vm.isMobile() ? vm.mobileSelect(nodeRef) : talentContentClick.emit({nodeRef})"
      (talentPurchasedToggle)="talentPurchasedToggle.emit({state: $event, nodeRef})"
    ></rpg-nds-character-talent-slot>
  </ng-container>
</ng-container>
<div class="tier" *ngFor="let t of tiers">{{ 'words.Tier' | transloco }} {{ t }}</div>

<ng-template #hidden><span></span></ng-template>

import { Injectable } from '@angular/core';

import { SupabaseService } from './supabase.service';

@Injectable({
  providedIn: 'root',
})
export class UserSB {
  constructor(private sb: SupabaseService) {}

  async getUser() {
    const id = (await this.sb.auth.getUser())?.data.user?.id;
    if (!id) return null;
    const user = await this.sb.db('user').select('*').eq('id', id).single();
    return user.data ?? null;
  }

  async isEmailUnique(email: string) {
    const users = await this.sb.db('user').select('id').eq('email', email);
    console.log(`users: ${email}`, users);
    if (!!users.error) return false; // Return false if error
    if (users.data.length === 0) return true; // If no records with that email are found, Unique!
    const userId = (await this.sb.auth.getUser())?.data.user?.id;
    // We have at least 1 record using this email, but this user isn't logged in, so it isn't them, Not Unique!
    if (!userId) return false;
    // The returned record (uniqueness is enforced on db) belongs to the current user, so it is "unique"
    if (users.data[0].id === userId) return true;
    // We found a record, but it doesn't belong to the current user, so it is not unique
    return false;
  }

  async isUsernameUnique(username: string) {
    const users = await this.sb.db('user').select('id').eq('username', username);
    console.log(`users: ${username}`, users);
    if (!!users.error) return false; // Return false if error
    if (users.data.length === 0) return true; // If no records with that username are found, Unique!
    const userId = (await this.sb.auth.getUser())?.data.user?.id;
    // We have at least 1 recording using this username, but this user isn't logged in, so it isn't them, Not Unique!
    if (!userId) return false;
    // The returned record (uniqueness is enforced on db) belongs to the current user, so it is "unique"
    if (users.data[0].id === userId) return true;
    // We found a record, but it doesn't belong to the current user, so it is not unique
    return false;
  }

  async updateUsername(username: string) {
    const user = (await this.sb.auth.getUser())?.data.user;
    if (!user) return false;
    const res = await this.sb.db('user').update({ username }).eq('id', user.id);
    return !res.error;
  }

  async updateEmail(email: string) {
    const user = (await this.sb.auth.getUser())?.data.user;
    if (!user) return false;
    const res = await this.sb.db('user').update({ email }).eq('id', user.id);
    return !res.error;
  }
}

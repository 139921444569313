export enum DiscordDiceSize {
  Regular = '[game dice size] regular',
  Large = '[game dice size] large',
}

export namespace DiscordDiceSize {
  export function toString(type: DiscordDiceSize): string {
    switch (type) {
      case DiscordDiceSize.Regular:
        return 'Regular';
      case DiscordDiceSize.Large:
        return 'Large';
      default:
        return '';
    }
  }

  export function translationKey(type: DiscordDiceSize): string {
    return `enums.discord.discord-dice-size.${type}`;
  }

  export const defaultValue = DiscordDiceSize.Large;

  export const members: DiscordDiceSize[] = [DiscordDiceSize.Regular, DiscordDiceSize.Large];
}

import { Injectable } from '@angular/core';
import {
  Body,
  Delete,
  Get,
  HttpService,
  MapClass,
  MapPaginatedList,
  Patch,
  Path,
  Post,
  Put,
  Query,
} from '@rpg/ngx/http';
import { Game, GameSort, StoryPointsStyle } from '@rpg/core/game';
import { EMPTY, Observable } from 'rxjs';
import { ObjectId } from 'bson';
import { GameTheme, PaginatedList } from '@rpg/core/base';
import { DiscordDiceSize } from '@rpg/core/discord';

@Injectable({
  providedIn: 'root',
})
export class GameHttpService extends HttpService {
  @Get('/game')
  @MapPaginatedList(Game)
  public getGameList(
    @Query('page') page: number,
    @Query('limit') limit: number,
    @Query('sort') sort: GameSort
  ): Observable<PaginatedList<Game>> {
    return EMPTY;
  }

  @Get('/game/:id')
  @MapClass(Game)
  public getGameById(@Path('id') gameId: ObjectId): Observable<Game> {
    return EMPTY;
  }

  @Get('/game/character/:id')
  @MapClass(Game)
  public getGamesByCharacterId(@Path('id') characterId: ObjectId): Observable<Game[]> {
    return EMPTY;
  }

  @Get('/game/discord')
  @MapClass(Game)
  public getDiscordGame(
    @Query('guildId') guildId: string,
    @Query('channelId') channelId?: string
  ): Observable<Game> {
    return EMPTY;
  }

  @Get('/game/discord/all')
  @MapClass(Game)
  public getAllDiscordServerGames(@Query('guildId') guidlId: string): Observable<Game[]> {
    return EMPTY;
  }

  @Patch('/game/:id')
  public updateGameById(
    @Path('id') gameId: ObjectId,
    @Body('characterId') characterId: ObjectId,
    @Body('vehicleId') vehicleId: ObjectId,
    @Body('partyVehicleId') partyVehicleId: ObjectId,
    @Body('critTableId') critTableId: string,
    @Body('vehicleCritTableId') vehicleCritTableId: string,
    @Body('gameDiceSize') gameDiceSize: DiscordDiceSize,
    @Body('gameTheme') gameType: GameTheme,
    @Body('name') gameName: string,
    @Body('gmStoryPointStyle') gmStoryPointStyle: StoryPointsStyle,
    @Body('pcStoryPointStyle') pcStoryPointStyle: StoryPointsStyle
  ): Observable<boolean> {
    return EMPTY;
  }

  @Delete('/game/:id')
  public leaveGameById(@Path('id') gameId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Post('/game/:id/createChildGame')
  @MapClass(Game)
  public createChildGame(
    @Path('id') gameId: ObjectId,
    @Body('name') name: string
  ): Observable<Game> {
    return EMPTY;
  }

  @Post('/game/:id/addMember')
  public addMember(
    @Path('id') gameId: ObjectId,
    @Body('userId') userId: ObjectId
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/game/:id/removeMember')
  public removeMember(
    @Path('id') gameId: ObjectId,
    @Body('userId') userId: ObjectId
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/game/:id/removeAllMembers')
  public removeAllMembers(@Path('id') gameId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Post('/game/:id/addChannel')
  public addChannel(
    @Path('id') gameId: ObjectId,
    @Body('channelId') channelId: string
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/game/:id/removeChannel')
  public removeChannel(
    @Path('id') gameId: ObjectId,
    @Body('channelId') channelId: string
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/game/:id/delete')
  public deleteGame(@Path('id') gameId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Put('/game/:id/settings/gameMasters')
  public setGameMasters(
    @Path('id') gameId: ObjectId,
    @Body() data: { gameMasters: ObjectId[] }
  ): Observable<Game> {
    return EMPTY;
  }

  @Put('/game/:id/settings/general')
  public updateGameGeneralSettings(
    @Path('id') gameId: ObjectId,
    @Body() params: any
  ): Observable<Game> {
    return EMPTY;
  }

  @Put('/game/:id/settings/character')
  public updateGameCharacterSettings(
    @Path('id') gameId: ObjectId,
    @Body() params: any
  ): Observable<Game> {
    return EMPTY;
  }

  @Put('/game/:id/settings/vehicle')
  public updateGameVehicleSettings(
    @Path('id') gameId: ObjectId,
    @Body() params: any
  ): Observable<Game> {
    return EMPTY;
  }

  @Put('/game/:id/settings/critTables')
  public updateGameCritTablesSettings(
    @Path('id') gameId: ObjectId,
    @Body() params: any
  ): Observable<Game> {
    return EMPTY;
  }

  @Put('/game/:id/settings/discord')
  public updateDiscordSettings(
    @Path('id') gameId: ObjectId,
    @Body() params: any
  ): Observable<Game> {
    return EMPTY;
  }

  @Put('/game/:id/settings/player')
  public updatePlayerOptions(@Path('id') gameId: ObjectId, @Body() params: any): Observable<Game> {
    return EMPTY;
  }
}

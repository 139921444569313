import { createId } from '@rpg/core/base';
import { DiceMetaType } from '../enums';

export class DiceMeta {
  public id: string = '';
  public key: DiceMetaType = DiceMetaType.Info;
  public displayName: string = '';
  public attrs: Record<string, string | number> = {};

  constructor(params?: Partial<DiceMeta>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.key = params.key ?? this.key;
      this.displayName = params.displayName ?? this.displayName;
      this.attrs = params.attrs ?? this.attrs;
    }
    if (!this.id) {
      this.id = createId();
    }
  }
}

import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterConfigurationData } from '@rpg/core/character';
import { baseContentConfigurationToFormGroup } from '../../../base';

export function ndsCharacterConfigurationToFormGroup(
  config: NdsCharacterConfigurationData
): FormGroup<NdsCharacterConfigurationData> {
  return new FormGroup<NdsCharacterConfigurationData>({
    ...baseContentConfigurationToFormGroup(config).controls,
    ndsCharacterType: new FormControl(config.ndsCharacterType),
    talentsDisplayType: new FormControl(config.talentsDisplayType),
    talentsSignatureAbilitiesEnabled: new FormControl(config.talentsSignatureAbilitiesEnabled),
    forcePowersDisplayType: new FormControl(config.forcePowersDisplayType),
    dutyEnabled: new FormControl(config.dutyEnabled),
    forceEnabled: new FormControl(config.forceEnabled),
    forceDiceEnabled: new FormControl(config.forceDiceEnabled),
    moralityEnabled: new FormControl(config.moralityEnabled),
    obligationEnabled: new FormControl(config.obligationEnabled),
    superCharacteristicsEnabled: new FormControl(config.superCharacteristicsEnabled),
    heroicAbilitiesEnabled: new FormControl(config.heroicAbilitiesEnabled),
    aemberEnabled: new FormControl(config.aemberEnabled),
    favorsEnabled: new FormControl(config.favorsEnabled),
    agendasEnabled: new FormControl(config.agendasEnabled),
    automations: new FormControl(config.automations),
  });
}

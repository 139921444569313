import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from '@sessions/env';

@Injectable({
  providedIn: 'root',
})
export class ReviewOnlyGuard implements CanActivate {
  constructor(private _router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (environment.production) {
      this._router.navigate(['/dice']);
      return false;
    }
    return true;
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NdsCharacterType } from '@rpg/core/character';

@Component({
  selector: 'rpg-nds-character-type',
  templateUrl: './character-type.component.html',
  styleUrls: ['./character-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NdsCharacterTypeComponent {
  @Input()
  public type!: NdsCharacterType;

  public NdsCharacterType: typeof NdsCharacterType = NdsCharacterType;

  constructor() {}
}

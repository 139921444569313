export enum RulesSystem {
  NarrativeDiceSystem = '[rules] nds',
  AGE = '[rules] age',
  Generic = '[rules] generic',
}

export namespace RulesSystem {
  export function toString(type: RulesSystem): string {
    switch (type) {
      case RulesSystem.NarrativeDiceSystem:
        return 'Narrative Dice System';
      case RulesSystem.AGE:
        return 'Adventure Game Engine';
      case RulesSystem.Generic:
        return 'Generic';
      default:
        return '';
    }
  }

  export function translationKey(type: RulesSystem): string {
    return `enums.core.rules-system.${type}`;
  }

  export const availableGameOptions: RulesSystem[] = [RulesSystem.NarrativeDiceSystem];

  export const members: RulesSystem[] = [
    RulesSystem.Generic,
    RulesSystem.AGE,
    RulesSystem.NarrativeDiceSystem,
  ];

  export function toUrlParam(type: RulesSystem): string | null {
    switch (type) {
      case RulesSystem.Generic:
        return 'generic';
      case RulesSystem.AGE:
        return 'gr-age';
      case RulesSystem.NarrativeDiceSystem:
        return 'nds';
      default:
        return null;
    }
  }

  export function fromUrlParam(param: string): RulesSystem | null {
    const sanitized: string = `${param}`.trim().toLowerCase();
    switch (sanitized) {
      case 'generic':
        return RulesSystem.Generic;
      case 'gr-age':
        return RulesSystem.AGE;
      case 'nds':
        return RulesSystem.NarrativeDiceSystem;
      default:
        return null;
    }
  }
}

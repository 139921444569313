import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { GameTheme } from '@rpg/core/base';
import {
  NDS_VEHICLE_CHARACTERISTIC_DEFAULT_VALUE,
  NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE,
  NDS_VEHICLE_CHARACTERISTIC_MAX_VALUE,
} from '@rpg/core/vehicle';
import { RxState } from '@rx-angular/state';

interface ComponentState {
  attrImage: string;
  editMode: boolean;
  labels: string[];
  resolvedValues: number[];
}

@Component({
  selector: 'rpg-nds-vehicle-characteristic-double',
  templateUrl: './characteristic-double.component.html',
  styleUrls: ['./characteristic-double.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class NdsVehicleCharacteristicDoubleComponent {
  @Input()
  public set gameTheme(theme: GameTheme) {
    this._updateAttrImage(theme);
  }

  @Input()
  public set labels(labels: string[]) {
    this._state.set({
      labels,
    });
  }

  @Input()
  public set editMode(editMode: boolean) {
    this._state.set({
      editMode,
    });
  }

  @Input()
  public set resolvedValues(resolvedValues: number[]) {
    this._state.set({
      resolvedValues,
    });
  }

  @Input()
  public controls!: FormControl<number>[];

  public state$ = this._state.select();

  constructor(private _state: RxState<ComponentState>) {}

  public checkValue(control: FormControl): void {
    const val = +control.value;
    if (!val) {
      control.setValue(NDS_VEHICLE_CHARACTERISTIC_DEFAULT_VALUE);
    } else if (isNaN(val)) {
      control.setValue(NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE);
    } else if (val < NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE) {
      control.setValue(NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE);
    } else if (val > NDS_VEHICLE_CHARACTERISTIC_MAX_VALUE) {
      control.setValue(NDS_VEHICLE_CHARACTERISTIC_MAX_VALUE);
    }
  }

  private _updateAttrImage(theme: GameTheme): void {
    let imgSrc: string = '';
    switch (theme) {
      case GameTheme.StarWars_AoR:
      case GameTheme.StarWars_EotE:
      case GameTheme.StarWars_FaD:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/starwars/vehstat02-sw.svg?auto=format';
        break;
      case GameTheme.Genesys_Android:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-android/vehstat02-and.svg?auto=format';
        break;
      case GameTheme.Genesys_TwilightImperium:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-imperium/vehstat02-ti.svg?auto=format';
        break;
      case GameTheme.Genesys_Core:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-core/vehstat02-gen.svg?auto=format';
        break;
      case GameTheme.Genesys_Keyforge:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-keyforge/vehstat02-kf.svg?auto=format';
        break;
      case GameTheme.Genesys_Terrinoth:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/genesys-terrinoth/vehstat02-ter.svg?auto=format';
        break;
      case GameTheme.Community_AwakenedAge:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-awakenedage/vehstat02-aa.svg?auto=format';
        break;
      case GameTheme.Community_Inquisition:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-inquisition/vehstat02-in.svg?auto=format';
        break;
      case GameTheme.Community_SomethingStrange:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-somethingstrange/vehstat02-ss.svg?auto=format';
        break;
      case GameTheme.Community_Tron:
        imgSrc =
          'https://unbound-legends.imgix.net/rules-nds/community-tron/vehstat02-tron.svg?auto=format';
        break;
      default:
        throw new Error('Invalid Game Theme supplied to NdsVehicleAttributeSingle');
    }
    this._state.set({
      attrImage: imgSrc,
    });
  }
}

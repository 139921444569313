import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslocoService } from '@ngneat/transloco';
import { fuzzySearch, Logger, RpgSelectOption, DiceTheme } from '@rpg/core/base';
import { MarkdownService } from 'ngx-markdown';
import { Observable, of, Subject, map, startWith, takeUntil } from 'rxjs';
import { MarkdownConfigService } from '@rpg/ngx/core';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'rpg-select-typeahead',
  templateUrl: './select-typeahead.component.html',
  styleUrls: ['./select-typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectTypeaheadComponent),
      multi: true,
    },
  ],
})
export class SelectTypeaheadComponent implements OnChanges, AfterContentInit, OnDestroy {
  @Input()
  public markdownLabel: boolean = false;
  @Input()
  public placeholder: string = '';
  @Input()
  public type: string = 'text';
  @Input()
  public inputControl: UntypedFormControl = new UntypedFormControl('');
  private _options: RpgSelectOption[] = [];
  @Input()
  public set options(value: RpgSelectOption[]) {
    this._options = value;
    this.inputControl?.updateValueAndValidity();
    this._cd.markForCheck();
  }
  public get options(): RpgSelectOption[] {
    return this._options;
  }
  @Input()
  public prefixIconName: IconProp | null = null;
  @Input()
  public suffixIconName: IconProp | null = null;
  @Input()
  public read: string = '';
  @Input()
  public noTranslation: boolean = false;
  private _disabled: boolean = false;
  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    Logger.log('SET DISABLED', value);
    this.setDisabledState(value);
  }
  public get disabled(): boolean {
    return this._disabled;
  }
  public filteredOptions$: Observable<RpgSelectOption[]> = of([]);
  @Input()
  public minCharacters: number = 0;
  @Input()
  // eslint-disable-next-line no-magic-numbers
  public resultLimit: number = 10;
  @Input()
  public markdownDiceTheme: DiceTheme = DiceTheme.Generic;

  @ViewChild(FormFieldComponent)
  public formField!: FormFieldComponent;

  private _unsub: Subject<void> = new Subject<void>();

  constructor(
    @Optional() public fg: FormGroupDirective,
    private _cd: ChangeDetectorRef,
    private _markdown: MarkdownService,
    private _markdownConfig: MarkdownConfigService,
    private _translocoService: TranslocoService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.filteredOptions$ = this.inputControl.valueChanges.pipe(
      startWith(''),
      map(inputValue => {
        if (this.minCharacters != null && inputValue.length < this.minCharacters) {
          return [];
        }

        if (!inputValue) {
          if (this.resultLimit != null) {
            return this.options.slice(0, this.resultLimit);
          } else {
            return this.options;
          }
        }

        const options = this.options || [];
        const fuzzyResults = fuzzySearch(options, inputValue, {
          keys: ['label'],
        });
        const results = fuzzyResults.map(x => x.item);
        if (this.resultLimit != null) {
          return results.slice(0, this.resultLimit);
        } else {
          return results;
        }
      })
    );
  }

  ngAfterContentInit(): void {
    if (!!this.fg) {
      this.fg.ngSubmit.pipe(takeUntil(this._unsub)).subscribe(event => {
        this.formField._checkForChanges();
      });
    }
    this.setDisabledState(this.disabled);
  }

  ngOnDestroy(): void {
    this._unsub.next();
    this._unsub.complete();
  }

  public __parseLabel(l: string): string {
    return this._markdownConfig._diceReplacement(l, this.markdownDiceTheme);
  }

  public onTouched: () => void = () => {};

  public writeValue(val: any): void {
    this.inputControl.setValue(val);
  }

  public registerOnChange(fn: any): void {
    this.inputControl.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  public getTranslatedInput(label: string): string {
    // Intentionally not ignoring empty reads right now to help identify
    // select boxes that haven't been changed to use i18n
    if (this.noTranslation) {
      return label;
    }

    // If no read is passed in, then we should assume the label
    // should remain untranslated
    if (!this.read) {
      return label;
    }

    const translation = this._translocoService.translate(`${this.read}.${label}`);
    if (translation.indexOf(this.read) === 0) {
      return label;
    }

    return translation;
  }

  public getLabel(value: any): string {
    return this.options.find(x => x.value === value)?.label || '';
  }
}

import { Images } from './images';

export enum CharacterImages {
  SwordGuy,
  RoboDrill,
  Mrax,
  PinkDroid,
  Ranger,
}

export namespace CharacterImages {
  export type ImageMember = {
    char: CharacterImages;
    index: number;
    src: string;
    alt: string;
  };
  export const members: CharacterImages.ImageMember[] = [
    {
      char: CharacterImages.SwordGuy,
      index: 0,
      src: Images.CharacterLogo1,
      alt: 'Sword Guy',
    },
    {
      char: CharacterImages.RoboDrill,
      index: 1,
      src: Images.CharacterLogo2,
      alt: 'Robodrill',
    },
    {
      char: CharacterImages.Mrax,
      index: 2,
      src: Images.CharacterLogo3,
      alt: 'Mrax',
    },
    {
      char: CharacterImages.PinkDroid,
      index: 3,
      src: Images.CharacterLogo4,
      alt: 'Pink Droid',
    },
    {
      char: CharacterImages.Ranger,
      index: 4,
      src: Images.CharacterLogo5,
      alt: 'Ranger',
    },
  ];
}

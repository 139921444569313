import { Injectable } from '@angular/core';
import { RPGAction } from '@rpg/core/action-stream';
import { parseDate, RulesSystem } from '@rpg/core/base';
import { BaseCharacter, mapCharacterToClass } from '@rpg/core/character';
import { Dice, DiceMeta } from '@rpg/core/dice';
import { DiscordChannel } from '@rpg/core/discord';
import { Game, GameNPCVisibility, GameTableMessage, GameTableNote } from '@rpg/core/game';
import { AddNPCOptions } from '@rpg/core/game-table';
import { User } from '@rpg/core/user';
import { BaseVehicle, mapVehicleToClass } from '@rpg/core/vehicle';
import { Body, Get, HttpService, MapClass, MapValue, Path, Post, Query } from '@rpg/ngx/http';
import { ObjectId } from 'bson';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GameTableHttpService extends HttpService {
  @Post('/game-table/create')
  @MapClass(Game)
  public createNewGame(
    @Body('name') name: string,
    @Body('rulesSystem') rulesSystem: RulesSystem
  ): Observable<Game> {
    return EMPTY;
  }

  @Post('/game-table/:id/dispatch')
  public dispatch(@Path('id') gameId: ObjectId, @Body() action: RPGAction): Observable<void> {
    return EMPTY;
  }

  @Post('/game-table/:id/join')
  @MapClass(Game)
  public joinGame(@Path('id') gameId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Get('/game-table/:id/characters')
  @MapValue((res: BaseCharacter[]) => res.map(r => mapCharacterToClass(r)))
  public getGameCharacters(@Path('id') gameId: ObjectId): Observable<BaseCharacter[]> {
    return EMPTY;
  }

  @Get('/game-table/:id/vehicles')
  @MapValue((res: BaseVehicle[]) => res.map(r => mapVehicleToClass(r)))
  public getGameVehicles(@Path('id') gameId: ObjectId): Observable<BaseVehicle[]> {
    return EMPTY;
  }

  @Get('/game-table/:id/npcs')
  @MapValue((res: BaseCharacter[]) => res.map(r => mapCharacterToClass(r)))
  public getGameNPCs(@Path('id') gameId: ObjectId): Observable<BaseCharacter[]> {
    return EMPTY;
  }

  @Post('/game-table/:id/npcVisibility')
  public npcVisibility(
    @Path('id') gameId: ObjectId,
    @Body('npcId') npcId: ObjectId,
    @Body('visibility') visibility: GameNPCVisibility
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/game-table/:id/addNPC')
  public addNPC(
    @Path('id') gameId: ObjectId,
    @Body('npcId') npcId: ObjectId,
    @Body('options')
    options?: AddNPCOptions
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/game-table/:id/removeNPC')
  public removeNPC(
    @Path('id') gameId: ObjectId,
    @Body('npcId') npcId: ObjectId
  ): Observable<boolean> {
    return EMPTY;
  }

  @Get('/game-table/:id/users')
  @MapClass(User)
  public getGameMembers(@Path('id') gameId: ObjectId): Observable<User[]> {
    return EMPTY;
  }

  @Post('/game-table/:id/characters')
  public setCharacters(
    @Path('id') gameId: ObjectId,
    @Body('characterIds') characterId: ObjectId[]
  ): Observable<void> {
    return EMPTY;
  }

  // @Get('/game-table/:id/notes')
  // @MapClass(Note)
  // public getNotes(
  //   @Path('id') gameId: ObjectId,
  //   @Query('beforeDate') beforeDate?: Date
  // ): Observable<Note[]> {
  //   return EMPTY;
  // }

  @Get('/game-table/:id/history')
  @MapClass(GameTableMessage)
  public getHistory(
    @Path('id') gameId: ObjectId,
    @Query('beforeDate') beforeDate?: Date
  ): Observable<GameTableMessage[]> {
    return EMPTY;
  }

  @Get('/game-table/:id/messages')
  @MapClass(GameTableNote)
  public getMessages(
    @Path('id') gameId: ObjectId,
    @Query('beforeDate') beforeDate?: Date
  ): Observable<GameTableNote[]> {
    return EMPTY;
  }

  @Post('/game-table/:id/messages')
  public addMessage(
    @Path('id') gameId: ObjectId,
    @Body('message') message: string
  ): Observable<void> {
    return EMPTY;
  }

  @Get('/game-table/:id/discordChannelList')
  @MapClass(DiscordChannel)
  public getDiscordChannelList(
    @Path('id') gameId: ObjectId,
    @Query('refresh', false) refresh?: boolean
  ): Observable<DiscordChannel[]> {
    return EMPTY;
  }

  @Get('/game-table/:id/discordChannelListRefreshDate')
  @MapValue((res: any) => parseDate(res))
  public getDiscordChannelListRefreshDate(@Path('id') gameId: ObjectId): Observable<Date> {
    return EMPTY;
  }

  @Get('/game-table/:id/availableNPCs')
  @MapValue((res: BaseCharacter[]) => res.map(r => mapCharacterToClass(r)))
  public getAvailableNPCs(@Path('id') gameId: ObjectId): Observable<BaseCharacter[]> {
    return EMPTY;
  }

  @Post('/game-table/:id/roll-crit')
  public rollCrit(
    @Path('id') gameId: ObjectId,
    @Body('characterId') characterId: ObjectId,
    @Body('modifier') modifier: number
  ): Observable<void> {
    return EMPTY;
  }

  @Post('/game-table/:id/dice')
  @MapClass(Dice)
  public rollDice(
    @Path('id') gameId: ObjectId,
    @Body()
    params: {
      diceString: string;
      description: string;
      diceChannelId?: string;
      asCharacterId?: ObjectId;
      diceMeta?: DiceMeta[];
    }
  ): Observable<Dice> {
    return EMPTY;
  }

  @Post('/game-table/:id/saveSupabaseRoll')
  @MapClass(Dice)
  public saveSupabaseRoll(
    @Path('id') gameId: ObjectId,
    @Body('roll_id') roll_id: string,
    @Body('diceChannelId') diceChannelId?: string,
    @Body('asCharacterId') asCharacterId?: ObjectId
  ): Observable<Dice> {
    return EMPTY;
  }

  @Post('/game-table/:id/kick-player')
  public kickPlayer(
    @Path('id') gameId: ObjectId,
    @Body('playerId') playerId: ObjectId,
    @Body('ban') ban: boolean
  ): Observable<boolean> {
    return EMPTY;
  }

  public uploadFileNote(gameId: ObjectId, uploadFile: File, comment: string): Observable<any> {
    const endpoint = `/game-table/${gameId.toHexString()}/messages/file`;
    const formData: FormData = new FormData();
    formData.append('file', uploadFile, uploadFile.name);
    formData.append('message', comment);
    return this._http.post<string[]>(endpoint, formData);
  }
}

import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterModifierWeaponData } from '@rpg/core/character';
import { ndsCharacterModifierBaseToFormGroup } from './nds-character-modifier-base-to-form-group';

export function ndsCharacterModifierWeaponToFormGroup(
  mod: NdsCharacterModifierWeaponData
): FormGroup<NdsCharacterModifierWeaponData> {
  return new FormGroup<NdsCharacterModifierWeaponData>({
    ...ndsCharacterModifierBaseToFormGroup(mod).controls,
    critModifier: new FormControl(mod.critModifier),
    encumbranceModifier: new FormControl(mod.encumbranceModifier),
    damageModifier: new FormControl(mod.damageModifier),
    extraDice: new FormControl(mod.extraDice),
    includeUncommittedForceDice: new FormControl(mod.includeUncommittedForceDice),
    linkedWeaponId: new FormControl(mod.linkedWeaponId),
  });
}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ThemeOption, Theme } from '@rpg/core/user';
import { Observable, BehaviorSubject } from 'rxjs';
import { CookieService } from './cookie.service';
import { CookieKey, Logger } from '@rpg/core/base';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  // prettier-ignore
  private _themeStream: BehaviorSubject<ThemeOption>
        = new BehaviorSubject<ThemeOption>(ThemeOption.Dark);

  // prettier-ignore
  public currentTheme$: Observable<ThemeOption>
        = this._themeStream.asObservable();

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _cookieService: CookieService
  ) {}

  public get currentTheme(): ThemeOption {
    return this._themeStream.getValue();
  }

  public changeTheme(selectedTheme: ThemeOption): void {
    if (isPlatformBrowser(this._platformId)) {
      this._applyTheme(Theme.getTheme(selectedTheme));
      this._applyBodyClass(Theme.getBodyClass(selectedTheme));
      this._themeStream.next(selectedTheme);
      this._cookieService.set(CookieKey.SiteTheme, selectedTheme);
      Logger.log('theme applied', selectedTheme);
    }
  }

  public restoreThemeIfExists(): void {
    if (isPlatformBrowser(this._platformId)) {
      const savedTheme = this._cookieService.get(CookieKey.SiteTheme);
      if (!!savedTheme && ThemeOption.members.includes(savedTheme as ThemeOption)) {
        this.changeTheme(savedTheme as ThemeOption);
      } else {
        this.changeTheme(ThemeOption.defaultValue);
      }
    }
  }

  private _applyTheme(theme: Theme): void {
    const docStyle = document.documentElement.style;
    for (const key of Object.keys(theme)) {
      docStyle.setProperty(Theme.getPropKey(key as keyof Theme), theme[key as keyof Theme]);
    }
  }

  private _applyBodyClass(themeClass: string): void {
    const body = document.body.classList;
    Theme.themeClasses.forEach(tc => body.remove(tc));
    body.add(themeClass);
  }
}

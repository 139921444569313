/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  merge,
  distinctUntilChanged,
  debounceTime,
  startWith,
} from 'rxjs';
import io, { Socket } from 'socket.io-client';
import { GatewayOptions, WebsocketConnectionStatus } from '../models';

@Injectable()
export class WebsocketService {
  private _host: string = '';
  private _namespace: string = '';
  private _socket: Socket;
  private _allEventsToRegister: { eventName: string; cb: (...args: any[]) => void }[] = [];
  // prettier-ignore
  private _connectStatus: ReplaySubject<WebsocketConnectionStatus> =
    new ReplaySubject<WebsocketConnectionStatus>();
  private _priorityEvents: ReplaySubject<WebsocketConnectionStatus> = new ReplaySubject();
  private _configOptions: any = {
    maxFailedConnectionAttempts: 5,
    notificationDebounceTime: 1000,
  };

  // Exposed Observables
  public connectionStatus$: Observable<WebsocketConnectionStatus> = merge(
    this._connectStatus
      .asObservable()
      .pipe(distinctUntilChanged(), debounceTime(this._configOptions.notificationDebounceTime)),
    this._priorityEvents.asObservable()
  ).pipe(startWith(WebsocketConnectionStatus.Disconnected));

  // Exposed State
  public isConnected: boolean = false;

  constructor() {
    const options: GatewayOptions = (this as any)['_gatewayOptions'] ?? null;
    if (options === null) {
      throw new Error('A service that extends WebsocketService must use the WebGateway Decorator');
    }
    this._host = options.host;
    this._namespace = options.namespace;
    this._socket = io(`${this._host}${this._namespace}`, {
      autoConnect: false,
      reconnectionAttempts: this._configOptions.maxFailedConnectionAttempts,
    });
    if (
      Array.isArray((this as any)['_eventsToRegister']) &&
      (this as any)['_eventsToRegister'].length > 0
    ) {
      this._allEventsToRegister = [
        ...(this as any)['_eventsToRegister'],
        ...this._allEventsToRegister,
      ];
    }
    this._allEventsToRegister.push(
      {
        eventName: 'connect',
        cb: (data: any) => {
          this.isConnected = true;
          this._connectStatus.next(WebsocketConnectionStatus.Connected);
        },
      },
      {
        eventName: 'disconnect',
        cb: (data: string) => {
          this.isConnected = false;
          this._connectStatus.next(WebsocketConnectionStatus.Disconnected);
        },
      },
      {
        eventName: 'reconnect_attempt',
        cb: (attemptNumber: number) => {
          this.isConnected = false;
          this._connectStatus.next(WebsocketConnectionStatus.Reconnecting);
        },
      },
      {
        eventName: 'reconnect_failed',
        cb: (error: any) => {
          this.isConnected = false;
          this._connectStatus.next(WebsocketConnectionStatus.Disconnected);
        },
      }
    );
    this._registerEvents(this._allEventsToRegister);
  }

  public connect(): void {
    this._priorityEvents.next(WebsocketConnectionStatus.Connecting);
    this._socket.connect();
  }

  public disconnect(): void {
    this._socket.disconnect();
  }

  protected _sendNewEvent(eventName: string, eventData: any): void {
    this._socket.emit(eventName, eventData);
  }

  private _registerEvents(events: { eventName: string; cb: (...args: any[]) => void }[]): void {
    events.forEach(event => {
      this._socket.on(event.eventName, eventData => {
        event.cb(eventData);
      });
    });
  }
}

import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsCharacterHeroicAbilityData } from '@rpg/core/character';
import { ndsCharacterHeroicAbilityLevelToFormGroup } from './nds-character-heroic-ability-level-to-form-group';
import { ndsCharacterHeroicAbilityUpgradeToFormGroup } from './nds-character-heroic-ability-upgrade-to-form-group';

export function ndsCharacterHeroicAbilityToFormGroup(
  ability: NdsCharacterHeroicAbilityData
): FormGroup<NdsCharacterHeroicAbilityData> {
  return new FormGroup<NdsCharacterHeroicAbilityData & Expandable>({
    expanded: new FormControl((ability as any)['expanded'] ?? false),
    base: ndsCharacterHeroicAbilityLevelToFormGroup(ability.base),
    description: new FormControl(ability.description),
    id: new FormControl(ability.id),
    improved: ndsCharacterHeroicAbilityLevelToFormGroup(ability.improved),
    name: new FormControl(ability.name),
    origins: new FormControl(ability.origins),
    supreme: ndsCharacterHeroicAbilityLevelToFormGroup(ability.supreme),
    upgrades: new FormArray(
      ability.upgrades.map(u => ndsCharacterHeroicAbilityUpgradeToFormGroup(u))
    ),
  });
}

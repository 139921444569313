import { RulesSystem, unreachable } from '@rpg/core/base';
import { BaseCharacter } from '../models';
import { NdsCharacter } from '../rules-nds';

export function validateBaseCharacter(data: BaseCharacter): string[] {
  let errors: string[] = [];
  switch (data.rulesSystem) {
    case RulesSystem.NarrativeDiceSystem:
      errors = NdsCharacter.validate(data as any);
      break;
    case RulesSystem.AGE:
    case RulesSystem.Generic:
      break;
    default:
      unreachable(data.rulesSystem, 'validateBaseCharacter');
  }
  return errors;
}

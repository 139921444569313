import { createId, parseBoolean, parseEnum, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterFavorSize } from '../enums';

export class NdsCharacterFavorData {
  public id: string = '';
  public favorSize: NdsCharacterFavorSize = NdsCharacterFavorSize.Regular;
  public faction: string = '';
  public description: string = '';
  public resolved: boolean = false;

  constructor(params?: Partial<NdsCharacterFavorData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.favorSize = parseEnum(NdsCharacterFavorSize, params.favorSize, this.favorSize);
      this.faction = params.faction ?? this.faction;
      this.description = params.description ?? this.description;
      this.resolved = parseBoolean(params.resolved, this.resolved);
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsCharacterFavorData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id, { fieldName: 'Favor ID' }));
    errors.push(
      ...RpgClassValidators.enum(input.favorSize, NdsCharacterFavorSize, {
        enumName: 'Favor Size',
        fieldName: 'Favor Size',
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.faction, { allowEmpty: true, fieldName: 'Favor Faction' })
    );
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: 'Favor Description',
      })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.resolved, { fieldName: 'Favor Resolved Toggle' })
    );
    return errors;
  }
}

import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterTalentData } from '@rpg/core/character';
import { NDSCharacterModifierFormGroupFactory } from './modifiers';

export function ndsCharacterTalentToFormGroup(
  talent: NdsCharacterTalentData
): FormGroup<NdsCharacterTalentData> {
  return new FormGroup<NdsCharacterTalentData>({
    activationType: new FormControl(talent.activationType),
    description: new FormControl(talent.description),
    id: new FormControl(talent.id),
    isConflictTalent: new FormControl(talent.isConflictTalent),
    isForceTalent: new FormControl(talent.isForceTalent),
    modifiers: new FormArray(talent.modifiers.map(m => NDSCharacterModifierFormGroupFactory(m))),
    name: new FormControl(talent.name),
    purchased: new FormControl(talent.purchased),
    ranked: new FormControl(talent.ranked),
    ranks: new FormControl(talent.ranks),
    xpCost: new FormControl(talent.xpCost),
  });
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { components } from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { LetModule } from '@rx-angular/template';
import { SharedComponentsModule } from '../../shared';
import { TranslocoModule } from '@ngneat/transloco';
import { MarkdownModule } from 'ngx-markdown';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LetModule,
    SharedComponentsModule,
    TranslocoModule,
    MarkdownModule,
    MatCheckboxModule,
    MatButtonModule,
    FontAwesomeModule,
    MatTooltipModule,
  ],
})
export class NdsCharacterComponentsModule {}

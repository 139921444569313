import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterMoralityEntryData } from '@rpg/core/character';

export function ndsCharacterMoralityEntryToFormGroup(
  entry: NdsCharacterMoralityEntryData
): FormGroup<NdsCharacterMoralityEntryData> {
  return new FormGroup<NdsCharacterMoralityEntryData>({
    id: new FormControl(entry.id),
    strengthDescription: new FormControl(entry.strengthDescription),
    strengthName: new FormControl(entry.strengthName),
    weaknessDescription: new FormControl(entry.weaknessDescription),
    weaknessName: new FormControl(entry.weaknessName),
  });
}

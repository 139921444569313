import { FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterModifierNarrativeData } from '@rpg/core/character';
import { ndsCharacterModifierBaseToFormGroup } from './nds-character-modifier-base-to-form-group';

export function ndsCharacterModifierNarrativeToFormGroup(
  mod: NdsCharacterModifierNarrativeData
): FormGroup<NdsCharacterModifierNarrativeData> {
  return new FormGroup<NdsCharacterModifierNarrativeData>({
    ...ndsCharacterModifierBaseToFormGroup(mod).controls,
  });
}

import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleModifierWeaponData } from '@rpg/core/vehicle';
import { ndsVehicleModifierBaseToFormGroup } from './nds-vehicle-modifier-base-to-form-group';

export function ndsVehicleModifierWeaponToFormGroup(
  mod: NdsVehicleModifierWeaponData
): FormGroup<NdsVehicleModifierWeaponData> {
  return new FormGroup<NdsVehicleModifierWeaponData>({
    ...ndsVehicleModifierBaseToFormGroup(mod).controls,
    linkedWeaponId: new FormControl(mod.linkedWeaponId, [Validators.required]),
    critModifier: new FormControl(mod.critModifier),
    damageModifier: new FormControl(mod.damageModifier),
    extraDice: new FormControl(mod.extraDice),
  });
}

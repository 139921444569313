import { FormArray, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterForceConfigData } from '@rpg/core/character';
import { ndsCharacterTalentListGroupToFormGroup, ndsCharacterTalentToFormGroup } from '.';
import { ndsCharacterForcePowerTreeToFormGroup } from './nds-character-force-power-tree-to-form-group';

export function ndsCharacterForcePowerConfigToFormGroup(
  config: NdsCharacterForceConfigData
): FormGroup<NdsCharacterForceConfigData> {
  return new FormGroup<NdsCharacterForceConfigData>({
    talents: new FormArray(config.talents.map(t => ndsCharacterTalentToFormGroup(t))),
    trees: new FormArray(config.trees.map(t => ndsCharacterForcePowerTreeToFormGroup(t))),
    listGroups: new FormArray(
      config.listGroups.map(l => ndsCharacterTalentListGroupToFormGroup(l))
    ),
  });
}

<div
  class="modifiers__container"
  [class.modifiers__container--edit-mode]="editMode"
  *ngFor="let m of modifiersArray"
>
  <button
    *ngIf="editMode"
    mat-mini-fab
    class="modifiers__edit-button"
    color="accent"
    type="button"
    (click)="editModifier.emit(m)"
  >
    <fa-icon icon="pencil-alt"></fa-icon>
  </button>
  <markdown
    class="modifiers__description"
    [data]="
      m.get('name').value + (m.get('description').value ? ': ' : '') + m.get('description').value
    "
  ></markdown>
</div>

export enum NdsCharacterModifierType {
  Armor = '[nds character modifier type] armor',
  Attribute = '[nds character modifier type] attribute',
  Characteristic = '[nds character modifier type] characteristic',
  Narrative = '[nds character modifier type] narrative',
  Skill = '[nds character modifier type] skill',
  Weapon = '[nds character modifier type] weapon',
}

export namespace NdsCharacterModifierType {
  export function translationKey(type: NdsCharacterModifierType): string {
    return `enums.character.nds.modifier-type.${type}`;
  }

  export const members: NdsCharacterModifierType[] = [
    NdsCharacterModifierType.Armor,
    NdsCharacterModifierType.Attribute,
    NdsCharacterModifierType.Characteristic,
    NdsCharacterModifierType.Narrative,
    NdsCharacterModifierType.Skill,
    NdsCharacterModifierType.Weapon,
  ];
}

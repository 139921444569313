export class UserPasswordUpdate {
  public currentPassword: string = '';
  public newPassword: string = '';

  constructor(params?: Partial<UserPasswordUpdate>) {
    if (!!params) {
      this.currentPassword = params.currentPassword ?? this.currentPassword;
      this.newPassword = params.newPassword ?? this.newPassword;
    }
  }
}

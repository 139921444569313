import { PatreonReward, PatreonTier } from '@rpg/core/patreon';
import { GameFeature } from './game-feature';

export enum GameTokenType {
  Explorer = '[game token type] Explorer',
  Adventurer = '[game token type] Adventurer',
  Champion = '[game token type] Champion',
}

export namespace GameTokenType {
  export const members: GameTokenType[] = [
    GameTokenType.Explorer,
    GameTokenType.Adventurer,
    GameTokenType.Champion,
  ];

  export const explorerFeatures: GameFeature[] = [GameFeature.CustomStoryPointStyles];

  export const adventurerFeatures: GameFeature[] = [GameFeature.CustomStoryPointStyles];

  export const championFeatures: GameFeature[] = [GameFeature.CustomStoryPointStyles];

  export function toString(type: GameTokenType): string {
    switch (type) {
      case GameTokenType.Explorer:
        return 'Explorer';
      case GameTokenType.Adventurer:
        return 'Adventurer';
      case GameTokenType.Champion:
        return 'Champion';
      default:
        return '';
    }
  }

  export function translationKey(type: GameTokenType): string {
    return `enums.table-token.game-token-type.${type}`;
  }

  export function toImageUrl(type: GameTokenType): string {
    switch (type) {
      case GameTokenType.Explorer:
        return 'https://unbound-legends.imgix.net/game/table-tokens/token-explorer.png?auto=format';
      case GameTokenType.Adventurer:
        return 'https://unbound-legends.imgix.net/game/table-tokens/token-adventurer.png?auto=format';
      case GameTokenType.Champion:
        return 'https://unbound-legends.imgix.net/game/table-tokens/token-champion.png?auto=format';
      default:
        return '';
    }
  }

  export function fromTier(tier: PatreonTier): GameTokenType[] {
    /**
     * The array lengths can change but each tier should only have one type of token
     */
    switch (tier) {
      case PatreonTier.Champion:
        return [GameTokenType.Champion];
      case PatreonTier.Adventurer:
        return [GameTokenType.Adventurer];
      case PatreonTier.Explorer:
        return [GameTokenType.Explorer];
      default:
        return [];
    }
  }

  export function rewards(token: GameTokenType): PatreonReward[] {
    switch (token) {
      case GameTokenType.Explorer:
        return PatreonTier.explorerRewards;
      case GameTokenType.Adventurer:
        return PatreonTier.adventurerRewards;
      case GameTokenType.Champion:
        return PatreonTier.championRewards;
      default:
        return [];
    }
  }

  export function features(token: GameTokenType): GameFeature[] {
    switch (token) {
      case GameTokenType.Explorer:
        return GameTokenType.explorerFeatures;
      case GameTokenType.Adventurer:
        return GameTokenType.adventurerFeatures;
      case GameTokenType.Champion:
        return GameTokenType.championFeatures;
      default:
        return [];
    }
  }

  export function tokenSort(a: { type: GameTokenType }, b: { type: GameTokenType }): 1 | 0 | -1 {
    if (a.type === b.type) {
      return 0;
    } else if (a.type === GameTokenType.Champion) {
      return -1;
    } else if (b.type === GameTokenType.Champion) {
      return 1;
    } else if (a.type === GameTokenType.Adventurer) {
      return -1;
    } else if (b.type === GameTokenType.Adventurer) {
      return 1;
    } else {
      return 0;
    }
  }
}

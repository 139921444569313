import { createId, parseNumber } from '../functions/utilities';
import { Position2D } from './position-2d';

export interface EmptyPositionVolatileData {}

// eslint-disable-next-line @typescript-eslint/ban-types
export class Position2DNodeRef<VolatileData extends EmptyPositionVolatileData = {}> {
  public id: string = '';
  public position: Position2D = new Position2D();
  public span: number = 1;
  public linkedId: string = '';
  public hidden: boolean = false;
  private _volatileData?: VolatileData;

  constructor(params?: Partial<Position2DNodeRef<VolatileData>>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.position = !!params.position ? new Position2D(params.position) : this.position;
      this.span = parseNumber(params.span, this.span);
      this.linkedId = params.linkedId ?? this.linkedId;
      this.hidden = params.hidden ?? this.hidden;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public get volatileData(): VolatileData {
    if (!this._volatileData) {
      this._volatileData = {} as VolatileData;
    }
    return this._volatileData;
  }

  public set volatileData(data: Partial<VolatileData>) {
    this._volatileData = {
      ...this.volatileData,
      ...data,
    };
  }

  public get isEmpty(): boolean {
    return !!this.linkedId;
  }
}

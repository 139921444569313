type RequiredNumber = number;
type OptionalNumber = RequiredNumber | string | undefined | null;
const radix = 10;

export function parseNumber(toParse: OptionalNumber): number | null;
export function parseNumber(toParse: OptionalNumber, defaultValue: RequiredNumber): number;
export function parseNumber(toParse: OptionalNumber, defaultValue?: RequiredNumber): number | null {
  if (toParse != null && toParse !== '') {
    if (typeof toParse === 'number' && !isNaN(toParse)) {
      return toParse;
    }
    if (typeof toParse === 'string') {
      if (toParse.includes('.')) {
        const parsed = parseFloat(toParse);
        if (!isNaN(parsed)) {
          return parsed;
        }
      } else {
        const parsed = parseInt(toParse, radix);
        if (!isNaN(parsed)) {
          return parsed;
        }
      }
    }
  }
  if (typeof defaultValue === 'number') {
    return defaultValue;
  }
  return null;
}

import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { BaseVehicle } from '@rpg/core/vehicle';
import { baseContentConfigurationToFormGroup } from '../base';
import { mongoDocToFormGroup } from '../mongo-doc-to-form-group';
import { vehicleOptionsToFormGroup } from './vehicle-options-to-form-group';

export function baseVehicleToFormGroup(vehicle: BaseVehicle): FormGroup<BaseVehicle> {
  return new FormGroup<BaseVehicle>({
    ...mongoDocToFormGroup(vehicle).controls,
    name: new FormControl(vehicle.name, [Validators.required]),
    lookupName: new FormControl(vehicle.lookupName),
    image: new FormControl(vehicle.image),
    notes: new FormControl(vehicle.notes),
    privateNotes: new FormControl(vehicle.privateNotes),
    rulesSystem: new FormControl(vehicle.rulesSystem, [Validators.required]),
    configuration: baseContentConfigurationToFormGroup(vehicle.configuration),
    userId: new FormControl(vehicle.userId),
    userFavorite: new FormControl(vehicle.userFavorite),
    clonedCount: new FormControl(vehicle.clonedCount),
    clonedFromVehicleId: new FormControl(vehicle.clonedFromVehicleId),
    importData: new FormControl(vehicle.importData),
    options: vehicleOptionsToFormGroup(vehicle.options),
    tempClone: new FormControl(vehicle.tempClone),
  });
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { GameTheme } from '@rpg/core/base';
import { RxState } from '@rx-angular/state';

type TypeOptions = 'standard' | 'alternate';
const SMALLER_SIZE_LIMIT = 5;

interface ComponentState {
  attrImage: string;
  type: TypeOptions;
  title: string;
  label: string;
  editMode: boolean;
  resolvedValue: number;
  isSmaller: boolean;
  textMode: 'light' | 'dark';
}

@Component({
  selector: 'rpg-nds-attribute-single',
  templateUrl: './attribute-single.component.html',
  styleUrls: ['./attribute-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class NdsAttributeSingleComponent {
  @Input()
  public set gameTheme(theme: GameTheme) {
    this._updateAttrImage(theme);
  }

  @Input()
  public set type(type: TypeOptions) {
    this._state.set({
      type,
    });
  }

  @Input()
  public set title(title: string) {
    this._state.set({
      title,
    });
  }

  @Input()
  public set label(label: string) {
    this._state.set({
      label,
    });
  }

  @Input()
  public set editMode(editMode: boolean) {
    this._state.set({
      editMode,
    });
  }

  @Input()
  public set resolvedValue(resolvedValue: number) {
    this._state.set({
      resolvedValue,
    });
  }

  @Input()
  public control!: FormControl<number>;

  public state$ = this._state.select();

  constructor(private _state: RxState<ComponentState>) {
    this._state.set({
      type: 'standard',
      isSmaller: false,
    });
  }

  public checkValue(): void {
    if (isNaN(this.control.value)) {
      this.control.setValue(0);
    }
    this._state.set({
      isSmaller: this.control.value.toString().length > SMALLER_SIZE_LIMIT,
    });
  }

  private _updateAttrImage(theme: GameTheme): void {
    const { type } = this._state.get();
    let imgSrc: string = '';
    let textMode: 'light' | 'dark' = 'light';
    switch (theme) {
      case GameTheme.StarWars_AoR:
      case GameTheme.StarWars_EotE:
      case GameTheme.StarWars_FaD:
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/starwars/statblock01-sw.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/starwars/statblock-def1-sw.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_Android:
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-android/statblock01-and.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-android/statblock-def1-and.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_TwilightImperium:
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-imperium/statblock01-ti.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-imperium/statblock-def1-ti.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_Core:
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-core/statblock01-gen.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-core/statblock-def1-gen.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_Keyforge:
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-keyforge/statblock01-kf.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-keyforge/statblock-def1-kf.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_Terrinoth:
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-terrinoth/statblock01-ter.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-terrinoth/statblock-def1-ter.svg?auto=format';
        }
        break;
      case GameTheme.Community_AwakenedAge:
        textMode = 'dark';
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-awakenedage/statblock01-aa.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-awakenedage/statblock-def1-aa.svg?auto=format';
        }
        break;
      case GameTheme.Community_Inquisition:
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-inquisition/statblock01-in.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-inquisition/statblock-def1-in.svg?auto=format';
        }
        break;
      case GameTheme.Community_SomethingStrange:
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-somethingstrange/statblock01-ss.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-somethingstrange/statblock-def1-ss.svg?auto=format';
        }
        break;
      case GameTheme.Community_Tron:
        if (type === 'alternate') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-tron/statblock01-tron.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-tron/statblock-def1-tron.svg?auto=format';
        }
        break;
      default:
        throw new Error('Invalid Game Theme supplied to NdsVehicleAttributeSingle');
    }
    this._state.set({
      attrImage: imgSrc,
      textMode,
    });
  }
}

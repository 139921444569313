export enum Images {
  LogoWhite = 'https://unbound-legends.imgix.net/logos/logo-white.png',
  LogoBlue = 'https://unbound-legends.imgix.net/logos/logo-blue-800w.png',
  CharacterLogo1 = 'https://res.cloudinary.com/dallastjames/image/upload/v1561154897/rpgsessions/01pc-swordguy',
  CharacterLogo2 = 'https://res.cloudinary.com/dallastjames/image/upload/v1561154897/rpgsessions/02pc-robodrill',
  CharacterLogo3 = 'https://res.cloudinary.com/dallastjames/image/upload/v1561154897/rpgsessions/03pc-mrax',
  CharacterLogo4 = 'https://res.cloudinary.com/dallastjames/image/upload/v1561154897/rpgsessions/04pc-pinkdroid',
  CharacterLogo5 = 'https://res.cloudinary.com/dallastjames/image/upload/v1561154897/rpgsessions/05pc-ranger',
  StarWarsLogoSquare = 'https://unbound-legends.imgix.net/logos/starwars-square.svg',
  GenesysLogoSquare = 'https://unbound-legends.imgix.net/logos/genesys-square.svg',
  GoogleSignInLogo = 'https://unbound-legends.imgix.net/logos/g-logo.png',
  FacebookSignInLogo = 'https://unbound-legends.imgix.net/logos/f_logo_RGB-Blue_72.png',
}

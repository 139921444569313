export enum InitiativeType {
  PC = '[Initiative Type] PC',
  NPC = '[Initiative Type] NPC',
}

export namespace InitiativeType {
  export const members: InitiativeType[] = [InitiativeType.PC, InitiativeType.NPC];

  export function getBotTranslationKey(type: InitiativeType): string {
    const base = `enums.initiativeType`;
    switch (type) {
      case InitiativeType.PC:
        return `${base}.pc`;
      case InitiativeType.NPC:
        return `${base}.npc`;
      default:
        return '';
    }
  }

  export function getTranslationKey(type: InitiativeType): string {
    const base = `words`;
    switch (type) {
      case InitiativeType.PC:
        return `${base}.PC`;
      case InitiativeType.NPC:
        return `${base}.NPC`;
      default:
        return '';
    }
  }

  export function toClass(type: InitiativeType): string {
    switch (type) {
      case InitiativeType.PC:
        return 'PC';
      case InitiativeType.NPC:
        return 'NPC';
      default:
        return '';
    }
  }

  export function toString(type: InitiativeType): string {
    switch (type) {
      case InitiativeType.PC:
        return 'PC';
      case InitiativeType.NPC:
        return 'NPC';
      default:
        return '';
    }
  }

  export function toEnum(value: string): InitiativeType | null {
    switch (value.trim().toLowerCase()) {
      case 'p':
      case 'pc':
      case 'player':
        return InitiativeType.PC;
      case 'n':
      case 'npc':
      case 'enemy':
        return InitiativeType.NPC;
      default:
        return null;
    }
  }
}

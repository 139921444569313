export enum GameLinkType {
  Discord = '[GameLinkType] discord',
  RPGSessions = '[GameLinkType] rpgsessions',
}

export namespace GameLinkType {
  export function toString(type: GameLinkType): string {
    switch (type) {
      case GameLinkType.Discord:
        return 'Discord';
      case GameLinkType.RPGSessions:
        return 'RPG Sessions';
      default:
        return '';
    }
  }

  export const members: GameLinkType[] = [GameLinkType.Discord, GameLinkType.RPGSessions];
}

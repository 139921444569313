import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { Position2DGridManager, Position2DNodeRef } from '@rpg/core/base';
import { NdsCharacterTalentData, NDS_CHARACTER_TALENT_PYRAMID_WIDTH } from '@rpg/core/character';
import { TalentPyramidLayoutState } from './talent-pyramid-layout.state';

@Component({
  selector: 'rpg-nds-character-talent-pyramid-layout',
  templateUrl: './talent-pyramid-layout.component.html',
  styleUrls: ['./talent-pyramid-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TalentPyramidLayoutState],
})
export class NdsCharacterTalentPyramidLayoutComponent {
  @HostBinding('class.force-mobile')
  public _forceMobile: boolean = false;

  @Input()
  public set positionManager(positionManager: Position2DGridManager) {
    this.vm.positionManager = positionManager;
  }
  @Input()
  public set editMode(editMode: boolean) {
    this.vm.editMode = editMode;
  }
  @Input()
  public set talents(talents: NdsCharacterTalentData[]) {
    this.vm.talents = talents;
  }
  @Input()
  public set forceMobile(forceMobile: boolean) {
    this.vm.forceMobile = forceMobile;
    this._forceMobile = forceMobile;
  }

  @Output()
  public talentContentClick = new EventEmitter<{
    nodeRef: Position2DNodeRef;
  }>();
  @Output()
  public talentPurchasedToggle = new EventEmitter<{ nodeRef: Position2DNodeRef; state: boolean }>();

  public tiers: number[] = new Array(NDS_CHARACTER_TALENT_PYRAMID_WIDTH)
    .fill(null)
    .map((_, idx) => idx + 1);

  constructor(public vm: TalentPyramidLayoutState) {}

  public trackBySlotId(index: number, ref: Position2DNodeRef): string {
    return ref.id;
  }

  public clearMobileSelection(): void {
    this.vm.clearMobileSelection();
  }
}

import { createId, RpgClassValidators } from '@rpg/core/base';

export class NdsCharacterMoralityEntryData {
  public id: string = '';
  public strengthName: string = '';
  public strengthDescription: string = '';
  public weaknessName: string = '';
  public weaknessDescription: string = '';

  constructor(params?: Partial<NdsCharacterMoralityEntryData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.strengthName = params.strengthName ?? this.strengthName;
      this.strengthDescription = params.strengthDescription ?? this.strengthDescription;
      this.weaknessName = params.weaknessName ?? this.weaknessName;
      this.weaknessDescription = params.weaknessDescription ?? this.weaknessDescription;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsCharacterMoralityEntryData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id, { fieldName: 'Morality Id' }));
    errors.push(
      ...RpgClassValidators.string(input.strengthName, {
        allowEmpty: true,
        fieldName: 'Morality Strength Name',
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.strengthDescription, {
        allowEmpty: true,
        fieldName: 'Morality Strength Description',
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.weaknessName, {
        allowEmpty: true,
        fieldName: 'Morality Weakness Name',
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.weaknessDescription, {
        allowEmpty: true,
        fieldName: 'Morality Weakness Description',
      })
    );
    return errors;
  }
}

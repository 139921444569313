import { createId, EdgeMatrix, Position2DGridManager, RpgClassValidators } from '@rpg/core/base';
import { NDS_CHARACTER_TREE_HEIGHT, NDS_CHARACTER_TREE_WIDTH } from './tree-size-constants';

export const NDS_CHARACTER_TALENT_TREE_WIDTH = NDS_CHARACTER_TREE_WIDTH;
export const NDS_CHARACTER_TALENT_TREE_HEIGHT = NDS_CHARACTER_TREE_HEIGHT;
export const NDS_CHARACTER_SIGNATURE_TALENT_TREE_HEIGHT = 3;

export class NdsCharacterTalentTreeData {
  public id: string = '';
  public name: string = '';
  public edgeMatrix: EdgeMatrix = new EdgeMatrix(
    NDS_CHARACTER_TALENT_TREE_WIDTH,
    NDS_CHARACTER_TALENT_TREE_HEIGHT
  );
  public positionManager: Position2DGridManager = new Position2DGridManager({
    width: NDS_CHARACTER_TALENT_TREE_WIDTH,
    height: NDS_CHARACTER_TALENT_TREE_HEIGHT,
  });
  public signatureTalentId: string = '';
  public signatureTalentEdgeMatrix: EdgeMatrix = new EdgeMatrix(
    NDS_CHARACTER_TALENT_TREE_WIDTH,
    NDS_CHARACTER_SIGNATURE_TALENT_TREE_HEIGHT
  );
  public signatureTalentPositionManager: Position2DGridManager = new Position2DGridManager({
    width: NDS_CHARACTER_TALENT_TREE_WIDTH,
    height: NDS_CHARACTER_SIGNATURE_TALENT_TREE_HEIGHT,
  });

  constructor(params?: Partial<NdsCharacterTalentTreeData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.edgeMatrix = !!params.edgeMatrix ? new EdgeMatrix(params.edgeMatrix) : this.edgeMatrix;
      this.positionManager = !!params.positionManager
        ? new Position2DGridManager(params.positionManager)
        : this.positionManager;
      this.signatureTalentId = params.signatureTalentId ?? this.signatureTalentId;
      this.signatureTalentEdgeMatrix = !!params.signatureTalentEdgeMatrix
        ? new EdgeMatrix(params.signatureTalentEdgeMatrix)
        : this.signatureTalentEdgeMatrix;
      this.signatureTalentPositionManager = !!params.signatureTalentPositionManager
        ? new Position2DGridManager(params.signatureTalentPositionManager)
        : this.signatureTalentPositionManager;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsCharacterTalentTreeData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id, { fieldName: 'Talent Tree ID' }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: 'Talent Tree Name' }));
    errors.push(
      ...RpgClassValidators.string(input.signatureTalentId, {
        fieldName: 'Signature Talent Id',
        allowEmpty: true,
      })
    );
    return errors;
  }
}

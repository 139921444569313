import { Constraints, parseEnum, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { NdsVehicleCharacteristic } from '../enums';

export const NDS_VEHICLE_CHARACTERISTIC_DEFAULT_VALUE = 0;
export const NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE = -9;
export const NDS_VEHICLE_CHARACTERISTIC_MAX_VALUE = 99;

export class NdsVehicleCharacteristicData {
  public type!: NdsVehicleCharacteristic;
  public value: number = NDS_VEHICLE_CHARACTERISTIC_DEFAULT_VALUE;

  constructor(params?: Partial<NdsVehicleCharacteristicData>) {
    if (!!params) {
      this.type = parseEnum(NdsVehicleCharacteristic, params.type, this.type);
      this.value = parseNumber(params.value, this.value);
    }
    // enforce constraints
    this.value = Constraints.withinRange(
      this.value,
      NDS_VEHICLE_CHARACTERISTIC_MIN_VALUE,
      NDS_VEHICLE_CHARACTERISTIC_MAX_VALUE
    );
  }

  public static validate(input: NdsVehicleCharacteristicData): string[] {
    const errors: string[] = [];
    errors.push(
      ...RpgClassValidators.enum(input.type, NdsVehicleCharacteristic, {
        enumName: 'Vehicle Characteristic',
      })
    );
    errors.push(...RpgClassValidators.number(input.value));
    return errors;
  }
}

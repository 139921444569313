// Moved into our repo to update immer and fix ng compat issue

import { StateContext } from '@ngxs/store';

import { ImmutableStateContext } from './state-context';

// eslint-disable-next-line @typescript-eslint/ban-types
export function ImmutableContext(): Function {
  return function (
    _target: Object,
    _key: string,
    descriptor: PropertyDescriptor
  ): PropertyDescriptor {
    const method = descriptor.value;

    descriptor.value = function (ctx: StateContext<any>, action: any, ...args: any[]) {
      return method.apply(this, [new ImmutableStateContext(ctx), action, ...args]);
    };

    return descriptor;
  };
}

import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleAttributeData } from '@rpg/core/vehicle';

export function ndsVehicleAttributeToFormGroup(
  attr: NdsVehicleAttributeData
): FormGroup<NdsVehicleAttributeData> {
  return new FormGroup<NdsVehicleAttributeData>({
    type: new FormControl(attr.type, [Validators.required]),
    value: new FormControl(attr.value),
  });
}

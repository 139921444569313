export class GameFlags {
  public managementEnabled: boolean = false;
  public locked: boolean = false;

  constructor(params?: Partial<GameFlags>) {
    if (!!params) {
      this.managementEnabled = params.managementEnabled ?? this.managementEnabled;
      this.locked = params.locked ?? this.locked;
    }
  }
}

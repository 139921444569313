// Cookie code taken from ngx-cookie-service

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieKey, Logger } from '@rpg/core/base';
import { addDays } from 'date-fns';
import { CookieAccessor } from '../models';

const COOKIE_DAYS_TILL_EXPIRATION = 10;

@Injectable()
export class CookieService implements CookieAccessor {
  constructor(@Inject(DOCUMENT) private document: any) {}

  private get expiryDate(): Date {
    return addDays(new Date(), COOKIE_DAYS_TILL_EXPIRATION);
  }

  public get(type: CookieKey | string): string {
    Logger.log(`Browser Cookie, Get: ${CookieKey.getNameFromKey(type)}`);
    return this.getCookie(CookieKey.getNameFromKey(type));
  }

  public set(type: CookieKey | string, value: string): void {
    Logger.log(`Browser Cookie, Set: {${CookieKey.getNameFromKey(type)}:${value}}`);
    this.setCookie(CookieKey.getNameFromKey(type), value, {
      expires: this.expiryDate,
      sameSite: 'Strict',
      path: '/',
    });
  }

  public delete(type: CookieKey | string): void {
    this.deleteCookie(CookieKey.getNameFromKey(type));
    if (type === CookieKey.AuthToken) {
      console.log('CLEAR ALL THE COOKIES!');
      // We just need to clear all the tokens ever because of Chrome weirdness
      const cookies = document.cookie.split('; ');
      for (let c = 0; c < cookies.length; c++) {
        const d = window.location.hostname.split('.');
        while (d.length > 0) {
          const cookieBase =
            encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
            '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
            d.join('.') +
            ' ;path=';
          const p = location.pathname.split('/');
          document.cookie = cookieBase + '/';
          while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
          }
          d.shift();
        }
      }
    }
  }

  /**
   * Return `true` if {@link Document} is accessible, otherwise return `false`
   *
   * @param name Cookie name
   * @returns boolean - whether cookie with specified name exists
   */
  private check(name: string): boolean {
    name = encodeURIComponent(name);
    const regExp: RegExp = this.getCookieRegExp(name);
    return regExp.test(this.document.cookie);
  }

  /**
   * Get cookies by name
   *
   * @param name Cookie name
   * @returns property value
   */
  private getCookie(name: string): string {
    if (this.check(name)) {
      name = encodeURIComponent(name);

      const regExp: RegExp = this.getCookieRegExp(name);
      const result: RegExpExecArray | null = regExp.exec(this.document.cookie);

      if (result === null) {
        return '';
      }
      console.log(`COOKIE RESULT: ${name}`, result);

      return result[1] ? this.safeDecodeURIComponent(result[1]) : '';
    } else {
      return '';
    }
  }

  /**
   * Get all cookies in JSON format
   *
   * @returns all the cookies in json
   */
  private getAllCookies(): { [key: string]: string } {
    const cookies: { [key: string]: string } = {};
    const document: any = this.document;

    if (document.cookie && document.cookie !== '') {
      document.cookie.split(';').forEach((currentCookie: string) => {
        const [cookieName, cookieValue] = currentCookie.split('=');
        cookies[this.safeDecodeURIComponent(cookieName.replace(/^ /, ''))] =
          this.safeDecodeURIComponent(cookieValue);
      });
    }

    return cookies;
  }

  /**
   * Set cookie based on provided information
   *
   * @param name     Cookie name
   * @param value    Cookie value
   * @param expires  Number of days until the cookies expires or an actual `Date`
   * @param path     Cookie path
   * @param domain   Cookie domain
   * @param secure   Secure flag
   * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
   */
  private setCookie(
    name: string,
    value: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void;

  /**
   * Set cookie based on provided information
   *
   * Cookie's parameters:
   * <pre>
   * expires  Number of days until the cookies expires or an actual `Date`
   * path     Cookie path
   * domain   Cookie domain
   * secure   Secure flag
   * sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
   * </pre>
   * @param name     Cookie name
   * @param value    Cookie value
   * @param options  Body with cookie's params
   */
  private setCookie(
    name: string,
    value: string,
    options?: {
      expires?: number | Date;
      path?: string;
      domain?: string;
      secure?: boolean;
      sameSite?: 'Lax' | 'None' | 'Strict';
    }
  ): void;

  private setCookie(
    name: string,
    value: string,
    expiresOrOptions?: number | Date | any,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void {
    if (
      typeof expiresOrOptions === 'number' ||
      expiresOrOptions instanceof Date ||
      path ||
      domain ||
      secure ||
      sameSite
    ) {
      const optionsBody = {
        expires: expiresOrOptions,
        path: '/',
        domain,
        secure,
        sameSite: sameSite ? sameSite : 'Lax',
      };
      console.log('SETTING COOKIE', optionsBody);
      this.setCookie(name, value, optionsBody);
      return;
    }

    let cookieString: string = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';

    const options = expiresOrOptions ? expiresOrOptions : {};

    if (options.expires) {
      if (typeof options.expires === 'number') {
        const dateExpires: Date = new Date(
          // eslint-disable-next-line no-magic-numbers
          new Date().getTime() + options.expires * 1000 * 60 * 60 * 24
        );

        cookieString += 'expires=' + dateExpires.toUTCString() + ';';
      } else {
        cookieString += 'expires=' + options.expires.toUTCString() + ';';
      }
    }

    // if (options.path) {
    //   cookieString += 'path=' + options.path + ';';
    // }
    // options.path = '';
    cookieString += `path=/;`;

    if (options.domain) {
      cookieString += 'domain=' + options.domain + ';';
    }

    if (options.secure === false && options.sameSite === 'None') {
      options.secure = true;
      Logger.warn(
        `[ngx-cookie-service] Cookie ${name} was forced with secure flag because sameSite=None.` +
          `More details : https://github.com/stevermeister/ngx-cookie-service/issues/86#issuecomment-597720130`
      );
    }
    if (options.secure) {
      cookieString += 'secure;';
    }

    if (!options.sameSite) {
      options.sameSite = 'Lax';
    }

    cookieString += 'sameSite=' + options.sameSite + ';';

    console.log('COOKIE STRING', cookieString);
    this.document.cookie = cookieString;
  }

  private deleteCookie(
    name: string,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite: 'Lax' | 'None' | 'Strict' = 'Lax'
  ): void {
    const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
    this.setCookie(name, '', { expires: expiresDate, path, domain, secure, sameSite });
  }

  private getCookieRegExp(name: string): RegExp {
    // eslint-disable-next-line no-useless-escape
    const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');

    return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
  }

  private safeDecodeURIComponent(encodedURIComponent: string): string {
    try {
      return decodeURIComponent(encodedURIComponent);
    } catch {
      // probably it is not uri encoded. return as is
      return encodedURIComponent;
    }
  }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';

interface Example {
  title: string;
  description?: string;
  examples: Array<{
    label?: string;
    input: string;
    rows: number;
  }>;
}

@Component({
  selector: 'rpg-formatting-help',
  templateUrl: './formatting-help.component.html',
  styleUrls: ['./formatting-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormattingHelpComponent {
  public sections: Example[] = [
    {
      title: 'Headers',
      examples: [
        {
          input: '# Header 1\n## Header 2\n###### Header 6',
          rows: 3,
        },
      ],
    },
    {
      title: 'Emphasis',
      examples: [
        {
          input:
            '*This text is italic*  \n_This text is also italic_\n\n**This text is bold**  \n__This text is also bold__\n\n_You **can** combine them_',
          rows: 8,
        },
      ],
    },
    {
      title: 'New Lines',
      description:
        'Leave a blank line between lines or end a line with two (2) spaces for a new line',
      examples: [
        {
          input:
            'This is a line\n\nAnd another new line\n\nThis ends with two spaces  \nSo this line starts on a new line',
          rows: 8,
        },
      ],
    },
    {
      title: 'List',
      description: 'Use three (3) spaces to indent a list',
      examples: [
        {
          label: 'Unordered',
          input: '* Item 1\n* Item2\n   * Item2 a\n   * Item 2 b',
          rows: 4,
        },
        {
          label: 'Ordered',
          input: '1. Item 1\n1. Item2\n   1. Item2 a\n   1. Item 2 b',
          rows: 4,
        },
      ],
    },
    {
      title: 'Quotes',
      examples: [
        {
          label: 'Blockquote',
          input: '> This is a quote that conveys the meaning of life, the answer to which is 42.',
          rows: 4,
        },
        {
          label: 'Inline',
          input: 'When you want to `make something in the middle` standout, use `single backticks`',
          rows: 4,
        },
        {
          label: 'Code Blocks',
          input: '```code\nUse triple backticks followed by "code" to create a code block\n```',
          rows: 5,
        },
      ],
    },
    {
      title: 'Tables',
      examples: [
        {
          input:
            'Column 1 | Column 2\n-----------|------------\nContent cell 1 | Content cell 2\nCell 3 | Cell 4\nContent 5 | Content 6',
          rows: 6,
        },
      ],
    },
    {
      title: 'Images',
      description: 'Format: ![Alt Text](url =WidthxHeight). WidthxHeight is optional',
      examples: [
        {
          input:
            '![RPG Sessions Logo](https://unbound-legends.imgix.net/logos/logo-white.png)\n\n![RPG Sessions Logo](https://unbound-legends.imgix.net/logos/logo-white.png =300x300)\n\n![RPG Sessions Logo](https://unbound-legends.imgix.net/logos/animated-icon-assets.gif)',
          rows: 14,
        },
      ],
    },
    {
      title: 'Disable Formatting',
      examples: [
        {
          input: '```\nWrap your text in triple backticks to **disable** all formatting\n```',
          rows: 5,
        },
      ],
    },
  ];

  constructor() {}
}

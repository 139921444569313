import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterModifierAttributeData } from '@rpg/core/character';
import { ndsCharacterModifierBaseToFormGroup } from './nds-character-modifier-base-to-form-group';

export function ndsCharacterModifierAttributeToFormGroup(
  mod: NdsCharacterModifierAttributeData
): FormGroup<NdsCharacterModifierAttributeData> {
  return new FormGroup<NdsCharacterModifierAttributeData>({
    ...ndsCharacterModifierBaseToFormGroup(mod).controls,
    attribute: new FormControl(mod.attribute, [Validators.required]),
    modifierAmount: new FormControl(mod.modifierAmount),
  });
}

import { createId, RpgClassValidators } from '@rpg/core/base';

export class NdsCharacterTalentListGroupData {
  public id: string = '';
  public name: string = '';
  public talentIds: string[] = [];

  constructor(params?: Partial<NdsCharacterTalentListGroupData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.talentIds = Array.isArray(params.talentIds) ? params.talentIds : this.talentIds;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsCharacterTalentListGroupData): string[] {
    const errors: string[] = [];
    errors.push(...RpgClassValidators.string(input.id, { fieldName: 'Talent List Group ID' }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: 'Talent List Group Name' }));
    return errors;
  }
}

import {
  GameTheme,
  parseEnum,
  RpgClassValidators,
  DiceTheme,
  BaseContentConfiguration,
  parseBoolean,
} from '@rpg/core/base';
import { NdsCharacterTalentDisplayType, NdsCharacterType } from '../enums';

type ConfigPreset = {
  [key in GameTheme]: NdsCharacterConfigurationData;
};

export class NdsCharacterConfigurationData extends BaseContentConfiguration {
  public ndsCharacterType: NdsCharacterType = NdsCharacterType.Player;
  public talentsDisplayType: NdsCharacterTalentDisplayType =
    NdsCharacterTalentDisplayType.ListGroup;
  public talentsSignatureAbilitiesEnabled: boolean = false;
  public forcePowersDisplayType: NdsCharacterTalentDisplayType =
    NdsCharacterTalentDisplayType.ListGroup;
  public dutyEnabled: boolean = false;
  public forceEnabled: boolean = false;
  public forceDiceEnabled: boolean = false;
  public moralityEnabled: boolean = false;
  public obligationEnabled: boolean = false;
  public superCharacteristicsEnabled: boolean = false;
  public heroicAbilitiesEnabled: boolean = false;
  public aemberEnabled: boolean = false;
  public favorsEnabled: boolean = false;
  public agendasEnabled: boolean = false;
  public automations: boolean = true;

  constructor(initParams?: Partial<NdsCharacterConfigurationData> | GameTheme) {
    const params =
      typeof initParams === 'object'
        ? initParams
        : NdsCharacterConfigurationData.presets[initParams ?? GameTheme.Genesys_Core];
    super(params);
    if (!!params) {
      this.ndsCharacterType = parseEnum(
        NdsCharacterType,
        params.ndsCharacterType,
        this.ndsCharacterType
      );
      this.talentsDisplayType = parseEnum(
        NdsCharacterTalentDisplayType,
        params.talentsDisplayType,
        this.talentsDisplayType
      );
      this.forcePowersDisplayType = parseEnum(
        NdsCharacterTalentDisplayType,
        params.forcePowersDisplayType,
        this.forcePowersDisplayType
      );
      this.talentsSignatureAbilitiesEnabled = parseBoolean(
        params.talentsSignatureAbilitiesEnabled,
        this.talentsSignatureAbilitiesEnabled
      );
      this.dutyEnabled = parseBoolean(params.dutyEnabled, this.dutyEnabled);
      this.forceEnabled = parseBoolean(params.forceEnabled, this.forceEnabled);
      this.forceDiceEnabled = parseBoolean(params.forceDiceEnabled, this.forceDiceEnabled);
      this.moralityEnabled = parseBoolean(params.moralityEnabled, this.moralityEnabled);
      this.obligationEnabled = parseBoolean(params.obligationEnabled, this.obligationEnabled);
      this.superCharacteristicsEnabled = parseBoolean(
        params.superCharacteristicsEnabled,
        this.superCharacteristicsEnabled
      );
      this.heroicAbilitiesEnabled = parseBoolean(
        params.heroicAbilitiesEnabled,
        this.heroicAbilitiesEnabled
      );
      this.aemberEnabled = parseBoolean(params.aemberEnabled, this.aemberEnabled);
      this.favorsEnabled = parseBoolean(params.favorsEnabled, this.favorsEnabled);
      this.agendasEnabled = parseBoolean(params.agendasEnabled, this.agendasEnabled);
      this.automations = parseBoolean(params.automations, this.automations);
    }
  }

  public static presets: ConfigPreset = {
    [GameTheme.StarWars_EotE]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.StarWars,
      gameTheme: GameTheme.StarWars_EotE,
      talentsDisplayType: NdsCharacterTalentDisplayType.Tree,
      talentsSignatureAbilitiesEnabled: true,
      forcePowersDisplayType: NdsCharacterTalentDisplayType.Tree,
      obligationEnabled: true,
    }),
    [GameTheme.StarWars_AoR]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.StarWars,
      gameTheme: GameTheme.StarWars_AoR,
      talentsDisplayType: NdsCharacterTalentDisplayType.Tree,
      talentsSignatureAbilitiesEnabled: true,
      forcePowersDisplayType: NdsCharacterTalentDisplayType.Tree,
      dutyEnabled: true,
    }),
    [GameTheme.StarWars_FaD]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.StarWars,
      gameTheme: GameTheme.StarWars_FaD,
      talentsDisplayType: NdsCharacterTalentDisplayType.Tree,
      talentsSignatureAbilitiesEnabled: true,
      forcePowersDisplayType: NdsCharacterTalentDisplayType.Tree,
      forceEnabled: true,
      forceDiceEnabled: true,
      moralityEnabled: true,
    }),
    [GameTheme.Genesys_Core]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Genesys_Core,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
    }),
    [GameTheme.Genesys_Android]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Genesys_Android,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
      favorsEnabled: true,
    }),
    [GameTheme.Genesys_Keyforge]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Genesys_Keyforge,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
      aemberEnabled: true,
    }),
    [GameTheme.Genesys_Terrinoth]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Genesys_Terrinoth,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
      heroicAbilitiesEnabled: true,
    }),
    [GameTheme.Genesys_TwilightImperium]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Genesys_TwilightImperium,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
      agendasEnabled: true,
    }),
    [GameTheme.Community_AwakenedAge]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Community_AwakenedAge,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
      superCharacteristicsEnabled: true,
    }),
    [GameTheme.Community_Inquisition]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Community_Inquisition,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
    }),
    [GameTheme.Community_SomethingStrange]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Community_SomethingStrange,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
    }),
    [GameTheme.Community_Tron]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Community_Tron,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
    }),
    [GameTheme.Generic]: new NdsCharacterConfigurationData({
      diceTheme: DiceTheme.Genesys,
      gameTheme: GameTheme.Genesys_Core,
      talentsDisplayType: NdsCharacterTalentDisplayType.Pyramid,
    }),
  };

  public static isEqual(
    obj1: NdsCharacterConfigurationData,
    obj2: NdsCharacterConfigurationData
  ): boolean {
    if (
      obj1.talentsDisplayType !== obj2.talentsDisplayType ||
      obj1.talentsSignatureAbilitiesEnabled !== obj2.talentsSignatureAbilitiesEnabled ||
      obj1.forcePowersDisplayType !== obj2.forcePowersDisplayType ||
      obj1.dutyEnabled !== obj2.dutyEnabled ||
      obj1.forceEnabled !== obj2.forceEnabled ||
      obj1.forceDiceEnabled !== obj2.forceDiceEnabled ||
      obj1.moralityEnabled !== obj2.moralityEnabled ||
      obj1.obligationEnabled !== obj2.obligationEnabled ||
      obj1.superCharacteristicsEnabled !== obj2.superCharacteristicsEnabled ||
      obj1.heroicAbilitiesEnabled !== obj2.heroicAbilitiesEnabled ||
      obj1.aemberEnabled !== obj2.aemberEnabled ||
      obj1.favorsEnabled !== obj2.favorsEnabled ||
      obj1.agendasEnabled !== obj2.agendasEnabled
    ) {
      return false;
    }
    return true;
  }

  public static validate(input: NdsCharacterConfigurationData): string[] {
    const errors: string[] = BaseContentConfiguration.validate(input);
    errors.push(
      ...RpgClassValidators.enum(input.ndsCharacterType, NdsCharacterType, {
        enumName: 'NDS Character Type',
        fieldName: 'Config Character Type',
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.talentsDisplayType, NdsCharacterTalentDisplayType, {
        enumName: 'Talent Display Type',
        fieldName: 'Config Talent Display Type',
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.forcePowersDisplayType, NdsCharacterTalentDisplayType, {
        enumName: 'Force Power Display Type',
        fieldName: 'Config Force Power Display Type',
      })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.talentsSignatureAbilitiesEnabled, {
        fieldName: 'Config Signature Talents Toggle',
      })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.dutyEnabled, { fieldName: 'Config Duty Toggle' })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.forceEnabled, { fieldName: 'Config Force Toggle' })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.moralityEnabled, { fieldName: 'Config Morality Toggle' })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.obligationEnabled, { fieldName: 'Config Obligation' })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.superCharacteristicsEnabled, {
        fieldName: 'Config Super Characteristics Toggle',
      })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.heroicAbilitiesEnabled, {
        fieldName: 'Config Heroic Abilities Toggle',
      })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.aemberEnabled, { fieldName: 'Config Aember Toggle' })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.favorsEnabled, { fieldName: 'Config Favors Toggle' })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.agendasEnabled, { fieldName: 'Config Agendas Toggle' })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.automations, { fieldName: 'Config Automations Toggle' })
    );
    return errors;
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { getErrorMessage } from '../functions';
import { TranslocoService } from '@ngneat/transloco';

interface ToastOptions {
  readTranslation: string;
  duration: number;
  action: string;
}

type SimpleError = {
  message: string;
};

const toastOptionsDefaults: ToastOptions = {
  readTranslation: '',
  duration: 3000,
  action: '',
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private _snackBar: MatSnackBar, private _transloco: TranslocoService) {}

  public success(text: string, options?: Partial<ToastOptions>): void {
    this.create('success', text, options);
  }

  public info(text: string, options?: Partial<ToastOptions>): void {
    this.create('info', text, options);
  }

  public warn(text: string, options?: Partial<ToastOptions>): void {
    this.create('warn', text, options);
  }

  public error(
    error: HttpErrorResponse | Error | SimpleError | string,
    options?: Partial<ToastOptions>
  ): void {
    const message = getErrorMessage(error);
    const longMessageLength = 28; // Totally arbitrary
    const longDuration = 5000;
    const normalDuration = 3000;
    const duration = message.length > longMessageLength ? longDuration : normalDuration;
    this.create('error', message, {
      ...options,
      duration: options?.duration ?? duration,
    });
  }

  public updateNotification(): void {
    const ref = this._snackBar.open('An Update Is Available!', 'Reload', {
      horizontalPosition: 'left',
      panelClass: 'update-notification',
      verticalPosition: 'bottom',
    });
    ref.onAction().subscribe(() => {
      window.location.reload();
    });
  }

  public create(
    type: 'success' | 'info' | 'warn' | 'error',
    text: string,
    options?: Partial<ToastOptions>
  ): void {
    options = {
      ...toastOptionsDefaults,
      ...options,
    };
    let message = text;
    if (options.readTranslation) {
      message = this._transloco.translate<string>(`${options.readTranslation}.${text}`);
      if (message.includes(options.readTranslation)) {
        message = this._transloco.translate(text);
      }
    } else {
      message = this._transloco.translate(text);
    }

    let action = options.action;
    if (options.action) {
      if (options.readTranslation) {
        action = this._transloco.translate<string>(`${options.readTranslation}.${options.action}`);
        if (action.includes(options.readTranslation)) {
          action = this._transloco.translate(action);
        }
      } else {
        action = this._transloco.translate(options.action);
      }
    }
    this._snackBar.open(message, action, {
      duration: options?.duration,
    });
  }
}

/* eslint-disable @typescript-eslint/ban-types */
import {
  EmptyPositionVolatileData,
  Position2D,
  Position2DGridManager,
  Position2DNodeRef,
} from '@rpg/core/base';
import { NdsCharacterTalentData } from './nds-character-talent-data';
import { NdsCharacterTalentListGroupData } from './nds-character-talent-list-group-data';
import { NdsCharacterTalentTreeData } from './nds-character-talent-tree-data';

export const NDS_CHARACTER_TALENT_PYRAMID_WIDTH = 5;
export const NDS_CHARACTER_TALENT_PYRAMID_DEFAULT_HEIGHT = 5;

/**
 * This function exists here because it both relies on data defined here as well as is consumed here
 * otherwise we'd get a circular import. So, that's why this is here
 */
export function getBlankNdsCharacterTalentPyramidNodes<
  VD extends EmptyPositionVolatileData = {}
>(): Position2DNodeRef<VD>[] {
  const nodes: Position2DNodeRef<VD>[] = [];
  for (let y = 0; y < NDS_CHARACTER_TALENT_PYRAMID_DEFAULT_HEIGHT; y++) {
    if (y + 1 < NDS_CHARACTER_TALENT_PYRAMID_WIDTH) {
      for (let x = y + 1; x < NDS_CHARACTER_TALENT_PYRAMID_WIDTH; x++) {
        nodes.push(
          new Position2DNodeRef({
            hidden: true,
            position: new Position2D({
              x,
              y,
            }),
          })
        );
      }
    }
  }
  return nodes;
}

export class NdsCharacterTalentConfigData {
  public talents: NdsCharacterTalentData[] = [];
  public trees: NdsCharacterTalentTreeData[] = [];
  public listGroups: NdsCharacterTalentListGroupData[] = [];
  public pyramidPositionManager: Position2DGridManager = new Position2DGridManager({
    width: NDS_CHARACTER_TALENT_PYRAMID_WIDTH,
    height: NDS_CHARACTER_TALENT_PYRAMID_DEFAULT_HEIGHT,
  });

  constructor(params?: Partial<NdsCharacterTalentConfigData>) {
    if (!!params) {
      this.talents = Array.isArray(params.talents)
        ? params.talents.map(t => new NdsCharacterTalentData(t))
        : this.talents;
      this.trees = Array.isArray(params.trees)
        ? params.trees.map(m => new NdsCharacterTalentTreeData(m))
        : this.trees;
      this.pyramidPositionManager = !!params.pyramidPositionManager
        ? new Position2DGridManager(params.pyramidPositionManager)
        : this.pyramidPositionManager;
      this.listGroups = Array.isArray(params.listGroups)
        ? params.listGroups.map(g => new NdsCharacterTalentListGroupData(g))
        : this.listGroups;
    }
    if (this.pyramidPositionManager.nodes.length === 0) {
      getBlankNdsCharacterTalentPyramidNodes().forEach(node =>
        this.pyramidPositionManager.addNode(node)
      );
    }
  }

  public static validate(input: NdsCharacterTalentConfigData): string[] {
    const errors: string[] = [];
    errors.push(
      ...input.trees.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterTalentTreeData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.talents.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterTalentData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.listGroups.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterTalentListGroupData.validate(next)],
        []
      )
    );
    return errors;
  }
}

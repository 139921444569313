import { FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleModifierNarrativeData } from '@rpg/core/vehicle';
import { ndsVehicleModifierBaseToFormGroup } from './nds-vehicle-modifier-base-to-form-group';

export function ndsVehicleModifierNarrativeToFormGroup(
  mod: NdsVehicleModifierNarrativeData
): FormGroup<NdsVehicleModifierNarrativeData> {
  return new FormGroup<NdsVehicleModifierNarrativeData>({
    ...ndsVehicleModifierBaseToFormGroup(mod).controls,
  });
}

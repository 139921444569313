import { parseDate, parseEnum } from '@rpg/core/base';
import { LoginType } from '../enums';

export class OAuthKey {
  public type: LoginType | null = null;
  public accessToken: string = '';
  public accessTokenExpiration: Date = new Date();
  public refreshToken: string = '';

  constructor(params?: Partial<OAuthKey>) {
    if (!!params) {
      this.type = parseEnum(LoginType.socialMembers, params.type);
      this.accessToken = params.accessToken ?? this.accessToken;
      this.accessTokenExpiration = parseDate(
        params.accessTokenExpiration,
        this.accessTokenExpiration
      );
      this.refreshToken = params.refreshToken ?? this.refreshToken;
    }
  }
}

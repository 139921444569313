import { parseNumber } from '../../base';

export class CritTableRow {
  public id: number = 0;
  public min: number = 0;
  public max: number | null = null;
  public description: string = '';
  public name: string = '';
  public difficulty: number = 0;

  constructor(params?: Partial<CritTableRow>) {
    if (!!params) {
      this.id = parseNumber(params.id, this.id);
      this.min = parseNumber(params.min, this.min);
      this.max = parseNumber(params.max);
      this.difficulty = parseNumber(params.difficulty, this.difficulty);
      this.description = params.description ?? this.description;
      this.name = params.name ?? this.name;
    }
  }
}

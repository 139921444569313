import { ThemeOption } from '../enums';

const lightTheme: Theme = {
  buttonPrimary: '#4164af',
  buttonPrimaryLight: '#4164af',
  buttonAccent: '#c8cbce',
  buttonAccentLight: '#414850',
  buttonWarn: '#dc2d32',
  colorTertiary: '#87a4b0',
  success: '#428342',
  textColor: '#323b43',
  textColorRgb: '51, 51, 51',
  textColorAlt: '#d8dadd',
  headerBar: '#b8bcc0',
  pageBackgroundColor: '#f9f9f9',
  sectionHeaderBarColor: '#f9f9f9',
  sectionBackgroundColor: '#d8dadd',
  sectionBackgroundDarkColor: '#e8eaeb',
  sectionBackgroundLightColor: '#9ea3a9',
  linesColor: '#f9f9f9',
  linesColorTransparent: '#f9f9f94d',
  markdownTableRowBackgroundColor: '#ffffff',
  markdownTableRowAlternateBackgroundColor: '#f6f8fa',
  colorFormFieldOutline: 'rgba(0, 0, 0, 0.12)',
  colorFormFieldOutlineThick: 'rgba(0, 0, 0, 1)',
  colorFormFieldOutlineDisabled: 'rgba(0, 0, 0, 0.06)',
  colorFormFieldTextDisabled: 'rgba(0, 0, 0, 0.38)',
  colorFormFieldLabel: 'rgba(51, 51, 51, 1)',
  // OLD
  sectionBackgroundLightColorAlternate: '#FFFF00',
  backgroundColor: 'rgb(255, 255, 0)',
  backgroundColorRGB: '255, 255, 0',
  backgroundColorMedium: '#FFFF00',
  cardHeaderBackgroundColor: 'rgba(255, 255, 0, 1)',
  cardExpansionBackgroundColor: 'rgba(255, 255, 0, 1)',
  cardBackgroundColor: 'rgb(255, 255, 0)',
  cardBackgroundColorRGB: '255, 255, 0',
  borderColor: 'rgba(255, 255, 0, 1)',
  characterCardHeaderBackgroundColor: '#FFFF00',
  characterCardBackgroundColor: '#FFFF00',
  character2SWCardBackgroundColor: 'rgb(255, 255, 0)',
  character2SWCardHeaderBackgroundColor: 'rgb(255, 255, 0)',
  vehicleCardHeaderBackgroundColor: '#FFFF00',
  vehicleCardBackgroundColor: '#FFFF00',
};

const darkTheme: Theme = {
  buttonPrimary: '#4164af',
  buttonPrimaryLight: '#6EA5E6',
  buttonAccent: '#4B525A',
  buttonAccentLight: '#C8CBCE',
  buttonWarn: '#F27168',
  colorTertiary: '#446874',
  success: '#9BCB9B',
  textColor: '#d8dadd',
  textColorAlt: '#323b43',
  textColorRgb: '216, 218, 221',
  headerBar: '#4b525a',
  pageBackgroundColor: '#121921',
  sectionHeaderBarColor: '#121921',
  sectionBackgroundColor: '#343b43',
  sectionBackgroundDarkColor: '#262d35',
  sectionBackgroundLightColor: '#545b63',
  linesColor: '#121921',
  linesColorTransparent: '#1219214d',
  markdownTableRowBackgroundColor: 'rgb(79, 82, 88)',
  markdownTableRowAlternateBackgroundColor: 'rgb(54, 57, 63)',
  colorFormFieldOutline: 'rgba(255, 255, 255, 0.12)',
  colorFormFieldOutlineThick: 'rgba(255, 255, 255, 0.6)',
  colorFormFieldOutlineDisabled: 'rgba(255, 255, 255, 0.06)',
  colorFormFieldLabel: 'rgba(255, 255, 255, 0.6)',
  colorFormFieldTextDisabled: 'rgba(255, 255, 255, 0.38)',
  // OLD
  borderColor: 'rgba(255, 255, 0, 1)',
  backgroundColor: 'rgb(255, 255, 0)',
  backgroundColorRGB: '255, 255, 0',
  backgroundColorMedium: '#FFFF00',
  cardHeaderBackgroundColor: 'rgba(255, 255, 0, 1)',
  cardExpansionBackgroundColor: 'rgba(255, 255, 0, 1)',
  cardBackgroundColor: 'rgb(255, 255, 0)',
  cardBackgroundColorRGB: '255, 255, 0',
  characterCardHeaderBackgroundColor: '#FFFF00',
  characterCardBackgroundColor: '#FFFF00',
  character2SWCardBackgroundColor: 'rgb(255, 255, 0)',
  character2SWCardHeaderBackgroundColor: 'rgb(255, 255, 0)',
  vehicleCardHeaderBackgroundColor: '#FFFF00',
  vehicleCardBackgroundColor: '#FFFF00',
  sectionBackgroundLightColorAlternate: '#FFFF00',
};

const themeMap: ThemeKeys = {
  buttonPrimary: '--theme-color-primary',
  buttonPrimaryLight: '--theme-color-primary-light',
  buttonAccent: '--theme-color-accent',
  buttonAccentLight: '--theme-color-accent-light',
  buttonWarn: '--theme-color-warn',
  colorTertiary: '--theme-color-tertiary',
  success: '--theme-color-success',
  textColor: '--theme-color-text',
  textColorAlt: '--theme-color-text-alt',
  textColorRgb: '--theme-color-text-rgb',
  headerBar: '--theme-header-bar',
  pageBackgroundColor: '--theme-page-background',
  sectionHeaderBarColor: '--theme-section-header-bar',
  sectionBackgroundColor: '--theme-section-background',
  sectionBackgroundDarkColor: '--theme-section-background-dark',
  sectionBackgroundLightColor: '--theme-section-background-light',
  linesColor: '--theme-lines',
  linesColorTransparent: '--theme-lines-transparent',
  markdownTableRowBackgroundColor: '--theme-markdown-table-row-background-color',
  markdownTableRowAlternateBackgroundColor: '--theme-markdown-table-row-alternate-background-color',
  colorFormFieldOutline: '--theme-color-form-field-outline',
  colorFormFieldOutlineThick: '--theme-color-form-field-outline-thick',
  colorFormFieldOutlineDisabled: '--theme-color-form-field-outline-disabled',
  colorFormFieldTextDisabled: '--theme-color-form-field-text-disabled',
  colorFormFieldLabel: '--theme-color-form-field-label',
  // OLD
  borderColor: '--theme-border-color',
  backgroundColor: '--theme-background-color',
  backgroundColorRGB: '--theme-background-color-rgb',
  backgroundColorMedium: '--theme-background-color-medium',
  cardHeaderBackgroundColor: '--theme-card-header-background-color',
  cardExpansionBackgroundColor: '--theme-card-expansion-background-color',
  cardBackgroundColor: '--theme-card-background-color',
  cardBackgroundColorRGB: '--theme-card-background-color-rgb',
  characterCardHeaderBackgroundColor: '--theme-character-card-header-background-color',
  characterCardBackgroundColor: '--theme-character-card-background-color',
  character2SWCardBackgroundColor: '--theme-character2-sw-card-background-color',
  character2SWCardHeaderBackgroundColor: '--theme-character2-sw-card-header-background-color',
  vehicleCardHeaderBackgroundColor: '--theme-vehicle-card-header-background-color',
  vehicleCardBackgroundColor: '--theme-vehicle-card-background-color',
  sectionBackgroundLightColorAlternate: '--theme-section-background-light-alt',
};

export class Theme {
  public static themeClasses: string[] = ['rpg-theme-light', 'rpg-theme-dark'];

  public buttonPrimary!: string;
  public buttonPrimaryLight!: string;
  public buttonAccent!: string;
  public buttonAccentLight!: string;
  public buttonWarn!: string;
  public colorTertiary!: string;
  public success!: string;
  public textColor!: string;
  public textColorAlt!: string;
  public textColorRgb!: string;
  public backgroundColor!: string;
  public backgroundColorRGB!: string;
  public backgroundColorMedium!: string;
  public cardHeaderBackgroundColor!: string;
  public cardExpansionBackgroundColor!: string;
  public cardBackgroundColor!: string;
  public cardBackgroundColorRGB!: string;
  public colorFormFieldOutline!: string;
  public colorFormFieldOutlineThick!: string;
  public colorFormFieldOutlineDisabled!: string;
  public colorFormFieldTextDisabled!: string;
  public colorFormFieldLabel!: string;
  public borderColor!: string;
  public characterCardHeaderBackgroundColor!: string;
  public characterCardBackgroundColor!: string;
  public character2SWCardBackgroundColor!: string;
  public character2SWCardHeaderBackgroundColor!: string;
  public vehicleCardHeaderBackgroundColor!: string;
  public vehicleCardBackgroundColor!: string;
  public markdownTableRowBackgroundColor!: string;
  public markdownTableRowAlternateBackgroundColor!: string;
  public headerBar!: string;
  public pageBackgroundColor!: string;
  public sectionHeaderBarColor!: string;
  public sectionBackgroundColor!: string;
  public sectionBackgroundDarkColor!: string;
  public sectionBackgroundLightColor!: string;
  public sectionBackgroundLightColorAlternate!: string;
  public linesColor!: string;
  public linesColorTransparent!: string;

  public static getPropKey<T extends keyof ThemeKeys>(key: T): string {
    return themeMap[key];
  }

  public static getTheme(option: ThemeOption): Theme {
    switch (option) {
      case ThemeOption.Dark:
        return darkTheme;
      case ThemeOption.Light:
      default:
        return lightTheme;
    }
  }

  public static getBodyClass(option: ThemeOption): string {
    switch (option) {
      case ThemeOption.Dark:
        return Theme.themeClasses[1];
      case ThemeOption.Light:
      default:
        return Theme.themeClasses[0];
    }
  }
}

interface ThemeKeys extends Theme {}

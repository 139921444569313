export enum NdsCharacterTalentMatrixType {
  Talent = 'talent',
  Upgrade = 'upgrade',
}

export namespace NdsCharacterTalentMatrixType {
  export const members: NdsCharacterTalentMatrixType[] = [
    NdsCharacterTalentMatrixType.Talent,
    NdsCharacterTalentMatrixType.Upgrade,
  ];
}

import { NdsCharacterModifierType } from '../enums';
import {
  NdsCharacterModifierArmorData,
  NdsCharacterModifierAttributeData,
  NdsCharacterModifierCharacteristicData,
  NdsCharacterModifierData,
  NdsCharacterModifierNarrativeData,
  NdsCharacterModifierSkillData,
  NdsCharacterModifierWeaponData,
} from '../models/modifiers';

export function NdsCharacterModifierFactory(
  modifier?: NdsCharacterModifierData
): NdsCharacterModifierData {
  switch (modifier?.type) {
    case NdsCharacterModifierType.Armor:
      return new NdsCharacterModifierArmorData(modifier as NdsCharacterModifierArmorData);
    case NdsCharacterModifierType.Attribute:
      return new NdsCharacterModifierAttributeData(modifier as NdsCharacterModifierAttributeData);
    case NdsCharacterModifierType.Characteristic:
      return new NdsCharacterModifierCharacteristicData(
        modifier as NdsCharacterModifierCharacteristicData
      );
    case NdsCharacterModifierType.Skill:
      return new NdsCharacterModifierSkillData(modifier as NdsCharacterModifierSkillData);
    case NdsCharacterModifierType.Weapon:
      return new NdsCharacterModifierWeaponData(modifier as NdsCharacterModifierWeaponData);
    case NdsCharacterModifierType.Narrative:
      return new NdsCharacterModifierNarrativeData(modifier as NdsCharacterModifierNarrativeData);
    default:
      return new NdsCharacterModifierData(modifier);
  }
}

import {
  GameTheme,
  parseEnum,
  parseEnumArray,
  RpgClassValidators,
  DiceTheme,
  BaseContentConfiguration,
} from '@rpg/core/base';
import { NdsVehicleDefenseZone } from '../enums';

export class NdsVehicleConfigurationData extends BaseContentConfiguration {
  public diceTheme: DiceTheme = DiceTheme.Genesys;
  public gameTheme: GameTheme = GameTheme.Genesys_Core;
  public defenseZones: NdsVehicleDefenseZone[] = [NdsVehicleDefenseZone.Fore];
  public forceDiceEnabled: boolean = false;

  constructor(params?: Partial<NdsVehicleConfigurationData>) {
    super(params);
    if (!!params) {
      this.gameTheme = parseEnum(GameTheme, params.gameTheme, this.gameTheme);
      this.diceTheme = parseEnum(DiceTheme, params.diceTheme, this.diceTheme);
      this.defenseZones = parseEnumArray(NdsVehicleDefenseZone, params.defenseZones);
      this.forceDiceEnabled = params.forceDiceEnabled ?? this.forceDiceEnabled;
    }
  }

  public static validate(input: NdsVehicleConfigurationData): string[] {
    const errors: string[] = BaseContentConfiguration.validate(input);
    errors.push(
      ...RpgClassValidators.enum(input.diceTheme, DiceTheme, {
        enumName: 'Dice Theme',
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.gameTheme, GameTheme, {
        enumName: 'Game Theme',
      })
    );
    errors.push(
      ...RpgClassValidators.enum(input.defenseZones, NdsVehicleDefenseZone, {
        isArray: true,
        enumName: 'Defense Zones',
      })
    );
    errors.push(...RpgClassValidators.boolean(input.forceDiceEnabled));
    return errors;
  }
}

import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsCharacterArmorData } from '@rpg/core/character';
import { NDSCharacterModifierFormGroupFactory } from './modifiers';
import { ndsCharacterAttachmentToFormGroup } from './nds-character-attachment-to-form-group';

export function ndsCharacterArmorToFormGroup(
  armor: NdsCharacterArmorData
): FormGroup<NdsCharacterArmorData> {
  return new FormGroup<NdsCharacterArmorData & Expandable>({
    expanded: new FormControl((armor as any)['expanded'] ?? false),
    attachments: new FormArray(armor.attachments.map(a => ndsCharacterAttachmentToFormGroup(a))),
    carrying: new FormControl(armor.carrying),
    cost: new FormControl(armor.cost),
    defense: new FormControl(armor.defense),
    encumbrance: new FormControl(armor.encumbrance),
    equipped: new FormControl(armor.equipped),
    hardPoints: new FormControl(armor.hardPoints),
    id: new FormControl(armor.id),
    lookupName: new FormControl(armor.lookupName),
    modifiers: new FormArray(armor.modifiers.map(m => NDSCharacterModifierFormGroupFactory(m))),
    name: new FormControl(armor.name),
    rarity: new FormControl(armor.rarity),
    restricted: new FormControl(armor.restricted),
    soak: new FormControl(armor.soak),
  });
}

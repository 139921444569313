export enum NdsCharacterAttribute {
  Soak = '[nds character attribute] soak',
  WoundsCurrent = '[nds character attribute] wounds current',
  WoundsThreshold = '[nds character attribute] wounds threshold',
  StrainCurrent = '[nds character attribute] strain current',
  StrainThreshold = '[nds character attribute] strain threshold',
  DefenseRanged = '[nds character attribute] defense ranged',
  DefenseMelee = '[nds character attribute] defense melee',
  AvailableXP = '[nds character attribute] available xp',
  TotalXP = '[nds character attribute] total xp',
  Money = '[nds character attribute] money',
  EncumbranceCurrent = '[nds character attribute] encumbrance current',
  EncumbranceThreshold = '[nds character attribute] encumbrance threshold',
  ForceRatingCurrent = '[nds character attribute] force rating current',
  ForceRatingCommitted = '[nds character attribute] force rating committed',
  AvailableHeroicAbilityPoints = '[nds character attribute] available heroic ability points',
  TotalHeroicAbilityPoints = '[nds character attribute] total heroic ability points',
  CharacterCount = '[nds character attribute] character count',
  CurrentMinionCount = '[nds character attribute] current minion count',
  AdversaryLevel = '[nds character attribute] adversary level',
  CombatPowerLevel = '[nds character attribute] combat power level',
  SocialPowerLevel = '[nds character attribute] social power level',
  GeneralPowerLevel = '[nds character attribute] general power level',
  AvailableAEmber = '[nds character attribute] available aember',
  TotalAEmber = '[nds character attribute] total aember',
}

export namespace NdsCharacterAttribute {
  export function translationKey(type: NdsCharacterAttribute): string {
    //! BACKWARDS COMPATIBILITY
    if (type.startsWith('[sw')) {
      type = type.replace(/\[sw/, '[nds') as any;
    } else if (type.startsWith('[gen')) {
      type = type.replace(/\[gen/, '[nds') as any;
    }

    return `enums.character.nds.attribute.${type}`;
  }

  export const members: NdsCharacterAttribute[] = [
    NdsCharacterAttribute.Soak,
    NdsCharacterAttribute.WoundsCurrent,
    NdsCharacterAttribute.WoundsThreshold,
    NdsCharacterAttribute.StrainCurrent,
    NdsCharacterAttribute.StrainThreshold,
    NdsCharacterAttribute.DefenseRanged,
    NdsCharacterAttribute.DefenseMelee,
    NdsCharacterAttribute.AvailableXP,
    NdsCharacterAttribute.TotalXP,
    NdsCharacterAttribute.Money,
    NdsCharacterAttribute.EncumbranceCurrent,
    NdsCharacterAttribute.EncumbranceThreshold,
    NdsCharacterAttribute.ForceRatingCommitted,
    NdsCharacterAttribute.ForceRatingCurrent,
    NdsCharacterAttribute.AvailableHeroicAbilityPoints,
    NdsCharacterAttribute.TotalHeroicAbilityPoints,
    NdsCharacterAttribute.CharacterCount,
    NdsCharacterAttribute.CurrentMinionCount,
    NdsCharacterAttribute.AdversaryLevel,
    NdsCharacterAttribute.CombatPowerLevel,
    NdsCharacterAttribute.SocialPowerLevel,
    NdsCharacterAttribute.GeneralPowerLevel,
    NdsCharacterAttribute.AvailableAEmber,
    NdsCharacterAttribute.TotalAEmber,
  ];
}

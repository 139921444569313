import { Constraints, createId, createSlug, parseNumber, RpgClassValidators } from '@rpg/core/base';
import { DiceType } from '@rpg/core/dice';
import { NdsCharacterRangeBand } from '../enums';
import { NdsCharacterModifierFactory } from '../functions/nds-character-modifier-factory';
import { NdsCharacterModifierValidatorFactory } from '../functions/nds-character-modifier-validator-factory';
import { NdsCharacterEncumbrance, NdsCharacterLookupName } from './interfaces';
import { NdsCharacterModifierData } from './modifiers';
import { NdsCharacterAttachmentData } from './nds-character-attachment-data';

export const NDS_CHARACTER_WEAPON_MIN_CRIT_RATING = 0;
export const NDS_CHARACTER_WEAPON_MAX_CRIT_RATING = 9;

export class NdsCharacterWeaponData implements NdsCharacterLookupName, NdsCharacterEncumbrance {
  public id: string = '';
  public name: string = '';
  public lookupName: string = '';
  public linkedSkillId: string = '';
  public baseDamage: number = 0;
  public damageAddsBrawn: boolean = false;
  public critRating: number = 0;
  public range: NdsCharacterRangeBand = NdsCharacterRangeBand.Medium;
  public encumbrance: number = 0;
  public carrying: boolean = false;
  public hardPoints: number = 0;
  public rarity: number = 0;
  public restricted: boolean = false;
  public cost: string = '';
  public equipped: boolean = false;
  public modifiers: NdsCharacterModifierData[] = [];
  public attachments: NdsCharacterAttachmentData[] = [];
  public extraDice: DiceType[] = [];
  public description: string = '';

  constructor(params?: Partial<NdsCharacterWeaponData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.lookupName = params.lookupName ?? this.lookupName;
      this.linkedSkillId = params.linkedSkillId ?? this.linkedSkillId;
      this.baseDamage = parseNumber(params.baseDamage, this.baseDamage);
      this.damageAddsBrawn = params.damageAddsBrawn ?? this.damageAddsBrawn;
      this.critRating = parseNumber(params.critRating, this.critRating);
      this.range = params.range ?? this.range;
      this.encumbrance = parseNumber(params.encumbrance, this.encumbrance);
      this.carrying = params.carrying ?? this.carrying;
      this.hardPoints = parseNumber(params.hardPoints, this.hardPoints);
      this.rarity = parseNumber(params.rarity, this.rarity);
      this.restricted = params.restricted ?? this.restricted;
      this.cost = params.cost ?? this.cost;
      this.equipped = params.equipped ?? this.equipped;
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(m => NdsCharacterModifierFactory(m)).filter(m => !!m.name)
        : this.modifiers;
      this.attachments = Array.isArray(params.attachments)
        ? params.attachments.map(a => new NdsCharacterAttachmentData(a))
        : this.attachments;
      this.extraDice = Array.isArray(params.extraDice) ? params.extraDice : this.extraDice;
      this.description = params.description ?? this.description;
    }
    // Enforce Constraints
    if (!this.id) {
      this.id = createId();
    }
    if (!this.lookupName) {
      this.lookupName = createSlug(this.name ?? '');
    }
    this.critRating = Constraints.withinRange(
      this.critRating,
      NDS_CHARACTER_WEAPON_MIN_CRIT_RATING,
      NDS_CHARACTER_WEAPON_MAX_CRIT_RATING
    );
  }

  public static validate(input: NdsCharacterWeaponData): string[] {
    const errors: string[] = [];
    const name = `Weapon ${input.name ? `(${input.name}) ` : ''}`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(
      ...RpgClassValidators.string(input.lookupName, {
        fieldName: `${name} Lookup Name`,
        allowEmpty: true,
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.linkedSkillId, { fieldName: `${name} Linked Skill` })
    );
    errors.push(
      ...RpgClassValidators.number(input.baseDamage, { fieldName: `${name} Base Damage` })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.damageAddsBrawn, {
        fieldName: `${name} Damage Adds Brawn`,
      })
    );
    errors.push(
      ...RpgClassValidators.number(input.critRating, { fieldName: `${name} Crit Rating` })
    );
    errors.push(
      ...RpgClassValidators.enum(input.range, NdsCharacterRangeBand, {
        enumName: 'Weapon Range',
        fieldName: `${name} Range`,
      })
    );
    errors.push(
      ...RpgClassValidators.number(input.encumbrance, { fieldName: `${name} Encumbrance` })
    );
    errors.push(...RpgClassValidators.boolean(input.carrying, { fieldName: `${name} Carrying` }));
    errors.push(
      ...RpgClassValidators.number(input.hardPoints, { fieldName: `${name} Hard Points` })
    );
    errors.push(...RpgClassValidators.number(input.rarity, { fieldName: `${name} Rarity` }));
    errors.push(
      ...RpgClassValidators.boolean(input.restricted, { fieldName: `${name} Restricted` })
    );
    errors.push(
      ...RpgClassValidators.string(input.cost, { allowEmpty: true, fieldName: `${name} Cost` })
    );
    errors.push(...RpgClassValidators.boolean(input.equipped, { fieldName: `${name} Equipped` }));
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterModifierValidatorFactory(next, name)],
        []
      )
    );
    errors.push(
      ...input.attachments.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterAttachmentData.validate(next, name)],
        []
      )
    );
    errors.push(
      ...RpgClassValidators.enum(input.extraDice, DiceType, {
        enumName: 'Extra Dice Type',
        isArray: true,
        fieldName: `${name} Extra Dice`,
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    return errors;
  }
}

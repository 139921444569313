import { DiceGroupType } from '../enums';
import { DiceSingle } from './dice-single';

export class NdsDiceStats {
  public success: number = 0;
  public advantage: number = 0;
  public triumph: number = 0;
  public failure: number = 0;
  public threat: number = 0;
  public despair: number = 0;
  public lightside: number = 0;
  public darkside: number = 0;
  public calculated: {
    success: number;
    advantage: number;
  } = {
    success: 0,
    advantage: 0,
  };

  constructor(params?: Partial<NdsDiceStats> | DiceSingle[]) {
    if (Array.isArray(params)) {
      this._parseDice(params);
    } else if (!!params) {
      this.success = params.success || this.success;
      this.advantage = params.advantage || this.advantage;
      this.triumph = params.triumph || this.triumph;
      this.failure = params.failure || this.failure;
      this.threat = params.threat || this.threat;
      this.despair = params.despair || this.despair;
      this.lightside = params.lightside || this.lightside;
      this.darkside = params.darkside || this.darkside;
      if (!!params.calculated) {
        this.calculated.success = params.calculated.success || this.calculated.success;
        this.calculated.advantage = params.calculated.advantage || this.calculated.advantage;
      }
    }
  }

  private _parseDice(dice: DiceSingle[]): void {
    let ndsForParse: string = '';
    for (const d of dice) {
      if (
        [
          DiceGroupType.NarrativeDiceSystem,
          DiceGroupType.FFGGenesys,
          DiceGroupType.FFGStarWars,
        ].includes(d.diceGroup)
      ) {
        ndsForParse += d.image;
      }
    }
    for (const nds of ndsForParse) {
      switch (nds.toUpperCase().trim()) {
        case 'R':
          this.triumph++;
        // falls through
        case 'S':
          this.success++;
          this.calculated.success++;
          break;
        case 'A':
          this.advantage++;
          this.calculated.advantage++;
          break;
        case 'D':
          this.despair++;
        // falls through
        case 'F':
          this.failure++;
          this.calculated.success--;
          break;
        case 'T':
          this.threat++;
          this.calculated.advantage--;
          break;
        case 'L':
          this.lightside++;
          break;
        case 'N':
          this.darkside++;
          break;
        default:
          break;
      }
    }
  }
}

// Disable no magic numbers. Result is a number based on the number
// of sides that a dice has. The switch checks against this number
// and for each number returns a given result

import { MersenneTwister, MTSeed } from '@rpg/core/base';
import { Dice, DiceType } from '@rpg/core/dice';
import { DiceSingle } from 'libs/core/src/dice/models';
import { NdsDiceImage } from '../../enums';
import { DiceRollerStrategy } from '../../types';

/* eslint-disable no-magic-numbers */
export function ndsDieResultToImage(type: DiceType, result: number): string {
  switch (type) {
    case DiceType.NdsProficiency:
      switch (result) {
        case 1:
          return `${NdsDiceImage.Advantage}${NdsDiceImage.Advantage}`;
        case 2:
          return `${NdsDiceImage.Advantage}`;
        case 3:
          return `${NdsDiceImage.Advantage}${NdsDiceImage.Advantage}`;
        case 4:
          return `${NdsDiceImage.Triumph}`;
        case 5:
          return `${NdsDiceImage.Success}`;
        case 6:
          return `${NdsDiceImage.Success}${NdsDiceImage.Advantage}`;
        case 7:
          return `${NdsDiceImage.Success}`;
        case 8:
          return `${NdsDiceImage.Success}${NdsDiceImage.Advantage}`;
        case 9:
          return `${NdsDiceImage.Success}${NdsDiceImage.Success}`;
        case 10:
          return `${NdsDiceImage.Success}${NdsDiceImage.Advantage}`;
        case 11:
          return `${NdsDiceImage.Success}${NdsDiceImage.Success}`;
        case 12:
          return `${NdsDiceImage.Blank}`;
        default:
          return ``;
      }
    case DiceType.NdsAbility:
      switch (result) {
        case 1:
          return `${NdsDiceImage.Success}`;
        case 2:
          return `${NdsDiceImage.Advantage}`;
        case 3:
          return `${NdsDiceImage.Success}${NdsDiceImage.Advantage}`;
        case 4:
          return `${NdsDiceImage.Success}${NdsDiceImage.Success}`;
        case 5:
          return `${NdsDiceImage.Advantage}`;
        case 6:
          return `${NdsDiceImage.Success}`;
        case 7:
          return `${NdsDiceImage.Advantage}${NdsDiceImage.Advantage}`;
        case 8:
          return `${NdsDiceImage.Blank}`;
        default:
          return ``;
      }
    case DiceType.NdsBoost:
      switch (result) {
        case 1:
          return `${NdsDiceImage.Success}${NdsDiceImage.Advantage}`;
        case 2:
          return `${NdsDiceImage.Advantage}${NdsDiceImage.Advantage}`;
        case 3:
          return `${NdsDiceImage.Success}`;
        case 4:
          return `${NdsDiceImage.Advantage}`;
        case 5:
          return `${NdsDiceImage.Blank}`;
        case 6:
          return `${NdsDiceImage.Blank}`;
        default:
          return ``;
      }
    case DiceType.NdsChallenge:
      switch (result) {
        case 1:
          return `${NdsDiceImage.Threat}${NdsDiceImage.Threat}`;
        case 2:
          return `${NdsDiceImage.Threat}`;
        case 3:
          return `${NdsDiceImage.Threat}${NdsDiceImage.Threat}`;
        case 4:
          return `${NdsDiceImage.Threat}`;
        case 5:
          return `${NdsDiceImage.Failure}${NdsDiceImage.Threat}`;
        case 6:
          return `${NdsDiceImage.Failure}`;
        case 7:
          return `${NdsDiceImage.Failure}${NdsDiceImage.Threat}`;
        case 8:
          return `${NdsDiceImage.Failure}`;
        case 9:
          return `${NdsDiceImage.Failure}${NdsDiceImage.Failure}`;
        case 10:
          return `${NdsDiceImage.Despair}`;
        case 11:
          return `${NdsDiceImage.Failure}${NdsDiceImage.Failure}`;
        case 12:
          return `${NdsDiceImage.Blank}`;
        default:
          return ``;
      }
    case DiceType.NdsDifficulty:
      switch (result) {
        case 1:
          return `${NdsDiceImage.Threat}`;
        case 2:
          return `${NdsDiceImage.Failure}`;
        case 3:
          return `${NdsDiceImage.Failure}${NdsDiceImage.Threat}`;
        case 4:
          return `${NdsDiceImage.Threat}`;
        case 5:
          return `${NdsDiceImage.Blank}`;
        case 6:
          return `${NdsDiceImage.Threat}${NdsDiceImage.Threat}`;
        case 7:
          return `${NdsDiceImage.Failure}${NdsDiceImage.Failure}`;
        case 8:
          return `${NdsDiceImage.Threat}`;
        default:
          return ``;
      }
    case DiceType.NdsSetback:
      switch (result) {
        case 1:
          return `${NdsDiceImage.Blank}`;
        case 2:
          return `${NdsDiceImage.Threat}`;
        case 3:
          return `${NdsDiceImage.Failure}`;
        case 4:
          return `${NdsDiceImage.Blank}`;
        case 5:
          return `${NdsDiceImage.Threat}`;
        case 6:
          return `${NdsDiceImage.Failure}`;
        default:
          return ``;
      }
    case DiceType.NdsForce:
      switch (result) {
        case 1:
          return `${NdsDiceImage.Dark}`;
        case 2:
          return `${NdsDiceImage.Light}${NdsDiceImage.Light}`;
        case 3:
          return `${NdsDiceImage.Dark}`;
        case 4:
          return `${NdsDiceImage.Light}${NdsDiceImage.Light}`;
        case 5:
          return `${NdsDiceImage.Dark}`;
        case 6:
          return `${NdsDiceImage.Light}${NdsDiceImage.Light}`;
        case 7:
          return `${NdsDiceImage.Dark}`;
        case 8:
          return `${NdsDiceImage.Light}`;
        case 9:
          return `${NdsDiceImage.Dark}`;
        case 10:
          return `${NdsDiceImage.Light}`;
        case 11:
          return `${NdsDiceImage.Dark}`;
        case 12:
          return `${NdsDiceImage.Dark}${NdsDiceImage.Dark}`;
        default:
          return ``;
      }
    case DiceType.NdsTriumph:
      return `${NdsDiceImage.Triumph}`;
    case DiceType.NdsSuccess:
      return `${NdsDiceImage.Success}`;
    case DiceType.NdsAdvantage:
      return `${NdsDiceImage.Advantage}`;
    case DiceType.NdsDespair:
      return `${NdsDiceImage.Despair}`;
    case DiceType.NdsFailure:
      return `${NdsDiceImage.Failure}`;
    case DiceType.NdsThreat:
      return `${NdsDiceImage.Threat}`;
    case DiceType.NdsLightSide:
      return `${NdsDiceImage.Light}`;
    case DiceType.NdsDarkSide:
      return `${NdsDiceImage.Dark}`;
    default:
      return ``;
  }
}

export const ndsRollerStrategy: DiceRollerStrategy = (
  dice: Dice,
  rngSeed?: number | MTSeed
): Dice => {
  const maxDiceAllowed = 1000;
  if (dice.dice.length > maxDiceAllowed) {
    throw new Error('Cannot roll more than 1000 dice at a time');
  }
  const random = new MersenneTwister(rngSeed);
  const rollFunc: (min: number, max: number) => number = (min, max) => random.integer(min, max);
  let rollOrder = 0;
  const rolledDice = dice.dice.map(d => {
    if (d.result !== null || d.type === null) {
      return d;
    }
    const sides = DiceType.getSides(d.type);
    d.result = sides > 1 ? rollFunc(1, sides) : sides;
    d.image = ndsDieResultToImage(d.type, d.result);
    d.rollOrder = rollOrder++;
    return d;
  });
  if (dice.details.superCharacteristicAvailable) {
    const initTriumphCount = rolledDice.filter(d => d.image === NdsDiceImage.Triumph).length;
    if (initTriumphCount > 0) {
      const additionalDice: DiceSingle[] = [];
      do {
        dice.details.superCharacteristicTriggered++;
        const result = rollFunc(1, DiceType.getSides(DiceType.NdsProficiency));
        additionalDice.push(
          new DiceSingle({
            type: DiceType.NdsProficiency,
            diceTheme: rolledDice[0].diceTheme,
            result: result,
            image: ndsDieResultToImage(DiceType.NdsProficiency, result),
            rollOrder: rollOrder++,
          })
        );
      } while (additionalDice[additionalDice.length - 1].image === NdsDiceImage.Triumph);
      rolledDice.unshift(...additionalDice);
    }
  }

  return new Dice({
    ...dice,
    dice: rolledDice.filter(d => typeof d.result === 'number'),
  });
};

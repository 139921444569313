import { isNotNull } from '@rpg/core/base';
import { NdsCharacter } from '../models';
import { ndsCalculatedCharacter } from './nds-calculated-character';

export function ndsCharacterItemReferences(
  unresolvedVehicle: NdsCharacter,
  itemId: string
): string[] {
  const { modifiers, resultCharacter } = ndsCalculatedCharacter(unresolvedVehicle);
  // Look if skills are referenced anywhere (weapons)
  const linkedWeaponIds = resultCharacter.weapons
    .reduce<Array<string | null>>(
      (acc, next) => [...acc, next.linkedSkillId === itemId ? next.id : null],
      []
    )
    .filter(isNotNull);
  const itemData = modifiers.get(itemId) ?? { appliedModifiers: [], ignoredModifiers: [] };
  // We don't care about applied or ignored, we want everything that is linked
  const linkedModifiers = [...itemData.appliedModifiers, ...itemData.ignoredModifiers];
  console.log(linkedModifiers);
  return linkedModifiers
    .filter(mod => !mod.parentIds.includes(itemId))
    .reduce<string[]>((acc, next) => [...acc, next.fromId], linkedWeaponIds);
}

import { FormGroup, FormArray } from '@ngneat/reactive-forms';
import { NdsVehicle } from '@rpg/core/vehicle';
import { baseVehicleToFormGroup } from '../../vehicle';
import {
  ndsVehicleActionToFormGroup,
  ndsVehicleAttachmentToFormGroup,
  ndsVehicleAttributeToFormGroup,
  ndsVehicleCharacteristicToFormGroup,
  ndsVehicleConfigurationToFormGroup,
  ndsVehicleCritToFormGroup,
  ndsVehicleDetailToFormGroup,
  NDSVehicleModifierFormGroupFactory,
  ndsVehicleWeaponToFormGroup,
} from './form-converters';

export function ndsVehicleToFormGroup(vehicle: NdsVehicle): FormGroup<NdsVehicle> {
  return new FormGroup<NdsVehicle>({
    ...baseVehicleToFormGroup(vehicle).controls,
    actions: new FormArray(vehicle.actions.map(a => ndsVehicleActionToFormGroup(a))),
    attachments: new FormArray(vehicle.attachments.map(a => ndsVehicleAttachmentToFormGroup(a))),
    attributes: new FormArray(vehicle.attributes.map(a => ndsVehicleAttributeToFormGroup(a))),
    characteristics: new FormArray(
      vehicle.characteristics.map(c => ndsVehicleCharacteristicToFormGroup(c))
    ),
    configuration: ndsVehicleConfigurationToFormGroup(vehicle.configuration),
    crits: new FormArray(vehicle.crits.map(c => ndsVehicleCritToFormGroup(c))),
    details: new FormArray(vehicle.details.map(d => ndsVehicleDetailToFormGroup(d))),
    modifiers: new FormArray(vehicle.modifiers.map(m => NDSVehicleModifierFormGroupFactory(m))),
    weapons: new FormArray(vehicle.weapons.map(w => ndsVehicleWeaponToFormGroup(w))),
  });
}

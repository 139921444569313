import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterModifierData } from '@rpg/core/character';

export function ndsCharacterModifierBaseToFormGroup(
  mod: NdsCharacterModifierData
): FormGroup<NdsCharacterModifierData> {
  return new FormGroup<NdsCharacterModifierData>({
    id: new FormControl(mod.id),
    type: new FormControl(mod.type, [Validators.required]),
    name: new FormControl(mod.name, [Validators.required]),
    description: new FormControl(mod.description),
  });
}

export enum NdsCharacterTalentDisplayType {
  Pyramid = '[nds character talent display type] pyramid',
  Tree = '[nds character talent display type] tree',
  ListGroup = '[nds character talent display type] list group',
}

export namespace NdsCharacterTalentDisplayType {
  export function translationKey(type: NdsCharacterTalentDisplayType): string {
    return `enums.character.nds.talent-display-type.${type}`;
  }

  export function toString(type: NdsCharacterTalentDisplayType): string {
    switch (type) {
      case NdsCharacterTalentDisplayType.Pyramid:
        return 'Pyramid';
      case NdsCharacterTalentDisplayType.Tree:
        return 'Tree';
      case NdsCharacterTalentDisplayType.ListGroup:
        return 'Table';
      default:
        return '';
    }
  }

  export const members: NdsCharacterTalentDisplayType[] = [
    NdsCharacterTalentDisplayType.Pyramid,
    NdsCharacterTalentDisplayType.Tree,
    NdsCharacterTalentDisplayType.ListGroup,
  ];
}

import { Injectable } from '@angular/core';
import { createId } from '@rpg/core/base';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _sessionId: string = '';

  public get sessionId(): string {
    return this._sessionId;
  }

  constructor() {
    this._sessionId = createId();
  }
}

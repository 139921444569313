import { Injectable } from '@angular/core';
import { GameSchema } from '@rpg/core/schema';

import { SupabaseService, toSupabaseErrorType } from './supabase.service';

@Injectable({
  providedIn: 'root',
})
export class GameSB {
  constructor(private sb: SupabaseService) {}

  __client() {
    return this.sb.client;
  }

  async createNewGame(name: string) {
    const { error: rpcError, data: rpcData } = await this.sb.client
      .rpc('game_create_new', { game_name: name })
      .maybeSingle();
    const { error, data } = toSupabaseErrorType<GameSchema>(rpcError, rpcData as any);
    if (!!error) return { error, data };
    return { error, data: data.id };
  }

  async game(game_id: string) {
    return this.sb.client
      .from('game')
      .select('id, name, allow_new_players')
      .eq('id', game_id)
      .maybeSingle();
  }

  async gameSettings(game_id: string) {
    return this.sb.db('game_settings').select('*').eq('game_id', game_id).maybeSingle();
  }

  async storyPoints(game_id: string) {
    return this.sb.db('story_points').select('*').eq('game_id', game_id).maybeSingle();
  }
}

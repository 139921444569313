<form [formGroup]="group" (submit)="submit(inputControl)">
  <mat-dialog-content>
    <rpg-input
      [type]="options.type"
      [inputControl]="inputControl"
      [placeholder]="options.placeholder"
      [suffixIconName]="'trash'"
      (suffixClick)="clear()"
    ></rpg-input>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button class="cancel-button" type="button" color="accent" (click)="cancel()">
      {{ options.cancelLabel }}
    </button>
    <button mat-raised-button class="submit-button" color="primary">
      {{ options.submitLabel }}
    </button>
  </mat-dialog-actions>
</form>

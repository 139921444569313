export enum DiceMetaType {
  Character = 'character',
  Force = 'force',
  Info = 'info',
  Skill = 'skill',
  Weapon = 'weapon',
}

export namespace DiceMetaType {
  export function translationKey(type: DiceMetaType): string {
    return `enums.dice.dice-meta-type.${type}`;
  }
}

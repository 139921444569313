import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SchemaMap } from '@rpg/core/schema';
import { CookieService } from '@rpg/ngx/core';
import { environment } from '@sessions/env';
import { PostgrestError, SupabaseClient } from '@supabase/supabase-js';

type SupabaseErrorInner<DataType> =
  | { error: null; data: DataType }
  | { error: Error; data: null }
  | { error: PostgrestError; data: null };

export type WithSupabaseError<DataType> = Promise<SupabaseErrorInner<DataType>>;

export function toSupabaseErrorType<T>(
  error: PostgrestError | null | undefined,
  data: T | null
): SupabaseErrorInner<T> {
  if (!!error) return { error, data: null };
  if (data === null) return { error: new Error('No data found!'), data: null };
  return { error: null, data };
}

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private _client: SupabaseClient;

  constructor(cookie: CookieService, @Inject(PLATFORM_ID) private id: any) {
    this._client = new SupabaseClient(environment.supabase.url, environment.supabase.key, {
      auth: {
        storage: {
          setItem: (key: string, value: string) => {
            localStorage.setItem(key, value);
            cookie.set(key, value);
          },
          getItem: (key: string) => {
            console.log('is browser', isPlatformBrowser(this.id));
            return localStorage.getItem(key);
            // return cookie.get(key);
          },
          removeItem: (key: string) => {
            localStorage.removeItem(key);
            cookie.delete(key);
          },
        },
      },
    });
  }

  get client() {
    return this._client;
  }

  get auth() {
    return this.client.auth;
  }

  get storage() {
    return this.client.storage;
  }

  db<TableName extends keyof SchemaMap>(tableName: TableName) {
    return this._client.from(tableName);
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Logger } from '@rpg/core/base';
// import * as Sentry from '@sentry/browser';
import 'hammerjs';
import { enableMapSet } from 'immer';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
} else {
  // Logger.setLevel('debug');
}
Logger.setLevel('debug');

// if (environment.enableSentry) {
//   Sentry.init({
//     dsn: environment.sentry.dsn,
//     release: environment.sentry.version,
//     environment: environment.sentry.environment,
//   });
// }

enableMapSet();

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

export enum NdsCharacterActivationType {
  Passive = '[nds character activation type] passive',
  Incidental = '[nds character activation type] incidental',
  Maneuver = '[nds character activation type] maneuver',
  Action = '[nds character activation type] action',
}

export namespace NdsCharacterActivationType {
  /**
   * @deprecated
   */
  export function toString(type: NdsCharacterActivationType): string {
    switch (type) {
      case NdsCharacterActivationType.Passive:
        return 'Passive';
      case NdsCharacterActivationType.Incidental:
        return 'Incidental';
      case NdsCharacterActivationType.Maneuver:
        return 'Maneuver';
      case NdsCharacterActivationType.Action:
        return 'Action';
      default:
        return '';
    }
  }

  export function translationKey(type: NdsCharacterActivationType): string {
    return `enums.character.nds.activation-type.${type}`;
  }

  export function abbreviationTranslationKey(type: NdsCharacterActivationType): string {
    return `enums.character.nds.activation-type-abbreviation.${type}`;
  }

  export const members: NdsCharacterActivationType[] = [
    NdsCharacterActivationType.Passive,
    NdsCharacterActivationType.Incidental,
    NdsCharacterActivationType.Maneuver,
    NdsCharacterActivationType.Action,
  ];
}

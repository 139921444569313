export enum NdsVehicleCharacteristic {
  Silhouette = '[nds vehicle characteristic] silhouette',
  MaxSpeed = '[nds vehicle characteristic] max speed',
  CurrentSpeed = '[nds vehicle characteristic] current speed',
  Handling = '[nds vehicle characteristic] handling',
}

export namespace NdsVehicleCharacteristic {
  export function translationKey(type: NdsVehicleCharacteristic): string {
    return `enums.vehicle.nds.characteristic.${type}`;
  }

  export const members: NdsVehicleCharacteristic[] = [
    NdsVehicleCharacteristic.Silhouette,
    NdsVehicleCharacteristic.MaxSpeed,
    NdsVehicleCharacteristic.CurrentSpeed,
    NdsVehicleCharacteristic.Handling,
  ];
}

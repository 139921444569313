<mat-dialog-content>
  <h2>Formatting Examples</h2>
  <div class="example" *ngFor="let section of sections">
    <h3 class="example__title" *ngIf="section.title">
      {{ section.title }}
    </h3>
    <p class="example__description" *ngIf="section.description">
      {{ section.description }}
    </p>
    <div class="example__group" *ngFor="let example of section.examples">
      <label *ngIf="example.label">{{ example.label }}</label>
      <div class="example__input">
        <textarea [rows]="example.rows" [value]="example.input" [disabled]="true"></textarea>
      </div>
      <div class="example__output">
        <markdown [data]="example.input"></markdown>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button mat-raised-button color="primary" mat-dialog-close>Close Formatting Help</button>
</mat-dialog-actions>

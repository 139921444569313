import { NdsVehicle } from '../models';
import { ndsCalculatedVehicle } from './nds-calculated-vehicle';

export function ndsVehicleItemReferences(unresolvedVehicle: NdsVehicle, itemId: string): string[] {
  const { modifiers } = ndsCalculatedVehicle(unresolvedVehicle);
  const itemData = modifiers.get(itemId);
  if (!itemData) return [];
  // We don't care about applied or ignored, we want everything that is linked
  const linkedModifiers = [...itemData.appliedModifiers, ...itemData.ignoredModifiers];
  console.log(linkedModifiers);
  return linkedModifiers.reduce<string[]>((acc, next) => [...acc, next.fromId], []);
}

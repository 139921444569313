import { Constraints, createId, parseNumber, RpgClassValidators } from '@rpg/core/base';

export const NDS_CHARACTER_CRIT_MIN_DIFFICULTY = 1;
export const NDS_CHARACTER_CRIT_MAX_DIFFICULTY = 5;
export const NDS_CHARACTER_CRIT_MIN_SEVERITY = 1;
export const NDS_CHARACTER_CRIT_MAX_SEVERITY = 999;

export class NdsCharacterCritData {
  public id: string = '';
  public name: string = '';
  public description: string = '';
  public difficulty: number = NDS_CHARACTER_CRIT_MIN_DIFFICULTY;
  public severity: number = NDS_CHARACTER_CRIT_MIN_SEVERITY;

  constructor(params?: Partial<NdsCharacterCritData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.description = params.description ?? this.description;
      this.difficulty = parseNumber(params.difficulty, this.difficulty);
      this.severity = parseNumber(params.severity, this.severity);
    }
    // Enforce Constraints
    if (!this.id) {
      this.id = createId();
    }
    this.difficulty = Constraints.withinRange(
      this.difficulty,
      NDS_CHARACTER_CRIT_MIN_DIFFICULTY,
      NDS_CHARACTER_CRIT_MAX_DIFFICULTY
    );
    this.severity = Constraints.withinRange(
      this.severity,
      NDS_CHARACTER_CRIT_MIN_SEVERITY,
      NDS_CHARACTER_CRIT_MAX_SEVERITY
    );
  }

  public static validate(input: NdsCharacterCritData): string[] {
    const errors: string[] = [];
    const name = `Crit ${input.name ? `(${input.name}) ` : ''}`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    errors.push(
      ...RpgClassValidators.number(input.difficulty, {
        min: NDS_CHARACTER_CRIT_MIN_DIFFICULTY,
        max: NDS_CHARACTER_CRIT_MAX_DIFFICULTY,
        fieldName: `${name} Difficulty`,
      })
    );
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(
      ...RpgClassValidators.number(input.severity, {
        min: NDS_CHARACTER_CRIT_MIN_SEVERITY,
        max: NDS_CHARACTER_CRIT_MAX_SEVERITY,
        fieldName: `${name} Severity`,
      })
    );
    return errors;
  }
}

import { StoryPointsType } from '../enums';
import { sortStoryPoints } from './sort-story-points';

export function addStoryPoints(
  currentPoints: StoryPointsType[],
  type?: StoryPointsType | StoryPointsType[]
) {
  const parsedTypes = Array.isArray(type)
    ? type.length > 0
      ? type
      : [StoryPointsType.Player]
    : !!type
    ? [type]
    : [StoryPointsType.Player];

  return sortStoryPoints([...currentPoints, ...parsedTypes]);
}

export function removeStoryPoints(
  currentPoints: StoryPointsType[],
  type?: StoryPointsType | StoryPointsType[]
) {
  if (currentPoints.length === 0) return currentPoints;
  const parsedTypes = Array.isArray(type)
    ? type.length > 0
      ? type
      : [currentPoints[currentPoints.length - 1]]
    : !!type
    ? [type]
    : [currentPoints[currentPoints.length - 1]];

  let updatedPoints = [...currentPoints];

  parsedTypes.forEach(p => {
    const pIndex = updatedPoints.indexOf(p);
    if (pIndex > -1) {
      updatedPoints = [...updatedPoints.slice(0, pIndex), ...updatedPoints.slice(pIndex + 1)];
    }
  });

  return updatedPoints;
}

export function toggleStoryPoints(
  currentPoints: StoryPointsType[],
  type: StoryPointsType | StoryPointsType[]
) {
  if (currentPoints.length === 0) return currentPoints;
  const parsedTypes = Array.isArray(type)
    ? type.length > 0
      ? type
      : null
    : !!type
    ? [type]
    : null;
  if (parsedTypes === null) return currentPoints;

  let updatedPoints = [...currentPoints];
  parsedTypes.forEach(p => {
    const pIndex = updatedPoints.indexOf(p);
    if (pIndex > -1) {
      updatedPoints = [
        ...updatedPoints.slice(0, pIndex),
        p === StoryPointsType.Player ? StoryPointsType.GM : StoryPointsType.Player,
        ...updatedPoints.slice(pIndex + 1),
      ];
    }
  });

  return sortStoryPoints(updatedPoints);
}

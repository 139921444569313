import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleConfigurationData } from '@rpg/core/vehicle';
import { RpgValidators } from '../../../validators';
import { baseContentConfigurationToFormGroup } from '../../../base';

export function ndsVehicleConfigurationToFormGroup(
  config: NdsVehicleConfigurationData
): FormGroup<NdsVehicleConfigurationData> {
  return new FormGroup<NdsVehicleConfigurationData>({
    ...baseContentConfigurationToFormGroup(config).controls,
    defenseZones: new FormControl(config.defenseZones, [RpgValidators.arrayWithValue]),
    forceDiceEnabled: new FormControl(config.forceDiceEnabled),
  });
}

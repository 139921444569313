import { GameTheme } from '@rpg/core/base';
import { NdsCharacterCharacteristic, NdsCharacterSkillType } from '../enums';
import { NdsCharacterSkillData } from '../models';

export function getDefaultNdsCharacterSkills(theme: GameTheme): NdsCharacterSkillData[] {
  const baseSkills = [
    // General Skills
    new NdsCharacterSkillData({
      name: 'Athletics',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
    }),
    new NdsCharacterSkillData({
      name: 'Cool',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Presence,
    }),
    new NdsCharacterSkillData({
      name: 'Coordination',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Agility,
    }),
    new NdsCharacterSkillData({
      name: 'Discipline',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Willpower,
    }),
    new NdsCharacterSkillData({
      name: 'Mechanics',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
    }),
    new NdsCharacterSkillData({
      name: 'Medicine',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
    }),
    new NdsCharacterSkillData({
      name: 'Perception',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
    }),
    new NdsCharacterSkillData({
      name: 'Resilience',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
    }),
    new NdsCharacterSkillData({
      name: 'Skulduggery',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
    }),
    new NdsCharacterSkillData({
      name: 'Stealth',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Agility,
    }),
    new NdsCharacterSkillData({
      name: 'Streetwise',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
    }),
    new NdsCharacterSkillData({
      name: 'Vigilance',
      skillType: NdsCharacterSkillType.General,
      linkedCharacteristic: NdsCharacterCharacteristic.Willpower,
    }),
    // Combat Skills
    new NdsCharacterSkillData({
      name: 'Brawl',
      skillType: NdsCharacterSkillType.Combat,
      linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
    }),
    // Social Skills
    new NdsCharacterSkillData({
      name: 'Charm',
      skillType: NdsCharacterSkillType.Social,
      linkedCharacteristic: NdsCharacterCharacteristic.Presence,
    }),
    new NdsCharacterSkillData({
      name: 'Coercion',
      skillType: NdsCharacterSkillType.Social,
      linkedCharacteristic: NdsCharacterCharacteristic.Willpower,
    }),
    new NdsCharacterSkillData({
      name: 'Deception',
      skillType: NdsCharacterSkillType.Social,
      linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
    }),
    new NdsCharacterSkillData({
      name: 'Leadership',
      skillType: NdsCharacterSkillType.Social,
      linkedCharacteristic: NdsCharacterCharacteristic.Presence,
    }),
    new NdsCharacterSkillData({
      name: 'Negotiation',
      skillType: NdsCharacterSkillType.Social,
      linkedCharacteristic: NdsCharacterCharacteristic.Presence,
    }),
  ];
  let additionalSkills: NdsCharacterSkillData[] = [];
  switch (theme) {
    case GameTheme.Genesys_Android:
      additionalSkills = [
        // General
        new NdsCharacterSkillData({
          name: 'Comp (Hacking)',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Comp (SysOps)',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Driving',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Operating',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Piloting',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Survival',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        // Combat
        new NdsCharacterSkillData({
          name: 'Gunnery',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Melee',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged-Heavy',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged-Light',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        // Knowledge
        new NdsCharacterSkillData({
          name: 'Science',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Society',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'The Net',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
      ];
      break;
    case GameTheme.Genesys_Keyforge:
      additionalSkills = [
        // General
        new NdsCharacterSkillData({
          name: 'Computers',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Operating',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Piloting',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Riding',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Survival',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        new NdsCharacterSkillData({
          name: 'Æmbercraft',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Willpower,
        }),
        // Combat
        new NdsCharacterSkillData({
          name: 'Gunnery',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Melee',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        // Knowledge
        new NdsCharacterSkillData({
          name: 'Crucible',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Culture',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Science',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Æmber',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
      ];
      break;
    case GameTheme.Genesys_Terrinoth:
      additionalSkills = [
        // General
        new NdsCharacterSkillData({
          name: 'Alchemy',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Riding',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Survival',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        // Magic
        new NdsCharacterSkillData({
          name: 'Arcana',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Divine',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Willpower,
        }),
        new NdsCharacterSkillData({
          name: 'Primal',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        new NdsCharacterSkillData({
          name: 'Runes',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Verse',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Presence,
        }),
        // Combat
        new NdsCharacterSkillData({
          name: 'Melee-Heavy',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Melee-Light',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        // Knowledge
        new NdsCharacterSkillData({
          name: 'Adventuring',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Forbidden',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Geography',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Lore',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
      ];
      break;
    case GameTheme.Genesys_TwilightImperium:
      additionalSkills = [
        // General
        new NdsCharacterSkillData({
          name: 'Astrogation',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Computers',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Operating',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Piloting',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Survival',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        // Combat
        new NdsCharacterSkillData({
          name: 'Gunnery',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Melee',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged-Heavy',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged-Light',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        // Knowledge
        new NdsCharacterSkillData({
          name: 'Culture',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Lore',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Science',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
      ];
      break;
    case GameTheme.Genesys_Core:
      additionalSkills = [
        // General
        new NdsCharacterSkillData({
          name: 'Alchemy',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Astrocartography',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Computers',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Driving',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Operating',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Piloting',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Riding',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Survival',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        // Magic
        new NdsCharacterSkillData({
          name: 'Arcana',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Divine',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Willpower,
        }),
        new NdsCharacterSkillData({
          name: 'Primal',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        // Combat
        new NdsCharacterSkillData({
          name: 'Gunnery',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Melee',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Melee-Heavy',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Melee-Light',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged-Heavy',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged-Light',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        // Knowledge
        new NdsCharacterSkillData({
          name: 'Knowledge',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
      ];
      break;
    case GameTheme.StarWars_AoR:
    case GameTheme.StarWars_EotE:
    case GameTheme.StarWars_FaD:
      additionalSkills = [
        new NdsCharacterSkillData({
          name: 'Astrogation',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Computers',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Piloting Planetary',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Piloting Space',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Survival',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        // Combat Skills
        new NdsCharacterSkillData({
          name: 'Gunnery',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Lightsaber',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Melee',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged Heavy',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged Light',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        // Social Skills
        // Knowledge Skills
        new NdsCharacterSkillData({
          name: 'Core Worlds',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Education',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Lore',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Outer Rim',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Underworld',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Warfare',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Xenology',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
      ];
      break;
    case GameTheme.Community_AwakenedAge:
      additionalSkills = [
        // General
        new NdsCharacterSkillData({
          name: 'Computers',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Driving',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Operating',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Piloting',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        // Other
        new NdsCharacterSkillData({
          name: 'Z-Gene',
          skillType: NdsCharacterSkillType.Other,
          linkedCharacteristic: NdsCharacterCharacteristic.None,
        }),
        // Combat
        new NdsCharacterSkillData({
          name: 'Gunnery',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Melee',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged Heavy',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged Light',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        // Knowledge
        new NdsCharacterSkillData({
          name: 'Education',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Science',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Society',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
      ];
      break;
    case GameTheme.Community_Inquisition:
      additionalSkills = [
        new NdsCharacterSkillData({
          name: 'Alchemy',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Riding',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Survival',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        new NdsCharacterSkillData({
          name: 'Melee-Heavy',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Melee-Light',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Forbidden',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Geography',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Lore',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Witchcraft',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
      ];
      break;
    case GameTheme.Community_SomethingStrange:
      additionalSkills = [
        new NdsCharacterSkillData({
          name: 'Computers',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Driving',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Operating',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Piloting',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Survival',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        new NdsCharacterSkillData({
          name: 'Arcana',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Divine',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Willpower,
        }),
        new NdsCharacterSkillData({
          name: 'Primal',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        new NdsCharacterSkillData({
          name: 'Melee',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged - Light',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged - Heavy',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Magic',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Occult',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Religion',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Science',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
      ];
      break;
    case GameTheme.Community_Tron:
      additionalSkills = [
        new NdsCharacterSkillData({
          name: 'Computers',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Driving',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Operating',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Piloting',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Survival',
          skillType: NdsCharacterSkillType.General,
          linkedCharacteristic: NdsCharacterCharacteristic.Cunning,
        }),
        new NdsCharacterSkillData({
          name: 'Gunnery',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Melee',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Brawn,
        }),
        new NdsCharacterSkillData({
          name: 'Ranged',
          skillType: NdsCharacterSkillType.Combat,
          linkedCharacteristic: NdsCharacterCharacteristic.Agility,
        }),
        new NdsCharacterSkillData({
          name: 'Data',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'The Grid',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Outlands',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Users',
          skillType: NdsCharacterSkillType.Knowledge,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
        new NdsCharacterSkillData({
          name: 'Coding',
          skillType: NdsCharacterSkillType.Magic,
          linkedCharacteristic: NdsCharacterCharacteristic.Intellect,
        }),
      ];
      break;
    default:
      additionalSkills = [];
  }
  return [...baseSkills, ...additionalSkills].sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });
}

export enum DiceType {
  EoteProficiency = 'EoteProficiency',
  EoteAbility = 'EoteAbility',
  EoteBoost = 'EoteBoost',
  EoteChallenge = 'EoteChallenge',
  EoteDifficulty = 'EoteDifficulty',
  EoteSetback = 'EoteSetback',
  EoteRemoveSetback = 'EoteRemoveSetback',
  EoteForce = 'EoteForce',
  EoteTriumph = 'EoteTriumph',
  EoteSuccess = 'EoteSuccess',
  EoteAdvantage = 'EoteAdvantage',
  EoteDespair = 'EoteDespair',
  EoteFailure = 'EoteFailure',
  EoteThreat = 'EoteThreat',
  EoteLightSide = 'EoteLightside',
  EoteDarkSide = 'EoteDarkside',
  EoteLightDarkSplit = 'EoteLightDark',
  EotePositiveUpgrade = 'EotePositiveUpgrade',
  EoteNegativeUpgrade = 'EoteNegativeUpgrade',

  GenesysProficiency = 'GenesysProficiency',
  GenesysAbility = 'GenesysAbility',
  GenesysBoost = 'GenesysBoost',
  GenesysChallenge = 'GenesysChallenge',
  GenesysDifficulty = 'GenesysDifficulty',
  GenesysSetback = 'GenesysSetback',
  GenesysRemoveSetback = 'GenesysRemoveSetback',
  GenesysTriumph = 'GenesysTriumph',
  GenesysSuccess = 'GenesysSuccess',
  GenesysAdvantage = 'GenesysAdvantage',
  GenesysDespair = 'GenesysDespair',
  GenesysFailure = 'GenesysFailure',
  GenesysThreat = 'GenesysThreat',
  GenesysPositiveUpgrade = 'GenesysPositiveUpgrade',
  GenesysNegativeUpgrade = 'GenesysNegativeUpgrade',

  NdsProficiency = 'NDSProficiency',
  NdsAbility = 'NDSAbility',
  NdsBoost = 'NDSBoost',
  NdsChallenge = 'NDSChallenge',
  NdsDifficulty = 'NDSDifficulty',
  NdsSetback = 'NDSSetback',
  NdsRemoveSetback = 'NDSRemoveSetback',
  NdsTriumph = 'NDSTriumph',
  NdsSuccess = 'NDSSuccess',
  NdsAdvantage = 'NDSAdvantage',
  NdsDespair = 'NDSDespair',
  NdsFailure = 'NDSFailure',
  NdsThreat = 'NDSThreat',
  NdsForce = 'NDSForce',
  NdsLightSide = 'NDSLightSide',
  NdsDarkSide = 'NDSDarkSide',
  NdsLightDarkSplit = 'NDSLightDarkSplit',
  NdsPositiveUpgrade = 'NDSPositiveUpgrade',
  NdsPositiveIncrease = 'NDSPositiveIncrease',
  NdsNegativeUpgrade = 'NDSNegativeUpgrade',
  NdsNegativeIncrease = 'NDSNegativeIncrease',

  AGED3 = 'aged3',
  AGED6 = 'aged6',
  AGEStunt = 'ageStunt',

  D4 = 'd4',
  D6 = 'd6',
  D8 = 'd8',
  D10 = 'd10',
  D12 = 'd12',
  D20 = 'd20',
  D100 = 'd100',
  Percentile = 'dpercentile',

  Polyhedral = 'Polyhedral',
  Modifier = 'Modifier',
}

export namespace DiceType {
  export function toString(type: DiceType | undefined | null): string {
    switch (type) {
      case DiceType.EoteProficiency:
      case DiceType.GenesysProficiency:
      case DiceType.NdsProficiency:
        return 'Proficiency';
      case DiceType.EoteAbility:
      case DiceType.GenesysAbility:
      case DiceType.NdsAbility:
        return 'Ability';
      case DiceType.EoteBoost:
      case DiceType.GenesysBoost:
      case DiceType.NdsBoost:
        return 'Boost';
      case DiceType.EoteChallenge:
      case DiceType.GenesysChallenge:
      case DiceType.NdsChallenge:
        return 'Challenge';
      case DiceType.EoteDifficulty:
      case DiceType.GenesysDifficulty:
      case DiceType.NdsDifficulty:
        return 'Difficulty';
      case DiceType.EoteSetback:
      case DiceType.GenesysSetback:
      case DiceType.NdsSetback:
        return 'Setback';
      case DiceType.EoteRemoveSetback:
      case DiceType.GenesysRemoveSetback:
      case DiceType.NdsRemoveSetback:
        return 'Remove Setback';
      case DiceType.EoteForce:
      case DiceType.NdsForce:
        return 'Force';
      case DiceType.EoteTriumph:
      case DiceType.GenesysTriumph:
      case DiceType.NdsTriumph:
        return 'Triumph';
      case DiceType.EoteSuccess:
      case DiceType.GenesysSuccess:
      case DiceType.NdsSuccess:
        return 'Success';
      case DiceType.EoteAdvantage:
      case DiceType.GenesysAdvantage:
      case DiceType.NdsAdvantage:
        return 'Advantage';
      case DiceType.EoteDespair:
      case DiceType.GenesysDespair:
      case DiceType.NdsDespair:
        return 'Despair';
      case DiceType.EoteFailure:
      case DiceType.GenesysFailure:
      case DiceType.NdsFailure:
        return 'Failure';
      case DiceType.EoteThreat:
      case DiceType.GenesysThreat:
      case DiceType.NdsThreat:
        return 'Threat';
      case DiceType.EoteLightSide:
      case DiceType.NdsLightSide:
        return 'Lightside';
      case DiceType.EoteDarkSide:
      case DiceType.NdsDarkSide:
        return 'Darkside';
      case DiceType.EoteLightDarkSplit:
      case DiceType.NdsLightDarkSplit:
        return 'Light/Dark';
      case DiceType.EotePositiveUpgrade:
      case DiceType.GenesysPositiveUpgrade:
      case DiceType.NdsPositiveUpgrade:
        return 'Upgrade';
      case DiceType.NdsPositiveIncrease:
        return 'Increase';
      case DiceType.EoteNegativeUpgrade:
      case DiceType.GenesysNegativeUpgrade:
      case DiceType.NdsNegativeUpgrade:
        return 'Negative Upgrade';
      case DiceType.NdsNegativeIncrease:
        return 'Negative Increase';
      case DiceType.AGEStunt:
        return 'Stunt';
      case DiceType.AGED3:
        return 'D3';
      case DiceType.D4:
        return 'D4';
      case DiceType.AGED6:
      case DiceType.D6:
        return 'D6';
      case DiceType.D8:
        return 'D8';
      case DiceType.D10:
        return 'D10';
      case DiceType.D12:
        return 'D12';
      case DiceType.D20:
        return 'D20';
      case DiceType.D100:
        return 'D100';
      case DiceType.Percentile:
        return 'Percentile';
      case DiceType.Polyhedral:
        return 'Polyhedral';
      case DiceType.Modifier:
        return 'Modifier';
      default:
        return '';
    }
  }

  export const diceOrder: { type: DiceType; order: number }[] = [
    { type: DiceType.EoteProficiency, order: 10 },
    { type: DiceType.EoteAbility, order: 20 },
    { type: DiceType.EoteBoost, order: 30 },
    { type: DiceType.EoteChallenge, order: 40 },
    { type: DiceType.EoteDifficulty, order: 50 },
    { type: DiceType.EoteSetback, order: 60 },
    { type: DiceType.EoteRemoveSetback, order: 61 },
    { type: DiceType.EoteTriumph, order: 70 },
    { type: DiceType.EoteSuccess, order: 80 },
    { type: DiceType.EoteAdvantage, order: 90 },
    { type: DiceType.EoteDespair, order: 100 },
    { type: DiceType.EoteFailure, order: 110 },
    { type: DiceType.EoteThreat, order: 120 },
    { type: DiceType.EotePositiveUpgrade, order: 124 },
    { type: DiceType.EoteNegativeUpgrade, order: 126 },
    { type: DiceType.GenesysProficiency, order: 130 },
    { type: DiceType.GenesysAbility, order: 140 },
    { type: DiceType.GenesysBoost, order: 150 },
    { type: DiceType.GenesysChallenge, order: 160 },
    { type: DiceType.GenesysDifficulty, order: 170 },
    { type: DiceType.GenesysSetback, order: 180 },
    { type: DiceType.GenesysRemoveSetback, order: 181 },
    { type: DiceType.GenesysTriumph, order: 190 },
    { type: DiceType.GenesysSuccess, order: 200 },
    { type: DiceType.GenesysAdvantage, order: 210 },
    { type: DiceType.GenesysDespair, order: 220 },
    { type: DiceType.GenesysFailure, order: 230 },
    { type: DiceType.GenesysThreat, order: 240 },
    { type: DiceType.GenesysPositiveUpgrade, order: 244 },
    { type: DiceType.GenesysNegativeUpgrade, order: 246 },
    { type: DiceType.NdsProficiency, order: 300 },
    { type: DiceType.NdsAbility, order: 305 },
    { type: DiceType.NdsBoost, order: 310 },
    { type: DiceType.NdsChallenge, order: 315 },
    { type: DiceType.NdsDifficulty, order: 320 },
    { type: DiceType.NdsSetback, order: 325 },
    { type: DiceType.NdsRemoveSetback, order: 330 },
    { type: DiceType.NdsForce, order: 335 },
    { type: DiceType.NdsTriumph, order: 340 },
    { type: DiceType.NdsSuccess, order: 345 },
    { type: DiceType.NdsAdvantage, order: 350 },
    { type: DiceType.NdsDespair, order: 355 },
    { type: DiceType.NdsFailure, order: 360 },
    { type: DiceType.NdsThreat, order: 365 },
    { type: DiceType.NdsLightSide, order: 370 },
    { type: DiceType.NdsDarkSide, order: 375 },
    { type: DiceType.NdsLightDarkSplit, order: 380 },
    { type: DiceType.NdsPositiveUpgrade, order: 385 },
    { type: DiceType.NdsPositiveIncrease, order: 390 },
    { type: DiceType.NdsNegativeUpgrade, order: 395 },
    { type: DiceType.NdsNegativeIncrease, order: 400 },
    // Force dice supported in both eote and genesys systems
    { type: DiceType.EoteForce, order: 500 },
    { type: DiceType.EoteLightSide, order: 600 },
    { type: DiceType.EoteDarkSide, order: 610 },
    { type: DiceType.EoteLightDarkSplit, order: 620 },
    { type: DiceType.AGED6, order: 700 },
    { type: DiceType.AGEStunt, order: 705 },
    { type: DiceType.AGED3, order: 710 },
    { type: DiceType.D4, order: 1010 },
    { type: DiceType.D6, order: 1020 },
    { type: DiceType.D8, order: 1030 },
    { type: DiceType.D10, order: 1040 },
    { type: DiceType.D12, order: 1050 },
    { type: DiceType.D20, order: 1060 },
    { type: DiceType.D100, order: 1070 },
    { type: DiceType.Percentile, order: 1080 },
    { type: DiceType.Polyhedral, order: 10000 },
    { type: DiceType.Modifier, order: 10010 },
  ];

  export const eoteDiceVisible: DiceType[] = [
    DiceType.EoteProficiency,
    DiceType.EoteAbility,
    DiceType.EoteBoost,
    DiceType.EoteChallenge,
    DiceType.EoteDifficulty,
    DiceType.EoteSetback,
    DiceType.EoteForce,
  ];

  export const eoteModifiers: DiceType[] = [
    DiceType.EoteTriumph,
    DiceType.EoteSuccess,
    DiceType.EoteAdvantage,
    DiceType.EoteDespair,
    DiceType.EoteFailure,
    DiceType.EoteThreat,
    DiceType.EoteLightSide,
    DiceType.EoteDarkSide,
  ];

  export const eoteDice: DiceType[] = [
    DiceType.EoteRemoveSetback,
    DiceType.EotePositiveUpgrade,
    DiceType.EoteNegativeUpgrade,
    ...DiceType.eoteDiceVisible,
    ...DiceType.eoteModifiers,
  ];

  export const genesysDiceVisible: DiceType[] = [
    DiceType.GenesysProficiency,
    DiceType.GenesysAbility,
    DiceType.GenesysBoost,
    DiceType.GenesysChallenge,
    DiceType.GenesysDifficulty,
    DiceType.GenesysSetback,
  ];

  export const genesysDiceWithForce: DiceType[] = [
    ...DiceType.genesysDiceVisible,
    DiceType.EoteForce,
  ];

  export const genesysModifiers: DiceType[] = [
    DiceType.GenesysTriumph,
    DiceType.GenesysSuccess,
    DiceType.GenesysAdvantage,
    DiceType.GenesysDespair,
    DiceType.GenesysFailure,
    DiceType.GenesysThreat,
  ];

  export const genesysDice: DiceType[] = [
    DiceType.GenesysRemoveSetback,
    DiceType.GenesysPositiveUpgrade,
    DiceType.GenesysNegativeUpgrade,
    ...DiceType.genesysDiceVisible,
    ...DiceType.genesysModifiers,
  ];

  export const ndsDice: DiceType[] = [
    DiceType.NdsProficiency,
    DiceType.NdsAbility,
    DiceType.NdsBoost,
    DiceType.NdsChallenge,
    DiceType.NdsDifficulty,
    DiceType.NdsSetback,
    DiceType.NdsRemoveSetback,
    DiceType.NdsTriumph,
    DiceType.NdsSuccess,
    DiceType.NdsAdvantage,
    DiceType.NdsDespair,
    DiceType.NdsFailure,
    DiceType.NdsThreat,
    DiceType.NdsForce,
    DiceType.NdsLightSide,
    DiceType.NdsDarkSide,
    DiceType.NdsLightDarkSplit,
    DiceType.NdsPositiveUpgrade,
    DiceType.NdsPositiveIncrease,
    DiceType.NdsNegativeUpgrade,
    DiceType.NdsNegativeIncrease,
  ];

  export const ndsForceDice: DiceType[] = [
    DiceType.NdsForce,
    DiceType.NdsLightSide,
    DiceType.NdsDarkSide,
    DiceType.NdsLightDarkSplit,
  ];

  export const ageDice: DiceType[] = [DiceType.AGED3, DiceType.AGED6, DiceType.AGEStunt];

  export const numberedDiceVisible: DiceType[] = [
    DiceType.D4,
    DiceType.D6,
    DiceType.D8,
    DiceType.D10,
    DiceType.D12,
    DiceType.D20,
    DiceType.Percentile,
  ];

  export const numberedDice: DiceType[] = [...DiceType.numberedDiceVisible];

  export const members: DiceType[] = [
    ...DiceType.numberedDice,
    ...DiceType.eoteDice,
    ...DiceType.genesysDice,
    ...DiceType.ageDice,
    ...DiceType.ndsDice,
    DiceType.Polyhedral,
    DiceType.Modifier,
  ];

  /* eslint-disable no-magic-numbers */
  export function getSides(type: DiceType): number {
    switch (type) {
      case DiceType.D100:
        return 100;
      case DiceType.D20:
        return 20;
      case DiceType.EoteProficiency:
      case DiceType.EoteChallenge:
      case DiceType.EoteForce:
      case DiceType.GenesysProficiency:
      case DiceType.GenesysChallenge:
      case DiceType.NdsProficiency:
      case DiceType.NdsChallenge:
      case DiceType.NdsForce:
      case DiceType.D12:
        return 12;
      case DiceType.D10:
      case DiceType.Percentile:
        return 10;
      case DiceType.EoteAbility:
      case DiceType.EoteDifficulty:
      case DiceType.GenesysAbility:
      case DiceType.GenesysDifficulty:
      case DiceType.NdsAbility:
      case DiceType.NdsDifficulty:
      case DiceType.D8:
        return 8;
      case DiceType.EoteBoost:
      case DiceType.EoteSetback:
      case DiceType.GenesysBoost:
      case DiceType.GenesysSetback:
      case DiceType.NdsBoost:
      case DiceType.NdsSetback:
      case DiceType.AGED6:
      case DiceType.AGEStunt:
      case DiceType.D6:
        return 6;
      case DiceType.D4:
        return 4;
      case DiceType.AGED3:
        return 3;
      default:
        return 1;
    }
  }
}

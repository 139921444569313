import { unreachable } from '@rpg/core/base';
import { NdsVehicleAttribute } from './nds-vehicle-attribute';

export enum NdsVehicleDefenseZone {
  Fore = '[nds vehicle defense zone] fore',
  Port = '[nds vehicle defense zone] port',
  Starboard = '[nds vehicle defense zone] starboard',
  Aft = '[nds vehicle defense zone] aft',
}

export namespace NdsVehicleDefenseZone {
  export function translationKey(type: NdsVehicleDefenseZone): string {
    return `enums.vehicle.nds.defense-zone.${type}`;
  }

  export const members: NdsVehicleDefenseZone[] = [
    NdsVehicleDefenseZone.Fore,
    NdsVehicleDefenseZone.Port,
    NdsVehicleDefenseZone.Starboard,
    NdsVehicleDefenseZone.Aft,
  ];

  export function toAttribute(type: NdsVehicleDefenseZone): NdsVehicleAttribute {
    switch (type) {
      case NdsVehicleDefenseZone.Fore:
        return NdsVehicleAttribute.DefenseFore;
      case NdsVehicleDefenseZone.Port:
        return NdsVehicleAttribute.DefensePort;
      case NdsVehicleDefenseZone.Starboard:
        return NdsVehicleAttribute.DefenseStarboard;
      case NdsVehicleDefenseZone.Aft:
        return NdsVehicleAttribute.DefenseAft;
      default:
        return unreachable(type, 'NdsVehicleDefenseZone.toAttribute');
    }
  }

  export const defenseOrder: { type: NdsVehicleDefenseZone; order: number }[] = [
    { type: NdsVehicleDefenseZone.Fore, order: 1 },
    { type: NdsVehicleDefenseZone.Port, order: 2 },
    { type: NdsVehicleDefenseZone.Starboard, order: 3 },
    { type: NdsVehicleDefenseZone.Aft, order: 4 },
  ];

  export function sort(a: NdsVehicleDefenseZone, b: NdsVehicleDefenseZone): number {
    const typeA = NdsVehicleDefenseZone.defenseOrder.find(d => d.type === a);
    const typeB = NdsVehicleDefenseZone.defenseOrder.find(d => d.type === b);
    if (!typeA || !typeB) {
      return 0;
    } else if (typeA.order > typeB.order) {
      return 1;
    } else if (typeB.order > typeA.order) {
      return -1;
    } else {
      return 0;
    }
  }
}

type LogLevel = 'debug' | 'warn' | 'error' | 'none';

const LogLevelKey = {
  none: 0,
  error: 1,
  warn: 2,
  debug: 3,
};

export class Logger {
  private static _logLevel: number = LogLevelKey.none;

  public static log(...args: any[]): void {
    if (Logger._logLevel < LogLevelKey.debug) return;
    console.log(...args);
  }

  public static warn(...args: any[]): void {
    if (Logger._logLevel < LogLevelKey.warn) return;
    console.warn(...args);
  }

  public static error(...args: any[]): void {
    if (Logger._logLevel < LogLevelKey.error) return;
    console.error(...args);
  }

  public static table(...args: any[]): void {
    if (Logger._logLevel < LogLevelKey.debug) return;
    console.table(...args);
  }

  public static setLevel(level: LogLevel): void {
    Logger._logLevel = LogLevelKey[level] ?? 0;
  }
}

import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl, FormArray, FormControl } from '@ngneat/reactive-forms';
import { Obj } from '@ngneat/reactive-forms/lib/types';
import { PropType } from '@rpg/core/base';
import {
  Observable,
  Subject,
  EMPTY,
  startWith,
  debounceTime,
  take,
  BehaviorSubject,
  filter,
} from 'rxjs';
import { formTraversal, getLookupKey } from '../functions';

interface ValueChangesOptions {
  startWithCurrentValue: boolean;
  debounce: boolean;
}

const valueChangesDefaults: ValueChangesOptions = {
  startWithCurrentValue: true,
  debounce: true,
};

@Injectable()
export class FormManager<FormShape extends Obj = any> {
  private _rootFormSubject: Subject<FormGroup<FormShape> | null> = new Subject();
  private _rootFormConnectNotifier = new BehaviorSubject<boolean>(false);
  private _rootForm: FormGroup<FormShape> | null = null;
  private _defaultArrayLookupKey: string = 'id';

  public rootForm$ = this._rootFormSubject.asObservable();

  public get onRootFormConnect$() {
    return this._rootFormConnectNotifier.asObservable().pipe(
      filter(connected => connected === true),
      take(1)
    );
  }

  public set rootForm(form: FormGroup) {
    this._rootForm = form;
    this._rootFormSubject.next(form);
    this._rootFormConnectNotifier.next(true);
  }

  public get rootForm(): FormGroup {
    if (!this._rootForm) {
      throw new Error('Missing root form element. Have you attached one first?');
    }
    return this._rootForm;
  }

  public get hasAttachedForm(): boolean {
    return !!this._rootForm;
  }

  public set defaultArrayLookupKey(newKey: string) {
    this._defaultArrayLookupKey = newKey;
  }

  public traverse<T extends AbstractControl = FormControl>(path: string): T {
    const { targetControl } = formTraversal<T>(this.rootForm, path, this._defaultArrayLookupKey);
    return targetControl;
  }

  public value<T = FormShape>(): T;
  public value<Key extends keyof FormShape>(path: Key): PropType<FormShape, Key>;
  public value<T = any>(path: string): T | null;
  public value<T = any>(path?: string): T | null {
    let val: T | null = null;
    this.valueChanges(path)
      .pipe(take(1))
      .subscribe(res => (val = res));
    return val;
  }

  public valueChanges<T = any>(
    path?: string,
    options: Partial<ValueChangesOptions> = {}
  ): Observable<T> {
    options = {
      ...valueChangesDefaults,
      ...options,
    };
    const { targetControl } = formTraversal<FormGroup<any>>(
      this.rootForm,
      path,
      this._defaultArrayLookupKey
    );
    if (!!targetControl) {
      const operators = [];
      // ORDER MATTERS!!!
      if (options.debounce) {
        const time = 350;
        operators.push(debounceTime(time));
      }
      if (options.startWithCurrentValue) {
        operators.push(startWith(targetControl.value));
      }
      if (operators.length < 1) {
        return targetControl.valueChanges as Observable<T>;
      } else {
        return operators.reduce(
          (obs: Observable<any>, nextOp) => obs.pipe(nextOp),
          targetControl.valueChanges
        ) as Observable<T>;
      }
    } else {
      return EMPTY;
    }
  }

  public clear(): void {
    this._rootForm = null;
    this._rootFormSubject.next(null);
  }

  public addControl({
    formKey,
    control,
    newFieldKey,
    sortArrayInsert,
  }: {
    formKey: string;
    control: AbstractControl;
    newFieldKey?: string;
    sortArrayInsert?: {
      keyLookup: (c: AbstractControl) => string;
      direction: 'asc' | 'desc';
    };
  }): void {
    if (!this._rootForm) {
      throw new Error(`Root Form Group must be configured before adding controls`);
    }
    const keys = formKey.split('.');
    const { previousControl, targetControl } = formTraversal(
      this._rootForm,
      formKey,
      this._defaultArrayLookupKey
    );
    if (targetControl instanceof FormArray) {
      if (!sortArrayInsert) {
        targetControl.push(control);
      } else {
        const arrayKeys = Object.keys(targetControl.controls);
        let indexToInsert = -1;
        for (let idx = 0; idx < arrayKeys.length; idx++) {
          const currentControl = targetControl.controls[arrayKeys[idx] as any];
          const currentKey = sortArrayInsert.keyLookup(currentControl);
          const insertKey = sortArrayInsert.keyLookup(control);
          if (sortArrayInsert.direction === 'desc') {
            if (currentKey > insertKey) {
              indexToInsert = idx;
              break;
            }
          } else {
            if (currentKey < insertKey) {
              indexToInsert = idx;
              break;
            }
          }
        }
        if (indexToInsert > -1) {
          targetControl.insert(indexToInsert, control);
        } else {
          targetControl.push(control);
        }
      }
    } else if (targetControl instanceof FormGroup) {
      if (!newFieldKey) {
        throw new Error('Cannot add a control to FormGroup without a newFieldKey');
      }
      targetControl.removeControl(newFieldKey);
      targetControl.addControl(newFieldKey, control);
    } else if (targetControl instanceof FormControl) {
      const lastKey = keys[keys.length - 1];
      (previousControl as FormGroup).removeControl(lastKey);
      (previousControl as FormGroup).addControl(lastKey, control);
    } else {
      throw new Error(
        `Target Control type is invalid: ${
          targetControl?.['constructor']?.name ?? typeof targetControl
        }`
      );
    }
  }

  public replaceControl({ formKey, control }: { formKey: string; control: AbstractControl }): void {
    if (!this._rootForm) {
      throw new Error(`Root Form Group must be configured before replacing controls`);
    }

    const keys = formKey.split('.');
    if (keys.length === 1) {
      // Root form element
      this._rootForm.removeControl(keys[0] as any);
      this._rootForm.addControl(keys[0] as any, control);
      return;
    }

    const { previousControl, targetControl } = formTraversal(
      this._rootForm,
      formKey,
      this._defaultArrayLookupKey
    );

    if (!previousControl) {
      throw new Error(`No control found for parent of key: ${formKey}`);
    }
    if (previousControl instanceof FormControl) {
      throw new Error(`Invalid target. FormControl found in key path: ${formKey}`);
    }
    if (!targetControl) {
      throw new Error(`No control found at key: ${formKey}`);
    }

    const lastKey = keys[keys.length - 1];
    if (previousControl instanceof FormArray) {
      // Replace in array
      const parentControl = previousControl as FormArray;
      const [lookupKey, key] = getLookupKey(lastKey);
      const controlIdx = parentControl.controls.findIndex(
        control => control.get(lookupKey)?.value === key
      );
      if (controlIdx > -1) {
        parentControl.removeAt(controlIdx);
        parentControl.insert(controlIdx, control);
      }
      return;
    } else {
      // Replace in group
      const parentControl = previousControl as FormGroup;
      parentControl.removeControl(lastKey);
      parentControl.addControl(lastKey, control);
      return;
    }
  }

  public removeControl(path: string): void {
    if (!this._rootForm) {
      throw new Error(`Root Form Group must be configured before removing controls`);
    }

    const keys = path.split('.');
    if (keys.length === 1) {
      this._rootForm.removeControl(keys[0] as any);
      return;
    }

    const { previousControl } = formTraversal(this._rootForm, path, this._defaultArrayLookupKey);

    if (!previousControl) {
      throw new Error(`No control found at key: ${previousControl}`);
    }
    if (previousControl instanceof FormControl) {
      throw new Error(`Invalid target. FormControl found at key: ${previousControl}`);
    }

    const lastKey = keys[keys.length - 1];
    if (previousControl instanceof FormGroup) {
      previousControl.removeControl(lastKey);
    } else if (previousControl instanceof FormArray) {
      const [lookupKey, parsedKey] = getLookupKey(lastKey);
      const controlIndex = previousControl.controls.findIndex(
        control => control.get(lookupKey)?.value === parsedKey
      );
      if (controlIndex < 0) {
        throw new Error(`Invalid target. No control found at key: ${path}`);
      }
      previousControl.removeAt(controlIndex);
    }
  }
}

import { Injectable } from '@angular/core';
import { Position2DGridManager, Position2DNodeRef } from '@rpg/core/base';
import { NdsCharacterTalentData } from '@rpg/core/character';
import { RxState } from '@rx-angular/state';

interface PyramidState {
  talents: Position2DNodeRef[];
  editMode: boolean;
  talentSlotRef: { [key: string]: NdsCharacterTalentData };
  maxRowIndexToRender: number;
  forceMobile: boolean;
  selectedTalentData: Position2DNodeRef | undefined;
}

const MAX_MOBILE_WIDTH = 767;

@Injectable()
export class TalentPyramidLayoutState extends RxState<PyramidState> {
  // We only want to check once for the mobile scaling, this is not responsive to changes
  private _mobileScalingChecked: boolean = false;
  private _inMobile: boolean = false;
  private _lastGridY: number = 0;
  private _positionManager!: Position2DGridManager;
  private _talentData: NdsCharacterTalentData[] = [];

  public state$ = this.select();

  constructor() {
    super();
    this.set({
      forceMobile: false,
      selectedTalentData: undefined,
    });
  }

  public set editMode(editMode: boolean) {
    this.set({ editMode });
  }

  public set talents(talents: NdsCharacterTalentData[]) {
    this._talentData = talents;
    this._rebuildSlotRefs();
  }

  public set positionManager(positionManager: Position2DGridManager) {
    this._positionManager = positionManager;
    this._recalculateTalents();
  }

  public set forceMobile(forceMobile: boolean) {
    this.set({ forceMobile });
  }

  public isMobile(): boolean {
    if (this._mobileScalingChecked) return this._inMobile;

    this._mobileScalingChecked = true;
    const { forceMobile } = this.get();
    this._inMobile = forceMobile;
    const currentWidth = window?.innerWidth ?? -1;

    // No window size available, SSR maybe? default to desktop unless otherwise asked
    if (currentWidth === -1) return this._inMobile;
    if (currentWidth <= MAX_MOBILE_WIDTH) {
      // If we have a width, and it's the mobile width of smaller, use mobile
      this._inMobile = true;
      return this._inMobile;
    }
    // InMobile defaults to false (aka, Desktop), but it's also been adjusted in the case
    // of mobile styling being forced, so we should rely on that logic here.
    return this._inMobile;
  }

  public mobileSelect(nodeRef: Position2DNodeRef): void {
    this.set({
      selectedTalentData: nodeRef,
    });
  }

  public clearMobileSelection(): void {
    this.set({
      selectedTalentData: undefined,
    });
  }

  private _rebuildSlotRefs(): void {
    // eslint-disable-next-line prefer-const
    let { talentSlotRef, selectedTalentData } = this.get();
    if (!talentSlotRef) {
      talentSlotRef = {};
    }
    let selectedTalentExists = false;
    this._talentData.forEach(t => {
      const tId = t.id;
      talentSlotRef[tId] = t;
      if (!!selectedTalentData && selectedTalentData.linkedId === tId) {
        selectedTalentExists = true;
      }
    });
    this.set({
      talentSlotRef,
      selectedTalentData: selectedTalentExists ? selectedTalentData : undefined,
    });
    if (!!this._positionManager) {
      if (this._lastGridY !== this._positionManager.height) {
        this._recalculateTalents();
      }
    }
  }

  private _recalculateTalents(): void {
    if (!this._positionManager) return;
    // Manage Grid size to always include nodes for at least 1 height bigger
    // than node position that we have
    // We have to adjust the height value because it is not 0-based
    const adjustedHeight = this._positionManager.height - 1;
    if (adjustedHeight <= this._positionManager.highestNodeYPosition) {
      // We need to grow our grid to 1 more than the max size
      this._positionManager.adjustGridSize({
        height: this._positionManager.highestNodeYPosition - adjustedHeight + 1,
      });
    }
    this.set({
      talents: this._positionManager.asArray,
      maxRowIndexToRender: this._getMaxRowIndexToRender(this._positionManager),
    });
  }

  private _getMaxRowIndexToRender(pm: Position2DGridManager): number {
    let maxIndex = 0;
    pm.nodes.forEach(node => {
      if (!node.hidden && node.position.y > maxIndex) {
        maxIndex = node.position.y;
      }
    });

    // We add one to the maxIndex since we always render at least 1 row beyond where the data is
    return maxIndex + 1;
  }
}

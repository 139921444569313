export enum NdsCharacterFavorSize {
  Small = '[nds character favor size] small',
  Regular = '[nds character favor size] regular',
  Big = '[nds character favor size] big',
}

export namespace NdsCharacterFavorSize {
  export function translationKey(type: NdsCharacterFavorSize): string {
    return `enums.character.nds.favor-size.${type}`;
  }

  export function toString(type: NdsCharacterFavorSize): string {
    switch (type) {
      case NdsCharacterFavorSize.Small:
        return 'Small';
      case NdsCharacterFavorSize.Regular:
        return 'Regular';
      case NdsCharacterFavorSize.Big:
        return 'Big';
      default:
        return '';
    }
  }

  export const members: NdsCharacterFavorSize[] = [
    NdsCharacterFavorSize.Small,
    NdsCharacterFavorSize.Regular,
    NdsCharacterFavorSize.Big,
  ];
}

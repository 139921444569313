import { Injectable } from '@angular/core';
import { HttpService, Get, Path, MapValue, Post, Body, Put } from '@rpg/ngx/http';
import { EMPTY, Observable } from 'rxjs';
import { ObjectId } from 'bson';
import {
  BaseVehicle,
  mapVehicleToClass,
  NdsVehicleAttribute,
  NdsVehicleCharacteristic,
} from '@rpg/core/vehicle';

interface VehicleUpdate<T> {
  type: T;
  value: number;
  gameId: ObjectId;
}

@Injectable({
  providedIn: 'root',
})
export class VehicleHttpService<
  VehicleClass extends BaseVehicle = BaseVehicle
> extends HttpService {
  @Get('/vehicle/:id')
  @MapValue(mapVehicleToClass)
  public getVehicleById<T extends VehicleClass = VehicleClass>(
    @Path('id') vehicleId: ObjectId
  ): Observable<T> {
    return EMPTY;
  }

  @Post('/vehicle')
  @MapValue(mapVehicleToClass)
  public createVehicle(
    @Body('vehicle') vehicleData: VehicleClass,
    @Body('folderId') folderId: string | ObjectId
  ): Observable<VehicleClass> {
    return EMPTY;
  }

  @Put('/vehicle/:id')
  public updateVehicleById(
    @Path('id') vehicleId: ObjectId,
    @Body() vehicleData: VehicleClass
  ): Observable<VehicleClass> {
    return EMPTY;
  }

  @Post('/vehicle/:id/clone')
  public cloneVehicle(@Path('id') vehicleId: ObjectId): Observable<ObjectId> {
    return EMPTY;
  }

  @Get('/vehicle/:id/isUserGM')
  public isUserGMOfVehicle(@Path('id') vehicleId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Get('/vehicle/game/:id/options')
  @MapValue((res: { partyVehicle?: VehicleClass; userOwned: VehicleClass[] }) => ({
    partyVehicle: !!res.partyVehicle ? mapVehicleToClass(res.partyVehicle) : undefined,
    userOwned: Array.isArray(res.userOwned) ? res.userOwned.map(u => mapVehicleToClass(u)) : [],
  }))
  public getGameVehicleOptions(
    @Path('id') gameId: ObjectId
  ): Observable<{ partyVehicle?: VehicleClass; userOwned: VehicleClass[] }> {
    return EMPTY;
  }

  @Put('/vehicle/:id/attribute')
  public updateVehicleAttribute(
    @Path('id') vehicleId: ObjectId,
    @Body() params: VehicleUpdate<NdsVehicleAttribute>
  ): Observable<void> {
    return EMPTY;
  }

  @Put('/vehicle/:id/characteristic')
  public updateVehicleCharacteristic(
    @Path('id') vehicleId: ObjectId,
    @Body() params: VehicleUpdate<NdsVehicleCharacteristic>
  ): Observable<void> {
    return EMPTY;
  }
}

export enum DiscordServerStructure {
  BaseGame = '[discord server structure] base game',
  ChildGame = '[discord server structure] child game',
}

export namespace DiscordServerStructure {
  export function toString(type: DiscordServerStructure): string {
    switch (type) {
      case DiscordServerStructure.BaseGame:
        return 'Single Game';
      case DiscordServerStructure.ChildGame:
        return 'Multi Game';
      default:
        return '';
    }
  }

  export const members: DiscordServerStructure[] = [
    DiscordServerStructure.BaseGame,
    DiscordServerStructure.ChildGame,
  ];
}

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslocoService } from '@ngneat/transloco';
import { Logger, RpgSelectOption, DiceTheme } from '@rpg/core/base';
import { MarkdownService } from 'ngx-markdown';
import { Subject, takeUntil } from 'rxjs';
import { MarkdownConfigService } from '@rpg/ngx/core';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'rpg-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements AfterContentInit, OnDestroy, ControlValueAccessor {
  @Input()
  public markdownLabel: boolean = false;
  @Input()
  public placeholder: string = '';
  @Input()
  public type: string = 'text';
  @Input()
  public inputControl: AbstractControl = new UntypedFormControl('');
  @Input()
  public panelClass: string | string[] = '';
  private _options: RpgSelectOption[] = [];
  @Input()
  public set options(value: RpgSelectOption[]) {
    this._options = value;
    this.inputControl?.updateValueAndValidity();
    this._cd.markForCheck();
  }
  public get options(): RpgSelectOption[] {
    return this._options;
  }
  @Input()
  public prefixIconName: IconProp | null = null;
  @Input()
  public suffixIconName: IconProp | null = null;
  @Input()
  public read: string = '';
  @Input()
  public noTranslation: boolean = false;
  private _disabled: boolean = false;
  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    Logger.log('SET DISABLED', value);
    this.setDisabledState(value);
  }
  @Input()
  public multiple: boolean = false;
  @Input()
  public markdownDiceTheme: DiceTheme = DiceTheme.Generic;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  public change: EventEmitter<any> = new EventEmitter<any>();
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  public get disabled(): boolean {
    return this._disabled;
  }

  @ViewChild(FormFieldComponent)
  public formField!: FormFieldComponent;

  private _unsub: Subject<void> = new Subject<void>();

  constructor(
    @Optional() public fg: FormGroupDirective,
    private _cd: ChangeDetectorRef,
    private _markdown: MarkdownService,
    private _markdownConfig: MarkdownConfigService,
    private _translocoService: TranslocoService
  ) {}

  ngAfterContentInit(): void {
    if (!!this.fg) {
      this.fg.ngSubmit.pipe(takeUntil(this._unsub)).subscribe(event => {
        this.formField._checkForChanges();
      });
    }
    this.setDisabledState(this.disabled);
  }

  ngOnDestroy(): void {
    this._unsub.next();
    this._unsub.complete();
  }

  public __parseLabel(l: string): string {
    return this._markdownConfig._diceReplacement(l, this.markdownDiceTheme);
  }

  public get control(): UntypedFormControl {
    return this.inputControl as UntypedFormControl;
  }

  public onTouched: () => void = () => {};

  public writeValue(val: any): void {
    this.inputControl.setValue(val);
  }

  public registerOnChange(fn: any): void {
    this.inputControl.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    Logger.log('SET DISABLED STATE', isDisabled);
    // isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  public getTranslatedInput(label: string): string {
    // Intentionally not ignoring empty reads right now to help identify
    // select boxes that haven't been changed to use i18n
    if (this.noTranslation) {
      return label;
    }
    // If no read is passed in, check the format for possible translationKey

    if (this.read) {
      const res = this._translocoService.translate<string>(`${this.read}.${label}`);
      if (!res.includes(this.read)) {
        return res;
      }
    }
    return this._translocoService.translate(label);
  }

  public getLabel(value: any): string {
    return this.options.find(x => x.value === value)?.label || '';
  }
}

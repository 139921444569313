import { User } from '@rpg/core/user';

export namespace UserActions {
  export class Clear {
    static readonly type = '[User] Clear';
  }
  export class Set {
    static readonly type = '[User] Set';
    constructor(public user: User) {}
  }
  export class Update {
    static readonly type = '[User] Update';
    constructor(public user: Partial<User>) {}
  }

  export class Restore {
    static readonly type = '[User] Restore';
  }

  export class NewLogin {
    static readonly type = '[User] New Login';
  }
}

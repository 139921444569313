export class GameNPCVisibility {
  public isVisible: boolean = false;
  public name: boolean = false;
  public stats: boolean = false;
  public crits: boolean = false;
  public image: boolean = false;
  public adversary: boolean = false;

  constructor(params?: Partial<GameNPCVisibility>) {
    if (!!params) {
      this.isVisible = params.isVisible ?? this.isVisible;
      this.name = params.name ?? this.name;
      this.stats = params.stats ?? this.stats;
      this.crits = params.crits ?? this.crits;
      this.image = params.image ?? this.image;
      this.adversary = params.adversary ?? this.adversary;
    }
    if (this.name || this.stats || this.crits || this.image || this.adversary) {
      this.isVisible = true;
    }
  }

  public get isAllVisible(): boolean {
    return this.isVisible && this.name && this.stats && this.crits && this.image && this.adversary;
  }

  public static get AllVisible(): GameNPCVisibility {
    const result = new GameNPCVisibility({
      isVisible: true,
      name: true,
      stats: true,
      crits: true,
      image: true,
      adversary: true,
    });
    return result;
  }

  public static get AllHidden(): GameNPCVisibility {
    const result = new GameNPCVisibility({
      isVisible: false,
      name: false,
      stats: false,
      crits: false,
      image: false,
      adversary: false,
    });
    return result;
  }
}

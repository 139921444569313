import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsVehicleModifierAttributeData } from '@rpg/core/vehicle';
import { ndsVehicleModifierBaseToFormGroup } from './nds-vehicle-modifier-base-to-form-group';

export function ndsVehicleModifierAttributeToFormGroup(
  mod: NdsVehicleModifierAttributeData
): FormGroup<NdsVehicleModifierAttributeData> {
  return new FormGroup<NdsVehicleModifierAttributeData>({
    ...ndsVehicleModifierBaseToFormGroup(mod).controls,
    attribute: new FormControl(mod.attribute, [Validators.required]),
    modifierAmount: new FormControl(mod.modifierAmount),
  });
}

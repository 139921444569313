import { RpgClassValidators } from '@rpg/core/base';
import { NdsVehicleDetail } from '../enums';

export class NdsVehicleDetailData {
  public type!: NdsVehicleDetail;
  public value: string = '';

  constructor(params?: Partial<NdsVehicleDetailData>) {
    if (!!params) {
      this.type = params.type ?? this.type;
      this.value = params.value ?? this.value;
    }
  }

  public static validate(input: NdsVehicleDetailData): string[] {
    const errors: string[] = [];
    errors.push(
      ...RpgClassValidators.enum(input.type, NdsVehicleDetail, {
        enumName: 'Detail Type',
      })
    );
    errors.push(...RpgClassValidators.string(input.value, { allowEmpty: true }));
    return errors;
  }
}

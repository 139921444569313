import { forEach, flattenDeep } from 'lodash';
import { Logger } from './utilities/logger';

export interface ParseUserOutput {
  [key: string]: number;
}

export function parseSingleParameterAsUserInput<T = ParseUserOutput>(
  param: string,
  inputOptions: { [P in keyof T]: string[] },
  depth: number = 0
): { [P in keyof T]: number } {
  const allOptions = flattenDeep<string>(Object.values(inputOptions));
  const numberRegex = /^\d+/;
  // Allow setting empty object to T
  const results: { [P in keyof T]: number } = {} as any;
  const maximumRecursionDepth = 100; // Just in case!
  const maximumNumberPrefix = 250; // For example, if somebody puts in 300r in the dice parser, limit it to 250

  if (depth > maximumRecursionDepth) {
    // Don't wanna accidentally end up in a stack overflow exception
    // Not sure how this could happen currently, but just being cautious
    Logger.log(
      `Hitting maximum recursion depth of (${maximumRecursionDepth})` + ` with input ${param}!`
    );
    return results;
  }

  let foundInRawForm = false;
  forEach(inputOptions, (values, key): boolean | void => {
    if (values.indexOf(param) !== -1) {
      results[key as keyof T] = 1;
      foundInRawForm = true;
      // Break out of forEach
      return false;
    }
  });

  if (foundInRawForm) {
    return results;
  }

  if (numberRegex.test(param)) {
    const matched = param.match(numberRegex);
    if (!matched) {
      return results;
    }
    const count = parseInt(matched[0], 10);
    if (isNaN(count)) {
      return results;
    }

    if (count > maximumNumberPrefix) {
      throw new Error(
        `Unable to process \`${param}\`. User input cannot exceed ${maximumNumberPrefix} entities.`
      );
    }

    const type = param.replace(numberRegex, '');
    // CAREFUL! Recursion ahead!
    const tempResults = parseSingleParameterAsUserInput<T>(type, inputOptions, ++depth);
    if (Object.keys(tempResults).length === 0) {
      return results;
    }

    // Multiply each element by the count and put them in the results array
    let firstKey = true;
    Object.keys(tempResults).forEach(key => {
      if (firstKey) {
        results[key as keyof T] = tempResults[key as keyof T] - 1 + count;
        firstKey = false;
      } else {
        results[key as keyof T] = tempResults[key as keyof T];
      }
    });
  } else {
    // Try to parse it as if everything is missing spaces
    let currentParam = param;
    while (currentParam.length !== 0) {
      let foundOption = false;
      // Loop through all options and check to see if the current parameter
      // starts with one of them
      forEach(allOptions, (option): boolean | void => {
        if (currentParam.indexOf(option) === 0) {
          // Find which type matched
          forEach(inputOptions, (values, key): boolean | void => {
            if (values.indexOf(option) !== -1) {
              if (!results[key as keyof T]) {
                results[key as keyof T] = 1;
              } else {
                results[key as keyof T]++;
              }

              // Break out of forEach
              return false;
            }
          });

          foundOption = true;
          // Remove the found option from the beginning of the parameter
          currentParam = currentParam.substr(option.length);

          // Break out of the current forEach loop. If we don't do this
          // we could end up breaking up the text in a way that doesn't
          // make sense.
          // Ex: gmgmgm could become mgmgmgm because it could match gm
          // then continue to match g. By breaking out here, we're forcing
          // it to start searching from the beginning after every match
          return false;
        }
      });

      if (numberRegex.test(currentParam)) {
        // Some more recursion for ya
        const tempResults = parseSingleParameterAsUserInput(currentParam, inputOptions, ++depth);

        forEach(tempResults, (val, key) => {
          if (!results[key as keyof T]) {
            results[key as keyof T] = 0;
          }

          results[key as keyof T] += val;
        });

        // Break out of the for loop, the recursive call will
        // handle everything else
        break;
      } else if (!foundOption) {
        // Couldn't parse input string
        Logger.error("Couldn't parse input string:", currentParam);

        throw new Error(`Unable to match input string: ${currentParam}`);
      }
    }
  }

  return results;
}

export function parseUserInput<T = ParseUserOutput>(
  input: string,
  inputOptions: { [P in keyof T]: string[] }
): { [P in keyof T]: number } {
  const splitInput = input.split(' ');
  const results: { [P in keyof T]: number } = {} as any;
  Object.keys(inputOptions).forEach(key => {
    // Default everything to 0
    results[key as keyof T] = 0;
  });

  splitInput.forEach(param => {
    const res = parseSingleParameterAsUserInput<T>(param, inputOptions);
    Object.keys(res).forEach(key => {
      results[key as keyof T] += res[key as keyof T];
    });
  });

  return results;
}

import { RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterDetail } from '../enums';

export class NdsCharacterDetailData {
  public type!: NdsCharacterDetail;
  public value: string = '';

  constructor(params?: Partial<NdsCharacterDetailData>) {
    if (!!params) {
      this.type = params.type ?? this.type;
      this.value = params.value ?? this.value;
    }
  }

  public static validate(input: NdsCharacterDetailData): string[] {
    const errors: string[] = [];
    errors.push(
      ...RpgClassValidators.enum(input.type, NdsCharacterDetail, {
        enumName: 'Detail Type',
        fieldName: `${input.type}`,
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.value, { allowEmpty: true, fieldName: `${input.type}` })
    );
    return errors;
  }
}

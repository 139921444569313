// Defines sort for Games
export enum GameSort {
  Name = 'name',
  Newest = 'new',
  Oldest = 'old',
  Modified = 'modified',
}

export namespace GameSort {
  export const defaultSort: GameSort = GameSort.Newest;

  export const members: GameSort[] = [
    GameSort.Name,
    GameSort.Newest,
    GameSort.Oldest,
    GameSort.Modified,
  ];

  export function translationKey(type: GameSort): string {
    return `enums.game.sort.${type}`;
  }

  export function toString(type: GameSort): string {
    switch (type) {
      case GameSort.Name:
        return 'Name';
      case GameSort.Newest:
        return 'Newest';
      case GameSort.Oldest:
        return 'Oldest';
      case GameSort.Modified:
        return 'Modified';
      default:
        return '';
    }
  }

  export function fromString(type: string): GameSort {
    switch (type) {
      case 'name':
        return GameSort.Name;
      case 'new':
        return GameSort.Newest;
      case 'old':
        return GameSort.Oldest;
      case 'modified':
        return GameSort.Modified;
      default:
        return GameSort.Name;
    }
  }
}

import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterModifierCharacteristicData } from '@rpg/core/character';
import { ndsCharacterModifierBaseToFormGroup } from './nds-character-modifier-base-to-form-group';

export function ndsCharacterModifierCharacteristicToFormGroup(
  mod: NdsCharacterModifierCharacteristicData
): FormGroup<NdsCharacterModifierCharacteristicData> {
  return new FormGroup<NdsCharacterModifierCharacteristicData>({
    ...ndsCharacterModifierBaseToFormGroup(mod).controls,
    characteristic: new FormControl(mod.characteristic, [Validators.required]),
    modifierAmount: new FormControl(mod.modifierAmount),
  });
}

export enum DataType {
  Character = '[dataType] character',
  Folder = '[dataType] folder',
  Game = '[dataType] game',
  Vehicle = '[dataType] vehicle',
}

export namespace DataType {
  export function translationKey(type: DataType): string {
    return `enums.core.data-type.${type}`;
  }

  export const members: DataType[] = [
    DataType.Character,
    DataType.Folder,
    DataType.Game,
    DataType.Vehicle,
  ];
}

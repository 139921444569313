import { Injectable } from '@angular/core';
import { Body, Get, HttpService, Post, Query } from '@rpg/ngx/http';
import { ObjectId } from 'bson';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminHttpService extends HttpService {
  @Get('/admin/userLookup')
  public userLookup(
    @Query('lookupType') lookupType: string,
    @Query('lookupValue') lookupValue: string
  ): Observable<any[]> {
    return EMPTY;
  }

  @Get('/admin/characterList')
  public characterListLookup(@Query('id') userId: ObjectId): Observable<any> {
    return EMPTY;
  }

  @Get('/admin/character')
  public characterLookup(@Query('id') id: ObjectId): Observable<any[]> {
    return EMPTY;
  }

  @Get('/admin/uploads')
  public uploadLookup(@Query('userId') userId: ObjectId): Observable<any[]> {
    return EMPTY;
  }

  @Post('/admin/uploads')
  public setCurrentMonthUploadValue(
    @Body('id') id: ObjectId,
    @Body('newValue') newValue: number
  ): Observable<boolean> {
    return EMPTY;
  }

  @Post('/admin/userOverrides')
  public userOverrides(
    @Body('userId') userId: ObjectId,
    @Body() overrides: { patreonTier: string }
  ): Observable<any> {
    return EMPTY;
  }
}

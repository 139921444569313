import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'rpg-well',
  templateUrl: './well.component.html',
  styleUrls: ['./well.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellComponent {
  constructor() {}
}

<div (rpgClickOutside)="onClickOutside()" [rpgClickOutsideEnabled]="hasFocus">
  <rpg-form-field>
    <fa-icon
      matPrefix
      class="prefix-icon"
      *ngIf="prefixIconName"
      [icon]="prefixIconName"
      (click)="prefixClick.emit()"
    ></fa-icon>
    <mat-label>{{ placeholder }}</mat-label>
    <textarea
      #textInput
      matInput
      (rpgSelectionStart)="_lastCursorPosition = $event"
      (focus)="setFocus(true)"
      [placeholder]="placeholder"
      [rows]="rows"
      [formControl]="control"
    ></textarea>
    <mat-error [rpgFormFieldError]="control"></mat-error>
    <fa-icon
      matSuffix
      class="suffix-icon"
      *ngIf="suffixIconName"
      (click)="suffixClick.emit()"
      [icon]="suffixIconName"
    ></fa-icon>
  </rpg-form-field>
  <ng-content select="rpg-dice-wheel"></ng-content>
  <span *ngIf="formatting" class="formatting" (click)="showFormattingHelp()"
    >Supports Formating <fa-icon icon="question-circle"></fa-icon
  ></span>
</div>

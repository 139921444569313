import { createId, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterHeroicAbilityLevel } from './nds-character-heroic-ability-level';
import { NdsCharacterHeroicAbilityUpgradeData } from './nds-character-heroic-ability-upgrade-data';

export class NdsCharacterHeroicAbilityData {
  public id: string = '';
  public name: string = '';
  public description: string = '';
  public origins: string = '';
  public base: NdsCharacterHeroicAbilityLevel = new NdsCharacterHeroicAbilityLevel();
  public improved: NdsCharacterHeroicAbilityLevel = new NdsCharacterHeroicAbilityLevel();
  public supreme: NdsCharacterHeroicAbilityLevel = new NdsCharacterHeroicAbilityLevel();
  public upgrades: NdsCharacterHeroicAbilityUpgradeData[] = [];

  constructor(params?: Partial<NdsCharacterHeroicAbilityData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.description = params.description ?? this.description;
      this.origins = params.origins ?? this.origins;
      this.base = !!params.base ? new NdsCharacterHeroicAbilityLevel(params.base) : this.base;
      this.improved = !!params.improved
        ? new NdsCharacterHeroicAbilityLevel(params.improved)
        : this.improved;
      this.supreme = !!params.supreme
        ? new NdsCharacterHeroicAbilityLevel(params.supreme)
        : this.supreme;
      this.upgrades = Array.isArray(params.upgrades)
        ? params.upgrades.map(m => new NdsCharacterHeroicAbilityUpgradeData(m))
        : this.upgrades;
    }
    if (!this.id) {
      this.id = createId();
    }
  }

  public static validate(input: NdsCharacterHeroicAbilityData): string[] {
    const errors: string[] = [];
    const name = `Heroic Ability ${input.name ? `(${input.name}) ` : ''}`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    errors.push(
      ...RpgClassValidators.string(input.origins, {
        allowEmpty: true,
        fieldName: `${name} Origins`,
      })
    );
    errors.push(...NdsCharacterHeroicAbilityLevel.validate(input.base, name));
    errors.push(...NdsCharacterHeroicAbilityLevel.validate(input.improved, name));
    errors.push(...NdsCharacterHeroicAbilityLevel.validate(input.supreme, name));
    errors.push(
      ...input.upgrades.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterHeroicAbilityUpgradeData.validate(next, name)],
        []
      )
    );
    return errors;
  }
}

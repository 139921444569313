/* eslint-disable no-magic-numbers */
export enum LoginType {
  Website = 1,
  Facebook = 2,
  Google = 3,
  Discord = 4,
  Patreon = 5,
}

export namespace LoginType {
  export function toString(type: LoginType): string {
    switch (type) {
      case LoginType.Website:
        return 'Website';
      case LoginType.Facebook:
        return 'Facebook';
      case LoginType.Google:
        return 'Google';
      case LoginType.Discord:
        return 'Discord';
      case LoginType.Patreon:
        return 'Patreon';
      default:
        return '';
    }
  }

  export const socialMembers: LoginType[] = [
    LoginType.Facebook,
    LoginType.Google,
    LoginType.Discord,
    LoginType.Patreon,
  ];

  export const members: LoginType[] = [LoginType.Website, ...LoginType.socialMembers];
}

import { Logger } from './logger';
import { normalizeString } from './normalize-string';

interface BooleanRules {
  isBooleanType: boolean;
  fieldName: string;
}

const defaultBooleanRules: BooleanRules = {
  isBooleanType: true,
  fieldName: '',
};

interface EnumRules {
  isEnumType: boolean;
  enumName: string;
  isArray: boolean;
  fieldName: string;
}

const defaultEnumRules: EnumRules = {
  isEnumType: true,
  enumName: 'Enum',
  isArray: false,
  fieldName: '',
};

interface NumberRules {
  isNumberType: boolean;
  min?: number;
  max?: number;
  fieldName: string;
}

const defaultNumberRules: NumberRules = {
  isNumberType: true,
  min: undefined,
  max: undefined,
  fieldName: '',
};

interface StringRules {
  isStringType: boolean;
  maxLength?: number;
  allowEmpty: boolean;
  fieldName: string;
}

const defaultStringRules: StringRules = {
  isStringType: true,
  maxLength: undefined,
  allowEmpty: false,
  fieldName: '',
};

function fieldNameOrInput(fieldName: string, input: string | number): string {
  return `${fieldName ?? '"' + `${input}` + '"'}`;
}

export const RpgClassValidators = {
  boolean: (input: boolean, rules: Partial<BooleanRules> = {}): string[] => {
    const errors: string[] = [];
    const combinedRules: BooleanRules = {
      ...defaultBooleanRules,
      ...rules,
    };
    if (combinedRules.isBooleanType) {
      if (typeof input !== 'boolean') {
        errors.push(`${fieldNameOrInput(combinedRules.fieldName, input)} is not a valid boolean`);
        Logger.log(input);
      }
    }
    return errors;
  },
  enum: (input: any, enumType: { members: any[] }, rules: Partial<EnumRules> = {}): string[] => {
    const errors: string[] = [];
    const combinedRules: EnumRules = {
      ...defaultEnumRules,
      ...rules,
    };
    if (combinedRules.isArray) {
      errors.push(
        ...(input as any[]).reduce(
          (acc, next) => [
            ...acc,
            ...RpgClassValidators.enum(next, enumType, {
              ...combinedRules,
              isArray: false,
            }),
          ],
          []
        )
      );
    } else {
      if (combinedRules.isEnumType) {
        if (!enumType.members.includes(input)) {
          errors.push(
            `${fieldNameOrInput(combinedRules.fieldName, input)} is not a valid member for ${
              combinedRules.enumName
            }`
          );
          Logger.log(input);
        }
      }
    }

    return errors;
  },
  number: (input: number, rules: Partial<NumberRules> = {}): string[] => {
    const errors: string[] = [];
    const combinedRules = {
      ...defaultNumberRules,
      ...rules,
    };
    if (combinedRules.isNumberType) {
      if (typeof input !== 'number') {
        errors.push(`${fieldNameOrInput(combinedRules.fieldName, input)} is not a valid number`);
        Logger.log(input);
      } else if (isNaN(input)) {
        errors.push(`${fieldNameOrInput(combinedRules.fieldName, input)} is invalid NaN`);
        Logger.log(input);
      }
    }
    if (typeof combinedRules.min === 'number') {
      if (input < combinedRules.min) {
        errors.push(
          `${fieldNameOrInput(
            combinedRules.fieldName,
            input
          )} must not be less than the minimum of ${combinedRules.min}`
        );
        Logger.log(input);
      }
    }
    if (typeof combinedRules.max === 'number') {
      if (input > combinedRules.max) {
        errors.push(
          `${fieldNameOrInput(
            combinedRules.fieldName,
            input
          )} must not be larger than the maximum of ${combinedRules.max}`
        );
        Logger.log(input);
      }
    }
    return errors;
  },
  string: (input: string, rules: Partial<StringRules> = {}): string[] => {
    const errors: string[] = [];
    const combinedRules = {
      ...defaultStringRules,
      ...rules,
    };
    // If we allow empty, and the string is actually empty, we can bypass
    // all the other rules
    if (combinedRules.allowEmpty && typeof input === 'string' && input === '') {
      return errors;
    }
    if (combinedRules.isStringType) {
      if (typeof input !== 'string') {
        errors.push(`${fieldNameOrInput(combinedRules.fieldName, input)} is not a valid string`);
        Logger.log(input);
      }
    }
    if (!combinedRules.allowEmpty) {
      if (!normalizeString(input)) {
        errors.push(`${fieldNameOrInput(combinedRules.fieldName, input)} cannot be blank`);
        Logger.log(input);
      }
    }
    if (typeof combinedRules.maxLength === 'number' && combinedRules.maxLength > 0) {
      if (input.length > combinedRules.maxLength) {
        errors.push(
          `${fieldNameOrInput(combinedRules.fieldName, input)} cannot exceed max length of ${
            combinedRules.maxLength
          } characters`
        );
        Logger.log(input);
      }
    }

    return errors;
  },
};

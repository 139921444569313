import { Injectable } from '@angular/core';
import {
  BaseCharacter,
  CharacterSort,
  mapCharacterToClass,
  NdsCharacterAttribute,
} from '@rpg/core/character';
import { Body, Get, HttpService, MapValue, Path, Post, Put, Query } from '@rpg/ngx/http';
import { ObjectId } from 'bson';
import { EMPTY, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CharacterHttpService<
  CharacterClass extends BaseCharacter = BaseCharacter
> extends HttpService {
  @Get('/character/:id')
  @MapValue(mapCharacterToClass)
  public getCharacterById(@Path('id') characterId: ObjectId): Observable<CharacterClass> {
    return EMPTY;
  }

  @Get('/character')
  @MapValue((res: CharacterClass[]) => res.map(r => mapCharacterToClass(r)))
  public getCharacterList(@Query('sort') sort: CharacterSort): Observable<CharacterClass[]> {
    return EMPTY;
  }

  @Post('/character')
  @MapValue(mapCharacterToClass)
  public createCharacter(
    @Body('character') characterData: CharacterClass,
    @Body('folderId') folderId: string | ObjectId
  ): Observable<CharacterClass> {
    return EMPTY;
  }

  @Put('/character/:id')
  public updateCharacterById(
    @Path('id') characterId: ObjectId,
    @Body() characterData: CharacterClass
  ): Observable<CharacterClass> {
    return EMPTY;
  }

  @Post('/character/:id/clone')
  public cloneCharacter(@Path('id') characterId: ObjectId): Observable<ObjectId> {
    return EMPTY;
  }

  @Get('/character/:id/isUserGM')
  public isUserGMOfCharacter(@Path('id') characterId: ObjectId): Observable<boolean> {
    return EMPTY;
  }

  @Get('/character/game/:id/options')
  @MapValue((res: CharacterClass[]) => res.map(c => mapCharacterToClass(c)))
  public getGameCharacterOptions(@Path('id') gameId: ObjectId): Observable<CharacterClass[]> {
    return EMPTY;
  }

  @Post('/character/:id/attribute')
  public updateCharacterAttributeById(
    @Path('id') characterId: ObjectId,
    @Body()
    data: { type: NdsCharacterAttribute; value: number; gameId?: ObjectId }
  ): Observable<boolean> {
    return EMPTY;
  }
}

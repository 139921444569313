<mat-form-field class="css-loader">
  <input matInput />
</mat-form-field>
<div class="mat-form-field-wrapper">
  <div
    class="mat-form-field-flex"
    #connectionContainer
    (click)="_control.onContainerClick && _control.onContainerClick($event)"
  >
    <!-- Outline used for outline appearance. -->
    <ng-container *ngIf="appearance === 'outline'">
      <div class="mat-form-field-outline">
        <div class="mat-form-field-outline-start"></div>
        <div class="mat-form-field-outline-gap-container">
          <div class="mat-form-field-outline-gap-top-container">
            <div class="mat-form-field-outline-gap-top"></div>
            <div class="mat-form-field-outline-filler-top"></div>
          </div>
          <div class="mat-form-field-outline-gap-bottom-container">
            <div class="mat-form-field-outline-gap-bottom"></div>
            <div class="mat-form-field-outline-filler-bottom"></div>
          </div>
        </div>
        <div class="mat-form-field-outline-end"></div>
      </div>
      <div class="mat-form-field-outline mat-form-field-outline-thick">
        <div class="mat-form-field-outline-start"></div>
        <div class="mat-form-field-outline-gap-container">
          <div class="mat-form-field-outline-gap-top-container">
            <div class="mat-form-field-outline-gap-top"></div>
            <div class="mat-form-field-outline-filler-top"></div>
          </div>
          <div class="mat-form-field-outline-gap-bottom-container">
            <div class="mat-form-field-outline-gap-bottom"></div>
            <div class="mat-form-field-outline-filler-bottom"></div>
          </div>
        </div>
        <div class="mat-form-field-outline-end"></div>
      </div>
    </ng-container>

    <div class="mat-form-field-prefix" *ngIf="_prefixChildren.length">
      <ng-content select="[matPrefix]"></ng-content>
    </div>

    <div class="mat-form-field-infix" #inputContainer>
      <ng-content></ng-content>

      <span class="mat-form-field-label-wrapper">
        <!-- We add aria-owns as a workaround for an issue in JAWS & NVDA where the label isn't
             read if it comes before the control in the DOM. -->
        <label
          class="mat-form-field-label"
          (cdkObserveContent)="updateOutlineGap()"
          [cdkObserveContentDisabled]="appearance !== 'outline'"
          [id]="_labelId"
          [attr.for]="_control.id"
          [attr.aria-owns]="_control.id"
          [class.mat-empty]="_control.empty && !_shouldAlwaysFloat"
          [class.mat-form-field-empty]="_control.empty && !_shouldAlwaysFloat"
          [class.mat-accent]="color === 'accent'"
          [class.mat-warn]="color === 'warn'"
          #label
          *ngIf="_hasFloatingLabel()"
          [ngSwitch]="_hasLabel()"
        >
          <!-- @breaking-change 8.0.0 remove in favor of mat-label element an placeholder attr. -->
          <ng-container *ngSwitchCase="false">
            <ng-content select="mat-placeholder"></ng-content>
            <span>{{ _control.placeholder }}</span>
          </ng-container>

          <ng-content select="mat-label" *ngSwitchCase="true"></ng-content>

          <!-- @breaking-change 8.0.0 remove `mat-placeholder-required` class -->
          <span
            class="mat-placeholder-required mat-form-field-required-marker"
            aria-hidden="true"
            *ngIf="!hideRequiredMarker && _control.required && !_control.disabled"
            >&#32;*</span
          >
        </label>
      </span>
    </div>

    <div class="mat-form-field-suffix" *ngIf="_suffixChildren.length">
      <ng-content select="[matSuffix]"></ng-content>
    </div>
  </div>

  <!-- Underline used for legacy, standard, and box appearances. -->
  <div class="mat-form-field-underline" #underline *ngIf="appearance !== 'outline'">
    <span
      class="mat-form-field-ripple"
      [class.mat-accent]="color === 'accent'"
      [class.mat-warn]="color === 'warn'"
    ></span>
  </div>

  <div
    class="mat-form-field-subscript-wrapper"
    #subscriptWrapper
    [ngSwitch]="_getDisplayedMessages()"
  >
    <div *ngSwitchCase="'error'" [@transitionMessages]="_subscriptAnimationState">
      <ng-content select="mat-error"></ng-content>
    </div>
    <div
      class="mat-form-field-hint-wrapper"
      *ngSwitchCase="'hint'"
      [@transitionMessages]="_subscriptAnimationState"
    >
      <ng-content select="mat-hint:not([align='end'])"></ng-content>
      <div class="mat-form-field-hint-spacer"></div>
      <ng-content select="mat-hint[align='end']"></ng-content>
    </div>
  </div>
</div>

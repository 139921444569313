import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}
  public handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (
      isPlatformBrowser(this._platformId) &&
      chunkFailedMessage.test(error.message) &&
      localStorage.getItem('lastChunkError') !== error.message
    ) {
      localStorage.setItem('lastChunkItem', error.message);
      window.location.reload();
      return;
    } else {
      Sentry.captureException(error.originalError || error);
    }
  }
}

import { NdsCharacterTalentData, NdsCharacterTalentListGroupData } from '.';
import { NdsCharacterForceTreeData } from './nds-character-force-tree-data';

export class NdsCharacterForceConfigData {
  public talents: NdsCharacterTalentData[] = [];
  public trees: NdsCharacterForceTreeData[] = [];
  public listGroups: NdsCharacterTalentListGroupData[] = [];

  constructor(params?: Partial<NdsCharacterForceConfigData>) {
    if (!!params) {
      this.talents = Array.isArray(params.talents)
        ? params.talents.map(t => new NdsCharacterTalentData(t))
        : this.talents;
      this.trees = Array.isArray(params.trees)
        ? params.trees.map(t => new NdsCharacterForceTreeData(t))
        : this.trees;
      this.listGroups = Array.isArray(params.listGroups)
        ? params.listGroups.map(g => new NdsCharacterTalentListGroupData(g))
        : this.listGroups;
    }
  }

  public static validate(input: NdsCharacterForceConfigData): string[] {
    const errors: string[] = [];
    errors.push(
      ...input.trees.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterForceTreeData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.talents.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterTalentData.validate(next)],
        []
      )
    );
    errors.push(
      ...input.listGroups.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterTalentListGroupData.validate(next)],
        []
      )
    );
    return errors;
  }
}

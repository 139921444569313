import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@ngneat/reactive-forms';
import { Store } from '@ngxs/store';
import { RpgSelectOption } from '@rpg/core/base';
import { ImportSource } from '@rpg/core/imports';
import { PatreonReward } from '@rpg/core/patreon';
import { User } from '@rpg/core/user';
import { getErrorMessage, ImportHttpService, OverlayResponse } from '@rpg/ngx/core';
import { RxState } from '@rx-angular/state';
import { UserState } from '@sessions/state';
import { ObjectId } from 'bson';
import { firstValueFrom, map, Observable, startWith } from 'rxjs';

interface ImportLimits {
  limit: number;
  current: number;
  hasCustomData: boolean;
}

interface ComponentState {
  user: User;
  items: RpgSelectOption[];
  limits: ImportLimits;
  loading: boolean;
  title: string;
  error: string;
}

@Component({
  selector: 'rpg-npc-import',
  templateUrl: './npc-import.component.html',
  styleUrls: ['./npc-import.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class NpcImportComponent implements OnInit {
  public inputName: FormControl = new FormControl<any, any>('');
  public submitEnabled$: Observable<boolean> = this.inputName.value$.pipe(
    startWith(''),
    map(input => {
      const { items } = this._state.get();
      const minChars = 3;
      if (!input || input.length < minChars || !items?.length) {
        return false;
      }

      // Only enable submit button if there's only one item matching their
      // input text.
      const matching = items.find(x => x.label === input);
      return !!matching;
    })
  );
  public PatreonReward: typeof PatreonReward = PatreonReward;
  public state$ = this._state.select();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: { type: ImportSource; parentFolderId?: ObjectId },
    private _dialog: MatDialogRef<NpcImportComponent, OverlayResponse<void>>,
    private _importHttpService: ImportHttpService,
    private _store: Store,
    private _state: RxState<ComponentState>
  ) {
    this._state.connect('user', this._store.select(UserState.currentUser));
    this._state.set({
      title: this._data.type === ImportSource.DylanPetrumsaNpc ? 'title-dylan' : 'title-stoogoff',
    });
  }

  public async ngOnInit(): Promise<void> {
    try {
      const [items, limits] = await Promise.all([
        firstValueFrom(this._importHttpService.loadNpcData(this._data.type)),
        firstValueFrom(this._importHttpService.getImportLimits()),
      ]);
      this._state.set({
        items,
        limits,
        loading: false,
      });
    } catch (e) {
      this._state.set({ error: 'Unable to load NPC data' });
    }
  }

  public cancel(): void {
    this._dialog.close(OverlayResponse.Cancel());
  }

  public import(): void {
    this._state.set({
      loading: true,
    });
    this._importHttpService
      .importNpcData(this._data.type, this.inputName.value, this._data.parentFolderId)
      .subscribe({
        error: err => {
          this._state.set({
            error: getErrorMessage(err),
            loading: false,
          });
        },
        next: () => this._dialog.close(OverlayResponse.Confirm()),
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpService } from '@rpg/ngx/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageHttpService extends HttpService {
  public uploadImage(uploadFile: File): Observable<{ url: string }> {
    const endpoint = `/image`;
    const formData: FormData = new FormData();
    formData.append('file', uploadFile, uploadFile.name);
    return this._http.post<{ url: string }>(endpoint, formData);
  }
}

import { createId, createSlug, parseBoolean, RpgClassValidators } from '@rpg/core/base';
import { NdsCharacterModifierFactory } from '../functions/nds-character-modifier-factory';
import { NdsCharacterModifierValidatorFactory } from '../functions/nds-character-modifier-validator-factory';
import { NdsCharacterEncumbrance, NdsCharacterLookupName } from './interfaces';
import { NdsCharacterModifierData } from './modifiers';

export class NdsCharacterEquipmentData implements NdsCharacterLookupName, NdsCharacterEncumbrance {
  public id: string = '';
  public name: string = '';
  public quantity: number = 0;
  public encumbrance: number = 0;
  public rarity: number = 0;
  public restricted: boolean = false;
  public cost: string = '';
  public lookupName: string = '';
  public carrying: boolean = false;
  public description: string = '';
  public modifiers: NdsCharacterModifierData[] = [];

  constructor(params?: Partial<NdsCharacterEquipmentData>) {
    if (!!params) {
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
      this.quantity = params.quantity ?? this.quantity;
      this.encumbrance = params.encumbrance ?? this.encumbrance;
      this.rarity = params.rarity ?? this.rarity;
      this.restricted = parseBoolean(params.restricted, this.restricted);
      this.cost = params.cost ?? this.cost;
      this.lookupName = params.lookupName ?? this.lookupName;
      this.carrying = parseBoolean(params.carrying, this.carrying);
      this.description = params.description ?? this.description;
      this.modifiers = Array.isArray(params.modifiers)
        ? params.modifiers.map(m => NdsCharacterModifierFactory(m)).filter(m => !!m.name)
        : this.modifiers;
    }
    if (!this.id) {
      this.id = createId();
    }
    if (!this.lookupName) {
      this.lookupName = createSlug(this.name ?? '');
    }
  }

  public static validate(input: NdsCharacterEquipmentData): string[] {
    const errors: string[] = [];
    const name = `Equipment ${input.name ? `(${input.name}) ` : ''}`;
    errors.push(...RpgClassValidators.string(input.id, { fieldName: `${name} ID` }));
    errors.push(...RpgClassValidators.string(input.name, { fieldName: `${name} Name` }));
    errors.push(...RpgClassValidators.number(input.quantity, { fieldName: `${name} Quantity` }));
    errors.push(
      ...RpgClassValidators.number(input.encumbrance, { fieldName: `${name} Encumbrance` })
    );
    errors.push(...RpgClassValidators.number(input.rarity, { fieldName: `${name} Rarity` }));
    errors.push(
      ...RpgClassValidators.boolean(input.restricted, { fieldName: `${name} Restricted` })
    );
    errors.push(
      ...RpgClassValidators.string(input.cost, { allowEmpty: true, fieldName: `${name} Cost` })
    );
    errors.push(
      ...RpgClassValidators.string(input.lookupName, { fieldName: `${name} Custom Name` })
    );
    errors.push(
      ...RpgClassValidators.boolean(input.carrying, { fieldName: `${name} Carrying Toggle` })
    );
    errors.push(
      ...RpgClassValidators.string(input.description, {
        allowEmpty: true,
        fieldName: `${name} Description`,
      })
    );
    errors.push(
      ...input.modifiers.reduce<string[]>(
        (acc, next) => [...acc, ...NdsCharacterModifierValidatorFactory(next, name)],
        []
      )
    );
    return errors;
  }
}

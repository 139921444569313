import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Expandable } from '@rpg/core/base';
import { NdsCharacterTalentTreeData } from '@rpg/core/character';

export function ndsCharacterTalentTreeToFormGroup(
  tree: NdsCharacterTalentTreeData
): FormGroup<NdsCharacterTalentTreeData> {
  return new FormGroup<NdsCharacterTalentTreeData & Expandable>({
    expanded: new FormControl((tree as any)['expanded'] ?? false),
    id: new FormControl(tree.id),
    name: new FormControl(tree.name),
    edgeMatrix: new FormControl(tree.edgeMatrix),
    positionManager: new FormControl(tree.positionManager),
    signatureTalentId: new FormControl(tree.signatureTalentId),
    signatureTalentEdgeMatrix: new FormControl(tree.signatureTalentEdgeMatrix),
    signatureTalentPositionManager: new FormControl(tree.signatureTalentPositionManager),
  });
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { GameTheme } from '@rpg/core/base';
import { RxState } from '@rx-angular/state';

type TypeOptions = 'standard' | 'solid';

interface ComponentState {
  attrImage: string;
  type: TypeOptions;
  title: string;
  editMode: boolean;
  labels: string[];
  resolvedValues: number[];
  textMode: 'light' | 'dark';
}

@Component({
  selector: 'rpg-nds-attribute-double',
  templateUrl: './attribute-double.component.html',
  styleUrls: ['./attribute-double.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class NdsAttributeDoubleComponent {
  @Input()
  public set gameTheme(theme: GameTheme) {
    this._updateAttrImage(theme);
  }

  @Input()
  public set type(type: TypeOptions) {
    this._state.set({
      type,
    });
  }

  @Input()
  public set title(title: string) {
    this._state.set({
      title,
    });
  }

  @Input()
  public set labels(labels: string[]) {
    this._state.set({
      labels,
    });
  }

  @Input()
  public set editMode(editMode: boolean) {
    this._state.set({
      editMode,
    });
  }

  @Input()
  public set resolvedValues(resolvedValues: number[]) {
    this._state.set({
      resolvedValues,
    });
  }

  @Input()
  public controls!: FormControl<number>[];

  public state$ = this._state.select();

  constructor(private _state: RxState<ComponentState>) {
    this._state.set({
      type: 'standard',
      editMode: false,
      labels: ['', ''],
      resolvedValues: [],
      textMode: 'light',
    });
  }

  public checkValue(control: FormControl): void {
    if (isNaN(control.value)) {
      control.setValue(0);
    }
  }

  private _updateAttrImage(theme: GameTheme): void {
    const { type } = this._state.get();
    let imgSrc: string = '';
    let textMode: 'light' | 'dark' = 'light';
    switch (theme) {
      case GameTheme.StarWars_AoR:
      case GameTheme.StarWars_EotE:
      case GameTheme.StarWars_FaD:
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/starwars/statblock-def2-sw.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/starwars/statblock02-sw.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_Android:
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-android/statblock-def2-and.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-android/statblock02-and.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_TwilightImperium:
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-imperium/statblock-def2-ti.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-imperium/statblock02-ti.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_Core:
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-core/statblock-def2-gen.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-core/statblock02-gen.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_Keyforge:
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-keyforge/statblock-def2-kf.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-keyforge/statblock02-kf.svg?auto=format';
        }
        break;
      case GameTheme.Genesys_Terrinoth:
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-terrinoth/statblock-def2-ter.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/genesys-terrinoth/statblock02-ter.svg?auto=format';
        }
        break;
      case GameTheme.Community_AwakenedAge:
        textMode = 'dark';
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-awakenedage/statblock-def2-aa.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-awakenedage/statblock02-aa.svg?auto=format';
        }
        break;
      case GameTheme.Community_Inquisition:
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-inquisition/statblock-def2-in.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-inquisition/statblock02-in.svg?auto=format';
        }
        break;
      case GameTheme.Community_SomethingStrange:
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-somethingstrange/statblock-def2-ss.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-somethingstrange/statblock02-ss.svg?auto=format';
        }
        break;
      case GameTheme.Community_Tron:
        if (type === 'solid') {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-tron/statblock-def2-tron.svg?auto=format';
        } else {
          imgSrc =
            'https://unbound-legends.imgix.net/rules-nds/community-tron/statblock02-tron.svg?auto=format';
        }
        break;
      default:
        throw new Error('Invalid Game Theme supplied to NdsVehicleAttributeDouble');
    }
    this._state.set({
      attrImage: imgSrc,
      textMode,
    });
  }
}

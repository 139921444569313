import { ObjectId } from 'bson';
import { DocStatus } from '../enums';
import { parseDate, parseEnum, parseObjectId } from '../functions/utilities';

export abstract class MongoDoc {
  public _id: ObjectId = new ObjectId();
  public createdDate: Date = new Date();
  public modifiedDate: Date = new Date();
  public status: DocStatus = DocStatus.Active;

  constructor(params?: Partial<MongoDoc>) {
    if (!!params) {
      this._id = parseObjectId(params._id, this._id);
      this.createdDate = parseDate(params.createdDate, this.createdDate);
      this.modifiedDate = parseDate(params.modifiedDate, this.modifiedDate);
      this.status = parseEnum(DocStatus, params.status, this.status);
    }
  }
}

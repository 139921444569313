export class DiscordLink {
  public transactionId: string = '';
  public linkKey: string = '';
  public id: string = '';
  public name: string = '';

  constructor(params?: Partial<DiscordLink>) {
    if (!!params) {
      this.transactionId = params.transactionId ?? this.transactionId;
      this.linkKey = params.linkKey ?? this.linkKey;
      this.id = params.id ?? this.id;
      this.name = params.name ?? this.name;
    }
  }
}

import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NdsCharacterEquipmentData } from '@rpg/core/character';
import { NDSCharacterModifierFormGroupFactory } from './modifiers';
import { Expandable } from '@rpg/core/base';

export function ndsCharacterEquipmentToFormGroup(
  equipment: NdsCharacterEquipmentData
): FormGroup<NdsCharacterEquipmentData> {
  return new FormGroup<NdsCharacterEquipmentData & Expandable>({
    expanded: new FormControl((equipment as any)['expanded'] ?? false),
    carrying: new FormControl(equipment.carrying),
    cost: new FormControl(equipment.cost),
    description: new FormControl(equipment.description),
    encumbrance: new FormControl(equipment.encumbrance),
    id: new FormControl(equipment.id),
    lookupName: new FormControl(equipment.lookupName),
    modifiers: new FormArray(equipment.modifiers.map(m => NDSCharacterModifierFormGroupFactory(m))),
    name: new FormControl(equipment.name),
    quantity: new FormControl(equipment.quantity),
    rarity: new FormControl(equipment.rarity),
    restricted: new FormControl(equipment.restricted),
  });
}

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormGroup } from '@ngneat/reactive-forms';
import { DiceTheme, GameTheme, RpgSelectOption, RulesSystem } from '@rpg/core/base';
import { NdsVehicleConfigurationData, NdsVehicleDefenseZone } from '@rpg/core/vehicle';
import { ndsVehicleConfigurationToFormGroup } from '@rpg/ngx/core';
import { startWith, Subscription } from 'rxjs';

@Component({
  selector: 'rpg-nds-vehicle-configuration',
  templateUrl: './nds-vehicle-configuration.component.html',
  styleUrls: ['./nds-vehicle-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NdsVehicleConfigurationComponent implements OnDestroy {
  @Input()
  public configurationForm = ndsVehicleConfigurationToFormGroup(
    new NdsVehicleConfigurationData({
      gameTheme: GameTheme.Genesys_Core,
      diceTheme: DiceTheme.Genesys,
      defenseZones: NdsVehicleDefenseZone.members,
    })
  );
  public advancedOptionsVisible = false;

  public gameThemeOptions: RpgSelectOption<GameTheme>[] = GameTheme.membersByRulesSystem(
    RulesSystem.NarrativeDiceSystem
  ).map(theme => ({
    label: GameTheme.translationKey(theme),
    value: theme,
  }));

  public DiceTheme: typeof DiceTheme = DiceTheme;
  public NdsVehicleDefenseZone: typeof NdsVehicleDefenseZone = NdsVehicleDefenseZone;
  // prettier-ignore
  public defenseZoneGrid: any[] = [
              null,             NdsVehicleDefenseZone.Fore,               null,
    NdsVehicleDefenseZone.Port,            null,            NdsVehicleDefenseZone.Starboard,
              null,             NdsVehicleDefenseZone.Aft,                null
  ]

  private _diceThemeWatcher: Subscription | undefined;

  constructor() {
    this._watchDiceTheme(this.configurationForm);
  }

  ngOnDestroy(): void {
    this._diceThemeWatcher?.unsubscribe();
  }

  public updateFormGroup(form: FormGroup<NdsVehicleConfigurationData>): void {
    this.configurationForm = form;
    this._watchDiceTheme(form);
    this.configurationForm.updateValueAndValidity();
  }

  public getValue(): NdsVehicleConfigurationData {
    return new NdsVehicleConfigurationData(this.configurationForm.value);
  }

  public updateDefenseZone(event: MatCheckboxChange): void {
    const value = event.source.value as NdsVehicleDefenseZone;
    const defenseControl = this.configurationForm.get('defenseZones');
    const defenseValue = defenseControl.value as NdsVehicleDefenseZone[];
    if (event.checked && !defenseValue.includes(value)) {
      defenseControl.setValue([...defenseValue, value].sort(NdsVehicleDefenseZone.sort));
      return;
    }
    if (!event.checked && defenseValue.includes(value)) {
      if (value.length === 1) {
        defenseControl.setValue([]);
        defenseControl.updateValueAndValidity();
        return;
      }
      const idx = defenseValue.indexOf(value);
      defenseControl.setValue([...defenseValue.slice(0, idx), ...defenseValue.slice(idx + 1)]);
      return;
    }
  }

  private _watchDiceTheme(form: FormGroup): void {
    this._diceThemeWatcher?.unsubscribe();
    const diceThemeControl = form.get('diceTheme');
    const forceDiceControl = form.get('forceDiceEnabled');
    this._diceThemeWatcher = diceThemeControl.valueChanges
      .pipe(startWith(diceThemeControl.value))
      .subscribe(nextValue => {
        if (nextValue === DiceTheme.Genesys && forceDiceControl.disabled) {
          forceDiceControl.enable();
        } else if (nextValue !== DiceTheme.Genesys && forceDiceControl.enabled) {
          forceDiceControl.disable();
          forceDiceControl.setValue(false);
        }
      });
  }
}

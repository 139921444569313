export enum WebsocketConnectionStatus {
  Connected = '[websocket connection] connected',
  Connecting = '[websocket connection] connecting',
  Reconnecting = '[websocket connection] reconnecting',
  Disconnected = '[websocket connection] disconnected',
}

export namespace WebsocketConnectionStatus {
  export function toString(type: WebsocketConnectionStatus): string {
    switch (type) {
      case WebsocketConnectionStatus.Connected:
        return 'Connected';
      case WebsocketConnectionStatus.Connecting:
        return 'Connecting';
      case WebsocketConnectionStatus.Reconnecting:
        return 'Reconnecting';
      case WebsocketConnectionStatus.Disconnected:
        return 'Disconnected';
      default:
        return '';
    }
  }

  export const members: WebsocketConnectionStatus[] = [
    WebsocketConnectionStatus.Connected,
    WebsocketConnectionStatus.Connecting,
    WebsocketConnectionStatus.Reconnecting,
    WebsocketConnectionStatus.Disconnected,
  ];
}
